export default {
	error: {
		en: 'error',
		ge: 'შეცდომა',
		de: 'Fehler',
		ru: 'ошибка',
		cn: '出错'
	},
	unexpected_error: {
		en: 'Unexpected error',
		ge: 'გაუთვალისწინებელი შეცდომა',
		de: 'Unerwarteter Fehler',
		ru: 'Внутренняя ошибка сервера',
		cn: 'Unexpected error', // TODO: translation needed
	},
	please_retry: {
		en: 'Please, try again later.',
		ge: 'გთხოვთ, სცადოთ მოგვიანებით.',
		de: 'Bitte versuchen Sie es später noch einmal.',
		ru: 'Пожалуйста, попробуйте позже.',
		cn: '请稍后重试。'
	},
	not_found: {
		en: 'Page not found.',
		ge: 'გვერდი ვერ მოიძებნა',
		de: 'Seite nicht gefunden.',
		ru: 'Старница не найдена.',
		cn: '未找到页面。'
	},
	to_the_main_page: {
		en: 'To the main page',
		ge: 'მთავარ გვერდზე',
		de: 'Zur Hauptseite',
		ru: 'На главную страницу',
		cn: 'To the main page'  // TODO: translation needed
	},
	input_required_empty: {
		en: 'This field cannot be empty',
		ge: 'ს ველი არ შეიძლება იყოს ცარიელი',
		de: 'Dieses Feld darf nicht leer sein',
		ru: 'Поле не может быть пустым',
		cn: '此字段不能为空'
	},
	select_required_not_selected: {
		en: 'You should choose an option',
		ge: 'თქვენ უნდა აირჩიოთ ვარიანტი',
		de: 'Sie müssen eine Option auswählen',
		ru: 'Нужно выбрать опцию',
		cn: '您需要选择一个选项'
	},
	fill_required_fields: {
		en: 'Fill all the required fields to continue',
		ge: 'შეავსეთ ყველა საჭირო ველი გასაგრძელებლად',
		de: 'Füllen Sie alle erforderlichen Felder aus, um fortzufahren',
		ru: 'Заполните все необходимые поля для продолжения',
		cn: '填写所有必填字段，然后继续'
	},
	resolve_errors: {
		en: 'Resolve all the errors to continue',
		ge: 'გააგრძელეთ ყველა შეცდომის მოგვარება',
		de: 'Beheben Sie alle Fehler, um fortzufahren',
		ru: 'Устраните все ошибки для продолжения',
		cn: '解决所有错误，然后继续'
	},
	creative_reslution_mismatch: {
		en: 'Selected file resolution ({current}) doesn\'t match the target resolution ({target})',
		ge: 'ფაილის არჩეული გარჩევადობა ({current}) არ ემთხვევა სამიზნე გარჩევადობას ({target})',
		de: 'Die Auflösung der ausgewählten Datei ({current}) stimmt nicht mit der Zielauflösung ({target}) überein',
		ru: 'Разрешение выбранного файла ({current}) не соответствует целевому разрешению ({target})',
		cn: '所选文件的分辨率 ({current}) 不符合目标分辨率 ({target})'
	},
	creative_duration_mismatch: {
		en: 'Selected file duration ({current}) doesn\'t match campaign\'s ad duration ({target})',
		ge: 'არჩეული ფაილის ხანგრძლივობა ({current}) არ ემთხვევა კამპანიის რეკლამის ხანგრძლივობას ({target})',
		de: 'Die Dauer der ausgewählten Datei ({current}) stimmt nicht mit der Anzeigendauer der Kampagne ({target}) überein',
		ru: 'Длительность выбранного файла ({current}) не соответствует длительности из кампании ({target})',
		cn: '所选文件的时长 ({current}) 不符合广告活动的时长 ({target})'
	},
	invalid_email: {
		en: 'Invalid email address format',
		ge: 'Invalid email address format', /* TODO: to be translated */
		de: 'Invalid email address format', /* TODO: to be translated */
		ru: 'Неверный формат email адреса',
		cn: /* TODO: to be translated */'Invalid email address format',
	},
	invalid_start_date: {
		en: 'The start date cannot be changed',
		ge: 'The start date cannot be changed', /* TODO: to be translated */
		de: 'The start date cannot be changed', /* TODO: to be translated */
		ru: 'Дату начала нельзя изменить',
		cn: /* TODO: to be translated */'The start date cannot be changed',
	},
	invalid_end_date: {
		en: 'The end date cannot be earlier than today',
		ge: 'The end date cannot be earlier than today', /* TODO: to be translated */
		de: 'The end date cannot be earlier than today', /* TODO: to be translated */
		ru: 'Дата окончания не может быть раньше сегодняшнего дня',
		cn: /* TODO: to be translated */'The end date cannot be earlier than today',
	},
	wrong_phone_number: {
		en: 'Wrong phone number',
		ge: 'Wrong phone number', /* TODO: to be translated */
		de: 'Wrong phone number', /* TODO: to be translated */
		ru: 'Неверный формат телефона',
		cn: /* TODO: to be translated */'Wrong phone number',
	},
	wrong_vat_number: {
		en: 'The payment system did not find this INN',
		ge: 'The payment system did not find this INN', /* TODO: to be translated */
		de: 'The payment system did not find this INN', /* TODO: to be translated */
		ru: 'Платежная система не нашла данный ИНН',
		cn: /* TODO: to be translated */'The payment system did not find this INN',
	}
};
