/**
 * Helper function for user model
 */


/**
 * Check if user has a role
 *
 * @param {object} user - user data with `roles` property.
 *                        Roles are returned from the backend for a specified agency.
 * @param {string} role - one of `ROLE_*` in `constants/roles.js`
 *
 * @returns {boolean}
 */
export function hasRole(user, role) {
	return user.roles.includes(role);
}
