
export default {
	reset_password_title: {
		en: 'Reset password',
		ge: 'პაროლის გადატვირთვა',
		de: 'Passwort zurücksetzen',
		ru: 'Сбросить пароль',
		cn: '重置密碼',
	},
	enter_new_password: {
		en: 'Enter a new password',
		ge: 'შეიყვანეთ ახალი პაროლი',
		de: 'Neues Passwort eingeben',
		ru: 'Введите новый пароль',
		cn: '輸入新的密碼',
	},
	reset_password: {
		en: 'reset password',
		ge: 'პაროლის გადაყენება',
		de: 'Passwort zurücksetzen',
		ru: 'восстановить пароль',
		cn: '重設密碼',
	},
	reset_password_description: {
		en: 'Enter your email. If it exists, we will send you a link to reset your password.',
		ge: 'შეიყვანეთ თქვენი ელ.ფოსტა. თუ ის არსებობს, ჩვენ გამოგიგზავნით ბმულს თქვენი პაროლის გადატვირთვისთვის.',
		de: 'Geben Sie Ihre E-Mail ein. Wenn sie existiert, senden wir Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
		ru: 'Введите ваш email. Если он существует, то мы вышлем на него ссылку для восстановления пароля.',
		cn: '輸入你的電子郵箱。如果存在，我們將向您發送一個鏈接以重置您的密碼.',
	},
	passwords_not_the_same: {
		en: 'Passwords are not the same',
		ge: 'პაროლები არ არის იგივე',
		de: 'Passwörter sind nicht gleich',
		ru: 'Пароли не совпадают',
		cn: '密碼不一樣',
	},
	password_not_valid: {
		en: 'Password must be at least {minLength} characters long',
		ge: 'პაროლი უნდა შეიცავდეს მინიმუმ {minLength} სიმბოლოს',
		de: 'Das Passwort muss mindestens {minLength} Zeichen lang sein',
		ru: 'Пароль должен состоять хотя бы из {minLength} символов',
		cn: 'Password must be at least {minLength} characters long', // TODO needs to translate
	},
	sent_link_to_reset_password: {
		en: 'If a user with this email exists, we will send a password reset link.',
		ge: 'თუ მომხმარებელი ამ ელფოსტით უკვე არსებობს, ჩვენ გამოგიგზავნით პაროლის გადატვირთვის ბმულს',
		de: 'Wenn ein Benutzer mit dieser E-Mail existiert, senden wir einen Link zum Zurücksetzen des Passworts.',
		ru: 'Если пользователь с таким email существует, мы вышлем на него ссылку для сброса пароля.',
		cn: 'If a user with this email exists, we will send a password reset link.', // TODO needs to translate
	}
};
