<template>
	<symbol :id="`${prefix}play-34`" viewBox="0 0 34 34">
		<circle
			cx="17" cy="17" r="16"
			fill="none"
			stroke-width="2"
			class="sign"
		/>

		<path d="M16.1538 19.8871L19.6154 17.7218C20.1282 17.401 20.1282 16.599 19.6154 16.2782L16.1538 14.1129C15.641 13.7921 15 14.1931 15 14.8347V19.1653C15 19.8069 15.641 20.2079 16.1538 19.8871Z" class="sign play"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlay34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke: var(--ni-icon-sign, #{$nice_color-green})

	&.play
		stroke: none
		fill: var(--ni-icon-sign, #{$nice_color-green})
</style>
