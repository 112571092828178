import _ from 'underscore';
import axios from 'axios';
import Vue from 'vue';


/**
 * state должен содержать list, query, fieldsSensitive, page, pageSize, totalCount, meta и сеттеры для него
 * mutation должен содержать setPage, setPageSize, setQuery
 */
export function storeFactory(moduleName, getRequest, serializer) {
	let storeObject = {
		mutations: {
			setList(state, items) {
				const resultList = _.map(items, function(item) {
					return Object.freeze(serializer(item));
				});

				state.list = resultList;
			},

			changeItem(state, item) {
				const index = _.findIndex(state.list, p => p.id === item.id);
				state.list.splice(index, 1, Object.freeze(item));
			},
		},

		getters: {
			loadingKeyBase(state, getters, rootState, rootGetters) {
				return `agency/${rootState.app.agency}/${moduleName}`;
			},

			loadingKeyList(state, getters) {
				return (filters, page, pageSize) => {
					const query = _.reduce(_.sortBy(_.keys(filters)), (result, key) => {
						return `${result}${key}=${filters[key]}&`;
					}, '');
					return `${getters.loadingKeyBase}/requesting/list?${query}page=${page}&page_size=${pageSize}`;
				};
			},

			loadingKeyItem(state, getters) {
				return id => `${getters.loadingKeyBase}/requesting/item/${id}`;
			},

			loadingKeyItemChange(state, getters) {
				return id => `${getters.loadingKeyBase}/requesting/item/${id}/change`;
			},

			isLoading(state, getters, rootState, rootGetters) {
				return rootState.app.loading.find((key) => key.startsWith(getters.loadingKeyBase));
			},

			isLoadingList(state, getters, rootState, rootGetters) {
				return (filters, page, pageSize) => {
					const loadingKey = getters.loadingKeyList(filters, page, pageSize);
					return !!rootState.app.loading[loadingKey];
				};
			},

			isLoadingItem(state, getters, rootState, rootGetters) {
				return id => {
					const loadingKey = getters.loadingKeyItem(id);
					return !!rootState.app.loading[loadingKey];
				};
			},

			isFilterChanged(state) {
				return filters => !_.isEqual(state.query, filters);
			},
		},

		actions: {
			/**
			 * Get resource list.
			 * Request resource list if need
			 *
			 * @param {Object} [filters]
			 * @param {number} [page]
			 * @param {number} [pageSize]
			 * @param {boolean} [force=false] - make request
			 * @param {boolean} [save=true] - save data to store
			 *
			 * @returns {Object} Request data
			 * @returns {Object[]} results - resource items list
			 * @returns {nubmer} count - items count
			 */
			async requestList({ commit, state, getters, rootState, rootGetters }, { filters={}, page=1, pageSize=20, force=false, save=true }) {
				// generates an error but breaks debugging
				// if (!rootState.app.agency) {
				// 	throw new Error('Agency not set');
				// }

				page = parseInt(page);
				pageSize = parseInt(pageSize);

				const alreadyLoading = getters.isLoadingList(filters, page, pageSize);
				const alreadyLoaded = !getters.isFilterChanged(filters) && page === state.page && pageSize === state.pageSize;

				if (!(alreadyLoading || alreadyLoaded) || force) {
					const loadingKey = getters.loadingKeyList(filters, page, pageSize);
					commit('app/pushLoading', loadingKey, { root: true });

					if (page != state.page) commit('setPage', page);
					if (pageSize != state.pageSize) commit('setPageSize', pageSize);
					if (getters.isFilterChanged(filters)) commit('setQuery', filters);

					const res = await getRequest(rootState.app.side).list(filters, page, pageSize);

					if (!getters.isFilterChanged(filters) && page === state.page && pageSize === state.pageSize && save) {
						commit('setList', res.results);
						commit('setTotalCount', res.count);
						commit('setMeta', res.meta);
					}

					commit('app/removeLoading', loadingKey, { root: true });

					// TODO: !!! different return signature !!!
					return res.results;
				}

				return {
					results: state.list,
					count: state.totalCount,
					meta: state.meta,
				};
			},

			async getList({ dispatch, state }, data) {
				Vue.$log.warn('getList `deprecated`, use `requestList`');
				await dispatch('requestList', data);
				return state.list;
			},

			/**
			 * Create resource item.
			 * POST request to create resource
			 *
			 * @param {object} data of resourse object to create
			 * @param {object} config request config
			 *
			 * @returns {object} result resourse object
			 */
			async create({ state, dispatch, rootState }, { data, config }) {
				let item = null;
				try {
					item = await getRequest(rootState.app.side).create(data, config);
				} catch (error) {
					if (axios.isCancel(error)) {
						/* request canceled */
						Vue.$log.info('Creation request has been canceled by client.');
					} else {
						throw error;
					}
					return null;
				}

				dispatch('refresh');
				return item;
			},

			/**
			 * Get resource by id.
			 * Request resource if not in loaded list
			 *
			 * @param {id} - item id
			 *
			 * @returns {object} Resourse object
			 */
			async getItem({ state, rootState }, params) {
				let id = 0;
				let force = false;

				if (typeof params === 'number') {
					id = params;
				} else if (typeof params === 'object') {
					id = params.id;
					force = params.force;
				}

				if (!rootState.app.agency) {
					throw new Error('Agency not set');
				}

				let item = state.list.find(p => p.id === id);

				if (force || typeof item == 'undefined') {
					item = serializer(await getRequest(rootState.app.side).retrieve(id));
				}

				return item;
			},

			/**
			 * Change resource item
			 *
			 * @param {object} data - resource item object
			 *
			 * @returns {object} Resource item object
			 */
			async change({ state, getters, dispatch, commit, rootState }, { id, data, config }) {
				const changeKey = getters.loadingKeyItemChange(id);
				commit('app/pushLoading', changeKey, { root: true });

				let raw = null;
				try {
					raw = await getRequest(rootState.app.side).patch(id, data, config);
				} catch (error) {
					if (axios.isCancel(error)) {
						/* request canceled */
						Vue.$log.info('Editing request has been canceled by client.');
					} else {
						throw error;
					}
					return null;
				}

				const item = serializer(raw);
				const old = state.list.find(p => p.id === item.id);

				const changedData = _.keys(data);
				let needRequest = false;
				if (old) {
					for (let k of changedData) {
						if (_.has(state.fieldsSensitive, k) && data[k] !== old[k]) {
							needRequest = true;
							break;
						}
					}
				} else {
					needRequest = true;
				}

				if (needRequest) {
					dispatch('refresh');
				} else {
					commit('changeItem', item);
				}

				commit('app/removeLoading', changeKey, { root: true });
				return item;
			},

			/**
			 * Delete resource item by id
			 *
			 * @param {number} id - resource item id
			 */
			async delete({ dispatch, rootState }, id) {
				await getRequest(rootState.app.side).delete(id);
				dispatch('refresh');
			},

			/**
			 *
			 */
			async refresh({ dispatch, state }) {
				dispatch('requestList', {
					filters: state.query,
					page: state.page,
					pageSize: state.pageSize,
					force: true
				});
			},
		},
	};

	// a better debug experience
	const funcTypes = ['mutations', 'actions', 'getters'];

	for (const type of funcTypes) {
		for (const funcName in storeObject[type]) {
			Object.defineProperty(
				storeObject[type][funcName],
				'name',
				{ value: `${moduleName}.${type}.${funcName}` }
			);
		}
	}

	return storeObject;
}
