<template>
	<td>
		<!-- there is some issue with `display: grid` on a table cell -->
		<div
			:class="{
				'ssp': hideSspName,
			}"
			class="nice-table-cell_campaign_audits"
		>
			<template
				v-if="audits.length"
			>
				<template
					v-for="audit in audits"
				>
					<span
					  :key="auditKey(audit) + 1"
						class="cell-agency-name"
					>{{ hideSspName ? '' : audit.ssp_agency_name }}</span>
					<nice-icon-2
					  :key="auditKey(audit) + 2"
						:icon="getStatusByValue(audit.status).icon"
						class="ni_table_c_status--label-icon cell-icon"
					/>
					<span
					 :key="auditKey(audit) + 3"
					 class="cell-status"
					>{{ getStatusByValue(audit.status).label }}</span>
				</template>
			</template>
			<!-- empty audits -->
			<template
				v-else
			>
				&nbsp;
			</template>
		</div>
	</td>
</template>

<script>


export default {
	name: 'TableCellCampaignAudits',


	props: {
		/**
		 * Campaign or request ID
		 */
		campaignId: {
			type: Number,
			required: true,
		},

		/**
		 * Actual audits of a request/campaign
		 *
		 * Array of audit objects: {
		 *    id: int,
		 *    ssp_agency: int,
     *    ssp_agency_name: str,
		 *    campaign: int,
		 *    status: int,
     * }
		 *
		 * See TODO: for statuses
		 */
		audits: {
			type: Array,
			required: true,
		},

		/**
		 * Available statuses reference (with label and icon)
		 *
		 * {
		 *   [value]: {
		 *      value: ,
		 *      icon: ,
		 *      label: ,
	   *   },
	   * }
		 */
		statuses: {
			type: Object,
			required: true,
		},

		/**
		 * Hide SSP agency name?
		 */
		hideSspName: {
			default: false,
		},
	},  // END: props


	methods: {
		/**
		 * Return a status object from `statuses` by `value` property
		 */
		getStatusByValue(value) {
			return this.statuses[value];
		},

		auditKey(audit) {
			return audit.campaign + ':' + audit.id;
		},
	},  // END: methods
};
</script>


<style lang="sass" scoped >
.nice-table-cell_campaign_audits
	display: grid
	justify-content: stretch
	grid-template-columns: auto 16px 100px
	align-items: stretch
	//center
	column-gap: 12px
	row-gap: 12px
	// margin-bottom: 12px

	&.ssp
		grid-template-columns: 0px 16px 100px
		margin-left: -12px

	.cell-agency-name
		justify-self: end
</style>
