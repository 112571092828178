<template>
	<section
		class="invitation-modal modal"
	>

		<div class="logo-wrap">
			<logo class="logo" />
		</div>

		<h1 class="title">
			{{ $uioverrides.name }}
		</h1>

		<h2 class="subtitle">
			{{ $t('invitation.personal_invitation_to_platform1') }}
			<div>
				{{ $t('invitation.personal_invitation_to_platform2', {
					platform_name: $uioverrides.name
				}) }}
			</div>
		</h2>


		<RegistrationInvitationForm
			:do-create-agency="doCreateAgency"
			:user-exists="userExists"
			@form-submit="createAccount"
		/>

	</section>
</template>

<script>
import {
	requestInvitationByToken,
	createAgencyUserByInvitation,
	addUserToAgencyIfUserExists,

	INVITATION_ROLE_DSP_AGENCY,
	// INVITATION_ROLE_DSP_ADMIN,
	// INVITATION_ROLE_SSP_ADMIN,
	// INVITATION_ROLE_SSP_APPROVER,
} from '@/api/invitation';

import Logo from '@/ui/logo.vue';
import RegistrationInvitationForm from '@/components/registration-invitation-form';
import { mapMutations } from 'vuex';


export default {
	name: 'InvitationPage',


	components: {
		Logo,
		RegistrationInvitationForm,
	},


	data() {
		return {
			token: null,
			invitation: null,
		};
	},


	computed: {
		target_role() {
			return this.invitation ? this.invitation.role : null;
		},

		doCreateAgency() {
			return this.target_role == INVITATION_ROLE_DSP_AGENCY;
		},

		userExists() {
			return this.invitation && this.invitation.user_exists;
		},
	},


	/**
	 * Hide tokenmargin-left: 24px
	 */
	created() {
		const token = this.$route.query.token;

		if (token) {
			window.localStorage.setItem('_invitation_token', token);

			let loc = this.$router.resolve({
				name: 'invitation',
				query: {},
			});

			window.location.href = loc.href;
			return;
		}

		this.token = window.localStorage.getItem('_invitation_token');

		if (!this.token) {
			this.$router.replace({
				name: 'error-404'
			});

			return;
		}

		window.localStorage.removeItem('_invitation_token');
	},


	async mounted() {
		// there is some bug when `this.token === null`
		// might be because of a reload in `profile/getProfile`
		if (this.token) {
			const response = await requestInvitationByToken(this.token, true);
			this.$set(this, 'invitation', response.data);
			await this.skipRegistrationIfUserExists();
		}
	},


	methods: {

		...mapMutations('profile', ['setToken']),
		...mapMutations('app', ['setAgency', 'setRole', 'setSide']),

		// TODO: copy of PageRegistration.registerAccount()
		async createAccount(user, formComponent) {
			user.token = this.token;
			user.username = user.email;

			try {
				let res = await createAgencyUserByInvitation(user);
				this._loginIn(res);
			}
			catch(error) {
				let errors = {};

				if (error && error.response && error.response.data) {
					for (var k in error.response.data) {
						if (!formComponent.$refs[k]) {
							continue;
						}

						let msg = '';
						// translations are on the backend
						for (let m of error.response.data[k]) {
							msg += m + '\n';
						}

						errors[k] = msg;
					}
				}

				formComponent.$set(formComponent, 'errors', errors);
				formComponent.goToTheFirstError();

				if (Object.keys(errors).length == 0) {
					alert(this.$t('invitation.failed_to_create_account'));
				}
			}
		},

		async skipRegistrationIfUserExists() {
			if (this.userExists) {
				const response = await addUserToAgencyIfUserExists(this.token);

				// If response is 201 then user has been added to the agency
				if (response.status === 201) {
					this._loginIn(response);
				}
			}
		},

		_loginIn: function (response) {
			// copy from the profile/login action
			this.setToken({
				token: response.data.access,
				refresh: response.data.refresh
			});

			const side = this.invitation.role.substring(0, 3);

			this.setAgency(this.invitation.target_agency);
			this.setRole(this.invitation.role);
			this.setSide(side);

			// reload the whole thing
			window.location.href = '/';
		},

	},  // END: methods
};
</script>

<style lang="sass" scoped>

.modal.invitation-modal
	display: flex
	flex-direction: column
	padding: 30px 40px
	margin: 48px auto
	box-sizing: border-box
	flex-grow: 1
	overflow: auto
	min-height: 200px
	max-width: 480px
	max-height: initial

	.logo-wrap
		height: 105px
		text-align: center

		.logo
			display: inline-block
			height: 100%

	.title
		margin: 25px 0
		font-size: 30px
		line-height: 140%
		// font-style: normal
		// font-weight: normal
		text-align: center

	.subtitle
		margin: 25px 0
		font-size: 20px
		line-height: 140%
		text-align: center
</style>
