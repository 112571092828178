<template>
	<span
		class="nice-menu-context"
		:class="{
			'default_label': !hasLabelSlot
		}"
	>
		<slot
			v-if="hasLabelSlot"

			name="label"
			class="ni_menu_context--label"
		/>

		<tippy
			ref="tooltip"
			v-bind="tippyConfig"
		>
			<template v-slot:trigger>
				<nice-button-2
					:icon="icon"
					iconPlacement="end"
					class="ni_menu_context--button-toogle-dropdown"
				>
					<template v-if="label" >{{ label }}</template>
				</nice-button-2>
			</template>
			<section class="ni_menu_context--dropdown-wrapper">
				<slot />
			</section>
		</tippy>
	</span>
</template>

<script>
export default {
	name: 'NiceMenuContext',

	props: {
		/**
		 * Label for show menu
		 */
		label: {
			type: String,
			default: '',
		},

		/**
		 * Icon
		 */
		icon: {
			type: String,
			default: 'arrow_corner-12',
		},

		/**
		 * Trigger show/hide menu
		 */
		tippyTrigger: {
			type: String,
			default: 'mouseenter focus',
		},

		/**
		 * Tippy placement
		 */
		tippyPlacement: {
			type: String,
			default: 'bottom-end',
		},
	},


	computed: {
		hasLabelSlot() {
			return !!this.$slots.label;
		},

		tippyConfig() {
			return {
				trigger: this.tippyTrigger,
				placement: this.tippyPlacement,
				interactive: true,
				reactive: true,
				distance: 9,
				arrow: false,
			};
		},
	},

	methods: {
		hide() {
			const tippy = this.$refs.tooltip.tippy();
			tippy.hide();
		},
	},
};
</script>

<style lang="sass" >
.nice-menu-context
	margin: 0 $ni_menu_context--margin
</style>

<style lang="sass" scoped >
.nice-menu-context

	> *:not(:first-child)
		margin-left: $ni_menu_context--margin

	&:not(.default_label)
		display: inline-flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: flex-start
		align-items: center
		align-content: center

.ni_menu_context--button-toogle-dropdown
	min-height: auto
	padding: 0

	.default_label &
		--ni-icon-base: var(--disabled_color)
		--ni-icon-sign: var(--main_color)

.ni_menu_context--dropdown-wrapper
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start

	> *
		margin: 4px 0
		min-height: auto
		padding: 0 0
		width: 100%

		text-align: left

		&:first-child
			margin-top: 0

		&:last-child
			margin-bottom: 0
</style>
