import baseRest from '../base/rest';
import { request } from '../request';
import { getPrefix as _getPrefix } from './index';


/**
 * Request endpoint prefix
 */
const getPrefix = () => `${_getPrefix()}request/`;


export default {
	getPrefix,

	'list': baseRest(getPrefix).list,
	'retrieve': baseRest(getPrefix).retrieve,
	'patch': baseRest(getPrefix).patch,


	/**
	 * Change Request audit
	 *
	 * @param {int} requestOrCampaignId - request or campaign ID
	 * @param {int} sspAgencyId - auditing SSP agency ID
	 * @param {int} status - new status. see `constants/audit-status-codes.js`
	 * @param {object} config - axios request config
	 */
	async changeAudit(requestOrCampaignId, sspAgencyId, status, config=null) {
		return await request(
			`${getPrefix()}${requestOrCampaignId}/change-audit/`,
			{
				id: requestOrCampaignId,
				ssp_agency_id: sspAgencyId,
				status: status,
			},
			'post'
		);
	},
};
