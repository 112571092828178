<template>
	<div
		class="nice-paginator"
		:class="{
			'disabled': disabled,
			'loading': loading,
		}"
	>
		<nice-paginator-item
			:index="prevIndex"

			:icon="{
				icon: 'arrow-corner',
				state: 180,
				palette: {
					main: $palette.black,
					disabled: $palette.black__disabled,
				}
			}"
			:disabled="index === 1 || disabled"

			:isLink="actualUseQuery"
			:queryKey="actualQueryKey"

			@go-to="goTo"
		/>

		<nice-paginator-item
			v-for="i in indexes"
			:key="'test'+i"

			:index="i"
			:is-current="index === i"
			:disabled="disabled"

			:isLink="actualUseQuery"
			:queryKey="actualQueryKey"

			@go-to="goTo"
		/>

		<nice-paginator-item
			:index="nextIndex"

			:icon="{
				icon: 'arrow-corner',
				palette: {
					main: $palette.black,
					disabled: $palette.black__disabled,
				}
			}"
			:disabled="index === count || disabled"

			:isLink="actualUseQuery"
			:queryKey="actualQueryKey"

			@go-to="goTo"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import NicePaginatorItem from './nice-paginator-item';

const defaultQueryKey = 'page';


/**
 * NicePagintor component
 */
export default {
	name: 'NicePaginator',


	components: {
		NicePaginatorItem
	},


	model: {
		prop: 'index',
		event: 'input',
	},


	// props
	props: {
		index: {
			type: Number,
			default: 1,
		},

		/**
		 * 'disabled' flag
		 */
		disabled: Boolean,

		/**
		 * 'loading' flag
		 */
		loading: Boolean,

		/**
		 * total pages count
		 */
		count: {
			type: Number,
			required: true,
			validator(value) { return value > 0; },
		},

		/**
		 * Count of link to show
		 */
		pagesShowAmount: {
			type: Number,
			default: 7,
			validator(value) { return value > 0; },
		},

		/**
		 * Use query flag
		 */
		useQuery: Boolean,

		queryKey: {
			type: String,
			default: null,
		},
	},


	computed: {
		...mapState(['route']),

		/**
		 * STUB
		 * Return an array of page indexes to draw
		 */
		indexes() {
			const index = parseInt(this.index);
			let start = index - Math.floor(parseInt(this.pagesShowAmount) / 2);
			const count = Math.min(parseInt(this.count), parseInt(this.pagesShowAmount));
			if (start < 1) start = 1;
			if (start + count > this.count) start = this.count - count + 1;
			return new Array(count).fill(null).map((nothing, i) => i + start);
		},

		actualUseQuery() {
			return !!(this.useQuery || this.queryKey);
		},

		actualQueryKey() {
			return this.queryKey || defaultQueryKey;
		},

		indexFromQuery() {
			return this.route.query[this.actualQueryKey];
		},

		prevIndex() {
			return this.index > 1 ? this.index - 1 : 1;
		},

		nextIndex() {
			return this.index < this.count ? this.index + 1 : this.count;
		},
	},


	// watch
	watch: {
		$route({ query }) {
			if (!this.actualUseQuery) return;

			if (this.indexFromQuery !== this.index) {
				this.goTo(this.indexFromQuery);
			}
		},
	},


	// methods
	methods: {
		fixIndex(index) {
			if (!index ||  isNaN(index) || index < 1) {
				index = 1;
			}
			if (index > this.count) {
				index = this.count;
			}
			return index;
		},

		goTo(newIndex) {
			newIndex = this.fixIndex(newIndex);
			this.$emit('input', parseInt(newIndex));
		},
	},


	// mounted
	mounted() {
		// goto page from query if necessary
		let calcedIndex = this.index;

		if (this.actualUseQuery) {
			calcedIndex = this.indexFromQuery;
		}

		calcedIndex = this.fixIndex(calcedIndex);

		if (calcedIndex != this.index) {
			this.goTo(calcedIndex);
		}
	},
};
</script>

<style lang="sass" scoped >
.nice-paginator
	display: inline-flex
	flex-direction: row
	justify-content: center
</style>
