/**
 * This file will generate SASS config variables:
 *  * $nice_color-default
 *  * $nice_color- ...
 */
module.exports = {

	//
	// UI Overrides
	//

	main_color: '#2dc3b7',
	main_lighter_color: '#2dc3b750',  // rgba
	disabled_color: '#2dc3b733',  // #rgba
	secondary_color: '#0263cc',
	secondary_disabled_color: '#0263cc33',


	//
	// new palette
	//

	gray_dark_ultra: '#000000',
	gray_dark: '#919191',
	gray_dark_semi: '#ababab',
	gray: '#c1c1c1',
	gray_light_semi: '#dddddd',
	gray_light: '#e9e9e9',
	'gray_light_ultra-2': '#f7f7f7',
	gray_light_ultra: '#ffffff',

	// cyan_dark_ultra: '#',
	// cyan_dark: '#',
	// cyan_dark_semi: '#2cc2b6',
	cyan: '#2dc3b7',
	// cyan_light_semi: '#c0ede9',
	cyan_light: 'rgba(45, 195, 183, .2)',  // #2dc3b733
	cyan_lighter: 'rgba(45, 195, 183, .3)',  // #2dc3b733
	cyan_light_ultra: '#e0f6f4',

	// green_dark_ultra: '#',
	// green_dark: '#',
	// green_dark_semi: '#',
	green: '#21cf52',
	// green_light_semi: '#',
	// green_light: '#e0f6f4',
	green_light_ultra: 'rgba(33, 207, 82, .2)',

	// yellow_dark_ultra: '#',
	// yellow_dark: '#',
	yellow_dark_semi: '#eac000',
	yellow: '#ffd100',
	// yellow_light_semi: '#',
	// yellow_light: '#e0f6f4',
	yellow_light_ultra: 'rgba(234, 192, 0, .2)',

	// red_dark_ultra: '#',
	// red_dark: '#',
	// red_dark_semi: '#',
	red: '#ff6236',
	// red_light_semi: '#',
	// red_light: '#e0f6f4',
	red_light_ultra: '#ffe0d7',

	// purple_dark_ultra: '#',
	// purple_dark: '#',
	// purple_dark_semi: '#',
	purple: '#7c5ecf',
	// purple_light_semi: '#',
	// purple_light: '#e0f6f4',
	purple_light_ultra: '#e5dff5',

	// blue_dark_ultra: '#',
	// blue_dark: '#',
	// blue_dark_semi: '#',
	blue: '#0263cc',
	blue__disabled: '#0263cc33',
	// blue_light_semi: '#',
	// blue_light: '#e0f6f4',
	blue_light_ultra: '#cce0f5',

	// navy_dark_ultra: '#',
	// navy_dark: '#',
	// navy_dark_semi: '#',
	// navy_light_semi: '#',
	navy: '#1a2e46',
	// navy_light: '#e0f6f4',
	navy_light_ultra: '#d1d5da',












	// old palette
	black: '#000000',

	default: '#232323',

	'gray-dark': '#919191',
	graydark: 'gray-dark',
	darkgray: 'gray-dark',

	'gray-semidark': '#ababab',
	graysemidark: 'gray-semidark',
	semidarkgray: 'gray-semidark',

	black__disabled: '#b2b2b2',

	// gray: '#c1c1c1',

	grayest: '#dddddd',

	graydark__disabled: '#dedede',
	darkgray__disabled: 'graydark__disabled',

	graylight: '#e1e1e1',
	lightgray: 'graylight',
	grayest__disabled: 'graylight',
	semidarkgray__disabled: 'graylight',
	graysemidark__disabled: 'graylight',

	'gray-light': '#e9e9e9',  // Description & Icons Light

	gray__disabled: '#ececec',

	graylightest: '#f7f7f7',

	white: '#ffffff',
	white__disabled: 'white',


	activebutton: '#cde0f5',
	bluelight: '#9ac1ec',
	blue_dark: '#1a2e46',
	'blue-gray': '#d1d5da',
	proposed: 'blue_dark',
	blue_dark__disabled: '#424f5e',
	// blue: '#0263cc',
	booked: 'blue',
	button: 'blue',

	greenligth: '#c0ede9',

	ar_green: '#2dc3b7',
	ar_green__disabled: 'greenligth',

	'green-ultra-light': '#e0f6f4',

	// green: '#21cf53',

	// yellow: '#ffd200',

	// red: '#ff6237',
	'red-light': '#ffe0d7',
	red__disabled: '#ffc4b4',

	// purple: '#7c5ecf',
	'purple-light': '#e5dff5',
	purple__disabled: 'purple-light',
};
