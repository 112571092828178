<template>
	<div class="user-card">
		<div class="cl-container cl-pad-no_sides">
			<div class="cl-xs4 cl-pad user-col">
				<h2 class="title">{{ $t('users.person') | capitalize }}</h2>
				<!-- first name -->
				<nice-input
					ref="first_name_input"
					v-model="instance.first_name"
					class="input-field"
					:label="$t('users.first_name') | capitalize"
					:required="true"
					:validator="nameAndLastNameValidate"
					@input="checkUserCardValidation"
				>
					<nice-icon2 icon="pencil-13"/>
				</nice-input>

				<!-- last name -->
				<nice-input
					ref="last_name_input"
					v-model="instance.last_name"
					class="input-field"
					:label="$t('users.lastname') | capitalize"
					:required="true"
					:validator="nameAndLastNameValidate"
					@input="checkUserCardValidation"
				>
					<nice-icon2 icon="pencil-13"/>
				</nice-input>


				<!-- second name -->
				<!-- <nice-input
					v-model="instance.father_name"
					class="input-field"
					:label="$t('users.fathers_name') | capitalize"
				>
					<nice-icon2 icon="pencil-13"/>
				</nice-input> -->


				<!-- TODO: agency not here -->
				<template v-if="false">
					<nice-select
						v-if="isAgencyEditable"
						v-model="instance.agency"
						class="input-field"
						:options="agencyOptions"
						:label="$t('users.agency') | capitalize"
					/>
					<div v-else>
						<div class="detail" >
							<nice-label class="label" :label="$t('users.agency') | capitalize" />
							<span class="value" >{{ 'AGENCY_STUB' || '—' }}</span>
						</div>
					</div>
				</template>
			</div>


			<div class="cl-xs4 cl-pad user-col">
				<h2 class="title">{{ $t('users.contact') | capitalize }}</h2>
				<!-- email -->
				<template v-if="isEmailEditModeOn">
					<nice-input
						v-model="emailTempValue"
						class="input-field"
						:label="$t('email') | capitalize"
					>
						<nice-button-2
							class="inline-edit-button"
							icon="check"
							@click="applyEmailChanges"
						/>
						<nice-button-2
							class="inline-edit-button"
							icon="error"
							@click="cancelEmailChanges"
						/>
					</nice-input>
				</template>
				<template v-else>
					<nice-input
						v-model="emailValue"
						class="input-field"
						:label="$t('email') | capitalize"
						:invalid="errors.email"
						disabled
					>
						<nice-button-2
							v-if="!isEmailEditModeOn"
							class="inline-edit-button"
							icon="pencil-13"
							@click="isEmailEditModeOn = true"
						/>
					</nice-input>
				</template>

				<!-- phone -->
				<nice-input
					ref="phone_input"
					v-model="instance.phone"
					class="input-field"
					:label="$t('phone') | capitalize"
					:validator="phoneValidate"
					@input="checkUserCardValidation"
				>
					<nice-icon2 icon="pencil-13"/>
				</nice-input>
			</div>


			<div v-if="isProfile" class="cl-xs4 cl-pad user-col">
				<h2 class="title">{{ $t('users.preferences') | capitalize }}</h2>

				<!-- language -->
				<nice-select
					v-model="instance.language"
					class="input-field"
					:label="$t('users.language') | capitalize"
					:options="langFormatOptions"
				/>

				<!-- date format -->
				<nice-select
					v-model="instance.date_format"
					class="input-field"
					:label="$t('users.dateformat') | capitalize"
					:options="dateFormatOptions"
				/>

				<!-- time format -->
				<nice-select
					v-model="instance.time_format"
					class="input-field"
					:label="$t('users.timeformat') | capitalize"
					:options="timeFormatOptions"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

import NiceInput from '@/ui/nice-input';
import NiceSelect from '@/ui/nice-select';
import { nameAndLastNameValidator, phoneValidator } from '@/ui/utils/validators';


const DATE_FORMAT_OPTIONS = Object.freeze([
	{ value: 'd/m/Y', label: 'dd/mm/yyyy', },
	{ value: 'Y-m-d', label: 'yyyy-mm-dd', },
]);

const TIME_FORMAT_OPTIONS = Object.freeze([
	{ value: '24', label: '24 hours', },
	{ value: '12', label: '12 hours', },
]);


export const MODEL = Object.freeze({
	event: 'update:user',
	prop: 'user'
});

export default {
	name: 'UserCard',

	model: MODEL,

	components: {
		NiceInput,
		NiceSelect,
	},


	props: {
		[MODEL.prop]: {
			type: Object,
			required: true,
		},

		isProfile: Boolean,
	},


	data() {
		return {
			instance: null,
			isEmailEditModeOn: false,
			emailValue: this.user.email,
			emailTempValue: this.user.email,

			langFormatOptions: this.$i18n.availableLocales.map(lang => ({
				value: lang,
				label: this.$t(`users.lang_caption_${lang}`),
			})),
			timeFormatOptions: TIME_FORMAT_OPTIONS,
			dateFormatOptions: DATE_FORMAT_OPTIONS,
			errors: {}
		};
	},

	methods: {
		...mapActions('profile', ['updateProfile']),

		async applyEmailChanges() {
			this.emailValue = this.emailTempValue;

			if (this.instance.have_2fa) {
				this.isEmailEditModeOn = false;
				this.$emit('show-modal', this.emailValue);
				return;
			}

			const data = {
				new_email: this.emailValue
			};

			try {
				await this.updateProfile(data);
			} catch (e) {
				if (e.response && e.response.data && e.response.data['error']) {
					this.errors.email = e.response.data['error'];
				} else {
					this.errors.email = this.$t('notifications.notification_error');
				}
				this.$log.error(e);
				this.cancelEmailChanges();
				throw e;
			}

			this.isEmailEditModeOn = false;
			this.$delete(this.errors, 'email');
		},

		cancelEmailChanges() {
			this.emailTempValue = this.emailValue;
			this.isEmailEditModeOn = false;
		},

		nameAndLastNameValidate(value){
			return nameAndLastNameValidator(value);
		},

		phoneValidate(value){
			return phoneValidator(value);
		},

		checkUserCardValidation() {
			const inputs = this.$refs;
			for (const key in inputs) {
				if (inputs[key].validatorResult && inputs[key].validatorResult.is) {
					this.$emit('userCard:valid', false);
					return;
				}
			}
			this.$emit('userCard:valid', true);
		}
	},


	computed: {
		...mapState('app', { appSide: 'side' }),
		...mapState('profile', { haveSuperPowers: 'is_super' }),


		// TODO: get from the props
		isAgencyEditable() {
			return this.appSide === 'ssp';
		},


		// TODO: do we need it?
		agencyList() {
			return [];
		},

		// TODO: do we need it?
		agencyOptions() {
			return this.agencyList.map(item => ({ value: item.id, label: item.name }));
		},
	},


	watch: {
		[MODEL.prop]: {
			deep: true,
			handler(value, oldValue) {
				if (this.instance !== value) {
					Vue.set(this, 'instance', value);
					Vue.set(this, 'emailValue', this.user.email);
					Vue.set(this, 'emailTempValue', this.user.email);
				}
			},
		},

		instance: {
			deep: true,
			handler(value, oldValue) {
				if (this[MODEL.prop] !== value) {
					this.$emit(MODEL.event, value);
				}
			},
		},
	},


	created() {
		Vue.set(this, 'instance', this.user);
	}
};
</script>

<style lang="sass" scoped>
.user-card
	margin-bottom: 24px

.user-data
	margin-bottom: 24px
	width: 100%
	margin-left: -15px

.user-col
	display: flex
	flex-direction: column
	flex-wrap: wrap
	width: 33.3%
	justify-content: flex-start

.title
	font-family: Roboto
	font-style: normal
	font-weight: normal
	font-size: 16px
	margin-bottom: 16px

.input-field
	margin-bottom: 24px

.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px
	justify-content: space-between


.detail
	display: flex
	flex-direction: column

.value
	display: flex
	align-items: center
	width: 100%
	line-height: 18px
	margin: (30px - 18px) / 2 0
	text-overflow: ellipsis

	&:not(.multiline)
		white-space: nowrap
		overflow: hidden

	& > .units,
	& > .shaded
		color: var(--text_2_color)

	& > *:not(:first-child)
		margin-left: .5em


.detail-huge
	& > .label
		font-size: 11px

	& > .value
		font-weight: 300
		font-size: 16px

.inline-edit-button
	max-height: 21px
	min-height: unset
	height: 21px
	box-sizing: border-box
	margin-left: 13px

</style>
