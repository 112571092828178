<template>
	<section class="nice-tabs">
		<header class="ni_tabs-header">
			<router-link
				v-for="(tab, index) in tabs"

				:key="index"
				class="ni_tabs-title"

				:to="`#${hashPrefix}${tab.slug}`"
				:event="tab.disabled ? '' : 'click'"
				:class="{
					'active': index === currentIndex,
				}"
				:disabled="tab.disabled"
			>
				{{ tab.name }}
			</router-link>
		</header>
		<hr class="line" />
		<nice-tab :vnode="filteredSlots[currentIndex]" class="ni_tabs-tab-body" />
	</section>
</template>


<script>
/**
 * NiceTabs
 *
 * attrs for tabs:
 *
 * data-ni_tab_slug
 * data-ni_tab_name
 * data-ni_tab_active
 * data-ni_tab_disabled
 *
 * TODO:
 * * [*] process second level tabs
 * * [ ] scroll to active from hash tab ?
 */

import _ from 'underscore';

import VnodeRenderer from '@/ui/vnode-renderer';

const COMPONENT_NAME = 'NiceTabs';
const SEPARATOR = ',';
const getAttr = (vnode, attr) => vnode.data && vnode.data.attrs ? vnode.data.attrs[attr] : null;


export default {
	name: COMPONENT_NAME,


	components: {
		NiceTab: VnodeRenderer,
	},


	data() {
		return {
			tabs: [],
			filteredSlots: [],
			lastIndex: null,
		};
	},


	computed: {
		/**
		 * Return hash prefix with hash from parents tabs groups
		 *
		 * @returns {string} prefix with parents hashes
		 */
		hashPrefix() {
			if (!this.parentTabs) {
				return '';
			}

			return `${this.parentTabs.hashPrefix}${this.parentTabs.tabs[this.parentTabs.currentIndex].slug}${SEPARATOR}`;
		},

		/**
		 * Return actual hash for current tabs group excluding parents and child hash parts
		 */
		currentSlug() {
			let result = this.$route.hash ? this.$route.hash.slice(1) : null;

			if (!result) {
				return null;
			}

			if (this.parentTabs) {
				result = result.replace(this.hashPrefix, '');
			}

			return result.split(SEPARATOR)[0];
		},

		/**
		 * Find default active tab
		 */
		activeByInitAttribute() {
			return _.findIndex(this.tabs, t => t.activeAttribute && !t.disabled);
		},

		/**
		 * Return active tab
		 */
		currentIndex() {
			let result = this.lastIndex;
			if (_.contains(_.pluck(_.filter(this.tabs, t => !t.disabled), 'slug'), decodeURIComponent(this.currentSlug))) {
				// по хешу
				result = _.findIndex(this.tabs, t => t.slug === decodeURIComponent(this.currentSlug));

			} else if (!result && this.activeByInitAttribute != -1) {
				// по атрибуту data-ni_tab_active
				result = this.activeByInitAttribute;

			} else if (!result) {
				// первый
				result = _.findIndex(this.tabs, t => !t.disabled);
			}

			return result;
		},

		/**
		 * Return parent NiceTabs component if exist
		 */
		parentTabs() {
			let parent = this.$parent;

			while(parent) {
				if (parent.$options.name === COMPONENT_NAME) {
					break;
				}

				parent = parent.$parent;
			}

			return parent;
		},
	},


	methods: {
		/**
		 * Return list of tabs
		 */
		generateTabs() {

			this.filteredSlots = _.filter(this.$slots.default, (vnode) => { return vnode.tag && !vnode.isComment; });

			this.tabs = _.map(this.filteredSlots, (vnode, index) => {
				return {
					index: index,
					name: this.calcName(vnode, index),
					slug: this.calcSlug(vnode, index),
					activeAttribute: getAttr(vnode, 'data-ni_tab_active') || getAttr(vnode, 'data-ni_tab_active') === '',
					disabled: getAttr(vnode, 'data-ni_tab_disabled') || getAttr(vnode, 'data-ni_tab_disabled') === '',
					vnode: vnode
				};
			});
		},

		/**
		 * Return tab name from attrs or make it
		 *
		 * @param {VNode} tab vnode
		 * @param {number} tab index
		 *
		 * @returns {string} name
		 */
		calcName(vnode, index) {
			return getAttr(vnode, 'data-ni_tab_name') || `Tab No${index+1}`;
		},

		/**
		 * Calc and return tab slug
		 *
		 * @param {VNode} tab vnode
		 * @param {number} tab index
		 *
		 * @returns {string} tab slug
		 */
		calcSlug(vnode, index) {
			return getAttr(vnode, 'data-ni_tab_slug') || this.calcName(vnode, index).toLowerCase().replace(/\s+/igm, '-').replace(SEPARATOR, '-');
		},
	},


	watch: {
		currentIndex(newValue, oldValue) {
			this.lastIndex = newValue;
		},
	},


	created() {
		this.generateTabs();
	},


	beforeUpdate() {
		this.generateTabs();
	},
};
</script>

<style lang="sass" scoped >
.nice-tabs
	margin-left: 0
	margin-right: 0
	width: 100%

	// wtf? too complex selector
	// > .ni_tabs-tab-body ::v-deep > *:not(.nice-tabs):not(.layout_block-full_width)
	// 	@extend %layout-full_width-block

.ni_tabs-tab-body:not(.layout-center805)::v-deep > *:not(.nice-tabs)
	@extend %layout-full_width-block

.ni_tabs-header
	display: flex
	flex-direction: row
	// flex-wrap: wrap
	justify-content: flex-start
	align-items: flex-end
	align-content: flex-end

	padding: 0 $indent_x

	color: var(--text_2_color)
	line-height: 34px
	font-size: $fsz__section-title

.ni_tabs-title
	position: relative
	display: block

	cursor: pointer
	color: $clr__gray
	text-decoration: none

	&:hover
		color: var(--main_color)

	&:not(:first-child)
		margin-left: 36px

	&.active
		color: $clr__default
		cursor: default

		&::after
			content: ""
			display: block
			position: absolute
			background-color: $clr__default
			right: -6px
			bottom: -1px
			left: -6px
			height: 2px

	&[disabled]
		cursor: default
		text-decoration: linethrough

.line
	display: block
	border-top: none
	border-left: none
	border-bottom: 1px solid $clr__gray
	border-right: none

	margin: 0 0 21px

</style>
