<template>
	<symbol :id="`${prefix}calendar`" viewBox="0 0 13 14">
		<path
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			d="M9.75,13.5h-6.5c-1.52,0-2.75-1.23-2.75-2.75v-6.5c0-1.52,1.23-2.75,2.75-2.75h6.5c1.52,0,2.75,1.23,2.75,2.75 v6.5C12.5,12.27,11.27,13.5,9.75,13.5z"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="0.5"
			y1="5.5"
			x2="12.5"
			y2="5.5"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="3.5"
			y1="0"
			x2="3.5"
			y2="3"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="9.5"
			y1="0"
			x2="9.5"
			y2="3"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="4.5"
			y1="6"
			x2="4.5"
			y2="13"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="8.5"
			y1="6"
			x2="8.5"
			y2="13"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="0.5"
			y1="9.5"
			x2="12.5"
			y2="9.5"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconCalendar',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.gray,
				main__disabled: this.$palette.gray__disabled,

				...this.palette,
			};
		},

		strokeWidth() {
			if (this.params && this.params.strokeWidth) {
				return this.params.strokeWidth;
			}
			return 2;
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
