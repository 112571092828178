<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 12 14">
		<path
			:fill="disabled ? colors.disabled : colors.main"

			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 3V2.375C9 1.06543 7.93457 0 6.625 0H5.375C4.06543 0 3 1.06543 3 2.375V3H0V4H1V11.083C1 12.6914 2.30859 14 3.91699 14H8.08301C9.69141 14 11 12.6914 11 11.083V4H12V3H9ZM4 2.375C4 1.6167 4.61719 1 5.375 1H6.625C7.38281 1 8 1.6167 8 2.375V3H4V2.375ZM10 11.083C10 12.1396 9.13965 13 8.08301 13H3.91699C2.86035 13 2 12.1396 2 11.083V4H10V11.083ZM5 6H4V11H5V6ZM7 6H8V11H7V6Z"
		/>
	</symbol>
</template>

<script>
export const slug = 'trash';


export default {
	name: 'NiIconTrash',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.red,
				disabled: this.$palette.red__disabled,
				...this.palette,
			};
		},
	},
};
</script>
