<template>
	<symbol :id="`${prefix}arrow_circle_2`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" class="base" />
		<path d="M9.00024 7.82849L9.00024 12.0356L7.00024 12.0356L7.00024 7.82858L5.39673 9.4321L3.98252 8.01788L8.00029 4.00011L12.0181 8.01788L10.6039 9.4321L9.00024 7.82849Z" class="sign arrow"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrowCircle2',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-blue})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-white})

.arrow
	transform: rotate3d(0, 0, 1, calc(180deg + var(--ni-icon-state, 0) * 1deg))
	transform-origin: center
</style>
