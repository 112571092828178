<template>
	<symbol :id="`${prefix}arrow_circle-34`" viewBox="0 0 34 34">
		<circle cx="17" cy="17" r="16" class="sign" stroke-width="2" fill="none"/>
		<g class="arrow">
			<circle cx="17" cy="17" r="16" stroke="none" fill="none"/>
			<path
				d="M14,17 17,20 M17,14 17,19 M20,17 17,20"
				stroke-width="2"
				stroke-linecap="square"
				class="sign"
			/>
		</g>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrowCircle34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: center
</style>
