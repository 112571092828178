var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nice-slider",class:{
		'not_choiced': !_vm.valueSetted,
		'active': _vm.drag.startX,
		'labeled': _vm.labeled || _vm.label,
		'label_holder': _vm.labeled && !_vm.label,
	},style:({
		'--marker-shift': ((this.positionCurrent) + "%"),
	})},[(_vm.label)?_c('nice-label',{staticClass:"ni_slider--label",attrs:{"label":_vm.label,"hint":_vm.hint}}):_vm._e(),(!_vm.hideValue)?_c('label',{staticClass:"ni_slider--input",attrs:{"type":"text","disabled":""},domProps:{"innerHTML":_vm._s(_vm.currentItemByCurrentPosition.label || _vm.currentItemByCurrentPosition.value)}}):_vm._e(),_c('div',{ref:"bar",staticClass:"ni_slider--bar"},[_c('span',{staticClass:"ni_slider--marker",on:{"mousedown":_vm.onDragStart}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }