<template>
	<tr>
		<th>
			{{ $t('inventory_modal.th_rule_type') }}
		</th>

		<th
			v-for="field in fieldsList"
			:key="field.key"
		>
			<th-ordering-button
				:name="field.key"
				:descending="descending"
				:orderBy="orderBy"
				:disabled="disabled"
				@ordering="handleButtonClick"
			>{{ field.caption }}</th-ordering-button>
		</th>
	</tr>
</template>


<script>
import ThOrderingButton from './th-ordering-button';

export default {
	name: 'SSPViewerInventoryTableHeaderRow',

	components: {
		ThOrderingButton,
	},

	model: {
		prop: 'ordering',
		event: 'update:ordering',
	},

	props: {
		ordering: {
			type: Object,
			default: () => ({
				orderBy: null,
				descending: null,
			}),
		},

		disabled: Boolean,
	},

	data() {
		return {
			orderBy: this.ordering.orderBy,
			descending: this.ordering.descending,
		};
	},


	computed: {

		fieldsList() {
			return [
				{ key: 'location_name', caption: this.$t('inventory_modal.th_location_name') },
				{ key: 'city', caption: this.$t('inventory_modal.th_city') },
				{ key: 'street', caption: this.$t('inventory_modal.th_address') },
				{ key: 'name', caption: this.$t('inventory_modal.th_device') },
				{ key: 'resolution', caption: this.$t('inventory_modal.th_specs') },
				{ key: 'uptime', caption: this.$t('inventory_modal.uptime') },
			];
		},

	},


	methods: {

		handleButtonClick(orderBy) {
			this.descending = orderBy === this.orderBy && !this.descending;
			this.orderBy = orderBy;

			this.$emit('update:ordering', {
				orderBy,
				descending: this.descending,
			});
		},

	},
};
</script>
