// area popup
export const ACTION_EXCLUDE = 'exclude';
export const ACTION_INCLUDE = 'include';
export const DEFAULT_RADIUS = 10;

// sources ID's
export const SOURCE_ID = 'devices';
export const AREAS_SOURCE_ID = 'selected_areas';
export const AREAS_CENTERS_SOURCE_ID = 'selected_areas_centers';

// 5 - Large islands / Large rivers
// 10 - Large roads
// 15 - Buildings
export const MAPBOX_CENTER_ZOOM = 10;
export const MAPBOX_CENTER_SPEED = 1;
// lng, lat
export const MAPBOX_CENTER_GC_SPb = [30.260494351387027, 59.943620275388454];
export const MAPBOX_CENTER_MSK = [36.8251419, 55.5815245];

// 1 - 19
export const YANDEX_CENTER_ZOOM = 6;
export const YANDEX_CENTER_MSK = [37.646075, 55.728853];


// sources configs
export const SOURCE = {
	type: 'geojson',
	// data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson",
	cluster: true,
	clusterMaxZoom: 14, // Max zoom to cluster points on
	clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
	// TODO: is there a better way?
	clusterProperties: {
		// not working
		// 'ids': [
		// 	'concat',
		// 	['literal', ','],
		// 	['to-string', ['id']]
		// ],
		'deployed-points': [
			'+',
			[
				'match',
				['get', 'state'],
				'deployed', 1,
				'white', 1,
				0
			]
		],
		'filtered-points': [
			'+',
			[
				'match',
				['get', 'state'],
				'deployed', 0,
				'white', 0,
				1
			]
		],
	},
};

//
// layers configs
//
// properties https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
// operators/expressions https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
//

// How to debug clusters
// source = $vm.map.getSource('devices')
// features = $vm.map.queryRenderedFeatures({ layers: ['clusters_layer'] })


export const CLUSTER_MARKER_LAYER = {
	id: 'clusters_layer',
	type: 'symbol',
	source: SOURCE_ID,
	filter: ['has', 'point_count'],
	layout: {
		'icon-image': [
			'case',
			['==', ['get', 'deployed-points'], ['get', 'point_count']], 'device-cluster',
			['==', ['get', 'filtered-points'], ['get', 'point_count']], 'device-cluster-filtered',
			'device-cluster-mixed'
		],
		'icon-offset': [0, -17],

		'text-field': '{point_count_abbreviated}',
		'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
		'text-size': 12,
		'text-offset': [-0.04, -1.73],
	}
};

export const DEVICE_LAYER = {
	id: 'devices_layer',
	type: 'symbol',
	source: SOURCE_ID,
	filter: ['!', ['has', 'point_count']],
	layout: {
		'icon-image': [
			'match',
			['get', 'state'],
			'black',
			'device-marker-exclude',
			'white',
			'device-marker-include',
			'not_deployed',
			'device-marker-filtered',
			'filtered',
			'device-marker-filtered',
			'device-marker'
		],
		'icon-offset': [0, -14],
		'icon-allow-overlap': true,
	}
};

export const AREAS_LAYER = {
	id: 'selected_areas_layer',
	type: 'fill',
	source: AREAS_SOURCE_ID,
	filter: ['has', 'action'],
	paint: {
		'fill-color': [
			'match',
			['get', 'action'],
			'include',
			'#2dc3b7',
			'exclude',
			'#ff6236',
			'#a2a2a2',  //TODO: add new icon and action
		],
		'fill-antialias': true,
		'fill-outline-color': 'rgba(0,0,0,0)',
		'fill-opacity': 0.2,
	},
};
export const AREAS_CENTERS_LAYER = {
	id: 'selected_areas_centers_layer',
	type: 'symbol',
	source: AREAS_CENTERS_SOURCE_ID,
	filter: ['has', 'action'],
	layout: {
		'icon-image': 'device-marker-{action}',
		'icon-offset': [0, -14],
		'icon-allow-overlap': true,
	}
};


/**
 * Generate circle coordinates
 *
 * @param {float[2]} coordinates - [longitude, latitude]
 * @param {float|integer} radius in Km
 * @param {int} amount of points (default: 128)
 *
 * @returns {float[[2]]} list of coordinates
 */
export function drawCircle(coordinates, radiusInKm, points=128) {
	const coords = {
		longitude: coordinates[0],
		latitude: coordinates[1],
	};

	const ret = [];
	const distanceX = radiusInKm / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
	const distanceY = radiusInKm / 110.574;

	let theta, x, y;
	for (var i=0 ; i<points ; i++) {
		theta = (i / points) * (2 * Math.PI);
		x = distanceX * Math.cos(theta);
		y = distanceY * Math.sin(theta);

		ret.push([coords.longitude+x, coords.latitude+y]);
	}

	ret.push(ret[0]);

	return [ret];
}
