<template>
	<symbol :id="`${prefix}trash-bold`" viewBox="0 0 13 14">
		<path
			d="M9 6H7V11H9V6Z"
			:fill="getColor('main')"
		/>
		<path
			d="M4 6H6V11H4V6Z"
			:fill="getColor('main')"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 3V2.5C9 1.12158 7.87891 0 6.5 0C5.12158 0 4 1.12158 4 2.5V3H0V5H1V11C1 12.6543 2.3457 14 4 14H9C10.6543 14 12 12.6543 12 11V5H13V3H9ZM6 2.5C6 2.22412 6.22412 2 6.5 2C6.77588 2 7 2.22412 7 2.5V3H6V2.5ZM10 11C10 11.5518 9.55176 12 9 12H4C3.44873 12 3 11.5518 3 11V5H10V11Z"
			:fill="getColor('main')"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconTrashBold',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.graysemidark,
				main__disabled: this.$palette.graysemidark__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
