/**
 * Agency documents
 */
import store from '@/store';
import baseRest from './base/rest';


const getPrefix = () => `agency/${store.state.app.agency}/${store.state.app.side}/document/`;

// TODO: return it as is?
const baseAdvertiserApi = baseRest(getPrefix);


export default {
	getPrefix,

	/**
	 * Request documents list
	 *
	 * @returns {Object[]} list of documents
	 */
	'list': baseAdvertiserApi.list,

	/**
	 * Retrieve a document
	 *
	 * @param {number} id
	 * @param {object} config
	 */
	'retrieve': baseAdvertiserApi.retrieve,

};
