<template>
	<symbol :id="`${prefix}arrow_circle`" viewBox="0 0 16 16">
		<path d="M7 8.20716V4H9V8.20706L10.6035 6.60355L12.0177 8.01776L7.99995 12.0355L3.98218 8.01776L5.39639 6.60355L7 8.20716Z" class="sign arrow" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrowCircle',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: center
</style>
