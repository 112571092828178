<template>
	<symbol :id="`${prefix}check`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" class="base" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7072 7.20718L7.50008 11.4143L4.29297 8.20718L5.70718 6.79297L7.50008 8.58586L10.293 5.79297L11.7072 7.20718Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolCheck',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-green})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-white})
</style>
