<template>
	<div
		class="thumb-holder"
		@mouseenter="hover = true"
		@mouseleave="hover = false"
		@click="$emit('interact', $event)"
	>
		<span
			class="circle"
			@click.stop="$emit('interact', $event)"
		>
			{{ char }}
		</span>

		<div class="text-content">
			<span
				:class="{
					deleted: is_deleted
				}"
				class="title"
				@click.stop="$emit('interact', $event)"
			>
				<template v-if="last_name" >{{ first_name }} <b>{{ last_name }}</b></template>
				<template v-else ><b>{{ first_name }}</b></template>
				<template v-if="roles.length" > ({{ roles_display }})</template>
				<template v-if="is_deleted" > ({{ $t('users.deleted') }})</template>
			</span>
			<span class="subtitle">{{ detail }}</span>
		</div>


		<!-- remove/restore buttons -->
		<div
			v-if="isRemoveAllowed"
			class="button-holder"
		>
			<nice-button-2
				v-if="!is_deleted"
				v-show="hover"
				class="button"
				palette="gray"
				icon="basket-14"
				icon-painted
				@click="$emit('remove', $event)"
			/>

			<nice-button-2
				v-if="is_deleted"
				v-show="hover"
				class="button"
				palette="gray"
				icon="plus-24"
				icon-painted
				@click="$emit('restore', $event)"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NiceUserThumb',

	data() {
		return {
			hover: false,
		};
	},

	props: {
		first_name: {
			type: String,
			required: true,
		},

		last_name: {
			type: String,
			default: '',
		},

		/**
		 * Backend returns roles for a specified agency
		 * so these roles MUST be in a context
		 */
		roles: {
			type: Array,
			default: () => [],
		},

		detail: {
			type: String,
			required: true,
		},

		is_deleted: {
			type: Boolean,
			required: false,
			default: false,
		},

		isRemoveAllowed: {
			type: Boolean,
			default: true
		},
		// id: {
		// 	type: [Number, String],
		// 	required: true,
		// },
	},


	computed: {
		char() {
			return this.last_name ? this.last_name.substr(0,1) : this.first_name.substr(0,1);
		},

		/**
		 * Comma-separated list
		 */
		roles_display() {
			return this.roles.map((r) => this.$t(`invitation.${r}`)).join(', ');
		},
	},

};
</script>

<style lang="sass" scoped>
.thumb-holder
	display: flex
	flex-direction: row
	align-items: center
	border-radius: 10px
	padding: 15px 10px

	&:hover
		background-color: $nice_color-graylightest


	& > *:not(:first-child)
		margin-left: 15px

.circle
	display: block
	border-radius: 50%
	border: 1px solid currentColor
	width: 36px
	height: 36px
	text-align: center
	line-height: 34px
	color: #cccccc
	flex-shrink: 0
	font-size: 16px

	cursor: pointer



.text-content
	margin-right: auto



.title
	display: block
	font-size: 13.5px
	cursor: pointer

	.circle:hover + .text-content > &,
	&:hover
		text-decoration: underline

.subtitle
	display: block
	color: #cccccc
	font-size: 12px
	cursor: default

.button-holder
	width: 36px

.deleted
	color: $nice_color-red

</style>
