<template>
	<div
		class="container"
		:class="{ selected: selected }"
	>
		<nice-label
			:label="label"
		/>
		<div class="value" v-html="value"></div>
	</div>
</template>

<script>
import NiceLabel from '@/ui/nice-label';


export default {
	name: 'NiceStatsValue',

	components: {
		NiceLabel,
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		value: {
			type: [Number, String],
			required: true,
		},
		selected: {
			type: Boolean,
			default: false,
		}
	},

};
</script>

<style lang="sass" scoped>
.container
	width: 130px
	height: 65px
	background: #FFFFFF
	border: 1px solid #DDDDDD
	box-sizing: border-box
	border-radius: 10px
	padding-left: 12px
	padding-top: 4px

	&.selected
		background: var(--bgcolor,#F0ECFC)
		border: 1px solid var(--bordercolor,#7C5ECF)

.value
	margin-top: 2px
	font-family: Roboto
	font-style: normal
	font-weight: normal
	font-size: 13.5px
	line-height: 14px
	color: #000000

</style>
