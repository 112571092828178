<template>
	<symbol :id="`${prefix}check-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15.7072 11.2072L11.5001 15.4143L8.29297 12.2072L9.70718 10.793L11.5001 12.5859L14.293 9.79297L15.7072 11.2072Z" class="sign"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolCheck24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-green})
	fill-opacity: 0.2

.sign
	fill: var(--ni-icon-sign, #{$nice_color-green})
</style>
