<template>
	<section class="page-section dev-page_nice-switch" >
		<h1>NiceWizard2</h1>

		<nice-wizard-2>
			<section>
				step 1
			</section>
			<section>
				step 2
			</section>
			<section>
				step 3
			</section>
		</nice-wizard-2>
	</section>
</template>

<script>
import NiceWizard2 from '@/ui/nice-wizard-2';


export default {
	name: 'TabMenuContext',


	components: {
		NiceWizard2,
	},
};
</script>


<style lang="sass" scoped >
</style>
