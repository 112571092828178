<template>
	<div>
		<div class="cl-container cl-pad-no_sides agency-card">
			<div class="cl-row">

				<!-- <contract-card
					ref="contract_card"
					:contract="instance.contract"
					:edit="edit"
					class="cl-xs6 cl-md4 cl-pad"
					@update="updateContract"
				/> -->


				<template
					v-if="editAll"
				>
					<div class="cl-xs6 cl-md3 cl-pad text-fields-wrapper">
						<nice-input
							v-model="instance.phone"
							:label="$t('phone') | capitalize"
							:placeholder="$t('enter_phone') | capitalize"
						/>

						<nice-input
							v-model="instance.address"
							:label="$t('address') | capitalize"
							:placeholder="$t('enter_address') | capitalize"
						/>

						<div class="detail balance">
							<nice-label
								:label="$t('balance') | capitalize"
							/>
							<div class="value">
								<span>{{ $options.filters.formatNumber(instance.balance, decimal=2, separator=' ') || 0 }}</span>

								<nice-button-2
									icon="plus-17"
									@click="showAddBalancePopup"
								/>
							</div>
						</div>

						<div class="detail balance">
							<nice-label
								:label="$t('currency') | capitalize"
							/>
							<div class="value">
								<span>{{ instance.currency }}</span>
							</div>
						</div>

					</div><!-- END: left column -->

					<div class="cl-xs6 cl-md3 cl-pad text-fields-wrapper">
						<nice-input
							v-model="instance.email"
							:label="$t('email') | capitalize"
							:placeholder="$t('enter_email') | capitalize"
						/>
					</div><!-- END: right column -->
				</template>

				<template v-else >
					<div class="cl-xs6 cl-md3 cl-pad text-fields-wrapper">

						<div class="detail">
							<nice-label
								:label="$t('phone') | capitalize"
							/>
							<div class="value">{{ instance.phone || '—' }}</div>
						</div>

						<div class="detail">
							<nice-label
								:label="$t('address') | capitalize"
							/>
							<div class="value">{{ instance.address || '—' }}</div>
						</div>

						<template
							v-if="isSuperSSP"
						>
							<div class="detail">
								<nice-label
									:label="$t('balance') | capitalize"
								/>
								<div class="value">{{ $options.filters.formatNumber(instance.balance, decimal=2, separator=' ') || 0 }}</div>
							</div>

							<div class="detail">
								<nice-label
									:label="$t('currency') | capitalize"
								/>
								<div class="value">{{ instance.currency || '—' }}</div>
							</div>
						</template>
					</div><!-- END: left column -->

					<div class="cl-xs6 cl-md3 cl-pad text-fields-wrapper">
						<div class="detail">
							<nice-label
								:label="$t('email') | capitalize"
							/>
							<div class="value">{{ instance.email || '—' }}</div>
						</div>
					</div><!-- END: right column -->

				</template>


			</div>
		</div>
	</div>
</template>

<script>
import _ from 'underscore';
import Vue from 'vue';
import { mapState } from 'vuex';

import { addChildAgencyBalance } from '@/api/agency';

import NiceInput from '@/ui/nice-input';
// import ContractCard from './contract-card';
import { convertStringToFloat } from '@/utilites';

const MODEL = Object.freeze({
	prop: 'agency',
	event: 'update',
});


export default {
	name: 'AgencyCard',


	components: {
		NiceInput,
		// ContractCard,
	},


	model: MODEL,


	props: {
		[MODEL.prop]: {
			type: Object,
			required: true,
		},

		// true - SSP editing child DSP agency
		// false - DSP or SSP viewing its card
		edit: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		let inst = this[MODEL.prop];

		return {
			instance: this._augmentAgency(inst),
		};
	},

	computed: {
		...mapState('profile', {
			isSuperSSP: 'is_super',
		}),

		/**
		 * Super SSP can edit everything
		 * SSP can assign networks
		 */
		editAll() {
			return this.edit && this.isSuperSSP;
		},
	},

	methods: {
		updateContract(contract) {
			Vue.set(this.instance, 'contract', contract);
		},

		reset() {
			// we need to reset selected file in the `contract_card`
			if (this.$refs.contract_card) {
				this.$refs.contract_card.reset();
			}
		},

		/**
		 * Add empty contract stub if not in the agency yet
		 */
		_augmentAgency(agency) {
			if (!agency.contract) {
				agency.contract = {};
			}
			return agency;
		},

		async showAddBalancePopup() {
			let amount = convertStringToFloat(window.prompt(this.$t('enter_an_amount_to_add'), 0));

			if (!amount) {
				return;
			}

			try {
				let res = await addChildAgencyBalance(this.instance.id, amount);

				this.$set(this.instance, 'balance', res.balance);
			}
			catch (err) {
				this.$log.error(err);
				window.alert(this.$t('failed_to_add_balance'));
			}
		},
	},


	watch: {
		[MODEL.prop]: {
			deep: true,
			handler(value) {
				if (!_.isEqual(this.instance, value)) {
					Vue.set(this, 'instance', this._augmentAgency(value));
				}
			},
		},

		instance: {
			deep: true,
			handler(value) {
				if (!_.isEqual(this[MODEL.prop], value)) {
					this.$emit(MODEL.event, value);
				}
			},
		},
	},
};
</script>

<style lang="sass" scoped>
.text-fields-wrapper
	& > *:not(:last-child)
		margin-bottom: 16px

.detail
	display: flex
	flex-direction: column

	&.no-label
		padding-top: 24px

.value
	display: flex
	align-items: center
	line-height: 30px

	white-space: nowrap
	text-overflow: ellipsis

	&.multiline
		white-space: unset

	& > .icon
		margin-right: 10px

.detail.balance

	.value
		flex-direction: row

		button
			margin-left: 20px


</style>
