<template>
	<main class="layout-full_width">
		<h1 class="title-error">
			{{ $t('errors.error') | capitalize }} 404 <span v-html="symbol" />
		</h1>

		<p class="error-mesage">
			{{ $t('errors.not_found') }}

			<br><br>

			<nice-button-2
				@click="$router.push({name: 'login'})"
			>
				{{ $t('errors.to_the_main_page') }}
			</nice-button-2>
		</p>

	</main>
</template>

<script>
import _ from 'underscore';


export default {
	name: 'pageError404',

	computed: {
		symbol() {
			return `&#128373;${['&#127995;', '&#127997;', '&#127998;', '&#127999;'][_.random(3)]}&zwj;${['&#9792;', '&#9794;'][_.random(1)]}`;
		},
	},
};
</script>
