<template>
	<div>
		<div class="top-panel">
			<nice-input
				v-model="filter.name"
				class="search-input"
				:placeholder="$t('search')"
			>
				<nice-icon
					icon="search"
					class="search-icon"
				/>
			</nice-input>
		</div>

		<table class="nice-table" >
			<thead>
				<tr>
					<th>{{ $t('access') | capitalize}}</th>
					<th>{{ $t('name') | capitalize}}</th>
					<th>{{ $t('points') | capitalize}}</th>
					{{ /*
					TODO: Галеев: «скидка» - это корректировка цены обратно, в минус.
					      АК: сколько получает сам франчайзи за вычетом комиссии владельца?
								Галеев: да
					*/ }}
					<!-- <th>{{ $t('extra_charge') | capitalize}}</th> -->
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="platform in filteredPlatformsListWithExtras /* available platforms */"
					:key="platform.id"
				>
					<!-- access -->
					<td
						class="cell-access" >
						<NiceActionList
							:status="~~platform.access"
							:list="accessStatuses"
							hide-on-click
							icons-in-list

							class="action-list"

							@change="changeAccess(platform.id, $event)"
						/>
						{{ /* available platform listed in agency's platforms */ }}
					</td>

					<!-- name -->
					<td class="cell-name">
						{{ platform.name }}
					</td>

					<!-- devices count -->
					<td class="cell-points">
						<nice-button-2
							icon="pencil-24"
							:class="{
								invisible: platform.access == 0
							}"
							@click="editPlatformDeviceList(platform)"
						/>
						<span>
							{{ platform.device_selected_count }} / {{ platform.device_count }}
						</span>
					</td>

					<!-- discount
					<td
						class="cell-fee"
					>
						<toggling-input
							v-if="platform.access"
							:value="platform.extra_charge || 0"
							:placeholder="$t('enter fee') | capitalize"
							type="number"
							min="0"
							max="1000"
							class="fee-input"
							units="%"
							@input="updateFee(platform.id, $event)"
						/>
						<template v-else>{{ $t('n_a') }}</template>
					</td>
					-->
				</tr>
			</tbody>
		</table>
	</div>
</template>


<script>
import _ from 'underscore';

import NiceInput from '@/ui/nice-input';
import NiceActionList from '@/ui/nice-action-list';

// import TogglingInput from './toggling-input';


const MODEL = Object.freeze({
	prop: 'platforms',
	event: 'update',
});


const ACCESS_OPTIONS_LIST = [
	{
		value: 0,
		icon: 'error',
		label: 'no access',
		labelAction: 'deny access',
	},
	{
		value: 1,
		icon: 'check',
		label: 'has access',
		labelAction: 'allow access',
	},
];


export default {
	name: 'SSPViewerPlatformsList',

	model: MODEL,

	components: {
		NiceInput,
		NiceActionList,
		// TogglingInput,
	},


	props: {
		/**
		 * v-model prop
		 * ssp viewer's platforms
		 */
		[MODEL.prop]: {
			type: Array,
			required: true,
		},

		/**
		 * SSP Viewer we operate on
		 *
		 * TODO: do we need it?
		 */
		user: {
			type: Object,
			required: true,
		},

		/**
		 * SSP Agency we operate on
		 */
		agency: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {

			/**
			 * Inner value - ssp viewer's platform list
			 * @type {obect[]}
			 */
			innerPlatforms: this[MODEL.prop],

			/**
			 * The filter/search word
			 * @type {string}
			 */
			filter: {
				name: '',
				allowed_only: false,
			},
		};
	},


	computed: {

		/**
		 * SSP Agency Networks
		 */
		availablePlatforms() {
			return this.agency.ssp_networks;
		},

		accessStatuses() {
			return ACCESS_OPTIONS_LIST.map(option => ({
				value: option.value,
				icon: option.icon,
				label: this.$options.filters.capitalize(this.$t(option.label)),
				labelAction: this.$options.filters.capitalize(this.$t(option.labelAction)),
			}));
		},

		/**
		 * List of available platforms with applied extra staff
		 * eg 'access', 'fee' for rendering
		 *
		 * @return {object[]} platforms list
		 */
		platformsListWithExtras() {
			return this.availablePlatforms
				.map(platform => {
					let agencyPlatform = this.innerPlatforms.find(agencyPlatform => agencyPlatform.id === platform.id);

					const result = {
						...platform,

						// TODO: not `extra_charge` but `discount`?
						extra_charge: 0,
						access: 0,
						device_selected_count: 0,
					};

					if (agencyPlatform) {
						result.access = 1;
						result.extra_charge = agencyPlatform.extra_charge || 0;
						result.device_selected_count = agencyPlatform.device_selected_count;
					}

					return result;
				});
		},

		/**
		 * Filtered list of `platformsListWithExtras`
		 *
		 * @return {object[]} platforms list
		 */
		filteredPlatformsListWithExtras() {
			if (!(this.filter.name || this.filter.allowed_only)) {
				return this.platformsListWithExtras;
			}

			const allowFilter = this.filter.allowed_only;
			const nameFilter = this.filter.name;

			return this.platformsListWithExtras.filter(platform => {
				const passAllow = !allowFilter || platform.access;
				const passName = !nameFilter || platform.name.toLowerCase().search(nameFilter.toLowerCase()) !== -1;
				return passAllow && passName;
			});
		},
	},


	methods: {
		changeAccess(id, status) {
			// TODO: cloning doesn't work:)
			let platforms = this.innerPlatforms.slice();

			let platform = platforms.find(platform => platform.id === id);

			const index = platforms.indexOf(platform);

			if (status === 0 && index !== -1) { // remove
				platforms.splice(index, 1);
			}
			else if (status === 1 && index === -1) { // add
				platform = this.availablePlatforms.find(platform => platform.id === id);
				platforms.push(platform);
			}

			this.$set(this, 'innerPlatforms', platforms);
		},


		platformSSPAgency(platform) {
			return platform?.ssp_agency?.name || '—';
		},


		/**
		 * Open modal window to edit ssp viewer devices list
		 */
		editPlatformDeviceList(platform) {
			// disable edit for a platform w/o access
			if (platform.access == 0) {
				return;
			}

			this.$router.push({
				name: 'user-ssp-viewer-network-devices',
				params: {
					id: this.user.id,
					agencyId: this.agency.id,
					networkId: platform.id,
				},
			});
		},
	},


	watch: {
		[MODEL.prop]: {
			deep: true,
			handler(value) {
				if (!_.isEqual(this.innerPlatforms, value)) {
					this.$set(this, 'innerPlatforms', value);
				}
			},
		},

		innerPlatforms: {
			deep: true,
			handler(value) {
				if (!_.isEqual(this[MODEL.prop], value)) {
					this.$emit(MODEL.event, value);
				}
			},
		},
	},
};
</script>

<style lang="sass" scoped>

tr
	display: flex
	flex-direction: row

	td,
	th
		display: flex
		flex-direction: row
		align-items: center
		flex: 1 1

.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px

.nice-table
	width: 100%
	margin-bottom: 24px

.search-input
	width: 200px
	margin-left: 12px


.cell-access
	& > .action-list
		width: 150px

		// align dropdown button vertically
		::v-deep > div > div
				display: flex

.cell-name
	color: #000000

.cell-points

	.nice-button
		margin-right: 10px

.invisible
	visibility: hidden

.action-list
	width: 100%

.fee-input
	width: 175px

	&::v-deep > .nice-input
		width: 60px

		input
			text-align: right

</style>
