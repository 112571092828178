/**
	The name of partial is the messages set namespace's name
	Import all partials here. Then provide all of them
	to `mapPartedMessages` @ export section.
	Finally you should get `messages` object with
	following structure

	```
	messages: {
		[locale]: {
			[namespace]: {
				[key]: <localized message>,
				...
			},
			...

			// root namespace
			[key]: <localized message>,
			...
		},
		...
	}
	```

	Use `root` namespace to push messages
	to the top layer (no namespace)

	WARNING: dont use `key` in root namespace equal another namespace!
 */

import agency from './partials/agency.js';
import advertiser from './partials/advertiser.js';
import billing from './partials/billing.js';
import campaign from './partials/campaign.js';
import common from './partials/common.js';
import creative_specification from './partials/creative-specification.js';
import dashboard from './partials/dashboard.js';
import documents from './partials/documents.js';
import errors from './partials/errors.js';
import request_list from './partials/request-list.js';
import payment_models from './partials/payment-models.js';
import ssp from './partials/ssp.js';
import users from './partials/users.js';
import invitation from './partials/invitation.js';
import notifications from './partials/notifications.js';
import inventory_modal from './partials/inventory-modal.js';
import transactions from './partials/transactions.js';
import two_factor_auth from './partials/two-factor-auth.js';
import reset_password from './partials/reset-password.js';
import registration from './partials/registration.js';
import map from './partials/map.js';
import root from './partials/root.js'; // root messages === no namespace


/**
 * Transform parted messages
 *      ```
 *      partedMessages = {
 *      	[namespace]: {
 *      		[key]: <localized message>,
 *      		...
 *      	},
 *      	...
 *      }
 *      ```
 * ... into messages ready to be used by vue-i18n
 *      ```
 *      messages: {
 *      	[locale]: {
 *      		[namespace]: {
 *      			[key]: <localized message>,
 *      			...
 *      		},
 *      		...
 *
 *      		// root namespace
 *      		[key]: <localized message>,
 *      		...
 *      	},
 *      	...
 *      }
 *
 * @param  {Object} partedMessages - parted messages
 * @return {Object} - messages set for vue-i18n
 */
function mapPartedMessages(partedMessages) {
	return Object.keys(partedMessages).reduce((messages, namespace) => {
		const part = partedMessages[namespace];
		Object.keys(part).forEach(key => {
			const phrase = part[key];
			Object.keys(phrase).forEach(locale => {
				if (!Object.prototype.hasOwnProperty.call(messages, locale)) {
					messages[locale] = {};
				}

				if (!Object.prototype.hasOwnProperty.call(messages[locale], namespace)) {
					messages[locale][namespace] = {};
				}

				if (namespace === 'root') {
					// use root partial === no namespace
					messages[locale][key] = phrase[locale];
				} else {
					messages[locale][namespace][key] = phrase[locale];
				}
			});
		});

		return messages;
	}, {});
}


export const messages = mapPartedMessages({
	agency,
	advertiser,
	billing,
	campaign,
	common,
	creative_specification,
	dashboard,
	documents,
	errors,
	request_list,
	payment_models,
	ssp,
	users,
	invitation,
	notifications,
	inventory_modal,
	transactions,
	two_factor_auth,
	reset_password,
	registration,
	map,
	root, // no namespace
});
