<template>
	<symbol :id="`${prefix}chat`" viewBox="0 0 23 21">
		<path
			:fill="getColor('main')"
			stroke="none"
			d="M23,21.01l-12.07-0.02C4.91,20.99,0,16.29,0,10.5C0,4.72,4.91,0.01,10.93,0.01c6.03,0,10.93,4.71,10.93,10.49 c0,2.53-0.95,4.95-2.67,6.86L23,21.01z M10.93,1.01c-5.45,0-9.89,4.26-9.89,9.49s4.44,9.49,9.89,9.49l9.55,0.01l-2.73-2.62 l0.35-0.35c1.76-1.78,2.73-4.1,2.73-6.53C20.83,5.27,16.39,1.01,10.93,1.01z"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconChat',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.darkgray,
				main__disabled: this.$palette.darkgray__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
