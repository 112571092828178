import { getPrefix } from './index';
import { request as apiRequest } from '../request';


export const getDeviceList = ({page=1, page_size=20, extended=true, platforms=[], ...options}={}, config) => {
	let platformsStr = platforms;
	if (platforms != 'all') {
		platformsStr = platforms.join(',');
	}

	const payload = {
		page,
		page_size,
		extended,
		platforms: platformsStr,
		...options,
	};
	return apiRequest(`${getPrefix()}device/`, payload, 'get');
};

export const getDeviceBlackWhiteList = ({page=1, page_size=20, extended=true, platforms=[], ...options}={}, config) => {
	let platformsStr = platforms;
	if (platforms != 'all') {
		platformsStr = platforms.join(',');
	}

	const payload = {
		page,
		page_size,
		extended,
		platforms: platformsStr,
		...options,
	};
	return apiRequest(`${getPrefix()}device/blackwhite_list_devices/`, payload, 'get');
};

/**
 * manual filter api reference
 *
 * GET    /api/v1/agency/<agency_id>/dsp/campaign/<request>/manualfilter/?type=<type>
 * POST   /api/v1/agency/<agency_id>/dsp/campaign/<request>/manualfilter/ { device, type: black|white }
 * DELETE /api/v1/agency/<agency_id>/dsp/campaign/<request>/manualfilter/<device>/
 */

export function getManualFilter(request_id, type) {
	const payload = {};
	if (type) {
		payload.type = type;
	}
	return apiRequest(`${getPrefix()}request/${ request_id }/manualfilter/`, payload, 'get');
}

export function addDevicesToManualFilter(request_id, type, devices) {
	return apiRequest(`${getPrefix()}request/${ request_id }/manualfilter/`, {
		devices,
		type,
	}, 'post');
}

export function removeDevicesFromManualFilter(request_id, devices) {
	return apiRequest(`${getPrefix()}request/${ request_id }/manualfilter/`, {
		devices,
	}, 'delete');
}
