<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 28 28">
		<path
			:fill="disabled ? colors.disabled : colors.main"

			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10 0H23C25.7568 0 28 2.24316 28 5V18C28 20.7568 25.7568 23 23 23C23 25.7568 20.7568 28 18 28H5C2.24316 28 0 25.7568 0 23V10C0 7.24316 2.24316 5 5 5C5 2.24316 7.24316 0 10 0ZM5 26H18C19.6543 26 21 24.6543 21 23V21V10C21 8.3457 19.6543 7 18 7H7H5C3.3457 7 2 8.3457 2 10V23C2 24.6543 3.3457 26 5 26ZM23 21C24.6543 21 26 19.6543 26 18V5C26 3.3457 24.6543 2 23 2H10C8.3457 2 7 3.3457 7 5H18C20.7568 5 23 7.24316 23 10V21ZM6.70703 15.293L10 18.5859L16.168 12.418L17.582 13.832L10 21.4141L5.29297 16.707L6.70703 15.293Z"
		/>
	</symbol>
</template>

<script>
export const slug = 'choosing';


export default {
	name: 'NiIconChoosing',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				disabled: this.$palette.disabled_color,
				...this.palette,
			};
		},
	},
};
</script>
