export default {
	documents: {
		en: 'Documents',
		ge: 'დოკუმენტები',
		de: 'Dokumente',
		ru: 'Документы',
		cn: /* TODO: to be translated */ 'Documents',
	},

	invoice: {
		en: 'Invoice',
		ge: 'ინვოისი',
		de: 'Rechnung',
		ru: 'Счет',
		cn: /* TODO: to be translated */ 'Invoice',
	},

	invoices: {
		en: 'Invoices',
		ge: 'ინვოისები',
		de: 'Rechnungen',
		ru: 'Счета',
		cn: /* TODO: to be translated */ 'Invoices',
	},

	invoice_id: {
		en: 'Invoice Number',
		ge: 'ინვოისის ნომერი',
		de: 'Rechnungsnummer',
		ru: 'Номер счета',
		cn: /* TODO: to be translated */ 'Invoice Number',
	},

	invoice_date: {
		en: 'Date',
		ge: 'თარიღი',
		de: 'Datum',
		ru: 'Дата',
		cn: /* TODO: to be translated */ 'Date',
	},

	invoice_total_excl_tax: {
		en: 'Total excl. tax',
		ge: 'სულ გამოკლ. გადასახადი',
		de: 'Gesamtbetrag ohne Steuern',
		ru: 'Сумма без налога',
		cn: /* TODO: to be translated */ 'Total excl. tax',
	},

	invoice_status: {
		en: 'Status',
		ge: 'სტატუსი',
		de: 'Status',
		ru: 'Статус',
		cn: /* TODO: to be translated */ 'Status',
	},

	invoice_status_pending: {
		en: 'not paid',
		ge: 'არ არის გადახდილი',
		de: 'nicht bezahlt',
		ru: 'не оплачен',
		cn: /* TODO: to be translated */ 'not paid',
	},
	invoice_status_paid: {
		en: 'paid',
		ge: 'გადახდილი',
		de: 'bezahlt',
		ru: 'оплачен',
		cn: /* TODO: to be translated */ 'paid',
	},
	invoice_status_canceled: {
		en: 'canceled',
		ge: 'გაუქმდა',
		de: 'storniert',
		ru: 'отменен',
		cn: /* TODO: to be translated */ 'canceled',
	},

	id: {
		en: 'ID',
		ge: 'ID',
		de: 'ID',
		ru: 'ID',
		cn: /* TODO: to be translated */ 'ID',
	},

	time: {
		en: 'Time',
		ge: 'დრო',
		de: 'Zeit',
		ru: 'Время',
		cn: /* TODO: to be translated */ 'Time',
	},
};
