<template>
	<section class="page page-dev">
		<h1>Table</h1>

		<p>Дефолтный стиль таблицы задётся классом <b><i>nice-table</i></b>.</p>
		<table class="nice-table" >
			<thead>
				<tr>
					<th>Component</th>
					<th>Preview</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>TableCellStatus</td>
					<table-cell-status
						resource="test"
						:item="{
							id: 1,
							status: 'pause',
						}"
						:list="[
							{
								value: 'pause',
								label: 'Paused',
								icon: 'pause',
							},
							{
								value: 'approved',
								icon: 'play',
								label: 'Approved',
							},
							{
								value: 'archived',
								icon: 'arrow_circle',
								label: 'Archived',
								labelAction: 'Archive',
							},
						]"
						can-change
					/>
				</tr>
				<tr>
					<td>TableCellName</td>
					<table-cell-name
						:id="1"
						resource="test"
						caption="Test name"
						show-link
					/>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
import TableCellStatus from '@/components/table/cell-status';
import TableCellName from '@/components/table/cell-name';


export default {
	name: 'PageDevTableBaseInfo',
	components: {
		TableCellStatus,
		TableCellName,
	},
};
</script>
