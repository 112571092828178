import _ from 'underscore';

import { setterGenerator, stateGenerator, complexDataSetter, capitalize } from '@/utilites.js';


const FIELDS = [
	'id',
	'name',
	'domain',
	'logo',
	'main_color',
	'background',
	'favicon',
	'country',
	'currency',
	'balance',
	'address',
	'commission',
	'contract',
	'ssp_networks',
	'dsp_networks',
	'legal_type',
	'default_map',
];
const LOADING_KEY = 'store/agency/requesting';


export default {
	namespaced: true,

	state: {
		...stateGenerator(FIELDS),
	},

	mutations: {
		...setterGenerator(FIELDS),
		...complexDataSetter(FIELDS),
	},

	getters: {
		data(state) {
			return {
				id: state.id,
				name: state.name,
				domain: state.domain,
				logo: state.logo,
				main_color: state.main_color,
				background: state.background,
				favicon: state.favicon,
				country: state.country,
				currency: state.currency,
				balance: state.balance,
				address: state.address,
				commission: state.commission,
				contract: state.contract,
				ssp_networks: state.ssp_networks,
				dsp_networks: state.dsp_networks,
				legal_type: state.legal_type,
				default_map: state.default_map,
			};
		},

		isLoading(state, getters, rootState, rootGetters) {
			return Boolean(rootGetters['app/inProgress'][LOADING_KEY]);
		},

		/**
		 * @returns {object} Agency info
		 */
		agency(state) {
			return _.pick(state, FIELDS);
		},
	},

	actions: {
		/**
		 * Clear this store. Used in logout action
		 */
		clear({ commit }) {
			_.each(FIELDS, function(f) {
				commit(`set${capitalize(f, null)}`);
			});
		},
	},
};
