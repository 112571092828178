<template>
	<section
		class="campaign-form_model-select"
	>
		<p class="welcome-message">{{ $t('campaign.step02_welcome_message') }}</p>
		<div class="card-list" >
			<div
				v-if="isModelEnabled(PAYMENT_MODEL_AD_PLAYS)"
				class="payment-model"
				:class="[
					{ 'payment-model_active': paymentModel === PAYMENT_MODEL_AD_PLAYS }
				]"
			>
				<svg width="78" height="78" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="payment-model__icon">
					<use xlink:href="#payment_model_icon__ad_plays" />
				</svg>
				<h2 class="payment-model__title">{{ $t('payment_models.ad_plays') }}</h2>
				<span class="payment-model__goal-label">{{ $t('campaign.step02_goal') }}</span>
				<p
					class="payment-model__goal"
					v-html="$t('payment_models.ad_plays_goal')"
				/>
				<p
					class="payment-model__description"
					v-html="$t('payment_models.ad_plays_description')"
				/>

				<nice-button-2
					bordered
					:filled="paymentModel === PAYMENT_MODEL_AD_PLAYS"
					class="payment-model__select-button"
					@click="choosePaymentModel(PAYMENT_MODEL_AD_PLAYS)"
				>{{ $t('select') }}</nice-button-2>
			</div>

			<div
				v-if="isModelEnabled(PAYMENT_MODEL_CPM)"
				class="payment-model"
				:class="[
					{ 'payment-model_active': paymentModel === PAYMENT_MODEL_CPM }
				]"
			>
				<svg width="78" height="78" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="payment-model__icon">
					<use xlink:href="#payment_model_icon__cpm" />
				</svg>
				<h2 class="payment-model__title">{{ $t('payment_models.cpm') }}</h2>
				<span class="payment-model__goal-label">{{ $t('campaign.step02_goal') }}</span>
				<p
					class="payment-model__goal"
					v-html="$t('payment_models.cpm_goal')"
				/>
				<p
					class="payment-model__description"
					v-html="$t('payment_models.cpm_description')"
				/>

				<nice-button-2
					bordered
					:filled="paymentModel === PAYMENT_MODEL_CPM"
					class="payment-model__select-button"
					@click="choosePaymentModel(PAYMENT_MODEL_CPM)"
				>{{ $t('select') }}</nice-button-2>
			</div>


			<div
				v-if="isModelEnabled(PAYMENT_MODEL_CPV)"
				class="payment-model"
				:class="[
					{ 'payment-model_active': paymentModel === PAYMENT_MODEL_CPV },
				]"
			>
				<svg width="78" height="78" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="payment-model__icon">
					<use xlink:href="#payment_model_icon__cpv" />
				</svg>
				<h2 class="payment-model__title">{{ $t('payment_models.cpv') }}</h2>
				<span class="payment-model__goal-label">{{ $t('campaign.step02_goal') }}</span>
				<p
					class="payment-model__goal"
					v-html="$t('payment_models.cpv_goal')"
				/>
				<p
					class="payment-model__description"
					v-html="$t('payment_models.cpv_description')"
				/>
				<nice-button-2
					bordered
					:filled="paymentModel === PAYMENT_MODEL_CPV"
					class="payment-model__select-button"
					@click="choosePaymentModel(PAYMENT_MODEL_CPV)"
				>{{ $t('select') }}</nice-button-2>
			</div>
		</div>



	</section>
</template>

<script>
import { mapState } from 'vuex';

import {
	PAYMENT_MODEL_AD_PLAYS,
	PAYMENT_MODEL_CPM,
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';


export default {
	name: 'Step02',


	props: {
		stepCurrent: {
			type: Number
		},

		stepCount: {
			type: Number
		},

		goToNext: {
			type: Function
		},

		goToPrev: {
			type: Function
		},

		/*
		 * contains `instance`, `step`
		 */
		context: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {
			PAYMENT_MODEL_AD_PLAYS,
			PAYMENT_MODEL_CPM,
			PAYMENT_MODEL_CPV,
		};
	},


	computed: {
		...mapState('app', [
			'enabledPaymentModels',
		]),

		paymentModel() {
			return this.context.instance.payment_model;
		},
	},


	methods: {
		choosePaymentModel(paymentModel) {
			this.$emit('payment-model-change', paymentModel);

			this.goToNext();
		},

		isModelEnabled(model) {
			return this.enabledPaymentModels.includes(model);
		},
	},
};
</script>

<style lang="sass" scoped >
$width-input: 385px
$welcome-message-line-compensation: 5px
$welcome-message-margin-top: $cmpnt-rc__content-margin-top - $ni_button-padding-y - $welcome-message-line-compensation


.campaign-form_model-select
	margin: $welcome-message-margin-top auto
	max-width: $cmpnt-rc__content-width
	padding-bottom: $welcome-message-margin-top

.welcome-message
	margin: 0 auto $welcome-message-margin-top
	font-size: $fsz__new__section-title
	line-height: (19/16)


.card-list
	display: flex
	flex-direction: row
	flex-wrap: wrap
	justify-content: space-evenly // space-between
	align-items: stretch
	align-content: space-between


.payment-model
	+card-wrapper-default
	width: 246px

	display: inline-block
	display: flex
	flex-direction: column
	align-items: center


.payment-model__icon
	width: 78px
	height: 78px
	display: block
	margin: 0 auto 15px
	color: var(--main_color)


.payment-model__title,
.payment-model__goal
	font-size: $fsz__new__section-title
	line-height: (19/16)
	margin: 0 auto 20px
	font-weight: normal


.payment-model__title
	text-align: center
	color: var(--main_color)


.payment-model__goal-label
	display: block
	margin: 0 auto 4px
	text-align: center
	font-size: 10px
	line-height: 12px
	color: var(--text_2_color)
	text-transform: uppercase


.payment-model__goal
	text-align: center
	min-height: (19 / 16 * 2em)


.payment-model__description
	color: var(--text_2_color)
	text-align: center
	font-size: $fsz__new__smaddle
	line-height: 150%
	margin-bottom: 32px


.payment-model__select-button
	display: block
	width: 100%
	margin-top: auto

// .payment-model__select-button_active,
// 	&, &:hover
// 		color: white
// 		background-color: var(--main_color)
// 		border-color: var(--main_color)
</style>
