<template>
	<symbol :id="`${prefix}resolution-14`" viewBox="0 0 14 14">
		<path d="M3.81,5.74l2,1.13L6.27,6l-2-1.13Z" class="sign"/>
		<path d="M7.73,8l2,1.13.5-.87-2-1.13Z" class="sign"/>
		<path d="M0,4A2,2,0,0,1,2,2H12a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2ZM2,3H12a1,1,0,0,1,1,1V9.89l-.85-.49-.5.86.94.55A1,1,0,0,1,12,11H2a1,1,0,0,1-1-1V4.1l.85.51.5-.87-.93-.55A1,1,0,0,1,2,3Z" class="sign"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolResolution14',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
$size: 14px

.sign
	fill-rule: evenodd
	fill: var(--ni-icon-sign, var(--main_color))
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))  // scaleX(-1)
	transform-origin: 50% 50%
</style>
