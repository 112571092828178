import { request } from './request';


/**
 * Request profile for the authenticated user
 */
export async function profile() {
	return await request('me/');
}


/**
 * Update profile of the authenticated user
 */
export async function updateProfile(data, config) {
	return await request('me/', data, 'patch', config);
}


export async function getQRCode() {
	return await request('2fa/enable/', null, 'post');
}

export async function getConfirm2fa(data) {
	return await request('2fa/confirm/', data, 'post');
}

export async function delete2fa(data) {
	return await request('2fa/disable/', data, 'delete');
}

export async function refreshStaticTokens(data) {
	return await request('2fa/static-refresh/', data, 'post');
}

export async function sendVerifyEmail() {
	return await request('send-verify-email/');
}
