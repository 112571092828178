<template>
	<section class="page-section cl-container">
		<nice-stats
			:graphData="testData"
			:labelData="labelData"
		/>
	</section>
</template>

<script>
import NiceStats from '@/ui/nice-stats';


export default {
	name: 'TabStatsGraph',


	components: {
		NiceStats,
	},


	data() {
		return {
			testData: [
				[10,20,22,30, 0,50,150,300, 100,205,220,30, 50,10,0,50, 10,20,22,130, 0,150,250,100, 80],
				[100,200,220,300,0,500,1500,500,100,200,220,300,0,500,2000,8000,3500,200,220,300,100,200,500,1500],
				[10,2000,2200,3000,0,5000,15000,92000,1000,2000,100,0,500,5000,40000,45000,35000,2000,2200,500,7000,5000,15000,8000],
			],
			labelData:[
				'one', 'two', 'umbrella',
			]
		};
	},
};
</script>
