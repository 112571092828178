<template>
	<symbol :id="`${prefix}hint`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8"
			:fill="getColor('main')"
		/>
		<path
			d="M8.8,3.1a3,3,0,0,0-2.63.53A3,3,0,0,0,5,6H6a2,2,0,0,1,.78-1.58,2,2,0,0,1,1.77-.35A2.11,2.11,0,0,1,10,6.19,1.65,1.65,0,0,1,8.67,7.86,2.2,2.2,0,0,0,7,10H8a1.21,1.21,0,0,1,.94-1.14A2.63,2.63,0,0,0,11,6.25,3.08,3.08,0,0,0,8.8,3.1Z"
			:fill="getColor('sign')"
		/>
		<rect
			x="7"
			y="11"
			width="1"
			height="2"
			:fill="getColor('sign')"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconHint',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number, Boolean],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.graydark,
				sign: this.$palette.white,

				main__disabled: this.$palette.graydark__disabled,
				sign__disabled: this.$palette.white__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
