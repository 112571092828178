<template>
	<div class="paginator cl-container cl-pad cl-row">
		<div
			class="pgtr-total cl-xs12 cl-md3 cl-lg2"
			:class="{
				'center': browserSize === 'xs' || browserSize === 'sm',
			}"
		>
			<span class="per-page-caption">
				{{$t('total') | capitalize}}
			</span>
			{{ total }}
		</div>


		<div class="cl-xs12 cl-md6 cl-lg8">
			<nice-paginator
				v-if="total > 0"

				v-model="page"
				v-bind="paginatorProps"
				class="pgtr-pgtr"
				:count="pagesCount"
				:disabled="disabled"
				:pages-show-amount="pagesShowAmount"
			/>
		</div>

		<div
			class="pgtr-per-page cl-xs12 cl-md3 cl-lg2"
			:class="{
				'center': browserSize === 'xs' || browserSize === 'sm',
			}"
		>
			<span class="per-page-caption">{{$t('request_list.lines_per_page') | capitalize}}</span>
			<nice-select
				v-model="pageSize"
				class="per-page-select"
				:options="pageSizeSelectOptions"
				placeholder="Select"
				:disabled="disabled"
			/>
		</div>
	</div>
</template>

<script>
import NicePaginator from '@/ui/nice-paginator';
import NiceSelect from '@/ui/nice-select';
import { mapGetters } from 'vuex';


export default {
	name: 'TablePginator',


	components: {
		NicePaginator,
		NiceSelect,
	},


	// data
	data() {
		let defaultPage = 1;
		let defaultPageSize = this.pageSizeOptions[Math.floor(this.pageSizeOptions.length / 2)];

		return {
			page: this.value.page || defaultPage,
			pageSize: this.value.pageSize || defaultPageSize,
		};
	},


	// props
	props: {
		/**
		 * Object contins the page number and lines per page amount
		 */
		value: {
			type: Object,
			default() {
				return { pageSize: 10, page: 1, };
			},
		},


		disabled: Boolean,
		// loading: Boolean,

		/**
		 * Total table lines amount
		 */
		total: {
			type: Number,
			required: true,
			validator(total) { return total >= 0; },
		},

		/**
		 * Array contains options for per-page select
		 */
		pageSizeOptions: {
			type: Array,
			default() {
				return [5, 10, 15, 20, 50];
			},
		},

		/**
		 * How many pages to show in paginator
		 */
		pagesShowAmount: {
			type: Number,
			default: 7,
		},


		/**
		 * Props to provide to the paginator component
		 */
		paginatorProps: {
			type: Object,
			default() {
				return {};
			},
		},
	},


	// computed
	computed: {
		...mapGetters('browser', { browserSize: 'size' }),

		/**
		 * Total pages count
		 */
		pagesCount() {
			return Math.ceil(this.total / this.pageSize);
		},

		/**
		 * perPge select options
		 */
		pageSizeSelectOptions() {
			return this.pageSizeOptions.map(n => ({ value: n, label: `${n}`, }));
		},
	},


	watch: {
		/**
		 * Watch value prop change
		 * for v-model support
		 */
		value: {
			deep: true,
			handler(value) {
				this.page = value.page;
				this.pageSize = value.pageSize;
			}
		},

		/**
		 * Watch `page`` change to trigger
		 * for v-model support
		 */
		page(page, oldPage) {
			if (page !== oldPage) this.triggerInput();
		},

		/**
		 * Watch `pageSize`` change to trigger
		 * for v-model support
		 */
		pageSize(pageSize, oldPageSize) {
			if (pageSize === oldPageSize) return;

			// let oldIndex = this.page;
			let newIndex = Math.ceil(((this.page - 1) * oldPageSize + 1) / pageSize);

			if (this.page !== newIndex) this.page = newIndex;
			else this.triggerInput();
		},
	},


	// methods
	methods: {
		triggerInput() {
			this.$emit('input', { page: this.page, pageSize: this.pageSize });
		},

	},
};

</script>

<style lang="sass" scoped >
$height: 45px

.paginator
	margin-top: 24px
	margin-bottom: 24px

.pgtr-total
	height: $height
	line-height: $height

	&.center
		text-align: center

.pgtr-pgtr
	display: flex
	flex-direction: row
	height: $height
	align-items: center

.pgtr-per-page
	display: flex
	flex-direction: row
	height: $height
	align-items: center
	justify-content: flex-end

	&.center
		justify-content: center

.per-page-caption
	display: inline-block
	margin-right: .75em
	//line-height: 30px
	color: $clr__darkgray
	font-size: $fsz__normal
	margin-bottom: 1px

.per-page-select
	display: inine-block
	width: 75px
</style>
