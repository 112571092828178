<template>
	<ul
		class="nice-tree-demo"
		:class="{
			'root-list': depth === 0,
		}"
		:style="{
			'--depth': depth,
		}"
	>
		<li
			v-for="entry in list"
			:key="`entry-${entry.value}`"
			class="entry"
			:class="{
				'selected': value.includes(entry.value),
			}"
		>
			<span class="caption">{{ entry.label | capitalize }}</span>

			<nice-tree-demo
				v-if="!value.includes(entry.value)"
				:tree="filteredTree"
				:depth="depth + 1"
				:value="value"
				:entry-point="entry.value"
			/>
		</li>
	</ul>
</template>

<script>

export default {
	name: 'NiceTreeDemo',

	props: {
		tree: {
			type: Array,
			required: true,
			validator(tree) {
				return tree instanceof Array && tree.length;
			}
		},

		value: {
			type: Array,
			default: () => [],
		},

		entryPoint: {
			type: [String, Number],
			default: null,
		},

		depth: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
		};
	},

	computed: {
		list() {
			if (!this.entryPoint && this.entryPoint !== 0) return this.filteredTree.filter(entry => !entry.parent);
			return this.filteredTree.filter(entry => entry.parent === this.entryPoint);
		},

		filteredTree() {
			// entryPoint mean it isn't a root list
			if (this.entryPoint || this.entryPoint === 0) {
				return this.tree;
			}

			return this.filterTreeBySelected(this.tree, this.value);
		}
	},

	methods: {
		filterTreeBySelected(tree, values) {
			const filteredTree = [];

			function pushUniq(array, entry) {
				if (array.indexOf(entry) === -1) array.push(entry);
			}

			const recursivePushWithParents = value => {
				if (!value && value !== 0) return;
				const entry = tree.find(entry => ''+entry.value === ''+value);
				if (entry.parent) {
					recursivePushWithParents(entry.parent, filteredTree);
				}
				pushUniq(filteredTree, entry);
			};

			values.forEach(recursivePushWithParents);

			return filteredTree;
		},
	},
};
</script>

<style lang="sass" scoped>
.nice-tree-demo
	--list-padding: 18px
	list-style: none
	padding: 0 0 0 var(--list-padding)

.root-list
	padding-left: 0


.entry
	line-height: 18px
	font-weight: 300
	color: var(--text_2_color)

	&.selected
		font-weight: normal
		color: var(--text_color)

	.root-list > &
		margin: (30px - 18px) / 2 0

.caption
</style>
