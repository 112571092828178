<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 17 17">
		<path
			:fill="disabled ? colors.disabled : colors.main"

			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 8.5C0 3.80566 3.8056 0 8.5 0C13.1944 0 17 3.80566 17 8.5C17 13.1943 13.1944 17 8.5 17C3.8056 17 0 13.1943 0 8.5ZM1 8.5C1 12.6357 4.3645 16 8.5 16C12.6355 16 16 12.6357 16 8.5C16 4.36426 12.6355 1 8.5 1C4.3645 1 1 4.36426 1 8.5ZM8 8V5H9V8H12V9H9V12H8V9H5V8H8Z"
		/>
	</symbol>
</template>

<script>
export const slug = 'add-min';


export default {
	name: 'NiIconAddMin',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				disabled: this.$palette.disabled_color,
				...this.palette,
			};
		},
	},
};
</script>
