<template>
	<symbol :id="`${prefix}pause-34`" viewBox="0 0 34 34">
		<circle
			cx="17" cy="17" r="16"
			fill="none"
			stroke-width="2"
			class="sign"
		/>
		<path
			d="M15,14 15,20 M19,14 19,20"
			fill="none"
			stroke-width="2"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPause34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke: var(--ni-icon-base, #{$nice_color-purple})
</style>
