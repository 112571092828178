<template>
	<section class="page page-dev">
		<h1>TableCellName</h1>
		<p>Виджет отображающий название и добавляющий при необходимости ссылку на редактирование инстанса.</p>

		<table class="nice-table" >
			<thead>
				<tr>
					<th class="col-number" >No</th>
					<th class="col-name" >Name</th>
					<th>Digit</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="i in 6"
					:key="i"
				>
					<td class="col-number" >{{ i }}</td>
					<table-cell-name
						:id="i"
						resource="test"
						:caption="`Test name No${i}`"
						:show-link="Boolean(Math.floor(Math.random()*2))"

						class="col-name"
					/>
					<td v-html="formatNumber(Math.random()*100000+100)" />
				</tr>
			</tbody>
		</table>

		<h2>Props</h2>
		<dl>
			<dt>resource</dt>
				<dd>Ресурс к которому относится айтем статус которого отслеживает и изменяется, используется при вызове ендопинта изменения статуса</dd>

			<dt>id</dt>
				<dd>ID объекта ресурса, стаус которого отслеживается / меняется</dd>

			<dt>caption</dt>
				<dd>Имя айтема.</dd>

			<dt>show-link</dt>
				<dd>Флаг определяющий отображать ли ссылку на редактирование или отображать просто текст</dd>
		</dl>
	</section>
</template>

<script>
import { formatNumber } from '@/utilites';

import TableCellName from '@/components/table/cell-name';



export default {
	name: 'PageDevTableCellName',


	components: {
		TableCellName,
	},


	methods: {
		formatNumber,
	},
};
</script>

<style lang="sass" scoped >
.col-number
	width: 100px

.col-name
	width: 150px
</style>
