<template>
	<symbol :id="`${prefix}load`" viewBox="0 0 15 14">
		<path d="M14 10.207V9.70703H15V10.207C15 12.1367 13.4297 13.707 11.5 13.707H3.5C1.57031 13.707 0 12.1367 0 10.207V9.70703H1V10.207C1 11.5859 2.12158 12.707 3.5 12.707H11.5C12.8789 12.707 14 11.5859 14 10.207Z" class="sign"/>
		<path d="M8 1.672L8 9.086L11.389 5.697L12.096 6.404L7.5 11L2.904 6.404L3.611 5.697L7 9.086L7 1.672H8Z" class="sign arrow" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolLoad',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: 50% 40%
</style>
