<template>
	<symbol :id="`${prefix}monitor-13`" viewBox="0 0 13 12">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.89543 0.895431 0 2 0H11C12.1046 0 13 0.895431 13 2V7C13 8.10457 12.1046 9 11 9H7V11H10V12H3V11H6V9H2C0.89543 9 0 8.10457 0 7V2ZM2 1H11C11.5523 1 12 1.44772 12 2V7C12 7.55228 11.5523 8 11 8H2C1.44772 8 1 7.55228 1 7V2C1 1.44772 1.44772 1 2 1Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMonitor13',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
