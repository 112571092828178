<template>
	<section>
		<h2>Вкладки</h2>

		<p>
			test reactivity in tabs:
			<button type="button" @click="testReactivityVariable++" >{{ testReactivityVariable }}</button>
		</p>
		<nice-input v-model="testReactivityTabName" />

		<nice-tabs>
			<section :data-ni_tab_name="testReactivityTabName" >
				<h3>Tab Number 1</h3>
				<p>testReactivityVariable: {{ testReactivityVariable }}</p>
			</section>

			<section>
				<h3>Tab Number 2</h3>
				<p>And we can make deeper tabs</p>

				<nice-tabs>
					<h4>3-rd level tabs No1</h4>
					<h4>3-rd level tabs No2</h4>
					<h4>3-rd level tabs No3</h4>
				</nice-tabs>

				<p>and after reloading they opened in last state</p>
			</section>

			<section>
				<h3>Tab Number 3</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam eos dolorem, eius ipsam, quod dolorum odio sequi atque ea laborum, temporibus dolores rerum facere officia adipisci eum dolor at, suscipit.</p>
			</section>
		</nice-tabs>


		<h3>Параметры</h3>

		<ul>
			<li>
				<b class="tabs-param" >name</b> - Имя вкладки, по умолчанию имеет следующую форму - Tab No&lt;tab_index&gt;
			</li>
			<li>
				<b class="tabs-param" >slug</b> - рабочий слаг вкладки, если не передать сгенерится на основе имени преобразую в нижний регистр и заменив пробелы на -
			</li>
			<li>
				<b class="tabs-param" >disabled</b> - очевидно - выключение вкладки, вкладка отключается и невозможно её открыть
			</li>
			<li>
				<b class="tabs-param" >active</b> - признак активности вкладки, первая вкладка с этим атрибутом по умолчанию открывается, если ни одна вкладка не имеет данный атрибут, то открывается первая
			</li>
		</ul>


		<h3>Применение</h3>

		<pre class="code-preview" ><code>&lt;nice-tabs&gt;
	&lt;section&gt;
		&lt;p&gt;Simple tab&lt;/p&gt;
	&lt;/section&gt;
	&lt;section  data-ni_tab_name="Tab" &gt;
		&lt;p&gt;Named tab&lt;/p&gt;
	&lt;/section&gt;
	&lt;section  data-ni_tab_name="Default"  data-ni_tab_active &gt;
		&lt;p&gt;Default active tab&lt;/p&gt;
	&lt;/section&gt;
	&lt;section  data-ni_tab_name="Disabled"  data-ni_tab_disabled &gt;
		&lt;p&gt;This text not be showed&lt;/p&gt;
	&lt;/section&gt;
	&lt;section  data-ni_tab_slug="mega_tab" &gt;
		&lt;p&gt;tab with setted slug&lt;/p&gt;
	&lt;/section&gt;
&lt;/nice-tabs&gt;</code></pre>

		<nice-tabs>
			<section>
				<p>Simple tab</p>
			</section>
			<section data-ni_tab_name="Tab" >
				<p>Named tab</p>
			</section>
			<section data-ni_tab_name="Default" data-ni_tab_active >
				<p>Default active tab</p>
			</section>
			<section data-ni_tab_name="Disabled" data-ni_tab_disabled >
				<p>This text not be showed</p>
			</section>
			<section data-ni_tab_slug="mega_tab" >
				<p>tab with setted slug</p>
			</section>
		</nice-tabs>

	</section>
</template>

<script>
import NiceTabs from '@/ui/nice-tabs';
import NiceInput from '@/ui/nice-input';


export default {
	name: 'TabTabs',

	components: {
		NiceTabs,
		NiceInput,
	},


	data() {
		return {
			testReactivityVariable: 0,
			testReactivityTabName: 'Tab name',
		};
	},
};
</script>

<style lang="sass" scoped >
.nice-tabs
	background-color: var(--disabled_color)
</style>
