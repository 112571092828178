<template>
	<symbol :id="`${prefix}x`" viewBox="0 0 9 9">
		<path
		  class="sign"
		  fill-rule="evenodd"
		  d="M3.85 4.55L.2 8.2l.7.7 3.65-3.64L8.2 8.91l.7-.71-3.64-3.65L8.91.91 8.2.2 4.55 3.85.91.2.2.9l3.65 3.65z"
		  clip-rule="evenodd"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolX',

	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: none
	fill: var(--ni-icon-sign, currentColor)
</style>
