<template>
	<section class="layout-full_width" >
		<h1>NiceInput</h1>

		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-input
					id="test-input-placeholder"
					v-model="testInputData.placeholder"
					class="cl-pad cl-xs4 cl-md3"
					placeholder="Type placeholder here"
					label="Placeholder"
					palette="red"
				/>
				<nice-input
					id="test-input-label"
					v-model="testInputData.label"
					class="cl-pad cl-xs4 cl-md3"
					placeholder="Type label"
					label="Label"
				/>
				<nice-input
					id="test-input-units"
					v-model="testInputData.units"
					class="cl-pad cl-xs4 cl-md3"
					placeholder="Type units"
					label="Units"
					hint="Опачки, привет!"
					disabled
				/>
			</div>

			<div class="cl-row cl-pad-no_sides" >
				<div class="cl-pad cl-xs8 cl-md7">
					<nice-input
						:id="testInputData.id"
						v-model="testInputData.value"
						:placeholder="testInputData.placeholder"
						:units="testInputData.units"
						:label="testInputData.label"
					/>
					<pre>value: "{{ testInputData.value }}"</pre>
				</div>
			</div>
		</div>


		<h2>Label</h2>
		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-input
					class="cl-pad cl-xs4 cl-md3"
					placeholder="unlabeled input"
				/>
				<nice-input
					class="cl-pad cl-xs4 cl-md3"
					placeholder="labeled input"
					labeled
				/>
				<nice-input
					class="cl-pad cl-xs4 cl-md3"
					label="labeled"
					placeholder="labeled"
				/>
			</div>
		</div>

		<h2>Units</h2>
		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-input
					type="text"
					value="30"
					units="cm"
					label="Units as text"
					class="cl-pad cl-xs4 cl-md3"
				/>
				<nice-input
					type="text"
					value="Координаты скайфол"
					label="Units as slot"
					class="cl-pad cl-xs4 cl-md3"
				>
					<nice-icon icon="marker" />
				</nice-input>
			</div>

			<div class="cl-row cl-pad-no_sides" >
				<code class="code-preview cl-pad cl-xs4 cl-md3" ><pre>&lt;nice-input
	type="text"
	value="30"
	units="cm"
	label="Units as text"
/&gt;</pre></code>
				<code class="code-preview cl-pad cl-xs4 cl-md3" ><pre>&lt;nice-input
	type="text"
	value="Координаты скайфол"
	label="Units as slot"
&gt;
	&lt;nice-icon icon="marker" /&gt;
&lt;/nice-input&gt;</pre></code>
			</div>
		</div>

		<h2>Autocomplete</h2>
		<p>Для использования автокомплита необходимо передать функцию получающую текущее значение и возвращающую список для заполнения.</p>
		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-input
					class="cl-pad cl-xs4 cl-md3"
					value="30"
					:autocomplete-request="getAutocompleteList"
				/>
			</div>
			<div class="cl-row cl-pad-no_sides" >
				<code class="code-preview cl-pad cl-xs9 cl-md9" ><pre>
// template
&lt;nice-input value="30" :autocomplete-request="getAutocompleteList" /&gt;

// script
const autocompleteList = [...];

getAutocompleteList(q) {
	return _.filter(autocompleteList, (i) => i.startsWith(q));
}
				</pre></code>
			</div>
		</div>

		<h2>Autocomplete (Objects List)</h2>
		<p>В данном случае функция возвращает список объектов, подобных тем, что используются в nice-select: [{value: ..., label: ...}, ...]</p>
		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-input
					class="cl-pad cl-xs4 cl-md3"
					value="2"
					:autocomplete-request="getAutocompleteObjectList"
				/>
			</div>
			<div class="cl-row cl-pad-no_sides" >
				<code class="code-preview cl-pad cl-xs9 cl-md9" ><pre>
// template
&lt;nice-input value="30" :autocomplete-request="getAutocompleteObjectList" /&gt;

// script
const autocompleteObjectList = [ {value: 1, label: "one"}, ...];

getAutocompleteObjectList(q) {
	return _.filter(autocompleteObjectList, (i) => i.value.startsWith(q) || i.label.startsWith(q));
}
				</pre></code>
			</div>
		</div>

	</section>
</template>

<script>
import _ from 'underscore';
import { mapState } from 'vuex';

import NiceInput from '@/ui/nice-input';

const autocompleteList = [
	'Restobar',
	'Washroom',
	'Hallway',
	'Unspecified',
	'Office',
	'Elevator',
	'Lobby',
	'Food Court',
	'Unspecified',
	'Residental',
	'Elevator',
	'Lobby',
	'Unspecified',
	'Convenience Store',
	'Grocery Store',
	'Health',
	'Clinic Waiting Room',
	'Pharmacy',
	'Sports & Recreational Centers',
	'Lobby',
	'Snack Bar',
	'Viewing Gallery',
	'Gym',
	'Washroom',
	'Changing Room',
	'Unspecified',
	'Subway & LRT',
	'Airport',
	'Hallway',
	'Gate',
	'Malls',
];

const autocompleteObjectList = [
	{
		value: 1,
		label: 'one',
		other: 'something with the object'
	},
	{
		value: 2,
		label: 'two',
		other: 'something with the object'
	},
	{
		value: 3,
		label: 'three',
		other: 'something with the object'
	},
	{
		value: 4,
		label: 'four',
		other: 'something with the object'
	},
	{
		value: 5,
		label: 'five',
		other: 'something with the object'
	},
];

export default {
	name: 'TabTextInput',


	components: {
		NiceInput,
	},


	data() {
		return {
			testInputData: {
				value: 'Name',
				id: '',
				placeholder: 'Type here anything',
				label: 'Test input',
				units: 'unts',
			},
		};
	},


	computed: {
		...mapState('app', { appSide: 'side' }),
	},


	methods: {
		getAutocompleteList(q) {
			return _.filter(autocompleteList, (i) => i.startsWith(q));
		},

		getAutocompleteObjectList(q) {
			return _.filter(autocompleteObjectList, (i) => String(i.value).startsWith(q) || i.label.startsWith(q));
		}
	},
};
</script>
