<template>
	<symbol :id="`${prefix}config`" viewBox="0 0 11 14">
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="2.5"
			y1="11.25"
			x2="2.5"
			y2="14"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="2.5"
			y1="0"
			x2="2.5"
			y2="6.79"
		/>
		<circle
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			cx="2.5"
			cy="9"
			r="2"
		/>
		<circle
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			cx="8.5"
			cy="5"
			r="2"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="8.5"
			y1="2.75"
			x2="8.5"
			y2="0"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			x1="8.5"
			y1="14"
			x2="8.5"
			y2="7.21"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconConfig',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.blue,
				main__disabled: this.$palette.blue__disabled,

				...this.palette,
			};
		},

		strokeWidth() {
			if (this.params && this.params.strokeWidth) {
				return this.params.strokeWidth;
			}
			return 2;
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
