import { setterGenerator } from '@/utilites.js';
import { SIZES } from '@/constants/browser.js';



export default {
	namespaced: true,

	state: {
		width: 0,
		height: 0,
	},

	mutations: {
		...setterGenerator(['width', 'height']),
	},

	getters: {
		size(state) {
			return SIZES.find(size => size.range[0] <= state.width && state.width <= size.range[1]).name;
		},
	},
};
