import { default as YMap } from './ymap';
import { default as MapBox } from './mapbox';


/**
 * Select map component based on agency settings
 */
export default {
	name: 'MapDevice',

	functional: true,

	render(h, context) {
		let MapComponent = MapBox;
		if (context.parent.$store.state.agency.default_map === 'ymap') {
			MapComponent = YMap;
		}

		return h(
			MapComponent,
			context.data,
			context.children,
		);
	},
};
