<template>
	<div>
		<div class="top-panel">
			<nice-input
				v-model="filter.name"
				class="search-input"
				:placeholder="$t('search')"
			>
				<nice-icon
					icon="search"
					class="search-icon"
				/>
			</nice-input>
		</div>

		<table v-if="agency" class="nice-table" >
			<thead>
				<tr>
					<th v-if="isSspDspEditPage">{{ $t('access') | capitalize}}</th>
					<th>{{ $t('status') | capitalize}}</th>
					<th>{{ $t('name') | capitalize}}</th>
					<th v-if="isDSP">{{ $t('ssp_agency') | capitalize}}</th>
					<th>{{ $t('points') | capitalize}}</th>
					<th v-if="isSspDspEditPage">{{ $t('extra_charge') | capitalize}}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="platform in filteredList /* available platforms */"
					:key="platform.id"
				>
					<!-- access -->
					<td
						v-if="isSspDspEditPage"
						class="cell-access" >
						<nice-action-list
							:status="~~platform.access"
							:list="accessStatuses"
							hide-on-click
							icons-in-list

							class="action-list"

							@change="changeAccess(platform.id, $event)"
						/>
						{{ /* available platform listed in agency's platforms */ }}
					</td>

					<!-- status -->
					<td class="cell-status">
						{{ $t( platform.hasOwnProperty('is_enable') && !platform.is_enable ? 'not_available_for_selling' : 'available_for_selling') | capitalize }}
					</td>

					<!-- name -->
					<td class="cell-name">
						{{ platform.name }}
					</td>

					<!-- ssp agency -->
					<td
						v-if="isDSP"
						class="cell-name"
					>
						{{ platformSSPAgency(platform) }}
					</td>

					<!-- devices count -->
					<td class="cell-points">
						{{ platform.device_count }}
					</td>

					<!-- fee -->
					<td
						v-if="isSspDspEditPage"
						class="cell-fee"
					>
						<toggling-input
							v-if="platform.access"
							:value="platform.extra_charge || 0"
							:placeholder="$t('enter fee') | capitalize"
							type="number"
							min="0"
							max="1000"
							class="fee-input"
							units="%"
							@input="updateFee(platform.id, $event)"
						/>
						<template v-else>{{ $t('n_a') }}</template>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import _ from 'underscore';
import { mapState } from 'vuex';

import NiceInput from '@/ui/nice-input';
import NiceActionList from '@/ui/nice-action-list';

import TogglingInput from './toggling-input';

import { ROLE_DSP } from '@/constants/roles';



const ACCESS_OPTIONS_LIST = [
	{
		value: 0,
		icon: 'error',
		label: 'no access',
		labelAction: 'deny access',
	},
	{
		value: 1,
		icon: 'check',
		label: 'has access',
		labelAction: 'allow access',
	},
];

const MODEL = Object.freeze({
	prop: 'platforms',
	event: 'update',
});


export default {
	name: 'PlatformsList',

	model: MODEL,

	components: {
		NiceInput,
		NiceActionList,
		TogglingInput,
	},


	props: {
		/**
		 * v-model prop
		 * Agency's platforms to show
		 */
		[MODEL.prop]: {
			type: Array,
			required: true,
		},

		/**
		 * Agency we operate on
		 *
		 * TODO: do we need it? We might need it for requests for SSP-DSP platform list
		 */
		agency: {
			type: Object,
			required: true,
		},

		/**
		 * Parent agency
		 * If set then we are on a SSP-DSP create/edit page
		 *
		 * TODO: do we need it
		 */
		parentAgency: {
			type: Object,
			required: false,
			default: null,
		},
	},


	data() {
		return {
			// statuses: [
			// 	"Not available for selling",
			// 	"Available for selling",
			// ],

			/**
			 * Access status list with traslated labels
			 * @type {obect[]}
			 */


			/**
			 * Inner value - agency's platform list
			 * @type {obect[]}
			 */
			instance: this[MODEL.prop],

			/**
			 * The filter/search word
			 * @type {string}
			 */
			filter: {
				name: '',
				allowed_only: false,
			},
		};
	},


	computed: {
		...mapState('app', {
			currentRole: 'role',
		}),

		//
		// TODO: move into store?
		//

		isDSP() {
			return this.currentRole == ROLE_DSP;
		},

		isSspDspEditPage() {
			return Boolean(this.parentAgency);
		},

		/**
		 * Helper for ssp_networks in a SspDspEditMode
		 */
		availablePlatforms() {
			if (this.isSspDspEditPage) {
				return this.parentAgency.ssp_networks;
			}

			return [];
		},

		accessStatuses(){ return ACCESS_OPTIONS_LIST.map(option => ({
			value: option.value,
			icon: option.icon,
			label: this.$options.filters.capitalize(this.$t(option.label)),
			labelAction: this.$options.filters.capitalize(this.$t(option.labelAction)),
		}));},
		/**
		 * List of available platforms with applied extra staff
		 * eg 'access', 'fee' for rendering
		 *
		 * @return {object[]} platforms list
		 */
		list() {
			// just return the list itself
			if (!this.isSspDspEditPage) {
				return this.instance;
			}

			return this.availablePlatforms
				.map(platform => {
					let agencyPlatform = this.instance.find(agencyPlatform => agencyPlatform.id === platform.id);
					const result = {
						...platform,
						extra_charge: 0,
						access: 0,
					};

					if (agencyPlatform) {
						result.access = 1;
						result.extra_charge = agencyPlatform.extra_charge || 0;
					}

					return result;
				});
		},

		/**
		 * filtered lict computed property
		 *
		 * @return {object[]} platforms list
		 */
		filteredList() {
			if (!(this.filter.name || this.filter.allowed_only)) {
				return this.list;
			}

			const allowFilter = this.filter.allowed_only;
			const nameFilter = this.filter.name;

			return this.list.filter(platform => {
				const passAllow = !allowFilter || platform.access;
				const passName = !nameFilter || platform.name.toLowerCase().search(nameFilter.toLowerCase()) !== -1;
				return passAllow && passName;
			});
		},
	},


	methods: {
		changeAccess(id, status) {
			// we need to $set(this, 'instance', ...), so we clone
			// TODO: cloning doesn't work:)
			let platforms = this.instance.slice();

			let platform = platforms.find(platform => platform.id === id);

			const index = platforms.indexOf(platform);

			if (status === 0 && index !== -1) { // remove
				platforms.splice(index, 1);
			} else if (status === 1 && index === -1) { // add
				platform = this.availablePlatforms.find(platform => platform.id === id);
				platforms.push(platform);
			}

			this.$set(this, 'instance', platforms);

			// console.log(`status of platform #${id} changed to ${status}`);
		},

		/**
		 * updateFee
		 * Updates a fee value for a provided platform
		 *
		 * @param {number} id - platform's id
		 * @param {string|number} value
		 */
		updateFee(id, value) {
			if (this.isDSP || !this.isSspDspEditPage) {
				alert('access error');
				return;
			}

			// we need to $set(this, 'instance', ...), so we clone
			let platforms = this.instance.slice();
			const platform = platforms.find(platform => platform.id === id);

			if (!platform) {
				alert('cannot find a platform with a given id');
				return;
			}

			const index = platforms.indexOf(platform);
			platforms.splice(index, 1, { ...platform, extra_charge: value });

			this.$set(this, 'instance', platforms);
		},

		platformSSPAgency(platform) {
			let name = platform?.ssp_agency?.name || '—';

			// if (platform && platform.ssp_agency && platform.ssp_agency.name )

			return name;
		},
	},


	watch: {
		[MODEL.prop]: {
			deep: true,
			handler(value) {
				if (!_.isEqual(this.instance, value)) {
					this.$set(this, 'instance', value);
				}
			},
		},

		instance: {
			deep: true,
			handler(value) {
				if (!_.isEqual(this[MODEL.prop], value)) {
					this.$emit(MODEL.event, value);
				}
			},
		},
	},
};
</script>

<style lang="sass" scoped>
.nice-table
	width: 100%
	margin-bottom: 24px

.cell-name
	color: #000000

.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px

.search-input
	width: 200px
	margin-left: 12px

.cell-access
	& > .action-list
		width: 150px

.action-list
	width: 100%

.fee-input
	width: 175px

	&::v-deep > .nice-input
		width: 60px

		input
			text-align: right

</style>
