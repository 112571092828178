<template>
	<button
		type="button"

		class="nice-select-option"
		:class="{
			'selected': selected,
		}"
		tabindex

		@click="$emit('select', value)"
	>
		<NiceIcon2
			v-if="icon"
			:icon="icon"
			class="ni_option--icon"
		/>
		<span class="ni_option--caption">{{ caption }}</span>
	</button>
</template>

<script>
export default {
	name: 'NiceSelectOption',


	props: {
		value: {
			type: [String, Number],
			required: true,
		},

		caption: {
			type: String,
			required: true,
		},

		icon: {
			type: String,
			required: false,
		},

		selected: Boolean,
		// multiple: { type: Boolean, default: false, },
	},
};
</script>

<style lang="sass" scoped >
.nice-select-option
	display: flex
	flex-direction: row
	align-items: center
	justify-content: flex-start
	flex-wrap: nowrap

	padding: 0 .92em

	border: none
	background-color: transparent
	text-align: left
	color: var(--text_color)
	font-size: $ni_select--option-font_size
	line-height: $ni_select--option-line_height
	cursor: pointer

	&:hover
		color: var(--main_color)

.ni_option--icon
	display: block
	width: 1.25em
	height: 1.25em
	margin-right: .92em
	flex-shrink: 0

.ni_option--caption
	display: block
	user-select: none
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap
	flex-grow: 1

.selected
	color: var(--main_color)
</style>
