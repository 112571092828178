<template>
	<section>
		<nice-spoiler
			:label="$t('campaign.poi_label') | capitalize"
			:expand="isSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetPois"
		>

			<div
				class="poi-wrapper"
			>
				<nice-select
					id="pois-select"
					v-model="innerPois"
					:options="poiList"
					:multiple="true"
					:filter="true"
					:filter-placeholder="$t('campaign.select_pois') | capitalize"
					class="poi-select"
				/>

				<nice-input
					v-model="innerRadius"
					type="number"
					step="1"
					:min="0"
					:max="500"
					:units="$t('meters')"
					class="poi-radius"
				/>
			</div>

		</nice-spoiler>
	</section>
</template>


<script>
import _ from 'underscore';

import NiceInput from '@/ui/nice-input';
import NiceSpoiler from '@/ui/nice-spoiler';
import NiceSelect from '@/ui/nice-select';


const EMPTY_POIS = {
	pois: [],
	radius: 0,
};


export default {
	name: 'PoisList',


	components: {
		NiceInput,
		NiceSpoiler,
		NiceSelect
	},


	props: {
		/**
		 * Format: poi1,poi2,...;radius_in_meters
		 */
		pois: {
			type: String,
			default: () => '',
		},

		poiList: {
			type: Array,
			default: () => [],
		},
	},


	data() {
		let { pois, radius } = this.decodePois(this.pois);

		return {
			innerPois: pois,
			innerRadius: radius,
		};
	},


	computed: {
		isSpoilerExpanded() {
			return this.innerPois.length > 0;
		},
	},


	methods: {
		resetPois() {
			this.$set(this, 'innerPois', []);
			this.$set(this, 'innerRadius', 0);
		},

		/**
		 * Get string, return list of pois and radius in meters
		 */
		decodePois(value) {
			if (!value) {
				return EMPTY_POIS;
			}

			let poisArr = value.split(';');

			// wrong format
			if (poisArr.length != 2) {
				return EMPTY_POIS;
			}

			let radius = parseInt(poisArr[1]);
			let pois = _.sortBy(poisArr[0].split(','));

			return {
				pois,
				radius,
			};
		},

		/**
		 * Get pois and radius, return string of pois and radius in meters
		 */
		encodePois(pois, radius) {
			if (!pois || !pois.length || !radius) {
				return '';
			}

			pois = _.sortBy(pois).join(',');
			return `${pois};${radius}`;
		},
	},


	watch: {
		pois: {
			handler(value, old) {
				if (value == this.encodePois(this.innerPois, this.innerRadius)) {
					return;
				}

				let { pois, radius } = this.decodePois(value);

				this.$set(this, 'innerPois', pois);
				this.$set(this, 'innerRadius', radius);
			},
		},

		innerPois: {
			handler(value, old) {
				let valueEncoded = this.encodePois(value, this.innerRadius);
				if (valueEncoded == this.pois) {
					return;
				}

				this.$emit('update:pois', valueEncoded);
			},
		},

		innerRadius: {
			handler(value, old) {
				let valueEncoded = this.encodePois(this.innerPois, value);
				if (valueEncoded == this.pois) {
					return;
				}

				this.$emit('update:pois', valueEncoded);
			},

		},
	},

};
</script>


<style lang="sass" scoped>
.poi-wrapper
	display: flex
	flex-direction: row

	.poi-select
		flex: 1

	.poi-radius
		flex: 0
		width: 60px
		margin-left: 30px

		::v-deep .ni_input--input
			text-align: right

			&[type=number]
				/* Firefox */
				-moz-appearance: textfield

				/* Chrome, Safari, Edge, Opera */
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button
					-webkit-appearance: none
					margin: 0
</style>
