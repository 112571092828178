var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({staticClass:"nice-button",class:( _obj = {
		'__bordered': _vm.bordered,
		'__watercolor': _vm.watercolor,
		'__filled': _vm.filled,
		'__inline': _vm.inline
	}, _obj[_vm.actualPalette] = _vm.actualPalette, _obj['icon-painted'] =  _vm.iconPainted, _obj ),attrs:{"type":_vm.type}},'button',_vm.$attrs,false),_vm.$listeners),[(_vm.$slots.default && _vm.iconPlacement === 'end')?_c('span',{staticClass:"wizard-btn-content"},[_vm._t("default")],2):_vm._e(),(_vm.icon)?_c('nice-icon-2',{staticClass:"ni_button-icon",attrs:{"icon":_vm.icon,"state":_vm.iconState}}):_vm._e(),(_vm.$slots.default && _vm.iconPlacement === 'start')?_c('span',{staticClass:"wizard-btn-content"},[_vm._t("default")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }