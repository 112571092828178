import { render, staticRenderFns } from "./environments-tree.vue?vue&type=template&id=8bc9871e&scoped=true&"
import script from "./environments-tree.vue?vue&type=script&lang=js&"
export * from "./environments-tree.vue?vue&type=script&lang=js&"
import style0 from "./environments-tree.vue?vue&type=style&index=0&id=8bc9871e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bc9871e",
  null
  
)

export default component.exports