import { render, staticRenderFns } from "./tree-el.vue?vue&type=template&id=000d476c&scoped=true&"
import script from "./tree-el.vue?vue&type=script&lang=js&"
export * from "./tree-el.vue?vue&type=script&lang=js&"
import style0 from "./tree-el.vue?vue&type=style&index=0&id=000d476c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000d476c",
  null
  
)

export default component.exports