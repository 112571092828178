<template>
	<symbol :id="`${prefix}melody-15`" viewBox="0 0 14 15">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M4 3.63967C4 2.6863 4.67292 1.8655 5.60777 1.67849L11.6078 0.478477C12.8453 0.23098 14 1.17757 14 2.43966V10.5001C14 11.8808 12.8807 13.0001 11.5 13.0001H11C9.89543 13.0001 9 12.1046 9 11.0001C9 9.89553 9.89543 9.00008 11 9.00008H13V5.2001L5 6.80007V12.5001C5 13.8808 3.88071 15.0001 2.5 15.0001H2C0.895432 15.0001 0 14.1046 0 13.0001C0 11.8955 0.895432 11.0001 2 11.0001H4V3.63967ZM5.80388 2.65908L11.8039 1.45907C12.4227 1.33535 13 1.80862 13 2.43966V4.18026L5 5.78023V3.63967C5 3.16299 5.33646 2.75259 5.80388 2.65908ZM2 12.0001H4V12.5001C4 13.3285 3.32843 14.0001 2.5 14.0001H2C1.44772 14.0001 1 13.5524 1 13.0001C1 12.4478 1.44772 12.0001 2 12.0001ZM13 10.0001H11C10.4477 10.0001 10 10.4478 10 11.0001C10 11.5524 10.4477 12.0001 11 12.0001H11.5C12.3284 12.0001 13 11.3285 13 10.5001V10.0001Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMelody15',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
