<template>
	<symbol :id="`${prefix}bell`" width="17" height="18" viewBox="0 0 17 18" fill="none">

		<path d="M10.1331 2C10.1331 1.46957 9.92234 0.960859 9.54727 0.585786C9.1722 0.210714 8.66349 0 8.13306 0C7.60262 0 7.09392 0.210714 6.71884 0.585786C6.34377 0.960859 6.13306 1.46957 6.13306 2V2.28991C3.24162 3.15049 1.13316 5.82902 1.13316 9V13L0.169631 14.4453C-0.273405 15.1099 0.202986 16 1.00168 16H6.13306C6.13306 16.5304 6.34377 17.0391 6.71884 17.4142C7.09392 17.7893 7.60262 18 8.13306 18C8.66349 18 9.1722 17.7893 9.54727 17.4142C9.92234 17.0391 10.1331 16.5304 10.1331 16H15.2646C16.0633 16 16.5397 15.1099 16.0967 14.4453L15.1332 13V9C15.1332 5.82894 13.0246 3.15036 10.1331 2.28985V2ZM13.1332 13.6056L13.1331 14H3.13306L3.13316 13.6056V9C3.13316 6.23858 5.37174 4 8.13316 4C10.8946 4 13.1332 6.23858 13.1332 9V13.6056Z" class="sign"/>

	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolBell',


	props: {
		prefix: {
			type: String,
			required: true,
		},
		state: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})
	fill-rule: evenodd
	clip-rule: evenodd

</style>
