import baseRest from './base/rest';


const getPrefix = () => 'category/';


export default {
	getPrefix,

	/**
	 * Request openRTB category list
	 *
	 * @returns {object[]} Category
	 * @returns {number} Category.id
	 * @returns {string} Category.parent
	 * @returns {number} Category.version
	 * @returns {number} Category.external_id
	 * @returns {string} Category.name
	 * @returns {number} Category.v1tov2_map
	 */
	'list': baseRest(getPrefix).list,
};
