<template>
	<symbol :id="`${prefix}mark_crossed-15`" viewBox="0 0 14 15">
		<path d="M11.3912 1.62521C13.4296 3.68246 13.5445 7.00659 11.6571 9.2046L8.80004 6.24842C8.92711 6.02814 8.9998 5.77256 8.9998 5.5C8.9998 4.67157 8.32823 4 7.4998 4C7.2455 4 7.00598 4.06328 6.79612 4.17496L3.99126 1.27276C6.15089 -0.534188 9.36824 -0.417511 11.3912 1.62521Z" class="sign" />
		<path d="M2.1166 4.41507C1.7744 6.07446 2.18273 7.86798 3.36039 9.22568L7.49964 14L9.33458 11.8835L11.6045 14.2322L13.0187 12.7689L1.51871 0.869851L0.104492 2.33314L2.1166 4.41507Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMarkCrossed15',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray})
</style>
