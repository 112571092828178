<template>
	<symbol :id="`${prefix}load-min`" viewBox="0 0 12 11">
		<path
			d="M10 6V7C10 8.10254 9.10254 9 8 9H4C2.89697 9 2 8.10254 2 7V6H0V7C0 9.20605 1.79443 11 4 11H8C10.2061 11 12 9.20605 12 7V6H10Z"
			:fill="getColor('main')"
		/>
		<path
			d="M9.94922 3.46435L8.53516 2.05029L7 3.58575V0H5V3.58594L3.46435 2.05029L2.05029 3.46435L6 7.41406L9.94922 3.46435Z"
			:fill="getColor('main')"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconLoadMin',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.graysemidark,
				main__disabled: this.$palette.graysemidark__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
