<template>
	<div
		class="nice-input"
		:class="{
			'invalid': error.is,
			'label_holder': labeled && !(label || hint),
			'labeled': labeled || label || hint,
			[actualPalette]: actualPalette,
		}"
	>
		<nice-label
			v-if="label || hint"
			hint-icon="hint"
			:hint="hint"
			:label="label"
			:error="error.message || error.is"
			class="ni_input--label"
		/>


		<div class="ni_input--input-row" >
			<!-- ref="input" -->
			<imask-input
				:id="id"
				ref="input"
				v-model='actualValue'
				:type="type"
				:disabled="disabled"
				:name="name"
				:placeholder="placeholder"
				v-bind="{
					max: maximum,
					min: minimum,
					...$attrs,
					...maskConfig,
				}"
				class="ni_input--input"
				v-on="inputListeners"
			/>
			<span
				v-if="units"
				class="ni_input--units"
				v-html="units"
			/>
			<slot
				v-if="$slots.default"
			/>
			<nice-button-2
				v-if="hasAutocomplete"
				icon="arrow_corner-12"
				:icon-state="showingAutocompleteList ? 180 : 0"
				class="ni_input--autocomplete-button_dd"
				@click="showAutocompleteList"
			/>
		</div>


		<div
			v-if="showingAutocompleteList"
			v-click-outside="closeAutocompleteList"

			class="ni_input--autocomplete"
		>
			<template
				v-if="autocompleteList && autocompleteList.length"
			>
				<button
					v-for="(variable, index) in autocompleteList"
					:key="index"

					type="button"
					class="ni_input--autocomplete-item"

					@click="autocompleteSet(variable)"
				>
					{{ variable.label || variable }}
				</button>
			</template>

			<span v-else class="ni_input--autocomplete-item empty" >&hellip;</span>
		</div>
	</div>
</template>

<script>
import _ from 'underscore';
import vClickOutside from 'v-click-outside';
import { IMaskComponent, IMaskDirective } from 'vue-imask';

/**
 * https://imask.js.org/guide.html#masked-number
 */
const NUMBER_MASK = {
	mask: Number,  // enable number mask
	radix: '.',  // fractional delimiter
	mapToRadix: [','],  // symbols to process as radix
	scale: 4,
};


const DEFUALT_ID_LENGTH = 10;
const DEFUALT_ID_ITEMS = [
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
	'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
	'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
];


export const AVAILABLE_PALETTES = [
	// 'default',
	// 'gray',
	// 'navy',
	// 'blue',
	// 'purple',
	// 'red',
	// 'yellow',
	// 'green',
];


const MODEL = Object.freeze({
	prop: 'value',
	event: 'input',
});


export default {
	name: 'NiceInput',


	model: MODEL,


	directives: {
		clickOutside: vClickOutside.directive,
		imask: IMaskDirective,
	},

	components: {
		ImaskInput: IMaskComponent,
	},


	props: {
		[MODEL.prop]: { type: [String, Number], default: '', },

		// makes NiceInput top-padded like it is labeled
		labeled: { type: Boolean, default: false, },

		// adds label to the top of
		label: { type: String },

		// hint text
		hint: { type: String },

		// input field id attribute
		id: {
			type: String,
			default: () => {
				let id = 'id-';
				for (var i = 0; i < DEFUALT_ID_LENGTH; i++) {
					let index = _.random(0, DEFUALT_ID_ITEMS.length);
					id += DEFUALT_ID_ITEMS[index];
				}

				return id;
			},
		},

		// input field name attribute
		name: { type: String },

		// input field type attribute
		type: {
			type: String,
			default: 'text',
			validator(v) {
				if (['text', 'tel', 'number', 'url', 'password', 'email'].indexOf(v) !== -1) {
					return true;
				}

				this.$log.warn(`Input type '${v}' not supported by nice-input :(`);
				return false;
			},
		},


		min: {
			type: [String, Date, Number],
			// default: -Infinity,
		},


		max: {
			type: [String, Date, Number],
			// default: Infinity,
		},


		// plaecholder text
		placeholder: { type: String, default: '', },

		// units
		units: { type: String, default: '', },

		// disabled
		disabled: { type: Boolean, default: false, },

		// autocomplete
		autocompleteRequest: {
			type: Function,
			default: null,
		},

		// milliseconds
		debounceAutocomplete: {
			type: Number,
			default: 0,
		},

		// palette
		palette: {
			type: [Object, String],
			default: null,
		},

		// validation props
		// invalid
		invalid: {
			type: [Boolean, String],
			default: false,
		},

		// validator - a function or a regular expression
		validator: {
			type: [Function, RegExp],
			default: null,
		},

		// required
		required: Boolean,

		// mask - imask.js config
		mask: {
			type: [Object, String],
			default: null,
		},
	},


	data() {
		return {
			actualValue: String(this[MODEL.prop]),
			autocompleteList: null,
			autocompleteUsed: false,
			manualShowingAutocompletePopup: false,

			validatorResult: { is: false, message: null },
		};
	},


	computed: {
		actualPalette() {
			if (!_.contains(AVAILABLE_PALETTES, this.palette)) return;
			return this.palette;
		},

		hasAutocomplete() {
			return !!this.autocompleteRequest;
		},

		showingAutocompleteList() {
			return (this.autocompleteList && this.autocompleteList.length) || this.manualShowingAutocompletePopup;
		},

		inputListeners() {
			return _.omit(this.$listeners, [MODEL.event]);
		},

		debouncedCalcAutocompleteList() {
			return _.debounce(this.calcAutocompleteList, this.debounceAutocomplete);
		},

		minimum() {
			if (typeof this.min === 'undefined') {
				return;
			}
			return typeof this.min === 'string' ? parseFloat(this.min) : this.min;
		},


		maximum() {
			if (typeof this.max === 'undefined') {
				return;
			}
			return typeof this.max === 'string' ? parseFloat(this.max) : this.max;
		},


		/**
		 * maskConfig
		 * @return {object} imask.js mask config
		 */
		maskConfig() {
			if (this.mask) {
				return { mask: this.mask };
			}

			if (this.type === 'number') {
				return NUMBER_MASK;
			}

			return null;
		},


		/**
		 * @typedef {Object} Error
		 * @property {Boolean} is - error flag
		 * @property {String} message - error message
		 */

		/**
		 * Error compyted property
		 * @return {Object} result
		 * @return { } [description]
		 */
		error() {
			let is = false;
			let message = null;

			// check required && no value
			if (this.required && !this.actualValue) {
				is = true;
				message = this.$t('errors.input_required_empty');

				return { is, message };
			}

			// check if prop invalid
			if (this.invalid) {
				is = true;
				message = typeof this.invalid === 'string' ? this.invalid : null;

				return { is, message };
			}


			if (this.validatorResult && this.validatorResult.is) {
				return this.validatorResult;
			}

			return { is, message };
		},
	},


	methods: {
		input() {
			return this.$refs.input.$el;
		},

		focus() {
			this.input().focus();
		},

		autocompleteSet(variable) {
			this.$emit('selected', variable);
			this.actualValue = variable.value || variable;
			this.autocompleteList = null;
			this.autocompleteUsed = true;
		},

		async calcAutocompleteList(value) {
			this.autocompleteList = await this.autocompleteRequest(value);
		},

		closeAutocompleteList() {
			this.autocompleteList = null;
			this.manualShowingAutocompletePopup = false;
		},

		async showAutocompleteList() {
			await this.calcAutocompleteList(this.actualValue);
			this.manualShowingAutocompletePopup = true;
		},


		// applies validator from props
		applyValidator() {
			let is = false;
			let message = null;

			if (typeof this.validator === 'function') {
				try {
					is = this.validator(this.actualValue);
				} catch(error) {
					is = true;
					message = error;
				}
			}

			if (this.validator instanceof RegExp) {
				is = !this.validator.test(this.actualValue);
			}

			return { is, message };
		},
	},


	watch: {
		[MODEL.prop]: function(newValue, oldValue) {
			this.actualValue = String(newValue);
		},

		actualValue(newValue, oldValue) {
			if (this.type == 'number' && typeof this.$attrs.min != 'undefined' && newValue < parseFloat(this.$attrs.min)) {
				newValue = this.$attrs.min;
			}

			this.$set(this, 'validatorResult', this.applyValidator(newValue));
			this.$emit(MODEL.event, newValue);

			if (this.autocompleteRequest && !this.autocompleteUsed && newValue) {
				this.debouncedCalcAutocompleteList(newValue);
			}

			this.autocompleteUsed = false;
		},
	},
};
</script>

<style lang="sass" scoped >
.nice-input
	--default-color: var(--text_2_color)
	--hover-color: var(--text_2_color)
	--active-color: var(--text_2_color)
	--disabled-color: var(--disabled_color)
	--text-color: var(--text_color)

	position: relative
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch
	box-sizing: border-box

	border-bottom: 1px solid var(--default-color)

	color: var(--default-color)

	&.invalid
		--default-color: #{$nice_color-red}


	&.label_holder
		padding-top: $ni-label-height

	::v-deep .nice-label .ni_label--label
		color: var(--default-color)

	// themes
	// &.gray
	// 	--default-color: #{$nice_color-gray_dark}
	// 	--disabled-color: #{$nice_color-gray_light_semi}
	// 	--hover-color: #{$nice_color-gray_dark_semi}
	// 	--active-color: #{$nice_color-gray_dark}
	// 	--text-color: #{$nice_color-gray_dark}

	// &.navy
	// 	--default-color: #{$nice_color-navy}
	// 	--disabled-color: #{$nice_color-navy_light_ultra}
	// 	--hover-color: #{$nice_color-navy}
	// 	--active-color: #{$nice_color-navy}
	// 	--text-color: #{$nice_color-navy}

	// &.red
	// 	--default-color: #{$nice_color-red}
	// 	--disabled-color: #{$nice_color-red_light_ultra}
	// 	--hover-color: #{$nice_color-red}
	// 	--active-color: #{$nice_color-red}
	// 	--text-color: #{$nice_color-red}

	// &.purple
	// 	--default-color: #{$nice_color-purple}
	// 	--disabled-color: #{$nice_color-purple_light_ultra}
	// 	--hover-color: #{$nice_color-purple}
	// 	--active-color: #{$nice_color-purple}
	// 	--text-color: #{$nice_color-purple}

	// &.blue
	// 	--default-color: #{$nice_color-blue}
	// 	--disabled-color: #{$nice_color-blue_light_ultra}
	// 	--hover-color: #{$nice_color-blue}
	// 	--active-color: #{$nice_color-blue}
	// 	--text-color: #{$nice_color-blue}

	// &.green
	// 	--default-color: #{$nice_color-green}
	// 	--disabled-color: #{$nice_color-green_light_ultra}
	// 	--hover-color: #{$nice_color-green}
	// 	--active-color: #{$nice_color-green}
	// 	--text-color: #{$nice_color-green}

	// &.yellow
	// 	--default-color: #{$nice_color-yellow_dark_semi}
	// 	--disabled-color: #{$nice_color-yellow_light_ultra}
	// 	--hover-color: #{$nice_color-yellow}
	// 	--active-color: #{$nice_color-yellow_dark_semi}
	// 	--text-color: #{$nice_color-yellow_dark_semi}

.ni_input--input-row
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center

	> *:not(:first-child):not(.ni_input--autocomplete-button_dd)
		margin-left: .4em

.ni_input--input
	flex-grow: 1
	height: $ni-input-height
	padding: 0

	border: none
	color: var(--text-color)
	font-size: $fsz__new__normal
	line-height: $txt__line_height
	overflow: hidden
	appearance: textfield


	&::placeholder
		color: var(--text_2_color)

	&:disabled
		background-color: transparent

.ni_input--units
	// color: var(--text_2_color)
	color: var(--text-color)

.ni_input--autocomplete-button_dd
	width: $ni_select--button_dd-size
	min-height: $ni_select--button_dd-size
	padding-left: $ni_select--button_dd-indent
	padding-right: $ni_select--button_dd-indent

	--ni-icon-sign: #000
	--ni-icon-sign-stroke-width: 1

.ni_input--autocomplete
	+shadowed_block

	position: absolute
	top: 100%
	left: 0
	z-index: 1

	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: flex-start

	box-sizing: border-box
	width: 100%
	padding: $cmpnt_tippy-indent-y $cmpnt_tippy-indent-x

	border-radius: 0 0 5px 5px

.ni_input--autocomplete-item
	+button__clear

	margin: 4px 0
	min-height: auto
	padding: 0 0
	width: 100%

	text-align: left
	text-transform: none

	&:first-child
		margin-top: 0

	&:last-child
		margin-bottom: 0

	&.empty
		text-align: center
		// color: var(--text_2_color)
		color: var(--default-color)
		text-transform: none

	&:hover
		background-color: #{$nice_color-gray_light}
</style>
