<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="8"
			:fill="getColor('sign')"
			stroke="none"
		/>
		<polygon
			:fill="getColor('icon')"
			stroke="none"
			points="12,5.5 10.5,4 8,6.6 5.5,4 4,5.5 6.6,8 4,10.5 5.5,12 8,9.4 10.5,12 12,10.5 9.4,8 "
		/>
	</symbol>
</template>

<script>
export const slug = 'cancel';


export default {
	name: 'NiIconCancel',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				icon: this.$palette.white,
				sign: this.$palette.red,
				icon__disabled: this.$palette.white__disabled,
				sign__disabled: this.$palette.red__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
