<template>
	<mgl-map
		ref="mglMap"
		class="map"
		:accessToken="accessToken"
		:mapStyle.sync="mapStyle"
		:center="center"
		:zoom="zoom"
		:transformRequest="transformRequest"

		@data="onDataInit"
		@load="onMapLoaded"
		@click="mapClick"
	>

		<!-- props needed for GeolocateControl -->
		<!-- camelCase is required (fly-to doesn't work) -->
		<!-- types: country,region,postcode,district,place,locality,neighborhood,address,poi. -->
		<mgl-geocoder-control
			v-if="enableGeocoder"
			ref="geocoder"
			:accessToken="accessToken"
			:marker="false"
			:flyTo="false"
			types="region,district,place,locality,neighborhood,address,poi"
		/>

		<mgl-navigation-control
			position="top-right"
		/>

		<template
			v-if="selectedAreasData"
		>
			<!-- selected areas -->
			<mgl-geojson-layer
				:sourceId="selectedAreasSourceId"
				:source="selectedAreasSource"

				:layerId="areasLayer.id"
				:layer="areasLayer"
			/>

			<!-- selected areas centers -->
			<mgl-geojson-layer
				:sourceId="selectedAreasCentersSourceId"
				:source="selectedAreasCentersSource"

				:layerId="areasCentersLayer.id"
				:layer="areasCentersLayer"

				@mouseenter="changeCursor(true)"
				@mouseleave="changeCursor(false)"
				@click="showExistingAreaPopup"
			/>
		</template>

		<template
			v-if="source"
		>
			<!-- load devices and show cluster -->
			<mgl-geojson-layer
				:sourceId="sourceId"
				:source="source"

				:layerId="clusterLayer.id"
				:layer="clusterLayer"

				@mouseenter="changeCursor(true)"
				@mouseleave="changeCursor(false)"
				@click="showDeviceClusterPopup"
			/>

			<!-- device markers -->
			<mgl-geojson-layer
				:sourceId="sourceId"
				:layerId="deviceLayer.id"
				:layer="deviceLayer"

				@mouseenter="changeCursor(true)"
				@mouseleave="changeCursor(false)"
				@click="showDevicePopup"
			/>

			<!--
			Popups
			-->
			<map-popup-device
				v-if="devicePopupData"
				:coordinates="devicePopupData.geometry.coordinates"
				:device="devicePopupData.properties"
				:without-control="withoutControl"
				:campaign-id="campaignId"

				@update-required="update"
				@close="closeDevicePopup"
			/>

			<map-popup-device-cluster
				v-if="deviceClusterPopupData"
				:coordinates="deviceClusterPopupData.coordinates"
				:devices="deviceClusterPopupData.devices"
				:without-control="withoutControl"
				:campaign-id="campaignId"

				@update-required="update"
				@close="closeDeviceClusterPopup"
			/>

			<map-popup-area
				v-if="tempArea && !withoutControl"
				:key="popupAreaKey"
				:without-control="withoutControl"
				:temp-area.sync="tempArea"

				@add-area="addArea"
				@remove-area="removeArea"
				@close="closeAreaPopup"
			/>
		</template>

	</mgl-map>
</template>

<script>
import _ from 'underscore';
import { mapState } from 'vuex';
import { MglMap, MglNavigationControl, MglGeojsonLayer } from 'vue-mapbox';
import MglGeocoderControl from '@geospoc/v-mapbox-geocoder';
import MglGeocoder from '@mapbox/mapbox-gl-geocoder';
import { getBaseApiUrl } from '@/api/request';
import { getGeofeatureName } from '@/utilites';


import MapPopupDevice from './popup-device';
import MapPopupDeviceCluster from './popup-device-cluster';
import MapPopupArea from './popup-area';
import {
	MAPBOX_CENTER_MSK,
	MAPBOX_CENTER_ZOOM,
	MAPBOX_CENTER_SPEED,

	SOURCE_ID,
	SOURCE,

	AREAS_SOURCE_ID,
	AREAS_CENTERS_SOURCE_ID,
	AREAS_CENTERS_LAYER,
	AREAS_LAYER,
	CLUSTER_MARKER_LAYER,
	DEVICE_LAYER,
	DEFAULT_RADIUS,

	drawCircle,
} from '../defaults';


export default {
	name: 'MapDeviceMapbox',


	components: {
		MapPopupDevice,
		MapPopupDeviceCluster,
		MapPopupArea,
		MglGeocoderControl,
		MglGeojsonLayer,
		MglMap,
		MglNavigationControl,
	},


	props: {
		// MAP ////////////////////////////////////////////////////////////////////////////////////
		/**
		 * Url or Object with devices markers data
		 */
		sourceData: {
			type: [Object, String],
			required: true
		},

		/**
		 * Url or Object with selected areas data
		 */
		selectedAreasData: {
			type: Array,
			// required: true
		},

		/**
		 * Init zoom
		 */
		zoom: {
			type: Number,
			default: 3,
		},

		/**
		 * map style string
		 */
		mapStyle: {
			type: [String, Object],
			default: process.env.VUE_APP_MAPBOX_STYLES,
		},


		// MARKERS ///////////////////////////////////////////////////////////////////////////////
		/**
		 * Config for clusters markers layer
		 */
		clusterLayer: {
			type: Object,
			default: () => CLUSTER_MARKER_LAYER,
		},

		/**
		 * Config for devices markers layer
		 */
		deviceLayer: {
			type: Object,
			default: () => DEVICE_LAYER,
		},

		/**
		 * Config for areas layer
		 */
		areasLayer: {
			type: Object,
			default: () => AREAS_LAYER,
		},

		/**
		 * Config for areas layer
		 */
		areasCentersLayer: {
			type: Object,
			default: () => AREAS_CENTERS_LAYER,
		},


		// POPUPS /////////////////////////////////////////////////////////////////////////////////
		/**
		 * Hide control
		 */
		withoutControl: {
			type: Boolean,
			required: false
		},

		/**
		 * Data with devices selected and zones
		 *
		 * @returns {object[]} Zone
		 * @returns {number} Zone.device - device_id
		 * @returns {number} Zone.distance - zone radius in meters
		 * @returns {string} Zone.condition - exclude or include zone
		 */
		zoneList: {
			type: Array,
			default: () => [],
		},

		// MAPBOX GEOCODER ////////////////////////////////////////////////////////////////////////
		enableGeocoder: {
			type: Boolean,
			default: false,
		},

		searchQuery: {
			type: String,
			default: '',
		},

		campaignId: {
			type: Number,
			default: null,
		},
	},


	data() {
		return {
			accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
			sourceId: SOURCE_ID,
			selectedAreasSourceId: AREAS_SOURCE_ID,
			selectedAreasCentersSourceId: AREAS_CENTERS_SOURCE_ID,
			center: MAPBOX_CENTER_MSK,

			searchList: [],

			// popups' data
			devicePopupData: null,
			deviceClusterPopupData: null,
			tempArea: null,
		};
	},


	computed: {
		...mapState('profile', [ 'token' ]),

		source() {
			if (!this.sourceData) return null;

			let data = this.sourceData;
			let zoneList = _.clone(this.zoneList);
			if (zoneList) {
				_.find(data.features, (device, i, l) => {
					let pickedZones = [];
					_.each(zoneList, (zone, i, l) => {
						if (zone.device === device.properties.id) {
							if (device.properties.zoneList) {
								device.properties.zoneList.push(zone);
							} else {
								device.properties.zoneList = [zone];
							}

							pickedZones.push(i);
						}
					});

					if (pickedZones) {
						zoneList = _.omit(zoneList, pickedZones);
					}

					return !zoneList.length;
				});
			}

			return {
				...SOURCE,
				data: data,
			};
		},

		selectedAreasSource() {
			if (!this.selectedAreasData && !this.tempArea) return null;

			const features = [];

			const adaptArea = (area) => ({
				'type': 'Feature',
				'geometry': {
					'type': 'Polygon',
					'coordinates': drawCircle(
						[
							area.longitude,
							area.latitude
						],
						area.radius
					),
				},
				'properties': {
					'action': area.action,
				}
			});

			for (let area of this.selectedAreasData) {
				if (this.tempArea !== null && this.tempArea.id === area.id) {
					continue;
				}
				features.push(adaptArea(area));
			}

			if (this.tempArea !== null) {
				features.push(adaptArea(this.tempArea));
			}

			return {
				type: 'geojson',
				data: {
					'type': 'FeatureCollection',
					'features': features,
				},
			};
		},

		selectedAreasCentersSource() {
			if (!this.selectedAreasData && !this.tempArea) return null;

			const features = [];

			const adaptArea = (area) => (
				{
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': [
							area.longitude,
							area.latitude
						],
					},
					'properties': {
						'longitude': area.longitude,
						'latitude': area.latitude,
						'id': area.id,
						'action': area.action,
						'radius': area.radius,
						'title': area.title,
						'sorting': area.sorting,
					}
				}
			);

			for (let area of this.selectedAreasData) {
				if (this.tempArea !== null && this.tempArea.id === area.id) {
					continue;
				}
				features.push(adaptArea(area));
			}

			if (this.tempArea !== null) {
				features.push(adaptArea(this.tempArea));
			}

			return {
				type: 'geojson',
				data: {
					'type': 'FeatureCollection',
					'features': features,
				},
			};
		},
	},


	methods: {
		/**
		 * Not working for custom urls
		 */
		transformRequest(url, resourceType) {
			let result = {
				url: url,
				headers: {
				},
			};

			if (url.startsWith(getBaseApiUrl())) {
				result.headers['X-Authorization'] = `Bearer ${this.token}`;

				if (process.env.VUE_APP_API_AUTH_HEADER) {
					result.headers.Authorization = process.env.VUE_APP_API_AUTH_HEADER;
				}
			}

			return result;
		},

		/**
		 * Change cursor on marker hover
		 * Set class in template for mgl-map entire component refresh (zoom, center etc.)
		 */
		changeCursor(val) {
			const map = this.getMapboxMap();
			if (!map) {
				return;
			}

			const canvas = map.getCanvas();

			if (val) {
				canvas.classList.add('active_cursor');
			} else {
				canvas.classList.remove('active_cursor');
			}
		},

		/**
		 * Currently to remove a popup with a device description
		 */
		mapClick(e) {
			const map = this.getMapboxMap();
			if (!map) {
				return;
			}
			// check if there is a device in this coords
			var bbox = [
				[e.mapboxEvent.point.x, e.mapboxEvent.point.y],
				[e.mapboxEvent.point.x, e.mapboxEvent.point.y]
			];

			var features = map.queryRenderedFeatures(
				bbox,
				{
					layers: [
						CLUSTER_MARKER_LAYER.id,
						DEVICE_LAYER.id,
						AREAS_CENTERS_LAYER.id
					]
				}
			);

			if (features.length === 0) {
				this.showAreaPopup(e);
			}
		},

		closeAllPopups(e) {
			this.closeAreaPopup(e);
			this.closeDevicePopup(e);
			this.closeDeviceClusterPopup(e);
		},

		showDevicePopup(e) {
			this.closeAllPopups(e);
			this.devicePopupData = e.mapboxEvent.features[0];
		},

		closeDevicePopup(e) {
			this.devicePopupData = null;
		},

		showExistingAreaPopup(e) {
			if (this.withoutControl) {
				return;
			}

			this.closeAllPopups(e);

			this.popupAreaKey = Math.random();
			const area = { ...e.mapboxEvent.features[0].properties };

			this.tempArea = area;
		},

		async showAreaPopup(e) {
			if (this.withoutControl) {
				return;
			}

			this.closeAllPopups(e);

			this.popupAreaKey = Math.random();
			this.coordinates = [e.mapboxEvent.lngLat.lng, e.mapboxEvent.lngLat.lat];

			const mapboxgl = this.getMapboxMap();

			// TODO: HACK: get from agency settings
			const worldview = this.$i18n.locale.substring(0, 2) === 'ru' ? 'ru' : 'us';

			// since v4.7.4 `reverseGeocode: true` option is needed
			const geocoder = new MglGeocoder({
				mapboxgl,
				accessToken: this.accessToken,
				language: this.$i18n.locale,
				worldview: worldview,
				limit: 1,
				types: 'region,district,place,locality,neighborhood,address,poi',
				reverseGeocode: true,
			});
			mapboxgl.addControl(geocoder);

			// v4.5.1 was `[lng, lat]`, since v4.7.4 it is `[lat, lng]`
			const result = await geocoder._geocode(String([e.mapboxEvent.lngLat.lat, e.mapboxEvent.lngLat.lng]));
			const title = result.body.features[0] ? getGeofeatureName(result.body.features[0]) : '';


			mapboxgl.removeControl(geocoder);

			const sorting = Math.max(...this.selectedAreasData.map((area) => area.sorting)) + 1;
			this.tempArea = {
				longitude: e.mapboxEvent.lngLat.lng,
				latitude: e.mapboxEvent.lngLat.lat,
				radius: DEFAULT_RADIUS,
				title,
				action: 'filtered',  // TODO: add new icon and action to mapBox
				sorting: sorting < 0 ? 0 : sorting,
			};
		},

		closeAreaPopup(e) {
			this.tempArea = null;
		},

		// TODO: ...
		async showDeviceClusterPopup(e) {
			this.closeAllPopups(e);

			// `e.mapboxEvent.features` or
			// `e.map.queryRenderedFeatures(e.point)`
			// should be 1 cluster
			const map = e.map;
			const clusterFeature = e.mapboxEvent.features[0];
			const source = map.getSource(this.sourceId);
			const devices = await this._getClusterDevices(source, clusterFeature);

			this.deviceClusterPopupData = {
				coordinates: [
					e.mapboxEvent.lngLat.lng,
					e.mapboxEvent.lngLat.lat
				],
				devices: devices,
			};
		},

		closeDeviceClusterPopup(e) {
			this.deviceClusterPopupData = null;
		},

		_getClusterDevices(source, cluster) {
			return new Promise((resolve, reject) => {
				// TODO: how to get unlimited number of points?
				const limit = 1000000;
				const offset = 0;
				source.getClusterLeaves(cluster.id, limit, offset, (error, features) => {
					if (error) {
						reject(error);
						return;
					}

					resolve(features);
				});
			});
		},

		async onDataInit() {
			const map = this.$refs.mglMap.map;
			// Disable all borders
			var borders = ['admin-0-boundary', 'admin-1-boundary', 'admin-0-boundary-disputed', 'admin-0-boundary-bg'];
			borders.forEach(function (border) {
				map.setLayoutProperty(border, 'visibility', 'none');
			});
		},

		async onMapLoaded(event) {
			const asyncActions = event.component.actions;

			event.map.setLanguage(this.$i18n.locale);

			// TODO: HACK: get from agency settings
			const worldview = this.$i18n.locale.substring(0, 2) === 'ru' ? 'ru' : 'us';
			event.map.setWorldview(worldview);

			await asyncActions.flyTo({
				center: this.center,
				zoom: MAPBOX_CENTER_ZOOM,
				speed: MAPBOX_CENTER_SPEED,
			});

			this.$emit('map-load', event);
		},

		successCoords(position) {
			let pos = [position.coords.longitude, position.coords.latitude];
			this.$set(this, 'center', pos);
		},

		/**
		 * Hide some checks here
		 *
		 * TODO: is there another way?
		 */
		getMapboxMap() {
			if (!this.$refs.mglMap || !this.$refs.mglMap.map) {
				return null;
			}

			return this.$refs.mglMap.map;
		},

		update() {
			this.$emit('update');
		},

		addArea(newArea) {
			const areas = [ ...this.selectedAreasData ];
			let index = -1;

			if (newArea.id) {
				index = areas.findIndex((item) => item.id === newArea.id);
			}

			if (index === -1) {
				index = areas.length;
			}

			areas.splice(index, 1, newArea);

			this.$emit('update:selectedAreasData', areas);
		},

		removeArea(area) {
			const areas = [ ...this.selectedAreasData ];
			const index = areas.findIndex((item) => item.id === area.id);
			if (index !== -1) {
				areas.splice(index, 1);
			}

			this.$emit('update:selectedAreasData', areas);
		}
	},


	watch: {
		searchQuery(newValue, oldValue) {
			if (!this.$refs.geocoder) {
				this.$emit('searchListRequest', []);
			}

			// ...query() return MapboxGeocoder 
			// return this..$refs.geocoder.control.query(newValue);
			const result = this
				.$refs
				.geocoder
				.control
				._geocode(newValue)
				.then((data) => {
					return data.body.features;
				});

			this.$emit('searchListRequest', result);
		},
	},


	created() {
		navigator.geolocation.getCurrentPosition(this.successCoords);
	},


	beforeDestroy() {
		// HACK: remove layers first
		const map = this.getMapboxMap();
		if (!map) {
			return;
		}

		const layersToRemove = [
			CLUSTER_MARKER_LAYER.id,
			DEVICE_LAYER.id,
			AREAS_LAYER.id,
			AREAS_CENTERS_LAYER.id,
		];

		for (let layerId of layersToRemove) {
			if (map.getLayer(layerId)) {
				map.removeLayer(layerId);
			}
		}
	},
};
</script>

<style lang="sass" scoped >
.map ::v-deep .active_cursor
	cursor: pointer
</style>
