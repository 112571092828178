<template>
	<symbol :id="`${prefix}cancel-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<g class="sign">
			<circle cx="12" cy="12" r="6" stroke-width="2"/>
			<path d="M15 8L9 16" stroke-width="2"/>
		</g>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolCancel24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})
	fill: none
</style>
