import { render, staticRenderFns } from "./audience-cpm.vue?vue&type=template&id=4f645f95&scoped=true&"
import script from "./audience-cpm.vue?vue&type=script&lang=js&"
export * from "./audience-cpm.vue?vue&type=script&lang=js&"
import style0 from "./audience-cpm.vue?vue&type=style&index=0&id=4f645f95&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f645f95",
  null
  
)

export default component.exports