<template>
	<section>
		<div class="page-wrapper layout-full_width">

			<div class="top-panel">
				<nice-button-2
					icon="arrow-14"
					icon-state="90"
					@click="goBack"
				>
					{{ $t('back') | capitalize }}
				</nice-button-2>
			</div>

			<template
				v-if="user"
			>
				<header class="header">
					<details-header
						:title="`${user.first_name} ${user.last_name}`"
						:subtitle="user.email"
						class="header__details-header"
					/>
				</header>

				<!-- <user-card
					v-model="user"
				/> -->
			</template>


			<NiceTabs
				v-if="agency && user"
			>
				<section :data-ni_tab_name="$t('common.platforms') | capitalize">
					{{ /* v-model: platforms*/ }}
					<SspViewerPlatformsList
						:platforms="user.ssp_viewer_platforms"
						:user="user"
						:agency="agency"

						@update="updatePlatforms"
					/>
				</section>
			</NiceTabs>

		</div><!-- END: .page-wrapper -->

		<!-- Network devices modal here -->
		<router-view
			@update-required="refresh"
		/>
	</section>
</template>


<script>
import {
	mapState,
	// mapActions,
	// mapMutations,
} from 'vuex';

import { requestAgencyUser } from '@/api/agency';
import { updateSSPViewerNetworks } from '@/api/ssp_viewer';

import NiceTabs from '@/ui/nice-tabs';
// import UserCard from '@/components/users/user-card';
import DetailsHeader from '@/components/users/details-header';
import SspViewerPlatformsList from '@/components/users/ssp-viewer-platforms-list';
// import TablePaginator from '@/components/table-paginator';
// import NiceModal from '@/ui/nice-modal';


export default {
	name: 'PageUserSSPViewerDetails',


	components: {
		NiceTabs,
		// UserCard,
		DetailsHeader,
		SspViewerPlatformsList,
		// TablePaginator,
		// NiceModal,
	},


	props: {
		// ssp viewer ID
		id: {
			type: Number,
			required: true,
		},
		// ssp viewer agency ID
		agencyId: {
			type: Number,
			required: true,
		},
	},


	data() {
		return {
			// get user and agency on mount
			user: null,
			agency: null,
		};
	},


	async mounted() {
		let loading = this.$loading.show();
		await this.refresh();
		loading.hide();
	},


	computed: {
		...mapState('app', {
			appSide: 'side',
		}),

		...mapState('agency', {
			platforms: 'ssp_networks',
		}),

	},


	methods: {

		async refresh() {
			this.$set(this, 'agency', await this.getAgency());
			this.$set(this, 'user', await this.getUser());
		},

		/**
		 * TODO: do we need to reget it? map it?
		 */
		async getAgency() {
			return this.$store.state.agency;
		},


		async getUser() {
			return await requestAgencyUser(this.agencyId, this.id, this.appSide);
		},


		/**
		 * `updateSSPViewerNetworks()` returns a ssp viewer instance
		 */
		async updatePlatforms(platforms) {
			this.$set(this, 'user', await updateSSPViewerNetworks(this.agencyId, this.id, platforms));
		},


		/**
		 * Go back to `/me#users` ("users" must be localized)
		 */
		async goBack() {
			this.$router.push({
				name: 'profile',
				hash: '#' + this.$t('common.users'),
			});
		},

	},  // END: methods

};
</script>


<style lang="sass" scoped >
.top-panel
	position: sticky
	top: 0
	z-index: 1

	display: flex
	flex-direction: row
	align-items: center
	justify-content: space-between
	margin: 0
	padding: 30px
	width: auto

	background: #fff


.header
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 24px

	& > *
		margin-right: 40px

		&:last-child
			margin-right: 0


// TODO: margin is eaten somehow
::v-deep .nice-tabs
	padding-bottom: 30px
</style>
