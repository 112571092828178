// import SpecificationTextRU from '@/locales/partials/creative/specification-ru.html';
import SpecificationTextEN from '@/locales/partials/creative/specification-en.html';
import SpecificationTextGE from '@/locales/partials/creative/specification-ge.html';
import SpecificationTextDE from '@/locales/partials/creative/specification-de.html';

import PolicyTextEN from '@/locales/partials/creative/policy-en.html';
import PolicyTextGE from '@/locales/partials/creative/policy-ge.html';
import PolicyTextDE from '@/locales/partials/creative/policy-de.html';


export default {
	specification_modal_title: {
		en: 'creative specification',
		ge: 'შექმნის სპეციფიკაცია',
		de: 'Kreativ-Spezifikation',
		ru: 'спецификация',
		cn: /* TODO: to be translated */'creative specification',
	},

	policy_modal_title: {
		en: 'creative policy',
		ge: 'შექმნის პოლიტიკა',
		de: 'Kreativ-Richtlinie',
		ru: 'политика',
		cn: /* TODO: to be translated */'creative policy',
	},

	specification_text: {
		en: SpecificationTextEN,
		ge: SpecificationTextGE,
		de: SpecificationTextDE,
		ru: SpecificationTextEN,
		cn: SpecificationTextEN,
	},

	policy_text: {
		en: PolicyTextEN,
		ge: PolicyTextGE,
		de: PolicyTextDE,
		ru: PolicyTextEN,
		cn: PolicyTextEN,
	},
};
