<script>
/**
 * Component returns provided vnode as itself
 * You can use it to render sloted component
 */
export default {
	// prevent $attrs applying to $el
	inheritAttrs: false,

	props: {
		vnode: {
			required: true,
		},
	},


	render(h) {
		const vnode = this.vnode;

		// merge propsData
		if (vnode && vnode.componentOptions) {
			vnode.componentOptions.propsData = { ...this.$attrs, ...vnode.componentOptions.propsData };
		}

		return vnode;
	},
};
</script>
