<template>
	<section class="layout-full_width" >
		<h1>NiceIcon2</h1>
		<p>Новые иконки, отсутствует палитра, нет состояния disabled</p>

		<table class="nice-table" >
			<thead>
				<tr>
					<th>Slug / Name</th>
					<th
						v-for="size in sizesTotal"
						:key="size"

						class="column-icon"
						v-html="size"
					/>
					<th class="column-icon" >State</th>
					<th>Example</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="icon in icons"
					:key="icon.name"
				>
					<td class="name" >{{ icon.name }}</td>
					<td
						v-for="size in sizesTotal"
						:key="size"

						class="column-icon"
					>
						<nice-icon-2
							v-if="icon.sizes.includes(size)"

							:icon="`${icon.name}${size === 'default' ? '' : '-'+size}`"
						/>
						<span v-else >&mdash;</span>
					</td>
					<td class="column-icon" >
						<nice-icon-2

							:icon="`${icon.name}${icon.sizes[0] === 'default' ? '' : '-'+icon.sizes[0]}`"
							:state="icon.state"
						/>
					</td>
					<td>
						<code class="code-preview" >&lt;nice-icon-2 icon="{{ icon.name }}{{ icon.sizes[0] === 'default' ? '' : '-'+icon.sizes[0] }}" {{ icon.state ? `:state="${icon.state}"` : '' }} /&gt;</code>
					</td>
				</tr>
			</tbody>
		</table>

		<h3>Изменение цвета</h3>
		<p>Для изменения цвета используем переменные css:</p>
		<p>
			<nice-icon-2 icon="check-24" style="--ni-icon-base: #d1d5da; --ni-icon-sign: #1a2e46;" />
			<code class="code-preview" >&lt;nice-icon-2 icon="check-24" style="--ni-icon-base: #d1d5da; --ni-icon-sign: #1a2e46;" /&gt;</code>
		</p>
	</section>
</template>

<script>
// NiceIcon2 is a global component - registered in main.js
// import NiceIcon2 from '@/ui/nice-icon-2';
import _ from 'underscore';


export default {
	name: 'TabIcons',


	// components: {
	// 	NiceIcon2,
	// },


	data() {
		return {
			icons: [
				// {
				// 	name: 'logo',
				// 	sizes: ['34'],
				// },
				{
					name: 'arrow',
					sizes: ['14'],
					state: 42,
				},
				{
					name: 'arrow_circle',
					sizes: ['default', '24', '34'],
					state: 42,
				},
				{
					name: 'arrow_circle_2',
					sizes: ['default'],
					state: 42,
				},
				{
					name: 'arrow_corner',
					sizes: ['12', '24'],
					state: 42,
				},
				{
					name: 'audio',
					sizes: ['default'],
				},
				{
					name: 'basket',
					sizes: ['14', '24'],
				},
				{
					name: 'calendar',
					sizes: ['14'],
				},
				{
					name: 'cancel',
					sizes: ['24'],
				},
				{
					name: 'check',
					sizes: ['default', '24', '34'],
				},
				{
					name: 'diagram',
					sizes: ['default', ],
					state: 35,
				},
				{
					name: 'draft',
					sizes: ['default', '24', '34'],
				},
				// {
				// 	name: 'edit',
				// 	sizes: ['24'],
				// },
				{
					name: 'error',
					sizes: ['default', '24', '34'],
				},
				{
					name: 'hint',
					sizes: ['default'],
				},
				{
					name: 'monitor',
					sizes: ['13', '17'],
				},
				{
					name: 'man',
					sizes: ['13'],
				},
				{
					name: 'melody',
					sizes: ['15'],
				},
				{
					name: 'load',
					sizes: ['default', '24'],
					state: 42,
				},
				{
					name: 'pause',
					sizes: ['default', '24', '34'],
				},
				{
					name: 'pencil',
					sizes: ['13', '24', '34'],
				},
				{
					name: 'play',
					sizes: ['default', '13', '24', '34'],
				},
				{
					name: 'plus',
					sizes: ['24', '17', '34', '20'],
				},
				{
					name: 'plus_another',
					sizes: ['20'],
				},
				{
					name: 'minus_another',
					sizes: ['20'],
				},
				{
					name: 'mark',
					sizes: ['14'],
				},
				{
					name: 'mark_crossed',
					sizes: ['15'],
				},
				{
					name: 'resolution',
					sizes: ['14'],
					state: 90
				},
				{
					name: 'see',
					sizes: ['24', '34'],
				},
				{
					name: 'warn',
					sizes: ['default', '24', '34'],
				},
			]
		};
	},


	computed: {
		sizesTotal() {
			let sizes = ['default'];
			_.each(this.icons, (icon, i, l) => {
				sizes = _.union(sizes, icon.sizes);
			});

			return sizes;
		},
	},
};
</script>


<style lang="sass" scoped >
.code-preview
	margin-left: 20px

	table &
		margin-left: 0
		color: var(--text_color)

.description
	font-size: 0.6em

.name
	color: var(--text_color)

.column-icon
	text-align: center

.finished
	color: red
	::v-deep
		.base
			fill: $nice_color-blue-gray

		.sign
			fill: $nice_color-blue_dark
</style>
