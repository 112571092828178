<template>
	<main class="page-auth" >
		<section class="auth-container">
			<nice-radio
				v-for="agency in availableAgenciesList"
				:key="agency.id"
				v-model="selectedAgencyId"
				name="agency"
				:value="agency.id"
				:caption="`${agency.name} (${$t('invitation.' + agency.role)})`"

				@update:modelValue="selectedAgency=agency"
			/>

			<nice-button-2
				type="button"
				icon="arrow-14"
				:icon-state="-90"
				icon-placement="end"
				icon-painted
				:disabled="!selectedAgency || isBusy"
				class="submit_button"
				@click="switchAgencyAndRole(selectedAgency)"
			>Confirm Agency Selection</nice-button-2>
		</section>
	</main>
</template>


<script>
import NiceRadio from '../ui/nice-radio.vue';
import { requestAgencies } from '../api/agency';


export default {
	name: 'ChooseAgency',


	components: {
		NiceRadio,
	},


	data() {
		return {
			isBusy: false,
			// old and for v-model. nice-radio needs only number or string
			selectedAgencyId: null,
			selectedAgency: null,
			agencies: []
		};
	},


	computed: {
		availableAgenciesList() {
			let agencies = [];

			for (let agency of this.agencies) {
				for (let role of agency.roles) {
					const side = role.substring(0, 3);

					agencies.push({
						id: agency.id,
						name: agency.name,
						role: role,
						side: side,
					});
				}
			}

			return agencies;
		},
	},


	async mounted() {
		this.agencies = await requestAgencies();
	},


	methods: {
		switchAgencyAndRole(agency) {
			// we need all three of these and reload
			this.$store.commit('app/setAgency', agency.id);
			this.$store.commit('app/setRole', agency.role);
			this.$store.commit('app/setSide', agency.side);

			// reload the whole thing
			window.location.href = '/';
		},
	},  // end: methods
};

</script>

<style lang="sass" scoped>
main.page-auth
	display: flex
	align-items: center
	justify-content: center

	min-height: 100vh

	// background-color: #f5f5f5

	h2
		margin: 0 0 10px

	.logo
		width: 27px
		height: 27px
		vertical-align: middle

.auth-container
	margin: 0 auto
	width: 100%
	max-width: 450px
	padding: 60px
	box-sizing: border-box

.submit_button
	margin-top: 30px
	align-self: flex-end

</style>
