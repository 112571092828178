<template>
	<td class="nice-table-cell_name" >
		<router-link
			v-if="showLink"
			class="ni_table_c_name--link"
			:to="actualRouter"
		>
			<span class="ni_table_c_name--link-text" >{{ caption }}</span>&nbsp;<nice-icon-2 icon="pencil-13" class="ni_table_c_name--link-icon" />
		</router-link>

		<template v-else >{{ caption }}</template>
	</td>
</template>

<script>
export default {
	name: 'TableCellName',


	props: {
		resource: {
			type: String,
			required: true,
		},

		id: {
			type: Number,
			required: true,
		},

		caption: {
			type: String,
			required: true,
		},

		/**
		 * Show link or caption only
		 */
		showLink: {
			type: Boolean,
			default: false,
		},

		/**
		 * Object, string or Function that returned router
		 */
		router: {
			type: [Function, Object, String],
			default: (r, id) => ({
				'name': `${r}-list/${r}-edit`,
				'params': { id },
			}),
			required: false,
		},
	},


	computed: {
		actualRouter() {
			if (typeof this.router == 'function') {
				return this.router(this.resource, this.id);
			}

			return this.router;
		},
	},
};
</script>

<style lang="sass" scoped>
.nice-table-cell_name
	vertical-align: middle
	color: var(--text_color)
	font-size: $fsz__new__middle
	text-decoration: none

.ni_table_c_name--link
	display: flex
	flex-direction: row
	justify-content: flex-start
	align-items: center

	text-decoration: none

	.ni_table_c_name--link-text
		@extend .nice-table-cell_name

	.ni_table_c_name--link-icon
		margin-left: 0.3em
		width: 13px
		min-width: 13px
		opacity: 0

	&:hover
		.ni_table_c_name--link-icon
			opacity: 1
</style>
