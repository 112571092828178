<template>
	<section class="page-section dev-page_nice-switch" >
		<h1>NiceMenuContext</h1>

		<p>
			<nice-button-2
				icon="plus-34"
				iconPlacement="start"
			>
				Create your own request
			</nice-button-2>

			or

			<nice-menu-context
				label="choose package"
			>
				<span class="choice" >Proposal</span>
				<span class="choice current" >Package</span>
			</nice-menu-context>

			with

			<nice-menu-context
				label="cpm"
			>
				<span class="choice" >CPV</span>
				<span class="choice current" >CPM</span>
				<span class="choice" >CPMV</span>
			</nice-menu-context>

			payment model
		</p>

		<h2>Props</h2>
		<dl>
			<dt>label</dt>
				<dd>Строка надпись</dd>

			<dt>icon</dt>
				<dd>Иконка</dd>

			<dt>tippyTrigger</dt>
				<dd>Триггер появления меню, возможные варианты - <a href="https://atomiks.github.io/tippyjs/all-options/" target="_blank" >документации tippy</a>.</dd>
		</dl>

		<h2>Example</h2>
		<p>
			<nice-menu-context
				label="cpm"
			>
				<span class="choice" >CPV</span>
				<span class="choice current" >CPM</span>
				<span class="choice" >CPMV</span>
			</nice-menu-context>
		</p>
		<p>
			<pre class="code-preview" ><code>&lt;nice-menu-context
	label="cpm"
&gt;
	&lt;span class="choice" &gt;CPV&lt;/span&gt;
	&lt;span class="choice current" &gt;CPM&lt;/span&gt;
	&lt;span class="choice" &gt;CPMV&lt;/span&gt;
&lt;/nice-menu-context&gt;</code></pre>
		</p>

		<h2>Usecase</h2>
		<p>
			<nice-menu-context
				icon="arrow_corner-24"
			>
				<template #label >
					<nice-icon-2 icon="draft" />
					<span class="ni_sc-label" >Draft</span>
				</template>

				<template>
					<span class="choice" >Proposal</span>
				</template>
				<template>
					<span class="choice current" >Package</span>
				</template>
			</nice-menu-context>
		</p>
	</section>
</template>

<script>
import NiceMenuContext from '@/ui/nice-menu-context';


export default {
	name: 'TabMenuContext',


	components: {
		NiceMenuContext,
	},
};
</script>


<style lang="sass" scoped >
</style>
