<template>
	<section class="layout-full_width" >
		<p>По дефолту у всех элементов нету горизонтальных отступов (<i>margin</i>), отступы добавляются в контексте блока с классами разных типов лейаута общий вид которых <b><i>layout-...</i></b> (т.е. по сетке выстраиваются уже дочерние компоненты а не скомпоненты с калссами <b><i>layout-...</i></b>). Это связано с необходимостью некоторые блоки открывать в полную ширину экрана (например карта или полоса с табами, см. Макеты). Дополнительно есть класс <b><i>layout_block-full_width</i></b> исключающий добавление марджинов.</p>

		<h2>Headers</h2>
		<h1>Header 1</h1>
		<h2>Header 2</h2>
		<h3>Header 3</h3>
		<h4>Header 4</h4>
		<h5>Header 5</h5>
		<h6>Header 6</h6>

		<h2>HR</h2>
		<hr />

		<h2>Tests</h2>
		<div class="layout-full_width layout-develop">
			<p><b><i>layout-full_width</i></b></p>
			<div class="layout_block-full_width">block over margins class <i><b>layout_block-full_width</b></i></div>
		</div>
		<div class="layout-center805 layout-develop">
			<p><b><i>layout-center805</i></b></p>
			<div class="layout_block-full_width">block over margins class <i><b>layout_block-full_width</b></i></div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'TabBaseStyles',
};
</script>

<style lang="sass" scoped >
.layout-develop > *
	padding: 20px 0
	margin-top: 0
	margin-bottom: 0


.layout-develop
	position: relative
	z-index: 0

	&::before,
	&::after
		content: ""
		z-index: -1

		position: absolute
		top: 0
		height: 100%

		opacity: .05
		background-color: #f00
		background-repeat: no-repeat
		background-size: 100% 100%

	> *
		background-color: rgba(0, 0, 255, .05)

	&.layout-full_width
		&::before
			left: 0
			width: $default-indent_x

		&::after
			right: 0
			width: $default-indent_x

	&.layout-center805
		&::before
			left: 0
			width: calc((100% - #{$layout-center805-width}) / 2)

		&::after
			right: 0
			width: calc((100% - #{$layout-center805-width}) / 2)
</style>
