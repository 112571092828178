<template>
	<section class="page-section" >
		<h1>NiceHint</h1>

		<p>
			<nice-label
				label="Label No1"
			/>
			<nice-hint
				hint="test hint"
			/>
		</p>

		<p>Триггер появления подсказки можно изменить через атрибут <b><i>trigger</i></b>, возможные варианты смотреть в <a href="https://atomiks.github.io/tippyjs/all-options/" target="_blank" >документации tippy</a>.</p>
		<p>
			<nice-hint
				hint="hint by click"
				trigger="click"
			/>
		</p>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div class="cl-pad cl-xs4 cl-md3" ><pre class="code-preview" >&lt;nice-hint
	hint="hint by click"
	trigger="click"
/&gt;</pre></div>
			</div>
		</div>
	</section>
</template>

<script>
import NiceHint from '@/ui/nice-hint';


export default {
	name: 'TabHint',


	components: {
		NiceHint,
	},
};
</script>

<style lang="sass" scoped >
.test_sloted_label
	width: 150px

.very_long_label
	width: 150px
</style>
