<template>
	<section class="page-section">
		<h1 class="title-error" >Deprecated!</h1>
		<p>use <b><i>nice-button-2</i></b></p>

		<section class="preview" >
			<h1 class="cl-pad cl-row" >Buttons</h1>
			<p class="cl-pad cl-row">
				Simple button, nothing else.<br />
				<nice-button>Simple button</nice-button>
				<br />
				<code class="code-preview" >&lt;nice-button&gt;Simple button&lt;/nice-button&gt;</code>
			</p>


			<p class="cl-pad cl-row">
				Prop <code class="code-preview">brdered</code>.<br />
				<nice-button bordered >Bordered button</nice-button>
				<br />
				<code class="code-preview" >&lt;nice-button bordered &gt;Bordered button&lt;/nice-button&gt;</code>
			</p>


			<p class="cl-pad cl-row">
				Bordered button with custom color. Prop <code class="code-preview">color</code> sets the color for every button state.<br />
				<nice-button
					bordered
					:color="$palette.yellow"
				>
					Bordered button
				</nice-button>
				<br />
				<pre class="code-preview" >&lt;nice-button
	bordered
	:color="$palette.yellow"
&gt;Bordered button&lt;/nice-button&gt;</pre>
			</p>

			<p class="cl-pad cl-row">
				Palette presets:<br />
				<nice-button
					v-for="(theme, i) in ['default', 'gray', 'red', 'blue', 'bluedark']"
					:key="`default_${i}`"

					:palette="theme"
				>
					palette="{{theme}}"
				</nice-button>
				<code class="code-preview">&lt;nice-button …</code>
				<br />

				<nice-button
					v-for="(theme, i) in ['default', 'gray', 'red', 'blue', 'bluedark']"
					:key="`bordered_${i}`"
					bordered
					:palette="theme"
				>
					palette="{{theme}}"
				</nice-button>
				<code class="code-preview">&lt;nice-button bordered …</code>
				<br />

				<nice-button
					v-for="(theme, i) in ['default', 'gray', 'red', 'blue', 'bluedark']"
					:key="`watercolor_${i}`"
					watercolor
					:palette="theme"
				>
					palette="{{theme}}"
				</nice-button>
				<code class="code-preview">&lt;nice-button watercolor …</code>
				<br />

				<nice-button
					v-for="(theme, i) in ['default', 'gray', 'red', 'blue', 'bluedark']"
					:key="`watercolor_and_bordered_${i}`"
					watercolor
					bordered
					:palette="theme"
				>
					palette="{{theme}}"
				</nice-button>
				<code class="code-preview">&lt;nice-button watercolor bordered …</code>
				<br />

				<nice-button
					v-for="(theme, i) in ['default', 'gray', 'red', 'blue', 'bluedark']"
					:key="`filled_${i}`"
					:palette="theme"
					filled
				>
					palette="{{theme}}"
				</nice-button>
				<code class="code-preview">&lt;nice-button filled …</code>
				<br />
				<pre class="code-preview" >&lt;nice-button
	:palette="$palette_name"
&gt;palette="$palette_name"&lt;/nice-button&gt;</pre>
			</p>

			<p class="cl-pad cl-row">
				Icons.<br />
				<code class="code-preview">icon</code> prop sets an icon.<br />
				<code class="code-preview">icon-placement</code> prop sets an icon placement: 'start' or 'end'.<br />
				<code class="code-preview">icon-palette</code> - custom icon palette. Provide 'null' to prevent icon coloring with current color.<br />
				<code class="code-preview">icon-state</code> - prop providing to the icon as 'state'.<br />
				<br />
				<nice-button bordered icon="trash" palette="red" >delete</nice-button><code class="code-preview"> ... bordered icon="trash" palette="red" ... </code><br />
				<nice-button icon="arrow" icon-placement="end" >next</nice-button><code class="code-preview"> ... icon="arrow" icon-placement="end" ... </code><br />
				<nice-button icon="edit" palette="red" :icon-palette="null" >edit</nice-button><code class="code-preview"> ... icon="edit" palette="red" :icon-palette="null" ... </code><br />
				<nice-button bordered icon="diagram" icon-state="45" palette="blue" >45%</nice-button><code class="code-preview"> ... bordered icon="diagram" icon-state="45" palette="blue" ... </code><br />
				<br />
				<pre class="code-preview" >&lt;nice-button
	:icon="$icon_name"
	:icon-placement="$icon_placement"
	:icon-state="$icon_state"
	:icon-palette="null | $icon_palette"
&gt;$button_caption&lt;/nice-button&gt;</pre>
			</p>



		</section>
	</section>
</template>

<script>
import NiceButton from '@/ui/nice-button.vue';

export default {
	name: 'TabButtons',
	components: {
		NiceButton,
	},

	data() {
		return {};
	},
};
</script>

<style lang="sass" scoped>
.preview
	& > p
		margin-top: 1em
		margin-bottom: 2em

::v-deep .nice-button
	margin: .5em 0
	&:not(:last-child)
		margin-right: .8em

pre.code-preview
	width: auto

.title-error
	color: red
</style>
