<template>
	<section class="page-section cl-container">
		<h2 class="cl-pad cl-row" >nice-value для вывода значений.</h2>
		<div class="cl-container cl-row" >
			<nice-value
				class="cl-pad cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1"
				label="Label"
				placeholder="&mdash;"
			/>
			<pre class="code-preview cl-pad" >&lt;nice-value label="Label" placeholder="&amp;mdash;" /&gt;</pre>
		</div>
		<div class="cl-container cl-row" >
			<nice-value
				class="cl-pad cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1"
				label="Amount"
				value="1&nbsp;M"
				extended-value="1&nbsp;023&nbsp;357"
				hint="Hint example..."
				units="of smthn"
			/>
			<pre class="code-preview cl-pad" >&lt;nice-value
	label="Amount"
	value="1&amp;nbsp;M"
	extended-value="1&amp;nbsp;023&amp;nbsp;357"
	hint="Hint example..."
	units="of smthn"
/&gt;</pre>
		</div>

		<div class="cl-container cl-row" >
			<div class="cl-container cl-xs6 cl-sm3 cl-md3 cl-lg2 cl-xl2">
				<nice-value
					class="cl-pad cl-row"
					label="Amount"
					value="1&nbsp;M"
					extended-value="1&nbsp;023&nbsp;357"
					hint="Hint example..."
					units="of smthn"
				/>
				<pre class="code-preview cl-row cl-pad" >&lt;nice-value
	class=""
	...
/&gt;</pre>
			</div>

			<div class="cl-container cl-xs6 cl-sm3 cl-md3 cl-lg2 cl-xl2">
				<nice-value
					class="md cl-pad cl-row"
					label="Amount"
					value="1&nbsp;M"
					extended-value="1&nbsp;023&nbsp;357"
					hint="Hint example..."
					units="of smthn"
				/>
				<pre class="code-preview cl-row cl-pad" >&lt;nice-value
	class="md"
	...
/&gt;</pre>
			</div>

			<div class="cl-container cl-xs6 cl-sm3 cl-md3 cl-lg2 cl-xl2">
				<nice-value
					class="lg cl-pad cl-row"
					label="Amount"
					value="1&nbsp;M"
					extended-value="1&nbsp;023&nbsp;357"
					hint="Hint example..."
					units="of smthn"
				/>
				<pre class="code-preview cl-row cl-pad" >&lt;nice-value
	class="lg"
	...
/&gt;</pre>
			</div>

			<div class="cl-container cl-xs6 cl-sm3 cl-md3 cl-lg2 cl-xl2">
				<nice-value
					class="xl cl-pad cl-row"
					label="Amount"
					value="1&nbsp;M"
					extended-value="1&nbsp;023&nbsp;357"
					hint="Hint example..."
					units="of smthn"
				/>
				<pre class="code-preview cl-row cl-pad" >&lt;nice-value
	class="xl"
	...
/&gt;</pre>
			</div>
		</div>
	</section>
</template>

<script>
import NiceValue from '@/ui/nice-value';


export default {
	name: 'TabTextInput',


	components: {
		NiceValue,
	},


	data() {
		return {
			testInputData: {
				value: 'Name',
				id: 'test-input',
				placeholder: 'Type here anything',
				label: 'Test input',
				units: 'unts',
			},
		};
	},
};
</script>
