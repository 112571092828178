<template>
	<!-- Edit details modal -->
	<transition name="modal-transition">
		<nice-modal
			@close="close"
		>
			<section class="modal">
				<header class="header">
					<h2 class="header__title">{{ $t('errors.resolve_errors') }}</h2>
				</header>

				<div class="message">
					<ul>
						<li
							v-for="(v, k) in errors"
							:key="k"
						>
							{{ $t(CAMPAIGN_REQUEST_FIELDS_LABELS[k] || 'errors.error') | capitalize }}{{ k == 'non_field_errors' ? '' : ':' }} {{ v }}
						</li>
					</ul>
				</div>

				<div class="buttons">
					<nice-button-2
						@click="close"
					>
						{{ $t('close') | capitalize }}
					</nice-button-2>
				</div>

			</section>
		</nice-modal>
	</transition>
</template>


<script>
import NiceModal from '@/ui/nice-modal';

import { CAMPAIGN_REQUEST_FIELDS_LABELS } from '@/constants/campaign_request';


export default {
	name: 'RequestErrorModal',

	components: {
		NiceModal,
	},

	props: {
		errors: Object,
	},

	data() {
		return {
			CAMPAIGN_REQUEST_FIELDS_LABELS,
		};
	},

	methods: {
		/**
		 * Closes the modal
		 */
		close() {
			this.$emit('close');
		},

	},  // END: methods
};
</script>


<style lang="sass" scoped>
.modal
	// background-color: var(--main_color)
	display: flex
	flex-direction: column
	padding: 30px 40px
	box-sizing: border-box
	flex-grow: 0
	overflow: auto
	min-height: 100px
	max-width: 480px
	max-height: calc(100vh - 160px)

	.header
		display: flex
		align-items: center
		margin-bottom: 30px

	.header__title
		font-size: 16px
		line-height: 20px
		// margin: calc((34px - 20px) / 2) 0
		font-weight: normal

	.message
		// color: white
		flex-grow: 1
		text-align: left
		font-size: $fsz__section-title
		// margin-top: 24px

	.buttons
		margin-top: 24px
		display: flex
		flex-direction: row
		align-items: center
		justify-content: flex-end

		.nice-button
			margin-left: 24px
</style>
