import { request } from './request';


export default {

	/**
	 * POI Types Reference
	 *
	 * @params {str} search - type filtration
	 *
	 * @returns {object}
	 */
	async getPOIs(search) {
		let params = {};
		if (search) {
			params['search'] = search;
		}

		return await request('poi-types/', params);
	},

};
