<template>
	<symbol :id="`${prefix}pause`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" class="base" />
		<rect x="9" y="5" width="2" height="6" class="sign" />
		<rect x="5" y="5" width="2" height="6" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPause',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-purple})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-white})
</style>
