<template>
	<symbol :id="`${prefix}bill`" width="10" height="12" viewBox="0 0 10 12">

		<path class="sign" d="M8.5 11L10 12V0L8.5 1L6.5 0L5 1L3.5 0L1.5 1L0 0V12L1.5 11L3.5 12L5 11L6.5 12L8.5 11ZM2 3V5H8V3H2ZM2 6V8H5V6H2Z"/>

	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolBill',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})
	fill-rule: evenodd
	clip-rule: evenodd
</style>
