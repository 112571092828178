<template>
	<label
		class="nice-checkbox"
	>
		<input
			:id="id"
			ref="input"
			v-model="innerModelValue"
			type="radio"
			:value="value"
			:name="name"
			:disabled="disabled"
			class="ni-chbx-input"
		/>
		<nice-icon
			:icon="icon"
			:state="value === innerModelValue ? 'checked' : 'unchecked'"
			:disabled="disabled"
		/>
		<span
			v-if="caption"
			class="ni-chbx-caption"
			:title="caption"
		>{{ caption }}</span>
	</label>
</template>


<script>
const MODEL = Object.freeze({
	prop: 'modelValue',
	event: 'update:modelValue',
});


export default {
	name: 'NiceRadio',
	model: MODEL,


	props: {
		id: String,

		name: {
			type: String,
			required: true,
		},

		caption: { type: String },

		disabled: Boolean,

		value: {
			type: [ String, Number ],
			required: true,
		},


		[MODEL.prop]: [ String, Number ],


		icon: { type: String, default: 'chbx-circle' }
	},


	data() {
		return {
			innerModelValue: this[MODEL.prop],
		};
	},


	computed: {
		input() { return this.$refs.input; },
		focus() { return this.$refs.input.focus; },

		checked() {
			return this.value === this.innerModelValue;
		},
	},


	watch: {
		[MODEL.prop](value) {
			this.innerModelValue = value;
		},

		innerModelValue(value, old) {
			if (value !== this[MODEL.prop]) {
				this.$emit(MODEL.event, this.innerModelValue);
			}
		},
	},


	methods: {
		noop() {},
	},
};
</script>

<style lang="sass" scoped >
.nice-checkbox
	@extend %ui-nice-component

	border-bottom: 1px solid transparent
	margin-bottom: 6px

	&.labeled
		padding-top: $ui-nice__labeled-without-label-padding-top

	> .nice-icon
		display: block
		flex-shrink: 0
		cursor: pointer

.ni-chbx-input
	display: none

	&:disabled + .nice-icon
		cursor: default

.niсe-icon__chbx-sqr
	width: 20px
	height: 20px

.niсe-icon__chbx-sqr
	width: 14px
	height: 14px

.ni-chbx-caption
	display: block
	font-size: $fsz__normal
	line-height: $ui-nice__input-heigth
	white-space: nowrap
	text-overflow: ellipsis
	user-select: none
	overflow: hidden
	margin-left: 9px
	// pointer-events: none

.__chbx-checked_box
	fill: var(--main_color)

.__chbx-checked_mark
	stroke: #ffffff

.__chbx-unchecked_box
	stroke: $clr__gray


</style>
