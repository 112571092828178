export default {
	transactions: {
		en: 'Transactions',
		ge: 'ტრანზაქცია',
		de: 'Transaktionen',
		ru: 'Транзакции',
		cn: /* TODO: to be translated */'Transactions',
	},
	id: {
		en: 'ID',
		ge: 'ID',
		de: 'ID',
		ru: 'ID',
		cn: /* TODO: to be translated */'ID',
	},
	debit: {
		en: 'Debit',
		ge: 'დებეტი',
		de: 'Soll',
		ru: 'Дебит',
		cn: /* TODO: to be translated */'Debit',
	},
	credit: {
		en: 'Credit',
		ge: 'კრედიტი',
		de: 'Haben',
		ru: 'Кредит',
		cn: /* TODO: to be translated */'Credit',
	},
	amount: {
		en: 'Amount',
		ge: 'თანხა',
		de: 'Betrag',
		ru: 'Количество средств',
		cn: /* TODO: to be translated */'Amount',
	},
	correspondent: {
		en: 'Correspondent',
		ge: 'კორესპონდენტი',
		de: 'Korrespondent',
		ru: 'Корреспондент',
		cn: /* TODO: to be translated */'Correspondent',
	},
	campaign_id: {
		en: 'Campaign ID',
		ge: 'კამპანიის ID',
		de: 'Kampagnen-ID',
		ru: 'ID Кампании',
		cn: /* TODO: to be translated */'Campaign ID',
	},
	description: {
		en: 'Description',
		ge: 'აღწერა',
		de: 'Beschreibung',
		ru: 'Описание',
		cn: /* TODO: to be translated */'Description',
	},
	impressions: {
		en: 'Impressions',
		ge: 'შთაბეჭდილებები',
		de: 'Impressionen',
		ru: 'Показы',
		cn: /* TODO: to be translated */'Impressions',
	},
	cash: {
		en: 'Cash',
		ge: 'ნაღდი ფული',
		de: 'Bargeld',
		ru: 'Вывод средств',
		cn: /* TODO: to be translated */'Cash',
	},
	adding_funds: {
		en: 'Adding funds',
		ge: 'ფინანსების დამატება',
		de: 'Geld hinzufügen',
		ru: 'Пополнение',
		cn: /* TODO: to be translated */'Adding funds',
	},
	time: {
		en: 'Time',
		ge: 'დრო',
		de: 'Zeit',
		ru: 'Время',
		cn: /* TODO: to be translated */'Time',
	},
};
