<template>
	<symbol :id="`${prefix}diagram`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="7"
			class="base"
		/>
		<circle
			cx="8"
			cy="8"
			r="7"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolDiagram',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: none
	stroke: var(--ni-icon-base, var(--disabled_color))
	stroke-width: var(--ni-icon-stroke-width, 2)

.sign
	fill: none
	stroke: var(--ni-icon-sign, var(--main_color))

	$radius: 7
	$circle_lenght: 3.14 * 2 * $radius
	stroke-width: var(--ni-icon-stroke-width, 2)
	stroke-dashoffset: 0.25 * $circle_lenght  // отступ на четверть круга
	stroke-dasharray: calc(#{$circle_lenght} * var(--ni-icon-state, 0) / 100) calc(#{$circle_lenght} * (1 - var(--ni-icon-state, 0) / 100))
</style>
