<template>
	<symbol :id="`${prefix}plus-17`" viewBox="0 0 17 17">
		<circle cx="8.5" cy="8.5" r="8" class="sign" />
		<line x1="8.5" y1="5" x2="8.5" y2="12" class="sign" />
		<line x1="5" y1="8.5" x2="12" y2="8.5" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlus17',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke-width: 1px
	stroke: var(--ni-icon-sign, var(--main_color))
	fill: none
</style>
