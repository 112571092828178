<template>
	<section class="page page-dev">
		<h1>TableCellStatus</h1>
		<p>Виджет управления и отображения статуса ресурса на странцие</p>

		<table class="nice-table" >
			<thead>
				<tr>
					<th class="col-name" >Name</th>
					<th class="col-status" >Status</th>
					<th>Digit</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="i in 6"
					:key="i"
				>
					<td class="col-name" >test No{{ i }}</td>
					<table-cell-status
						resource="test"
						:item="{
							id: i,
							status: statusList1[Math.floor(Math.random()*statusList1.length)].value,
						}"
						:list="statusList1"
						:can-change="Boolean(Math.floor(Math.random()*2))"

						class="col-status"
					/>
					<td v-html="formatNumber(Math.random()*100000+100)" />
				</tr>
			</tbody>
		</table>

		<h2>Props</h2>
		<dl>
			<dt>resource</dt>
				<dd>Ресурс к которому относится айтем статус которого отслеживает и изменяется, используется при вызове ендопинта изменения статуса</dd>

			<dt>item</dt>
				<dd>Объект айтема стаус которого отслеживается / меняется</dd>

			<dt>canChange</dt>
				<dd>Флаг определяющий возможность изменения статуса</dd>
		</dl>

		<p>Следующие пропы <i><b>list</b></i>, <i><b>iconsInList</b></i>, <i><b>hideOnClick</b></i> и<i><b>tippyTrigger</b></i> это пропы для компонента <i>NiceActionList</i> на базе которого сделан данный компонент, их оригинальное поведение и значение нужно смотерть в <i>NiceActionList</i>.</p>
		<dl>
			<dt>list</dt>
				<dd>Список доступных вариантов, могут быть объектами <code class="code-preview" >{ value: &lt;value&gt;, label: &lt;label&gt;}</code> либо просто строкой значением.</dd>

			<dt>iconsInList</dt>
				<dd>Булево значение, Отображать ли иконки внутри бабла со списком вариантов</dd>

			<dt>hideOnClick</dt>
				<dd>Булево значение, скрывать ли бабл при клике</dd>

			<dt>tippyTrigger</dt>
				<dd>Триггер появления меню, возможные варианты - <a href="https://atomiks.github.io/tippyjs/all-options/" target="_blank" >документации tippy</a>.</dd>
		</dl>
	</section>
</template>

<script>
import { formatNumber } from '@/utilites';

import TableCellStatus from '@/components/table/cell-status';


export default {
	name: 'PageDevTableCellStatus',


	components: {
		TableCellStatus,
	},


	data() {
		return {
			statusList1: [
				{
					value: 'pause',
					label: 'Paused',
					icon: 'pause',
				},
				{
					value: 'approved',
					icon: 'play',
					label: 'Approved',
				},
				{
					value: 'archived',
					icon: 'arrow_circle',
					label: 'Archived',
					labelAction: 'Archive',
				},
			],
		};
	},


	methods: {
		formatNumber,
	},
};
</script>

<style lang="sass" scoped >
.col-name
	width: 100px

.col-status
	width: 150px
</style>
