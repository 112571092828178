<template>
	<symbol :id="`${prefix}diagram`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="7"
			fill="none"
			:stroke="getColor('bar')"
			stroke-width="2"
		/>
		<circle
			cx="8"
			cy="8"
			r="7"
			fill="none"
			:stroke="diagramColor"
			stroke-width="2"
			:stroke-dashoffset="`${-6.28*7*(1-state/100-0.25)}`"
			:stroke-dasharray="`${6.28*7*state/100} ${6.28*7*(1-state/100)}`"
		/>
	</symbol>
</template>

<script>
import { BUSY_FILLRATE, FREE_FILLRATE } from '@/store/modules/devices/constants.js';



export default {
	name: 'NiIconDiagram',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				bar: this.$palette.grayest,
				busy:  this.$palette.red,
				medium:  this.$palette.yellow,
				free:  this.$palette.green,
				bar__disabled: this.$palette.grayest__disabled,
				busy__disabled:  this.$palette.red__disabled,
				medium__disabled:  this.$palette.yellow__disabled,
				free__disabled:  this.$palette.green__disabled,

				...this.palette,
			};
		},

		diagramColor() {
			if (parseInt(this.state) >= BUSY_FILLRATE) {
				return this.getColor('busy');
			} else if (parseInt(this.state) <= FREE_FILLRATE) {
				return this.getColor('free');
			} else {
				return this.getColor('medium');
			}
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
