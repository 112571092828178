<template>
	<symbol :id="`${prefix}pencil-13`" viewBox="0 0 13 13">
		<path
			d="M12.35,2.34L10.71,0.7c-0.58-0.58-1.6-0.58-2.19,0L1.05,8.18v3l-0.85,0.85l0.71,0.71l0.73-0.73h3.24l7.48-7.48c0.29-0.29,0.45-0.68,0.45-1.09C12.81,3.02,12.64,2.63,12.35,2.34z M4.46,11.01H2.05V8.59l5.57-5.57l2.41,2.41L4.46,11.01z M11.65,3.82l-0.91,0.91L8.33,2.31l0.91-0.91c0.21-0.21,0.57-0.21,0.77,0l1.64,1.64C11.86,3.26,11.86,3.61,11.65,3.82z"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPencil13',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke: none
	fill: var(--ni-icon-sign, var(--main_color))
</style>
