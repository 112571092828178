import { render, staticRenderFns } from "./plus_another-20.vue?vue&type=template&id=3ae31790&scoped=true&"
import script from "./plus_another-20.vue?vue&type=script&lang=js&"
export * from "./plus_another-20.vue?vue&type=script&lang=js&"
import style0 from "./plus_another-20.vue?vue&type=style&index=0&id=3ae31790&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae31790",
  null
  
)

export default component.exports