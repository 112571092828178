<template>
	<div
		class="nice-slider"
		:class="{
			'not_choiced': !valueSetted,
			'active': drag.startX,
			'labeled': labeled || label,
			'label_holder': labeled && !label,
		}"
		:style="{
			'--marker-shift': `${this.positionCurrent}%`,
		}"
	>
		<nice-label
			v-if="label"
			class="ni_slider--label"
			:label="label"
			:hint="hint"
		/>

		<!-- make input with mask -->
		<label
			v-if="!hideValue"

			type="text"
			class="ni_slider--input"
			disabled
			v-html="currentItemByCurrentPosition.label || currentItemByCurrentPosition.value"
		/>

		<div
			ref="bar"
			class="ni_slider--bar"
		>
			<span
				class="ni_slider--marker"

				@mousedown="onDragStart"
			/>
		</div>
	</div>
</template>


<script>
import _ from 'underscore';

const ROUND_DIGITS = 4;

const itemFromValue = (value, max, min=0) => {
	const position = value / max * 100;
	const positionPrev = (value-1) / max * 100;
	const positionNext = (value+1) / max * 100;

	return {
		value: value,
		position: position,
		positionMin: value <= min ? 0 : position - (position - positionPrev) / 2,
		positionMax: value >= max ? 100 : position + (positionNext - position)/2,
	};
};

const clearPosition = i => Math.max(0, Math.min(100, i));


export default {
	name: 'NiceSlider',

	model: {
		prop: 'value',
		event: 'update:value',
	},

	props: {
		value: {
			default: null,
		},

		labeled: { type: Boolean, default: false, },
		label: { type: String },
		hint: { type: String },
		hideValue: { type: Boolean, default: false, },

		/**
		 * List of values
		 *
		 * may be:
		 * * Array of values
		 * * Array of objects - [{ "value": V, position: P }, ... ] - lets rock
		 * * Number - from 0 to N
		 * * Object - { "start": N1, "end": N2, "step": S }
		 */
		axis: {
			type: [Array, Object, Number],
			default: 100,
		},
	},

	data() {
		return {
			innerValue: this.value,

			// percent shift for market from 0 to 100
			positionCurrent: null,

			globalDragHandler: null,
			globalDragEndHandler: null,
			// drag data
			drag: {
				startX: null,
				positionStart: null
			},

		};
	},

	computed: {
		axisActual() {
			let result = [];

			if (typeof this.axis === 'number') {
				this.$log.warn('if u read this something went wrong');

				for (let i = 0; i < this.axis; i++) {
					result.push({
						value: i,
						position: i / (this.axis-1) * 100,
					});
				}

			} else if (this.axis instanceof Array) {
				if (typeof this.axis[0] === 'object') {
					result = this.axis;
				} else {
					result = _.map(this.axis, (v, k, l) => {
						return {
							value: v,
							position: k / (this.axis.length-1) * 100,
						};
					});
				}

			} else if (typeof this.axis === 'object') {
				const start = this.axis.start || 0;
				const end = this.axis.end;
				const step = this.axis.step || 1;
				const stepCount = (end - start) / step;

				for (let i = 0; i <= stepCount; i++) {
					result.push({
						value: start + i*step,
						position: i / stepCount * 100,
					});
				}
			}

			return _.map(result, (item, i, l) => {
				return {
					...item,

					positionMin: i == 0 ? 0 : item.position - (item.position - l[i-1].position)/2,
					positionMax: i == l.length-1 ? 100 : item.position + (l[i+1].position - item.position)/2,
				};
			});
		},


		valueSetted() {
			return !!this.currentItemByCurrentValue;
		},


		currentItemByCurrentValue() {
			if (!this.innerValue && this.innerValue !== 0) {
				return;
			}

			if (typeof this.axis === 'number') {
				return itemFromValue(this.innerValue, this.axis);
			}

			return _.find(this.axisActual, i => i.value.toFixed(ROUND_DIGITS) === this.innerValue.toFixed(ROUND_DIGITS));
		},


		currentItemByCurrentPosition() {
			if (typeof this.axis === 'number') {
				return itemFromValue(Math.round(this.positionCurrent / 100 * this.axis), this.axis);
			}

			let result = _.find(this.axisActual, (item, i, l) => {
				if (item.position == this.positionCurrent) {
					return true;
				}

				return this.positionCurrent >= item.positionMin && this.positionCurrent <= item.positionMax;
			});
			return result;
		},
	},


	methods: {
		/**
		 * Handler on drag slider start
		 */
		onDragStart(event) {
			this.drag.startX = event.screenX;
			this.drag.positionStart = this.positionCurrent;

			const dragEvent = /* touch ? 'touchmove' : */ 'mousemove';
			const dragEndEvent = /* touch ? 'touchend' : */ 'mouseup';

			window.document.addEventListener(dragEvent, this.globalDragHandler);
			window.document.addEventListener(dragEndEvent, this.globalDragEndHandler, { once: true, });
		},


		/**
		 * Handler on drag slider
		 */
		onDrag(event) {
			event.preventDefault();
			const deltaRaw = event.screenX - this.drag.startX;
			const deltaPercent = deltaRaw / this.$refs.bar.clientWidth * 100;  // / window.devicePixelRatio;
			let newPosition = this.drag.positionStart + deltaPercent;

			this.positionCurrent = clearPosition(newPosition);
		},


		/**
		 * Handler on drag slider end
		 */
		onDragEnd(event) {
			window.document.removeEventListener('mousemove', this.globalDragHandler);
			this.drag = {
				startX: null,
				positionStart: null
			};

			this.innerValue = this.currentItemByCurrentPosition.value;
			this.positionCurrent = clearPosition(this.currentItemByCurrentValue.position);
		},
	},


	watch: {
		/**
		 * Watch value to innerValue
		 */
		value(newValue, oldValue) {
			this.innerValue = newValue;
		},

		/**
		 * Watch value to update slider
		 * Without `if` for change check becouse simple value
		 */
		innerValue(newValue, oldValue) {
			// when editing and the `newValue` is empty
			// TODO: not that simple?
			if (!this.currentItemByCurrentValue && this.currentItemByCurrentValue !== 0) {
				return;
			}

			this.positionCurrent = clearPosition(this.currentItemByCurrentValue.position);
			this.$emit('update:value', newValue);
		},
	},


	created() {
		this.globalDragHandler = event => { this.onDrag(event); };
		this.globalDragEndHandler = event => { this.onDragEnd(event); };

		if (this.valueSetted) {
			this.positionCurrent = clearPosition(this.currentItemByCurrentValue.position);
		} else if (typeof this.axis === 'number') {
			this.positionCurrent = 0;
		} else {
			this.positionCurrent = clearPosition(this.axisActual[0].position);
		}
	},


	beforeDestroy() {
		window.document.removeEventListener('mousemove', this.globalDragHandler);
		window.document.removeEventListener('mouseup', this.globalDragEndHandler);
	},
};
</script>


<style lang="sass" scoped>
.nice-slider
	--marker-shift: 0

	--range-color: var(--secondary_color)
	--marker-color: var(--secondary_color)

	z-index: 0

	&.label_holder
		padding-top: $ui-nice__label-height

.ni_slider--input
	display: block

	width: 100%
	height: auto
	padding: $ni_slider-input-height - $ni_slider-input-fontsize * 1.2 0 0

	border: none
	background-color: transparent
	font-size: $ni_slider-input-fontsize
	line-height: 1.2em
	color: var(--text_color)

	.label_holder > &,
	.ni_slider--label + &
		margin-top: 3px

.ni_slider--bar
	position: relative

	margin-top: 6px
	width: 100%
	height: $ni_slider-bar-height

	&::before
		content: ""

		position: absolute
		top: 0
		left: 0

		display: block
		width: 100%
		height: $ni_slider-bar-height

		border-radius: calc(#{$ni_slider-bar-height} / 2)
		background-color: var(--bar-bg, #{$nice_color-gray})

		cursor: pointer

	&::after
		content: ""

		position: absolute
		top: 0
		left: 0

		display: block
		width: var(--marker-shift)
		height: $ni_slider-bar-height

		border-radius: calc(#{$ni_slider-bar-height} / 2)
		background-color: var(--range-color, #{$nice_color-blue})
		will-change: width

		cursor: pointer

		will-change: width

	.nice-slider:not(.active) &
		&::after
			transition: width $ni_slider-bar-transition-time $ni_slider-bar-transition-easing

.ni_slider--marker
	position: absolute
	z-index: 1
	top: calc(50% - #{$ni_slider-marker-size} / 2)
	left: calc(var(--marker-shift) - #{$ni_slider-marker-size} / 2)

	display: block
	width: $ni_slider-marker-size
	height: $ni_slider-marker-size

	background-color: var(--marker-color, #{$nice_color-blue})
	border-radius: 50%
	cursor: grab

	will-change: left

	&::before
		content: ""

		position: absolute
		top: calc(50% - #{$ni_slider-marker-halo-size} / 2)
		left: calc(50% - #{$ni_slider-marker-halo-size} / 2)

		display: block
		width: $ni_slider-marker-halo-size
		height: $ni_slider-marker-halo-size

		border-radius: 50%
		background-color: var(--marker-color, #{$nice_color-blue})

		opacity: 0
		will-change: opacity
		transition: opacity $ni_slider-marker-hover-transition-time $ni_slider-marker-hover-transition-easing

	&::after
		content: ""

		position: absolute
		top: calc(50% - #{$ni_slider-marker-hover-size} / 2)
		left: calc(50% - #{$ni_slider-marker-hover-size} / 2)

		display: block
		width: $ni_slider-marker-hover-size
		height: $ni_slider-marker-hover-size

		border-radius: 50%
		background-color: var(--marker-color, #{$nice_color-blue})
		transform: scale3d(.5, .5, 1)

		will-change: transform
		transition: transform $ni_slider-marker-hover-transition-time $ni_slider-marker-hover-transition-easing

	.nice-slider:not(.active) &
		transition: left $ni_slider-bar-transition-time $ni_slider-bar-transition-easing

	.nice-slider.active &,
	&:hover

		&::before
			opacity: .2

		&::after
			transform: scale3d(1, 1, 1)

	&.active
		cursor: grabbing
</style>
