<template>
	<mgl-popup
		ref="map_popup"
		:coordinates="[tempArea.longitude, tempArea.latitude]"
		:close-on-click="false"
		:close-button="false"

		@added="popupAdded"
		v-on="$listeners"
	>
		<div class="popup-body">
			<nice-button
				class="close-button"
				icon="x"
				:palette="{ default: '#919191' }"
				@click="popupClose"
			/>

			<span class="popup-body-address"> {{ address }} </span>
			<nice-label
				class="popup-body-label"
				:label="$t('campaign.distance') | capitalize"
			/>
			<div class="metric">
				<!--
					TODO: min, max, step broken!!!
				-->
				<nice-input
					v-model.number="radius"
					type="number"
					:min="distanceAxis.start"
					:max="distanceAxis.end"
					:step="distanceAxis.step"
					class="metric-input"
				/>
				<nice-select
					v-model="units"
					class="model-select-line__inline-dd"
					:options="unitOptions"
				/>

				<nice-button-2
					v-if="tempArea.id"
					icon="basket-14"
					class="selected_area-delete"
					@click="deleteArea"
				/>
			</div>

			<nice-slider
				v-model="radius"
				:value="radius"
				class="popup-body-slider"
				:axis="distanceAxis"
				hideValue
			/>

			<template>
				<div class="control" >
					<nice-button-2
						v-tippy="{ appendTo: () => $el }"
						palette="red"
						bordered
						:filled="tempArea.action === ACTION_EXCLUDE"
						type="button"
						class="device-action-button"
						role="checkbox"
						:aria-checked="tempArea.action === ACTION_EXCLUDE"
						aria-label="blacklist"

						@click="handleActionButtonClick(ACTION_EXCLUDE)"
					>{{$t('inventory_modal.add_to_blacklist')}}</nice-button-2>

					<nice-button-2
						v-tippy="{ appendTo: () => $el }"
						bordered
						:filled="tempArea.action === ACTION_INCLUDE"
						type="button"
						class="device-action-button"
						role="checkbox"
						:aria-checked="tempArea.action === ACTION_INCLUDE"
						aria-label="whitelist"

						@click="handleActionButtonClick(ACTION_INCLUDE)"
					>{{$t('inventory_modal.add_to_whitelist')}}</nice-button-2>
				</div>
			</template>
		</div>
	</mgl-popup>
</template>

<script>
import { MglPopup } from 'vue-mapbox';
import NiceSelect from '@/ui/nice-select';
import NiceInput from '@/ui/nice-input';
import NiceSlider from '@/ui/nice-slider';

import { ACTION_EXCLUDE, ACTION_INCLUDE } from '../defaults';

const UNITS_OPTIONS = Object.freeze([
	{
		label: 'kilometers',
		value: 'km',
	},
	{
		label: 'meters',
		value: 'm',
	},
]);

const multipliersMap = {
	m: .001,
	km: 1,
};

const axisMap = {
	km: Object.freeze({ start: 0.2, end: 10, step: 0.1 }),
	m: Object.freeze({ start: 10, end: 200, step: 1 }),  // need to set step in input divisible by step here
};

const POPUP_MAX_WIDTH = '290px';


export default {
	name: 'MapPopupArea',


	components: {
		MglPopup,
		NiceSlider,
		NiceSelect,
		NiceInput,
	},

	props: {
		withoutControl: {
			type: Boolean,
			required: false
		},

		tempArea: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			ACTION_EXCLUDE,
			ACTION_INCLUDE,

			radius: this.tempArea.radius,
			units: 'km',
			address: this.tempArea.title
		};
	},


	mounted() {
		// HACK: max popup width
		if (this.$refs.map_popup) {
			this.$refs.map_popup.popup.setMaxWidth(POPUP_MAX_WIDTH);
		}
	},


	computed: {
		distanceAxis() {
			// let result = [];
			// const m = 10 + 34 + 66;

			// for (let i = 1;i<=m;i++) {
			// 	const v = i>10
			// 		? i>48
			// 			? (i-44)*5000
			// 			: (i-8)*500
			// 		: i*100;
			// 	result.push({
			// 		value: v/1000,
			// 		label: this.units == 'km'? v/1000 + this.units : v + this.units,
			// 		position: i/m*100,
			// 	});
			// }
			// return result;
			return axisMap[this.units];
		},

		unitOptions() {
			return UNITS_OPTIONS;
		},

		radiusInKm() {
			return this.radius * multipliersMap[this.units];
		},
	},

	methods: {
		popupAdded(event) {
			// HACK: popups are not shown event if `:showed="true"`.
			//       Don't set `:showed="true"` and add manually.
			event.popup.addTo(event.map);

			event.popup.addClassName('ar_mapbox_popup');
			event.popup._container.setAttribute(this.$options._scopeId, true);
		},

		popupClose(event) {
			// TODO: ???
			this.$refs.map_popup.remove();
		},

		handleActionButtonClick(action) {
			this.tempArea.action = action;
			this.$emit('add-area', this.tempArea);
			this.popupClose();
		},

		deleteArea() {
			this.$emit('remove-area', this.tempArea);
			this.popupClose();
		}
	},

	watch: {
		radiusInKm(radiusInKm) {
			this.$emit('update:temp-area', { ...this.tempArea, radius: radiusInKm });
		},

		units(units, oldUnits) {
			switch (`${oldUnits}->${units}`) {
			case 'm->km':
				this.radius = axisMap.km.start;
				break;
			case 'km->m':
				this.radius = axisMap.m.end;
				break;
			}
		}
	}
};
</script>

<style lang="sass" scoped >
.ar_mapbox_popup
	will-change: unset
	transform: translate(-100%, 100%)
	& ::v-deep
		.mapboxgl-popup-tip
			// display: none

		.mapboxgl-popup-content
			+default__shadow
			padding: 20px
			border-radius: 10px


.popup-body
	display: flex
	flex-direction: column

.popup-body-address
	margin-top:
	color: var(--text_2_color)

.popup-body-slider
	margin: 10px 0


.popup-body-label
	margin-top: 10px

.control
	display: flex
	flex-direction: row
	justify-content: space-between

	margin-top: 8px
	margin-bottom: 0


	&:first-child
		margin-left: 0

.popup-header
	display: flex
	flex-direction: row
	justify-content: flex-end

.model-select-line__inline-dd
	display: inline-block
	margin-left: 10px

.metric
	display: flex
	flex-direction: row
	justify-content: flex-start

	&:first-child
		margin-left: 10px

.metric-input
	max-width: 50px

.close-button
	max-width: 40px
	position: absolute
	top: 0
	right: 0

.selected_area-delete
	margin-left: 20px
	padding-left: 10px
	padding-right: 10px
	margin-right: -10px
</style>
