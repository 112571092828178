var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nice-input",class:( _obj = {
		'invalid': _vm.error.is,
		'label_holder': _vm.labeled && !(_vm.label || _vm.hint),
		'labeled': _vm.labeled || _vm.label || _vm.hint
	}, _obj[_vm.actualPalette] = _vm.actualPalette, _obj )},[(_vm.label || _vm.hint)?_c('nice-label',{staticClass:"ni_input--label",attrs:{"hint-icon":"hint","hint":_vm.hint,"label":_vm.label,"error":_vm.error.message || _vm.error.is}}):_vm._e(),_c('div',{staticClass:"ni_input--input-row"},[_c('imask-input',_vm._g(_vm._b({ref:"input",staticClass:"ni_input--input",attrs:{"id":_vm.id,"type":_vm.type,"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.placeholder},model:{value:(_vm.actualValue),callback:function ($$v) {_vm.actualValue=$$v},expression:"actualValue"}},'imask-input',Object.assign({}, {max: _vm.maximum,
				min: _vm.minimum},
				_vm.$attrs,
				_vm.maskConfig),false),_vm.inputListeners)),(_vm.units)?_c('span',{staticClass:"ni_input--units",domProps:{"innerHTML":_vm._s(_vm.units)}}):_vm._e(),(_vm.$slots.default)?_vm._t("default"):_vm._e(),(_vm.hasAutocomplete)?_c('nice-button-2',{staticClass:"ni_input--autocomplete-button_dd",attrs:{"icon":"arrow_corner-12","icon-state":_vm.showingAutocompleteList ? 180 : 0},on:{"click":_vm.showAutocompleteList}}):_vm._e()],2),(_vm.showingAutocompleteList)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeAutocompleteList),expression:"closeAutocompleteList"}],staticClass:"ni_input--autocomplete"},[(_vm.autocompleteList && _vm.autocompleteList.length)?_vm._l((_vm.autocompleteList),function(variable,index){return _c('button',{key:index,staticClass:"ni_input--autocomplete-item",attrs:{"type":"button"},on:{"click":function($event){return _vm.autocompleteSet(variable)}}},[_vm._v(" "+_vm._s(variable.label || variable)+" ")])}):_c('span',{staticClass:"ni_input--autocomplete-item empty"},[_vm._v("…")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }