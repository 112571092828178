<template>
	<symbol :id="`${prefix}plus-34`" viewBox="0 0 34 34">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 17C0 7.62598 7.62598 0 17 0C26.374 0 34 7.62598 34 17C34 26.374 26.374 34 17 34C7.62598 34 0 26.374 0 17ZM2 17C2 25.2715 8.729 32 17 32C25.2715 32 32 25.2715 32 17C32 8.729 25.2715 2 17 2C8.729 2 2 8.729 2 17ZM16 16V11H18V16H23V18H18V23H16V18H11V16H16Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlus34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
