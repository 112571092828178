import store from '@/store';
import { request } from './request';


/**
 * Returns a prefix, eg, `agency/5/ssp/` or `agency/6/dsp/`
 */
export const getPrefix = () => `agency/${store.state.app.agency}/${store.state.app.side}/`;


/**
 * Request impression, spent / income, ad plays stats
 * For Super, SSP, DSP
 *
 * TODO: all params are mutually exclusive?
 *
 * @param {number} dsp_agency child DSP agency id. Only for SSP
 * @param {number} platform network id. Only for SSP
 * @param {number} campaign campaign id. For SSP and DSP
 * @param {number} device device id. For SSP only
 * @param {string} type { hourly, daily, weekly, monthly }
 * @param {datetime} date_from
 * @param {datetime} date_till
 *
 * @returns {object} Impression stats
 */
export async function requestStats(params) {
	return await request(`${getPrefix()}analytics/statistics/`, params);
}
