<template>
	<tippy
		v-if="this.id"
		:interactive="true"
		:reactive="true"
		:placement="'right-end'"
		:arrow="false"
		class="account-menu"
		trigger="mouseenter focus"
	>
		<template v-slot:trigger>
			<button class="account-menu__trigger" >{{ initials }}</button>
		</template>

		<div
			id="account-menu__popover"
			class="account-menu__popover popover"
		>

			<div class="popover__part">
				<span class="platform-role">
					{{ $t(`invitation.${currentRole}`) }}
				</span>
				<p class="popover__name">
					{{ name }}
				</p>
				<p class="popover__email">{{ email }}</p>
			</div>

			<div class="popover__part">
				<NiceLabel :label="$t('common.agency')" />

				<NiceMenuContext
					:label="agencyName"
					tippy-placement="bottom-start"
					class="available-agencies-list"
				>
					<NiceButton2
						v-for="agency in availableAgenciesList"
						:key="agency.id + agency.role"
						class="agency-select-line__option"
						:class="{
							current: currentAgency === agency.id,
						}"

						@click="switchAgencyAndRole(agency.id, agency.role)"
					>
						{{ agency.name }} <span class="role">({{ $t(`invitation.${agency.role}`) }})</span>
					</NiceButton2>
				</NiceMenuContext>

				<!-- <p class="popover__agency">{{ agencyName }}</p> -->
			</div>

			<!-- balance -->
			<div v-if="isDev && agencyCurrency && platformSide == 'dsp'" class="popover__part">
				<nice-label :label="$t('common.balance')" />
				<p class="popover__balance">
					<span v-html="formatCash(agencyBalanceInCurrency, agencyCurrency)" />
				</p>
			</div>


			<!-- locale -->
			<div v-if="isDev" class="popover__part">
				<nice-label :label="$t('common.language')" />
				<div>
					<locale-changer/>
				</div>
			</div>

			<hr class="separator" />

			<div class="popover__part popover__part_buttons">
				<nice-button-2
					@click="$router.push('/me')">
					{{ $t('common.profile') | capitalize }}
				</nice-button-2>
				<nice-button-2
					@click="logout">
					{{ $t('common.logout') | capitalize }}
				</nice-button-2>
			</div>
		</div>
	</tippy>
</template>


<script>
import { mapState, mapActions } from 'vuex';

import NiceMenuContext from '@/ui/nice-menu-context';
import LocaleChanger from '@/components/locale-changer';
import { formatCash, getInitials } from '@/utilites';


export default {
	name: 'AccountMenu',

	components: {
		NiceMenuContext,
		LocaleChanger,
	},

	filters: {
		formatCash,
	},

	//
	// Variables
	//

	data() {
		return {
			isSettingsOpened: false,
		};
	},


	computed: {
		...mapState('app', {
			platformSide: 'side',
			currentRole: 'role',
		}),

		...mapState('profile', [
			'id',
			'email',
			'username',
			'first_name',
			'last_name',
			'availableAgencies',
		]),

		...mapState('agency', {
			agencyName: 'name',
			agencyBalanceInCurrency: 'balance',
			agencyCurrency: 'currency',
		}),

		isDev() {
			return process.env.NODE_ENV === 'development';
		},


		// we need it as an int
		currentAgency() {
			return parseInt(this.$store.state.app.agency);
		},


		name() {
			let name = [this.first_name, this.last_name].join(' ').trim();

			if (!name) {
				name = this.username;
			}

			if (!name) {
				name = this.email;
			}

			return name;
		},

		initials() {
			return getInitials(this.name);
		},


		availableAgenciesList() {
			let agencies = [];

			for (let agency of this.availableAgencies) {
				for (let role of agency.roles) {
					agencies.push({
						id: agency.id,
						name: agency.name,
						role: role,
					});
				}
			}

			return agencies;
		},
	},


	//
	// Component Lifecycle
	//

	created() {
		window.addEventListener('click', this.closeSetting, false);
	},


	beforeDestroy() {
		window.removeEventListener('click', this.closeSetting);
	},


	//
	// Methods
	//

	methods: {
		...mapActions({
			logoutAction: 'logout'
		}),

		formatCash,

		switchAgencyAndRole(agencyId, role) {
			const side = role.substring(0, 3);

			this.$store.commit('app/setAgency', agencyId);
			this.$store.commit('app/setRole', role);
			this.$store.commit('app/setSide', side);

			// reload the whole thing
			window.location.href = '/';
		},

		toggleSetting() {
			this.isSettingsOpened = !this.isSettingsOpened;
		},

		closeSetting() {
			this.isSettingsOpened = false;
		},

		logout() {
			this.logoutAction();
		},
	},
};
</script>

<style lang="sass">
.account-menu
	display: inline-block

	--trigger_bg_color: var(--disabled_color)
	--trigger_color: var(--main_color)
	--trigger_bg_color_hover: var(--main_color)
	--trigger_color_hover: #{$nice_color-white}

.account-menu__trigger
	+button__clear
	width: 34px
	height: 34px
	border-radius: 50%

	color: var(--trigger_color)
	background-color: var(--trigger_bg_color)

	transition: color .2s ease, background-color .2s ease

	&:hover
		color: var(--trigger_color_hover)
		background-color: var(--trigger_bg_color_hover)

.popover
	display: flex
	flex-direction: column
	align-items: stretch
	text-align: left

.popover__part
	margin-top: 15px

	&:first-child,
	.separator + &
		margin-top: 0

.popover__part_buttons
	display: flex
	justify-content: space-between
	align-items: center

.popover__balance,
.popover__name,
.popover__email,
.popover__agency
	margin: 0

.popover__name
	font-size: 16px

.platform-role
	float: right
	font-size: 10px
	color: var(--text_2_color)
	text-transform: uppercase
	margin-top: 5px

.popover__email
	color: var(--text_2_color)
	font-size: 10px

.popover__balance,
.popover__agency
	font-size: 12px
	color: var(--main_color)


.agency-select-line__option

	.role
		color: var(--text_2_color)
		font-weight: normal
		front-size: 10px

	&:hover
		.role
			opacity: 0.3


.available-agencies-list

	.nice-button.ni_menu_context--button-toogle-dropdown
		--ni-icon-sign: #{$nice_color-gray_dark}

</style>
