<template>
	<tr>
		<th>
			{{ $t('inventory_modal.th_rule_type') }}
		</th>

		<th
			v-for="field in fieldsList"
			:key="field.key"
		>
			<th-ordering-button
				:name="field.key"
				:descending="descending"
				:orderBy="orderBy"
				:disabled="disabled"
				@ordering="handleButtonClick"
			>{{ field.caption }}</th-ordering-button>
		</th>

		<th>
			{{ $t('campaign.widget_ad_plays_title') }}
		</th>
		<th>
			{{ $t('campaign.widget_impressions_title') }}
		</th>
		<th>
			{{ $t(`inventory_modal.th_${costField}`, { currency }) }}
		</th>
	</tr>
</template>


<script>
import ThOrderingButton from './th-ordering-button';

import {
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';


export default {
	name: 'InventoryTableHeaderRow',


	components: {
		ThOrderingButton,
	},


	model: {
		prop: 'ordering',
		event: 'update:ordering',
	},


	props: {
		ordering: {
			type: Object,
			default: () => ({
				orderBy: null,
				descending: null,
			}),
		},

		disabled: Boolean,

		context: {
			type: Object,
			required: true,
			default: () => ({
				currency: null,
				payment_model: null,
			}),
		},
	},


	data() {
		return {
			orderBy: this.ordering.orderBy,
			descending: this.ordering.descending,
			currency: this.$options.filters.formatCurrency(this.context.currency),
		};
	},


	computed: {
		otsField() {
			if (this.context.payment_model === PAYMENT_MODEL_CPV) {
				return 'cpv_count';
			}
			return 'cpm_count';
		},


		costField() {
			return `${this.context.payment_model}_cost`;
		},

		fieldsList() {
			// const currency = this.$options.filters.formatCurrency(this.context.currency);

			return [
				{ key: 'location_name', caption: this.$t('inventory_modal.th_location_name') },
				{ key: 'city', caption: this.$t('inventory_modal.th_city') },
				{ key: 'street', caption: this.$t('inventory_modal.th_address') },
				{ key: 'name', caption: this.$t('inventory_modal.th_device') },
				{ key: 'resolution', caption: this.$t('inventory_modal.th_specs') },
				{ key: 'count_screen', caption: this.$t('inventory_modal.count_screen') },
				{ key: 'uptime', caption: this.$t('inventory_modal.uptime') },
				// {
				// 	key: 'ad_palys_count',
				// 	// the same title as @ CardListSummary
				// 	caption: this.$t('campaign.widget_ad_plays_title'),
				// },
				// {
				// 	key: 'cpm_count',
				// 	// the same title as @ CardListSummary
				// 	caption: this.$t('campaign.widget_impressions_title'),
				// },
				// {
				// 	key: this.costField,
				// 	caption: this.$t(`inventory_modal.th_${this.costField}`, { currency }),
				// },
			];
		},
	},

	methods: {
		handleButtonClick(orderBy) {
			this.descending = orderBy === this.orderBy && !this.descending;
			this.orderBy = orderBy;

			this.$emit('update:ordering', {
				orderBy,
				descending: this.descending,
			});
		},
	},
};
</script>
