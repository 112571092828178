import { formatCash, formatCurrency, normalizeDecimal } from '@/utilites';


/**
* Capitalizes provided string
* @param {string} value - raw string
* @return {string} capitalized one
*/
export function capitalize(value) {
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
* Uppercases provided string
* @param {string} value - raw string
* @return {string} uppercased one
*/
export function uppercase(value) {
	if (!value) return '';
	value = value.toString();
	return value.toUpperCase();
}

/**
* Lowercases provided string
* @param  {string} value - raw string
* @return {string} lowercased one
*/
export function lowercase(value) {
	if (!value) return '';
	value = value.toString();
	return value.toLowerCase();
}

/**
* Replace all occurrences of subStr with newSubStr
*/
export function replace(value, subStr='', newSubStr='') {
	if (!value) return '';
	value = value.toString();
	return value.replace(subStr, newSubStr);
}

/**
* Format Number
*/
export function formatNumber(value, decimal=2) {
	let num = Number.parseFloat(value).toFixed(decimal);

	// special case
	if (num == 0) {
		num = 0;
	}

	return num;
}

/**
 * Format money
 *
 * We need number here.
 * TODO: a special case for a input on the step 3 of request creation
 */
export function formatMoney(value, currency = '', decimal = 2) {
	decimal = normalizeDecimal(value);
	let num = Number.parseFloat(value).toFixed(decimal);

	// special case
	if (num == 0) {
		num = 0;
	}

	const separator = '';
	// TODO: w/o this formatCash will return an int
	const separatorDecimal = '.';
	return formatCash(num, currency, decimal, separator, separatorDecimal);
}


const FILTERS = {
	capitalize,
	formatCash,
	formatMoney,
	formatNumber,
	formatCurrency,
	lowercase,
	replace,
	uppercase,
};

export default {
	/**
	* Install filters.
	* By default installs all the filters
	* but you can whitelist some to plug especially them
	*
	* @param {class} Vue - Vue class
	* @param {string[]} options.whitelist - array of filters names
	*/
	install(Vue, options={}) {
		// apply all or whitelisted filters
		Object.keys(FILTERS).forEach(filter => {
			if (options.whitelist && !options.whitelist.includes(filter)) return null;
			// plug filter in
			Vue.filter(filter, FILTERS[filter]);
		});
	},
};
