<template>
	<symbol :id="`${prefix}audio`" viewBox="0 0 16 12">
		<path d="M3.05025 10.9497C0.316582 8.21607 0.316582 3.78392 3.05025 1.05025L2.34315 0.34314C-0.781049 3.46733 -0.781049 8.53265 2.34315 11.6568L3.05025 10.9497Z" class="sign" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13 7.99999L9.46022 10.9498C8.48341 11.7639 7.00001 11.0692 7.00001 9.79741V2.20257C7.00001 0.930838 8.48341 0.236075 9.46022 1.05022L13 3.99999H14C15.1045 3.99999 16 4.89544 16 5.99999C16 7.10454 15.1045 7.99999 14 7.99999H13ZM8.82008 1.81847L12 4.46837V7.53161L8.82008 10.1816C8.4944 10.4529 8.00001 10.2214 8.00001 9.79741V2.20257C8.00001 1.77862 8.4944 1.54705 8.82008 1.81847ZM13 6.99999H14C14.5523 6.99999 15 6.5523 15 5.99999C15 5.44768 14.5523 4.99999 14 4.99999H13V6.99999Z" class="sign" />
		<path d="M4.87881 3.87867C3.70723 5.05025 3.70723 6.94974 4.87881 8.12131L4.1717 8.82842C2.6096 7.26632 2.6096 4.73366 4.1717 3.17157L4.87881 3.87867Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolAudio',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
