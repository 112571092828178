<template>
  <button
		v-bind="$attrs"
		type="button"
		:class="[
			'th-button',
			{ 'th-button_active': isActive }
		]"
		@click="buttonClickHandler"
	>
		<span class="visuallyhidden">Order by</span>
		<span class="th-button__caption"><slot /></span>
		<svg class="th-button__icon" width="10" height="7" viewBox="0 0 10 7">
			<g stroke="currentColor" fill="none" >
				<path v-if="!isActive" d="M0 3.5H5H10" />
				<path v-else-if="descending" d="M0.5 5.5L5 1.5L9.5 5.5" />
				<path v-else d="M0.5 1.5L5 5.5L9.5 1.5" />
			</g>
		</svg>
	</button>
</template>

<script>
export default {
	name: 'ThOrderingButton',

	props: {
		name: String,
		orderBy: String,
		descending: Boolean,
	},

	computed: {
		isActive() {
			return this.name === this.orderBy;
		},
	},

	methods: {
		buttonClickHandler() {
			this.$emit('ordering', this.name);
		}
	},
};
</script>

<style lang="sass" scoped>
.th-button
	display: inline-flex
	appearance: none
	border: none
	background: none
	padding: 0
	margin: 0
	color: inherit
	font-family: inherit
	font-size: inherit
	line-height: inherit
	font-weight: inherit
	text-align: inherit
	text-transform: inherit
	cursor: pointer
	align-items: center

	transition: color .2s ease, opacity .2s ease
	&_active
		color: var(--main_color)

	&:disabled
		opacity: .6

.th-button__caption
	flex-basis: 0

.th-button__icon
	margin-left: 5px
</style>
