<template>
	<section class="page-section dev-page_nice-switch" >
		<h1>Переключатель</h1>

		<div class="cl-container">
			<h2 class="cl-pad cl-row" >Пример</h2>

			<nice-input
				id=""
				class="cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad"
				type="text"
				placeholder="labeled input"
				label="labeled input"
				value=""
				disabled
			/>
			<nice-switch
				class="cl-xs10 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad"
				label="Labeled switch"
			/>

			<div class="cl-xs10 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad" >
				<nice-switch
					id="test-switch"
					v-model="switchData"
					:caption="`1st switch (${switchData ? 'true' : 'false'})`"
				/>
				<nice-switch
					id="test-switch"
					v-model="switchData"
					:caption="`2nd switch (${switchData ? 'true' : 'false'})`"
				/>
			</div>
		</div>

		<section class="preview" >
			<h2>Использование</h2>
			<p><nice-switch /> <code class="code-preview" >&lt;nice-switch /&gt;</code></p>
			<p><nice-switch disabled /> <code class="code-preview" >&lt;nice-switch disabled /&gt;</code></p>

			<h3>Для использования в стилизованных формах</h3>

			<p>с лейблом</p>
			<p>
				<nice-switch label="Labeled switch" />
				<code class="code-preview" >&lt;nice-switch label="Labeled switch" /&gt;</code>
			</p>

			<p>с инпутом</p>
			<div class="cl-container">
				<nice-input
					id=""
					type="text"
					placeholder="labeled input"
					label="labeled input"
					value=""
					disabled
					class="cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad"
				/>
				<nice-switch
					class="cl-xs10 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad"
					labeled
				/>
				<code class="code-preview" >&lt;nice-switch labeled /&gt;</code>
			</div>
		</section>
	</section>
</template>

<script>
import NiceInput from '@/ui/nice-input.vue';
import NiceSwitch from '@/ui/nice-switch.vue';

export default {
	name: 'TabCheckbox',
	components: {
		NiceInput,
		NiceSwitch,
	},

	data() {
		return {
			switchData: false,
		};
	},
};
</script>


<style lang="sass" scoped >
.dev-page_nice-switch
	.preview
		.nice-switch
			display: inline-flex
			vertical-align: top

</style>
