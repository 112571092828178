<template>
	<symbol :id="`${prefix}error`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" class="base" />
		<path d="M5.87866 4.46436L4.46436 5.87854L6.58569 7.99988L4.46436 10.1212L5.87842 11.5354L7.99976 9.41406L10.1211 11.5354L11.5354 10.1212L9.41406 7.99982L11.5354 5.87854L10.1211 4.46436L8 6.58563L5.87866 4.46436Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolError',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-red})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-white})
</style>
