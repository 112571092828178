<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="8"
			:fill="getColor('sign')"
			stroke="none"
		/>
		<path
			:fill="getColor('icon')"
			stroke="none"
			d="M10.9,7.1L7.4,4.9C6.9,4.6,6.3,4.8,6,5.3C5.9,5.4,5.9,5.6,5.9,5.8v4.4c0,0.6,0.4,1,1,1c0.2,0,0.4-0.1,0.5-0.2 l3.5-2.2c0.5-0.3,0.6-0.9,0.3-1.4C11.1,7.3,11,7.2,10.9,7.1z"
		/>
	</symbol>
</template>

<script>
export const slug = 'play';


export default {
	name: 'NiIconPlay',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				icon: this.$palette.white,
				sign: this.$palette.green,
				icon__disabled: this.$palette.white__disabled,
				sign__disabled: this.$palette.green__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
