<template>
	<mgl-popup
		ref="map_popup"
		:coordinates="coordinates"
		anchor="bottom"
		:close-on-click="false"
		:close-button="false"
		:offset="[0, -19]"

		:class="{
			'withControl': !withoutControl,
		}"
		@added="popupAdded"
		v-on="$listeners"
	>

		<div class="popup-body">
			<div class="row-name">
				<span class="name-wrapper" >
					<nice-icon-2 :icon="`${icon}-17`" />
					<span
						class="name"
					>{{ $t('map.devices_selected_count') }}: {{ devicesCount }}</span>
					<nice-button
						class="close-button"
						icon="x"
						@click="popupClose"
					/>
				</span>
			</div>

			<span class="address" >
				<!-- TODO: -->
				<!-- {{ device.address }} -->
			</span>

			<template
				v-if="!withoutControl"
			>

				<div class="control" >
					<nice-button-2
						v-tippy="{ appendTo: () => $el }"
						:content="$t(`inventory_modal.${type === 'black' ? 'remove_from' : 'add_to'}_blacklist_hint`)"
						palette="red"
						bordered
						:filled="type === 'black'"
						:disabled="type == 'not_deployed'"
						type="button"
						class="device-action-button"
						role="checkbox"
						:aria-checked="type === 'black'"
						aria-label="blacklist"

						@click="handleActionButtonClick('black')"
					>{{$t('inventory_modal.add_to_blacklist')}}</nice-button-2>

					<nice-button-2
						v-tippy="{ appendTo: () => $el }"
						:content="$t(`inventory_modal.${type === 'white' ? 'remove_from' : 'add_to'}_whitelist_hint`)"
						bordered
						:filled="type === 'white'"
						:disabled="type == 'not_deployed'"
						type="button"
						class="device-action-button"
						role="checkbox"
						:aria-checked="type === 'white'"
						aria-label="whitelist"

						@click="handleActionButtonClick('white')"
					>{{$t('inventory_modal.add_to_whitelist')}}</nice-button-2>
				</div>

			</template>
		</div>
	</mgl-popup>
</template>

<script>
import { MglPopup } from 'vue-mapbox';
import {
	addDevicesToManualFilter,
	removeDevicesFromManualFilter,
} from '@/api/dsp/manual-inventory-filter';

const POPUP_ICON = 'monitor';
const POPUP_MAX_WIDTH = '290px';
const STATE_WHITELIST = 'white';
const STATE_BLACKLIST = 'black';


export default {
	name: 'MapPopupDeviceCluster',


	components: {
		MglPopup,
	},


	props: {
		/**
		 * Popup coordinates
		 *
		 * [lng, lat]
		 */
		coordinates: {
			type: Array,
			required: true
		},

		/**
		 * Devices
		 */
		devices: {
			type: Array,
			required: true
		},

		/**
		 * Hide control
		 */
		withoutControl: {
			type: Boolean,
			required: false
		},

		campaignId: {
			type: Number,
			default: null
		}
	},


	data() {
		return {
			isBusy: false,

			// calculate in `mounted()`
			type: '',
		};
	},


	computed: {
		/**
		 * Return icon slug for device
		 */
		icon() {
			return POPUP_ICON;
		},

		devicesCount() {
			return this.devices.length;
		},
	},


	mounted() {
		// HACK: max popup width
		if (this.$refs.map_popup) {
			this.$refs.map_popup.popup.setMaxWidth(POPUP_MAX_WIDTH);
		}

		// get list type
		let isWhite = false;
		let isBlack = false;
		for (let device of this.devices) {
			if (device.properties.state == STATE_WHITELIST) {
				isWhite = true;
			}
			if (device.properties.state == STATE_BLACKLIST) {
				isBlack = true;
			}
		}
		// set list type
		if (isWhite) {
			this.type = STATE_WHITELIST;
		}
		if (isBlack) {
			this.type = STATE_BLACKLIST;
		}
	},


	methods: {
		popupAdded(event) {
			// HACK: popups are not shown event if `:showed="true"`.
			//       Don't set `:showed="true"` and add manually.
			event.popup.addTo(event.map);

			event.popup.addClassName('ar_mapbox_popup');
			event.popup._container.setAttribute(this.$options._scopeId, true);
		},

		popupClose(e) {
			// TODO: ???
			this.$refs.map_popup.remove();
		},

		// TODO: rewrite addition/exclution to/from white/black list
		async handleActionButtonClick(type) {
			this.isBusy = true;
			try {
				if (type === this.type) {
					// remove rule on a repeating click
					this.type = null;
					await this._removeDevicesFromManualFilter();
				}
				else {
					// add rule
					this.type = type;
					await this._addDevicesToManualFilter(type);
				}
			}
			catch (error) {
				const data = error.response && error.response.data;
				this.$log.error(`Error ${error.response && error.response.status}. Failed rule change.`, data);
				this.isBusy = false;
				throw error;
			}

			this.$emit('update-required');
			this.isBusy = false;
		},

		async _addDevicesToManualFilter(type) {
			const devices = this.devices.map(d => d.properties.id);
			await addDevicesToManualFilter(
				this.campaignId,
				type,
				devices,
			);
		},

		async _removeDevicesFromManualFilter() {
			const devices = this.devices.map(d => d.properties.id);
			await removeDevicesFromManualFilter(
				this.campaignId,
				devices,
			);
		},
	}
};
</script>

<style lang="sass" scoped >
.ar_mapbox_popup
	will-change: unset
	& ::v-deep
		.mapboxgl-popup-tip
			// display: none

		.mapboxgl-popup-content
			+default__shadow
			padding: 20px
			border-radius: 10px

.popup-body
	display: flex
	flex-direction: column
	width: 250px

.row-name
	display: inline-flex
	// box-sizing: border-box
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between
	align-items: stretch
	align-content: stretch
	width: 100%

	font-size: $fsz__normal
	line-height: 17px
	color: $nice_color-black

.name-wrapper
	display: inline-flex
	flex-grow: 1
	flex-shrink: 1

	.name
		flex-grow: 1
		font-family: inherit
		// vertical-align: top
		font-size: 100%
		font-weight: bold
		margin-left: 10px

.nice-button
	flex-grow: 0
	flex-shrink: 0

	$padding: 5px
	margin: (-$padding + 1) (-$padding + 1) 0 0
	min-height: auto
	height: 20px
	padding: $padding
	margin-left: 20px
	// line-height: 0
	// height: 10px

	.nice-icon
		margin-right: 0

	.nice-icon
		vertical-align: top
		margin-right: .5em


.address
	padding: 10px 0
	font-family: inherit
	font-size: 100%
	// line-height: $fsz__large
	color: $nice_color-graydark

.control
	display: flex
	flex-direction: row
	justify-content: space-between

	margin-top: 8px
	margin-bottom: 0


	&:first-child
		margin-left: 0

.device-action-button
	margin-left: 0px

.close-button
	max-width: 40px
</style>
