<template>
	<symbol :id="`${prefix}pause-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<rect x="13" y="9" width="2" height="6" class="sign" />
		<rect x="9" y="9" width="2" height="6" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPause24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-purple_light_ultra})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-purple})
</style>
