import _ from 'underscore';
import axios from 'axios';
import Vue from 'vue';

import apiDsp from '@/api/dsp/creative';
import apiSsp from '@/api/ssp/creative';
import { setterGenerator } from '@/utilites.js';
import { storeFactory } from './_base';


const DEFAULT_PAGE_SIZE = 20;

const serializeItemTime = item => ({
	...item,
	ctime: new Date(item.ctime),
	mtime: new Date(item.mtime),
});



const serializeCreative = item => {
	const mediaType = item.type;
	const media = serializeItemTime(item[mediaType]);

	// const audit = item.audit instanceof Array
	// 	? item.audit.map(serializeItemTime)
	// 	: serializeItemTime(item.audit);

	return {
		...item,
		[mediaType]: media,
		// audit,
		ctime: new Date(item.ctime),
		mtime: new Date(item.mtime),
	};
};

const getRequest = (side) => side == 'ssp' ? apiSsp : apiDsp;
const generated = storeFactory('creative', getRequest, serializeCreative);







export default {
	namespaced: true,

	state: {
		list: [],
		query: null,
		fieldsSensitive: null,
		totalCount: 0,
		page: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		meta: null,
	},

	mutations: {
		...setterGenerator(['fieldsSensitive', 'totalCount', 'page', 'pageSize', 'meta',]),
		...generated.mutations,  // setList, changeItem

		/**
		 * Puses an item to the store
		 * @param  {object} state - module state
		 * @param  {object} item - an item
		 * @return {[type]}       [description]
		 */

		push(state, item) {
			state.list.push(serializeCreative(item));
		},

		/**
		 * save filters to store
		 * + сохраняет стек используемых в фильтрах полей
		 *
		 * @param {State}
		 * @param {query{}} page
		 */
		setQuery(state, query) {
			state.query = query;

			let fields = {};
			_.each(_.keys(state.query), (filter) => {
				let filterFields = [filter];

				switch(filter) {
				case 'search':
					filterFields = ['name'];
					break;

				case 'by_me':
					filterFields = ['created_by'];
					break;
				}

				_.each(filterFields, (field) => {

					if (_.has(fields, field)) {
						fields[field].push(filter);
					} else {
						fields[field] = [filter];
					}
				});
			});

			state.fieldsSensitive = Object.freeze(fields);
		},
	},

	getters: {
		...generated.getters,  // loadingKeyBase, loadingKeyList, loadingKeyItem, loadingKeyItemChange, isLoading, isLoadingList, isLoadingItem, isFilterChanged
	},

	actions: {
		...generated.actions,  // getList, getItem, change, delete, refresh

		/**
		 * Create resource item.
		 * POST request to create resource
		 *
		 * @param {object} data of resourse object to create
		 * @param {object} config axios request config
		 *
		 * @returns {object} result resourse object
		 */
		async create({ state, commit, dispatch, rootState }, { data, config }) {
			let item = null;
			try {
				item = serializeCreative(await getRequest(rootState.app.side).create(data, config));
			} catch (error) {
				if (axios.isCancel(error)) {
					/* request canceled */
					Vue.$log.info('Creation request has been canceled by client.');
				} else {
					throw error;
				}
				return null;
			}

			// dispatch('refresh');
			commit('push', item);
			return item;
		},


		/**
		 * Remove creative from campaign but not from the DB
		 *
		 * @param {number} id - creative id
		 * @param {number} campaign - campaign id
		 */
		async removeFromCampaign({ state, commit, dispatch, rootState }, { id, campaign }) {
			let res = await getRequest(rootState.app.side).removeFromCampaign(id, campaign);
			return res;
		},


		/**
		 * Update creative audit for the specified campaign
		 *
		 * @param {number} id - creative id
		 * @param {number} campaign - campaign id
		 * @param {number} status - audit status code
		 * @param {string} feedback - audit status reason
		 * @return {promise} put request
		 */
		async updateAuditForCampaign({ dispatch, state, rootState }, {id, campaign, status, feedback}) {
			if (rootState.app.side !== 'ssp') {
				Vue.$log.warn(`Attempting to update creative audit from the '${rootState.app.side}' has been prevented.`);
				return null;
			}
			return await getRequest(rootState.app.side).updateAuditForCampaign(id, campaign, status, feedback);
		},

	},
};
