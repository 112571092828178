const _ = require('underscore');
const palette = require('./palette.js');

const PREFIX = process.env.VUE_APP_COLOR_SCHEME_PREFIX;


/**
 * Parse input palette object transform colors alias
 *
 * @param {object} raw - object with palette
 *
 * @returns {object} processed palette
 */
function parsePalette(raw=palette) {
	return _.mapObject(raw, (value, key, instance) => {
		if (_.has(instance, value)) {
			return instance[value];
		}

		return value;
	});
}


/**
 * Return sass code line to mixin with color palette
 *
 * @param {object} extra - extra sass code
 *
 * @returns {string} big string with sass color vriables
 */
function sassPaletteMixin(extra) {
	let sass = [];

	_.each(parsePalette(palette), (color, name) => {
		sass.push(`$${PREFIX}${name}: ${color}`);
	});

	if (extra) {
		sass.push(extra);
	}

	return sass.join('\n');
}

module.exports = {
	sassPaletteMixin,
	parsePalette,
};
