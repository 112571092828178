<template>
	<section class="page-section">
		<h1>NiceSlider</h1>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<nice-input
					id="test-input-placeholder"
					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					type="text"
					placeholder="Type placeholder here"
					label="input label"
				/>
				<nice-slider
					v-model="demoNo1"
					:axis="demoNo1Axis"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					labeled
					hide-value
				/>
				<nice-slider
					v-model="demoNo1"
					:axis="demoNo1Axis"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
				/>
				<nice-slider
					:axis="9"
					:value="8"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					label="label"
					hide-value
				/>
				<nice-slider
					:axis="9"
					:value="8"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					label="label"
					hint="The Ultimate Question of Life, the Universe, and Everything"
					hide-value
				/>
			</div>
		</div>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<nice-input
					id="test-input-placeholder"
					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					type="text"
					placeholder="Type placeholder here"
					label="input label"
				/>
				<nice-slider
					v-model="demoNo1"
					:axis="demoNo1Axis"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					labeled
				/>
				<nice-slider
					:axis="9"
					:value="8"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					label="input label"
				/>
				<nice-slider
					:axis="9"
					:value="8"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl1"
					label="input label"
					hint="42"
				/>
			</div>
		</div>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div
					class="cl-xs12 cl-sm6 cl-md4 cl-xl3 cl-pad"
				>
					<h2>Number</h2>
					<p><nice-slider :axis="9" :value="8" /></p>
					<p><code class="code-preview" >&lt;nice-slider :axis="9" :value="8" /&gt;</code></p>
				</div>

				<div
					class="cl-xs12 cl-sm6 cl-md4 cl-xl3 cl-pad"
				>
					<h2>Array</h2>
					<p><nice-slider :axis="['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']" value="five" /></p>
					<p><code class="code-preview" >&lt;nice-slider :axis="['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']" value="five" /&gt;</code></p>
				</div>

				<div
					class="cl-xs12 cl-sm6 cl-md4 cl-xl3 cl-pad"
				>
					<h2>Object</h2>
					<p><nice-slider :axis="{ start: -2, end: 2, step: .5 }" :value="-2" /></p>
					<p><code class="code-preview" >&lt;nice-slider :axis="{ start: -2, end: 2, step: .5 }" :value="-2" /&gt;</code></p>
				</div>
			</div>
		</div>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div
					class="cl-xs12 cl-sm6 cl-md4 cl-xl3 cl-pad"
				>
					<h2>Array of objects</h2>
					<p><nice-slider :axis="arrayOfObjectValue" value="five" /></p>
					<p><pre class="code-preview" ><code>
&lt;nice-slider :axis="[
	{
		value: 0,
		label: '0 - custom preview',
		position: 0,
	},
	{
		value: 1,
		position: 70,
	},
	{
		value: 2,
		position: 100,
	}
]" value="five" /&gt;
					</code></pre></p>
					<p><nice-slider :axis="distanceAxis" :value="15000" /></p>
				</div>
			</div>
		</div>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div
					class="cl-xs12 cl-sm6 cl-md4 cl-xl3 cl-pad"
				>
					<h2>Colors</h2>
					<p><nice-slider class="color-slider" :value="42" /></p>
					<p><pre class="code-preview" ><code>
// template
&lt;nice-slider class="color-slider" :value="42" /&gt;

// styles
.color-slider
	--bar-bg: #d9c3b7
	--range-color: #27140e
	--marker-color: #27140e
					</code></pre></p>
				</div>
				<div
					class="cl-xs12 cl-sm6 cl-md4 cl-xl3 cl-pad"
				>
					<h2>Reactivity</h2>
					<p>{{ testNo1 }}</p>
					<p><nice-slider v-model="testNo1" /></p>
					<p><nice-slider v-model="testNo1" /></p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { formatMetric } from '@/utilites';

import NiceInput from '@/ui/nice-input';
import NiceSlider from '@/ui/nice-slider';


export default {
	name: 'TabSlider',

	components: {
		NiceInput,
		NiceSlider,
	},

	data() {
		let distanceAxis = [];
		const m = 10 + 34 + 66;

		for (let i = 1;i<=m;i++) {
			const v = i>10 ? i>48 ? (i-44)*5000 : (i-8)*500 : i*100;
			distanceAxis.push({
				value: v,
				label: `${formatMetric(v)}m`,
				position: i/m*100,
			});
		}

		return {
			demoNo1: 42,
			demoNo1Axis: 123,
			testNo1: 15,
			arrayOfObjectValue: [
				{
					value: 0,
					label: '0 - custom preview',
					position: 0,
				},
				{
					value: 1,
					position: 70,
				},
				{
					value: 2,
					position: 100,
				}
			],
			distanceAxis,
		};
	},
};
</script>

<style lang="sass" scoped >
.color-slider
	--bar-bg: #d9c3b7
	--range-color: #27140e
	--marker-color: #27140e
</style>
