<template>
	<symbol :id="`${prefix}chbx-circle`" viewBox="0 0 14 14">
		<circle
			cx="7"
			cy="7"
			:r="state == 'checked' ? 5 : 6"
			fill="none"
			:stroke="getColor(state)"
			:stroke-width="state == 'checked' ? 4 : 2"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconChbxCircle',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number, Boolean],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				unchecked: this.$palette.gray,
				checked: this.$palette.main_color,

				unchecked__disabled: this.$palette.gray__disabled,
				checked__disabled: this.$palette.disabled_color,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
