<template>
	<symbol :id="`${prefix}basket-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M15 11H13V16H15V11Z" class="sign" />
		<path d="M10 11H12V16H10V11Z" class="sign" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15 8V7.5C15 6.12158 13.8789 5 12.5 5C11.1216 5 10 6.12158 10 7.5V8H6V10H7V16C7 17.6543 8.3457 19 10 19H15C16.6543 19 18 17.6543 18 16V10H19V8H15ZM12 7.5C12 7.22412 12.2241 7 12.5 7C12.7759 7 13 7.22412 13 7.5V8H12V7.5ZM16 16C16 16.5518 15.5518 17 15 17H10C9.44873 17 9 16.5518 9 16V10H16V16Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolBasket24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})
</style>
