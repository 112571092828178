import { request } from './request';


export default function (prefix) {

	return {

		/**
		 * Request/Campaign (device selection) stats
		 *
		 * @param {string} filter - query string (with ?)
		 *
		 * @returns {object}
		 */
		async stats(filter) {
			let result = await request(`${prefix()}stats/${filter}`);
			return result;
		},


		/**
		 * Request available resolutions
		 *
		 * @returns {string[]} resolution
		 */
		async availableResolutions(params) {
			let result = await request(`${prefix()}resolutions/`, params);
			return result;
		},

		/**
		 * Request payment models
		 *
		 * @returns {string[]} value
		 * @returns {string[]} label
		 */
		async paymentModels() {
			let result = await request(`${prefix()}payment_models/`);
			return result;
		},


		/**
		 * Request broadcast types
		 *
		 * @param params axios query string params
		 *
		 * @returns {string[]} value
		 * @returns {string[]} label
		 */
		async broadcastTypes(params) {
			let result = await request(`${prefix()}types/`, params);
			return result;
		},


		/**
		 * Request orientations
		 */
		async orientations() {
			return await request(`${prefix()}orientations/`);
		},


		//
		// TMP:
		//
		async getEnvironments(params) {
			let result = await request(`${prefix()}environments/`, params);
			return result;
		},


		/**
		 * TODO: must be an actual request to the backend
		 */
		async getInterestsAndActivities() {
			return new Promise(function(resolve, reject) {
				// TODO: move into the backend
				resolve([
					{ value: '1', label: 'Arts & Entertainment', parent: null },
					{ value: '2', label: 'Arts and Crafts Lovers', parent: '1' },
					{ value: '3', label: 'Avid Reader', parent: '1' },
					{ value: '4', label: 'Movie Buffs', parent: '1' },
					{ value: '5', label: 'Music Discovery Enthusiasts', parent: '1' },
					{ value: '6', label: 'Music Lover', parent: '1' },
					{ value: '7', label: 'Music Streamers', parent: '1' },
					{ value: '8', label: 'Photo & Video Enthusias', parent: '1' },
					{ value: '9', label: 'Photo Editors', parent: '1' },
					{ value: '10', label: 'Photo Sharer', parent: '1' },

					{ value: '11', label: 'Automotive & Boating', parent: null },
					{ value: '12', label: 'Vehicle Owner', parent: '11' },

					{ value: '13', label: 'Communications', parent: null },
					{ value: '14', label: 'Influencers', parent: '13' },
					{ value: '15', label: 'Social Influencer', parent: '13' },

					{ value: '16', label: 'Financial Services', parent: null },
					{ value: '17', label: 'Casual Investors', parent: '16' },
					{ value: '18', label: 'Home Financial Planners', parent: '16' },
					{ value: '19', label: 'Insurance Owners', parent: '16' },
					{ value: '20', label: 'Mobile Banking User', parent: '16' },
					{ value: '21', label: 'Mobile Payment Makers', parent: '16' },

					{ value: '22', label: 'Food & Restaurant', parent: null },
					{ value: '23', label: 'Coffee Connoisseurs', parent: '22' },
					{ value: '24', label: 'Fast Food Patron', parent: '22' },
					{ value: '25', label: 'Foodies', parent: '22' },
					{ value: '26', label: 'Home Chefs', parent: '22' },
					{ value: '27', label: 'Restaurant Hoppers', parent: '22' },

					{ value: '28', label: 'Gaming', parent: null },
					{ value: '29', label: 'Casino Gamers', parent: '28' },
					{ value: '30', label: 'Casino App Players', parent: '28' },
					{ value: '31', label: 'Card & Board Gamer', parent: '28' },
					{ value: '32', label: 'eSports Fans', parent: '28' },
					{ value: '33', label: 'Hardcore Gamers', parent: '28' },
					{ value: '34', label: 'Social & Casual Gamers', parent: '28' },

					{ value: '35', label: 'Health & Fitness', parent: null },
					{ value: '36', label: 'Avid Cyclists', parent: '35' },
					{ value: '37', label: 'Avid Runners', parent: '35' },
					{ value: '38', label: 'Calorie Counters', parent: '35' },
					{ value: '39', label: 'Fitness Device Owners', parent: '35' },
					{ value: '40', label: 'Health & Fitness Enthusiasts', parent: '35' },

					{ value: '41', label: 'Home & Garden', parent: null },
					{ value: '42', label: 'Home & Garden Pros', parent: '41' },
					{ value: '43', label: 'Home Improvement Enthusiasts', parent: '41' },

					{ value: '44', label: 'Language', parent: null },
					{ value: '45', label: 'Chinese Speakers', parent: '44' },
					{ value: '46', label: 'French Speakers', parent: '44' },
					{ value: '47', label: 'German Speakers', parent: '44' },
					{ value: '48', label: 'Italian Speakers', parent: '44' },
					{ value: '49', label: 'Japanese Speakers', parent: '44' },
					{ value: '50', label: 'Spanish Speakers', parent: '44' },
					{ value: '51', label: 'Russian Speakers', parent: '44' },

					{ value: '52', label: 'News', parent: null },
					{ value: '53', label: 'e-News Readers', parent: '52' },
					{ value: '54', label: 'News Readers', parent: '52' },

					{ value: '55', label: 'On Demand Economy', parent: null },
					{ value: '56', label: 'On-Demand Economy Participants', parent: '55' },
					{ value: '57', label: 'On-Demand Learning', parent: '55' },
					{ value: '58', label: 'On-Demand Lodging', parent: '55' },
					{ value: '59', label: 'On-Demand Transportation', parent: '55' },

					{ value: '60', label: 'Sharing Economy', parent: null },
					{ value: '61', label: 'Ride Sharers', parent: '60' },
					{ value: '62', label: 'Room Sharers', parent: '60' },

					{ value: '63', label: 'Sports & Recreation', parent: null },
					{ value: '64', label: 'Football Fans', parent: '63' },
					{ value: '65', label: 'Basketball Fans', parent: '63' },
					{ value: '66', label: 'Sports Fans', parent: '63' },
					{ value: '67', label: 'Extreme Sports Enthusiasts', parent: '63' },
					{ value: '68', label: 'Hockey Fans', parent: '63' },
					{ value: '69', label: 'Outdoor Sports Enthusiasts', parent: '63' },
					{ value: '70', label: 'Baseball Fans', parent: '63' },

					{ value: '71', label: 'Technology', parent: null },
					{ value: '72', label: 'Home Automation Enthusiasts', parent: '71' },
					{ value: '73', label: 'Home Entertainment Enthusiasts', parent: '71' },
					{ value: '74', label: 'Tech & Gadget Enthusiasts', parent: '71' },

					{ value: '75', label: 'Telecommunications', parent: null },
					{ value: '76', label: 'Wireless Plan App Owners', parent: '75' },

					{ value: '77', label: 'Travels', parent: null },
					{ value: '78', label: 'Commuters', parent: '77' },
					{ value: '79', label: 'Vacationers', parent: '77' },

					{ value: '80', label: 'Video Streaming', parent: null },
					{ value: '81', label: 'Basic TV Streamers', parent: '80' },
					{ value: '82', label: 'Free Content Streamers', parent: '80' },
					{ value: '83', label: 'On Demand Media Streamers', parent: '80' },
					{ value: '84', label: 'Over the Top Device', parent: '80' },
					{ value: '85', label: 'Service Provider Streamers', parent: '80' },
					{ value: '86', label: 'Subscription Content Streamers', parent: '80' },
					{ value: '87', label: 'TV Cord Cutters', parent: '80' },
					{ value: '88', label: 'TV Subscribers', parent: '80' },
					{ value: '89', label: 'Video Streamers', parent: '80' },
				]);
			});
		},


		/**
		 * TODO: must be an actual request to the backend
		 */
		async getLifeStages() {
			return new Promise(function(resolve, reject) {
				// TODO: move into the backend
				resolve([
					{ value: '1', label: 'Professional field', parent: null },
					{ value: '2', label: 'Average income', parent: null },

					{ value: '8', label: 'Automotive business', parent: '1' },
					{ value: '9', label: 'Administrative staff', parent: '1' },
					{ value: '10', label: 'Banks, investments, leasing', parent: '1' },
					{ value: '11', label: 'Security', parent: '1' },
					{ value: '12', label: 'Accounting, management accounting, business finance', parent: '1' },
					{ value: '13', label: 'High management', parent: '1' },
					{ value: '14', label: 'Public service, non-profit organizations (NPOs)', parent: '1' },
					{ value: '15', label: 'Raw material extraction', parent: '1' },
					{ value: '16', label: 'Domestic staff', parent: '1' },
					{ value: '17', label: 'Procurement', parent: '1' },
					{ value: '18', label: 'Installation and service', parent: '1' },
					{ value: '19', label: 'Informational technologies, internet, telecom', parent: '1' },
					{ value: '20', label: 'Art, entertainment, mass media', parent: '1' },
					{ value: '21', label: 'Business, Consultinng', parent: '1' },
					{ value: '22', label: 'Marketing, advertising, PR', parent: '1' },
					{ value: '23', label: 'Medicine, pharmacy', parent: '1' },
					{ value: '24', label: 'Science, education', parent: '1' },
					{ value: '25', label: 'The beginning of a career, students', parent: '1' },
					{ value: '26', label: 'Sales', parent: '1' },
					{ value: '27', label: 'Production', parent: '1' },
					{ value: '28', label: 'Working staff', parent: '1' },
					{ value: '29', label: 'Sports clubs, fitness, beauty salons', parent: '1' },
					{ value: '30', label: 'Insurance', parent: '1' },
					{ value: '31', label: 'Construction, real estate', parent: '1' },
					{ value: '32', label: 'Transport, logistics', parent: '1' },
					{ value: '33', label: 'Tourism, hotels, restaurants', parent: '1' },
					{ value: '34', label: 'Personnel management, training', parent: '1' },
					{ value: '35', label: 'Lawyers', parent: '1' },

					{ value: '3', label: '< $5K', parent: '2' },
					{ value: '4', label: '$5K - $10K', parent: '2' },
					{ value: '5', label: '$10K - $20K', parent: '2' },
					{ value: '6', label: '$20K - $50K', parent: '2' },
					{ value: '7', label: '> $50K', parent: '2' },
				]);
			});
		},


		/**
		 * TODO: must be an actual request to the backend
		 */
		async getIntents() {
			return new Promise(function(resolve, reject) {
				// TODO: move into the backend
				resolve([
					{ value: '1', label: 'Shopping', parent: null },
					{ value: '2', label: 'Technology', parent: null },
					{ value: '3', label: 'Travel', parent: null },
					{ value: '4', label: 'Post Services', parent: null },
					{ value: '5', label: 'Banks Services', parent: null },
					{ value: '6', label: 'Events', parent: null },
					{ value: '7', label: 'Fashion', parent: null },
					{ value: '8', label: 'Real Estate', parent: null },

					{ value: '9', label: 'Beauty Retail Shoppers', parent: 1 },
					{ value: '10', label: 'Fashion Retail Shoppers', parent: 1 },
					{ value: '11', label: 'FMCG retail Shoppers', parent: 1 },
					{ value: '12', label: 'Pharmacy retail Shoppers', parent: 1 },
					{ value: '13', label: 'Spirits retail Shoppers', parent: 1 },
					{ value: '14', label: 'E-commerce retail Shoppers', parent: 1 },
					{ value: '15', label: 'Impulse Buyers', parent: 1 },
					{ value: '19', label: 'Auto Shoppers', parent: 1 },

					{ value: '16', label: 'Tec gadget Shoppers', parent: 2 },

					{ value: '17', label: 'Flight Shoppers', parent: 3 },
					{ value: '18', label: 'Hotel Shoppers', parent: 3 },
					{ value: '20', label: 'Ticket Shoppers', parent: 3 },
				]);
			});
		},

	};
}
