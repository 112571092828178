<template>
	<symbol :id="`${prefix}draft-24`" viewBox="0 0 24 24" >
		<circle cx="12" cy="12" r="12" class="base" />
		<circle
			cx="12"
			cy="12"
			r="7"
			stroke-width="2"
			stroke-dasharray="2.9 2.6"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolDraft24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: none
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})
</style>
