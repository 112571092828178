<template>
	<symbol :id="`${prefix}chbx-rnd`" viewBox="0 0 14 14">
		<!-- CHECKED -->
		<template
			v-if="state == 'checked'"
		>
			<circle
				cx="7"
				cy="7"
				r="7"
				:fill="getColor('main')"
			/>
			<polyline
				points="4.3,6.6 6.4,8.9 9.8,5.6"
				fill="none"
				stroke-width="1"
				stroke-miterlimit="10"
				:stroke="getColor('glyph')"
			/>
		</template>

	<!-- UNCHECKED -->
		<circle
			v-else
			cx="7"
			cy="7"
			r="6"
			fill="none"
			:stroke="getColor('border')"
			stroke-width="2"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconChbxRnd',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number, Boolean],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				glyph: this.$palette.white,
				border: this.$palette.gray,

				main__disabled: this.$palette.disabled_color,
				glyph__disabled: this.$palette.white__disabled,
				border__disabled: this.$palette.gray__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
