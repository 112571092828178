<template>
		<section class="layout-full_width" >
			<header class="header">
				<h1 class="title">{{ $t(`${resource} list`) | capitalize }}</h1>

				<div class="top-panel">
					<nice-button-2
						icon="plus-24"
						class="add-button"
						icon-painted
						@click="$router.push({ name: `${ resource }/create` })"
					>
						{{ $t(`add ${resource}`) }}
					</nice-button-2>


					<nice-input
						v-model="filter.name"
						class="search-input"
						type="text"
						:placeholder="$t('search') | capitalize"
					>
						<nice-icon
							icon="search"
						/>
					</nice-input>
				</div>
			</header>

			<nice-users-list
				:items="filteredList"
				@remove="removeHandler"
				@interact="interactHandler"
			/>

		</section>
</template>

<script>
import Vue from 'vue';
import NiceUsersList from '@/ui/nice-users/nice-users-list';
import NiceInput from '@/ui/nice-input';


export default {
	name: 'PageUserList',

	components: {
		NiceUsersList,
		NiceInput,
	},

	props: {
		resource: {
			type: String,
			default: 'user',
		},
	},

	data() {
		return {
			filter: {
				name: '',
			},
			listStub: [],
		};
	},


	computed: {
		// TODO: get items from API through the store
		list() {
			// request stub
			return this.listStub;
		},

		filteredList() {
			return this.list.filter(item => {
				const filterString = this.filter.name.toLowerCase();

				try {
					return item.last_name.toLowerCase().search(filterString) > -1
						|| item.first_name.toLowerCase().search(filterString) > -1
						|| item.email.toLowerCase().search(filterString) > -1
						|| (`${item.first_name} ${item.last_name}`).toLowerCase().search(filterString) > -1
						|| (`${item.last_name} ${item.first_name}`).toLowerCase().search(filterString) > -1;
				} catch (error) {
					this.$log.error(error);
					return null;
				}
			});
		},
	},


	methods: {
		removeHandler(id) {
			const i = this.listStub.indexOf(this.listStub.find(item => item.id === id));
			if (i === -1) {
				return null;
			}

			this.listStub.splice(i, 1);
		},

		interactHandler(id) {
			this.$router.push({
				name: `${this.resource}-details`,
				params: { id },
			});
		}
	},


	created() {
		// TODO: what for?
		Vue.set(this, 'listStub', []);
	},
};
</script>

<style lang="sass" scoped >
.search-input
	width: 200px

.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px

	& > *:not(:first-child)
		margin-left: 20px

</style>
