<template>
	<span
		class="nice-label"
	>
		<label
			:class="{
				'ni_label--label_error': isError
			}"
			class="ni_label--label"
			v-bind="$attrs"
			v-on="$listeners"
			v-html="label"
		/>



		<nice-hint
			v-if="hasHint"
			:trigger="hintTrigger"
			class="ni_label--hint"
		>
			<slot><span v-html="hint" /></slot>
		</nice-hint>

		<template v-if="isError">
			<nice-hint
				v-if="errorMessage"
				:trigger="hintTrigger"
				class="ni_label--hint"
				icon="error"
			>
				<span
					v-html="errorMessage"
				/>
			</nice-hint>
			<nice-icon-2
				v-else
				class="ni_label--hint"
				icon="error"
			/>

		</template>
	</span>
</template>


<script>
import NiceHint from '@/ui/nice-hint';


export default {
	name: 'NiceLabel',


	components: {
		NiceHint,
	},


	props: {
		label: {
			type: String,
			required: true,
		},

		hint: {
			type: String,
		},

		error: {
			type: [String, Boolean],
			defautl: false,
		},

		hintTrigger: {
			type: String,
		},
	},


	computed: {
		hasHint() {
			return !!(this.$slots.default || this.hint);
		},

		isError() {
			return !!this.error;
		},

		errorMessage() {
			// return error if it's string
			return this.error !== true && this.error || null;
		},
	},
};
</script>

<style lang="sass" scoped >
.nice-label
	display: inline-flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center
	align-content: center

	max-width: 100%
	height: $ni_label-height

	font-size: $ni_label-font-size
	line-height: $ni_label-height

.ni_label--label
	color: var(--text_2_color)
	font-family: inherit
	font-size: inherit
	line-height: inherit
	text-overflow: ellipsis
	text-transform: uppercase
	white-space: nowrap
	overflow: hidden

.ni_label--label_error
	color: $nice_color-red

.ni_label--hint
	flex-shrink: 0
	margin-left: 7px
</style>
