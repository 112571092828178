<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 12 12" >
		<polyline
			:stroke="disabled ? colors.disabled : colors.main"
			fill="none"
			points="3.5 1 8.5 6 3.5 11"
			stroke-miterlimit="10"
			:style="{
				transform: `rotate3d(0, 0, 1, ${angle}deg)`,
				transformOrigin: 'center',
			}"

		/>
	</symbol>
</template>

<script>
export const slug = 'arrow-corner';


export default {
	name: 'NiIconArrowCorner',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				disabled: this.$palette.disabled_color,
				...this.palette,
			};
		},

		angle() {
			let angle = this.state;
			switch(angle) {
			case 'forward':
			case 'next':
			case 'right':
				angle = 0;
				break;

			case 'bottom':
			case 'down':
				angle = 90;
				break;

			case 'back':
			case 'prev':
			case 'left':
				angle = 180;
				break;

			case 'top':
			case 'up':
				angle = 270;
				break;
			}

			return angle;
		},
	},
};
</script>
