<template>
	<main>
		<header class="header_panel-control" >
			<nice-button-2
				palette="gray"
				icon="arrow-14"
				icon-placement="start"
				icon-state="90"
				@click="backButton"
			>
				{{ $t('campaign.back_btn_label') | capitalize }}
			</nice-button-2>

			<nice-button-2
				palette="gray"
				@click="modalClose"
			>
				{{ $t('campaign.close_btn_label') | capitalize }}
			</nice-button-2>
		</header>

		<NiceWizard2
			ref="wizard"
			:step-initial="step"
			class="create_wizard"
			:preNextStepHook="nextStep"
			@next-step="onStepChange"
			@prev-step="onStepChange"
			@to-step="onStepChange"
		>
			<step01
				v-model="valueStep01"
				:context="{ instance, step, errors }"
				data-ni_wizard_2-name_trans_key="campaign.step01_title"
			/>
			<step02
				v-if="enabledPaymentModels.length > 1"
				:context="{ instance, step, errors }"
				data-ni_wizard_2-name_trans_key="campaign.step02_title"
				@payment-model-change="handlePaymentModelChange"
			/>
			<step03
				v-model="valueStep03"
				:context="{ instance, step, errors }"
				data-ni_wizard_2-name_trans_key="campaign.step03_title"
				@update:value="debouncedCreateOrUpdateRequest"
			/>
			<step04
				v-model="valueStep04"
				:context="{ instance, step, errors }"
				data-ni_wizard_2-name_trans_key="campaign.step04_title"
				@request-create="createRequest"
			/>
		</NiceWizard2>

		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-icons">
			<defs>
				<symbol id="payment_model_icon__ad_plays" viewBox="0 0 78 79">
					<circle opacity="0.1" cx="39" cy="39" r="39" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M73.1835 53.4347C75.0429 48.9456 76 44.1343 76 39.2754H78C78 44.3969 76.9912 49.4683 75.0313 54.2C73.0713 58.9317 70.1986 63.231 66.5771 66.8525C62.9556 70.474 58.6563 73.3467 53.9246 75.3067C49.1929 77.2666 44.1215 78.2753 39 78.2753V76.2753C43.8589 76.2753 48.6702 75.3183 53.1593 73.4589C57.6483 71.5995 61.7272 68.8741 65.1629 65.4383C68.5987 62.0025 71.3241 57.9237 73.1835 53.4347Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.64591 61.7995C12.6038 65.6543 16.292 68.8888 20.5 71.3183L19.5 73.0503C15.0646 70.4896 11.177 67.0802 8.05921 63.017C4.94141 58.9538 2.65444 54.3163 1.32889 49.3693C0.00333976 44.4223 -0.334843 39.2626 0.333649 34.1849C1.00215 29.1071 2.66423 24.2108 5.225 19.7754L6.95705 20.7754C4.5276 24.9833 2.95075 29.6286 2.31654 34.4459C1.68232 39.2632 2.00316 44.1583 3.26074 48.8517C4.51832 53.545 6.688 57.9447 9.64591 61.7995Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M34.1705 2.59193C29.3532 3.22614 24.7079 4.80299 20.5 7.23244L19.5 5.50039C23.9354 2.93962 28.8317 1.27754 33.9095 0.609039C38.9872 -0.0594524 44.1469 0.278731 49.0939 1.60429C54.0409 2.92984 58.6784 5.21681 62.7416 8.33461C66.8048 11.4524 70.2142 15.34 72.7749 19.7754L71.0429 20.7754C68.6134 16.5674 65.379 12.8792 61.5241 9.92131C57.6693 6.96339 53.2696 4.79371 48.5763 3.53614C43.8829 2.27856 38.9878 1.95771 34.1705 2.59193Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M39.7246 28H49.7246C53.0383 28 55.7246 30.6863 55.7246 34V44C55.7246 47.3137 53.0383 50 49.7246 50H39.7246V28ZM37.7246 26H39.7246H49.7246C54.1428 26 57.7246 29.5817 57.7246 34V44C57.7246 48.4183 54.1428 52 49.7246 52L39.7246 52H37.7246L27.7246 52C23.3063 52 19.7246 48.4183 19.7246 44V34C19.7246 29.5817 23.3063 26 27.7246 26H37.7246ZM29.7245 50H27.7246C24.4109 50 21.7246 47.3137 21.7246 44V34C21.7246 31.3876 23.3942 29.1651 25.7246 28.3414V33C25.7246 35.2091 27.5155 37 29.7246 37C31.9337 37 33.7246 35.2091 33.7246 33V28H37.7246V50H31.7245V42.3818L26.2773 45.1054L27.1718 46.8943L29.7245 45.6179V50ZM27.7246 33V28H31.7246V33C31.7246 34.1046 30.8292 35 29.7246 35C28.62 35 27.7246 34.1046 27.7246 33ZM45.7246 36C45.7246 34.8954 46.62 34 47.7246 34C48.8291 34 49.7246 34.8954 49.7246 36C49.7246 37.1046 48.8291 38 47.7246 38V40C48.8291 40 49.7246 40.8954 49.7246 42C49.7246 43.1045 48.8291 44 47.7246 44C46.62 44 45.7246 43.1045 45.7246 42H43.7246C43.7246 44.2091 45.5154 46 47.7246 46C49.9337 46 51.7246 44.2091 51.7246 42C51.7246 40.8053 51.2008 39.7329 50.3704 39C51.2008 38.267 51.7246 37.1947 51.7246 36C51.7246 33.7909 49.9337 32 47.7246 32C45.5154 32 43.7246 33.7909 43.7246 36H45.7246Z" fill="currentColor"/>
				</symbol>

				<symbol id="payment_model_icon__cpm" viewBox="0 0 78 78">
					<path d="M40.1385 21.7246C32.868 21.7246 26.8929 27.266 26.2046 34.3564L21.5205 43.7246H26.1385V46.7246C26.1385 49.486 28.3771 51.7245 31.1385 51.7245H34.1385V54.7245C34.1385 55.2768 34.5862 55.7245 35.1385 55.7245C35.6908 55.7245 36.1385 55.2768 36.1385 54.7245V49.7245H31.1385C29.4817 49.7245 28.1385 48.3814 28.1385 46.7246V41.7246H24.7566L28.1642 34.9093L28.1802 34.7172C28.6912 28.5617 33.8505 23.7246 40.1385 23.7246C46.7659 23.7246 52.1385 29.0972 52.1385 35.7246C52.1385 38.5626 51.1545 41.1682 49.5082 43.2225C48.73 44.1936 48.1385 45.4307 48.1385 46.8107V54.7245C48.1385 55.2768 48.5862 55.7245 49.1385 55.7245C49.6908 55.7245 50.1385 55.2768 50.1385 54.7245V46.8107C50.1385 46.0118 50.4845 45.2025 51.0689 44.4732C52.9892 42.0769 54.1385 39.0336 54.1385 35.7246C54.1385 27.9926 47.8705 21.7246 40.1385 21.7246Z" fill="currentColor"/>
					<path d="M34.1385 39.7246C35.2431 39.7246 36.1385 38.8291 36.1385 37.7246C36.1385 36.62 35.2431 35.7246 34.1385 35.7246C33.0339 35.7246 32.1385 36.62 32.1385 37.7246C32.1385 38.8291 33.0339 39.7246 34.1385 39.7246Z" fill="currentColor"/>
					<circle opacity="0.1" cx="39.1376" cy="39.0048" r="38.9999" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M73.3212 53.1591C75.1806 48.6701 76.1376 43.8588 76.1376 38.9999H78.1376C78.1376 44.1214 77.1288 49.1928 75.1689 53.9245C73.209 58.6562 70.3363 62.9555 66.7148 66.577C63.0933 70.1985 58.794 73.0712 54.0623 75.0311C49.3306 76.991 44.2592 77.9998 39.1377 77.9998V75.9998C43.9966 75.9998 48.8079 75.0428 53.2969 73.1833C57.786 71.3239 61.8648 68.5985 65.3006 65.1628C68.7363 61.727 71.4617 57.6482 73.3212 53.1591Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.7836 61.5241C12.7415 65.3789 16.4297 68.6134 20.6376 71.0428L19.6376 72.7749C15.2023 70.2141 11.3147 66.8048 8.19689 62.7416C5.0791 58.6784 2.79214 54.0409 1.46658 49.0939C0.141035 44.1468 -0.197147 38.9872 0.471344 33.9094C1.13984 28.8317 2.80192 23.9354 5.36269 19.5L7.09474 20.5C4.66529 24.7079 3.08844 29.3532 2.45423 34.1705C1.82001 38.9878 2.14086 43.8829 3.39843 48.5762C4.65601 53.2696 6.82569 57.6693 9.7836 61.5241Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M34.3082 2.31654C29.4909 2.95074 24.8456 4.52759 20.6377 6.95704L19.6377 5.225C24.0731 2.66423 28.9694 1.00215 34.0471 0.333647C39.1249 -0.334843 44.2845 0.00334071 49.2316 1.32889C54.1786 2.65445 58.8161 4.94141 62.8793 8.05921C66.9425 11.177 70.3518 15.0646 72.9126 19.5L71.1805 20.5C68.7511 16.292 65.5166 12.6038 61.6618 9.64591C57.8069 6.688 53.4073 4.51831 48.7139 3.26074C44.0206 2.00316 39.1255 1.68232 34.3082 2.31654Z" fill="currentColor"/>
				</symbol>

				<symbol id="payment_model_icon__cpv" viewBox="0 0 78 78">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M19.993 38.6264C24.0579 32.2378 31.0431 28 39.0008 28C46.9586 28 53.9437 32.2378 58.0086 38.6264L58.3454 38.8282C58.311 38.8856 58.2764 38.9429 58.2415 39C58.2764 39.0571 58.311 39.1144 58.3454 39.1718L58.0086 39.3735C53.9437 45.7621 46.9586 50 39.0008 50C31.0431 50 24.0579 45.7621 19.993 39.3735L19.6562 39.1718C19.6907 39.1144 19.7253 39.0571 19.7602 39C19.7253 38.9429 19.6907 38.8856 19.6562 38.8282L19.993 38.6264ZM22.145 39C25.9227 44.4607 32.071 48 39.0008 48C45.9307 48 52.0789 44.4607 55.8567 39C53.5682 35.692 50.4099 33.0892 46.7349 31.5511C48.715 33.0073 50 35.3536 50 38C50 42.4183 46.4182 46 42 46C37.5817 46 34 42.4183 34 38C34 33.9761 36.9709 30.646 40.8389 30.0836C40.2332 30.0283 39.6201 30 39.0008 30C32.071 30 25.9227 33.5392 22.145 39ZM42 32C38.6863 32 36 34.6863 36 38C36 41.3137 38.6863 44 42 44C45.3137 44 48 41.3137 48 38C48 34.6863 45.3137 32 42 32Z" fill="currentColor"/>
					<circle opacity="0.1" cx="39" cy="39" r="39" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M73.1835 53.4347C75.0429 48.9456 76 44.1343 76 39.2754H78C78 44.3969 76.9912 49.4683 75.0313 54.2C73.0713 58.9317 70.1986 63.231 66.5771 66.8525C62.9556 70.474 58.6563 73.3467 53.9246 75.3067C49.1929 77.2666 44.1215 78.2753 39 78.2753V76.2753C43.8589 76.2753 48.6702 75.3183 53.1593 73.4589C57.6483 71.5995 61.7272 68.8741 65.1629 65.4383C68.5987 62.0025 71.3241 57.9237 73.1835 53.4347Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.64591 61.7995C12.6038 65.6543 16.292 68.8888 20.5 71.3183L19.5 73.0503C15.0646 70.4896 11.177 67.0802 8.05921 63.017C4.94141 58.9538 2.65444 54.3163 1.32889 49.3693C0.00333976 44.4223 -0.334843 39.2626 0.333649 34.1849C1.00215 29.1071 2.66423 24.2108 5.225 19.7754L6.95705 20.7754C4.5276 24.9833 2.95075 29.6286 2.31654 34.4459C1.68232 39.2632 2.00316 44.1583 3.26074 48.8517C4.51832 53.545 6.688 57.9447 9.64591 61.7995Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M34.1705 2.59193C29.3532 3.22614 24.7079 4.80299 20.5 7.23244L19.5 5.50039C23.9354 2.93962 28.8317 1.27754 33.9095 0.609039C38.9872 -0.0594524 44.1469 0.278731 49.0939 1.60429C54.0409 2.92984 58.6784 5.21681 62.7416 8.33461C66.8048 11.4524 70.2142 15.34 72.7749 19.7754L71.0429 20.7754C68.6134 16.5674 65.379 12.8792 61.5241 9.92131C57.6693 6.96339 53.2696 4.79371 48.5763 3.53614C43.8829 2.27856 38.9878 1.95771 34.1705 2.59193Z" fill="currentColor"/>
				</symbol>
			</defs>
		</svg>

		<errors-modal
			v-if="showErrorsPopup"
			:errors="errors"
			class="layout_block-full_width"

			@close="showErrorsPopup=false"
		/>
	</main>
</template>


<script>
import _ from 'underscore';

import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import {
	PENDING_AUDIT as STATUS_AWAITING_APPROVAL,
	// APPROVED as STATUS_APPROVED,
} from '@/constants/audit-status-codes';

// import NiceModal from '@/ui/nice-modal';
import NiceWizard2 from '@/ui/nice-wizard-2';
import ErrorsModal from './errors-modal';

import Step01 from './step_01';
import Step02 from './step_02';
import Step03 from './step_03';
import Step04 from './step_04';

// milliseconds. is 1 second too short?
const DEBOUNCE_TIMEOUT = 1000;


export default {
	name: 'PageRequestCreate',


	components: {
		// NiceModal,
		NiceWizard2,
		ErrorsModal,

		Step01,
		Step02,
		Step03,
		Step04,
	},


	props: {
		/**
		 * Request ID. If wizard is using for a request editing.
		 */
		id: {
			type: Number,
			default: 0,
			required: false,
		},

		/**
		 * `resource` prop
		 * The component represents both request
		 * or campaign creation. So the resource
		 * should be provided. `request` by default
		 *
		 * @type {Object}
		 */
		resource: {
			type: String,
			default: 'request',
			valaidator: resource => [
				'request',
				'campaign',
			].includes(resource),
		},
	},


	data() {
		return {
			step: 0,
			instance_id: this.id,
			valueStep01: {},
			valueStep03: {},
			valueStep04: {},

			errors: {},
			showErrorsPopup: false,

			debouncedCreateOrUpdateRequest: _.debounce(
				this.createOrUpdateRequest,
				DEBOUNCE_TIMEOUT,
			),
		};
	},


	/**
	 * `created` componenet life-time hook
	 * Gets the instance if id provided
	 */
	async created() {
		if (!this.id) return null;

		const action = `${this.resource}/getItem`;
		const instance = await this.$store.dispatch(action, { id: this.id, force: true });

		Vue.set(this, 'instance', instance);
	},


	computed: {
		...mapState('app', [
			'agency',
			'enabledPaymentModels',
		]),

		...mapState('agency', {
			agencyCurrency: 'currency',
		}),


		instance: {
			get() {
				let start_date = null;
				let end_date = null;

				if (this.valueStep03.time_period) {
					start_date = this.valueStep03.time_period.start;
					end_date = this.valueStep03.time_period.end;
				}

				// convert to float
				// and limit the number of decimal places
				let max_bid = 0;
				if (this.valueStep03.max_bid) {
					max_bid = parseFloat(parseFloat(this.valueStep03.max_bid).toFixed(2));
				}

				let ad_rate_per_hour = null;
				if (this.valueStep03.ad_rate_per_hour) {
					ad_rate_per_hour = this.valueStep03.ad_rate_per_hour * this.valueStep03.ad_rate_multiplier;
				}

				// TODO: we need a better default values system
				let payment_model = this.enabledPaymentModels[0];
				if (this.valueStep03.payment_model) {
					payment_model = this.valueStep03.payment_model;
				}
				// set `payment_model` if `enabledPaymentModels.length === 1`
				if (this.enabledPaymentModels.length === 1) {
					payment_model = this.enabledPaymentModels[0];
				}

				return {
					...this.valueStep01,
					...this.valueStep03,
					payment_model,
					end_date,
					max_bid,
					start_date,
					ad_rate_per_hour,

					...this.valueStep04,
				};
			},

			set(instance) {
				this.valueStep = {...instance};

				this.valueStep01 = {
					..._.pick(instance, [
						'id',
						'name',
						'industry',
						'description',
						'brand',
						'brand_instance',
						'advertiser',
						'advertiser_instance',
					]),
				};

				// this.valueStep02 = {
				// 	..._.pick(instance, [
				// 		'payment_model'
				// 	]),
				// };

				this.valueStep03 = {
					..._.pick(instance, [
						'areas',
						'platforms',
						'age',
						'gender',
						'interests',
						'life_stages',
						'intents',
						'environments',
						'pois',
						'resolutions',
						'orientations',
						'device_types',
						//'time_period', // see below
						'start_date',
						'end_date',
						'time_range',
						'schedule',
						'ad_duration',
						'ad_rate_multiplier',
						'ad_rate_per_hour',
						'payment_model',
						'budget',
						'is_fixed_placement',
						'is_budget_manually_set',
						'is_max_bid_manually_set',
						'max_bid',
						'owner_instance'
					]),
				};

				// set `payment_model` if `enabledPaymentModels.length === 1`
				if (this.enabledPaymentModels.length === 1) {
					this.valueStep03.payment_model = this.enabledPaymentModels[0];
				}

				this.valueStep03.time_period = {
					start: instance.start_date,
					end: instance.end_date,
				};

				this.valueStep03.ad_rate_per_hour = this.valueStep03.ad_rate_per_hour / this.valueStep03.ad_rate_multiplier;

				// fix `budget` & `max_bid` - remove 0 and null values
				// and prevent overwrite
				this.valueStep03.budget = parseFloat(this.valueStep03.budget);
				if (!this.valueStep03.budget) {
					delete this.valueStep03.budget;
				}
				this.valueStep03.max_bid = parseFloat(this.valueStep03.max_bid);
				if (!this.valueStep03.max_bid) {
					delete this.valueStep03.max_bid;
				}

				// set default currency
				this.valueStep03.currency = instance.currency || this.agencyCurrency;

				this.valueStep04 = {
					..._.pick(instance, [
						'ads'
					]),
				};
			},
		},
	},  // end: computed


	methods: {
		...mapActions('request', {
			requestCreate: 'create',
			requestChange: 'change',
		}),

		/**
		 * TODO: do we need this method?
		 */
		onStepChange(currentStep, oldStep, wizard) {
			this.step = currentStep;
		},

		/**
		 * Close the modal
		 * === go to parent route
		 */
		modalClose() {
			// при возвращении на предыдущую страницу нужно бы вернуться на исходный запрос
			this.$log.warn('need return old query');
			this.$router.push({
				name: this.$route.meta.parentRouteName,
				// params: this.$route.params,
				// query: this.$route.query,
			});
		},

		backButton() {
			if (this.step > 0) {
				this.$refs.wizard.stepPrev();
			} else {
				this.modalClose();
			}
		},

		/**
		 * May return promise or value
		 */
		async nextStep(currentStep, prevStep, wizard) {
			const instance = await this.createOrUpdateRequest();

			let isError = Object.keys(this.errors).length > 0;

			if (isError) {
				this.showErrorsPopup = true;

				throw Error('error updating request');
			}

			// redirect to edit page after step 1 on a request-add page
			if (
				prevStep == 0
				&& this.$route.name == 'request-add'
				&& !isError
			) {
				await this.$router.replace({
					name: 'request-details',
					params: {
						id: instance.id,
					},
					hash: `#step${currentStep}`,
				});
			}

			return instance;
		},

		/**
		 * Returns Promise
		 */
		async createOrUpdateRequest(options /*{ doPlaceOrder:false, finish:false }*/) {
			// clear errors
			this.errors = {};

			let data = {
				...this.instance,
			};

			// Set default param `ad_rate_per_hour` on BE side
			if (!data.ad_rate_per_hour) {
				delete data['ad_rate_per_hour'];
			}

			// fix `A valid number is required`
			const budget = Number.parseInt(data.budget);
			if (Number.isNaN(budget)) {
				delete data['budget'];
			}

			// fix `all` option for device_types, orientations, resolutions
			_.each(['device_types', 'orientations', 'resolutions'], function(opt) {
				if (data[opt] && _.contains(data[opt], 'all')) {
					delete data[opt];
				}
			});

			// TODO: rewrite with `changeAudit()`
			if (options && options.doPlaceOrder) {
				data.status = STATUS_AWAITING_APPROVAL;
			}

			const resource = this.resource; // campaign | request
			const method = this.instance_id ? 'change' : 'create'; // create if no ID
			const action = `${ resource }/${ method }`;

			let args = {
				id: this.instance_id,
				data,
			};

			try {
				Vue.set(this, 'instance', await this.$store.dispatch(action, args));
				this.instance_id = this.instance.id;

				if (options && options.finish) {
					this.modalClose();
				}
			}
			catch (err) {
				if (err && err.response && err.response.data) {
					let errors = err.response.data;
					for (let key in errors) {
						if (Array.isArray(errors[key])) {
							errors[key] = errors[key].join('. ');
						}
					}

					this.errors = errors;
				}
			}

			return this.instance;
		},

		async createRequest(options) {
			let instance = await this.createOrUpdateRequest(options);

			let isError = Object.keys(this.errors).length > 0;
			if (isError) {
				this.showErrorsPopup = true;
			}

			return instance;
		},

		/**
		 * Fired in step02
		 *
		 * Changes
		 *  * payment_model
		 */
		handlePaymentModelChange(paymentModel) {
			this.$set(this.valueStep03, 'payment_model', paymentModel);
		},

	},  // END: methods

};
</script>

<style lang="sass" scoped >
.header_panel-control
	$padding: 25px

	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between

	margin: 0
	padding: $padding - $ni_button-padding-y $padding - $ni_button-padding-x

	.nice-button
		padding: 0 $ni_button-padding-x

.ni_wizard_2--content
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: center
	align-items: stretch
	align-content: center

.svg-icons
	display: none
</style>
