var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page page-dev"},[_c('h1',[_vm._v("Table")]),_vm._m(0),_c('table',{staticClass:"nice-table"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',[_vm._v("TableCellStatus")]),_c('table-cell-status',{attrs:{"resource":"test","item":{
						id: 1,
						status: 'pause',
					},"list":[
						{
							value: 'pause',
							label: 'Paused',
							icon: 'pause',
						},
						{
							value: 'approved',
							icon: 'play',
							label: 'Approved',
						},
						{
							value: 'archived',
							icon: 'arrow_circle',
							label: 'Archived',
							labelAction: 'Archive',
						} ],"can-change":""}})],1),_c('tr',[_c('td',[_vm._v("TableCellName")]),_c('table-cell-name',{attrs:{"id":1,"resource":"test","caption":"Test name","show-link":""}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Дефолтный стиль таблицы задётся классом "),_c('b',[_c('i',[_vm._v("nice-table")])]),_vm._v(".")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Component")]),_c('th',[_vm._v("Preview")])])])}]

export { render, staticRenderFns }