<template>
	<section>
		<h1>АХТУНГ! Изменения в табах не применяются без перезагрузки dev сервера!!!</h1>
		<p>Второй набор UI компонентов, теперь с использованием css переменных для задания дефолтного цвета, закладываем механизм изменения цветовой палитры<br /> Из-за этого серьъёзно меняются иконки, а они являются зависимостями очень многих других элементов</p>
		<nice-tabs>
			<tab-action-list data-ni_tab_name="Action List" />
			<tab-buttons data-ni_tab_name="Buttons" />
			<tab-datetime data-ni_tab_name="DateTime" />
			<tab-hint data-ni_tab_name="Hint" />
			<tab-input data-ni_tab_name="Input" />
			<tab-label data-ni_tab_name="Label" />
			<tab-menu-context data-ni_tab_name="Menu Context" />
			<tab-progress-bar data-ni_tab_name="Progress Bar" />
			<tab-select data-ni_tab_name="Select" />
			<tab-slider data-ni_tab_name="Slider" />
			<tab-spoiler data-ni_tab_name="Spoiler" />
			<tab-tabs data-ni_tab_name="Tabs" />
			<tab-wizard-2 data-ni_tab_name="Wizard" />
		</nice-tabs>
	</section>
</template>

<script>
import NiceTabs from '@/ui/nice-tabs.vue';

import TabActionList from './tab-action-list';
import TabButtons from './tab-buttons';
import TabDatetime from './tab-datetime.vue';
import TabHint from './tab-hint';
import TabInput from './tab-input';
import TabLabel from './tab-label';
import TabMenuContext from './tab-menu-context';
import TabProgressBar from './tab-progress-bar';
import TabSelect from './tab-select.vue';
import TabSlider from './tab-slider';
import TabSpoiler from './tab-spoiler';
import TabTabs from './tab-tabs.vue';
import TabWizard2 from './tab-wizard-2';


export default {
	name: 'PageDevUi',


	components: {
		NiceTabs,

		TabActionList,
		TabButtons,
		TabDatetime,
		TabHint,
		TabInput,
		TabLabel,
		TabMenuContext,
		TabProgressBar,
		TabSelect,
		TabSlider,
		TabSpoiler,
		TabTabs,
		TabWizard2,
	},
};
</script>
