<template>
	<symbol :id="`${prefix}arrow_corner-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M11.9497 15.3638L8 11.4141L9.41406 10L11.9497 12.5356L14.4849 10L15.8989 11.4141L11.9497 15.3638Z" class="sign arrow" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrowCorner24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: center
</style>
