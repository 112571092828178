<template>
	<div
		ref="niceSelect"
		class="nice-select"
		:class="{
			'has-value': valuesList.length,
			'invalid': error.is,
			'label_holder': labeled && !(label || hint),
			'labeled': labeled || label || hint,
		}"
	>
		<nice-label
			v-if="label"
			:for="id"
			:label="label"
			:hint="hint"
			:error="error.message || error.is"
			class="ni_select--label"

			v-on="labelListeners"
		/>

		<span
			class="ni_select--select-row"
		>
			<span
				v-if="searchIsEnabled"
				class="ni_select--input-wrapper"
				:class="{
					'show-dd': isDropDownShowing,
				}"
			>
				<input
					:id="id"
					v-model="filterQuery"
					class="ni_select--input"
					type="text"
					:placeholder="filterPlaceholder || $t('find')"
					:disabled="disabled"

					v-on="inputListeners"
				/>
				<NiceIcon2
					v-if="icon && !isDropDownShowing"
				  :icon="icon"
				  class="ni_select--icon"
				/>
				<label
					:for="id"
					class="ni_select--caption"
					:class="{
						'with-icon': icon
					}"
					v-html="caption || placeholder || $t('select')"
				/>
			</span>
			<template
				v-else
			>
				<NiceIcon2
					v-if="icon"
					:icon="icon"
					class="ni_select--icon"
				/>
				<label
					class="ni_select--caption"
					:class="{
						'with-icon': icon
					}"
					@click="toggleDropDown"
					v-html="caption || placeholder || $t('select')"
				/>
			</template>
			<nice-button-2
				icon="arrow_corner-12"
				:icon-state="isDropDownShowing && optionList.length ? 180 : 0"
				class="ni_select--button_dd"
				@click="toggleDropDown"
			/>
		</span>

		<ul
			v-if="isDropDownShowing && optionList.length"
			ref="optionList"
			v-click-outside="vcoConfig"
			class="ni_select--options"
		>
			<nice-option
				v-for="option in optionList"
				:key="option.value"

				:value="option.value"
				:caption="option.label"
				:icon="option.icon"
				:selected="valueIsSelected(option.value)"
				class="ni-sel-option"

				@select="optionToggleHandler(option)"
			/>
		</ul>
	</div>
</template>

<script>
/**
 * Add available blank
 */
import vClickOutside from 'v-click-outside';

import NiceOption from './nice-option';
import OptionsMixin from './mixins/options';
import ValueMixin from './mixins/value';


export default {
	name: 'NiceSelect',


	mixins: [OptionsMixin, ValueMixin],


	directives: {
		clickOutside: vClickOutside.directive,
	},


	components: {
		NiceOption,
	},


	props: {
		id: {
			type: String,
			default: () => `__${new Date().getTime().toString(16)}_nice-select`
		},

		// label
		labeled: Boolean,
		disabled: Boolean,


		label: {
			type: String
		},

		hint: {
			type: String
		},

		placeholder: {
			type: String,
		},
	},


	data() {
		return {
			isDropDownShowing: false,
			vcoConfig: {
				handler: this.vcoHandler,
				middleware: this.vcoMiddleware,
				events: ['click', 'touchstart'],
			},

			validatorResult: { is: false, message: null },
		};
	},


	computed: {
		/**
		 * Return listeners for label
		 */
		labelListeners() {
			let result = {};

			if (!this.searchIsEnabled) {
				result['click'] = this.showDropDown;
			}
			return result;
		},

		/**
		 * Return listeners for input
		 */
		inputListeners() {
			let result = {
				focus: this.showDropDown,
				blur: () => {
					if (!this.optionList.length) {
						this.hideDropDown();
					}
				},
			};

			if (typeof this.options == 'function') {
				result['input'] = this.requestOptions;
			}
			return result;
		},

		/**
		 * Current selected option label object
		 */
		caption() {
			let result;

			if (this.valuesList.length === 1) {
				result = this.selectedOptions[this.valuesList[0]];
			}
			else if (this.valuesList.length > 1) {
				result = this.$options.filters.capitalize(this.$t('selected')) + '(' +this.valuesList.length + ')';
			}
			else {
				result = null;
			}

			return result;
		},

		/**
		 * If only one option is selected and it has icon
		 */
		icon() {
			if (this.valuesList.length !== 1) {
				return null;
			}

			const selectedOption = this.optionList.find(i => i.value === this.valuesList[0]);
			if (!selectedOption || !selectedOption.icon) {
				return null;
			}

			return selectedOption.icon;
		},
	},


	methods: {
		/**
		 * v-click-outside handler
		 */
		vcoHandler() {
			this.isDropDownShowing = false;
		},

		/**
		 * v-click-outside middleware
		 */
		vcoMiddleware(event) {
			const etcw = event.target.closest('.nice-select');
			return !etcw || etcw !== this.$refs.niceSelect;
		},

		/**
		 * Hide / show dropdown
		 */
		toggleDropDown() {
			this.isDropDownShowing = !this.isDropDownShowing;
		},

		/**
		 * Show dropdown
		 */
		showDropDown() {
			this.isDropDownShowing = true;
		},

		/**
		 * Show dropdown
		 */
		hideDropDown() {
			this.isDropDownShowing = false;
		},
	},


	async created() {
		await this.recalcSelectedOptions();
		if (this.options instanceof Function) {
			this.requestOptions();
		}
	},

};
</script>

<style lang="sass" scoped >
.nice-select
	--default-color: var(--text_2_color)
	--hover-color: var(--text_2_color)
	--active-color: var(--text_2_color)
	--disabled-color: var(--disabled_color)
	--text-color: var(--text_color)
	position: relative

	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch
	box-sizing: border-box

	border-bottom: 1px solid var(--default-color)

	&.label_holder
		padding-top: $ni-label-height

.invalid
	--default-color: #{$nice_color-red}

.ni_select--select-row
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center

.ni_select--input-wrapper
	position: relative
	flex-grow: 1
	height: $ni-input-height

	.ni_select--icon
		position: absolute
		top: 0
		left: 0

		width: 16px
		height: 100%

	.ni_select--caption
		position: absolute
		top: 0
		left: 0

		height: 100%
		width: 100%

		&.with-icon
			left: 16px
			width: calc(100% - 16px)

	&.show-dd
		.ni_select--input
			opacity: 1

		.ni_select--caption
			display: none

.ni_select--input
	height: 100%
	width: 100%
	padding: 0

	border: none
	color: var(--text-color)
	font-size: $fsz__new__normal
	line-height: $txt__line_height
	overflow: hidden

	// если скрывать через `display: none` то не выстваляется фокус по клику на лейбл `.ni_select--input-wrapper .ni_select--caption`
	opacity: 0

	&::placeholder
		color: var(--default-color)

	&:disabled
		background-color: transparent

.ni_select--caption
	flex-grow: 1
	height: $ni-input-height
	padding: 0

	color: var(--default-color)
	font-family: inherit
	font-size: $fsz__new__normal
	line-height: $ni-input-height
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap

	&.with-icon
		margin-left: 10px

	.has-value &
		color: var(--text-color)

.ni_select--button_dd
	flex-shrink: 0
	width: $ni_select--button_dd-size
	min-height: $ni_select--button_dd-size
	padding-left: $ni_select--button_dd-indent
	padding-right: $ni_select--button_dd-indent

	--ni-icon-sign: #000
	--ni-icon-sign-stroke-width: 1

.ni_select--options
	position: absolute
	top: 100%  // calc(100% - 1px)
	z-index: 1

	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: flex-start

	box-sizing: border-box
	margin: 0
	min-width: 100%
	max-width: 250px
	max-height: $ni_select--option-line_height * 5em
	padding: $ni_select--options-padding_y 0

	font-size: $ni_select--option-font_size
	list-style: none
	overflow: auto
	background-color: #ffffff
	border: 1px solid var(--default-color)

</style>
