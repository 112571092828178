<template>
	<symbol :id="`${prefix}dashboard`" viewBox="0 0 34 34" >
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9 10C9 8.89543 9.89543 8 11 8H15C16.1046 8 17 8.89543 17 10V14C17 15.1046 16.1046 16 15 16H11C9.89543 16 9 15.1046 9 14V10ZM11 10H15V14H11V10Z" class="square"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9 19C9 17.8954 9.89543 17 11 17H15C16.1046 17 17 17.8954 17 19V23C17 24.1046 16.1046 25 15 25H11C9.89543 25 9 24.1046 9 23V19ZM11 19H15V23H11V19Z" class="square"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M20 8C18.8954 8 18 8.89543 18 10V14C18 15.1046 18.8954 16 20 16H24C25.1046 16 26 15.1046 26 14V10C26 8.89543 25.1046 8 24 8H20ZM24 10H20V14H24V10Z" class="square"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M18 19C18 17.8954 18.8954 17 20 17H24C25.1046 17 26 17.8954 26 19V23C26 24.1046 25.1046 25 24 25H20C18.8954 25 18 24.1046 18 23V19ZM20 19H24V23H20V19Z" class="square"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolDashboard',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.square
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})
</style>
