import store from '@/store';
import { debounce } from 'underscore';


const DEBOUNCE_DELAY = 300;
const resizeHandler = debounce((function(store) {
	// Old measures
	let width = null;
	let height = null;

	return function resizeHandler(event) {
		// Trigger mutations only if measures changed
		if (width !== window.innerWidth) {
			width = window.innerWidth;
			store.commit('browser/setWidth', width);
		}

		if (height !== window.innerHeight) {
			height = window.innerHeight;
			store.commit('browser/setHeight', height);
		}
	};
})(store), DEBOUNCE_DELAY);

/*
 * The directive updates `browser/height` and `browser/width`
 * state from store using mutations.
 */
export default {
	bind(el, binding, vnode) {
		resizeHandler();
	},

	inserted(el, binding, vnode) {
		window.addEventListener('resize', resizeHandler);
	},

	unbind(el, binding, vnode) {
		window.removeEventListener('resize', resizeHandler);
	},
};
