<template>
	<span
		:is="generatedComponent"
		class="ni-pgtr-btn"
		:class="{
			'current': isCurrent,
		}"

		:disabled="disabled"

		:to="routeNext"
		@click="goTo(index)"

	>
		<nice-icon
			v-if="icon"
			v-bind="icon"
			:disabled="disabled"
		/>
		<span v-else class="ni-pgtr-index" >{{ index }}</span>
	</span>
</template>

<script>
export default {
	name: 'NicePaginatorItem',


	// props
	props: {
		index: {
			type: Number,
			required: true,
		},

		/**
		 * Make router-link or button with emit event
		 */
		isLink: {
			type: Boolean,
			default: true,
		},

		/**
		 * Icon slug
		 */
		icon: {
			type: Object,
			default: null,
		},

		isCurrent: {
			type: Boolean,
			default: false,
		},

		queryKey: {
			type: String,
			default: null,
		},

		/**
		 * 'disabled' flag
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
	},


	computed: {
		/**
		 * Return name of generated component
		 */
		generatedComponent() {
			let result;

			if (this.disabled || this.isCurrent) {
				result = 'span';
			} else if (this.isLink) {
				result = 'router-link';
			} else {
				result = 'button';
			}

			return result;
		},

		/**
		 * Return route object
		 */
		routeNext() {
			if (this.generatedComponent !== 'router-link') return;

			return {
				query: {
					...this.$route.query,
					[this.queryKey]: this.index,
				},
			};
		},
	},


	// methods
	methods: {
		goTo(index) {
			if (!this.disabled && !this.isCurrent) {
				this.$emit('go-to', index);
			}
		},
	},
};
</script>

<style lang="sass" scoped >
$height: 29px
$font-size: $fsz__large

.ni-pgtr-btn
	+button__clear

	display: flex
	align-items: center
	justify-content: center

	margin: 0 1em 0 0
	min-width: $height
	height: $height

	border-radius: $height / 2
	text-decoration: none

	.ni-pgtr-index
		display: inline-block
		box-sizing: border-box
		min-width: $height

		color: $nice_color-black
		font-size: $font-size
		line-height: $height
		text-align: center
		text-decoration: none

	.nice-icon
		height: 12px

	.nice-icon__prev
		margin-right: 0.1em

	.nice-icon__next
		margin-left: 0.1em

	&:last-child
		margin-right: 0

	&:hover,
	&:active
		background-color: var(--secondary_disabled_color)

	&.current
		background-color: var(--secondary_color)

		.ni-pgtr-index
			color: white

	&[disabled],
	&:disabled
		cursor: default
		background-color: transparent

		&:hover,
		&:active,
		&.current
			background-color: transparent

</style>
