<template>
	<symbol :id="`${prefix}logo`" viewBox="0 0 34 34">
		<circle cx="17" cy="17" r="17" fill="#0023FF"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9442 19.7343C18.5838 20.5917 18.0855 21.327 17.4464 21.9444C16.7862 22.5619 16.0078 23.06 15.1097 23.3982C14.1905 23.7561 13.1925 23.9357 12.0931 23.9357H7.59985V9.99976H12.0931C13.1925 9.99976 14.1905 10.178 15.1097 10.5176C16.0078 10.8754 16.7862 11.3736 17.4464 11.9896C18.0855 12.607 18.5838 13.3438 18.9442 14.1998C19.3031 15.0558 19.4833 15.9721 19.4833 16.967C19.4833 17.9633 19.3031 18.8783 18.9442 19.7343ZM17.0058 14.9168C16.7468 14.2994 16.4076 13.7619 15.9487 13.3045C15.4884 12.8666 14.9295 12.5285 14.2707 12.2688C13.612 12.0106 12.8926 11.8914 12.0931 11.8914H9.69726V22.0441H12.0931C12.8926 22.0441 13.612 21.9248 14.2707 21.6666C14.9295 21.4266 15.4884 21.0884 15.9487 20.631C16.3879 20.1735 16.7468 19.6346 17.0058 19.0186C17.2662 18.4012 17.3859 17.7037 17.3859 16.967C17.3859 16.2303 17.2662 15.5343 17.0058 14.9168Z" fill="white"/>
		<path d="M19.9156 22.183C20.1352 21.9641 20.4351 21.8448 20.7743 21.8448C21.1136 21.8448 21.4134 21.9641 21.633 22.183C21.8526 22.4033 21.9722 22.7008 21.9722 23.0404C21.9722 23.3786 21.8526 23.6775 21.633 23.8964C21.4134 24.1153 21.1136 24.2346 20.7743 24.2346C20.4351 24.2346 20.1352 24.1153 19.9156 23.8964C19.696 23.6775 19.575 23.3786 19.575 23.0404C19.575 22.7008 19.696 22.4033 19.9156 22.183Z" fill="white"/>
		<path d="M24.1088 23.9678V13.3998C23.9692 13.5194 23.7698 13.6191 23.5505 13.6989C23.371 13.7786 23.1118 13.8385 22.8127 13.8983C22.5136 13.9581 22.1348 13.998 21.716 13.998V12.4028C22.055 12.4028 22.394 12.323 22.713 12.1835C23.0121 12.0439 23.2913 11.8644 23.5306 11.6451C23.7698 11.4258 23.9692 11.1865 24.1088 10.8874C24.2484 10.6082 24.3082 10.3091 24.3082 10.01H26.2025V23.9678H24.1088Z" fill="white"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolLogo',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>
