var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
		'stat-graph',
		_vm.type ]},[_c('div',{staticClass:"label"},[_c('span',{staticClass:"label-text"},[_vm._v(_vm._s(_vm.units))])]),_c('div',{staticClass:"legend-y"},_vm._l((_vm.invertedRows),function(row,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(row)+" ")])}),0),_c('div',{ref:"container",staticClass:"graph"},[_c('svg',{attrs:{"width":"100%","height":_vm.height,"viewBox":("0 0 " + _vm.width + " " + _vm.height)}},[_c('path',{attrs:{"d":_vm.shapeString,"fill":_vm.fillColor}}),_c('path',{attrs:{"d":_vm.colsCurveString,"stroke":_vm.graphColor,"stroke-width":"2","fill":"none"}}),_vm._l((_vm.linesCount),function(point,index){return _c('line',{key:index,staticClass:"vert",class:{
					selected: index == _vm.over && _vm.type == 'lines',
				},attrs:{"x1":_vm.margin + _vm.stepX * index,"y1":_vm.margin,"x2":_vm.margin + _vm.stepX * index,"y2":_vm.height - _vm.margin,"stroke":_vm.lineColor,"stroke-width":"1","stroke-dasharray":"1,3","vector-effect":"non-scaling-stroke","stroke-linecap":"round"}})}),(_vm.overBool)?_c('circle',{attrs:{"cx":_vm.coords[_vm.over].x,"cy":_vm.coords[_vm.over].y,"stroke":_vm.graphColor,"r":"5","fill":"white","stroke-width":"2","vector-effect":"non-scaling-stroke","stroke-linecap":"round"}}):_vm._e(),_vm._l((_vm.coords),function(point,index){return _c('rect',{key:index + 'rect',staticClass:"hit-zone",attrs:{"width":_vm.hitZoneWidth,"height":_vm.height,"x":point.x - _vm.hitZoneWidth / 2},on:{"mouseenter":function($event){return _vm.showInfo(index)},"mouseleave":function($event){return _vm.hideInfo()}}})}),_vm._l((_vm.maxBidHistoryData),function(maxBid,index){return [(maxBid)?_c('line',{key:index + 'maxbid',staticClass:"vert",attrs:{"x1":_vm.margin + _vm.stepX * index + _vm.hitZoneWidth / 2,"y1":_vm.margin,"x2":_vm.margin + _vm.stepX * index + _vm.hitZoneWidth / 2,"y2":_vm.height - _vm.margin,"stroke":_vm.maxBidLineColor,"stroke-width":"2","stroke-dasharray":"1,3","vector-effect":"non-scaling-stroke","stroke-linecap":"round"}}):_vm._e()]})],2),(_vm.overBool)?_c('div',{ref:"tooltip",staticClass:"tooltip",style:(_vm.tooltipCoords)},[_vm._t("default",function(){return [_c('h5',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.dataset.columns[_vm.over] || _vm.$t('agency.title'))+" ")]),(_vm.formatHint)?_c('p',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.formatHint({
						'dataset': _vm.dataset,
						'maxBidHistoryData': _vm.maxBidHistoryData,
						'over': _vm.over,
					}))}}):_c('p',{staticClass:"content"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.units || _vm.$t('campaign.label')))]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.dataset.value[_vm.over]))])])]},{"slotProps":{ 'over': _vm.over, 'maxBidHistoryData': _vm.maxBidHistoryData }})],2):_vm._e()]),_c('div',{staticClass:"bottomLegend",style:({
			width: _vm.type === 'lines' ? ((_vm.marginedSize.w + _vm.stepX) + "px") : ((_vm.marginedSize.w) + "px"),
			'margin-left': _vm.type === 'lines' ? ((-_vm.stepX / 2 + _vm.margin) + "px") : (_vm.margin + "px"),
		})},[_vm._l((_vm.dataset.columns),function(col,index){return _c('div',{key:index,staticClass:"legend-element",style:({
					width:(_vm.stepX + "px"),
				})},[_vm._v(" "+_vm._s(index % _vm.skipBottomLegendLabels ? "" : col)+" ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }