<template>
	<symbol :id="`${prefix}arrow-14`" viewBox="0 0 14 14">
		<path
			d="M9,2.4L7.6,3.8L9.8,6H0.3v2h9.5l-2.2,2.2L9,11.6L13.7,7L9,2.4z"
			class="sign arrow"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrow14',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark_semi})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg + 90deg))
	transform-origin: center
</style>
