var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"nice-value",class:{
		'labeled': _vm.labeled || _vm.label,
	}},[(_vm.label)?_c('span',{staticClass:"ni-label "},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}),(_vm.hint)?_c('nice-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
				placement: 'top',
				distance: 10
			}),expression:"{\n\t\t\t\tplacement: 'top',\n\t\t\t\tdistance: 10\n\t\t\t}"}],staticClass:"label-icon",attrs:{"icon":"hint","content":_vm.hint}}):_vm._e(),_vm._t("label")],2):_vm._e(),(_vm.value)?_c('span',_vm._b({directives:[{name:"tippy",rawName:"v-tippy",value:({
			placement: 'bottom',
			distance: 3
		}),expression:"{\n\t\t\tplacement: 'bottom',\n\t\t\tdistance: 3\n\t\t}"}],staticClass:"ni-value",class:{
			'ni-placeholder': !_vm.value,
		},attrs:{"content":_vm.extendedValue},domProps:{"innerHTML":_vm._s(_vm.value || _vm.placeholder)}},'span',_vm.$attrs,false)):_vm._e(),(_vm.units && (_vm.value || _vm.placeholder))?_c('span',{staticClass:"ni-input-units",domProps:{"innerHTML":_vm._s(_vm.units)}}):_vm._e(),(_vm.$slots.default)?_vm._t("default"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }