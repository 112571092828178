import baseRest from '../base/rest';
import { getPrefix as _getPrefix } from './index';
import baseDevice from '../device';


/**
 * Device endpoint prefix
 */
const getPrefix = () => `${_getPrefix()}device/`;


export default {
	...baseRest(getPrefix),  // list, create, patch, delete
	getPrefix,

	// getEnvironments, getInterestsAndActivities, getLifeStages, getIntents,
	// orientations, availableResolutions, paymentModels, broadcastTypes,
	...baseDevice(getPrefix),
};
