<template>
	<section class="page-section">
		<div class="cl-container">
			<h2 class="cl-pad cl-row" >Checkboxes</h2>

			<nice-input
				id=""
				class="cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad"
				type="text"
				placeholder="labeled input"
				label="labeled input"
				value=""
				disabled
			/>
			<nice-checkbox
				id="test-checkbox"
				class="cl-xs10 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad"
				caption="Checkbox"
				label="Labeled checkbox"
				disabled
			/>

			<div class="cl-xs10 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad" >
				<nice-checkbox
					id="test-checkbox"
					v-model="chbxData"
					:caption="`1st checkbox (${chbxData ? 'true' : 'false'})`"
				/>
				<nice-checkbox
					id="test-checkbox"
					v-model="chbxData"
					:caption="`2nd checkbox (${chbxData ? 'true' : 'false'})`"
				/>
			</div>
		</div>

		<section class="preview" >
			<h2>Использование</h2>
			<p><nice-checkbox caption="default checkbox" /> <code class="code-preview" >&lt;nice-checkbox caption="default checkbox" /&gt;</code></p>
			<p><nice-checkbox caption="disabled checkbox" disabled /> <code class="code-preview" >&lt;nice-checkbox caption="disabled checkbox" disabled /&gt;</code></p>
			<br/>
			<p><nice-checkbox caption="radio checkbox mark" icon="chbx-rnd" /> <code class="code-preview" >&lt;nice-checkbox caption="radio checkbox" icon="chbx-rnd" /&gt;</code></p>
			<br/>
			<p><nice-checkbox caption="radio checkbox circle" icon="chbx-circle" /> <code class="code-preview" >&lt;nice-checkbox caption="radio checkbox" icon="chbx-circle" /&gt;</code></p>
			<p><nice-checkbox caption="disabled radio checkbox circle" icon="chbx-circle" disabled /> <code class="code-preview" >&lt;nice-checkbox caption="radio checkbox" icon="chbx-circle" disabled /&gt;</code></p>
			<p><nice-checkbox caption="disabled checked radio checkbox circle" icon="chbx-circle" value disabled /> <code class="code-preview" >&lt;nice-checkbox caption="radio checkbox" icon="chbx-circle" value disabled /&gt;</code></p>
		</section>
	</section>
</template>

<script>
import NiceCheckbox from '@/ui/nice-checkbox.vue';
import NiceInput from '@/ui/nice-input.vue';

export default {
	name: 'TabCheckbox',
	components: {
		NiceCheckbox,
		NiceInput,
	},

	data() {
		return {
			chbxData: false,
		};
	},
};
</script>
