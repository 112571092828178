import { i18n } from '@/i18n.js';


/**
 * Get string message instead thowing error
 * Example: `message = getMessage(phoneValidator, this.user.phone);`
 * @param {*} fun function validator
 * @param {*} value should be validated
 * @returns message or nothing
 */
export function getMessage(fun, value) {
	try {
		fun(value);
	} catch (message) {
		return message;
	}
}

export function nameAndLastNameValidator(value) {
	if (!/^[A-Za-zА-Яа-яЁё-]+$/.test(value)) {
		throw i18n.t('users.verify_first_and_last_name');
	}
}

export function phoneValidator(value) {
	if (!/^[0-9-()\s+]*$/.test(value)) {
		throw i18n.t('users.verify_phone');
	}
}

export function phoneValidatorNumbersAndPlus(value) {
	if (!/^[0-9+]*$/.test(value)) {
		throw i18n.t('users.verify_phone_only_number_and_plus');
	}
}

export function numbersValidator(value) {
	if (!/^[0-9]*$/.test(value)) {
		throw i18n.t('users.only_numbers');
	}
}

export function innValidator(value) {
	if (!/^\d{10}$|^\d{12}$/.test(value)) {
		throw i18n.t('users.verify_inn');
	}
}

export function emailValidator(value) {
	if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
		throw i18n.t('errors.invalid_email');
	}
}
