<template>
	<div class="select_areas" >

		<div class="select_areas select_areas-row" >
			<!-- <nice-input
				v-model="actualSearchQuery"
				:label="$t('campaign.address')"
				:placeholder="$t('enter_address')"
				class="select_areas-address_search"
				:autocomplete-request="addressAutocompleteRequest"
				:debounce-autocomplete="DEBOUNCE_TIMEOUT"
			/> -->

			<!-- Может быть понадобиться сделать селект? -->
			<nice-select
				id="campaign-address"
				v-model="address"
				:label="$t('campaign.address')"
				:placeholder="$t('enter_address')"
				:options="addressOptionsRequest"
				class="select_areas-address_search"
			/>
			<nice-button-2
				icon="plus-17"
				class="select_areas-add_button"
				@click="addArea"
			>{{ $t('add') }}</nice-button-2>
		</div>

		<selected-area
			v-for="(area, i) in areas"
			:key="i"

			:area="area"
			@input="a => updateArea(a, i)"
			@delete="a => deleteArea(i)"
		/>
	</div>
</template>

<script>
import _ from 'underscore';

// import NiceInput from '@/ui/nice-input';
import NiceSelect from '@/ui/nice-select';
import { getGeofeatureName } from '@/utilites';

import SelectedArea from './selected-area';
import {
	ACTION_INCLUDE,
	ADDRESS_SEPARATOR,
	DEFAULT_RADIUS,
	MAPGEOCODER_DEBOUNCE_TIMEOUT,
} from './defaults';


const MODEL = Object.freeze({
	prop: 'areas',
	event: 'select',
});


export default {
	name: 'SelectIncludeAndExcludeAreas',


	model: MODEL,


	components: {
		// NiceInput,
		NiceSelect,
		SelectedArea,
	},


	props: {
		[MODEL.prop]: {
			type: Array,
			default: () => ([]),
		},

		searchQuery: {
			type: String,
			default: ''
		},

		searchListRequest: {
			type: [Array, Promise],
			default: () => ([])
		},
	},


	data() {
		return {
			address: null,
			DEBOUNCE_TIMEOUT: MAPGEOCODER_DEBOUNCE_TIMEOUT,
			addressAutocompleteOptions: [],
		};
	},

	computed: {
		/**
		 * Areas getter and setter
		 * setter emit event for v-model
		 */
		actualAreas: {
			// getter
			get: function () {
				return this[MODEL.prop];
			},

			// setter
			set: function (newValue) {
				this.$emit(MODEL.event, newValue);
			}
		},

		/**
		 * Radius getter and setter
		 * emit event for v-model
		 */
		actualSearchQuery: {
			// getter
			get: function () {
				return this.searchQuery;
			},

			// setter
			set: function (newValue) {
				this.$emit('update:searchQuery', newValue);
			}
		},
	},


	methods: {
		/**
		 * Request address
		 */
		// async addressAutocompleteRequest(value) {
		// 	let result = await this.searchListRequest;
		// 	result = result.map((item) => {
		//		const place_name = getGeofeatureName(item);
		// 		return {
		// 			value: place_name,
		// 			label: place_name,
		// 			// item: item,
		// 		};
		// 	});

		// 	return result;
		// },

		/**
		 * Request address
		 *
		 *
		 */
		async addressOptionsRequest({ query, values }) {
			if (values) {
				let result = await this.searchListRequest;

				return _.reduce(result, (options, option) => {
					const place_name = option.address || getGeofeatureName(option);
					const value = [...option.center, place_name].join(ADDRESS_SEPARATOR);

					if (values.indexOf(value) != -1) {
						options.push({
							value: value,
							label: place_name,
						});
					}

					return options;
				}, []);

			}
			else if (query) {
				await this.$emit('update:searchQuery', query);
				let result = await this.searchListRequest;
				
				result = result.map((item) => {
					const place_name = item.address || getGeofeatureName(item);

					return {
						// center - [longitude, latitude]
						value: [...item.center, place_name].join(ADDRESS_SEPARATOR),
						label: place_name,
					};
				});

				return result;
			}

			return [];
		},

		/**
		 * Add area and emit updated data
		 */
		addArea() {
			if (this.address) {
				const newAreas = [...this[MODEL.prop]];

				const mapboxGeocodingAddress = this.address.split(ADDRESS_SEPARATOR);
				newAreas.unshift({
					sorting: newAreas.length,
					longitude: parseFloat(mapboxGeocodingAddress[0]),
					latitude: parseFloat(mapboxGeocodingAddress[1]),
					title: mapboxGeocodingAddress[2],
					radius: DEFAULT_RADIUS,
					action: ACTION_INCLUDE,
				});
				this.$emit(MODEL.event, newAreas);
			}
		},

		/**
		 * Update area and emit updated data
		 */
		updateArea(area, i) {
			const newAreas = [...this[MODEL.prop]];
			newAreas[i] = area;
			this.$emit(MODEL.event, newAreas);
		},

		/**
		 * Delete area and emit updated data
		 */
		deleteArea(i) {
			const newAreas = [...this[MODEL.prop]];
			newAreas.splice(i, 1);
			this.$emit(MODEL.event, newAreas);
		},
	},
};
</script>

<style lang="sass" scoped >
.select_areas
	+ .selected_area
		margin-top: 20px

.select_areas-row
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between
	align-items: flex-end

.select_areas-address_search
	flex-grow: 1
	flex-shrink: 0

.select_areas-add_button
	margin-top: $ni_label-height
	margin-left: 13px
	margin-right: -10px
	padding: 0 10px
	flex-grow: 0
	flex-shrink: 0

</style>
