import store from '@/store';
import { send, request } from './request';


export const getPrefix = () => `agency/${store.state.app.agency}/`;


/**
 * Request user agency
 *
 * @returns {object} Agency
 */
export async function requestAgency(agency_id, side) {
	return await request(`me/agency/${side}/${agency_id}/`);
}

/**
 * Request (DSP) agencies
 *
 * @returns {array} Agencies
 */
export async function requestAgencies() {
	return await request('me/agency/');
}


export async function requestAgencySettings() {
	return await request(`${getPrefix()}ssp/whitelabel/${store.state.app.agency}/`);
}

export async function patchAgencySettings(data, config={}) {
	return await request(`${getPrefix()}ssp/whitelabel/${store.state.app.agency}/`, data, 'patch', config);
}


/**
 * Request child (DSP) agencies
 * For SSP and Super only
 *
 * TODO: child is a deprecated term - use associated DSP agencies
 *
 * @returns {array} Agencies
 */
export async function requestChildAgencies() {
	return await request(`${getPrefix()}ssp/child-agency/`);
}


/**
 * Create child (DSP) agency
 * For SSP and Super only
 *
 * @param {object} data
 * @param {object} axios config
 *
 * @returns {object} Agency
 */
export async function createChildAgency(data, config) {
	return await request(`${getPrefix()}ssp/child-agency/`, data, 'post', config);
}

//(data, config) => {
//let result = await request(prefix(), data, 'post', config);

/**
 * Request child (DSP) agency
 * For SSP and Super only
 *
 * @param {number} child agency id
 *
 * @returns {object} Agency
 */
export async function requestChildAgency(id) {
	return await request(`${getPrefix()}ssp/child-agency/${id}/`);
}


/**
 * Update child (DSP) agency
 * For SSP and Super only
 *
 * @param {number} child agency id
 * @param {object} data
 * @param {object} axios config
 *
 * @returns {object} Agency
 */
export async function updateChildAgency(id, data, config) {
	// we can't update contract.file via this endpoint so...
	if (data.contract) {
		delete data.contract.file;
	}
	return await request(`${getPrefix()}ssp/child-agency/${id}/`, data, 'patch', config);
}


/**
 * Delete child (DSP) agency
 * For SSP and Super only
 *
 * @param {number} child agency id
 *
 * @returns "ok" | error
 */
export async function deleteChildAgency(id) {
	return await request(`${getPrefix()}ssp/child-agency/${id}/`, null, 'delete');
}

/**
 * Restore child (DSP) agency
 * For SSP and Super only
 *
 * @param {number} child agency id
 *
 * @returns "ok" | error
 */
export async function restoreChildAgency(id) {
	return await request(`${getPrefix()}ssp/child-agency/${id}/restore/`, null, 'post');
}


/**
 * Request agency member
 *
 * @param {number} user id
 *
 * @returns {object} User
 */
export async function requestMember(id) {
	return await request(`${getPrefix()}user/${id}/`);
}


/**
 * Update child (DSP) agency's contract
 * For SSP and Super only
 *
 * @param {number} child agency id
 * @param {object} data
 * @param {object} axios config
 *
 * @returns {object} Agency
 */
export async function updateChildAgencyContract(agency_id, contract_id, data, config) {
	config = {
		...config,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/contract/${contract_id}/`, data, 'post', config);
}

//
// Invitations
//
// TODO: OLD: see api/invitation.js
//

/**
 * Request child (DSP) agency's invitations
 * For SSP and Super only
 *
 * @returns {array} Agencies
 */
export async function requestChildAgencyInvitations(agency_id) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/invitation/`);
}


/**
 * Create child (DSP) agency invitation
 * For SSP and Super only
 *
 * data:
 *  email
 *
 * @returns {object} invitation
 */
export async function createChildAgencyInvitation(agency_id, data, config) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/invitation/`, data, 'post', config);
}


/**
 * Delete child (DSP) agency invitation
 * For SSP and Super only
 *
 * data:
 *  id
 *
 * @returns
 */
export async function deleteChildAgencyInvitation(agency_id, invitation_id, config) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/invitation/${invitation_id}/`, {}, 'delete', config);
}


/**
 * Add balance to a child (DSP) agency
 * For SSP and Super only
 *
 * @param {number} child agency id
 * @param {number} amount
 *
 * @returns "ok" | error
 */
export async function addChildAgencyBalance(id, amount) {
	const data = {
		amount: amount,
	};

	return await request(`${getPrefix()}ssp/child-agency/${id}/add-balance/`, data, 'patch');
}


//
// Users
//


/**
 * Request child (DSP) agency's users
 * For SSP and Super only
 *
 * @returns {array} Users
 */
export async function requestChildAgencyUsers(agency_id) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/user/`);
}


/**
 * Request child (DSP) agency's user
 * For SSP and Super only
 *
 * @returns {object} user
 */
export async function requestChildAgencyUser(agency_id, user_id) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/user/${user_id}/`);
}


/**
 * Update child (DSP) agency's user
 * For SSP and Super only
 *
 * @returns {object} User
 */
export async function updateChildAgencyUser(agency_id, user_id, data, config) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/user/${user_id}/`, data, 'patch', config);
}


/**
 * Delete child (DSP) agency's user
 * For SSP and Super only
 *
 * @returns {object}
 */
export async function deleteChildAgencyUser(agency_id, user_id, config) {
	return await request(`${getPrefix()}ssp/child-agency/${agency_id}/user/${user_id}/`, {}, 'delete', config);
}


export async function getTransactionsList(payload) {
	return await request(`${getPrefix()}transactions/`, payload);
}


export async function getRequestCampaignCount(payload) {
	return await request(`${getPrefix()}${store.state.app.side}/request-campaign/count/`, payload);
}


/**
 * Register Agency User
 *
 * WL registration
 *
 * @param {obj} data {
 *  first_name
 *  last_name
 *  email
 *  phone
 *  agency_name
 *  password
 *  tos_accepted
 * }

 * @returns {object} user
 */
export async function registerAgencyUser(data, config) {
	return await send('agency/register-agency-user/', data, 'post', config);
}


//
// Agency User/Invitation API
//


/**
 * @param {int} agencyId - Agency ID
 * @param {str} side - {ssp|dsp}
 * @param {str} role - {is_ssp_admin|is_ssp_approver|is_dsp}
 */
export async function requestAgencyInvitations(agencyId, side, role) {
	return await request(`agency/${agencyId}/${side}/invitation/`, {
		role: role,
	});
}

/**
 * Create Invitation
 *
 * @param {str} email
 * @param {str} role {dsp_admin|dsp_agency|ssp_admin|ssp_approver}
 *
 * @returns {object} Invitation
 */
export async function createAgencyInvitation(agencyId, side, email, role) {
	let data = {
		email,
		role,
	};

	return await request(`agency/${store.state.app.agency}/${store.state.app.side}/invitation/`, data, 'post');
}


/**
 * Get Agency users
 *
 * For SSP: ssp admins and ssp approvers
 * For DSP: dsp admins
 *
 * @param {int} agencyId - Agency ID
 * @param {str} side - {ssp|dsp}
 */
export async function requestAgencyUsers(agencyId, side) {
	return await request(`agency/${agencyId}/${side}/user/`);
}


/**
 * Get Agency user
 *
 * For SSP: ssp admins and ssp approvers
 * For DSP: dsp admins
 *
 * @param {int} agencyId - Agency ID
 * @param {int} userId - User ID
 * @param {str} side - {ssp|dsp}
 */
export async function requestAgencyUser(agencyId, userId, side) {
	return await request(`agency/${agencyId}/${side}/user/${userId}/`);
}


// deleteAgencyInvitation(agencyId, invitationId, side)
// deleteAgencyUser(agencyId, userId, side)
