<template>
	<section class="page-section">
		<h1>NiceRane - range componenet</h1>
		<p>Нужно добавить отображение выбранного диапазона. Возможно стоит добавить именованный слот, и переделать легенду на именованные слоты, а в легенду добавить простые элементы которые просто будут ровно по всей длине рейнджа позиционироваться <b><i>justify-content: space-between</i></b></p>
		<dl>
			<dt><i>initial</i></dt>
				<dd>Значение при инициализации не сохраняющееся в <i>value</i>, по умолчанию имеет крайние позиции (максимальное и минимальое).</dd>
		</dl>

		<div class="examples cl-container">
			<div
				v-for="(example, index) in examples"
				:key="index"
				class="example cl-xs12 cl-sm6 cl-md4 cl-xl2 cl-pad"
			>
				<h2>{{example.title}}</h2>
				<nice-range
					v-model="example.value"
					:axis="example.axis"
					:scale="example.scale"
				/>
				<p>Value: <code class="code-preview" >{{ example.value }}</code></p>
				<p>Axis: <code class="code-preview">{{example.axis}}</code></p>
				<p v-if="example.scale">Scale: <code class="code-preview">{{example.scale}}</code></p>

				<template v-if="example.vModelDemo">
					<br />
					<hr />
					<p><em>v-model</em> support demo</p>
					<nice-range
						v-model="example.value"
						:axis="example.axis"
						:scale="true"
					/>
				</template>

			</div>

			<div
				class="example cl-xs12 cl-sm6 cl-md4 cl-xl2 cl-pad"
			>
				<h2>Legend</h2>
				<nice-range
					v-model="examples[2].value"
					:axis="examples[2].axis"
				>

					<nice-range-foofel
						placement="start"
						label="Early year<br />Past winter"
						class="legend-modifier-class"
					/>

					<nice-range-foofel
						placement="end"
						label="Late year<br />Next winter"
						class="legend-modifier-class"
					/>
				</nice-range>
				<pre class="code-preview">
// import
import { NiceRangeLegendItem } from '@/ui/nice-range';

<hr />
// add component
...
	components: {
		NiceRangeLegendItem
		...
	},
...

<hr />
// template
&lt;nice-range
	v-model="..."
	axis="..."
&gt;
	&lt;nice-range-legend-item
		placement="start"
		label="Early year&lt;br /&gt;Past winter"
		class="legend-modifier-class"
	/&gt;

	&lt;nice-range-legend-item
		placement="end"
		label="Late year&lt;br /&gt;Next winter"
		class="legend-modifier-class"
	/&gt;
&lt;/nice-range&gt;
				</pre>
			</div>
			<div
				class="example cl-xs12 cl-sm6 cl-md4 cl-xl2 cl-pad"
			>
				<h2>Legend</h2>
				<nice-range
					v-model="linesTest.value"
					:axis="linesTest.axis"
				>
					<template #viz="props" >
						<nice-range-viz
							v-bind="props"
						/>
					</template>
					<nice-range-foofel
						v-for="(item,index) in linesTest.scale"
						:key="index"
						:placement="{0: 'start', [linesTest.scale.length -1]: 'end'}[index] || 'value'"
						:position="item.value"
						:label="item.label"
						class="legend-modifier-class"
					/>
				</nice-range>
			</div>
		</div>
	</section>
</template>

<script>
import NiceRange from '@/ui/nice-range';
import { NiceRangeLegendItem } from '@/ui/nice-range';
import NiceRangeViz from '@/ui/nice-range/nice-range-viz';


export default {
	name: 'TabTabs',

	components: {
		NiceRange,
		NiceRangeFoofel: NiceRangeLegendItem,
		NiceRangeViz,
	},

	data() {
		return {
			examples: [
				{
					title: 'Normal',
					value: [12, 75],
					axis: 100,
					vModelDemo: true,
				},
				{
					title: 'Custom',
					value: [-.5, .5],
					axis: { start: -2, end: 2, step: .5 },
					scale: true,
				},
				{
					title: 'List',
					value: ['mar', 'aug'],
					axis: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
					scale: [
						{ value: 'mar', label: 'March' },
						{ value: 'aug', label: 'August' },
						{ value: 'dec', label: 'December' },
					],
				},

			],
			linesTest:
			{
				title: 'Normal',
				value: ['male', 'female'],
				axis: ['male','70%male','shemale','70%female','female'],
				scale: [
					{ value: 'male', label:'male' },
					{ value: '70%male', label:'70%male' },
					{ value: 'shemale', label:'shemale' },
					{ value: '70%female', label:'70%female' },
					{ value: 'female', label:'female' },
				],
			},
		};
	},
};
</script>

<style lang="sass" scoped>
.legend-modifier-class
	text-transform: uppercase
	font-size: 10px

</style>
