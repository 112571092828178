<template>
	<div>
	<div class="cl-container" >
		<nice-button
			icon="chbx-sqr"
			:icon-state="selected"
			@click="update"/>
		<nice-button-2
			:icon="icon"
			:icon-state="dir"
			@click="expand">
				{{current.label}}
		</nice-button-2>
	</div>
	<div v-if="expanded" style="margin-left:30px" >
		<tree-element
			v-for="el in list"
			:id="el.id"
			:key="el.id"
			:tree="tree"
			@changed="pass"
		/>
	</div>
	</div>
</template>

<script>


export default {
	name: 'TreeElement',
	props: {
		label: {
			type: String,
		},
		tree:{
			type: Array,
		},
		id:{
			type: Number,
		}
	},
	methods: {
		expand() {
			this.expanded=!this.expanded;
		},
		update(){
			this.$emit('changed',this.id, !this.current.selected);
		},
		pass(id, selected){
			this.$emit('changed',id, selected);
		},
		branchHasTrue(branchId){

			let result=false;
			let children = this.tree.filter(el=>el.parent===branchId);
			if (children.length>0){
				children.forEach(el => {
					let res = el.selected;
					let inner = this.branchHasTrue(el.id);
					if (res || inner){
						result = true;
					}
				});
			}
			//console.log("branchHasTrue",branchId,result);
			return result;
		}
	},
	computed: {
		selected(){
			//console.log(this.id,'recalc selected icon');
			if (this.current.selected){
				return 'checked';
			}else{
				if (this.notEmpty){
					if (this.branchHasTrue(this.id)){
						return 'mixed';
					}else{
						return 'unchecked';
					}
				}else{
					return 'unchecked';
				}
			}
			//return this.current.selected ? 'checked' : 'unchecked';
		},
		current(){
			return this.tree.find(el=>el.id===this.id);
		},
		list() {
			return this.tree.filter(el=>el.parent===this.id);
		},
		dir(){
			return this.expanded ? 0 : 270;
		},
		notEmpty(){
			return this.list.length>0;
		},
		icon(){
			return this.notEmpty ? 'arrow-14' : '';
		}
	},
	data() {
		return {
			expanded: false
		};
	},

	components: {

	},

};
</script>

<style lang="sass" scoped >
.done
	text-decoration: line-through
	color:grey

</style>
