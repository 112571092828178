/**
 * Разнообразные дополнительные данные получаемые с бекенда, например списки типов оплаты или типов точек вещания, используемые в различных формах и не относящиеся к какой либо конкретной структуре данных (например те же методы оплаты используются используются в нескольких местах, и не относятся к какой то сущности определённой) или для уменьшения кода в соответствующем сторе, например из девайсов уберём получение спика возможных типов точек вещания
 */

import apiDspDevice from '@/api/dsp/device';
import apiSspDevice from '@/api/ssp/device';
import { setterGenerator, stateGenerator, complexDataSetter } from '@/utilites.js';

const FIELDS = [ 'paymentModels', 'broadcastTypes', 'orientations' ];
const LOADING_KEY = 'store/extra';

const getRequestDevice = (side) => side == 'ssp' ? apiSspDevice : apiDspDevice;

const UNKNOWN_LIST_ITEM = {
	value: '&mdash;',
	label: '&mdash;',
};

export const UNKNOWN_PAYMENT_MODEL = {
	...UNKNOWN_LIST_ITEM,
	unit_factor: '&mdash;',
	trading_units: '&mdash;',
};

export const UNKNOWN_BROADCAST_TYPES = UNKNOWN_LIST_ITEM;


export default {
	namespaced: true,

	state: {
		...stateGenerator(FIELDS),
		...complexDataSetter(FIELDS),
	},

	mutations: {
		...setterGenerator(FIELDS),
	},

	getters: {
		getPaymentModelByValue(state) {
			return value => {
				return state.paymentModels ? state.paymentModels.find(i => i.value == value) || UNKNOWN_PAYMENT_MODEL : UNKNOWN_PAYMENT_MODEL;
			};
		},

		getBroadcastTypeByValue(state) {
			return value => {
				return state.broadcastTypes ? state.broadcastTypes.find(i => i.value == value) || UNKNOWN_BROADCAST_TYPES : UNKNOWN_BROADCAST_TYPES;
			};
		},
	},

	actions: {
		/**
		 * Return payment model list
		 * request this if needed
		 *
		 * @param {boolean} force - forced request from api
		 *
		 * @returns {object[]} PaymentModel
		 * @returns {number} PaymentModel.value - value
		 * @returns {string} PaymentModel.label - label
		 * @returns {number} PaymentModel.unit_factor - unit factor
		 * @returns {string} PaymentModel.trading_units - trading units
		 */
		async getPaymentModels({ commit, state, rootState }, force) {
			const loadingKey = `${LOADING_KEY}/payment_models`;
			commit('app/pushLoading', loadingKey, { root: true });

			if (!state.paymentModels || force) {
				const paymentModels = await getRequestDevice(rootState.app.side).paymentModels();
				commit('setPaymentModels', paymentModels);
				commit('app/removeLoading', loadingKey, { root: true });
			}

			return state.paymentModels;
		},

		/**
		 * Return broadcast types list
		 * request this if needed
		 *
		 * @param {boolean} force - forced request from api
		 *
		 * @returns {object[]} BroadcastType
		 * @returns {number} BroadcastType.value - value
		 * @returns {string} BroadcastType.label - label
		 */
		async getBroadcastTypes({ commit, state, rootState }, params) {
			const loadingKey = `${LOADING_KEY}/broadcast_types`;
			commit('app/pushLoading', loadingKey, { root: true });

			if (!state.broadcastTypes || (params && params.force)) {
				const broadcastTypes = await getRequestDevice(rootState.app.side).broadcastTypes(params);
				commit('setBroadcastTypes', broadcastTypes);
				commit('app/removeLoading', loadingKey, { root: true });
			}

			return state.broadcastTypes;
		},

		/**
		 * Return orientations list
		 *
		 * @param {boolean} force - forced request from api
		 *
		 * @returns {object[]} BroadcastType
		 * @returns {number} BroadcastType.value - value
		 * @returns {string} BroadcastType.label - label
		 */
		async getOrientations({ commit, state, rootState }, force) {
			const loadingKey = `${LOADING_KEY}/orientations`;
			commit('app/pushLoading', loadingKey, { root: true });

			if (!state.orientations || force) {
				const orientations = await getRequestDevice(rootState.app.side).orientations();
				commit('setOrientations', orientations);
				commit('app/removeLoading', loadingKey, { root: true });
			}

			return state.orientations;
		},
	},
};
