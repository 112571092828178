<template>
	<div class="qrbox">
		<header class="qrbox__header">
			<h2 class="qrbox__title">{{ $t('two_factor_auth.link_app') | capitalize }}</h2>
			<nice-button-2
				palette="gray"
				class="qrbox__close-button"
				@click="close"
			>{{ $t('close') }}</nice-button-2>
		</header>


		<div class="qrbox__image-wrapper">
			<img
				alt="QR-code"
				:src="dataImage"
				class="qrbox__image"
			>
		</div>

		<p>{{ $t('two_factor_auth.scan_qr') | capitalize }}</p>

		<form
			class="qrbox__form"
			@submit.prevent="handleFormSubmit"
		>
			<nice-input
				v-model="code"
				:label="$t('two_factor_auth.verification_code')"
				type="text"
				:invalid="error && error.message"
				class="qrbox__input"
			/>

			<nice-button-2
				type="submit"
				filled
				class="qrbox__submit"
			>{{ $t('two_factor_auth.submit') | capitalize }}</nice-button-2>
		</form>
	</div>
</template>

<script>

import { getConfirm2fa, } from '@/api/profile';
import NiceInput from '@/ui/nice-input';
export default {
	name: 'QrBox',

	components: {
		NiceInput,
	},

	props: {
		qrSvg: String,
	},

	data() {
		return {
			error: null,
			code: '',
		};
	},

	computed: {
		dataImage() {
			if (!this.qrSvg) {
				return null;
			}

			return 'data:image/svg+xml;text,' + this.qrSvg;
		}
	},


	methods: {
		async handleFormSubmit() {
			const payload = {
				code: this.code
			};

			try {
				await getConfirm2fa(payload);
			} catch(error) {
				this.handleSubmitError(error.response);
				return null;
			}
			this.error = null;
			this.$emit('ok');
		},

		handleSubmitError(error) {
			if (error && error.status === 400) {
				this.error = Object.freeze({
					status: 400,
					message: error.data.error,
				});
			} else {
				this.error = Object.freeze({
					status: null,
					message: 'unexpected error',
				});
			}

		},

		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="sass" scoped>
.qrbox
	box-sizing: border-box
	max-width: 380px
	padding: 40px

.qrbox__header
	display: flex
	align-items: center


.qrbox__title
	margin: 0 auto 0 0

.qrbox__close-button
	flex-shrink: 0
	flex-grow: 0
	margin-left: 20px

.qrbox__image-wrapper
	position: relative
	max-width: 300px
	width: 100%
	margin: 20px auto
	background-color: #f6f6f6

	&::before
		content: ''
		display: block
		padding-top: 100%
		pointer-events: none

.qrbox__image
	display: block
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	object-fit: contain

.qrbox__form
	display: flex
	align-items: flex-end
.qrbox__input
	flex-grow: 1


.qrbox__submit
	flex-grow: 0
	flex-shrink: 0
	margin-left: 20px
</style>
