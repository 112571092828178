<template>
	<div class="deactivate">
		<header class="deactivate__header">
			<h2 class="deactivate__title">{{ $t('two_factor_auth.deactivate_tfa') | capitalize }}</h2>
			<nice-button-2
				palette="gray"
				class="deactivate__close-button"
				@click="close"
			>{{ $t('close') }}</nice-button-2>
		</header>

		<p>{{ $t('two_factor_auth.confirm_deactivate') | capitalize }}</p>

		<form
			class="deactivate__form"
			@submit.prevent="handleFormSubmit"
		>
			<nice-input
				v-model="code"
				:label="$t('two_factor_auth.verification_code')"
				type="text"
				:invalid="error && error.message"
				class="deactivate__input"
			/>

			<nice-button-2
				type="submit"
				filled
				class="deactivate__submit"
			>{{ $t('two_factor_auth.submit') | capitalize }}</nice-button-2>
		</form>
	</div>
</template>

<script>
import { delete2fa } from '@/api/profile';
import NiceInput from '@/ui/nice-input';

export default {
	name: 'DisableTfa',

	components: {
		NiceInput,
	},

	data() {
		return {
			error: null,
			code: '',
		};
	},

	methods: {
		async handleFormSubmit() {
			const payload = {
				code: this.code
			};

			try {
				await delete2fa(payload);
			} catch(error) {
				this.handleSubmitError(error.response);
				return null;
			}
			this.error = null;
			this.$emit('ok');
		},

		handleSubmitError(error) {
			if (error && error.status === 400) {
				this.error = Object.freeze({
					status: 400,
					message: error.data.error,
				});
			} else {
				this.error = Object.freeze({
					status: null,
					message: 'unexpected error',
				});
			}

		},

		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="sass" scoped>
.deactivate
	box-sizing: border-box
	max-width: 380px
	padding: 40px

.deactivate__header
	display: flex
	align-items: center


.deactivate__title
	margin: 0 auto 0 0

.deactivate__close-button
	flex-shrink: 0
	flex-grow: 0
	margin-left: 20px

.deactivate__form
	display: flex
	align-items: flex-end
.deactivate__input
	flex-grow: 1


.deactivate__submit
	flex-grow: 0
	flex-shrink: 0
	margin-left: 20px
</style>
