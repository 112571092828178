<template>
	<symbol :id="`${prefix}hint`" viewBox="0 0 16 16">
		<path d="M8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0ZM8 13H7V11H8V13ZM8.93848 8.82812C8.37695 8.98242 8 9.44043 8 9.96777H7C7 8.98438 7.67188 8.13867 8.67236 7.86377C9.52393 7.62842 9.94434 7.09619 9.99512 6.18945C10.0488 5.22803 9.4126 4.29785 8.54639 4.07178C7.91699 3.90723 7.27344 4.0332 6.77832 4.41602C6.28369 4.79834 6 5.37549 6 6H5C5 5.06348 5.42529 4.19775 6.16699 3.625C6.90967 3.05127 7.86816 2.86182 8.79932 3.104C10.1309 3.45166 11.0742 4.80225 10.9932 6.24512C10.9189 7.58398 10.208 8.47656 8.93848 8.82812Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolHint',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-fill, #{$nice_color-gray})
</style>
