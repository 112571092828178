<template>
	<symbol :id="`${prefix}pencil-34`" viewBox="0 0 34 34">
		<circle cx="17" cy="17" r="16" stroke-width="2" class="circle" fill="none"/>
		<path d="M9.96714 21.3349L9.26003 20.6278L8.96714 20.9207V21.3349H9.96714ZM12.7956 24.1633V25.1633H13.2098L13.5027 24.8704L12.7956 24.1633ZM9.96714 24.1633H8.96714V25.1633H9.96714V24.1633ZM10.6742 22.042L22.1724 10.5438L20.7582 9.12959L9.26003 20.6278L10.6742 22.042ZM23.5866 11.958L12.0885 23.4562L13.5027 24.8704L25.0008 13.3722L23.5866 11.958ZM12.7956 23.1633H9.96714V25.1633H12.7956V23.1633ZM10.9671 24.1633V21.3349H8.96714V24.1633H10.9671ZM23.5866 10.5438C23.9772 10.9343 23.9772 11.5675 23.5866 11.958L25.0008 13.3722C26.1724 12.2007 26.1724 10.3012 25.0008 9.12959L23.5866 10.5438ZM22.1724 10.5438C22.5629 10.1533 23.1961 10.1533 23.5866 10.5438L25.0008 9.12959C23.8293 7.95802 21.9298 7.95802 20.7582 9.12959L22.1724 10.5438Z" class="sign "/>
		<!-- <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17C0 7.62598 7.62598 0 17 0C26.374 0 34 7.62598 34 17C34 26.374 26.374 34 17 34C7.62598 34 0 26.374 0 17ZM2 17C2 25.2715 8.729 32 17 32C25.2715 32 32 25.2715 32 17C32 8.729 25.2715 2 17 2C8.729 2 2 8.729 2 17ZM16 16V11H18V16H23V18H18V23H16V18H11V16H16Z" class="sign" /> -->
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPencil34',

	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))

.circle
	stroke: var(--ni-icon-sign, var(--main_color))
</style>
