<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 100 100"
		class="nice-icon"
		:class="[
			iconClass,
			iconState
		]"
	>
		<defs>
			<symbol
				:is="`ni-icon-${actualIcon}`"
				v-bind="getParams()"
			/>
		</defs>

		<use :xlink:href="`#${symbolId}`" x="0" y="0" width="100" height="100"/>
	</svg>
</template>

<script>
import NiIconAdd from './ni-icon-add';
import NiIconAddMin from './ni-icon-add-min';
import NiIconAddSqr from './ni-icon-add-sqr';
import NiIconArrow from './ni-icon-arrow';
import NiIconArrowCorner from './ni-icon-arrow-corner';
import NiIconBell from './ni-icon-bell';
import NiIconBusy from './ni-icon-busy';
import NiIconBusyMedium from './ni-icon-busy-medium';
import NiIconCalendar from './ni-icon-calendar';
import NiIconCancel from './ni-icon-cancel';
import NiIconChat from './ni-icon-chat';
import NiIconChbxCircle from './ni-icon-chbx-circle';
import NiIconChbxRnd from './ni-icon-chbx-rnd';
import NiIconChbxSqr from './ni-icon-chbx-sqr';
import NiIconChoiceDevices from './ni-icon-choice-devices';
import NiIconChoosing from './ni-icon-choosing';
import NiIconConfig from './ni-icon-config';
import NiIconDiagram from './ni-icon-diagram';
import NiIconDots from './ni-icon-dots';
import NiIconDraft from './ni-icon-draft';
import NiIconEdit from './ni-icon-edit';
import NiIconFree from './ni-icon-free';
import NiIconGear from './ni-icon-gear';
import NiIconHint from './ni-icon-hint';
import NiIconLoad from './ni-icon-load';
import NiIconLoadMin from './ni-icon-load-min';
import NiIconMarker from './ni-icon-marker';
import NiIconMedium from './ni-icon-medium';
import NiIconMonitor from './ni-icon-monitor';
import NiIconPause from './ni-icon-pause';
import NiIconPlay from './ni-icon-play';
import NiIconSearch from './ni-icon-search';
import NiIconSwitch from './ni-icon-switch';
import NiIconTrash from './ni-icon-trash';
import NiIconTrashBold from './ni-icon-trash-bold';
import NiIconX from './ni-icon-x';



export default {
	name: 'NiceIcon',

	components: {
		NiIconAdd,
		NiIconAddMin,
		NiIconAddSqr,
		NiIconArrow,
		NiIconArrowCorner,
		NiIconBell,
		NiIconBusy,
		NiIconBusyMedium,
		NiIconCalendar,
		NiIconCancel,
		NiIconChat,
		NiIconChbxCircle,
		NiIconChbxRnd,
		NiIconChbxSqr,
		NiIconChoiceDevices,
		NiIconChoosing,
		NiIconConfig,
		NiIconDiagram,
		NiIconDots,
		NiIconDraft,
		NiIconEdit,
		NiIconFree,
		NiIconGear,
		NiIconHint,
		NiIconLoad,
		NiIconLoadMin,
		NiIconMarker,
		NiIconMedium,
		NiIconMonitor,
		NiIconPause,
		NiIconPlay,
		NiIconSearch,
		NiIconSwitch,
		NiIconTrash,
		NiIconTrashBold,
		NiIconX,
	},


	data() {
		return {
			key: '',
		};
	},


	props: {
		icon: { type: String, required: true, },
		state: {
			type: [String, Number, Boolean],
			required: false,
			default: null
		},
		params: { type: Object, required: false, },
		palette: { type: Object, },
		hover: { type: Boolean, default: false, },
		disabled: { type: Boolean, default: false, },
	},


	computed: {
		iconClass() {
			return `nice-icon__${this.actualIcon}`;
		},

		/**
		 * return state for icon
		 */
		iconState() {
			return `state-${this.state}`;
		},

		prefix() {
			return `__${this.key}__`;
		},

		symbolId() {
			const icon_id = `${this.prefix}${this.actualIcon}`;
			return icon_id;
		},

		actualIcon() {
			let icon = this.icon;

			switch(icon) {
			case 'sketch':
				icon = 'draft' ;
				break;

			case 'cross':
			case 'criss-cross':
				icon = 'x';
				break;

			case 'availability-busy':
				icon = 'busy';
				break;

			case 'awaiting_approval':
			case 'availability-medium':
				icon = 'medium';
				break;

			case 'availability-free':
			case 'published':
			case 'finished':
			case 'accepted':
			case 'approved':
				icon = 'free';
				break;

			case 'delete':
			case 'rejected':
				icon = 'cancel';
				break;

			case 'paused':
			case 'unpublished':
				icon = 'pause';
				break;

			case 'playing':
				icon = 'play';
				break;

			case 'add-mini':
				icon = 'add-min';
				break;

			case 'archived':
				icon = 'trash-bold';
				break;
			}

			return icon;
		}
	},

	methods: {
		getParams() {
			return {
				icon: this.icon,
				prefix: this.prefix,
				state: this.state,
				palette: this.palette,
				disabled: this.disabled,
				params: this.params,
			};
		},
	},

	created() {
		this.key = Math.round(Math.random() * 100000).toString(16);
	},
};
</script>

<style lang="sass" scoped >
.nice-icon__add
	height: 34px

.nice-icon__choice-devices,
.nice-icon__choosing
	height: 28px

.nice-icon__switch
	height: 25px

.nice-icon__chat
	height: 23px

.nice-icon__bell
	height: 22px

.nice-icon__gear
	height: 21px

.nice-icon__chbx-sqr,
.nice-icon__add-sqr,
.nice-icon__busy-medium
	height: 20px

.nice-icon__monitor,
.nice-icon__add-min
	height: 17px

.nice-icon__busy,
.nice-icon__free,
.nice-icon__medium,
.nice-icon__cancel,
.nice-icon__pause,
.nice-icon__play,
.nice-icon__draft,
.nice-icon__diagram,
.nice-icon__hint
	height: 16px

.nice-icon__load
	height: 15px

.nice-icon__chbx-rnd,
.nice-icon__marker,
.nice-icon__calendar,
.nice-icon__config,
.nice-icon__chbx-circle,
.nice-icon__trash,
.nice-icon__arrow,
.nice-icon__dots,
.nice-icon__trash-bold
	height: 14px

.nice-icon__edit
	height: 13px

.nice-icon__search,
.nice-icon__load-min
	height: 12px

.nice-icon__x,
.nice-icon__arrow-corner
	height: 10px
</style>
