import { request } from '../request';


function _addEndingSlash(url) {
	return url.endsWith('/') ? url : `${url}/`;
}


function _list(prefix) {
	return async (filters, page=1, page_size=20, config) => {
		const data = {
			...filters,
			page,
			page_size,
		};
		const result = await request(prefix(), data, 'get', config);
		return result;
	};
}


function _create(prefix) {
	return async (data, config) => {
		let result = await request(prefix(), data, 'post', config);
		return result;
	};
}

function _retrieve(prefix) {
	return async (id, config) => {
		let result = await request(`${_addEndingSlash(prefix())}${id}/`, {}, 'get', config);
		return result;
	};
}


function _patch(prefix) {
	return async (id, data, config) => {
		let result = await request(`${_addEndingSlash(prefix())}${id}/`, data, 'patch', config);
		return result;
	};
}


function _delete(prefix) {
	return async (id, data, config) => {
		let result = await request(`${_addEndingSlash(prefix())}${id}/`, data, 'delete', config);
		return result;
	};
}

export default function (prefix) {
	return {
		'list': _list(prefix),
		'create': _create(prefix),
		'retrieve': _retrieve(prefix),
		'patch': _patch(prefix),
		'delete': _delete(prefix),
	};
}
