<template>
	<label
		class="nice-switch"
		:class="{
			'labeled': labeled || label,
		}"
		:disabled="disabled"
	>
		<span v-if="label" class="ni-label" >{{ label }}</span>
		<input
			:id="id"
			v-model="innerValue"
			type="checkbox"
			class="ni-switch-input"
			:name="name"
			:disabled="disabled"
		/>
		<nice-icon
			icon="switch"
			:state="innerValue"
			class="ni-switch-icon"
		/>
		<span
			v-if="caption"
			class="ni-switch-caption"
			:title="caption"
		>{{ caption }}</span>
	</label>
</template>

<script>
export default {
	name: 'NiceSwitch',


	props: {
		labeled: { type: Boolean, default: false, },
		label: { type: String },

		id: { type: String, default: '' },
		name: { type: String },

		captionOff: { type: String, default: 'off' },
		captionOn: { type: String, default: 'on' },
		disabled: { default: false, },
		value: {
			type: [Boolean, String, Number],
			default: false,
		},
	},


	data() {
		let value;
		switch (this.value) {
		case true:
		case 'true':
		case 'True':
		case 'on':
		case 1:
		case '1':
		case 't':
		case 'T':
		case 'y':
		case 'yes':
		case 'yeah':
		case 'yup':
		case 'certainly':
		case 'uh-huh':
			value = true;
			break;

		default:
			value = false;
			break;
		}

		return {
			innerValue: value || false,
		};
	},


	computed: {
		caption() {
			if (this.innerValue) {
				return this.captionOn;
			} else {
				return this.captionOff;
			}
		},

		input() { return this.$refs.input; },

		focus() { return this.$refs.input.focus; },
	},


	watch: {
		value() {
			this.innerValue = this.value;
		},

		innerValue() {
			this.$emit('input', this.innerValue);
		},
	},
};
</script>

<style lang="sass" scoped >
.nice-switch
	@extend %ui-nice-component

	border-bottom: 1px solid transparent
	margin-bottom: 6px

	&.labeled
		padding-top: 25px

	&[disabled]
		opacity: 0.6

.ni-switch-input
	display: none

.ni-switch-icon
	cursor: pointer

.ni-switch-caption
	display: block
	font-size: $fsz__normal
	line-height: $ui-nice__input-heigth
	white-space: nowrap
	text-overflow: ellipsis
	user-select: none
	overflow: hidden
	margin-left: 9px
</style>
