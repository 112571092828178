import agency from './agency.js';
import app from './app.js';
import menu from './menu.js';
import browser from './browser.js';
import campaign from './campaign.js';
import creative from './creative.js';
import deviceFilters from './device-filters.js';
import devices from './devices';
import document from './document';
import extra from './extra.js';
import profile from './profile.js';
import request from './request.js';
import modals from './modals.js';

export default {
	agency,
	app,
	menu,
	browser,
	campaign,
	creative,
	deviceFilters,
	devices: devices(),
	document,
	extra,
	profile,
	request,
	wizardDevices: devices(),
	modals,
};
