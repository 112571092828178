<template>
	<div
		class="nice-progress-bar"
		:class="{
			'labeled': labeled || hasLabel,
			'label_holder': labeled && !hasLabel,
		}"
		:style="{
			'--progress': `${positionCurrent}%`,
		}"
	>
		<div
			v-if="hasLabel"

			class="ni_progress_bar--label"
		>
			<slot name="label" >
				<nice-label
					:label="label"
					:hint="hint"
				/>
			</slot>

			<slot name="max-label" >
				<nice-label
					:label="maxLabel"
					:hint="maxHint"
				/>
			</slot>
		</div>

		<div
			v-if="!hideValue"
			class="ni_progress_bar--value_row"
		>

			<div class="ni_progress_bar--value">
				<nice-icon-2
					v-if="valueIcon"
					:icon="valueIcon"
					class="ni_progress_bar--value_icon"
				/>
				<span v-html="formatNumber(value)" />
			</div>
			<div class="ni_progress_bar--value">
				<nice-icon-2
					v-if="valueIcon"
					:icon="valueIcon"
					class="ni_progress_bar--value_icon"
				/>
				<span v-html="formatNumber(max)" />
			</div>
		</div>

		<div class="ni_progress_bar--bar"></div>

	</div>
</template>


<script>
import { formatNumber } from '@/utilites';


const clearPosition = i => Math.max(0, Math.min(100, i));


export default {
	name: 'NiceProgressBar',


	props: {
		value: {
			type: Number,
			required: true,
		},

		valueIcon: String,

		max: {
			type: Number,
			default: 100,
		},

		labeled: {
			type: Boolean,
			default: false,
		},

		// label
		label: String ,
		hint: String,

		// label
		maxLabel: {
			type: String,
			default: 'Available',
		},
		maxHint: String,

		hideValue: {
			type: Boolean,
			default: false,
		},

	},


	computed: {
		positionCurrent() {
			return clearPosition(this.value / this.max * 100);
		},

		hasLabel() {
			return !!(this.$slots.label || this.label);
		},
	},


	methods: {
		formatNumber,
	},
};
</script>


<style lang="sass" scoped>
.nice-progress-bar
	--progress: 0
	--hint: 0
	width: 250px
	font-size: $fsz__normal

	&.label_holder
		padding-top: $ni_progress_bar-label-height

.ni_progress_bar--label
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between

.ni_progress_bar--value_row
	$padding-top: 4px

	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between

	width: 100%
	height: auto
	min-height: $ni_progress_bar-label-height - $padding-top
	padding: $padding-top 0 0

	font-size: inherit
	color: var(--text_color)

.ni_progress_bar--value
	display: flex
	align-items: center
	font-size: inherit
	line-height: $fsz__line_height-default


.ni_progress_bar--value_icon
	margin-right: 3px


.ni_progress_bar--bar
	position: relative

	width: 100%
	height: $ni_progress_bar-label-height

	&::before
		content: ""

		position: absolute
		top: $ni_progress_bar-bar-margin-y
		left: 0

		display: block
		height: $ni_progress_bar-bar-height
		width: 100%

		background-color: var(--disabled_color)
		border-radius: $ni_progress_bar-bar-height / 2

	&::after
		content: ""

		position: absolute
		top: $ni_progress_bar-bar-margin-y
		left: 0

		display: block
		height: $ni_progress_bar-bar-height
		width: var(--progress)

		background-color: var(--main_color)
		border-radius: $ni_progress_bar-bar-height / 2

		transition: width $ni_progress_bar-bar-transition-time $ni_progress_bar-bar-transition-easing

.ni_progress_bar--hint
	position: absolute
	left: var(--hint)
	top: 6px
	width: 0
	height: 12px
	z-index: 1
	transition: left $ni_progress_bar-bar-transition-time $ni_progress_bar-bar-transition-easing

.progress-marker
	position: absolute
	display: block
	left: -5px
	top: 0
	width: 10px
	height: 10px
	border-radius: 50%
	border: 1px solid #ffffff
	background-color: var(--main_color)
	box-sizing: border-box

	&::before
		background-color: inherit
		will-change: opacity
		content: ''
		display: block
		z-index: -1
		position: absolute
		width: 20px
		height: 20px
		top: -6px
		left: -6px
		opacity: 0
		transition: opacity .2s ease
		border-radius: 50%

	&:hover::before
		opacity: .4

</style>
