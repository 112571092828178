<template>
	<symbol :id="`${prefix}calendar-14`" viewBox="0 0 13 14">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.01436V3H4V2H9V3H10V2.01436C11.1238 2.14157 12 3.09806 12 4.25534V5H1V4.25534C1 3.09806 1.87617 2.14157 3 2.01436ZM4 1H9V0H10V1.00987C11.6785 1.1401 13 2.54339 13 4.25534V10.7447C13 12.5425 11.5425 14 9.74466 14H3.25534C1.45746 14 0 12.5425 0 10.7447V4.25534C0 2.54339 1.32147 1.1401 3 1.00987V0H4V1ZM5 6H8V9H5V6ZM4 9V6H1V9H4ZM1 10H4V13H3.25534C2.01174 13 1 11.9883 1 10.7447V10ZM5 10H8V13H5V10ZM9 10V13H9.74466C10.9883 13 12 11.9883 12 10.7447V10H9ZM12 9V6H9V9H12Z" class="sign"/>
	</symbol>


</template>

<script>
export default {
	name: 'NiIconSymbolCalendar14',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})
</style>
