<template>
	<section class="layout-full_width" >
		<MglMap
			:accessToken="accessToken"
			:mapStyle="mapStyle"
			class="map"
		>
			<!-- props needed for GeolocateControl -->
			<MglGeocoderControl
				:marker="false"
				:fly-to="false"
				types="place,locality,neighborhood,address,poi"

				:accessToken="accessToken"
				:input.sync="defaultInput"
				@results="handleSearch"
			/>
		</MglMap>
	</section>
</template>

<script>
import { MglMap } from 'vue-mapbox';
import MglGeocoderControl from '@geospoc/v-mapbox-geocoder';
import { getGeofeatureName } from '@/utilites';


export default {
	name: 'TabMapboxGeocoder',


	components: {
		MglMap,
		MglGeocoderControl
	},


	data() {
		return {
			accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
			mapStyle: process.env.VUE_APP_MAPBOX_STYLES,
			defaultInput: 'Bodhgaya'
		};
	},


	methods: {
		handleSearch(event) {
			event.features.map((item) => {
				const place_name = getGeofeatureName(item);
				return {
					value: place_name,
					label: place_name,
					item: item,
				};
			});
		}
	}
};
</script>


<style lang="sass" >
.map
	height: 400px
</style>
