<template>
	<section>
		<div class="top-panel">

			<!-- TODO: move invitation out of users list? -->
			<invitation-create-modal
				v-if="isInvitationCreateModalShowing"
				:agency-id="agency.id"
				:is-sent="isInvitationSent"

				:showRolesSelect="invitationShowRolesSelect"
				:rolesList="invitationRolesList"

				@send-invitation="$emit('send-invitation', $event)"
				@close="isInvitationCreateModalShowing = false"
			/>

			<nice-button-2
				icon="plus-24"
				class="add-button"
				icon-painted
				@click="isInvitationSent = false; isInvitationCreateModalShowing = true"
			>
				{{ $t('users.add_'+resource) | capitalize }}
			</nice-button-2>


			<nice-input
				v-model="filter"
				class="search-input"
				type="text"
				:placeholder="$t('search') | capitalize"
			>
				<nice-icon
					icon="search"
				/>
			</nice-input>
		</div>

		<nice-users-list
			:items="items"

			@remove="$emit('remove', $event)"
			@interact="$emit('interact', $event)"
		/>

	</section>
</template>

<script>
import InvitationCreateModal from '@/components/users/invitation-create-modal';

import NiceUsersList from '@/ui/nice-users/nice-users-list';
import NiceInput from '@/ui/nice-input';


export default {
	name: 'UsersList',

	components: {
		NiceUsersList,
		NiceInput,
		InvitationCreateModal,
	},

	props: {
		resource: {
			type: String,
			default: 'user',
		},

		agency: {
			type: Object,
			required: true,
		},

		items: {
			type: Array,
			required: true,
		},

		// pass into InvitationCreateModal
		invitationShowRolesSelect: {
			default: false,
		},

		/**
		 * Required if `showRolesSelect == true`
		 *
		 * [
		 *   {
		 *     value: <value>,
		 *     label: <label>
	   *   },
		 *   ...
	   * ]
		 */
		invitationRolesList: {
			default: () => [],
		},
	},

	data() {
		return {
			// TODO: not working
			filter: '',

			// show invitation modal
			isInvitationCreateModalShowing: false,
			isInvitationSent: false,
		};
	},

};
</script>

<style lang="sass" scoped >
.search-input
	width: 200px
	margin-left: 4px

.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px

	& > *:not(:first-child)
		margin-left: 20px

.page-title
	font-family: Roboto
	font-style: normal
	font-weight: normal
	font-size: 24px
	line-height: 28px
	text-align: left
	margin-bottom: 40px
</style>
