<template>
	<section class="page-section">
		<h1>NiceProgressBar</h1>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<nice-input
					id="test-input-placeholder"
					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					type="text"
					placeholder="Type placeholder here"
					label="input label"
				/>
				<nice-slider
					v-model="testNo1"
					:axis="testNo1Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
					label="label"
				/>
				<nice-slider
					v-model="testNo2"
					:axis="testNo2Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					hide-value
				/>
				<nice-slider
					v-model="testNo3"
					:axis="testNo3Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
					hide-value
				/>
				<nice-slider
					v-model="testNo4"
					:axis="testNo4Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
				/>
				<nice-slider
					v-model="testNo5"
					:axis="testNo5Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					label="label"
					hide-value
				/>
				<nice-slider
					v-model="testNo6"
					:axis="testNo6Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
				/>
				<nice-slider
					v-model="testNo7"
					:axis="testNo7Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
					label="label"
				/>
			</div>
		</div>

		<hr />
		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<nice-input
					id="test-input-placeholder-2"
					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					type="text"
					placeholder="Type placeholder here"
					label="input label"
				/>
				<nice-progress-bar
					:value="testNo1"
					:max="testNo1Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
					label="label"
				/>
				<nice-progress-bar
					:value="testNo2"
					:max="testNo2Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					hide-value
				/>
				<nice-progress-bar
					:value="testNo3"
					:max="testNo3Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
					hide-value
				/>
				<nice-progress-bar
					:value="testNo4"
					:max="testNo4Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
				/>
				<nice-progress-bar
					:value="testNo5"
					:max="testNo5Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					label="label"
					hide-value
				/>
				<nice-progress-bar
					:value="testNo6"
					:max="testNo6Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
				/>
				<nice-progress-bar
					:value="testNo7"
					:max="testNo7Label"

					class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2"
					labeled
					label="label"
					hint="Alibaba"
					max-hint="Alideda"
				/>
			</div>
		</div>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo1"
	:max="testNo1Label"

	labeled
	label="label"
/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo2"
	:max="testNo2Label"

	hide-value
/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo3"
	:max="testNo3Label"

	labeled
	hide-value
/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo4"
	:max="testNo4Label"

/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo5"
	:max="testNo5Label"

	label="label"
	hide-value
/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo6"
	:max="testNo6Label"

	labeled
/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-sm3 cl-md2 cl-lg1 cl-xl2" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo7"
	:max="testNo7Label"

	labeled
	label="label"
	hint="Alibaba"
	max-hint="Alideda"
/&gt;</pre></div>
			</div>
		</div>

		<h2>Слоты в лейблах</h2>
		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<nice-progress-bar
					:value="testNo1"
					:max="testNo1Label"
					class="cl-pad cl-xs6  cl-md4"
				>
					<template #label >
						<nice-icon-2 icon="play" />
					</template>
				</nice-progress-bar>
				<div class="cl-pad cl-xs6 cl-md4" ><pre class="code-preview" >&lt;nice-progress-bar
	:value="testNo1"
	:max="testNo1Label"
&gt;
	&lt;template #label &gt;
		&lt;nice-icon-2 icon="play" /&gt;
	&lt;/template&gt;
&lt;/nice-progress-bar&gt;</pre></div>
			</div>
		</div>
	</section>
</template>

<script>
import NiceInput from '@/ui/nice-input';
import NiceProgressBar from '@/ui/nice-progress-bar';
import NiceSlider from '@/ui/nice-slider';


export default {
	name: 'TabProgressBar',

	components: {
		NiceInput,
		NiceProgressBar,
		NiceSlider,
	},

	data() {
		return {
			testNo1: 15,
			testNo1Label: 100,

			testNo2: 35,
			testNo2Label: 100,

			testNo3: 15,
			testNo3Label: 100,

			testNo4: 1120,
			testNo4Label: 3000,

			testNo5: 15,
			testNo5Label: 100,

			testNo6: 4234532,
			testNo6Label: 356100000,

			testNo7: 150,
			testNo7Label: 2546,
		};
	},
};
</script>
