<template>
	<symbol :id="`${prefix}play-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M14.6154 11.2782C15.1282 11.599 15.1282 12.401 14.6154 12.7218L11.1538 14.8871C10.641 15.2079 10 14.8069 10 14.1653L10 9.83468C10 9.1931 10.641 8.79211 11.1538 9.1129L14.6154 11.2782Z" class="sign"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlay24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-green})
	fill-opacity: 0.2

.sign
	fill: var(--ni-icon-sign, #{$nice_color-green})
</style>
