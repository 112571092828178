<template>
	<section class="page-section">
		<h1>NiceSpoiler</h1>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<nice-spoiler
					label="Age"

					class="cl-pad cl-xs4 cl-sm3 cl-md4 cl-lg3 cl-xl4"
				/>
				<nice-spoiler
					label="Life Stage"
					expand

					class="cl-pad cl-xs4 cl-sm3 cl-md4 cl-lg3 cl-xl4"
				>
					<nice-range
						:value="[4, 8]"
						:axis="10"
						scale
					/>
				</nice-spoiler>
			</div>
		</div>
		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div
					class="cl-pad cl-xs4 cl-sm3 cl-md4 cl-lg3 cl-xl4"
				>
					<pre class="code-preview" ><code>&lt;nice-spoiler
	label="Age"

	class="cl-xs4 cl-sm3 cl-md4 cl-lg3 cl-xl4"
/>&gt;</code></pre>
				</div>
				<div
					class="cl-pad cl-xs4 cl-sm3 cl-md4 cl-lg3 cl-xl4"
				>
					<pre class="code-preview" ><code>&lt;nice-spoiler
	label="Life Stage"
	expand

	class="cl-xs4 cl-sm3 cl-md4 cl-lg3 cl-xl4"
&gt;
	&lt;nice-range
		:value="[4, 8]"
		:axis="10"
		scale
	/&gt;
&lt;/nice-spoiler&gt;</code></pre>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
import NiceSpoiler from '@/ui/nice-spoiler';
import NiceRange from '@/ui/nice-range';


export default {
	name: 'TabSpoiler',

	components: {
		NiceSpoiler,
		NiceRange,
	},
};
</script>
