<template>
	<symbol :id="`${prefix}man`" width="20" height="19" viewBox="0 0 20 19" fill="none">

		<path d="M7.19657 10.7927L7.61048 11.489C7.9803 12.1112 8.85771 12.6492 9.99955 12.6492C11.1414 12.6492 12.0188 12.1112 12.3886 11.489L12.8025 10.7927L13.5696 11.0531C15.53 11.7184 17.2215 12.9465 18.1911 14.6162L19.8643 17.4979L18.1348 18.5021L16.4615 15.6204C15.8798 14.6187 14.8738 13.767 13.5833 13.2013C12.7153 14.1147 11.3991 14.6492 9.99955 14.6492C8.60001 14.6492 7.28377 14.1147 6.41576 13.2013C5.12535 13.767 4.11929 14.6187 3.53761 15.6204C3.53761 15.6204 3.53761 15.6204 3.53761 15.6204L1.86434 18.5021L0.134766 17.4979L1.80804 14.6162C2.77756 12.9465 4.46913 11.7184 6.42949 11.0531L7.19657 10.7927Z" class="sign"/>
		<path d="M9.99955 9C11.4813 9 12.9995 7.61664 12.9995 5.5C12.9995 3.38336 11.4813 2 9.99955 2C8.51781 2 6.99955 3.38336 6.99955 5.5C6.99955 7.61664 8.51781 9 9.99955 9ZM9.99955 11C12.761 11 14.9995 8.53757 14.9995 5.5C14.9995 2.46243 12.761 0 9.99955 0C7.23813 0 4.99955 2.46243 4.99955 5.5C4.99955 8.53757 7.23813 11 9.99955 11Z" class="sign"/>

	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMan13',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
	fill-rule: evenodd
	clip-rule: evenodd
</style>
