<template>
	<main>
		<section class="layout-full_width" >
			<table-top-panel
				v-model="filterOptions"
			/>

			<table class="nice-table">
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Description</th>
						<th>Start</th>
						<th>End</th>
						<th>Modified</th>
						<th><!-- Actions --></th>
					</tr>
				</thead>

				<tbody>
					<template
						v-if="documentListTotalCount"
					>
						<tr
							v-for="(doc, index) in documentList"
							:key="`${doc.id}-${index}`"
							:class="`doc_${doc.id}_row`"

							@mouseenter="onRowMouseEneter(index)"
							@mouseleave="onRowMouseLeave(index)"
						>
							<td>{{ doc.id }}</td>
							<table-cell-name
								:id="doc.id"
								resource="document"
								:caption="doc.name"
								:show-link="isSSP"
								:show-icon="index === hoverRowIndex"
							/>
							<td class="col-description" >
								{{ doc.description }}
							</td>

							<td
								class="col-start"
								v-html="typeof doc.start === 'string' ? doc.start : formatDate(doc.start)"
							>
								{{ doc.start }}
							</td>

							<td
								class="col-end"
								v-html="typeof doc.end === 'string' ? doc.end : formatDate(doc.end)"
							>
								{{ doc.end }}
							</td>

							<td
								class="col-modified"
								v-html="typeof doc.mtime === 'string' ? doc.mtime : formatDate(doc.mtime)"
							>
								{{ doc.mtime }}
							</td>

							<td class="col-actions">
								<nice-button
									class="action-button"
									:class="{
										hidden: index !== hoverRowIndex
									}"
									icon="trash"
								/>

								<nice-button
									class="action-button"
									icon="load"
									icon-state="download"
								/>
							</td>
						</tr>
					</template>

					<tr
						v-else
						class="table-empty"
					>
						<td colspan="6" >There's nothing 😕</td>
					</tr>
				</tbody>
			</table>

			<table-paginator
				v-model="pagination"
				:total="documentListTotalCount"
				:paginator-props="{ queryKey: 'p' }"
			/>
		</section>
	</main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import TableCellName from '@/components/table/cell-name';
import TableTopPanel from '@/components/table-top-panel.vue';
import TablePaginator from '@/components/table-paginator.vue';

import { formatDate } from '@/utilites';


export default {
	name: 'PageDocumentList',

	components: {
		TableCellName,
		TablePaginator,
		TableTopPanel,
	},

	data() {
		return {
			filterOptions: {},
			hoverRowIndex: null,
		};
	},

	computed: {
		...mapGetters('app', { appIsLoading: 'isLoading' }),
		...mapGetters('document', { documentListIsLoading: 'isLoading' }),
		...mapState('app', { appAgency: 'agency', appSide: 'side' }),
		...mapState('document', {
			documentList: 'list',
			documentListTotalCount: 'totalCount',
			documentListPage: 'page',
			documentListPageSize: 'pageSize',
		}),

		isSSP() {
			return this.appSide == 'ssp';
		},

		/**
		 * Return statuses for filter
		 */
		statusFilter() {
			let result = [
				{ value: '---', label: 'All campaigns' },
				// { value: STATUS_REJECTED, label: 'Rejected' },
				// { value: STATUS_PLAYING, label: 'Playing' },
				// { value: STATUS_PAUSED, label: 'Paused' },
				// { value: STATUS_FINISHED, label: 'Finished' },
			];

			if (this.isSSP) {
				// result.splice(1, 0, { value: STATUS_DRAFT, label: 'Drafts', });
			}
			return result;
		},

		pagination: {
			get() {
				return {
					page: this.documentListPage,
					pageSize: this.documentListPageSize,
				};
			},

			set(pagination) {
				this.requestDocumentList({
					filters: this.filterOptions,
					...pagination,
				});
			},
		},
	},


	methods: {
		...mapActions('document', {
			'requestDocumentList': 'requestList',
		}),

		formatDate,

		onRowMouseEneter(index) {
			this.hoverRowIndex = index;
		},
		onRowMouseLeave(index) {
			if (this.hoverRowIndex === index) this.hoverRowIndex = null;
		},
	},


	watch: {
		appAgency(value, oldValue) {
			if (value && value !== oldValue) {
				this.requestDocumentList({ filters: this.filterOptions, force: true });
			}
		},

		filterOptions: {
			deep: true,
			handler() {
				if (this.appAgency) {
					this.requestDocumentList({ filters: this.filterOptions, force: true });
				}
			}
		},
	},
};
</script>

<style lang="sass" scoped >
.button-status_change
	+button__clear

	.nice-icon
		display: block

.status_change-wrapper
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start

.status_change
	@extend %button__text

	display: flex
	flex-direction: row
	justify-content: center
	align-items: flex-start

	padding: 5px
	font-size: 11px
	text-transform: capitalize
	color: $nice_color-semidarkgray

	.nice-icon
		margin-right: 10px

.table-empty
	text-align: center

.col-actions
	text-align: right

.action-button
	&.hidden
		opacity: 0
		pointer-events: none

	&:not(:last-child)
		margin-right: 1.5em
</style>
