/**
 * Add available blank
 */
import _ from 'underscore';
import { rawToOption } from './options';


const MODEL = Object.freeze({
	prop: 'inputValue',
	event: 'input',
});


export default {
	model: MODEL,


	props: {
		[MODEL.prop]: {
			type: [Number, String, Array],
			default: null,
		},

		multiple: Boolean,

		required: Boolean,
		unselectable: Boolean,

		invalid: {
			type: [Boolean, String],
			default: false,
		},

		validator: {
			type: Function,
		},
	},


	data() {

		if (this.multiple && !Array.isArray(this[MODEL.prop])) {
			this.$log.warn(`multiple is true but input value "${this[MODEL.prop]}" is not a list`);
		}

		// for `multiple == true`
		let innerValue = JSON.parse(JSON.stringify(this[MODEL.prop]));

		return {
			value: innerValue,

			// temp list of selected options used for display label
			// need for request options list from async function in prop options
			selectedOptions: _.reduce(innerValue instanceof Array ? innerValue : innerValue, (options, option) => {
				options[option] = option;
				return options;
			}, {}),
		};
	},


	computed: {
		/**
		 * Convert value to list and return
		 */
		valuesList() {
			return this.value !== null ? _.flatten([this.value]) : [];
		},


		/**
		 * @typedef {Object} Error
		 * @property {Boolean} is - error flag
		 * @property {String} message - error message
		 */

		/**
		 * Error compyted property
		 * @return {Object} result
		 * @return { } [description]
		 */
		error() {
			let is = false;
			let message = null;

			// check required && no value
			if (this.required && !this.value) {
				is = true;
				message = this.$t('errors.select_required_not_selected');

				return { is, message };
			}

			// check if prop invalid
			if (this.invalid) {
				is = true;
				message = typeof this.invalid === 'string' ? this.invalid : null;

				return { is, message };
			}


			if (this.validatorResult && this.validatorResult.is) {
				return this.validatorResult;
			}

			return { is, message };
		},
	},


	methods: {
		/**
		 * Calc selected or not value
		 */
		valueIsSelected(value) {
			return _.indexOf(this.valuesList, value) !== -1;
		},

		/**
		 * Handler toggle option status (selected / unselected)
		 */
		optionToggleHandler(option) {
			let value = JSON.parse(JSON.stringify(this.value));

			if (this.valueIsSelected(option.value)) {
				// unset value for not multiple
				// unset or remove value for multiple

				if (this.multiple) {
					if (value.length > 1 || this.unselectable) {
						value = _.without(value, option.value);
						this.selectedOptions = _.omit(this.selectedOptions, option.value);
					}
				}
				else {
					if (this.unselectable) {
						value = null;
						this.selectedOptions = _.omit(this.selectedOptions, option.value);
					}
				}
				// value = this.multiple && value && value.length > 1 ? _.without(value, option.value) : null;
				// this.selectedOptions = _.omit(this.selectedOptions, option.value);
			}
			else {
				if (this.multiple) {
					// saw null few times
					if (!value) {
						value = [];
					}
					value = value.concat(option.value);
				}
				else {
					value = option.value;
					this.isDropDownShowing = false;
					this.filterQuery = null;
				}
				// value = this.multiple ? [option.value].concat(value || []) : option.value;
				// this.selectedOptions[option.value] = option.label;
			}

			this.$set(this, 'value', value);
		},

		/**
		 * Recalc selected options
		 */
		async recalcSelectedOptions() {
			let optionsList = this.optionList;
			if (this.options instanceof Function) {
				// request options
				const requestedOptions = _.map(await this.options({ values: this.valuesList }), rawToOption);
				if (requestedOptions.length) {
					optionsList = requestedOptions;
				}
			}

			const defaultOptions = _.reduce(this.valuesList, (options, option) => {
				options[option] = option;
				return options;
			}, {});

			this.selectedOptions = _.reduce(optionsList, (options, option) => {
				options[option.value] = option.label;
				return options;
			}, defaultOptions);
		},


		// applies validator from props
		applyValidator() {
			let is = false;
			let message = null;

			if (typeof this.validator === 'function') {
				try {
					is = this.validator(this.value);
				} catch(error) {
					is = true;
					message = error;
				}
			}

			return { is, message };
		},
	},


	watch: {
		[MODEL.prop]: {
			deep: true,
			async handler(newValue, oldValue) {
				if (this.multiple) {
					const newValueString = _.sortBy(newValue).join(',');
					if (!oldValue) {
						oldValue = [];
					}
					const oldValueString = _.sortBy(oldValue).join(',');

					if (newValueString == oldValueString) {
						return;
					}
				}

				this.$set(this, 'value', newValue);
				await this.recalcSelectedOptions();

				if (this.multiple && this.options instanceof Function) {
					this.requestedOptions = await this.options({ values: newValue });
				}
			},
		},  // END: watch [MODEL.prop]

		value: {
			handler(newValue, oldValue) {
				if (this.multiple) {
					const newValueString = _.sortBy(newValue).join(',');
					if (!oldValue) {
						oldValue = [];
					}
					const oldValueString = _.sortBy(oldValue).join(',');

					if (newValueString == oldValueString) {
						return;
					}
				}

				this.$emit(MODEL.event, newValue);
				this.$set(this, 'validatorResult', this.applyValidator(newValue));
			},
		},  // END: watch value

	},
};
