import baseRest from '../base/rest';
import { getPrefix as _getPrefix } from './index';
// import store from "@/store";
import { request } from '../request';

const getPrefix = () => `${_getPrefix()}ad/`;

const { list, retrieve, /* patch */ } = baseRest(getPrefix);

export default {
	list,
	retrieve,
	getPrefix,

	/**
	 * Update audit
	 *
	 * TODO: ssp agency is in the URL
	 *
	 * @param {number} id - creative id
	 * @param {number} campaign - campaign id
	 * @param {number} status - audit status code
	 * @param {string} feedback - audit status reason
	 *
	 * @returns {promise} result
	 */
	async updateAuditForCampaign(id, campaign, status, feedback) {
		let result = await request(
			`${getPrefix()}${id}/update-audit-for-campaign/${campaign}/`,
			{
				id,
				campaign,
				status,
				feedback,
			},
			'patch'
		);
		return result;
	},
};
