<template>
	<symbol :id="`${prefix}check-34`" viewBox="0 0 34 34">
		<circle cx="17" cy="17" r="16" fill="none" stroke-width="2" class="sign" />
		<path d="M13.5,16.5 16.5,19.5 20.5,15.5" fill="none" stroke-width="2" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolCheck34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke: var(--ni-icon-sign, #{$nice_color-green})
</style>
