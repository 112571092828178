
// Campaign / Request fields' labels
// Use like this: `$t(CAMPAIGN_REQUEST_FIELDS_LABELS[fieldName])`
export const CAMPAIGN_REQUEST_FIELDS_LABELS = {
	// 'non_field_errors': '',
	'name': 'campaign.campaign_name',
	'description': 'campaign.description',
	'industry': 'campaign.content_category_label',
	'advertiser': 'campaign.advertiser_label',
	'brand': 'campaign.brand_label',
	'payment_model': 'campaign.payment_model_label',
	'platforms': 'campaign.platform_label',
	'areas': 'campaign.address',
	'environments': 'campaign.environment_label',
	'life_stages': 'campaign.life_stage_label',
	'interests': 'campaign.int_and_act_label',
	'intents': 'campaign.intent_label',
	'pois': 'campaign.poi_label',
	'age': 'campaign.age_label',
	'gender': 'campaign.gender_label',
	'resolutions': 'campaign.resolution_label',
	'orientations': 'campaign.orientation_label',
	'device_types': 'campaign.type_label',
	'start_date': 'campaign.time_period',
	'end_date': 'campaign.time_period',
	'time_range': 'campaign.time_range',
	'schedule': 'campaign.week_days_label',
	'ad_duration': 'campaign.message_duration',
	'ad_rate_per_hour': 'campaign.frequency_limit',
	'ad_rate_multiplier': 'campaign.frequency_limit',
	'budget': 'campaign.budget',
	'is_fixed_placement': 'campaign.fixed_placement',
	'max_bid': 'campaign.max_bid',
	'status': 'request_list.status',
	// TODO: other label?
	'ads': 'campaign.creative_tab',
};
