<template>
	<symbol :id="`${prefix}choice-devices`" viewBox="0 0 23 28">
		<path
			fill="none"
			:stroke="getColor('main')"
			:stroke-width="strokeWidth"
			d="M22,12v11c0,2.21-1.79,4-4,4H5c-2.21,0-4-1.79-4-4V5c0-2.21,1.79-4,4-4h6"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			:stroke-width="strokeWidth"
			x1="9"
			y1="22"
			x2="14"
			y2="22"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			:stroke-width="strokeWidth"
			x1="16"
			y1="0"
			x2="16"
			y2="14"
		/>
		<line
			fill="none"
			:stroke="getColor('main')"
			:stroke-width="strokeWidth"
			x1="23"
			y1="7"
			x2="9"
			y2="7"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconChoiceDevices',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				main__disabled: this.$palette.disabled_color,

				...this.palette,
			};
		},

		strokeWidth() {
			if (this.params && this.params.strokeWidth) {
				return this.params.strokeWidth;
			}
			return 2;
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
