<template>
	<section>
		<nice-spoiler
			v-if="resolutionList.length > 1 && fields && fields.includes('resolutions')"
			:label="$t('campaign.resolution_label') | capitalize"
			:expand="isResolutionsSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetResolutions"
		>
			<nice-tree-check
				v-model="innerResolutions"
				:tree="resolutionList"
			/>
		</nice-spoiler>


		<!-- до лучших времен -->

		<!-- <nice-spoiler
			v-if="fields && fields.includes('orientations')"
			:label="$t('campaign.orientation_label') | capitalize"
			:expand="isOrientationsSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetOrientations"
		>
			<nice-tree-check
				v-if="isTreesLoaded"
				v-model="innerOrientations"
				:tree="orientationList"
			/>
			<span v-else>{{ $t('common.loading') | capitalize }}&hellip;</span>
		</nice-spoiler> -->

		<nice-spoiler
			v-if="broadcatsTypesList.length > 1 && fields && fields.includes('broadcast_types')"
			:label="$t('campaign.type_label') | capitalize"
			:expand="isDeviceTypesSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetDeviceTypes"
		>
			<nice-tree-check
				v-model="innerDeviceTypes"
				:tree="broadcastTypesTree"
			/>
		</nice-spoiler>
	</section>
</template>


<script>
import _ from 'underscore';

import { mapState } from 'vuex';

import NiceSpoiler from '@/ui/nice-spoiler';
import NiceTreeCheck from '@/ui/nice-tree-check';


export default {
	name: 'BroadcastPoints',


	components: {
		NiceSpoiler,
		NiceTreeCheck,
	},


	props: {
		platforms: {
			type: [Array, String],
			default: 'all'
		},

		resolutions: {
			type: Array,
			default: () => [],
		},

		resolutionList: {
			type: Array,
			default: () => [],
		},

		orientations: {
			type: Array,
			default: () => [],
		},

		orientationList: {
			type: Array,
			default: () => [],
		},

		deviceTypes: {
			type: Array,
			default: () => [],
		},

		broadcatsTypesList: {
			type: Array,
			default: () => [],
		},

		fields: {
			type: Array,
			default: () => ['resolutions', 'orientations', 'broadcast_types']
		},
	},


	data() {
		return {
			innerResolutions: this.resolutions || [],
			innerOrientations: this.orientations || [],
			innerDeviceTypes: this.deviceTypes || [],


			isResolutionsSpoilerExpanded: !!(this.resolutions && this.resolutions.length),
			isOrientationsSpoilerExpanded: !!(this.orientations && this.orientations.length),
			isDeviceTypesSpoilerExpanded: !!(this.deviceTypes && this.deviceTypes.length),

		};
	},


	computed: {
		...mapState('extra', [ 'broadcastTypes' ]),

		broadcastTypesTree() {
			// const tree = _.union(
			// 	[{ value: 'all', label: 'All', parent: null, }],
			// 	_.map(this.broadcastTypes, type => {
			// 		return {
			// 			parent: 'all',
			// 			...type,
			// 		};
			// 	})
			// );

			const tree = this.broadcastTypes;
			return tree;
		},
	},


	methods: {
		resetResolutions() {
			this.$set(this, 'innerResolutions', []);
		},

		resetOrientations() {
			this.$set(this, 'innerOrientations', []);
		},

		resetDeviceTypes() {
			this.$set(this, 'innerDeviceTypes', []);
		},
	},


	watch: {
		resolutions(value, old) {
			if (_.isEqual(value, this.innerResolutions)) {
				return;
			}

			this.$set(this, 'innerResolutions', value);
		},

		orientations(value, old) {
			if (_.isEqual(value, this.innerOrientations)) {
				return;
			}

			this.$set(this, 'innerOrientations', value);
		},

		deviceTypes(value, old) {
			if (_.isEqual(value, this.innerDeviceTypes)) {
				return;
			}

			this.$set(this, 'innerDeviceTypes', value);
		},

		innerResolutions(value, old) {
			if (_.isEqual(value, this.resolutions)) {
				return;
			}

			this.$emit('update:resolutions', value);
		},

		innerOrientations(value, old) {
			if (_.isEqual(value, this.orientations)) {
				return;
			}

			this.$emit('update:orientations', value);
		},

		innerDeviceTypes(value, old) {
			if (_.isEqual(value, this.deviceTypes)) {
				return;
			}

			this.$emit('update:deviceTypes', value);
		},
	},

};
</script>


<style lang="sass" scoped>
.nice-spoiler
	box-sizing: border-box
	width: 100%
	max-width: 380px
	margin: 0

	&.ni_slider--container
		margin-top: 10px
		margin-bottom: 10px
</style>
