<template>
	<symbol :id="`${prefix}arrow_circle-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M11.0002 12.2072V8H13.0002V12.2071L14.6038 10.6035L16.018 12.0178L12.0002 16.0355L7.98242 12.0178L9.39664 10.6035L11.0002 12.2072Z" class="sign arrow" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrowCircle24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: center
</style>
