import AUDIT_STATUS_CODES from '@/constants/audit-status-codes';

export const DEFAULT_CREATIVE_AUDIT = {
	status: AUDIT_STATUS_CODES.PENDING_AUDIT,
};

export default {
	[AUDIT_STATUS_CODES.PENDING_AUDIT]: {
		label: 'pending audit',
		label_translation_id: 'pending_audit',
		actionLabel: 'reset_to_pending',
		action_label_tr_id: 'reset_to_pending',
		actionNounlabel: 'status_resetting',
		icon: 'warn',
		palette: 'yellow',
	},
	[AUDIT_STATUS_CODES.PRE_APPROVED]: {
		label: 'pre-approved',
		label_translation_id: 'pre_approved',
		actionLabel: 'pre_approve',
		action_label_tr_id: 'pre_approve',
		actionNounlabel: 'pre_approvement',
		icon: 'warn',
		palette: 'yellow',
	},
	[AUDIT_STATUS_CODES.APPROVED]: {
		label: 'approved',
		label_translation_id: 'approved',
		actionLabel: 'approve',
		action_label_tr_id: 'approve',
		actionNounlabel: 'approvement',
		icon: 'check',
		palette: 'green',
	},
	[AUDIT_STATUS_CODES.DENIED]: {
		label: 'rejected',
		label_translation_id: 'rejected',
		actionLabel: 'reject',
		action_label_tr_id: 'reject',
		actionNounlabel: 'rejection',
		icon: 'error',
		palette: 'red',
	},
	[AUDIT_STATUS_CODES.CHANGED_RESUBMISSION_REQUESTED]: {
		label: 'changed',
		label_translation_id: 'changed',
		actionLabel: 'require resubmition',
		action_label_tr_id: 'require_resubmition',
		actionNounlabel: 'resubmition_requirement',
		icon: 'warn',
		palette: 'yellow',
	},
	[AUDIT_STATUS_CODES.EXPIRED]: {
		label: 'expired',
		label_translation_id: 'expired',
		actionLabel: 'set to expired',
		action_label_tr_id: 'set_to_expired',
		actionNounlabel: 'expiration',
		icon: 'arrow_circle',
		palette: 'gray',
	},
};
