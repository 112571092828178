export default {
	install(Vue, options={}) {
		Vue.prototype.$asyncSet = async function asyncSet(instance, field, promise) {
			let data;
			if (typeof promise === 'function') {
				data = await promise();
			} else {
				data = await promise;
			}

			Vue.set(instance, field, data);
			return data;
		};
	},
};
