<template>
	<section class="page-details layout-full_width" >

		<div class="top-panel">
			<div class="left">
				<nice-button-2
					icon="arrow-14"
					icon-state="90"
					@click="goBack"
				>
					{{ $t('back') | capitalize }}
				</nice-button-2>
			</div>

			<div class="top-panel__buttons">
				<nice-button-2
					icon="cancel-24"
					:disabled="!isChanged || !instance.id"
					palette="gray"

					@click="reset"
				>
					{{ $t('reset') | capitalize}}
				</nice-button-2>

				<nice-button-2
					icon="check-24"
					:disabled="!isChanged"
					palette="green"

					@click="apply"
				>
					{{ $t('apply') | capitalize}}
				</nice-button-2>
			</div>
		</div>

		<header class="header">

			<details-header
				:title="instance.name"
				:subtitle="instance.comment"
				:edit="isSspDspEditPage"
				class="header__details-header"
				@input="updateNameAndComment"
			/>

		</header>

		<agency-card
			v-if="instance.id"
			ref="agency_card"
			v-model="instance"
			:edit="isSspDspEditPage"
		/>

		<nice-tabs
			v-if="instance.id"
		>
			<div :data-ni_tab_name="$t('common.platforms') | capitalize">
				<platforms-list
					:platforms="platforms"
					:agency="instance"
					:parentAgency="(side === 'dsp' && isSspDspEditPage) ? userAgency : null"
					@update="updatePlatforms"

				/>
			</div>

			<div
				v-if="isSuperSSP"
				:data-ni_tab_name="$t('common.users') + ' (Super SSP Only)' | capitalize"
			>
				<!-- TODO: move invitation out of users list? -->
				<users-list
					ref="users_list"
					:items="usersList"
					:agency="instance"

					:invitationShowRolesSelect="true"
					:invitationRolesList="invitationRolesList"

					@remove="removeUserOrInvitation"
					@interact="interactUser"
					@send-invitation="sendInvitation"
				/>
				<!-- @invitation-sent="invitationSent" -->
			</div>
		</nice-tabs>

	</section>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';

import {
	createChildAgency,
	requestChildAgency,
	updateChildAgency,

	// new methods
	requestAgencyInvitations,

	// SSP Super Admin only!
	requestChildAgencyUsers,
	deleteChildAgencyUser,
} from '@/api/agency';

import {
	// new
	createInvitation,
	deleteInvitation,

	INVITATION_ROLE_SSP_ADMIN,
	INVITATION_ROLE_SSP_APPROVER,
	INVITATION_ROLE_SSP_VIEWER,
	INVITATION_ROLE_DSP_ADMIN,
} from '@/api/invitation';

import NiceTabs from '@/ui/nice-tabs';

import UsersList from '@/components/users/users-list';
import AgencyCard from '@/components/users/agency-card';
import PlatformsList from '@/components/users/platforms-list';
import DetailsHeader from '@/components/users/details-header';
import { notifyOk, notifyError } from '@/plugins/notifications';


export default {
	name: 'PageAgencyDetails',


	components: {
		UsersList,
		PlatformsList,
		AgencyCard,
		DetailsHeader,
		NiceTabs,
	},


	props: {
		id: {
			type: Number,
			required: false,
			default: null,
		},

		/**
		 * the "side" of an agency we are looking at
		 * (theoretically) one agency could be SSP and DSP at the same time
		 */
		side: {
			type: String,
			required: true,
		},

		// TODO: do we need it?
		resource: {
			type: String,
			default: 'agency',
		},

		// if false then show only ssp_ or dsp_networks for this.id agency and based on this.side
		// only valid if this.side === 'dsp'
		isSspDspEditPage: {
			type: Boolean,
			default: false,
		},
	},



	data() {
		return {
			// stub for creation
			instance: {
				name: '',
				comment: '',
			},
			users: [],
			invitations: [],
			// the easy way to check if object is manipulated somehow
			isChanged: false,

			// for super ssp user invitation
			invitationRolesList: [
				{
					value: INVITATION_ROLE_DSP_ADMIN,
					label: this.$t('invitation.dsp_admin'),
				},
				{
					value: INVITATION_ROLE_SSP_ADMIN,
					label: this.$t('invitation.ssp_admin'),
				},
				{
					value: INVITATION_ROLE_SSP_APPROVER,
					label: this.$t('invitation.ssp_approver'),
				},
				{
					value: INVITATION_ROLE_SSP_VIEWER,
					label: this.$t('invitation.ssp_viewer'),
				},
			],
		};
	},


	watch: {
		instance: {
			deep: true,
			handler(value, oldValue) {
				// ignore initial $set()
				if(oldValue === null) {
					return;
				}

				this.isChanged = true;
			},
		},
	},


	computed: {
		...mapState('app', { appSide: 'side' }),
		...mapState('profile', {
			isSuperSSP: 'is_super',
		}),
		// current user's agency
		...mapState({ userAgency: 'agency' }),


		/**
		 * TODO:
		 *   1. super user can change anything - out of scope for now. use backend admin UI.
		 *   2. dsp can't change anything, can it? name, address, email, phone? most probably not.
		 *   3. ssp? only for a child DSP agency?
		 */
		// editMode() {
		// 	if (this.isSuperSSP) {
		// 		return true;
		// 	}
		//
		// 	if (this.appSide === 'dsp') {
		// 		return false;
		// 	}
		//
		// 	// instance is owned ssp-agency
		// 	return true;
		// },


		/**
		 * Platforms list
		 * Depends on agency access
		 *
		 * @return {object[]} list
		 */
		platforms() {
			if (this.side === 'ssp') {
				return this.instance.ssp_networks.slice();
			}

			return this.instance.dsp_networks.slice();
		},


		/**
		 * Combine users and invitations
		 */
		usersList() {
			let users = this.users.map((item) => {
				return {
					...item,
					'detail': item.email,
					// add type - we are using one list so we need a way to differentiate
					'type': 'user',
				};
			});

			let invitations = this.invitations.map((item) => {
				return {
					'id': item.id,
					'first_name': item.email + ' (' + this.$t('invitation.invitation') + ')',
					'last_name': '',
					'detail': this.$t('common.expires') + ' ' + item.expiration_date,
					// add type
					'type': 'invitation',
					'role': item.role,
				};
			});

			return users.concat(invitations);
		},
	},


	async created() {
		// TODO: on page reload we don't have an agency here
		//       but this way we can have 2 requests
		// TODO: `mounted()`?

		this.reset();

		if (this.isSuperSSP) {
			this.getInvitations();
			this.getUsers();
		}
	},


	methods: {
		async getInstance(id) {
			return await requestChildAgency(id);
		},


		// actions
		goBack() {
			this.$router.go(-1);
		},


		apply() {
			// creation case
			if (!this.id) {
				this.createAgency();
				return;
			}

			this.updateAgency();
		},

		async createAgency() {
			try {
				let res = await createChildAgency(this.instance);

				if (!res) {
					throw 'Empty response';
				}

				// TODO: it can be removed and `reset()` put into `mounted()`
				await this.$set(this, 'instance', res);
				this.isChanged = false;

				this.$router.push({
					name: `${this.resource}-details`,
					params: { id: res.id },
				});
				notifyOk();
			}
			catch(error) {
				this.$log.error(error);
				notifyError();
			}
		},


		async updateAgency() {
			try {
				let res = await updateChildAgency(this.id, this.instance);

				if (!res) {
					throw 'Empty response';
				}

				await this.$set(this, 'instance', res);

				this.isChanged = false;
				notifyOk();
			}
			catch(error) {
				notifyError();
			}
		},


		async reset() {
			// reset instance to saved state
			// TODO: probably reserve the instance @ created twice and here
			// TODO: we need to wait for $set() to resolve
			if (this.id) {
				await this.$set(this, 'instance', await this.getInstance(this.id));
			}
			this.isChanged = false;

			// we need to reset selected file in the contract card too
			if (this.$refs && this.$refs.agency_card) {
				this.$refs.agency_card.reset();
			}
		},


		// reverse (aka setter) for computed `platforms`
		updatePlatforms(list) {
			Vue.set(this.instance, this.side == 'ssp' ? 'ssp_networks' : 'dsp_networks', list);
		},


		/**
		 * Handler for name/comment update from details-header
		 *
		 * TODO: use v-model
		 */
		updateNameAndComment(value) {
			this.$set(this.instance, 'name', value.title);
			this.$set(this.instance, 'comment', value.subtitle);
		},


		/**
		 * Send invitation
		 *
		 * TODO:
		 *  * copied from `pages/user/details.vue`
		 */
		 async sendInvitation(data) {
			 // DSP Admin is a default role
			 let role = data.role || INVITATION_ROLE_DSP_ADMIN;
			 let email = data.email || data;

			 try {
				 let res = await createInvitation(this.id, email, role);

				 if (!res) {
					 throw this.$t('invitation.email_failed');
				 }

				 // HACK:
				 this.$refs.users_list.isInvitationSent = true;

				 await this.getInvitations();
			 }
			 catch(error) {
				 this.$log.error(error);

				 let message = this.$t('invitation.failed');
				 if(error && error.response && error.response.data && error.response.data.email) {
					 message = '';
					 // translations are on the backend
					 for (let m of error.response.data.email) {
						 message += m + '\n';
					 }
				 }

				 alert(message);
			 }
		 },


		/**
		 * TODO: several requests! reuse API
		 *
		 * For Super SSP only
		 */
		async getInvitations() {
			if (!this.id) {
				return;
			}

			let res = [
				...(await requestAgencyInvitations(this.id, 'dsp', INVITATION_ROLE_DSP_ADMIN)),
				...(await requestAgencyInvitations(this.id, 'ssp', INVITATION_ROLE_SSP_ADMIN)),
				...(await requestAgencyInvitations(this.id, 'ssp', INVITATION_ROLE_SSP_APPROVER)),
				...(await requestAgencyInvitations(this.id, 'ssp', INVITATION_ROLE_SSP_VIEWER)),
			];

			this.$set(this, 'invitations', res);
		},


		async getUsers() {
			if (!this.id) {
				return;
			}

			try {
				let res = await requestChildAgencyUsers(this.id);
				this.$set(this, 'users', res);
			}
			catch (error) {
				this.$log.error(error);
			}
		},

		async removeUserOrInvitation(item) {
			if (!confirm(`Delete ${item.first_name} ${item.last_name}?`)) {
				return;
			}

			if (item.type === 'invitation') {
				try {
					await deleteInvitation(this.id, item.id, item.role);
					await this.getInvitations();
				}
				catch(error) {
					this.$log.error(error);
					alert('Failed to delete invitation');
				}
				return;
			}

			if (item.type === 'user') {
				try {
					await deleteChildAgencyUser(this.id, item.id);
					await this.getUsers();
				}
				catch(error) {
					this.$log.error(error);
					alert('Failed to delete user');
				}
				return;
			}
		},


		/**
		 * We don't interact with invitations
		 */
		interactUser(item) {
			if (item.type === 'invitation') {
				return;
			}

			this.$router.push({
				name: 'user-details',
				params: {
					id: item.id,
					agencyId: this.instance.id,
				},
			});
		},
	},
};
</script>


<style lang="sass" scoped >
.page-details
	padding-bottom: 40px

	& > *
		margin-bottom: 40px

.top-panel
	position: sticky
	z-index: 1
	top: 0
	margin: 0
	padding: 30px
	background: #fff
	width: auto

.top-panel__buttons
	margin-left: auto
	& > *
		margin-right: 40px

		&:last-child
			margin-right: 0

.top-panel,
.actions
	display: flex
	flex-direction: row
	align-items: center
	justify-content: space-between

.header
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 24px
	width: auto

	& > *
		margin-right: 40px

		&:last-child
			margin-right: 0


.header__details-header

.header__buttons
	margin-left: auto
	& > *
		margin-right: 40px

		&:last-child
			margin-right: 0

</style>
