<template>
	<button
		:type="type"
		class="nice-button"
		:class="{
			'__bordered': bordered,
			'__watercolor': watercolor,
			'__filled': filled,
			[theme]: theme,
		}"

		v-bind="$attrs"
		:disabled="disabled"

		:style="buttonPalette"
		v-on="$listeners"
	>
		<span
			v-if="$slots.default && iconPlacement === 'end'"
			class="wizard-btn-content"
		><slot /></span>
		<nice-icon
			v-if="icon"
			class="button-icon"
			:icon="icon"
			:state="iconState"
			:disabled="disabled"
			:palette="iconPalette"
		/>
		<span
			v-if="$slots.default && iconPlacement === 'start'"
			class="wizard-btn-content"
		><slot /></span>
	</button>
</template>

<script>
import _ from 'underscore';

const ICON_PLACEMENTS = [ 'start', 'end' ];
const AVAILABLE_THEMES = [
	'default',
	'gray',
	'red',
	'blue',
	'bluedark',
	'darkblue',
];



export default {
	name: 'NiceButton',


	props: {
		disabled: {
			type: Boolean,
			default: false,
		},

		type: {
			type: String,
			default: 'button',
		},

		bordered: {
			type: Boolean,
			default: false,
		},

		watercolor: {
			type: Boolean,
			default: false,
		},

		filled: {
			type: Boolean,
			default: false,
		},

		color: {
			type: String,
			default: null,
		},

		palette: {
			type: [Object, String],
			default: null,
		},

		icon: {
			type: String,
			default: null,
		},

		iconState: {
			type: [String, Number, Boolean],
			default: null,
		},

		iconPalette: {
			type: Object,
			default: () => ({ main: 'currentColor', }),
		},

		iconPlacement: {
			type: String,
			default: 'start',
			validator(value) {
				return _.contains(ICON_PLACEMENTS, value);
			},
		},
	},


	computed: {
		buttonPalette() {
			if (this.theme || !(this.color || this.palette)) return;
			let palette = {};

			if (this.color) {
				palette = {
					default: this.color,
					active: this.color,
					hover: this.color,
					disabled: this.color,
				};
			}


			if (typeof this.palette === 'object') {
				palette = { ...palette, ...this.palette };
			}

			// convert palette keys `<state>` -> `--<state>-color`
			palette = _.reduce(_.keys(palette), (plt, key) => {
				plt[`--${key}-color`] = palette[key];
				return plt;
			}, {});

			return palette;
		},

		theme() {
			if (!_.contains(AVAILABLE_THEMES, this.palette)) return;
			return this.palette;
		},
	},
};
</script>

<style lang="sass" scoped>
$border-width: $ni_button-border-width
$border-radius: $ni_button-border-radius
$pad-x: $ni_button-padding-x - $border-width
$pad-y: $ni_button-padding-y - $border-width

.nice-button
	@extend %button__text

	--default-color: var(--main_color)
	--disabled-color: var(--disabled_color)
	--hover-color: var(--main_lighter_color)
	--active-color: var(--main_color)
	--contrast-color: #ffffff

	--pad-x: #{$pad-x}
	--pad-y: #{$pad-y}
	--border-width: #{$border-width}
	--border-radius: #{$border-radius}

	position: relative
	z-index: 0

	display: inline-flex
	min-height: 31px
	box-sizing: border-box
	flex-direction: row
	justify-content: flex-start
	align-items: center

	color: var(--default-color, currentColor)
	font-size: $fsz__small
	font-weight: 500

	padding: var(--pad-y, #{$pad-y}) var(--pad-x, #{$pad-x})
	border-radius: var(--border-radius, #{$border-radius})

	overflow: hidden


	&:hover
		color: var(--hover-color, currentColor)

	&:active
		color: var(--active-color, currentColor)

	&:disabled
		color: var(--disabled-color, currentColor)


	&.__bordered
		border: var(--border-width, #{$border-width}) solid currentColor

	&.__watercolor
		border: var(--border-width, #{$border-width}) solid transparent
		&::before
			content: ""
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			z-index: -1

			background-color: currentColor
			opacity: .2

	&.__filled
		border: var(--border-width, #{$border-width}) solid transparent
		color: var(--contrast-color, #ffffff)
		background-color: var(--default-color)

		&:hover
			background-color: var(--hover-color)
			border-color: var(--hover-color)

		&:active
			background-color: var(--active-color)
			border-color: var(--active-color)

		&:disabled
			background-color: var(--disabled-color)
			border-color: var(--disabled-color)




	& > *:not(:first-child)
		margin-left: .8em



	// themes
	&.default
		--default-color: var(--main_color)
		--disabled-color: var(--disabled_color)
		--hover-color: var(--main_lighter_color)
		--active-color: var(--main_color)

	&.gray
		--default-color: #{$nice_color-graysemidark}
		--disabled-color: #{$nice_color-graysemidark__disabled}
		--hover-color: #{$nice_color-gray}
		--active-color: #{$nice_color-graysemidark}

	&.red
		--default-color: #{$nice_color-red}
		--disabled-color: #{$nice_color-red__disabled}
		--hover-color: #{$nice_color-red}
		--active-color: #{$nice_color-red}

	&.blue
		--default-color: #{$nice_color-blue}
		--disabled-color: #{$nice_color-activebutton}
		--hover-color: #{$nice_color-bluelight}
		--active-color: #{$nice_color-blue}

	&.bluedark,
	&.darkblue
		--default-color: #{$nice_color-blue_dark}
		--disabled-color: #{$nice_color-blue_dark__disabled}
		--hover-color: #{$nice_color-blue}
		--active-color: #{$nice_color-blue_dark}

</style>
