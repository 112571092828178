<template>
	<symbol :id="`${prefix}monitor-17`" viewBox="0 0 17 14">
		<path d="M15 0H2C0.895447 0 0 0.895447 0 2V9C0 10.1046 0.895447 11 2 11H8V13H4V14H13V13H9V11H15C16.1046 11 17 10.1046 17 9V2C17 0.895447 16.1046 0 15 0ZM16 9C16 9.55139 15.5514 10 15 10H2C1.44861 10 1 9.55139 1 9V2C1 1.44861 1.44861 1 2 1H15C15.5514 1 16 1.44861 16 2V9Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMonitor17',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
