<template>
	<main class="layout-full_width">

		<h1 class="page-title">
			<nice-icon2
				icon="dashboard"
				class="page-title-icon"
			/>
			{{ $t('page_caption_index') }}
		</h1>


		<!-- START QUICK BUTTONS -->
		<div
			v-if="side === 'dsp'"
			class="quick-buttons-container"
		>

			<div class="buttons">

				<!-- Add Advertiser -->
				<router-link
					:to="{
						name: 'advertiser-add',
					}"
					class="button"
				>
					<nice-icon2 icon="man" class="icon" />
					<span class="plus-icon">+</span>

					<span class="label">{{ $t('dashboard.add_advertiser') }}</span>
				</router-link>

				<!-- Add Campaign Request -->
				<router-link
					:to="{
						name: 'request-add',
					}"
					class="button"
				>
					<nice-icon2 icon="bell" class="icon" />
					<span class="plus-icon">+</span>

					<span class="label">{{ $t('dashboard.add_request') }}</span>
				</router-link>

				<!-- Add Balance -->
				<router-link
					:to="{
						name: 'balance-add',
					}"
					class="button"
				>
					<nice-icon2 icon="money" class="icon" />
					<span class="plus-icon">+</span>

					<span class="label">{{ $t('dashboard.add_balance') }}</span>
				</router-link>

			</div>
		</div>
		<!-- END: START QUICK BUTTONS -->


		<DashboardCounters
			:side="side"
		/>

		<nice-stats
			:graphData="graphData"
			:labelData="labelData"

			:platformsList="platforms"

			:agenciesList="childAgencies"

			:campaignsList="getCampaignsChoices"

			:selectorLabels="selectorLabels"

			:impressions="impressions"
			:spent="spent"
			:adPlays="adPlays"

			:formatHint="formatHint"

			@range-updated="rangeUpdated"
			@selected="selectedGraph = $event"
		/>
	</main>
</template>


<script>
import _ from 'underscore';
import { mapState, mapActions } from 'vuex';

import { requestChildAgencies } from '@/api/agency';

import { formatCash, normalizeValue } from '@/utilites';
import DashboardCounters from '@/components/dashboard-counters';
import NiceStats from '@/ui/nice-stats';
import {
	getStatsGraphLabels,
	getStatsGraph,
	TIMESTAMP_KEY_FORMAT,
} from '@/ui/nice-stats/utils';
import { ROLE_SSP_ADMIN } from '@/constants/roles';


// TODO: localize
const DSP_SELECTOR_LABELS = ['impressions', 'spent', 'ad_plays'];
const SSP_SELECTOR_LABELS = ['impressions', 'income', 'ad_plays'];


export default {
	name: 'Dashboard',


	components: {
		NiceStats,
		DashboardCounters,
	},


	data() {
		return {
			graphData: [],
			labelData: [],

			platforms: [],
			childAgencies: [],
			campaigns: [],

			filterCampaigns: '',

			currency: '',
			selectedGraph: 0,

			impressions: 0,
			spent: 0,
			adPlays: 0,
		};
	},


	async mounted() {
		// populate platforms and child agency filters for SSP
		if (this.currentRole === ROLE_SSP_ADMIN) {
			this.$set(this, 'platforms', this.getPlatformChoices(this.ssp_networks));
			this.$set(this, 'childAgencies', await this.getChildAgencyChoices());
		}
	},


	computed: {
		...mapState('app', {
			side: 'side',
			currentRole: 'role',
		}),

		...mapState('agency', {
			userAgency: 'agency',
			ssp_networks: 'ssp_networks',
		}),

		...mapState('profile', {
			profileLanguage: 'language',
			profileDateFormat: 'date_format',
			profileTimeFormat: 'time_format',
		}),

		selectorLabels() {
			let selectorLabels = DSP_SELECTOR_LABELS.map(key => this.$t(key));
			if (this.side === 'ssp') {
				selectorLabels = SSP_SELECTOR_LABELS.map(key => this.$t(key));
			}

			return selectorLabels;
		},
	},


	methods: {
		...mapActions('campaign', {
			requestCampaignList: 'requestList',
		}),


		/**
		 * Update stats graphs
		 *
		 * rangeType - { hourly, daily, weekly, monthly }
		 */
		async rangeUpdated({ rangeType, dateFrom, dateTill, platforms, agency, campaign }) {
			let labels = getStatsGraphLabels(rangeType, dateFrom, dateTill);
			let graphsData = await getStatsGraph(rangeType, dateFrom, dateTill, platforms, agency, campaign);

			let graphs = [
				graphsData.impressions,
				graphsData.spentIncome,
				graphsData.adPlays,
			];

			// fill sparse data with 0's
			let processedGraphs = [ [], [], [] ];

			for (let l of labels) {
				let key = l.format(TIMESTAMP_KEY_FORMAT);

				// impressions, spent / income, ad plays
				for (let i = 0; i < graphs.length; i++) {
					let value = 0;
					if (_.has(graphs[i], key)) {
						value = graphs[i][key];
					}
					processedGraphs[i].push(value);
				}

			}

			// format labels
			let formatedLabels = [];
			for (let l of labels) {
				formatedLabels.push( l.format('ddd DD MMM') );
			}

			// set graphs
			this.$set(this, 'graphData', processedGraphs);
			this.$set(this, 'labelData', formatedLabels);

			let totalSpentInCurrency = formatCash(
				graphsData.meta.total_spent_income_in_currency,
				graphsData.meta.currency
			);

			// set totals
			let totalImpressions = normalizeValue(graphsData.meta.total_impressions);
			if (isNaN(totalImpressions)) {
				totalImpressions = '';
			}
			this.$set(this, 'impressions', totalImpressions);
			//this.$set(this, 'spent',  `${totalSpent} (${totalSpentInCurrency})`);
			this.$set(this, 'spent',  totalSpentInCurrency);
			this.$set(this, 'adPlays', graphsData.meta.total_ad_plays);
			this.$set(this, 'currency', graphsData.meta.currency);
		},


		/**
		 * For platform filter dropdown
		 */
		getPlatformChoices(networks) {
			return networks.map((item) => {
				return {
					value: item.id,
					label: item.name,
				};
			});
		},


		/**
		 * For DSP agency filter dropdown
		 */
		async getChildAgencyChoices() {
			let agencies = await requestChildAgencies();

			return agencies.map((item) => {
				return {
					value: item.id,
					label: item.name,
				};
			});
		},


		/**
		 * Request filtered campaigns
		 */
		async getCampaignsChoices(params) {

			let campaigns = await this.requestCampaignList({
				filters: {
					search: params.query || '',
				},
				force: true,
			});

			return _.map(campaigns, category => {
				return {
					value: category.id,
					label: category.name,
				};
			});
		},

		/**
		 * Format hint inside the NiceStats graph
		 *
		 * Default is:
		 * <p class="content">
		 * 	<span class="label">{{ units || "Label" }}</span>
		 * 	<span class="value">{{ dataset.value[over] }}</span>
		 * </p>
		 *
		 * TODO: coppied from tab-status.vue
		 * TODO: do it via some components render function!
		 * TODO: currency is from a state
		 */
		formatHint(graph) {
			if (this.selectedGraph == 0 || this.selectedGraph == 2) {
				return '<span class="value">' + graph.dataset.value[graph.over] + '</span>';
			}

			let spent = formatCash(
				graph.dataset.value[graph.over],
				this.currency
			);

			return spent;
		},

	},  // END METHODS

};
</script>

<style lang="sass" scoped>
.quick-buttons-container
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	margin-top: 20px
	margin-bottom: 40px

	.subtitle
		font-weight: 400
		font-size: 16px
		line-height: 19px
		margin-bottom: 20px

	.buttons
		display: flex

		.button
			flex-grow: 1
			position: relative
			display: inline-block
			min-width: 100px
			height: 90px
			border: 1px solid $nice_color-gray_light_semi
			border-radius: 10px
			font-weight: 400
			font-size: 14px
			line-height: 16px
			text-decoration: none
			color: black
			min-width: fit-content

			&:hover
				color: var(--main_color)

			&:not(:last-child)
				margin-right: 10px

			.icon
				--ni-icon-sign: var(--main_color)
				position: absolute
				left: 14px
				top: 14px
				height: 20px
				width: 18px

			.plus-icon
				position: absolute
				right: 14px
				top: 14px
				font-weight: 400
				font-size: 20px
				line-height: 20px
				color: #919191

			.label
				display: flex
				flex-direction: column
				justify-content: flex-end
				padding: 0 14px 0
				height: 78px
				width: 105px


.page-title
	font-weight: 400
	font-size: 18px
	line-height: 21px
	text-transform: capitalize

	.page-title-icon
		--ni-icon-sign: black
		vertical-align: middle
		margin-left: -8px
</style>
