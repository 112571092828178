<template>
	<symbol :id="`${prefix}plus-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12ZM17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z" class="sign" />
		<path d="M11 11V9H13V11H15V13H13V15H11V13H9V11H11Z" class="sign cross" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlus24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})

.cross
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: 50% 50%
</style>
