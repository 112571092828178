<template>
	<div class="toggling-input">
		<template v-if="editMode">
			<nice-input
				ref="input"
				v-model="innerValue"
				class="field"
				v-bind="$attrs"
				:units="units"

				@keypress.enter="apply"
				@keypress.esc="cancel"
			/>
			<nice-button-2
				icon="check-24"
				@click="apply"
			/>
			<nice-button-2
				icon="error-24"
				@click="cancel"
			/>
		</template>

		<template v-else>
			<div
				class="value"
				@click="enterEditMode"
			>{{ innerValue }} <span v-if="units" class="units">{{ units }}</span></div>
			<nice-button-2
				icon="pencil-13"
				@click="enterEditMode"
			/>
		</template>
	</div>
</template>


<script>
import NiceInput from '@/ui/nice-input';
import Vue from 'vue';



const MODEL = Object.freeze({
	prop: 'value',
	event: 'input',
});



export default {
	name: 'TogglingInput',

	components: {
		NiceInput,
	},

	model: MODEL,

	props: {
		[MODEL.prop]: {
			type: [String, Number],
		},

		units: {
			type: String,
			default: null,
		}
	},


	data() {
		return {
			innerValue: this[MODEL.prop],
			editMode: false,
		};
	},


	methods: {
		enterEditMode() {
			this.editMode = true;
			this.$nextTick(() => {
				if (this.$refs.input && typeof this.$refs.input.focus === 'function') {
					this.$refs.input.$refs.input.$el.select();
					this.$refs.input.focus();
				}
			});
		},

		apply() {
			this.editMode = false;
			this.$emit(MODEL.event, parseFloat(this.innerValue));
		},

		cancel() {
			Vue.set(this, 'innerValue', this[MODEL.prop]);
			this.editMode = false;
		},
	},


	watch: {
		[MODEL.prop]() {
			if (!this.editMode) {
				Vue.set(this, 'innerValue', this[MODEL.prop]);
			}
		}
	},


	created() {
		Vue.set(this, 'innerValue', this[MODEL.prop]);
	},
};
</script>


<style lang="sass" scoped>
.toggling-input
	display: flex
	align-items: center

	& > *:not(:last-child)
		margin-right: 16px

.field
	flex-grow: 1
	flex-shrink: 1

.field::v-deep input
	background: none
</style>
