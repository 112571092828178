<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 14 14">
		<path
			:fill="disabled ? colors.disabled : colors.main"
			d="M9,2.4L7.6,3.8L9.8,6H0.3v2h9.5l-2.2,2.2L9,11.6L13.7,7L9,2.4z"
			:style="{
				transform: `rotate3d(0, 0, 1, ${angle}deg)`,
				transformOrigin: 'center',
			}"
		/>
	</symbol>
</template>

<script>
export const slug = 'arrow';


export default {
	name: 'NiIconArrow',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				disabled: this.$palette.disabled_color,
				...this.palette,
			};
		},

		angle() {
			let angle = this.state;
			switch(angle) {
			case 'forward':
			case 'next':
			case 'right':
				angle = 0;
				break;

			case 'bottom':
			case 'down':
				angle = 90;
				break;

			case 'back':
			case 'prev':
			case 'left':
				angle = 180;
				break;

			case 'top':
			case 'up':
				angle = 270;
				break;
			}

			return angle;
		},
	},
};
</script>
