<template>
	<transition name="modal-transition">
		<nice-modal
			@close="close"
		>
			<section class="modal">
				<div class="message">
					{{ $t('campaign.approve_creatives_first_warning') }}
				</div>

				<div class="buttons">
					<nice-button-2
						@click="close"
					>
						{{ $t('close') | capitalize }}
					</nice-button-2>
				</div>
			</section>
		</nice-modal>
	</transition>
</template>

<script>
import NiceModal from '@/ui/nice-modal';


export default {
	name: 'ApproveCreativesWarningModal',


	components: {
		NiceModal,
	},


	data() {
		return {};
	},


	methods: {
		/**
		 * Close the modal
		 */
		close() {
			this.$emit('close');
		},

	}
};
</script>


<style lang="sass" scoped >
.modal
	// background-color: var(--main_color)
	display: flex
	flex-direction: column
	padding: 30px 40px
	box-sizing: border-box
	flex-grow: 0
	overflow: auto
	min-height: 100px
	max-width: 480px
	max-height: calc(100vh - 160px)

	.message
		// color: white
		flex-grow: 1
		text-align: center
		font-size: $fsz__section-title
		margin-top: 24px

	.buttons
		margin-top: 24px
		display: flex
		flex-direction: row
		align-items: center
		justify-content: flex-end

		.nice-button
			margin-left: 24px
</style>
