export default {
	namespaced: true,

	state: {
		showHelpModal: false,
	},

	mutations: {
		setShowHelpModal(state, isShown) {
			state.showHelpModal = isShown;
		},
	},
};