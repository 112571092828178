<template>
	<section class="page-section" >
		<h1>NiceLabel</h1>

		<p>
			<nice-label
				label="Label No1"
				hint="Short hint"
				for="test_input"
			/>
			<nice-label
				label="Label No2 without hint"
				for="test_input"
			/>
			<nice-label
				label="Label No3 with hint showed by click"
				hint="Hint showing by click"
				hint-trigger="click"
				for="test_input"
			/>
			<nice-label
				label="Label No4 with sloted hint"
				for="test_input"
			>
				hint
			</nice-label>
			<nice-label
				label="Label No4 with very very very very very very long label"
				class="very_long_label"
				hint="Short hint"
				for="test_input"
			/>
		</p>
		<p>
			<nice-input
				id="test_input"
				placeholder="input test for attribute for"
			/>
		</p>

		<h2>Подсказки:</h2>
		<p>Слот <b><i>default</i></b> выводится в подсказку</p>
		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div class="cl-pad cl-xs4 cl-md3" >
					<nice-label
						label="Label with hint"
						hint="Short hint"
					/>
				</div>
				<div class="cl-pad cl-xs4 cl-md3" >
					<nice-label
						label="Simple sloted hint"
					>
						simple hint slot
					</nice-label>
				</div>
				<div class="cl-pad cl-xs4 cl-md3" >
					<nice-label
						:label="`Sloted hint: ${testNo1}`"
					>
						<nice-slider v-model="testNo1" class="test_sloted_label" />
					</nice-label>
				</div>
			</div>
		</div>

		<div class="cl-container">
			<div class="cl-row cl-pad-no_sides">
				<div class="cl-pad cl-xs4 cl-md3" ><pre class="code-preview" >&lt;nice-label
	label="Label with hint"
	hint="Short hint"
/&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-md3" ><pre class="code-preview" >&lt;nice-label
	label="Simple sloted hint"
&gt;
	simple hint slot
&lt;/nice-label&gt;</pre></div>
				<div class="cl-pad cl-xs4 cl-md3" ><pre class="code-preview" >&lt;nice-label
	label="Label with hint"
	hint="Short hint"
/&gt;</pre></div>
			</div>
		</div>
	</section>
</template>

<script>
import NiceInput from '@/ui/nice-input';
import NiceSlider from '@/ui/nice-slider';


export default {
	name: 'TabLabel',


	components: {
		NiceInput,
		NiceSlider,
	},


	data() {
		return {
			testNo1: 15,
		};
	},
};
</script>

<style lang="sass" scoped >
.test_sloted_label
	width: 150px

.very_long_label
	width: 150px
</style>
