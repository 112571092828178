/**
 * https://www.guipss.com/posts/vuex_and_vue_i18n/
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages } from '@/locales';

import {
	LOCALSTORAGE_LOCALE_NAME,
	DEFAULT_LOCALE,
} from '@/constants/locale.js';


Vue.use(VueI18n);


//const VueI18n_getChoiceIndex = VueI18n.prototype.getChoiceIndex;
/**
 * https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
 *
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 */

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
	// this === VueI18n instance, so the locale property also exists here
	// TODO: strange error for EN
	//if (this.locale !== 'ru') {
	//	return VueI18n_getChoiceIndex.call(this, choice, choicesLength);
	//}
	if (choice === 0) {
		return 0;
	}
	const teen = choice > 10 && choice < 20;
	const endsWithOne = choice % 10 === 1;
	if (!teen && endsWithOne) {
		return 1;
	}
	if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
		return 2;
	}
	return (choicesLength < 4) ? 2 : 3;
};


export const i18n = new VueI18n({
	locale: localStorage.getItem(LOCALSTORAGE_LOCALE_NAME) || DEFAULT_LOCALE,
	fallbackLocale: DEFAULT_LOCALE,
	messages,
	silentTranslationWarn: process.env.VUE_APP_SILENT_TRANSLATION_WARN,
});
