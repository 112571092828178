<template>
	<symbol :id="`${prefix}play`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" class="base" />
		<path d="M10.6154 7.27823C11.1282 7.59901 11.1282 8.40099 10.6154 8.72177L7.15385 10.8871C6.64103 11.2079 6 10.8069 6 10.1653L6 5.83468C6 5.1931 6.64103 4.79211 7.15385 5.1129L10.6154 7.27823Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlay',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-green})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-white})
</style>
