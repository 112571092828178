<template>
	<label
		class="nice-checkbox"
		:class="{
			'labeled': labeled || label,
		}"
		:value="val"
		@click.stop="$emit('click', $event)"
	>
		<span
			v-if="label"
			class="ni-label"
			@click.stop="noop"
		>{{ label }}</span>
		<input
			:id="id"
			ref="input"
			v-model="val"
			type="checkbox"
			class="ni-chbx-input"
			:name="name"
			:disabled="disabled"
		/>
		<nice-icon
			:icon="icon"
			:state="val ? 'checked' : 'unchecked'"
			:disabled="disabled"
		/>
		<span
			v-if="caption"
			class="ni-chbx-caption"
			:title="title || caption"
			@click.stop="noop"
		>{{ caption }}</span>
	</label>
</template>

<script>
export default {
	name: 'NiceCheckbox',


	props: {
		labeled: { type: Boolean, default: false, },
		label: { type: String },
		id: { type: String, default: '', },
		name: { type: String },
		title: { type: String },
		caption: { type: String },
		disabled: { type: Boolean, default: false, },
		value: {
			type: [Boolean, String, Number],
			default: false,
		},
		icon: { type: String, default: 'chbx-sqr' }
	},


	data() {
		let value;
		switch (this.value) {
		case true:
		case 'true':
		case 'True':
		case 'on':
		case 1:
		case '1':
		case 't':
		case 'T':
		case 'y':
		case 'yes':
		case 'yeah':
		case 'yup':
		case 'certainly':
		case 'uh-huh':
			value = true;
			break;

		default:
			value = false;
			break;
		}

		return {
			val: value || false,
		};
	},


	computed: {
		input() { return this.$refs.input; },
		focus() { return this.$refs.input.focus; },
	},


	watch: {
		value(value) {
			this.val = value;
		},

		val() {
			this.$emit('input', this.val);
		},
	},


	methods: {
		// onInput() {
		// 	this.$emit('input', this.val);
		// },

		noop() {},
	},
};
</script>

<style lang="sass" scoped >
.nice-checkbox
	@extend %ui-nice-component

	border-bottom: 1px solid transparent
	margin-bottom: 6px

	&.labeled
		padding-top: $ui-nice__labeled-without-label-padding-top

	> .nice-icon
		display: block
		flex-shrink: 0
		cursor: pointer

.ni-chbx-input
	display: none

	&:disabled + .nice-icon
		cursor: default

.niсe-icon__chbx-sqr
	width: 20px
	height: 20px

.niсe-icon__chbx-sqr
	width: 14px
	height: 14px

.ni-chbx-caption
	display: block
	font-size: $fsz__normal
	line-height: $ui-nice__input-heigth
	white-space: nowrap
	text-overflow: ellipsis
	user-select: none
	overflow: hidden
	margin-left: 9px
	cursor: pointer
	// pointer-events: none

// TODO: OLD: remove?
// .__chbx-checked_box
// 	fill: var(--main_color)
//
// .__chbx-checked_mark
// 	stroke: #ffffff
//
// .__chbx-unchecked_box
// 	stroke: $clr__gray


</style>
