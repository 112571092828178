/**
 * Options
 *
 * Prop options must be Array or Function then return Array on request
 */

import _ from 'underscore';


/**
 * Convert value option to right format
 */
export const optionFromValue = (value) => {
	return {
		value: value,
		label: `${value}`,
	};
};

/**
 * Convert raw option to right format
 */
export const rawToOption = (raw) => {
	if (typeof raw != 'object') return optionFromValue(raw);

	return {
		...optionFromValue(raw.value),
		...raw,
	};
};


export default {
	props: {
		/**
		 * List of options
		 * * value - string, integer or object value
		 * * label - text label
		 */
		options: {
			type: [Array, Function],
			default: () => []
		},

		/**
		 * Force enable filtering
		 */
		filter: {
			type: Boolean,
			default: false
		},

		/**
		 * Placeholder for filter input
		 */
		filterPlaceholder: {
			type: String,
		},

		/**
		 * Critical Options count to automatic enable filtering
		 */
		criticalOptionCount: {
			type: Number,
			default: 20
		},
	},


	data() {
		return {
			// requested options if prop options is function for request them
			requestedOptions: [],
			filterQuery: null,
		};
	},


	computed: {
		/**
		 * Return list of options
		 */
		optionList() {
			let result = [];

			if (this.options instanceof Array) {
				result = _.map(this.options, rawToOption);

				if (this.filterQuery) {
					const filterQuery = this.filterQuery.toLowerCase();

					result = _.filter(result, option => {
						return option.label.toLowerCase().includes(filterQuery);
					});
				}
			}
			else if (typeof this.options == 'function') {
				if (this.requestedOptions.length) {
					result = _.map(this.requestedOptions, rawToOption);
				}
			}

			return result;
		},

		/**
		 * Calc enable or not search by options names
		 */
		searchIsEnabled() {
			const filterExist = Boolean(this.filter);
			const optionsRequested = this.options instanceof Function;
			const optionsAmoutCritical = this.optionList.length > this.criticalOptionCount;

			return filterExist || optionsRequested || optionsAmoutCritical;
		},
	},


	methods: {
		/**
		 * Handler to request options
		 *
		 * Must be async to not set Promise to requestedOptions
		 */
		async requestOptions() {
			this.requestedOptions = await this.options(this.filterQuery ? { query: this.filterQuery } : {});
		},
	},


	watch: {
		/**
		 * Recalculate selected options on options change
		 */
		async options(value, oldValue) {
			if (value.length != oldValue.length) {
				await this.recalcSelectedOptions();
			}
		},
	},
};
