<template>
	<symbol :id="`${prefix}mark-14`" viewBox="0 0 11 14">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			class="sign"
			d="M1.60826 1.62521C3.7538 -0.541255 7.24493 -0.542219 9.39142 1.62521C11.4363 3.68904 11.5455 7.02789 9.63909 9.22568L5.49984 14L1.36059 9.22568C-0.545345 7.02837 -0.436217 3.68953 1.60826 1.62521ZM7 5.5C7 6.32842 6.32843 7 5.5 7C4.67157 7 4 6.32842 4 5.5C4 4.67157 4.67157 4 5.5 4C6.32843 4 7 4.67157 7 5.5Z"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMark14',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray})
</style>
