import Vue from 'vue';
import Vuex from 'vuex';
// import router from '@/router';
import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules,

	state: {},

	mutations: {},

	actions: {
		/**
		 * call clear action in each module if exists
		 */
		logout({ dispatch }) {
			for(let m in modules) {
				let module = modules[m];

				if (!module.actions) {
					continue;
				}

				let actionNames = Object.keys(module.actions);
				if (actionNames.indexOf('clear') !== -1) {
					Vue.$log.debug(`calling clear for module ${m}`);
					dispatch(`${m}/clear`);
				}
			}

			this.$router.push({name: 'login'}, () => {
				// reset router this way
				window.location.reload();
			});
		},
	},
});


// watch locale change and update <html lang="...">
store.subscribe((mutation, state) => {
	if (mutation.type == 'profile/setLanguage' && document) {
		try {
			document.getElementsByTagName('html')[0].setAttribute('lang', mutation.payload);
		}
		catch (err) {
			// TODO: log it?
			// console.error(`Failed to set <html lang="${mutation.payload}">`);
		}
	}
});


export default store;
