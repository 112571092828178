<template>
	<main class="page-auth">
		<section class="auth-container">
			<h2>
				{{ $t('reset_password.reset_password_title') }}
			</h2>
			<form
					v-if="!showSuccessMessage"
					class="auth-form"
					@submit.prevent="resetPasswordRequest"
			>
				<div class="description">
					{{ $t('reset_password.reset_password_description') }}
				</div>
				<nice-input
						v-model="email"
						type="text"
						:placeholder="$t('common.type_email')"
						:label="$t('common.email')"

						autocomplete="email"
				/>
				<nice-label
						v-if="error"
						class="label-detail"
						:label="error"
						:error="true"
				/>

				<div class="buttons-container">
					<nice-button-2
							type="submit"
							icon="arrow-14"
							:icon-state="-90"
							icon-placement="end"
							icon-painted
							:disabled="!email"
							class="submit_button"
					>
						{{ $t('common.send') | capitalize }}
					</nice-button-2>
				</div>
				<div class="buttons-container container-back">
					<button type="button"
									class="login-button"
									@click="$router.push({name: 'login'})"
					>
						{{ $t('common.back') }}
					</button>
				</div>
			</form>
			<div v-else>
				{{ $t('reset_password.sent_link_to_reset_password') }}
			</div>
		</section>
	</main>
</template>

<script>
import {mapActions} from 'vuex';

import NiceInput from '@/ui/nice-input.vue';

export default {
	name: 'ResetPassword',

	components: {
		NiceInput,
	},


	data() {
		return {
			email: '',
			showSuccessMessage: false,

			error: null
		};
	},

	methods: {
		...mapActions('profile', ['resetPassword']),

		async resetPasswordRequest() {
			try {
				this.error = null;

				await this.resetPassword({
					email: this.email
				});

				this.showSuccessMessage = true;
			} catch (error) {
				this.error = this.getError(error.response);
			}
		},

		getError(error) {
			if (error && error.status === 400) {
				if (error.data[0]) {
					return error.data[0];
				}
			}

			return this.$t('errors.unexpected_error');
		}
	}
};
</script>

<style lang="sass" scoped>
main.page-auth
	display: flex
	align-items: center
	justify-content: center

	min-height: 100vh

	h2
		margin: 0 0 10px

.auth-container
	margin: 0 auto
	width: 100%
	max-width: 450px
	padding: 60px
	box-sizing: border-box

.auth-form
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch

	> *
		margin-top: $ui-grid__mgn-top

.buttons-container
	margin-top: 30px
	display: flex
	flex-direction: row
	justify-content: space-between

.register-button
	align-self: flex-start

.submit_button
	align-self: flex-end

.label-detail
	font-size: 90%

.login-button
	margin-top: 5px
	padding: 0
	background: none
	border: none
	text-align: left
	color: var(--text_2_color)
	cursor: pointer

.container-back
	margin-top: 0

.description
	color: var(--text_2_color)

</style>
