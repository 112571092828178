<template>
	<symbol :id="`${prefix}see-34`" viewBox="0 0 34 34">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M8 17L9.73438 15.0806C13.7471 10.6398 20.253 10.6398 24.2656 15.0806L26 17L24.2656 18.9194C20.253 23.3602 13.7471 23.3602 9.73438 18.9194L8 17ZM14.75 17C14.75 18.2426 15.7574 19.25 17 19.25C18.2426 19.25 19.25 18.2426 19.25 17C19.25 15.7573 18.2426 14.75 17 14.75C15.7574 14.75 14.75 15.7573 14.75 17Z" class="sign" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0 17C0 7.62598 7.62598 0 17 0C26.374 0 34 7.62598 34 17C34 26.374 26.374 34 17 34C7.62598 34 0 26.374 0 17ZM2 17C2 25.2715 8.729 32 17 32C25.2715 32 32 25.2715 32 17C32 8.729 25.2715 2 17 2C8.729 2 2 8.729 2 17Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolSee34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
