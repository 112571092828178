<template>
	<div class="details-header">
		<span class="abbr">{{ abbr || '?' }}</span>
		<div class="text-content">
			<div class="title">
				<nice-input
					v-if="editAll"
					id="details-header-title"
					v-model="instance.title"
				/>
				<!-- class="cl-xs6 cl-md3 cl-pad" -->
				<div v-else class="value">{{ instance.title ? instance.title : '—' }}</div>
			</div>
			<div class="subtitle">
				<nice-input
					v-if="editAll"
					v-model="instance.subtitle"
					:placeholder="$t('users.comment')"
				/>
				<!-- class="cl-xs6 cl-md3 cl-pad" -->
				<div v-else class="value">{{ instance.subtitle ? instance.subtitle : '' }}</div>
			</div>
		</div>
	</div>
</template>


<script>
import { mapState } from 'vuex';

import NiceInput from '@/ui/nice-input';


export default {
	name: 'DetailsHeader',

	components: {
		NiceInput,
	},

	props: {
		title: {
			type: String,
			default: '',
		},

		subtitle: {
			type: String,
			default: '',
		},

		edit: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			instance: {
				title: this.title,
				subtitle: this.subtitle,
			},
		};
	},

	computed: {
		...mapState('profile', {
			isSuperSSP: 'is_super',
		}),

		/**
		 * Super SSP can edit everything
		 * SSP can assign networks
		 */
		editAll() {
			return this.edit && this.isSuperSSP;
		},

		abbr() {
			if (!this.instance.title) {
				return '';
			}

			let result = '';
			const words = this.instance.title.split(/\s+/ig);

			if (words.length) {
				result = result + words.shift().charAt(0);
			}

			if (words.length) {
				result = result + words.pop().charAt(0);
			}

			return result;
		},
	},

	watch: {
		title(value) {
			if(value === this.instance.title) {
				return;
			}
			this.$set(this.instance, 'title', value);
		},

		subtitle(value) {
			if(value === this.instance.subtitle) {
				return;
			}
			this.$set(this.instance, 'subtitle', value);
		},

		instance: {
			deep: true,
			handler(value) {
				this.$emit('input', value);
			},
		},
	},
};
</script>


<style lang="sass" scoped>
.details-header
	display: flex
	flex-direction: row
	align-items: center

.title
	.nice-input /deep/ .ni_input--input
		font-size: $fsz__new__title

.subtitle

	.nice-input
		margin-top: 5px

.abbr
	width: 72px
	height: 72px
	border-radius: 50%
	text-align: center
	line-height: 70px
	color: var(--main_color)
	flex-shrink: 0
	font-size: 36px
	font-weight: 200
	border: 1px solid currentColor

.text-content
	flex-grow: 1
	font-size: 24px
	line-height: 28px
	margin-left: 16px

	> .subtitle
		color: var(--text_2_color)
		font-size: 16px
</style>
