/**
 * New (DSP - SSP bound) list of advertisers
 */
import store from '@/store';
import baseRest from './base/rest';


const getPrefix = () => `agency/${store.state.app.agency}/${store.state.app.side}/advertiser/`;

// TODO: return it as is?
const baseAdvertiserApi = baseRest(getPrefix);


export default {
	getPrefix,

	/**
	 * Create an advertiser
	 *
	 * Only for a DSP agency
	 *
	 * @param {Object(name, email, phone, contact_details, site, ssp_agency)}
	 */
	'create': baseAdvertiserApi.create,

	/**
	 * Request advertiser list
	 *
	 * @returns {Object[]} list of advertisers
	 */
	'list': baseAdvertiserApi.list,

	/**
	 * Retrieve an advertiser
	 *
	 * @param {number} id
	 * @param {object} config
	 */
	'retrieve': baseAdvertiserApi.retrieve,

	/**
	 * Update an advertiser (status too)
	 *
	 * Only for an SSP agency
	 *
	 * @param {number} id
	 * @param {object} config
	 */
	'patch': baseAdvertiserApi.patch,

	/**
	 * Delete an advertiser
	 *
	 * @param {number} id
	 * @param {object} config
	 */
	'delete': baseAdvertiserApi.delete,
};
