<!--
It is only a thin wrapper to change styling and hook in a little bit
-->
<template>
	<label
		class="nice-datetime"
		:class="{
			'labeled': labeled || label,
			'label_holder': labeled && !label,
		}"
	>
		<nice-label
			v-if="label"
			class="ni_datetime--label"
			:label="label"
			:hint="hint"
			:error="error.message || error.is"
		/>

		<vue-ctk-date-time-picker
			v-model="datetime"
			:label="placeholder"
			:no-label="noPlaceholder"
			:hint="hint"
			:error="false"
			:no-value-to-custom-elem="noValueToCustomElem"
			:only-time="onlyTime"
			:only-date="onlyDate"
			:range="range"
			:format="format"
			:formatted="formatted"
			:output-format="outputFormat"
			:minute-interval="minuteInterval"
			:min-date="minDate"
			:max-date="maxDate"
			:first-day-of-week="firstDayOfWeek"
			:inline="inline"
			:color="$palette.main_color"
			:no-shortcuts="noShortcuts"
			:locale="$i18n.locale"
			class="ni_datetime--datepicker"
			@input="onInput"
		><slot></slot></vue-ctk-date-time-picker>
	</label>
</template>


<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

const MODEL = Object.freeze({
	prop: 'value',
	event: 'input',
});


export default {
	name: 'NiceDatetime',


	components: {
		VueCtkDateTimePicker,
	},


	model: MODEL,


	props: {
		[MODEL.prop]: { type: [String, Object], default: null, },

		// label
		labeled: Boolean,
		label: String,

		placeholder: { type: String, default: 'Select date & time' },
		noPlaceholder: Boolean,
		hint: String,
		// error: { type: Boolean, default: Boolean },
		// don't set value to the custom element (in the slot)
		noValueToCustomElem: {
			default: false,
		},
		onlyTime: Boolean,
		onlyDate: Boolean,
		range: Boolean,
		// format: { type: String, default: 'YYYY-MM-DD hh:mm a' },/
		format: String,
		formatted: { type: String, default: 'llll' },
		// outputFormat: String,
		outputFormat: String,
		minuteInterval: { type: [String, Number], default: 1 },
		minDate: String,
		maxDate: String,
		firstDayOfWeek: { type: Number, default: null },
		inline: Boolean,
		color: { type: String, default: '#0363cd' },
		noShortcuts: Boolean,

		// validation props
		// invalid
		invalid: {
			type: [Boolean, String],
			default: false,
		},

		// validator - a function
		validator: {
			type: Function,
			default: null,
		},

		// required
		required: Boolean,
	},


	data() {
		return {
			datetime: this[MODEL.prop],

			validatorResult: { is: false, message: null },
		};
	},


	computed: {
		/**
		 * Error compyted property
		 * @return {Object} result
		 * @return { } [description]
		 */
		error() {
			let is = false;
			let message = null;

			// check required && no value
			if (this.required) {
				if (!this.datetime || this.datetime && !(this.datetime.start && this.datetime.end)) {
					is = true;
					message = this.$t('errors.input_required_empty');

					return { is, message };
				}
			}

			// check if prop invalid
			if (this.invalid) {
				is = true;
				message = typeof this.invalid === 'string' ? this.invalid : null;

				return { is, message };
			}


			if (this.validatorResult && this.validatorResult.is) {
				return this.validatorResult;
			}

			return { is, message };
		},
	},


	methods: {
		onInput(value) {
			this.$emit(MODEL.event, value);
		},


		// applies validator from props
		applyValidator() {
			let is = false;
			let message = null;

			if (typeof this.validator === 'function') {
				try {
					is = this.validator(this.datetime);
				} catch(error) {
					is = true;
					message = error;
				}
			}

			return { is, message };
		},
	},


	watch: {
		[MODEL.prop]: function(newValue, oldValue) {
			this.datetime = newValue;

			this.$set(this, 'validatorResult', this.applyValidator(newValue));
		},
	},
};
</script>


<style lang="sass" scoped >
.nice-datetime
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch
	box-sizing: border-box

	border-bottom: 1px solid $nice_color-gray

	&.label_holder
		padding-top: $ni-label-height

.ni_datetime--label
	align-self: flex-start

.ni_datetime--datepicker
	line-height: initial
	height: $ni-input-height  // $ui-nice__input-heigth

	::v-deep
		.field-input
			border-radius: 0
			height: $ni-input-height  // $ui-nice__input-heigth
			min-height: $ni-input-height  // $ui-nice__input-heigth
			line-height: $ni-input-height  // $ui-nice__input-heigth
			border: none !important
			// border-bottom: 1px solid $clr__darkgray
			padding: 0

			&::placeholder
				color: var(--text_2_color)
				font-size: inherit
				font-family: inherit
				line-height: inherit

				user-select: none

				transition: opacity .2s ease

		.field-clear-button
			// we have some styles overlap so need to move it
			right: -2px

		.has-value
			.field-input
				padding-top: 0 !important

			.field-label
				display: none

		.datepicker-buttons-container

			.datepicker-button

				.datepicker-button-effect
					background: var(--main_color)

				svg
					fill: var(--main_color)
</style>
