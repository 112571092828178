<template>
	<section class="layout-full_width" >
		<h1>NiceDatetime</h1>

		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-datetime
					class="cl-pad cl-xs3 cl-md2"
					labeled
					@input="str => { logDatetimeChange(`datetime No01 [example 01]: ${str}`) }"
				/>
				<nice-datetime
					class="cl-pad cl-xs3 cl-md2"
					label="test"
					@input="str => { logDatetimeChange(`datetime No02 [example 02]: ${str}`) }"
				/>
				<nice-input
					class="cl-pad cl-xs3 cl-md2"
					type="text"
					placeholder="input"
					label="input"
					value=""
				/>
			</div>
		</div>

		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<div class="cl-pad cl-xs5 cl-md3" >
					<h2>default</h2>
					<nice-datetime
						@input="str => { logDatetimeChange(`datetime No03 [default]: ${str}`) }"
					/>

					<h2>no field</h2>
					<nice-datetime
						:no-value-to-custom-elem="true"
						@input="str => { logDatetimeChange(`datetime No04 [without input]: ${str}`) }"
					>
						<button>click me</button>
					</nice-datetime>

					<h2>Separate Date and Time</h2>
					<div class="cl-container" >
						<div class="cl-row cl-pad-no_sides" >
							<div class="cl-pad cl-xs6" >
								<nice-datetime
									:only-date="true"
									placeholder="Select date"
									@input="str => { logDatetimeChange(`datetime No05 [date]: ${str}`) }"
								/>
							</div>

							<div class="cl-pad cl-xs6" >
								<nice-datetime
									:only-time="true"
									format="HH:mm"
									formatted="HH:mm"
									placeholder="Select time"
									@input="str => { logDatetimeChange(`datetime No06 [time]: ${str}`) }"
								/>
							</div>
						</div>
					</div>

					<h2>range</h2>
					<nice-datetime
						:range="true"
						format="YYYY-MM-DD"
						formatted="YYYY-MM-DD"
						label="duration"
						placeholder="Select date interval"
						@input="str => { logDatetimeChange(`datetime No07 [interval]: ${str}`) }"
					/>

					<h2>error</h2>
					<nice-datetime
						:error="true"
						@input="str => { logDatetimeChange(`datetime No08 [with error]: ${str}`) }"
					/>
				</div>

				<div class="cl-pad cl-xs5 cl-md-ml1 cl-md5" >
					<h2>inline</h2>
					<nice-datetime
						:inline="true"
						@input="str => { logDatetimeChange(`datetime No09 [inline]: ${str}`) }"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import NiceDatetime from '@/ui/nice-datetime.vue';
import NiceInput from '@/ui/nice-input.vue';

export default {
	name: 'TabDateTime',


	components: {
		NiceDatetime,
		NiceInput,
	},


	methods: {
		// TODO: ???
		logDatetimeChange(str) {
			// console.log(str);
		},
	},
};
</script>
