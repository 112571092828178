export default {
	create: {
		en: 'Create...',
		ge: 'შექმნა...',
		de: 'Erstellen...',
		ru: 'Создать...',
		cn: /* TODO: to be translated */'Create...',
	},

	add_advertiser: {
		en: 'Advertiser',
		ge: 'რეკლამის განმთავსებელი',
		de: 'Werbetreibender',
		ru: 'Рекламодатель',
		cn: /* TODO: to be translated */'Advertiser',
	},

	add_request: {
		en: 'Ad Request',
		ge: 'რეკლამის მოთხოვნა',
		de: 'Werbeanfrage',
		ru: 'Заявка на рекламу',
		cn: /* TODO: to be translated */'Ad Request',
	},

	add_balance: {
		en: 'Add Balance',
		ge: 'დაამატე ბალანსი',
		de: 'Guthaben hinzufügen',
		ru: 'Пополнить баланс',
		cn: /* TODO: to be translated */'Add Balance',
	},
};
