<template>
	<symbol :id="`${prefix}add-sqr`" viewBox="0 0 20 20">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6 0C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H14C17.3137 20 20 17.3137 20 14V6C20 2.68629 17.3137 0 14 0H6ZM9 5H11V9H15V11H11V15H9V11H5V9H9V5Z"
			:fill="getColor('main')"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolAddSqr',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				main__disabled: this.$palette.disabled_color,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
