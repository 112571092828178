<template>
	<section class="layout-full_width" >
	<div>
		<tree-element
			v-for="el in list"
			:id="el.id"
			:key="el.id"
			:tree="tree"
			@changed="update"
		/>
	</div>

	</section>
</template>

<script>
import TreeElement from './tree-el.vue';

export default {
	name: 'MrazTree',

	data() {
		return {
			tree: [
				{
					id			: 0,
					label		: 'Элемент 1',
					selected	: false,
					parent		: null,
				},
				{
					id			: 1,
					label		: 'Элемент 2',
					selected	: false,
					parent		: null,
				},
				{
					id			: 2,
					label		: 'Элемент 0.1',
					selected	: false,
					parent		: 0,
				},
				{
					id			: 6,
					label		: 'Элемент 0.2',
					selected	: false,
					parent		: 0,
				},
				{
					id			: 7,
					label		: 'Элемент 0.3',
					selected	: false,
					parent		: 0,
				},
				{
					id			: 3,
					label		: 'Элемент 0.1.1',
					selected	: false,
					parent		: 2,
				},
				{
					id			: 4,
					label		: 'Элемент 0.1.2',
					selected	: false,
					parent		: 2,
				},
				{
					id			: 8,
					label		: 'Элемент 0.1.3',
					selected	: false,
					parent		: 2,
				},
				{
					id			: 5,
					label		: 'Элемент 1.1',
					selected	: false,
					parent		: 1,
				},
			]
		};
	},

	methods: {
		update(id, selected, action='node') {
			let node = this.tree.find(el=>el.id===id);
			node.selected = selected;

			if (action==='node' || action==='down'){
				let children = this.tree.filter(el=>el.parent===id);
				if (children.length>0){
					children.forEach(el=>{
						if (el.selected!=selected){
							this.update(el.id,selected,'down');
						}
					});
				}
			}
			if (action==='node' || action==='up'){
				let parent = this.tree.find(el=>el.id===node.parent);
				let siblings = this.tree.filter(el=>el.parent===node.parent);

				if (parent){
					let newValue;
					// siblings.forEach(el=> console.log(el.selected));

					if (siblings.every(el => el.selected === true)) {
						newValue = true;
					}
					else if (siblings.every(el => el.selected === false)) {
						newValue = false;
					}
					else {
						newValue = false;
					}

					if (newValue != parent.selected) {
						this.update(parent.id, newValue, 'up');
					}
				}
			}
		}
	},

	computed: {
		list() {
			return this.tree.filter(el=>el.parent===null);
		}
	},
	components: {
		TreeElement,
	},
};
</script>

<style lang="sass" scoped >
</style>
