<template>
	<div class="selected_area" >
		<nice-button-2
			:icon="toggleButtonIcon"
			class="selected_area-toggle_action"
			@click="toggleAction"
		/>

		<nice-icon-2
			:icon="markerIcon"
			class="selected_area-action_icon"
		/>

		<nice-input
			v-model="actualName"
			:placeholder="$t('campaign.address_placeholder')"
			class="selected_area-address"
		/>

		<!-- min not worked -->
		<nice-input
			v-model="actualRadius"
			type="number"
			step="0.1"
			:min="0"
			:units="$t('km')"
			class="selected_area-radius"
		/>

		<nice-button-2
			icon="basket-14"
			class="selected_area-delete"
			@click="deleteArea"
		/>
	</div>
</template>

<script>
import NiceInput from '@/ui/nice-input';
import { ACTION_EXCLUDE, ACTION_INCLUDE } from './defaults';


const MODEL = Object.freeze({
	prop: 'area',
	event: 'input',
});


export default {
	name: 'SelectedIncludeOrExcludeArea',


	model: MODEL,


	components: {
		NiceInput,
	},


	props: {
		[MODEL.prop]: {
			type: Object,
			default: () => ({}),
		},

		input: {
			type: String,
			default: ''
		},
	},


	computed: {
		value() {
			return this[MODEL.prop];
		},

		/**
		 * Area name getter and setter
		 * setter emit event for v-model
		 */
		actualName: {
			// getter
			get: function () {
				return this.value.title;
			},

			// setter
			set: function (newValue) {
				this.$emit(MODEL.event, {
					...this.value,
					title: newValue,
				});
			}
		},

		/**
		 * Radius getter and setter
		 * setter emit event for v-model
		 */
		actualRadius: {
			// getter
			get: function () {
				return this.value.radius;
			},

			// setter
			set: function (newValue) {
				if (isNaN(newValue)) {
					newValue = 0;
				}
				this.$emit(MODEL.event, {
					...this.value,
					radius: parseFloat(parseFloat(newValue).toFixed(2)),
				});
			}
		},

		/**
		 * Return icon name for toggle action button
		 */
		toggleButtonIcon() {
			if (ACTION_INCLUDE == this.value.action) {
				return 'plus_another-20';
			} else if (ACTION_EXCLUDE == this.value.action) {
				return 'minus_another-20';
			}

			return `${this.value.action}_another-20`;
		},

		/**
		 * Return icon name for marker
		 */
		markerIcon() {
			if (ACTION_INCLUDE == this.value.action) {
				return 'mark-14';
			} else if (ACTION_EXCLUDE == this.value.action) {
				return 'mark_crossed-15';
			}

			return `${this.value.action}`;
		},
	},


	methods: {
		/**
		 * Emit update event
		 */
		toggleAction() {
			this.$emit(MODEL.event, {
				...this.value,
				action: this.value.action == ACTION_EXCLUDE ? ACTION_INCLUDE : ACTION_EXCLUDE,
			});
		},

		/**
		 * Emit delete event
		 */
		deleteArea() {
			this.$emit('delete');
		},
	},
};
</script>

<style lang="sass" scoped >
.selected_area
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between
	align-items: flex-end

	margin-top: 15px

.selected_area-toggle_action
	position: relative

	margin-left: -5px
	padding-left: 5px
	padding-right: 5px
	border-radius: 0

	&::after
		content: ""
		position: absolute
		left: 5px
		bottom: 0
		display: block
		height: 1px
		width: calc(100% - 5px)
		background-color:  $nice_color-gray

.selected_area-action_icon
	padding-left: 5px
	padding-right: 10px
	padding-bottom: 8px

	border-bottom: 1px solid $nice_color-gray

	&.nice-icon__mark_crossed-15
		padding-left: 4px
		padding-bottom: 7px

.selected_area-address
	flex-grow: 1
	flex-shrink: 0

.selected_area-radius
	margin-left: 30px
	width: 60px

	::v-deep .ni_input--input
		text-align: right

		&[type=number]
			/* Firefox */
			-moz-appearance: textfield

			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				-webkit-appearance: none
				margin: 0

.selected_area-delete
	margin-left: 20px
	padding-left: 10px
	padding-right: 10px
	margin-right: -10px
</style>
