<template>
	<symbol :id="`${prefix}bell`" viewBox="0 0 22 21">
		<path
			:fill="getColor('main')"
			stroke="none"
			d="M19.37,17.02c-0.56-0.64-0.87-1.46-0.87-2.31V9.5c0-3.26-2.09-6.03-5.01-7.06C13.46,1.09,12.36,0,11,0 C9.64,0,8.54,1.09,8.51,2.44C5.59,3.47,3.5,6.24,3.5,9.5v5.21c0,0.85-0.31,1.67-0.87,2.31c-0.31,0.36-0.39,0.85-0.19,1.28 c0.2,0.43,0.61,0.7,1.09,0.7h5.02c0.23,1.14,1.24,2,2.45,2c1.21,0,2.22-0.86,2.45-2h5.02c0.48,0,0.89-0.27,1.09-0.7 C19.75,17.87,19.68,17.37,19.37,17.02z M11,1c0.7,0,1.29,0.49,1.45,1.14C11.98,2.05,11.5,2,11,2s-0.98,0.05-1.45,0.14 C9.71,1.49,10.3,1,11,1z M11,20c-0.65,0-1.2-0.42-1.41-1h2.82C12.2,19.58,11.65,20,11,20z M18.64,17.88 C18.63,17.93,18.58,18,18.47,18H3.53c-0.11,0-0.16-0.07-0.18-0.12s-0.04-0.13,0.03-0.21C4.1,16.85,4.5,15.8,4.5,14.71V9.5 C4.5,5.92,7.42,3,11,3s6.5,2.92,6.5,6.5v5.21c0,1.09,0.4,2.14,1.11,2.96C18.69,17.76,18.66,17.84,18.64,17.88z"
		/>
		<circle
			v-if="state"
			cx="18"
			cy="5"
			r="4"
			:fill="getColor('dot')"
			stroke="none"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconBell',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.darkgray,
				dot: this.$palette.red,
				main__disabled: this.$palette.darkgray__disabled,
				dot__disabled: this.$palette.red__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
