import baseRest from './base/rest';


const getPrefix = () => 'brand/';


export default {
	getPrefix,

	/**
	 * Request Brand List
	 *
	 * @returns {object[]} Brand
	 * @returns {number} Brand.id
	 * @returns {string} Brand.domain
	 */
	'list': baseRest(getPrefix).list,

	/**
	 * @params {number} id
	 * @params {object} config
	 */
	'retrieve': baseRest(getPrefix).retrieve,
};
