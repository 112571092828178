<template>
	<section
		ref="overlay"
		v-prevent-body-scroll
		class="nice-modal"

		@mousedown="onMousedown"
		@touchstart="onTouchstart"
		@mouseup.self="onMouseup"
		@touchend.self="onTouchend"
		@keyup.esc="close"
	>
		<slot />
	</section>
</template>


<script>

/**
 * NiceModal used to travel throuhgh projects and now
 * it is back!
 *
 * TODO: add focus trap
 */

export default {
	name: 'NiceModal',

	data() {
		return {
			isOverlayMousedowned: false,
			isOverlayTouchstarted: false,
		};
	},


	methods: {
		/**
		 * Mousedown handler
		 * save isMousedowned
		 * @param {MouseEvent} event
		 */
		onMousedown(event) {
			this.isOverlayMousedowned = event.target === this.$refs.overlay;
			this.isOverlayTouchstarted = false;
		},


		/**
		 * Mouseup handler
		 * check if previously mousedowned and fire close if is
		 */
		onMouseup() {
			if (this.isOverlayMousedowned) {
				this.close();
			}
		},


		/**
		 * Touchstart handler
		 * save isTouchstarted
		 * @param {MouseEvent} event
		 */
		onTouchstart(event) {
			this.isOverlayTouchstarted = event.target === this.$refs.overlay;
			this.isOverlayMousedowned = true;
		},


		/**
		 * Touchend  handler
		 * check if previously touchstarted and fire close if is
		 */
		onTouchend() {
			if (this.isOverlayTouchstarted) {
				this.close();
			}
		},


		/**
		 * Close modal
		 * Emits `close` event
		 */
		close() {
			this.$emit('close');
		},
	},

};
</script>

<style lang="sass" scoped>
.nice-modal
	position: fixed
	z-index: 9001
	top: 0
	right: 0
	bottom: 0
	left: 0
	transform: translateZ(0)
	background-color: rgba(#112132, .9)
	outline: none
	display: flex
	flex-direction: column

	> *
		position: relative
		width: 100%
		max-width: 1016px
		margin: auto
		background-color: #ffffff
		height: fit-content
</style>
