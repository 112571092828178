<template>
	<section>
		<map-device
			:source-data="source"
		>
			<PopupDevice
				:coordinates="[60.260494351387027, 59.943620275388454]"
				:device="{
					name: 'Test Popup with very long long long long long long long long long name',
					address: 'Central mall, Przhevalsky street 12, Smolensk, Russia',
					type: 3,
				}"
			/>
		</map-device>

		<button type="button" @click="changePoints" >change points</button>
	</section>
</template>

<script>
import MapDevice from '@/components/map-device/mapbox';
import PopupDevice from '@/components/map-device/mapbox/popup-device';


const CENTER_GC_SPb = [30.260494351387027, 59.943620275388454];
const CENTER_ORIGINAL = [-103.59179687498357, 40.66995747013945];

const sourceDataLocal = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'properties': {
				'id': 'ak16994521',
				'name': 'Foo',
				'mag': 2.3,
				'time': 1507425650893,
				'felt': null,
				'tsunami': 0,
				address: 'Central mall, Przhevalsky street 12, Smolensk, Russia',
				type: 3,
			},
			'geometry': {
				'type': 'Point',
				'coordinates': [-151.5129, 63.1016]
			}
		},
		{
			'type': 'Feature',
			'properties': {
				'id': 'ak16994522',
				'name': 'Bar',
				'mag': 2.3,
				'time': 1507425650893,
				'felt': null,
				'tsunami': 0,
				address: 'Central mall, Przhevalsky street 12, Smolensk, Russia',
				type: 3,
			},
			'geometry': {
				'type': 'Point',
				'coordinates': CENTER_ORIGINAL,
			}
		},
		{
			'type': 'Feature',
			'properties': {
				'id': 'ak16994522',
				'name': 'Bar',
				'mag': 2.3,
				'time': 1507425650893,
				'felt': null,
				'tsunami': 0,
				address: 'Central mall, Przhevalsky street 12, Smolensk, Russia',
				type: 3,
			},
			'geometry': {
				'type': 'Point',
				'coordinates': CENTER_GC_SPb,
			}
		}
	]
};
const sourceDataRemote = 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson';


export default {
	name: 'TabMap',


	components: {
		MapDevice,
		PopupDevice,
	},


	data() {
		return {
			initSourceRemote: true,
		};
	},


	computed: {
		source() {
			if (this.initSourceRemote) {
				return sourceDataLocal;
			} else {
				return sourceDataRemote;
			}
		},
	},


	methods: {
		changePoints() {
			this.initSourceRemote = !this.initSourceRemote;
		},
	},
};
</script>

<style lang="sass" scoped >
.map
	width: 100%
	height: 400px
</style>
