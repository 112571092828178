<template>
	<section class="layout-full_width" >
		<p>Цвета доступны в sass с префиксом <b>{{ prefix }}</b>&lt;color&gt;</p>
		<p>либо в контексте вью компонентов - <b>this.$palette.</b>&lt;color&gt;</p>
		<p>Хорошо бы для каждого цвета делать цвет с суфикос `__disabled` для использования в случаях отключённого элемента</p>
		<p>Судя по некоторым макетам, disabled это цвет + 0.7 белого</p>

		<section class="palette-scheme layout_block-full_width" >
			<section
				v-for="color in palette"
				:key="color.color"

				class="palette-color"
				:style="{
					'background-color': color.color
				}"
			>
				<p
					v-for="name in color.aliases"
					:key="name"
					class="palette-color-name"
				>
					{{ name }}
				</p>
				<p class="palette-color-code" >{{ color.color }}</p>
			</section>
		</section>
	</section>
</template>

<script>
import _ from 'underscore';


export default {
	name: 'TabPallete',

	data() {
		return {
			prefix: process.env.VUE_APP_COLOR_SCHEME_PREFIX
		};
	},

	computed: {
		palette() {
			let colors = {};

			_.each(this.$palette, (color, name) => {
				if (_.has(colors, color)) {
					colors[color].aliases.push(name);
				} else {
					colors[color] = {
						aliases: [name],
						color: color,
					};
				}
			});

			return colors;
		}
	}
};
</script>

<style lang="sass" scoped >
.palette-scheme
	display: flex
	flex-direction: row
	flex-wrap: wrap
	justify-content: flex-start

.palette-color
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: center
	align-items: center
	align-content: center

	width: 25%
	height: 300px

	text-align: center

.palette-color-name,
.palette-color-code
	margin: 0
	padding: 0

	background-color: #ffffff
	font-family: monospace

</style>
