<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 20 20">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0C4.47705 0 0 4.47714 0 10C0 15.5229 4.47705 20 10 20ZM15 9H5V11H15V9Z"
			:fill="getColor('main')"
		/>
	</symbol>
</template>

<script>
export const slug = 'busy-medium';


export default {
	name: 'NiIconBusyMedium',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.red,
				main__disabled: this.$palette.red__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
