<template>
	<section class="page-section">
		<h1>NiceTreeCheck - tree of checkboxes</h1>

		<div style="margin: 20px 40px;">
			<h2>Selected Values</h2>
			<pre>{{ initialValues }}</pre>

			<button @click="addSome('fruits')">Add fruits</button>
		</div>

		<nice-tree-check
			v-model="initialValues"
			:tree="tree"
			style="margin-left: 40px;"
			:hideSubtrees="false"
		/>

		<nice-tree-demo
			:tree="tree"
			:value="initialValues"
		/>

		<pre class="code-preview">
// import
import { NiceRangeLegendItem } from '@/ui/nice-range';

<hr />
// add component
...
	components: {
		NiceRangeLegendItem
		...
	},
...

<hr />
// template
&lt;nice-range
	v-model="..."
	axis="..."
&gt;
	&lt;nice-range-legend-item
		placement="start"
		label="Early year&lt;br /&gt;Past winter"
		class="legend-modifier-class"
	/&gt;

	&lt;nice-range-legend-item
		placement="end"
		label="Late year&lt;br /&gt;Next winter"
		class="legend-modifier-class"
	/&gt;
&lt;/nice-range&gt;
		</pre>
	</section>
</template>

<script>
import _ from 'underscore';
import NiceTreeCheck from '@/ui/nice-tree-check';
import NiceTreeDemo from '@/ui/nice-tree-check/nice-tree-demo';

export default {
	name: 'TabTree',

	components: {
		NiceTreeCheck,
		NiceTreeDemo,
	},

	data() {
		return {
			tree: [
				{ value: 'milk-products', label: 'milk-products', parent: null },
				{ value: 'cream', label: 'cream', parent: 'milk-products' },
				{ value: 'milk', label: 'milk', parent: 'milk-products' },
				{ value: 'sour-cream', label: 'sour-cream', parent: 'milk-products' },
				{ value: 'cheese', label: 'cheese', parent: 'milk-products' },
				{ value: 'milkshake', label: 'milkshake', parent: 'milk-products' },
				{ value: 'butter', label: 'butter', parent: 'milk-products' },
				{ value: 'banana-milkshake', label: 'banana-milkshake', parent: 'milkshake' },
				{ value: 'strawberry-milkshake', label: 'strawberry-milkshake', parent: 'milkshake' },
				{ value: 'fish-milkshake', label: 'fish-milkshake', parent: 'milkshake' },
				{ value: 'mozarela', label: 'mozarela', parent: 'cheese' },
				{ value: 'cheeder', label: 'cheeder', parent: 'cheese' },
				{ value: 'parmezan', label: 'parmezan', parent: 'cheese' },
				{ value: 'fruits', label: 'fruits', parent: null },
				{ value: 'peach', label: 'peach', parent: 'fruits' },
				{ value: 'apple', label: 'apple', parent: 'fruits' },
				{ value: 'pear', label: 'pear', parent: 'fruits' },
				{ value: 'berries', label: 'berries', parent: 'fruits' },
				{ value: 'wildberry', label: 'wildberry', parent: 'berries' },
				{ value: 'cranberry', label: 'cranberry', parent: 'berries' },
				{ value: 'strawberry', label: 'strawberry', parent: 'berries' },
				{ value: 'dino', label: 'dino', parent: null },
				{ value: 't-rax', label: 't-rax', parent: 'dino' },
				{ value: 'diplodock', label: 'diplodock', parent: 'dino' },
				{ value: 'tmnt', label: 'tmnt', parent: null },
				{ value: 'turtules', label: 'turtules', parent: 'tmnt' },
				{ value: 'leo', label: 'leo', parent: 'turtules' },
				{ value: 'don', label: 'don', parent: 'turtules' },
				{ value: 'mikey', label: 'mikey', parent: 'turtules' },
				{ value: 'raph', label: 'raph', parent: 'turtules' },
				{ value: 'splinter', label: 'splinter', parent: 'tmnt' },
			],
			//initialValues: ['diplodock', 't-rax'],
			initialValues: ['dino'],
		};
	},

	methods: {
		addSome(value) {
			let newValues = _.clone(this.initialValues);
			newValues.push(value);
			this.initialValues = newValues;
		},
	},
};
</script>

<style lang="sass" scoped>
</style>
