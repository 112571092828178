<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 100 100"
		class="nice-icons"
	>
		<defs>
			<component
				:is="symbol"
				v-for="symbol in SYMBOL_LIST"
				:key="symbol"
				:prefix="prefix"
			/>
		</defs>
	</svg>
</template>

<script>
import _ from 'underscore';

import { camelToSnake } from '@/utilites';

const req = require.context('./symbol/', true, /\.vue$/i);

const SYMBOL_MODULE_LIST = req.keys().reduce((moduleList, module, i) => {
	const m = req(module).default;
	moduleList[m.name] = m;
	return moduleList;
}, {});


export default {
	name: 'NiceIconSymbols',


	components: SYMBOL_MODULE_LIST,


	data() {
		return {
			prefix: 'nice_icon--symbol_',
		};
	},


	computed: {
		SYMBOL_LIST() {
			let symbols = _.keys(SYMBOL_MODULE_LIST).reduce((symbolList, symbol, i) => {
				symbolList.push(camelToSnake(symbol));
				return symbolList;
			}, []);

			return symbols;
		},
	},
};
</script>

<style lang="sass" scoped>
.nice-icons
	display: none
</style>
