<template>
	<symbol :id="`${prefix}money`" width="20" height="20" viewBox="0 0 20 20" fill="none">

		<ellipse cx="7" cy="4.5" rx="6" ry="2.5" class="sign"/>
		<path d="M13 9.00002C13 8.07466 11.7934 7.26673 10 6.83447" class="sign" stroke-linecap="round"/>
		<path d="M7 16C3.68629 16 1 14.8807 1 13.5C1 12.6822 1.94241 11.9561 3.39942 11.5" class="sign" stroke-linecap="round"/>
		<path d="M7 11.5C3.68629 11.5 1 10.3807 1 9C1 8.1822 1.94241 7.45611 3.39942 7" class="sign" stroke-linecap="round"/>
		<ellipse cx="13" cy="11.5" rx="6" ry="2.5" class="sign"/>
		<path d="M9.39942 14C7.94241 14.4561 7 15.1822 7 16C7 17.3807 9.68629 18.5 13 18.5C16.3137 18.5 19 17.3807 19 16C19 15.1822 18.0576 14.4561 16.6006 14" class="sign" stroke-linecap="round"/>

	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolMoney',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped>
.sign
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})
	stroke-width: 2
	fill: none
</style>
