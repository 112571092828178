<template>
	<symbol :id="`${prefix}drop`" viewBox="0 0 40 48">
		<path class="sign" d="M40 28c0 11-9 20-20 20S0 39 0 28C0 14 20 0 20 0s20 14 20 28z"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolDrop',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: none
	fill: var(--ni-icon-sign, #ffffff)
</style>
