var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nice-tree-check"},_vm._l((_vm.flatTree),function(entry){return _c('div',{key:("entry-" + (entry.value)),ref:("item__" + (entry.value)),refInFor:true,class:[
			'item',
			("item__" + (entry.value)),
			("level__" + (entry.level)),
			{
				'hidden': entry.isHidden && entry.parent != null,
			} ],style:({
			'padding-left': ((entry.level * 20) + "px"),
			'max-width': ("calc(100% - " + (entry.level * 20) + "px)")
		}),attrs:{"id":("item__" + (entry.value))}},[_c('nice-button',{class:{
				invisible: entry.children.length === 0,
			},attrs:{"icon":"arrow-corner","palette":{
				default: _vm.$palette.black,
				disabled: _vm.$palette.black__disabled,
			},"icon-state":entry.isHidden ? '0' : '90'},on:{"click":function($event){return _vm.toggleHide(entry, true)}}}),_c('nice-checkbox',{attrs:{"caption":entry.label,"title":entry.title,"disabled":_vm.disabled},on:{"click":function($event){return _vm.toggleCheckbox(entry, $event)}},model:{value:(entry.isChecked),callback:function ($$v) {_vm.$set(entry, "isChecked", $$v)},expression:"entry.isChecked"}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }