<template>
	<symbol :id="`${prefix}arrow_corner-12`" viewBox="0 0 12 8">
		<path d="M1 1.5L6 6.5L11 1.5" class="sign arrow" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolArrowCorner12',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke-width: var(--ni-icon-sign-stroke-width, 2)
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})
	fill: none

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: center
</style>
