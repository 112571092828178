<template>
	<div class="cl-container" >
		<nice-checkbox v-model="isDone"/>
		<nice-input 
			ref="input" 
			v-model="value" 
			style="width:30em;margin-left:5px"
			:class="{done: isDone}"
		/>
		<nice-button 
				bordered 
				icon="trash" palette="red" 
				@click="$emit('delete',task.id)"
		/>
	</div>
</template>

<script>
import NiceButton from '@/ui/nice-button.vue';
import NiceInput from '@/ui/nice-input.vue';
import NiceCheckbox from '@/ui/nice-checkbox.vue';

export default {
	name: 'TaskElement',

	components: {
		NiceButton,
		NiceInput,
		NiceCheckbox,
	},
	props:{
		id:{type:Number},
		task:{
			type:Object,
			required:true,
		},
	},

	model: {
		prop:'task',
		event:'input',
	},
	methods: {
		update() {
			this.$emit('input', {
				...this.task,
				value:this.value,
				isDone:this.isDone} );
		}
	},
	data() {
		return {
			isDone: this.task.isDone,
			value:this.task.value,
		};
	},
	watch: {
		isDone(newValue, oldValue) {
			this.update();
		},
		value(newValue, oldValue) {
			this.update();
		},
		task(newValue, oldValue) {
			this.isDone != newValue.isDone ? this.isDone = newValue.isDone : null;
			this.value != newValue.value ? this.value = newValue.value : null;
		}
	},

	mounted: function(){
		//console.log(this.$refs.input.focus);
		this.$refs.input.focus();

	}
};
</script>

<style lang="sass" scoped >
.done 
	text-decoration: line-through
	color:grey

</style>
