<template>
	<div
		id="app"
		v-window-size-to-store
		class="app"
		:class="{
			'app_show-header': isHeaderShowing,
		}"
	>

		<MainMenu
			v-if="isLoggedIn && !$route.meta.hideNavbar && agency"
			class="app__header-panel"
		/>

		<router-view v-if="accessGranted" />
		<error-500 v-else-if="errorMessage" />
		<!-- <error-404 v-else /> -->

		<nice-icon-symbols />

		<HelpModal v-if="showHelpModal" />
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Error500 from '@/pages/error/500-internal-server-error';
import MainMenu from '@/components/main-menu.vue';
import HelpModal from '@/components/help-modal.vue';
import NiceIconSymbols from '@/ui/nice-icon-2/symbols';
import { userAccessToRoute } from '@/utilites';


export default {
	name: 'App',


	components: {
		Error500,
		MainMenu,
		NiceIconSymbols,
		HelpModal
	},

	/**
	 * Adding custom scripts here
	 */
	mounted() {
		if (process.env.VUE_APP_HINTED_ORG_ID) {
			let el = document.createElement('script');
			el.setAttribute('src', 'https://app.hintedme.ru/script.js');
			el.setAttribute('organizationId', process.env.VUE_APP_HINTED_ORG_ID);
			el.setAttribute('host', 'https://app.hintedme.ru/api');
			el.setAttribute('isSpa', 'true');
			el.setAttribute('spaSelector', '#app');
			document.head.appendChild(el);
		}
	},


	computed: {
		...mapGetters('profile', [ 'isLoggedIn' ]),
		...mapState('modals', ['showHelpModal']),
		...mapState('app', {
			platformSide: 'side',
			errorMessage: 'errorMessage',
			agency: 'agency',
		}),

		// TODO: move into the router
		accessGranted() {
			return userAccessToRoute(this.platformSide, this.$route);
		},

		isHeaderShowing() {
			return this.isLoggedIn && !this.$route.meta.hideNavbar && this.agency;
		},
	},
};
</script>

<style lang="css" src="normalize.css" ></style>
<style lang="sass" src="@/assets/styles/main.sass" ></style>
<style lang="sass" >
// these are main css variables which can be overriden in `uioverrides.js`
// `html` has less priority than `:root`
html
	--main_color: #{$nice_color-cyan}  // main theme color (ex. filled buttons)
	--main_lighter_color: #{$nice_color-cyan_lighter}
	--disabled_color: #{$nice_color-cyan_light}  // disabled main color (ex. watercolor buttons)
	--secondary_color: #{nice_color-blue}
	--secondary_color: #{nice_color-blue__disabled}

\:root
	// --contrast_color: #{$nice_color-navy}  // main contrast color

	--text_color: #{$nice_color-black}  // base text color
	--text_2_color: #{$nice_color-gray_dark}  // second level text color
	--text_3_color: #{$nice_color-white}  // text on main color (ex. text in filled buttons)
</style>

<style lang="sass" scoped >
$header-width: 55px

#app
	min-height: 100%
	box-sizing: border-box

.app_show-header
	padding-left: $header-width

.app__header-panel
	position: fixed
	left: 0
	top: 0
	bottom: 0
	width: $header-width
	box-sizing: border-box
	transform: translateZ(0)

</style>
