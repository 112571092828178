import _ from 'underscore';

// import apiDsp from '@/api/dsp/document';
// import apiSsp from '@/api/ssp/document';
import { setterGenerator } from '@/utilites.js';
// import { storeFactory } from './_base';


const DEFAULT_PAGE_SIZE = 15;
const serializeItem = item => {
	let start = new Date();
	let end = new Date();
	let ctime = new Date();
	let mtime = new Date();

	return {
		...item,
		start,
		end,
		ctime,
		mtime,
	};
};
// const getRequest = (side) => side == 'ssp' ? apiSsp : apiDsp;
// STUB


// const generated = storeFactory('campaign', getRequest, serializeItem);


export default {
	namespaced: true,

	state: {
		list: [],
		query: null,
		fieldsSensitive: null,
		totalCount: 0,
		page: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		meta: null,
	},

	mutations: {
		...setterGenerator(['fieldsSensitive', 'totalCount', 'page', 'pageSize', 'meta']),

		/* stub */ ...setterGenerator(['list']),

		// ...generated.mutations,  // setList, changeItem

		/**
		 * save filters to store
		 * + сохраняет стек используемых в фильтрах полей
		 *
		 * @param {State}
		 * @param {query{}} page
		 */
		setQuery(state, query) {
			state.query = query;

			let fields = {};
			_.each(_.keys(state.query), (filter) => {
				// дефолтное значение, атрибут фильтра равен полю девайса
				let filterFields = [filter];

				switch(filter) {
				case 'search':
					filterFields = ['name'];
					break;

				case 'by_me':
					filterFields = ['created_by'];
					break;
				}

				_.each(filterFields, (field) => {

					if (_.has(fields, field)) {
						fields[field].push(filter);
					} else {
						fields[field] = [filter];
					}
				});
			});

			state.fieldsSensitive = Object.freeze(fields);
		},
	},

	getters: {
		// ...generated.getters,  // loadingKeyBase, loadingKeyList, loadingKeyItem, loadingKeyItemChange, isLoading, isLoadingList, isLoadingItem, isFilterChanged
		isLoading: () => false,
	},

	actions: {
		// ...generated.actions,  // getList, create, getItem, change, delete


		async requestList({ commit, state }) {
			await new Promise(resolve => {
				setTimeout(() => {
					commit('setList', [
						{ id: 1, name: 'doc1', description: 'document stub 1' },
						{ id: 2, name: 'doc2', description: 'document stub 2' },
						{ id: 3, name: 'doc3', description: 'document stub 3' },
						{ id: 4, name: 'doc4', description: 'document stub 4' },
						{ id: 5, name: 'doc5', description: 'document stub 5' },
					].map(serializeItem));
					commit('setTotalCount', 5);
				}, Math.random() * 1200);
			});

			return {
				results: state.list,
				count: state.totalCount,
			};
		},
	},
};
