/**
 * As defined in `ar_adexchange.members.models.agency.Agency` in the backend
 *
 * TODO: frontend mostly use the old code
 */

export const ROLE_SUPER = 'super';
export const ROLE_SSP_ADMIN = 'ssp_admin';
export const ROLE_SSP_APPROVE = 'ssp_approve';
export const ROLE_SSP_VIEWER = 'ssp_viewer';
export const ROLE_DSP = 'dsp';
