<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 16 16">
			<!-- :fill="colors.icon" -->
		<circle
			cx="8"
			cy="8"
			r="5.5"
			fill="none"
			stroke-width="5"
			:stroke="getColor('sign')"
		/>
	</symbol>
</template>

<script>
export const slug = 'medium';


export default {
	name: 'NiIconMedium',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				icon: this.$palette.white,
				sign: this.$palette.yellow,
				icon__disabled: this.$palette.white__disabled,
				sign__disabled: this.$palette.yellow__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
