<template>
	<div class="nice-tree-check">

		<div
			v-for="entry in flatTree"
			:id="`item__${entry.value}`"
			:key="`entry-${entry.value}`"
			:ref="`item__${entry.value}`"
			:class="[
				'item',
				`item__${entry.value}`,
				`level__${entry.level}`,
				{
					'hidden': entry.isHidden && entry.parent != null,
				},
			]"
			:style="{
				'padding-left': `${entry.level * 20}px`,
				'max-width': `calc(100% - ${entry.level * 20}px)`
			}"
		>

			<nice-button
				icon="arrow-corner"
				:palette="{
					default: $palette.black,
					disabled: $palette.black__disabled,
				}"
				:icon-state="entry.isHidden ? '0' : '90'"
				:class="{
					invisible: entry.children.length === 0,
				}"
				@click="toggleHide(entry, true)"
			/>

			<nice-checkbox
				v-model="entry.isChecked"
				:caption="entry.label"
				:title="entry.title"
				:disabled="disabled"
				@click="toggleCheckbox(entry, $event)"
			/>

		</div>

	</div>
</template>

<script>
import _ from 'underscore';

import NiceCheckbox from '@/ui/nice-checkbox.vue';
import NiceButton from '@/ui/nice-button.vue';


export default {
	name: 'NiceTreeCheck',

	components: {
		NiceCheckbox,
		NiceButton
	},

	props: {
		/**
		 * Like:
		 [
				 { value: 'dino', label: 'dino', parent: null },
				 { value: 't-rax', label: 't-rax', parent: 'dino' },
				 { value: 'diplodock', label: 'diplodock', parent: 'dino' },
		 ]
		 */
		tree: {
			type: [Array, Promise],
			required: true,
		},

		/**
		 * [<value1>, <value2>, ...]
		 */
		value: {
			type: Array,
			default: () => [],
		},

		hidden: {
			type: Boolean,
			default: false,
		},

		hideSubtrees: {
			type: Boolean,
			default: true,
		},

		disabled: {
			type: Boolean,
		},
	},

	data() {
		return {
			fullTree: {},
			flatTree: [],
			// val: this.value,
		};
	},

	computed: {
	},

	//
	// Watchers
	//

	watch: {
		tree: {
			deep: true,
			handler: async function(value, oldValue) {
				let idsSorted = _.sortBy(value.map(item => item.value));
				let oldIdsSorted = _.sortBy(oldValue.map(item => item.value));

				if (_.isEqual(idsSorted, oldIdsSorted)) {
					return;
				}

				await this.initTrees();
			},
		},

		value: {
			deep: false,
			handler: function(value, oldValue) {
				let valueSorted = _.sortBy(value);
				let oldValueSorted = _.sortBy(oldValue);

				if (_.isEqual(valueSorted, oldValueSorted)) {
					return;
				}

				this.$log.debug("'value' is updated", valueSorted, oldValueSorted);

				_.each(this.flatTree, function(item) {
					item.isChecked = _.contains(value, item.value);
				});
			},
		},
	},


	//
	// Events
	//

	async created() {
		await this.initTrees();
	},


	//
	// Methods
	//

	methods: {

		async initTrees() {
			let fullTree = await this.getFullTree();
			let flatTree = this.getFlatTree(fullTree, null, 0);

			this.$set(this, 'fullTree', fullTree);
			this.$set(this, 'flatTree', flatTree);

			// HACK: update the v-model after the initialization
			//this.emitValue();
			//this.doHideSubtrees();
		},

		async getFullTree() {
			let self = this;

			// create an index
			let tree = {};

			let promisedTree = await Promise.resolve(this.tree);

			_.each(promisedTree, function(item) {
				let currentItem = {};
				if (self.fullTree && self.fullTree[item.value]) {
					currentItem = self.fullTree[item.value];
				}

				tree[item.value] = {
					value: item.value,
					label: item.label,
					title: item.title,
					'_parent': item.parent,
					parent: currentItem.parent || null,
					children: currentItem.children || [],
				};

				tree[item.value].isChecked = _.contains(self.value, item.value);
				if (typeof currentItem.isChecked != 'undefined') {
					tree[item.value].isChecked = currentItem.isChecked;
				}

				tree[item.value].isHidden = self.hideSubtrees;  // (item.parent != null && self.hideSubtrees);
				if (typeof currentItem.isHidden != 'undefined') {
					tree[item.value].isHidden = currentItem.isHidden;
				}
			});

			// populate children and parent properties
			_.each(promisedTree, function(item) {
				if (!item.parent) {
					return;
				}

				tree[item.parent].children.push(tree[item.value]);
				tree[item.value].parent = tree[item.parent];
			});

			// HACK: show subtree if something is checked
			let tillParent = null;
			_.each(promisedTree.reverse(), function(item) {
				if (tree[item.value].isChecked && tree[item.value].parent) {
					tillParent = tree[item.value].parent;
					tree[item.value].isHidden = false;
				}

				if (tillParent) {
					tree[item.value].isHidden = false;
				}

				if (tree[item.value] == tillParent) {
					tillParent = null;
				}
			});

			// update isChecked according to the parent isChecked
			this.updateIsChecked(tree, null);

			return tree;
		},

		/**
		 * Make a flat list with level
		 */
		getFlatTree(tree, parent, level) {
			let self = this;
			let flatList = [];

			let currentLevel = _.filter(tree, function(item) {
				return item._parent == parent;
			});

			_.each(currentLevel, function(item) {
				item.level = level;

				flatList.push(item);

				let subList = self.getFlatTree(tree, item.value, level+1);
				flatList = _.union(flatList, subList);
			});

			return flatList;
		},

		/**
		 * TODO: too much logic here
		 *
		 * entry - could be null (the root)
		 */
		updateIsChecked(tree, entry, setIsChecked, force) {
			let self = this;

			if (typeof force == 'undefined') {
				force = false;
			}

			// update parents
			if (force && entry) {
				if (!setIsChecked) {
					this.uncheckParents(entry);
				}
				else {
					this.checkParents(entry);
				}
			}

			let children = [];
			if (entry) {
				children = entry.children;
			}
			else {
				children = _.filter(tree, function(item) {
					return item._parent == null;
				});
			}

			_.each(children, function(item) {
				let isChecked = item.isChecked;
				if (typeof setIsChecked !== 'undefined') {
					isChecked = setIsChecked;
				}

				if (force || isChecked) {
					item.isChecked = isChecked;
				}

				// we need to update children here too
				_.each(item.children, function(childItem) {
					if (isChecked || force) {
						childItem.isChecked = isChecked;
					}
				});

				self.updateIsChecked(tree, item, setIsChecked, force);
			});
		},

		/**
		 * TODO: A LOT OF MAGIC HERE!!!
		 */
		toggleCheckbox(entry, event) {
			let self = this;

			// HACK:
			if (event.target.tagName.toLowerCase() != 'input') {
				return;
			}

			// HACK: set watcher for isChecked - it is updated after click
			// we need interaction
			let unwatch = this.$watch(
				function() {
					return entry.isChecked;
				},
				function(value, oldValue) {
					unwatch();

					self.$log.debug('toggleCheckbox after click', entry.value, entry.isChecked);

					self.updateIsChecked(self.flatTree, entry, entry.isChecked, true);
					self.emitValue();
				}
			);
		},

		toggleHide(entry, onlyCurrentLevel) {

			let self = this;
			let el = this.$refs[`item__${entry.value}`][0];
			let add = false;

			// not working
			if (typeof onlyCurrentLevel == 'undefined') {
				onlyCurrentLevel = false;
			}

			// toggle state
			entry.isHidden = !entry.isHidden;

			_.each(el.parentNode.querySelectorAll('div.item'), function(item) {

				// we hit the entry - start addind elements to the list from the next one
				if (item.classList.contains(`item__${entry.value}`)) {
					add = true;
					return;
				}

				// we hit the same level entry - stop adding items
				if (item.classList.contains(`level__${entry.level}`)) {
					add = false;
					return;
				}

				// hide / unhide
				// OMG: my head is so low...
				if (add) {
					self._toggleHideEntry(entry, item);
					// open
					// if (!entry.isHidden && onlyCurrentLevel) {
					// 	if (item.classList.contains(`level__${entry.level + 1}`)) {
					// 		self._toggleHideEntry(entry, item);
					// 	}
					// }
					// // close
					// else {
					// 	self._toggleHideEntry(entry, item);
					// }
				}
			});

		},

		_toggleHideEntry(entry, item) {
			item.classList.toggle('hidden', entry.isHidden);
			let id = item.id.replace('item__', '');
			this.fullTree[id].isHidden = entry.isHidden;
		},

		uncheckParents(entry) {
			if (entry.parent) {
				entry.parent.isChecked = false;
				this.uncheckParents(entry.parent);
			}
		},

		checkParents(entry) {
			if (!entry.parent) {
				return;
			}

			let allValues = _.map(entry.parent.children, function(item) {
				return item.isChecked;
			});

			if (_.every(allValues)) {
				entry.parent.isChecked = true;
				this.checkParents(entry.parent);
			}
		},

		doHideSubtrees() {
			let self = this;

			_.each(this.flatTree, function(entry) {
				if (entry.level == 0) {
					self.toggleHide(entry);
				}
			});
		},

		getValue() {
			let values = _.reduce(this.flatTree, function(memo, item) {
				if (item.isChecked) {
					memo.push(item.value);
				}

				return memo;
			}, []);

			return _.uniq(values);
		},

		emitValue() {
			let val = this.getValue();
			this.$emit('input', val);
		},
	},
};
</script>

<style lang="sass" scoped>
.item
	position: relative
	padding-left: 0px

	&.hidden,
	.hidden
		display: none

	.sub-tree
		padding-left: 20px

	//.nice-icon__arrow-corner

	.nice-button
		float: left
		padding: 0
		//margin-left: -20px
		margin-right: 10px
		margin-top: 10px
		cursor: pointer
		min-height: 10px

		&.invisible
			visibility: hidden


	.nice-checkbox
		display: inline-flex
		width: calc(100% - 20px)
		margin-bottom: 1px
		font-size: $fsz__normal

		span.ni-chbx-caption
			text-overflow: ellipsis
</style>
