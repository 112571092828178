<template>
	<div class="counters-row">

		<!-- Balance Block -->
		<div class="container">
			<div class="header">
				<router-link
					class="label"
					:to="{ name: 'profile' }"
				>
					{{ $t('balance') | capitalize }}
				</router-link>

				<nice-hint
					:hint="$t(`campaign.balance_${side}_hint`) | capitalize"
				/>
			</div>


			<!-- Balance Stats -->
			<div class="stat-wrapper">
				<span
					v-if="Number(agencyBalanceInCurrency) || side === 'ssp'"
					class="total_balance"
					v-html="formatCash(agencyBalanceInCurrency, agencyCurrency)"
				/>
				<div
					v-else-if="side === 'dsp'"
					class="label-wrapper"
				>
					<span class="empty-balance-label">{{ $t(`campaign.empty_balance`) | capitalize }} </span>
					<nice-hint
						:hint="$t(`campaign.empty_balance_hint`) | capitalize"
					/>
				</div>
				<div class="timing">
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.for_week')"
						/>
						<div>
							<nice-hint
								class="value-wrapper__icon"
								icon="arrow-14"
								:state="balance_counters.week_sum > balance_counters.week_sum_before? -135 : -45"
								:style="balance_counters.week_sum > balance_counters.week_sum_before? '--ni-icon-sign: #21CF52;' : ''"
								:hint="$t('campaign.week_changes') + Math.abs(balance_counters.week_sum - balance_counters.week_sum_before)"
							/>
							<span>{{ balance_counters.week_sum || 0 }}</span>
						</div>
					</div>

					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.for_day')"
						/>
						<div>
							<nice-hint
								class="value-wrapper__icon"
								icon="arrow-14"
								:state="balance_counters.day_sum > balance_counters.day_sum_before? -135 : -45"
								:style="balance_counters.day_sum > balance_counters.day_sum_before? '--ni-icon-sign: #21CF52;' : ''"
								:hint="$t('campaign.day_changes') + Math.abs(balance_counters.day_sum - balance_counters.day_sum_before)"
							/>
							<span>{{ balance_counters.day_sum || 0 }}</span>
						</div>
					</div>
				</div>
			</div><!-- END: Balance Stats -->

		</div><!-- END: Balance Block -->


		<!-- Requests Block -->
		<div class="container">
			<div class="label-wrapper">
				<div class="header">
					<router-link
						v-if="!isSSPViewer"
						class="label"
						:to="{ name: 'request-list' }"
					>
						{{ $t('request_list.requests') | capitalize }}
					</router-link>
					<span
						v-else
						class="label"
					>{{ $t('request_list.requests') | capitalize }}</span>


					<nice-hint
						:hint="$t(`campaign.requests_${side}_hint`) | capitalize"
					/>
				</div>

			</div>


			<!-- Requests Stats -->
			<div class="stat-wrapper">

				<div class="requests">
					<span class="total">{{ requests.total }}</span>
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.for_week')"
						/>
						<div>
							<nice-hint
								class="value-wrapper__icon"
								icon="arrow-14"
								:state="requests.for_week > requests.week_before? -135 : -45"
								:style="requests.for_week > requests.week_before? '--ni-icon-sign: #21CF52;' : ''"
								:hint="$t('campaign.week_changes') + Math.abs(requests.for_week - requests.week_before)"
							/>
							<span>{{ requests.for_week }}</span>
						</div>
					</div>

					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.for_day')"
						/>
						<div>
							<nice-hint
								class="value-wrapper__icon"
								icon="arrow-14"
								:state="requests.for_day > requests.day_before? -135 : -45"
								:style="requests.for_day > requests.day_before? '--ni-icon-sign: #21CF52;' : ''"
								:hint="$t('campaign.day_changes') + Math.abs(requests.for_day - requests.day_before)"
							/>
							<span>{{ requests.for_day }}</span>
						</div>
					</div>

					<hr />

					<div class="value-wrapper">&nbsp;</div>

					<!-- awaiting_approval -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_1') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="warn"
							/>
							<span>{{ requests.awaiting_approval }}</span>
						</div>
					</div>

					<!-- <div class="value-wrapper">
						<nice-label
							:label="$t('campaign.creative_audit_status_pre_approved') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="pre_approved"
							/>
							<span>{{ requests.pre_approved }}</span>
						</div>
					</div> -->

					<!-- rejected -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_4') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="error"
							/>
							<span>{{ requests.rejected }}</span>
						</div>
					</div>

				</div>
			</div><!-- END: Requests Stats -->

		</div><!-- END: Requests Block -->


		<!-- Campaigns Block -->
		<div class="container">
			<div class="header">
				<router-link
					class="label"
					:to="{ name: 'campaign-list' }"
				>
					{{ $t('campaign.campaigns') | capitalize }}
				</router-link>

				<nice-hint
					:hint="$t(`campaign.campaigns_${side}_hint`) | capitalize"
				/>
			</div>


			<!-- Campaigns Stats -->
			<div class="stat-wrapper">

				<div class="requests">
					<span class="total">{{ campaigns.total }}</span>
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.for_week')"
						/>
						<div>
							<nice-hint
								class="value-wrapper__icon"
								icon="arrow-14"
								:state="campaigns.for_week > campaigns.week_before? -135 : -45"
								:style="campaigns.for_week > campaigns.week_before? '--ni-icon-sign: #21CF52;' : ''"
								:hint="$t('campaign.week_changes') + Math.abs(campaigns.for_week - campaigns.week_before)"
							/>
							<span>{{ campaigns.for_week }}</span>
						</div>
					</div>

					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.for_day')"
						/>
						<div>
							<nice-hint
								class="value-wrapper__icon"
								icon="arrow-14"
								:state="campaigns.for_day > campaigns.day_before? -135 : -45"
								:style="campaigns.for_day > campaigns.day_before? '--ni-icon-sign: #21CF52;' : ''"
								:hint="$t('campaign.day_changes') + Math.abs(campaigns.for_day - campaigns.day_before)"
							/>
							<span>{{ campaigns.for_day }}</span>
						</div>
					</div>

					<hr />

					<!-- awaiting_approval -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_1') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="warn"
							/>
							<span>{{ campaigns.awaiting_approval }}</span>
						</div>
					</div>

					<!-- playing -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_3') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="play"
							/>
							<span>{{ campaigns.playing }}</span>
						</div>
					</div>

					<!-- paused -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_692') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="pause"
							/>
							<span>{{ campaigns.paused }}</span>
						</div>
					</div>

					<!-- finished -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_693') | capitalize"
						/>
						<div class="value">
							<nice-icon
								class="value-wrapper__icon"
								icon="accepted"
							/>
							<span>{{ campaigns.finished }}</span>
						</div>
					</div>

					<!-- rejected -->
					<div class="value-wrapper">
						<nice-label
							:label="$t('campaign.status_change_4') | capitalize"
						/>
						<div class="value">
							<nice-icon-2
								class="value-wrapper__icon"
								icon="error"
							/>
							<span>{{ campaigns.rejected }}</span>
						</div>
					</div>

				</div>
			</div><!-- END: Campaigns Stats -->

		</div><!-- END: Campaigns Block -->

	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { formatCash } from '@/utilites';
import { getRequestCampaignCount } from '@/api/agency';
import NiceHint from '@/ui/nice-hint';


export default {
	name: 'DashboardCounters',

	components: {
		NiceHint,
	},

	props: {
		side: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			labels: ['balance', 'requests', 'campaigns'],
			error: null,
			balance_counters: {
				week_sum: 0,
				week_sum_before: 0,
				day_sum: 0,
				day_sum_before: 0,
			},
			requests: {
				total: 0,
				awaiting_approval: 0,
				pre_approved: 0,
				rejected: 0,
				for_week: 0,
				for_day: 0,
			},
			campaigns: {
				total: 0,
				awaiting_approval: 0,
				playing: 0,
				paused: 0,
				finished: 0,
				rejected: 0,
				for_week: 0,
				for_day: 0,
			}
		};
	},


	computed: {
		...mapState('agency', {
			agencyBalanceInCurrency: 'balance',
			agencyCurrency: 'currency',
		}),

		...mapGetters('app', [
			'isSSPViewer',
		]),
	},


	async mounted() {
		await this.getCounters();
	},


	methods: {
		...mapActions('request', {
			requestRequestList: 'requestList'
		}),


		formatCash,


		async getCounters() {
			let response = null;
			try {
				response = await getRequestCampaignCount();

				this.$set(this, 'balance_counters', Object.freeze(response.balance_counters));
				this.$set(this, 'requests', Object.freeze(response.requests));
				this.$set(this, 'campaigns', Object.freeze(response.campaigns));
			}
			catch (error) {
				const resp = error.response;
				this.$log.error(`Error ${resp && resp.status}. Failed to get a counters.`, resp);
				this.error = Object.freeze({
					status: resp.status,
					statusText: resp.status,
					data: resp.data,
				});

				throw error;
			}

			this.error = null;
			return response;
		}
	},
};
</script>

<style lang="sass" scoped>
.counters-row
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	margin-bottom: 40px
	min-height: 245px
	border-radius: 10px
	border: 1px solid #DDDDDD
	box-sizing: border-box
	min-width: fit-content

	&>*
		border-right: 1px solid #dddddd
		&:last-child
			border: none

.container
	padding-left: 15px
	padding-top: 10px

	display: flex
	flex-direction: column
	padding: 22px 16px

.stat-wrapper
	margin: auto 0

.requests
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	align-items: center
	column-gap: 15px
	row-gap: 15px

	& > hr
		border: 1px solid #dddddd
		border-width: 0 0 1px
		grid-column: span 3
		margin: 0

.value-wrapper
	display: flex
	flex-direction: column
	margin-top: -7px
	font-size: 12px

.total
	font-weight: 300
	font-size: 36px
	line-height: (42 / 36)

.total_balance
	font-weight: 300
	font-size: 50px
	line-height: (60 / 50)

.label-wrapper
	display: flex
	flex-direction: row
	justify-content: space-between

.header
	display: flex
	align-items: flex-start

.label
	font-size: 13.5px
	line-height: 16px
	color: #000000
	text-decoration: underline

	margin-bottom: 16px
	margin-right: 5px

.timing
	display: flex
	flex-direction: row
	justify-content: space-between
	margin-top: 35px
	max-width: 200px

.value-wrapper__icon
	display: inline-block
	width: 16px
	height: 16px
	margin: -1.5px 5px -1.5px 0
	vertical-align: auto

.value
	display: flex
	align-items: center
	margin-top: 5px

.empty-balance-label
	font-weight: 300
	font-size: 20px
	line-height: 23px

	color: #919191

.request-button
	text-decoration: none

.request-button-body
	width: 150px
	height: 31px

	background: rgba(45, 195, 183, 0.2)
	border-radius: 5px

	display: flex
	flex-direction: row
	align-items: center
	text-transform: uppercase
	justify-content: center

.request-button-text
	font-weight: bold
	font-size: 11px
	line-height: 13px

	color: #2CC3B7;

.request-button-icon
	width: 10px
	height: 10px
	margin: -1.5px 8px -1.5px 0;

	align-items: center
	color: #2DC3B7;
</style>
