<template>
	<Transition name="modal-transition">

		<NiceModal
			@close="close"
		>

			<section class="advertiser-add-container">

				<header class="header">
					<NiceIcon2
						class="header__icon"
						icon="plus-34"
					/>

					<h2 class="header__title">{{ $t('advertiser.add_advertiser') | capitalize }}</h2>
				</header>

				<NiceInput
					v-model="company.name"
					:invalid="errors.name"
					:label="$t('advertiser.company_name_label') + '*' | capitalize"
				/>

				<NiceActionList
					v-if="isSSPAdminOrApprover && company.status"
					:key="company.id"
					:status="company.status"
					:list="statusList"
					icons-in-list
					hide-on-click
					class="status-list"

					@change="company.status=$event"
				/>

				<NiceInput
					v-model="company.email"
					:invalid="errors.email"
					:label="$t('advertiser.company_email_label') + '*' | capitalize"
					placeholder="name@company.tld"
				/>

				<NiceInput
					v-model="company.phone"
					:invalid="errors.phone"
					:label="$t('advertiser.company_phone_label') + '*' | capitalize"
				/>
				<!-- :placeholder="+7 999 1112233" -->

				<NiceInput
					v-model="company.contact_details"
					:invalid="errors.contact_details"
					:label="$t('advertiser.company_contact_details_label') + '*' | capitalize"
				/>

				<!-- <NiceInput
					v-model="company.site"
					:invalid="errors.site"
					:label="$t('advertiser.company_website_label') | capitalize"
					placeholder="http://company.tld"
				/> -->

				<div
					v-if="errorGeneral"
					class="error"
				>{{ errorGeneral }}</div>

				<div class="footer__actions">
					<NiceButton2
						:disabled="isBusy"
						palette="gray"
						@click="close"
					>{{$t('common.cancel')}}</NiceButton2>

					<NiceButton2
						:disabled="isBusy || !isFormValid"
						@click="addOrEditAdvertiser"
					>
						<template v-if="!isEdit">{{$t('common.add')}}</template>
						<template v-else>{{$t('common.save')}}</template>
					</NiceButton2>
				</div>

			</section>

		</NiceModal>
	</Transition>
</template>


<script>
import { mapState, mapGetters } from 'vuex';

import NiceModal from '@/ui/nice-modal';
import NiceInput from '@/ui/nice-input';
import NiceActionList from '@/ui/nice-action-list';

import advertiserApi from '@/api/advertiser.js';

import {
	STATUS_AWAITING_APPROVAL,
	STATUS_APPROVED,
} from '@/constants/advertiser.js';


export default {
	name: 'PageAddAdvertiser',


	components: {
		NiceModal,
		NiceInput,
		NiceActionList,
	},


	props: {
		// if set - we are editing advertiser (SSP)
		advertiserId: {
			required: false,
		}
	},


	data() {
		return {
			// if in the edit mode
			advertiser: null,

			company: {
				name: '',
				email: '',
				phone: '',
				contact_details: '',
				site: '',
			},

			requiredFields: [
				'name',
				'email',
				'phone',
				'contact_details',
			],

			errors: {
				name: null,
				email: null,
				phone: null,
				contact_details: null,
				site: null,
			},

			errorGeneral: null,

			// for preloader
			business: [],
		};
	},


	async mounted() {
		if (this.advertiserId) {
			this.getAdvertiserAndUpdateForm();
		}
	},


	computed: {
		...mapState('app', {
			appSide: 'side',
		}),

		...mapGetters('app', [
			'isSSPAdminOrApprover',
			'isDSP',
		]),

		isEdit() {
			return this.advertiserId;
		},

		isBusy() {
			return !!this.business.length;
		},

		isFormValid() {
			for (let f of this.requiredFields) {
				if (!this.company[f]) {
					return false;
				}
			}

			return true;
		},

		statusList() {
			return [
				{
					value: STATUS_AWAITING_APPROVAL,
					icon: 'warn',
					label: this.$options.filters.capitalize(this.$t('advertiser.status_change_awaiting_approval')),
					labelAction: this.$options.filters.capitalize(this.$t('advertiser.status_change_awaiting_approval')),
				},
				{
					value: STATUS_APPROVED,
					icon: 'play',
					label: this.$options.filters.capitalize(this.$t('advertiser.status_change_approved')),
					labelAction: this.$options.filters.capitalize(this.$t('advertiser.status_change_approved')),
				},
			];
		},
	},


	methods: {
		close() {
			this.$router.push({ name: 'advertiser-list' });
			this.$emit('close-add-advertiser');
		},


		async addOrEditAdvertiser() {
			if (!this.isFormValid) {
				return;
			}

			this.resetErrors();

			try {
				if (this.isEdit) {
					await advertiserApi.patch(this.advertiserId, this.company);
				}
				else {
					await advertiserApi.create(this.company);
				}
				this.close();
			}
			catch(error) {
				if (error.response.data) {
					for (var f in error.response.data) {
						this.errors[f] = error.response.data[f][0];
					}
				}
				else {
					this.errorGeneral = this.$t('advertiser.failed_to_create_advertiser');
				}
			}
		},


		async getAdvertiserAndUpdateForm() {
			this.advertiser = await advertiserApi.retrieve(this.advertiserId);

			this.company = {
				...JSON.parse(JSON.stringify(this.advertiser)),
			};
		},


		resetErrors() {
			this.errorGeneral = null;

			for (var f in this.errors) {
				this.errors[f] = null;
			}
		},
	},
};
</script>


<style lang="sass" scoped>
.advertiser-add-container
	width: 360px
	padding: 30px 40px

	.nice-input,
	.status-list
		margin-top: 25px

	.status-list

		&::v-deep > div > div
			display: flex

.header
	display: flex
	align-items: center
	margin-bottom: 30px

	& > *
		margin-left: 15px

		&:first-child
			margin-left: 0

.error
	color: red
	margin-top: 35px

.header__icon
	--ni-icon-sign: var(--main_color)
	width: 34px
	height: 34px
	flex-shrink: 0

.header__title
	font-size: 16px
	line-height: 20px
	// margin: calc((34px - 20px) / 2) 0
	font-weight: normal

.footer__actions
	display: flex
	align-items: center
	justify-content: flex-end
	margin-left: auto
	margin-top: 40px

	& > *
		margin-left: 35px

</style>
