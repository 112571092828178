<template>
	<section class="review-tab layout-center805">
		<main class="wrapper">
			<div v-if="error" class="error-message">
				<p>{{ error }}</p>
			</div>
			<div v-else-if="history.length">
				<table class="nice-table">
					<thead>
						<tr>
							<th>{{ $t('campaign.date') }}</th>
							<th>{{ $t('campaign.old_value') }}</th>
							<th>{{ $t('campaign.new_value') }}</th>
							<th>{{ $t('campaign.login') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in history" :key="item.date">
							<td class="col-text">{{ formatDate(item.date) }}</td>
							<td class="col-text">
								<HistoryItem :item="item" :isNewValue="false" />
							</td>
							<td class="col-text">
								<HistoryItem :item="item" :isNewValue="true" />
							</td>
							<td class="col-text">{{ item.user.username }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<p>{{ $t('campaign.history_not_found') }}</p>
			</div>
		</main>
	</section>
</template>

<script>
import moment from 'moment';
import { requestHistory } from '@/api/campaign';
import HistoryItem from '@/components/creative/history-item';

export default {
	name: 'TabHistory',

	components: {
		HistoryItem
	},

	props: {
		instance: {
			type: Object,
			required: true,
		},
		status: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			history: [],
			error: null
		};
	},

	methods: {
		async fetchHistory() {
			try {
				const response = await requestHistory(this.instance.id);
				var changes = [...response['audit_changes'], ...response['campaign_changes']];
				changes.sort((a, b) => new Date(b.date) - new Date(a.date));
				this.history = changes;
			} catch (error) {
				this.error = this.$t('campaign.history_error');
			}
		},

		formatDate(date) {
			return moment(date).format('DD.MM.YYYY HH:mm:ss');
		},
	},

	watch: {
		status(newStatus, oldStatus) {
			if (newStatus !== oldStatus) {
				this.fetchHistory();
			}
		}
	},

	created() {
		this.fetchHistory();
	},
};
</script>


<style lang="sass" scoped>
.nice-table
	width: 100%

.error-message
	color: red
</style>
