import AUDIT_STATUS_CODES from '@/constants/audit-status-codes';

/**
 * Extend status campaign APPROVED ('playing')
 * If all creatives are not approved then show 'pending audit'
 * If some audits are not approved then show 'partially playing'
 *
 * @param campaign
 */
export function getExtendedStatus(campaign) {
	const status = campaign.status[0].status;
	if (campaign.ads.length > 0 && status == AUDIT_STATUS_CODES.APPROVED) {
		const allNotApproved = campaign.ads.every(ad =>
			ad.audits.every(audit => audit.status !== AUDIT_STATUS_CODES.APPROVED)
		);

		if (allNotApproved) {
			return AUDIT_STATUS_CODES.PENDING_AUDIT_EXTENDED_APPROVED;
		}

		const countApproved = campaign.ads.filter(ad => 
		  ad.audits.length > 0 && ad.audits.every(audit => audit.status === AUDIT_STATUS_CODES.APPROVED)
		).length;

		if (countApproved < campaign.ads.length) {
			return AUDIT_STATUS_CODES.PARTIALLY_APPROVED_EXTENDED_APPROVED;
		}
	}
	return status;
}
