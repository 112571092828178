<template>
	<section class="page-section">
		<div class="cl-container">
			<h2 class="cl-pad cl-xs12 cl-sm12 cl-md12 cl-lg12 cl-xl12" >Multiselect</h2>
			<div class="cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad">
				<nice-select
					id="multiselect1"
					v-model="mltslctValue"
					placeholder="select"
					labeled
					label="multiselect"
					:options="slctOptions"
					multiple
				/>
				<pre>{{mltslctValue}}</pre>
			</div>
			<div class="cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad">
				<nice-select
					id="multiselect2"
					v-model="mltslctValue"
					placeholder="select"
					labeled
					label="multiselect"
					:options="slctOptions"
					multiple
				/>
			</div>
			<h2 class="cl-pad cl-xs12 cl-sm12 cl-md12 cl-lg12 cl-xl12" >Без опций будет создавать их на основе текущих значений, причём при снятии галочки, соответствующая опция будет удаляться из списка доступных</h2>
			<div class="cl-xs6 cl-sm3 cl-md2 cl-lg1 cl-xl1 cl-pad">
				<nice-select
					v-model="multiselectWithoutOptions"
					placeholder="select"
					label="multiselect"
					multiple
				/>
			</div>
		</div>
	</section>
</template>

<script>
import NiceSelect from '@/ui/nice-select';

export default {
	name: 'TabMultiselect',
	components: {
		NiceSelect,
	},
	data() {
		return {
			mltslctValue: ['val1'],
			slctOptions: [
				{ value: 'val1', label: 'VERY-VERY-VERY-VERY-VERY-VERY-VERY-VERY-LONG-OPTION-NAME', },
				{ value: 'val2', label: 'Option 2', },
				{ value: 'val3', label: 'Option 3', },
				{ value: 'val4', label: 'Option 4', },
			],
			multiselectWithoutOptions: [ 'option 1', 'option 2', 'option 3' ],
		};
	},
};
</script>
