<!-- TODO: It becomes all-in-one component. graph part MUST be refactored out -->
<template>
	<div>
		<div class="settings">
			<div class="selectors">
				<nice-stats-value
					v-for="(selector,id) in selectors"
					:key="id"
					:label="selector.label"
					:value="selector.value"
					:selected="id==selected"
					:style='{
						"--bgcolor": selector.bgcolor,
						"--bordercolor": selector.bordercolor,
					}'
					@click.native="selectMe(id)"
				/>
			</div>

			<div class="dropdowns">

				<nice-select
					v-if="platformsList.length"
					v-model="actualPlatforms"
					:options="platformsList"
					multiple
					:label="$t('common.platform') | capitalize"
					placeholder=""
					class="platform"
					@input="rangeUpdated"
				/>

				<nice-select
					v-if="agenciesList.length"
					v-model="actualAgency"
					:options="agenciesList"
					:label="$t('common.dsp_agency') | capitalize"
					placeholder=""
					class="platform"
					@input="rangeUpdated"
				/>

				<nice-select
					v-if="campaignsList.length"
					v-model="actualCampaign"
					:options="campaignsList"
					:label="$t('campaign.verbose_name') | capitalize"
					placeholder=""
					class="platform"
					@input="rangeUpdated"
				/>

				<!-- TODO: remove `v-if` - use `data.range` or `data.rangeType` -->
				<nice-datetime
					v-if="rangeType != 'hourly'"
					v-model="datetimeranged"
					range
					:format="formatDefault"
					:formatted="formattedDefault"
					:label="$t('common.time_period') | capitalize"
					:placeholder="$t('common.time_period_placeholder') | capitalize"
					@input="rangeUpdated"
				/>
				<nice-datetime
					v-else
					v-model="datetimeranged.start"
					onlyDate
					:format="formatDefault"
					:formatted="formattedDefault"
					:label="$t('common.time_period') | capitalize"
					:placeholder="$t('select_date_placeholder') | capitalize"
					@input="rangeUpdated"
				/>

				<nice-select
					v-model="rangeType"
					:options="rangeTypesLocalized"
					:label="$t('scale') | capitalize"
					:placeholder="$t('scale') | capitalize"
					class="scale"
					@input="rangeUpdated"
				/>
			</div>
		</div>

		<div class="graph">
			<nice-stats-graph
				v-slot="{ slotProps }"

				:height="height"
				:dataset="dataset"
				:units="units"
				:type="type"
				:graph-color="сolorSchemes[selected].line"
				:fill-color="сolorSchemes[selected].fill"
				:skipBottomLegendLabels="skipBottomLegendLabels"
				:formatHint="formatHint"
				:maxBidHistoryData="maxBidHistoryGraphData"
			>
				<slot :statsData="slotProps"></slot>
			</nice-stats-graph>
		</div>
	</div>
</template>

<script>
import * as moment from 'moment';
import { normalizeValue } from '@/utilites';

import NiceStatsValue from '@/ui/nice-stats/nice-stats-value';
import NiceStatsGraph from '@/ui/nice-stats/nice-stats-graph';
import NiceDatetime from '@/ui/nice-datetime';
import NiceSelect from '@/ui/nice-select';


// days
const DEFAULT_DATE_PERIOD = 7;


export default {
	name: 'NiceStats',

	components: {
		NiceStatsGraph,
		NiceDatetime,
		NiceSelect,
		NiceStatsValue
	},

	props: {
		graphData: {
			type: Array,
			required:true,
		},
		labelData: {
			type: Array,
			required:true,
		},


		dateFrom: {
			default: null,
		},
		dateTill: {
			default: null,
		},


		platforms: {
			default() {
				return [];
			},
		},
		platformsList: {
			default() {
				return [];
			},
		},

		agency: {
			default: null,
		},
		agenciesList: {
			default() {
				return [];
			},
		},

		campaign: {
			default: null,
		},
		campaignsList: {
			default() {
				return [];
			},
		},

		// TODO: arbitrary number of graphs
		impressions: {
			default: 0,
		},
		spent: {
			default: 0,
		},
		adPlays: {
			default: 0,
		},

		formatHint: {
			type: Function,
			default: null,
		},

		selectorLabels: {
			default() {
				return ['Impressions', 'Spent', 'Ad Plays'];
			},
		},

		maxBidHistory: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	data() {
		// TODO: set via props?
		let defaultRangeType = 'daily';

		let dateTill = this.dateTill ? moment(this.dateTill) : moment();
		let dateFrom = moment(dateTill).subtract(DEFAULT_DATE_PERIOD, 'days');

		// future proof
		if (defaultRangeType == 'hourly') {
			dateFrom = moment(dateTill);
		}

		return {
			//width:600,
			height:300,
			vertLegendCount: 5,
			hourlyLabels: [
				'00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
				'06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
				'12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
				'18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
				// next day
				'00:00'
			],

			//format:             "YYYY-MM-DD",
			formatDefault:      'YYYY-MM-DD',
			formatForHourly:    'YYYY-MM-DD HH:mm',

			//formatted:          "D MMM`YY",
			formattedDefault:   'D MMM`YY',
			formattedForHourly: 'D MMM`YY HH:mm',

			defaultGraphType:   'cols',

			actualPlatforms: this.platforms,
			actualAgency: this.agency,
			actualCampaign: this.campaign,

			rangeType:          defaultRangeType,
			rangeTypes: [
				// TODO: BUG: wrong request dates and keys!
				// {
				// 	value:  'hourly',
				// 	label:  'Hourly',
				// 	type:   'lines',
				// },
				{
					value:  'daily',
					label:  'Daily',
					type:   'cols',
				},
				{
					value:  'weekly',
					label:  'Weekly',
					type:   'cols',
				},
				{
					value:  'monthly',
					label:  'Monthly',
					type:   'cols',
				},
			],

			сolorSchemes:[
				{
					fill:'#F0ECFC',
					line:'#6240D9',
				},
				{
					fill:'#E2F7FA',
					line:'#3CC6DF',
				},
				{
					fill:'#DCF0FC',
					line:'#139BE8',
				},
			],

			selected: 0,

			datetimeranged: {
				start: dateFrom,
				end: dateTill,
			},
		};
	},


	mounted() {
		this.rangeUpdated();
		this.$emit('selected', this.selected);
	},


	computed: {
		// TODO: set via props?

		rangeTypesLocalized() {
			return this.rangeTypes.map(type => ({ ...type, label:this.$t('range_type_' + type.value) }));
		},

		selectors() {
			return [
				{
					label: this.selectorLabels[0],
					value: this.impressions,
					bgcolor: '#F0ECFC',
					bordercolor: '#7C5ECF',
				},
				// spent / income
				{
					label: this.selectorLabels[1],
					value: this.spent,
					bgcolor: '#E2F7FA',
					bordercolor: '#3CC6DF',
				},
				{
					label: this.selectorLabels[2],
					value: this.adPlays,
					bgcolor: '#DCF0FC',
					bordercolor: '#139BE8',
				},
			];
		},

		range() {
			return this.rangeType != 'hourly';
		},

		units() {
			return this.selectors[this.selected].label;
		},

		type() {
			let newType = this.rangeTypes.find(el => el.value == this.rangeType);
			if (newType != undefined) {
				return newType.type;
			} else {
				return this.defaultGraphType;
			}
		},

		dataset() {
			let d = {
				value: [],
				valueRange: [0, 100],
				columns: [],
				rows: [],
			};

			if (this.graphData.length == 0) {
				return d;
			}

			let max = Math.max(...this.graphData[this.selected]);
			let step = max / (this.vertLegendCount - 1);
			let vert = Array(this.vertLegendCount).fill(null).map((emptyness, index) => normalizeValue(index * step));

			d.value = this.graphData[this.selected];
			d.valueRange = [0, max];

			if (this.rangeType == 'hourly') {
				d.columns = this.hourlyLabels;
			} else {
				d.columns = this.labelData;
			}

			d.rows = vert;

			return d;
		},

		skipBottomLegendLabels() {
			return this.rangeType == 'hourly' ? 3 : 0;
		},

		/**
		 * TODO: adapt
		 */
		maxBidHistoryGraphData() {
			return this.maxBidHistory;
		},
	},


	methods: {
		/**
		 * This can be captured by a watcher
		 */
		rangeUpdated() {

			// fix deselect of nice-select and date picked
			if (!this.rangeType || !this.datetimeranged) {
				return;
			}

			let dateFrom = this.datetimeranged.start || moment().format(this.formatDefault);
			let dateTill = this.datetimeranged.end || moment().format(this.formatDefault);

			// convert to moment
			dateFrom = moment(dateFrom);
			dateTill = moment(dateTill);

			// default reset / truncate
			dateFrom.startOf('hour');
			dateTill.endOf('day');

			// truncate `dateFrom`
			if (this.rangeType === 'daily') {
				dateFrom.startOf('day');
			}
			else if (this.rangeType === 'weekly') {
				dateFrom.startOf('isoWeek');
			}
			else if (this.rangeType === 'monthly') {
				dateFrom.startOf('month');
			}


			if (dateFrom > dateTill) {
				dateTill = dateFrom.clone();
			}

			// use the same date (start) for hourly graphs
			if (this.rangeType == 'hourly') {
				dateTill = dateFrom.clone();
			}

			this.$emit('range-updated', {
				rangeType: this.rangeType,
				dateFrom: dateFrom,
				dateTill: dateTill,
				platforms: this.actualPlatforms,
				agency: this.actualAgency,
				campaign: this.actualCampaign,
			});
		},

		selectMe(id) {
			this.selected = id;
			this.$emit('selected', id);
		}
	},
};
</script>

<style lang="sass" scoped>

::v-deep .datepicker
	right: 0

.settings
	display: grid
	grid-template-columns: 1fr 2fr
	margin-bottom: 30px

.selectors
	display: flex
	flex-direction: row

	> *
		flex-grow: 1

	> *:not(:last-child)
		margin-right: 10px

.dropdowns
	display: flex
	flex-direction: row
	justify-content: flex-end
	margin-left: 30px

	> *
		flex-grow: 1
		max-width: 200px
		margin-right: 20px

.platform
	width: 130px

.scale
	width: 110px
</style>
