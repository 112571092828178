<template>
	<symbol :id="`${prefix}warn`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" class="base" />
		<circle r="3" transform="matrix(1 0 0 -1 8 8)" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolWarn',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-yellow})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-white})
</style>
