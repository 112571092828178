<template>
	<section class="layout-full_width" >
		<h1>Buttons</h1>

		<p>
			<nice-button bordered >button</nice-button>
		</p>

		<p>
			Button in text, look this <nice-button inline >text inline button</nice-button>
		</p>

		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-input
					id="test-input-placeholder"
					class="cl-pad cl-xs4 cl-md3"
					type="text"
					placeholder="Type placeholder here"
				/>
				<nice-button bordered class="cl-pad cl-xs4 cl-md3" >button</nice-button>
			</div>
		</div>

		<h2>Palette / Theme examples</h2>
		<table class="nice-table" >
			<thead>
				<tr>
					<th>Theme \ Palette</th>
					<th>default</th>
					<th
						v-for="palette in AVAILABLE_PALETTES"
						:key="palette"
						v-html="palette"
					/>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="theme in AVAILABLE_THEMES"
					:key="theme.name"
				>
					<td class="name" >{{ theme.name }}</td>
					<td>
						<nice-button
							v-bind="theme.props"
						>
							default
						</nice-button>
					</td>
					<td
						v-for="palette in AVAILABLE_PALETTES"
						:key="`${theme.name}_${palette}`"
					>
						<nice-button
							:palette="palette"
							v-bind="theme.props"
						>
							palette="{{ palette }}"
						</nice-button>
					</td>
				</tr>
			</tbody>
		</table>

		<h2>Icons</h2>
		<p>
			Icons.<br />
			<code class="code-preview">icon</code> prop sets an icon.<br />
			<code class="code-preview">icon-placement</code> prop sets an icon placement: <i>start</i> or <i>end</i>.<br />
			<code class="code-preview">icon-state</code> - prop providing to the icon as <i>state</i>.<br />
			<code class="code-preview">icon-painted</code> - providing text color to icon sign and disable color to icon base.
		</p>
		<table>
			<tr>
				<td><nice-button palette="red" bordered icon="basket-14" icon-painted >delete</nice-button></td>
				<td><code class="code-preview">&lt;nice-button-2 palette="red" bordered icon="basket-14" icon-painted &gt;delete&lt;/nice-button&gt;</code></td>
			</tr>
			<tr>
				<td><nice-button icon="arrow-14" icon-state="-90" icon-placement="end" icon-painted >next</nice-button></td>
				<td><code class="code-preview">&lt;nice-button-2 icon="arrow-14" icon-state="-90" icon-placement="end" icon-painted &gt;next&lt;/nice-button&gt;</code></td>
			</tr>
			<tr>
				<td><nice-button palette="gray" icon="plus-17" >add</nice-button></td>
				<td><code class="code-preview">&lt;nice-button-2 palette="gray" icon="plus-17" &gt;add&lt;/nice-button&gt;</code></td>
			</tr>
			<tr>
				<td><nice-button bordered icon="diagram" icon-state="45" >45%</nice-button></td>
				<td><code class="code-preview">&lt;nice-button-2 bordered icon="diagram" icon-state="45" &gt;45%&lt;/nice-button&gt;</code></td>
			</tr>
		</table>
		<p>
			<pre class="code-preview" >&lt;nice-button
:icon="$icon_name"
:icon-placement="$icon_placement"
:icon-state="$icon_state"
&gt;$button_caption&lt;/nice-button&gt;</pre>
		</p>
	</section>
</template>

<script>
import NiceButton from '@/ui/nice-button-2';
import NiceInput from '@/ui/nice-input';
import { AVAILABLE_PALETTES } from '@/ui/nice-button-2';

export default {
	name: 'TabButtons',


	components: {
		NiceButton,
		NiceInput,
	},


	data() {
		return {
			AVAILABLE_PALETTES,
			AVAILABLE_THEMES: [
				{
					name: 'default',
					props: {},
				},
				{
					name: 'bordered',
					props: {
						bordered: true,
					},
				},
				{
					name: 'watercolor',
					props: {
						watercolor: true,
					},
				},
				{
					name: 'bordered watercolor',
					props: {
						bordered: true,
						watercolor: true,
					},
				},
				{
					name: 'filled',
					props: {
						filled: true,
					},
				},
			],
		};
	},
};
</script>

<style lang="sass" scoped>
// .preview
// 	& > p
// 		margin-top: 1em
// 		margin-bottom: 2em

// ::v-deep .nice-button
// 	margin: .5em 0

// 	&:not(:last-child)
// 		margin-right: .8em

// pre.code-preview
// 	width: auto

</style>
