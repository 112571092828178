<template>
	<section class="table-top-pannel cl-container" >
		<section class="cl-xs9">
			<component
				:is="addRoute ? 'router-link' : 'button'"
				v-if="addTitle"
				id="add_request_btn"
				class="create_package"
				:to="addRoute"
				@click="$emit('add')"
			>
				<nice-icon icon="add" :params="{ strokeWidth: 1 }" />
				{{ addTitle }}
			</component>

			<button type="button" class="search-button" >
				<nice-icon icon="search" />
			</button>
			<input
				v-model="search"
				class="search-input"
				type="text"
				:placeholder="$t('search')"
			>
		</section>

		<section class="cl-xs3 cl-container align-end" >
			<nice-checkbox
				v-if="showOnlyMy"
				v-model="by_me"
				class="cl-xs6"
				:caption="$t('only_mine') | capitalize"
			/>
			<nice-select
				v-if="statusList.length"
				v-model="status"
				class="cl-xs6"
				:options="statusList"
			/>
		</section>
	</section>
</template>

<script>
import _ from 'underscore';
import NiceCheckbox from '@/ui/nice-checkbox.vue';
import NiceSelect from '@/ui/nice-select';

const FIELDS = ['search', 'by_me', 'status'];
const NULL_VALUES = ['---', null, ''];


export default {
	name: 'TableTopPanel',


	components: {
		NiceCheckbox,
		NiceSelect,
	},


	data() {
		return {
			..._.reduce(FIELDS, (setters, field) => {
				setters[field] = this.value[field] || this.default[field];
				return setters;
			}, {}),

			initialized: false,
		};
	},


	props: {
		/**
		 * filter data
		 * `value` is for v-model binding
		 */
		value: {
			type: Object,
			required: true,
		},

		/**
		 * Default values
		 */
		default: {
			type: Object,
			default() {
				return {
					search: '',
					by_me: false,
					status: '---',
				};
			},
			required: false,
		},

		/**
		 * Query params equal filter fields
		 */
		queryParams: {
			type: Object,
			default() {
				return {
					search: 's',
					by_me: 'bm',
					status: 'st',
				};
			},
			required: false,
		},

		/**
		 * Create new button text
		 */
		addTitle: {
			type: String,
			default: null,
			required: false,
		},

		/**
		 * Create new button text
		 */
		addRoute: {
			type: Object,
			default: null,
			required: false,
		},

		/**
		 * Status filter choice list
		 */
		statusList: {
			type: Array,
			default: (/* factory */) => [],
			required: false,
		},

		/**
		 * Show only my filter
		 */
		showOnlyMy: Boolean,
	},


	computed: {
		/**
		 * Return cleared filter result
		 */
		result() {
			let filters = {};

			if (this.initialized) {
				_.each(FIELDS, (item) => {
					this.processParam(item, filters);
				});
			}

			return filters;
		},
	},


	methods: {
		/**
		 * init params
		 */
		initParam(param) {
			const query_param = this.queryParams[param];
			const query = this.$route.query[query_param];

			if (query) {
				this[param] = query;
			} else if (!this.value[param]) {
				this[param] = this.default[param];
			}
		},

		/**
		 * Trigger input event
		 */
		triggerInput() {
			const data = this.result;
			let query = _.clone(this.$route.query);

			_.each(FIELDS, (param) => {
				const queryParam = this.queryParams[param];
				const value = data[param];

				if (!_.isUndefined(value)) {
					query[queryParam] = value;
				} else {
					delete query[queryParam];
				}
			});

			if (!_.isEqual(query, this.$route.query)) {
				this.$router.push({ query: query });
			}

			this.$emit('input', data);
		},

		/**
		 * Handler calc filter data
		 */
		processParam(param, data) {
			const value = this[param];

			let availableValue;
			if (_.contains(NULL_VALUES, value)) {
				availableValue = false;
			} else {
				availableValue = true;
			}

			switch(param) {
			case 'by_me':
				if (value === false) {
					availableValue = false;
				}
				break;
			}

			if (availableValue) {
				data[param] = value;
			}
		},
	},


	watch: {
		/**
		 * Watch changes
		 */
		result() {
			this.triggerInput();
		},

		/**
		 * Watch changes
		 */
		value(data) {
			_.mapObject(data, (val, key) => {
				this[key] = val;
			});
		},
	},


	created() {
		_.each(FIELDS, (item) => {
			this.initParam(item);
		});

		this.initialized = true;
	},
};
</script>

<style lang="sass" scoped >
$table-top-pannel_heigth: 29px
$search_btn-left-padding: 10px

.table-top-pannel
	// margin-top: 19px
	height: $table-top-pannel_heigth

.create_package
	@extend %button__text

	font-size: 11px
	font-weight: bold
	line-height: $table-top-pannel_heigth
	text-decoration: none

	+ .search-button
		margin-left: 80px - $search_btn-left-padding

.nice-icon__add
	margin-right: 12px
	height: $table-top-pannel_heigth
	vertical-align: top

.search-button
	@extend %button__text

	height: $table-top-pannel_heigth
	padding: 0 5px 0 $search_btn-left-padding
	margin-left: 14px

	.nice-icon__search
		vertical-align: middle
		margin-bottom: 1px

.search-input
	border: none
	height: $table-top-pannel_heigth
	padding: 0 5px

	text-transform: uppercase
	font-size: 11px
	line-height: $table-top-pannel_heigth
	color: $clr__semidarkgray
	width: 200px
</style>
