<template>
	<symbol :id="`${prefix}pencil-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M12.4429 9.17131C13.1096 8.50464 14.1905 8.50464 14.8571 9.17131C15.5238 9.83797 15.5238 10.9189 14.8571 11.5855L10.4074 16.0353L7.99317 16.0353L7.99317 13.6211L12.4429 9.17131Z" class="sign" stroke-width="2"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPencil24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})
	fill: none
</style>
