<template>
	<section class="page-section" >
		<h1>DeviceCardListSummary</h1>
		<section
			class="cards-list"
		>
			<device-card-list-summary
				v-for="(pckg, i) in packageList"
				:key="i"

				:adPlays="pckg.adPlays"
				:impressions="pckg.impressions"
				:inventory="pckg.inventory"
				:specs="pckg.specs"
			/>
		</section>
	</section>
</template>

<script>
import DeviceCardListSummary from '@/components/device/card-list-summary';

const packageList = [
	{
		adPlays: {
			value: 1323,
			total: 3000,
		},
		impressions: {
			value: 335421980,
			total: 356100000,
		},
		inventory: {
			value: 175,
			total: 3000,
		},
		specs: [
			{
				type: 'monitor',
				resolution: '1920x1080',
				count: 21,
			},
			{
				type: 'monitor',
				resolution: '1080x1920',
				count: 29,
			},
			{
				type: 'monitor',
				resolution: '800x600',
				count: 37,
			},
			{
				type: 'audio',
				count: 45,
			},
		]
	},
	{
		adPlays: {
			value: 2,
			total: 2,
		},
		impressions: {
			value: 13,
			total: 67,
		},
		inventory: {
			value: 180,
			ttal: 1550,
		}
	},
	{
		adPlays: {
			value: 1323,
			total: 3000,
		},
		impressions: {
			value: 1354219,
			total: 356100000,
		},
		inventory: {
			value: 175,
			ttal: 3000,
		}
	},
];


export default {
	name: 'TabPackage',


	components: {
		DeviceCardListSummary,
	},


	data() {
		return {
			packageList,
		};
	},
};
</script>

<style lang="sass" scoped >
.cards-list
	display: flex

	> *
		margin-right: 10px
</style>
