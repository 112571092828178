<template>
	<tippy v-bind="tippyConfig" class="nice-hint">
		<template v-slot:trigger>
			<slot name="trigger">
				<nice-icon-2
					class="ni_hint--icon"
					:icon="icon"
					v-bind="$attrs"
				/>
			</slot>
		</template>
		<div class="ni_hint--popup">
			<slot>
				<span v-html="hint" />
			</slot>
		</div>
	</tippy>
</template>

<script>
export default {
	name: 'NiceHint',


	props: {
		hint: {
			type: String,
		},

		icon: {
			type: String,
			default: 'hint',
		},

		trigger: {
			type: String,
			default: 'mouseenter focus',
		},

		maxWidth: {
			type: Number,
			default: 350
		},

		appendTo: {
			type: [String, Function, Element],
			default: 'parent',
		},
	},


	computed: {
		tippyConfig() {
			const config = {
				distance: 9,
				interactive: true,
				placement: 'top',
				reactive: true,
				trigger: this.trigger,
				maxWidth: this.maxWidth
			};

			if (this.appendTo) {
				config.appendTo = this.appendTo;
			}

			return config;
		},
	},
};
</script>

<style lang="sass" scoped>
.nice-hint
	display: inline-flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center
	align-content: center

.ni_hint--icon
	display: block
</style>
