<template>
	<section :class="[
			'stat-graph',
			type,
		]"
	>
		<div class="label">
			<span class="label-text">{{ units }}</span>
			</div>
		<div class="legend-y">
			<span
				v-for="(row, index) in invertedRows"
				:key="index"
			>
				{{ row }}
			</span>
		</div>
		<div
			ref="container"
			class="graph"
		>
			<svg
			width="100%"
			:height="height"
			:viewBox="`0 0 ${width} ${height}`"
			>
				<path
					:d="shapeString"
					:fill="fillColor"
				/>
				<path
					:d="colsCurveString"
					:stroke="graphColor"
					stroke-width="2"
					fill="none"
				/>
				<line
					v-for="(point, index) in linesCount"
					:key="index"
					:x1="margin + stepX * index"
					:y1="margin"
					:x2="margin + stepX * index"
					:y2="height - margin"
					:stroke="lineColor"
					class="vert"
					:class="{
						selected: index == over && type == 'lines',
					}"
					stroke-width="1"
					stroke-dasharray="1,3"
					vector-effect="non-scaling-stroke"
					stroke-linecap="round"
				/>
				<circle
					v-if="overBool"
					:cx="coords[over].x"
					:cy="coords[over].y"
					:stroke="graphColor"
					r="5"
					fill="white"
					stroke-width="2"
					vector-effect="non-scaling-stroke"
					stroke-linecap="round"
				/>

				<rect
					v-for="(point, index) in coords"
					:key="index + 'rect'"
					:width="hitZoneWidth"
					:height="height"
					:x="point.x - hitZoneWidth / 2"
					class="hit-zone"
					@mouseenter="showInfo(index)"
					@mouseleave="hideInfo()"
				/>

				<!-- max bid changes -->
				<template
					v-for="(maxBid, index) in maxBidHistoryData"
				>
					<line
						v-if="maxBid"
						:key="index + 'maxbid'"
						:x1="margin + stepX * index + hitZoneWidth / 2"
						:y1="margin"
						:x2="margin + stepX * index + hitZoneWidth / 2"
						:y2="height - margin"
						:stroke="maxBidLineColor"
						class="vert"
						stroke-width="2"
						stroke-dasharray="1,3"
						vector-effect="non-scaling-stroke"
						stroke-linecap="round"
					/>
				</template>
			</svg>

			<div
				v-if="overBool"
				ref="tooltip"
				class="tooltip"
				:style="tooltipCoords"
			>
				<slot :slotProps="{ 'over': over, 'maxBidHistoryData': maxBidHistoryData }">
					<h5 class="title">
						{{ dataset.columns[over] || $t('agency.title') }}
					</h5>


					<p
						v-if="formatHint"
						class="content"
						v-html="formatHint({
							'dataset': dataset,
							'maxBidHistoryData': maxBidHistoryData,
							'over': over,
						})"
					>
					</p>
					<p
						v-else
						class="content"
					>
						<span class="label">{{ units || $t('campaign.label') }}</span>
						<span class="value">{{ dataset.value[over] }}</span>
					</p>
				</slot>
			</div>
		</div>

		<div
			class="bottomLegend"
			:style="{
				width: type === 'lines' ? `${marginedSize.w + stepX}px` : `${marginedSize.w}px`,
				'margin-left': type === 'lines' ? `${-stepX / 2 + margin}px` : `${margin}px`,
			}"
		>
			<template>
				<div
					v-for="(col, index) in dataset.columns"
					:key="index"
					class="legend-element"
					:style="{
						width:`${stepX}px`,
					}"
				>
					{{ index % skipBottomLegendLabels ? "" : col }}
				</div>
			</template>
		</div>
	</section>
</template>

<script>
export default {
	name: 'NiceStatsGraph',


	props: {
		dataset: {
			type: Object,
			required: true,
		},
		units: {
			type: String,
			required: true,

		},
		height: {
			type: Number,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		skipBottomLegendLabels: {
			type: Number,
			default: 3,
		},

		formatHint: {
			type: Function,
			default: null,
		},

		graphColor: {
			default: '#6240D9',
		},
		fillColor: {
			default: '#F0ECFC',
		},
		lineColor: {
			default: 'rgb(0, 0, 0)',
		},
		maxBidLineColor: {
			default: 'rgb(45, 195, 183)',
		},

		maxBidHistoryData: {
			type: Array,
			default() {
				return [];
			},
		},
	},


	data() {
		return {
			margin:         10,
			outerScale:     4,
			curveSmooth:    6,
			bezierPart:     3,
			over:           -1,
			width:          400,

			// ???
			tooltipWidth:   160,
			tooltipHeight:  60,
		};
	},


	mounted() {
		this.width = this.$refs.container.clientWidth;
		this.observer = new ResizeObserver(entries => {
			for(let entry of entries){
				this.width = entry.contentRect.width;
			}
		});
		this.observer.observe(this.$refs.container);
	},


	computed: {
		tooltipCoords() {
			let x = 0;
			let y = 0;

			// TODO: ???
			const horizontalSpacer = 8;

			if (this.overBool) {
				x = this.coords[this.over].x + horizontalSpacer;
				if (x + this.tooltipWidth > this.width) {
					x = this.coords[this.over].x - this.tooltipWidth - horizontalSpacer;
				}

				y = this.clamp(
					this.coords[this.over].y - this.tooltipHeight / 2,
					this.margin,
					this.height - this.margin - this.tooltipHeight
				);
			}

			return {
				left: x + 'px',
				top: y + 'px'
			};
		},

		// is hovered over a tooltip
		overBool() {
			return this.over >= 0 ? true : false;
		},

		lineString() {
			let str = '';

			let prePoint = this.startPoint.x + ',' + this.startPoint.y + ' ';
			let postPoint =  this.endPoint.x + ',' + this.endPoint.y + ' ';

			for(let i = 0; i < this.dataset.value.length; i++) {
				str += Math.round(this.coords[i].x) + ',' + Math.round(this.coords[i].y) + ' ';
			}

			let result = 'M ' + prePoint + str + postPoint;
			return result;
		},

		colsCurveString() {
			let str = '';

			// we need 3 coords to calculate this
			// HACK:
			if (this.drawData.pathCoords.length < 3) {
				let height = this.marginedSize.h + this.margin;
				let width = this.marginedSize.w + this.margin;

				return [
					'M 10,' + (height - 1),
					'L ' + width + ',' + (height - 1),
					'L ' + width + ',' + height,
					'L 10,' + height,
				].join(' ');
			}

			for(let i = 0; i < this.drawData.pathCoords.length; i++) {
				if (i === 0) {
					str += 'M ';
					str += this.coordStr(i);
				} else if (i === 1) {
					let сstepX =  this.coordDelta(i + 1, i - 1, 'x') / this.curveSmooth;
					let сstepY =  this.coordDelta(i + 1, i - 1, 'y') / this.curveSmooth;

					if (this.type === 'cols') {
						str += 'S ';
						str += this.coordStr(i - 1, сstepX / 2);
						//str += this.coordStr(i,-сstepX, -сstepY);
					} else {
						str += 'C ';
						str += this.coordStr(i - 1, сstepX / 2);
						str += this.coordStr(i, -сstepX, -сstepY);
					}

					str += this.coordStr(i);
				} else if (i === this.drawData.pathCoords.length - 1) {
					let сstepX = this.coordDelta(i, i - 2, 'x') / this.curveSmooth;
					//let сstepY =  this.coordDelta(i,i-2,"y")/this.curveSmooth;
					str += 'S ';
					str += this.coordStr(i, -сstepX / 2);
					str += this.coordStr(i);
				} else {
					let сstepX = this.coordDelta(i + 1, i - 1, 'x') / this.curveSmooth;
					let сstepY = this.coordDelta(i + 1, i - 1, 'y') / this.curveSmooth;
					str += 'S ';
					//str += this.coordStr(i-1, сstepX, сstepY);
					str += this.coordStr(i, -сstepX, -сstepY);
					str += this.coordStr(i);
				}
			}

			return str;
		},

		shapeString() {
			let str = this.colsCurveString;

			if (this.drawData.pathCoords.length < 3) {
				return str;
			}

			str += 'L ';
			str += (this.margin + this.marginedSize.w) + ',' + (this.marginedSize.h + this.margin) + ' ';
			str += 'l ';
			str += -this.marginedSize.w + ',' + 0; // 0 ? not this.marginedSize.h?

			return str;
		},

		/**
		 * TODO: removed case(type): cols, lines. do we have other types?
		 */
		coords() {
			let arr = [];

			for(let i = 0; i < this.dataset.value.length; i++) {
				let x = this.margin + this.stepX * i;
				if (this.type === 'cols') {
					x += this.stepX / 2;
				}

				let y = (
					this.height
					- this.margin
					- this.linear(
						this.dataset.value[i],
						this.dataset.valueRange[0],
						this.dataset.valueRange[1],
						0,
						this.marginedSize.h
					)
				);

				let coords = {
					x: x,
					y: y,
				};

				arr.push(coords);
			}

			// HACK: if we recalculated coords then hide tooltip
			//       fixes a nasty bug during the dataset update
			this.hideInfo();

			return arr;
		},

		startPoint() {
			if (this.coords.length < 2) {
				return {
					x: this.margin,
					y: Math.round(this.clamp(
						0,
						this.margin,
						this.marginedSize.h + this.margin
					))
				};
			}

			let startD = this.coords[0].y - this.coords[1].y;
			return {
				x: this.margin,
				y: Math.round(this.clamp(
					this.coords[0].y + startD / this.outerScale,
					this.margin,
					this.marginedSize.h + this.margin
				))
			};
		},

		endPoint() {
			if (this.coords.length < 2) {
				return {
					x: this.margin + this.marginedSize.w,
					y: Math.round(this.clamp(
						0,
						this.margin,
						this.marginedSize.h + this.margin
					))
				};
			}

			let endD = this.coords[this.coords.length - 2].y - this.coords[this.coords.length - 1].y;
			return {
				x: this.margin + this.marginedSize.w,
				y: Math.round(this.clamp(
					this.coords[this.coords.length - 1].y - endD / this.outerScale,
					this.margin,
					this.marginedSize.h + this.margin
				))
			};
		},

		pointsCount() {
			return this.dataset.value.length;
		},

		linesCount() {
			let result = 0;

			switch(this.type) {
			case 'cols':
				result = this.pointsCount + 1;
				break;
			case 'lines':
				result = this.pointsCount;
				break;
			}

			return result;
		},

		marginedSize() {
			return {
				w: this.width - this.margin * 2,
				h: this.height - this.margin * 2
			};
		},

		stepX() {
			let result = 0;

			if (this.pointsCount === 0) {
				return result;
			}

			switch(this.type) {
			case 'cols':
				result = this.marginedSize.w / this.pointsCount;
				break;
			case 'lines':
				result = this.marginedSize.w / (this.pointsCount - 1);
				break;
			}

			return result;
		},

		hitZoneWidth() {
			return this.stepX - 2;
		},

		drawData() {
			let dd = {};

			switch(this.type) {
			case 'cols':
				dd.pathCoords = [
					this.startPoint,
					...this.coords,
					this.endPoint
				];
				break;
			case 'lines':
				dd.pathCoords = this.coords;
				break;
			}
			return dd;
		},

		invertedRows() {
			let arr = this.dataset.rows.slice(0);
			return arr.reverse();
		},
	},


	methods: {
		linear(n, min1, max1, min2, max2) {
			if (min1 === max1 || min2 === max2) {
				return 0;
			} else {
				return min2 + (n - min1) / (max1 - min1) * (max2 - min2);
			}
		},

		clamp(value, min, max) {
			return Math.max(Math.min(value, max), min);
		},

		coordStr(n, modX = 0, modY = 0) {
			let x = this.drawData.pathCoords[n].x + modX;

			let moddedModY = modY;

			if (this.drawData.pathCoords[n].y + modY > this.drawData.pathCoords[n].y) {
				if (this.drawData.pathCoords[n].y - modY < this.margin) {
					moddedModY = this.drawData.pathCoords[n].y - this.margin;
				} else {
					moddedModY = this.clamp(moddedModY, 0, this.margin + this.marginedSize.h - this.drawData.pathCoords[n].y);
				}
			}else if (this.drawData.pathCoords[n].y + modY < this.drawData.pathCoords[n].y) {
				if (this.drawData.pathCoords[n].y - modY > this.margin + this.marginedSize.h) {
					moddedModY = this.margin + this.marginedSize.h - this.drawData.pathCoords[n].y;
				} else {
					moddedModY = this.clamp(moddedModY, this.margin - this.drawData.pathCoords[n].y, 0);
				}
			}

			let y = this.drawData.pathCoords[n].y + moddedModY;

			return Math.round(x) + ',' + Math.round(y) + ' ';
		},

		coordDelta(a, b, att) {
			return this.drawData.pathCoords[a][att] - this.drawData.pathCoords[b][att];
		},

		//
		// handlers
		//

		showInfo(n){
			this.over = n;
		},

		hideInfo(){
			this.over = -1;
		}
	},
};
</script>


<style lang="sass" scoped>

$height: 30px

.stat-graph
	display: grid
	grid-template-columns: 30px 40px auto
	grid-template-rows: auto 30px
	grid-template-areas: "label legend-y graph" ". . legend-x"
	position: relative
	overflow: hidden
	padding-bottom: 50px

.label
	grid-area: label
	display: flex
	align-items: center
	justify-content: center
	position: relative

.label-text
	transform: rotate(-90deg)
	display: block
	text-transform: uppercase
	font-family: Roboto
	font-style: normal
	font-weight: normal
	font-size: 10px
	line-height: 12px
	color: #919191
	white-space: nowrap

.legend-y
	grid-area: legend-y
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: space-between
	align-items: flex-end

	>span
		text-transform: uppercase
		font-family: Roboto
		font-style: normal
		font-weight: normal
		font-size: 10px
		line-height: 12px
		color: #919191
		white-space: nowrap

.graph
	grid-area: graph
	position: relative

.bottomLegend
	grid-area: legend-x
	display: flex
	font-family: Roboto
	font-style: normal
	font-weight: normal
	font-size: 10px
	line-height: 12px
	text-transform: uppercase
	color: #919191
	position: absolute

.legend-element
	text-align: center

// HACK: for the first and last labels
.stat-graph.lines

	.legend-element
		&:first-child
			transform: translateX(50%)
			text-align: left
		&:last-child
			transform: translateX(-50%)
			text-align: right

.hit-zone
	fill: rgb(255,0 ,0 )
	opacity: 0

.tooltip
	will-change: top,left
	position: absolute
	top: 100px
	left: 100px
	width: 140px
	background-color: #FFFFFF
	border-radius: 10px
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2)
	padding: 10px
	pointer-events: none
	transition: top .2 ease, left .2 ease
	z-index: 1

	.title
		font-family: Roboto
		font-style: normal
		font-weight: normal
		font-size: 13px
		line-height: 15px
		color: #000000
		margin: 0

	.content
		display: flex
		flex-direction: column
		justify-content: space-between
		align-items: flex-start
		margin-bottom: 0

	.label
		font-family: Roboto
		font-style: normal
		font-weight: normal
		font-size: 11px
		line-height: 13px
		color: #6240D9

	::v-deep .value
		font-family: Roboto
		font-style: normal
		font-weight: normal
		font-size: 11px
		line-height: 13px
		text-align: left

		&.max-bid-value
			color: rgb(45, 195, 183)
			margin-top: 10px

.vert
	opacity: .25

	&.selected
		opacity: 1

.header
	display: flex
	flex-direction: row
	justify-content: space-between
	align-items: flex-start

.header-date
	width: 51px
	height: 24px

	font-size: 10px
	line-height: 12px
	text-align: right

	color: #919191

.max-bid
	display: flex
	flex-direction: row
	justify-content: space-between

	margin-top: 15px
	align-items: flex-end

.max-bid-label
	width: 65px
	height: 13px

	font-size: 11px
	line-height: 13px
	/* identical to box height */

	color: #2DC3B7

.max-bid-diff
	text-align: right

.old-bid
	width: 65px
	height: 25px

	font-size: 9px
	line-height: 11px
	text-align: right
	text-decoration-line: line-through

	color: #000000

.new-bid
	width: 65px
	height: 25px

	font-size: 11px
	line-height: 13px
	text-align: right
	font-weight: bold

	color: #000000

.bid-footer
	margin-top: 20px

.bid-author
	width: 122px
	height: 24px

	font-size: 10px
	line-height: 12px

	color: #919191
</style>
