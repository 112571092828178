// localStorage item name for locale overrides
export const LOCALSTORAGE_LOCALE_NAME = 'locale';

// TODO: get these from the backend
export const DEFAULT_LOCALE = 'en';
export const LOCALES = {
	en: 'English',
	ru: 'Russian',
	cn: 'Chinese',
};
