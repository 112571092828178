<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 100 100"
		class="nice-icon"
		:class="[
			iconClass,
			iconState
		]"
	>
		<use
			:xlink:href="`#nice_icon--symbol_${icon}`"
			x="0"
			y="0"
			width="100"
			height="100"
			:style="{
				'--ni-icon-state': state,
			}"
		/>
	</svg>
</template>

<script>
export default {
	name: 'NiceIcon',


	props: {
		icon: { type: String, required: true, },
		state: {
			type: [String, Number, Boolean],
			required: false,
			default: null
		},
	},


	computed: {
		iconClass() {
			return `nice-icon__${this.actualIcon}`;
		},

		/**
		 * return state for icon
		 */
		iconState() {
			return `state-${this.state}`;
		},

		actualIcon() {
			let icon = this.icon;

			switch(icon) {
			// case 'alias':
			// 	icon = 'real_icon' ;
			// 	break;

			case 'edit':
				icon = 'pencil' ;
				break;
			}

			return icon;
		}
	},
};
</script>

<style lang="sass" scoped >
.nice-icon__arrow_circle-34,
.nice-icon__check-34,
.nice-icon__draft-34,
.nice-icon__error-34,
.nice-icon__pause-34,
.nice-icon__pencil-34,
.nice-icon__play-34,
.nice-icon__plus-34,
.nice-icon__see-34,
.nice-icon__warn-34,
.nice-icon__dashboard
	height: 34px

.nice-icon__arrow_circle-24,
.nice-icon__arrow_corner-24,
.nice-icon__basket-24,
.nice-icon__cancel-24,
.nice-icon__check-24,
.nice-icon__draft-24,
.nice-icon__error-24,
.nice-icon__load-24,
.nice-icon__pause-24,
.nice-icon__pencil-24,
.nice-icon__play-24,
.nice-icon__plus-24,
.nice-icon__see-24,
.nice-icon__warn-24,
.nice-icon__copy-24
	height: 24px

.nice-icon__minus_another-20,
.nice-icon__plus-20,
.nice-icon__plus_another-20,
.nice-icon__money,
.nice-icon__man,
	height: 20px

.nice-icon__bell
	height: 18px

.nice-icon__monitor-17,
.nice-icon__plus-17
	height: 17px

.nice-icon__arrow_circle,
.nice-icon__arrow_circle_2,
.nice-icon__audio,
.nice-icon__check,
.nice-icon__diagram,
.nice-icon__draft,
.nice-icon__error,
.nice-icon__hint,
.nice-icon__pause,
.nice-icon__play,
.nice-icon__warn
	height: 16px

.nice-icon__load,
.nice-icon__melody-15,
.nice-icon__mark_crossed-15
	height: 15px

.nice-icon__arrow-14,
.nice-icon__calendar-14,
.nice-icon__basket-14,
.nice-icon__resolution-14,
.nice-icon__mark-14
	height: 14px

.nice-icon__monitor-13,
.nice-icon__pencil-13,
.nice-icon__play-13
	height: 13px

.nice-icon__bill,
.nice-icon__arrow_corner-12
	height: 12px

.nice-icon__x
	height: 9px
</style>
