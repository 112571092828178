<template>
	<section class="layout-full_width" >
	<div>
		<task-element 
			v-for='(task,index) in tasks'
			:key='index'
			v-model="tasks[index]"
			@delete='deleteTask'
		/>
	</div>

	<div style="margin-top:10px">
		<nice-button bordered icon="add" palette="green" @click="addTask"></nice-button>
		<nice-button bordered icon="trash" palette="red" @click="deleteCompleted"></nice-button>
	</div>
	</section>
</template>

<script>
import TaskElement from './task-el.vue';
import NiceButton from '@/ui/nice-button.vue';

export default {
	name: 'Mraz',

	data() {
		return {
			tasks:[{id:0,value:'123',isDone:false},
				{id:1,value:'345',isDone:false},],
			nextId:2,
		};
	},
	methods:{
		addTask() {
			this.tasks.push({id:this.nextId++,value:''});
			
		},
		deleteTask(id){
			this.tasks.splice(this.tasks.indexOf(this.tasks.find(task=>task.id===id)),1);
		},
		deleteCompleted(){
			let arr = this.tasks.filter(task=>task.isDone); //.map((task, index)=>task.id);
			for(let i=0;i<arr.length;i++){
				this.tasks.splice(this.tasks.indexOf(arr[i]),1);
			}
		},
	},
	

	components: {
		TaskElement,
		NiceButton,
	},
};
</script>

<style lang="sass" scoped >
</style>
