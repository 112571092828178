<template>
		<yandex-map
		ref="mglMap"
		class="map"

		:settings="ymSettings"
		:coords.sync="center"
		:zoom.sync="zoom"
		:controls="controls"

		@map-was-initialized="mapInitializedHandler"
	/>
</template>


<script>
import { yandexMap } from 'vue-yandex-maps';

import {
	DEVICE_DEPLOYED,
	DEVICE_NOT_DEPLOYED,
	DEVICE_FILTERED,
	DEVICE_BLACK_LIST,
	DEVICE_WHITE_LIST,
} from '@/constants/device';

import { createDevicePopupLayoutClass } from './device-popup.js';
import { createClusterPopupLayoutClass } from './cluster-popup.js';
import { createAreaPopupLayoutClass } from './area-popup.js';

import {
	YANDEX_CENTER_MSK,
	YANDEX_CENTER_ZOOM,
	ACTION_INCLUDE,
	DEFAULT_RADIUS,
	// ACTION_EXCLUDE,

	drawCircle,
} from '../defaults';


const YM_SETTINGS = {
	apiKey: '7384089f-da2d-4aa0-a60f-787f7a86704c',
	lang: 'ru_RU',
	coordorder: 'longlat',
	enterprise: false,
	version: '2.1',
};


const SELECTED_AREA_BASE_OPTIONS = {
	fillColor: '56db40',
	fillOpacity: 0.2,
	strokeColor: '56db40',
};
const SELECTED_AREA_NOT_SELECTED_COLOR = 'ed4543';

const DEVICE_STATE_ICON_MAP = {
	[DEVICE_DEPLOYED]: 'islands#blueCircleDotIcon',
	[DEVICE_NOT_DEPLOYED]: 'islands#grayCircleDotIcon',
	[DEVICE_FILTERED]: 'islands#grayCircleDotIcon',
	[DEVICE_BLACK_LIST]: 'islands#redCircleDotIcon',
	[DEVICE_WHITE_LIST]: 'islands#greenCircleDotIcon',
};

export default {
	name: 'MapDeviceYandex',


	components: {
		yandexMap,
	},


	props: {
		campaignId: {
			type: Number,
			default: null,
		},


		/**
		 * Url or Object with devices markers data
		 */
		sourceData: {
			type: [Object, String],
			required: true
		},

		/**
		 * Url or Object with selected areas data
		 */
		selectedAreasData: {
			type: Array,
			// required: true
		},

		// POPUPS /////////////////////////////////////////////////////////////////////////////////
		/**
		 * Hide control
		 */
		withoutControl: {
			type: Boolean,
			required: false,
			default: false,
		},

		searchQuery: {
			type: String,
			default: '',
		},
	},


	data() {
		return {
			ymSettings: YM_SETTINGS,
			// lat, lon
			center: YANDEX_CENTER_MSK,
			// 0 - 19
			zoom: YANDEX_CENTER_ZOOM,
			controls: [
				'fullscreenControl',
				'searchControl',
				'zoomControl',
			],

			// needed for geojson data manipulation
			map: null,
			mapObjectManager: null,

			devicePopupLayoutClass: null,
		};
	},


	// TODO: debounce anything?
	watch: {
		sourceData(newValue) {
			this.updateDevicesMap(newValue);
		},

		withoutControl(newValue) {
			this.updateDevicesMap(this.sourceData);
		},

		selectedAreasData(newValue) {
			this.updateSelectedAreasMap(newValue);
		},

		searchQuery(newValue) {
			this.emitSearchListRequest(newValue);
		},
	}, // END: watch


	computed: {
	}, // END: computed


	methods: {
		/**
		 * we can use a global `ymaps` object after the map is initialized
		 */
		async mapInitializedHandler(map) {
			// TODO: TMP:
			window.mymap = map;

			// init ymaps popup layout classes
			this.devicePopupLayoutClass = createDevicePopupLayoutClass(window, this);
			this.clusterPopupLayoutClass = createClusterPopupLayoutClass(window, this);
			this.areaPopupLayoutClass = createAreaPopupLayoutClass(window, this);

			this.map = map;

			map.events.add('click', async (e) => await this.mapClickHandler(e));

			map.events.add('boundschange', (e) => {
				// TODO: broken (was working previously)
				// HACK: postpone update
				window.setTimeout(
					() => this.updateClustersMap(),
					10,
				);
			});

			// map could initialize after the data, so refresh it here too
			this.updateDevicesMap(this.sourceData);
			this.updateSelectedAreasMap(this.selectedAreasData);
		},


		/**
		 * Update geojson data
		 *
		 * Using geoQuery() and GeoQueryResult is very slow!
		 * This way (with a custom manager) is faster
		 */
		updateDevicesMap(geojson) {
			if (!this.map) {
				return;
			}

			// clear on update
			// TODO: this.mapObjectManager.removeAll() doesn't work - point presets are cached?
			if (this.mapObjectManager != null) {
				this.map.geoObjects.remove(this.mapObjectManager);
				delete this.mapObjectManager;
			}

			this.mapObjectManager = new window.ymaps.ObjectManager({
				clusterize: true,
				clusterDisableClickZoom: true,
				clusterHideIconOnBalloonOpen: false,
				clusterBalloonPanelMaxMapArea: 0,
				clusterBalloonContentLayout: this.clusterPopupLayoutClass,
				clusterIconLayout: 'default#pieChart',
				// Радиус диаграммы в пикселях.
				clusterIconPieChartRadius: 16,
				// Радиус центральной части макета.
				clusterIconPieChartCoreRadius: 8,
				// Ширина линий-разделителей секторов и внешней обводки диаграммы.
				clusterIconPieChartStrokeWidth: 2,
			});
			this.map.geoObjects.add(this.mapObjectManager);

			geojson.features.forEach(item => {
				item.properties.balloonContent = item.properties.address;

				// add bunch of stuff
				item.properties.add_to_blacklist_label = this.$t('inventory_modal.add_to_blacklist');
				item.properties.add_to_whitelist_label = this.$t('inventory_modal.add_to_whitelist');
				item.properties.count_screen_label = this.$t('inventory_modal.count_screen');
				item.properties.without_control = this.withoutControl;

				item.options = {
					preset: DEVICE_STATE_ICON_MAP[item.properties.state],
					hideIconOnBalloonOpen: false,
					balloonPanelMaxMapArea: 0,
					balloonContentLayout: this.devicePopupLayoutClass,
				};

				// TODO: we need IDs here - change API?
				if (!item.id && item.properties.id) {
					item.id = item.properties.id;
				}
			});

			this.mapObjectManager.add(geojson);

			this.updateClustersMap();
		},

		/**
		 * TODO: get geojson for selected areas from the back
		 *       and process like geojson of the points
		 */
		updateSelectedAreasMap(selectedAreas) {
			if (!this.map) {
				return;
			}

			// clear the map
			window.ymaps
				.geoQuery(this.map.geoObjects)
				.search('geometry.type = "Polygon"')
				.removeFromMap(this.map);

			for (let area of selectedAreas) {
				const geoObject = this._createAreaGeoObject(area);
				this.map.geoObjects.add(geoObject);
			}
		},

		emitSearchListRequest(searchQuery) {
			if (!this.map) {
				this.$emit('searchListRequest', []);
			}

			const promise = new Promise((resolve, reject) => {
				window.ymaps.geocode(searchQuery).then(data => {
					const results = [];
					data.geoObjects.each(geocoder => {
						const address = geocoder.getAddressLine();
						const coordinates = geocoder.geometry.getCoordinates();
						results.push({'address': address, 'center': coordinates});
					});
					resolve(results);
				}, error => {
					reject(error);
				});
			});

			this.$emit('searchListRequest', promise);
		},


		/**
		 * Helper to create an area GeoObject
		 */
		_createAreaGeoObject(area) {
			let areaGeoJson = {
				'id': area.id,
				'geometry': {
					// geojson doesn't have Circle, but ymaps has
					'type': 'Polygon',
					'coordinates': drawCircle(
						[
							area.longitude,
							area.latitude
						],
						area.radius
					),
				},
				'properties': {
					...area,

					add_to_blacklist_label: this.$t('inventory_modal.add_to_blacklist'),
					add_to_whitelist_label: this.$t('inventory_modal.add_to_whitelist'),
					kilometers_label: this.$t('kilometers'),
					meters_label: this.$t('meters'),
					delete_label: this.$t('delete'),
					without_control: this.withoutControl,

					distance_label: this.$t('campaign.distance'),
				}
			};

			let options = {
				...SELECTED_AREA_BASE_OPTIONS,

				balloonPanelMaxMapArea: 0,
				balloonContentLayout: this.areaPopupLayoutClass,
			};

			if (area.action != ACTION_INCLUDE) {
				options.fillColor = SELECTED_AREA_NOT_SELECTED_COLOR;
				options.strokeColor = SELECTED_AREA_NOT_SELECTED_COLOR;
			}

			return new window.ymaps.GeoObject(areaGeoJson, options);
		},


		/**
		 * Update map clusters' presets
		 *
		 * Used during initialization and on `boundschange`
		 */
		updateClustersMap() {
			this.mapObjectManager.clusters.each(cluster => {
				cluster.properties.add_to_blacklist_label = this.$t('inventory_modal.add_to_blacklist');
				cluster.properties.add_to_whitelist_label = this.$t('inventory_modal.add_to_whitelist');
				cluster.properties.count_screen_short_label = this.$t('inventory_modal.count_screen_short');
				cluster.properties.devices_selected_label = this.$t('map.devices_selected_count');
				cluster.properties.max_devices = process.env.VUE_APP_MAP_MAX_DEVICES_IN_POP_UP;
				cluster.properties.without_control = this.withoutControl;
				cluster.properties.devices_selected_count = cluster.features.length;
				cluster.features.sort(function (a, b) {
					return b.properties.count_screen - a.properties.count_screen;
				});
				cluster.properties.devices = cluster.features.slice(0, process.env.VUE_APP_MAP_MAX_DEVICES_IN_POP_UP);
				cluster.properties.state = this.getClusterType(cluster);
			});
		},


		/**
		 * Handle map click
		 *
		 * Creates a tmp circle and opens a popup
		 */
		async mapClickHandler(event) {
			if (this.withoutControl) {
				return;
			}

			const coords = event.get('coords');

			const geocodeResult = await window.ymaps.geocode(coords);
			const firstGeoObject = geocodeResult.geoObjects.get(0);

			// tmp area
			let area = {
				title: firstGeoObject.getAddressLine(),
				radius: DEFAULT_RADIUS,
				longitude: coords[0],
				latitude: coords[1],
				action: ACTION_INCLUDE,
			};

			const geoObject = this._createAreaGeoObject(area);
			this.map.geoObjects.add(geoObject);

			geoObject.balloon.open(coords);
		},


		/**
		 * Return overall cluster type
		 */
		getClusterType(cluster) {
			let type = '';

			let isWhite = false;
			let isBlack = false;
			for (let device of cluster.features) {
				if (device.properties.state == DEVICE_WHITE_LIST) {
					isWhite = true;
				}
				if (device.properties.state == DEVICE_BLACK_LIST) {
					isBlack = true;
				}
			}

			// set list type
			if (isWhite) {
				type = DEVICE_WHITE_LIST;
			}
			if (isBlack) {
				type = DEVICE_BLACK_LIST;
			}

			return type;
		},

	}, // END: methods
};
</script>

<!--use not scoped css to provide css when map is fullscreen-->
<style lang="sass">
ymaps .active_cursor
	cursor: pointer

ymaps .ymap-area-popup
	max-width: 290px

ymaps .ymap-device-popup
	margin: 10px

	label
		display: block
		text-transform: uppercase
		margin-top: 10px

	.name
		display: block
		font-size: $fsz__normal
		font-weight: bold
		line-height: 17px
		color: $nice_color-black
		margin-bottom: 10px

	.address
		display: block
		margin-top: 10px
		font-size: 100%
		// line-height: $fsz__large
		color: $nice_color-graydark

	.count-screen
		margin-bottom: 10px
		color: $nice_color-graydark

	.device-item
		color: $nice_color-graydark

	.distance
		margin-top: 10px
		margin-bottom: 20px

		.radius
			display: inline-block
			max-width: 50px
			border: 0
			border-bottom: 1px solid #919191

		.units
			display: inline-block
			margin-left: 10px
			border: 0
			border-bottom: 1px solid #919191

	.control
		display: flex
		flex-direction: row
		justify-content: space-between
		margin-top: 15px
		margin-bottom: 0

	.nice-button
		box-sizing: border-box
		display: inline-flex
		flex-direction: row
		flex-grow: 0
		flex-shrink: 0

		cursor: pointer
		font-size: 11px
		font-weight: 700
		text-transform: uppercase
		user-select: none
		border: 2px solid currentColor
		justify-content: center
		align-items: center
		background: none
		border-radius: 5px
		color: #1bad03

		$padding: 5px
		margin: (-$padding + 1) (-$padding + 1) 0 20px
		min-height: auto
		height: 20px
		padding: $padding

		&:first-child
			margin-left: 0

		&.gray
			color: #919191

		&.red
			color: #ff6236

		&.filled-red
			background: #ff6236
			border: 2px solid #ff6236
			color: white

		&.filled-green
			background: #1bad03
			border: 2px solid #1bad03
			color: white

</style>
