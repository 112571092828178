<template>
	<symbol :id="`${prefix}monitor`" viewBox="0 0 17 14">
		<path
			:fill="getColor('main')"
			stroke="none"
			d="M15,0H2C0.9,0,0,0.9,0,2v7c0,1.1,0.9,2,2,2h6v2H4v1h9v-1H9v-2h6c1.1,0,2-0.9,2-2V2C17,0.9,16.1,0,15,0z M16,9c0,0.55-0.45,1-1,1H2c-0.55,0-1-0.45-1-1V2c0-0.55,0.45-1,1-1h13c0.55,0,1,0.45,1,1V9z"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconMonitor',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				main__disabled: this.$palette.disabled_color,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
