<template>
	<symbol :id="`${prefix}warn-34`" viewBox="0 0 34 34">
		<circle cx="17" cy="17" r="16" class="sign border" stroke-width="2" fill="none"/>
		<circle cx="17" cy="17" r="3" class="sign circle" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolWarn34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >


.sign
	&.border
		stroke: var(--ni-icon-base, #{$nice_color-yellow})
	&.circle
		fill: var(--ni-icon-base, #{$nice_color-yellow})
</style>
