<template>
	<symbol :id="`${prefix}pre_approved`" viewBox="0 0 16 16">
		<circle cx="8" cy="8" r="8" fill="#0263CC"/>
		<path fill="#fff" d="M12 8a4 4 0 11-4-4v4h4z" class="sign"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPreApproved',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: none
	fill: var(--ni-icon-sign, #ffffff)
</style>
