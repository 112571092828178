<template>
	<symbol :id="`${prefix}draft-34`" viewBox="0 0 34 34">
		<circle
			cx="17"
			cy="17"
			r="16"
			stroke-width="2"
			class="sign"
		/>

		<circle
			cx="17"
			cy="17"
			r="8"
			stroke-width="2"
			stroke-dasharray="3.14 3.14"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolDraft34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: none
	stroke: var(--ni-icon-sign, #{$nice_color-gray_dark})
</style>
