<template>
	<section class="layout-full_width" >
		<h1>NiceSelect</h1>

		<div class="cl-container" >
			<div class="cl-row" >
				<nice-select
					placeholder="select"
					labeled
					label="select"
					:options="slctOptions"
					class="cl-xs3 cl-md2"
				/>
				<nice-select
					v-model="slctValue01"
					placeholder="select"
					labeled
					label="select"
					:options="slctOptions"
					class="cl-xs3 cl-md2"
				/>
			</div>
		</div>

		<div class="cl-container" >
			<div class="cl-row cl-pad-no_sides" >
				<nice-select
					v-model="slctValue"
					placeholder="select"
					labeled
					label="Select"
					:options="slctOptions"
					class="cl-pad cl-xs3 cl-md2"
				/>
				<nice-select
					v-model="slctValue"
					placeholder="select"
					labeled
					label="Select"
					:options="slctOptions"
					class="cl-pad cl-xs3 cl-md2"
				/>
				<nice-select
					v-model="slctValue"
					placeholder="select"
					labeled
					label="Select"
					hint="hint"
					:options="slctOptions"
					class="cl-pad cl-xs4 cl-md8"
				/>
			</div>
		</div>

		<h2>Multiselect</h2>
		<div class="cl-container" >
			<div class="cl-row" >
				<nice-select
					placeholder="select"
					label="multiselect"
					:options="slctOptions"
					class="cl-xs4 cl-md3"
					multiple
				/>
			</div>
		</div>

		<h2>Filter options</h2>
		<p>Включение фильтрации списка опций происходит автоматически при наличии более 50 элементов в списке.</p>
		<div class="cl-container" >
			<div class="cl-row" >
				<nice-select
					v-model="slctValue02"
					label="Select with filter"
					placeholder="filter"
					:options="slctOptions"
					filter
					class="cl-xs4 cl-md3"
				/>
			</div>
		</div>
		<pre class="code-preview" ><code>&lt;nice-select
	v-model="slctValue02"
	label="Select with filter"
	placeholder="filter"
	:options="slctOptions"
	filter
/&gt;</code></pre>
		<p>Так же можно задать функцию фильтрации, в которую будет передаваться введённая фраза и будет ожидать массив опций для отображения в выпадающем списке, либо список значений для получения данных по ним.</p>
		<div class="cl-container" >
			<div class="cl-row" >
				<nice-select
					v-model="slctValue02"
					label="Select with filter"
					placeholder="select"
					:options="() => {
						return slctOptions;
					}"

					class="cl-xs4 cl-md3"
				/>
			</div>
		</div>
		<pre class="code-preview" ><code>&lt;nice-select
			:filter="() => {
						return [1, 2, 3, 4, 5];
					}"
	v-model="slctValue02"
	label="Select with filter"
	placeholder="filter"
	:options="slctOptions"
	:filter="() => {}"
/&gt;</code></pre>

		<h2>Special cases</h2>
		<p>Без опций в списке будет выводиться единственная опция с текстом значения без возможности отключения</p>
		<div class="cl-container" >
			<div class="cl-row" >
				<nice-select
					v-model="selectWithoutOptions"
					placeholder="select"
					label="multiselect"
					class="cl-xs4 cl-md3"
				/>
			</div>
		</div>

		<h5>Параметры:</h5>
		<dl>
			<dt>input-value</dt>
				<dd>Собственно значение на входе, лучше использовать в <i>v-model</i>.</dd>

			<dt>id</dt>
				<dd>id поля, пол умолчани. Генерится автоматом.</dd>

			<dt>labeled</dt>
				<dd>Выделять ли пространство для лейбла.</dd>

			<dt>label</dt>
				<dd>Лейбл собственной персоной.</dd>

			<dt>hint</dt>
				<dd>Подсказка.</dd>

			<dt>placeholder</dt>
				<dd>собственно плейсхолдер.</dd>

			<dt>options</dt>
				<dd>Список позиций для выбора - массим, значений или объъектов вида <i class="code-preview" >{ value: &lt;value&gt;, label: '&lt;label&gt;'}</i>.<br />
				Так же может принимать асинхронную функцию для динамического получения списка опций, функция должна возвращать массив соответствующих значений, при передаче функции автоматически включается возможность фильтрации. В функцию будет передаваться оюъект содержащий либо ключ <b><i>query</i></b> - строку для фильтрации, либо <b><i>values</i></b> - список значений для которых нужно получить данные (необходимо для отображения уже введённого значения значения т.е. будет запрашиваться список значений из <i>value</i>).</dd>

			<dt>multiple</dt>
				<dd>Флаг определяющий возможность выбирать несколько значений, в таком случае значение будет списком.</dd>

			<dt>filter</dt>
				<dd>Флаг включающий фильтрацию списка опций.</dd>

			<dt>filter-placeholder</dt>
				<dd>Плейсхолдер для инпута при фильтрации.</dd>

			<dt>disabled</dt>
				<dd>флаг выключающий поле.</dd>
		</dl>
	</section>
</template>

<script>
import NiceSelect from '@/ui/nice-select';

export default {
	name: 'TabSelect',
	components: {
		NiceSelect,
	},

	data() {
		return {
			slctOptions: [
				{ value: 'val1', label: 'VERY-VERY-VERY-VERY-VERY-VERY-VERY-VERY-LONG-OPTION-NAME', },
				{ value: 'val2', label: 'Option 2', },
				{ value: 'val3', label: 'Option 3', },
				{ value: 'val4', label: 'Option 4', },
				{ value: 'val5', label: 'Option 5', },
				{ value: 'val6', label: 'Option 6', },
				{ value: 'val7', label: 'Option 7', },
			],
			slctValue: null,
			slctValue01: 'val1',
			slctValue02: 'val2',
			slctMultiValue: null,
			selectWithoutOptions: 'option 1',
		};
	},
};
</script>
