<template>
	<div>
		<div class="top-panel">
			<nice-button-2
				icon="plus-34"
				class="add-button">
				Invite User
			</nice-button-2>
			<nice-button-2
				icon="plus-34"
				class="add-button">
				Add User
			</nice-button-2>
			<nice-icon
				icon="search"
				class="search-icon"
			/>
			<nice-input
				class="search-input"
				type="text"
				placeholder="Filter"
			/>
		</div>
		<table class="nice-table">
			<thead>
				<tr>
					<th>Name</th>
					<th>Email</th>
					<th>Clear</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(el,id) in users"
					:key="id"
				>
					<td class="user-name">
						{{el.name}}
					</td>
					<td>
						{{el.email}}
					</td>
					<td>
						<nice-button-2
							palette="red"
							icon="basket-14"
							icon-painted
						/>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import NiceInput from '@/ui/nice-input';
import NiceButton2 from '@/ui/nice-button-2';

export default {
	name:'UsersListTable',
	data() {
		return {

		};
	},
	props: {
		users: {
			type: Array,
			required: true,
		},
	},
	components: {
		NiceInput,
		NiceButton2,
	},

};
</script>

<style lang="sass" scoped>
.nice-table
	width: 100%
	margin-bottom: 24px
.user-name
	color: #000000
.top-panel
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 20px
.search-input
	width: 200px
	margin-left: 12px
.add-button
	margin-right: 24px
</style>
