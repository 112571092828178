var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"niceSelect",staticClass:"nice-select",class:{
		'has-value': _vm.valuesList.length,
		'invalid': _vm.error.is,
		'label_holder': _vm.labeled && !(_vm.label || _vm.hint),
		'labeled': _vm.labeled || _vm.label || _vm.hint,
	}},[(_vm.label)?_c('nice-label',_vm._g({staticClass:"ni_select--label",attrs:{"for":_vm.id,"label":_vm.label,"hint":_vm.hint,"error":_vm.error.message || _vm.error.is}},_vm.labelListeners)):_vm._e(),_c('span',{staticClass:"ni_select--select-row"},[(_vm.searchIsEnabled)?_c('span',{staticClass:"ni_select--input-wrapper",class:{
				'show-dd': _vm.isDropDownShowing,
			}},[_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.filterQuery),expression:"filterQuery"}],staticClass:"ni_select--input",attrs:{"id":_vm.id,"type":"text","placeholder":_vm.filterPlaceholder || _vm.$t('find'),"disabled":_vm.disabled},domProps:{"value":(_vm.filterQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filterQuery=$event.target.value}}},_vm.inputListeners)),(_vm.icon && !_vm.isDropDownShowing)?_c('NiceIcon2',{staticClass:"ni_select--icon",attrs:{"icon":_vm.icon}}):_vm._e(),_c('label',{staticClass:"ni_select--caption",class:{
					'with-icon': _vm.icon
				},attrs:{"for":_vm.id},domProps:{"innerHTML":_vm._s(_vm.caption || _vm.placeholder || _vm.$t('select'))}})],1):[(_vm.icon)?_c('NiceIcon2',{staticClass:"ni_select--icon",attrs:{"icon":_vm.icon}}):_vm._e(),_c('label',{staticClass:"ni_select--caption",class:{
					'with-icon': _vm.icon
				},domProps:{"innerHTML":_vm._s(_vm.caption || _vm.placeholder || _vm.$t('select'))},on:{"click":_vm.toggleDropDown}})],_c('nice-button-2',{staticClass:"ni_select--button_dd",attrs:{"icon":"arrow_corner-12","icon-state":_vm.isDropDownShowing && _vm.optionList.length ? 180 : 0},on:{"click":_vm.toggleDropDown}})],2),(_vm.isDropDownShowing && _vm.optionList.length)?_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.vcoConfig),expression:"vcoConfig"}],ref:"optionList",staticClass:"ni_select--options"},_vm._l((_vm.optionList),function(option){return _c('nice-option',{key:option.value,staticClass:"ni-sel-option",attrs:{"value":option.value,"caption":option.label,"icon":option.icon,"selected":_vm.valueIsSelected(option.value)},on:{"select":function($event){return _vm.optionToggleHandler(option)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }