import store from '@/store';
import { request, send, API_AUTH_HEADER } from './request';


export const getPrefix = () => `agency/${store.state.app.agency}/${store.state.app.side}/invitation/`;


export const INVITATION_ROLE_DSP_AGENCY = 'dsp_agency';
export const INVITATION_ROLE_DSP_ADMIN = 'dsp_admin';
export const INVITATION_ROLE_SSP_ADMIN = 'ssp_admin';
export const INVITATION_ROLE_SSP_APPROVER = 'ssp_approver';
export const INVITATION_ROLE_SSP_VIEWER = 'ssp_viewer';


/**
 * Get Invitations List
 *
 * @param {str} role {dsp_admin|dsp_agency|ssp_admin|ssp_approver}
 *
 * @returns {array} Invitations
 */
export async function requestInvitations(role=null) {
	let data = {};
	if (role) {
		data.role = role;
	}

	return await request(getPrefix(), data);
}


/**
 * Get Invitations By Token
 *
 * @param {str} token
 * @param {boolean} check_user
 *
 * @returns {object} Invitation object and user existence information
 */
export async function requestInvitationByToken(token, check_user) {
	return await send(`invitation/${token}/?check_user=${!!check_user}`, null, 'get', {
		headers: {
			// unset auth header
			[API_AUTH_HEADER]: null
		}
	});
}


/**
 * Create Invitation
 *
 * @param {int} agencyId - in which agency to invite into
 * @param {str} email
 * @param {str} role {dsp_admin|dsp_agency|ssp_admin|ssp_approver}
 *
 * @returns {object} Invitation
 */
export async function createInvitation(agencyId, email, role) {
	let data = {
		email,
		role,
	};

	// only dsp admin is invited from dsp side
	let side = 'ssp';
	if (role == 'dsp_admin') {
		side = 'dsp';
	}

	return await request(
		`agency/${agencyId}/${side}/invitation/`,
		data,
		'post'
	);
}


/**
 * Delete Invitation
 *
 * @param {int} invitationId
 * @param {str} role {dsp_admin|dsp_agency|ssp_admin|ssp_approver|ssp_viewer} - required for permissions check
 *
 * @returns {array} Invitations
 */
export async function deleteInvitation(agencyId, invitationId, role) {
	// only dsp admin is invited from dsp side
	let side = 'ssp';
	if (role == 'dsp_admin') {
		side = 'dsp';
	}

	return await request(`agency/${agencyId}/${side}/invitation/${invitationId}/?role=${role}`, null, 'delete');
}


/**
 * Create Agency User By Invitation
 * Create DSP Agency By Invitation
 *
 * @param {obj} data {
 *  token
 *  first_name
 *  last_name
 *  email
 *  phone
 *  agency_name - only for dsp_agency invitation.role
 *  password
 *  tos_accepted
 * }

 * @returns {object} user
 */
export async function createAgencyUserByInvitation(data, config) {
	return await send('invitation/create-agency-user-by-invitation/', data, 'post', config);
}


/**
 *
 * @param {str} token
 *
 * @returns {obj} data with access token, if user is added successfully, otherwise - empty
 */
export async function addUserToAgencyIfUserExists(token) {
	const data = {
		token: token
	};
	return await send('invitation/add-user-to-agency-by-invitation/', data, 'post');
}
