var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nice-range",class:{
		'scaled': _vm.scaleMarkers,
		'disabled': _vm.disabled,
		'demo': _vm.demo,
	}},[_vm._t("viz",null,{"range":[0,_vm.axisLimit],"selected":_vm.markersArray}),_c('div',{ref:"axis",staticClass:"ni-axis"},[(_vm.initialized)?[(_vm.scaleMarkers)?_vm._l((_vm.scaleMarkers),function(marker){return _c('div',{key:("scale-marker-" + (marker.value)),staticClass:"ni-scale-marker",class:{
						'in-range': marker.position >= _vm.markers[0] && marker.position <= _vm.markers[1]
					},style:({
						'--x-shift': _vm.pos2shift(marker.position),
					})},[_c('span',{staticClass:"ni-scale-label"},[_vm._v(_vm._s(marker.label || marker.value))])])}):_vm._e(),(!_vm.demo)?[_c('span',{ref:"range",staticClass:"ni-range",class:{
						'active': _vm.context.active,
					},style:({
						'--a-shift': _vm.markersShift[0],
						'--b-shift': _vm.markersShift[_vm.markersShift.length - 1],
					})}),(!_vm.disabled)?_vm._l((_vm.markers),function(marker,index){return _c('div',{key:("marker-" + index),ref:"marker",refInFor:true,staticClass:"ni-marker",class:{
							'active': (index == _vm.context.index) && _vm.context.active,
							'last-active': index == _vm.context.index
						},style:({
							'--x-shift': _vm.markersShift[index],
						}),on:{"mousedown":function($event){return _vm.onDragStart($event, index)}}})}):_vm._e()]:_vm._e()]:_vm._e()],2),(_vm.legendSlotList)?_c('div',{staticClass:"ni-legend-wrapper"},_vm._l((_vm.legendSlotList),function(slotIndex,index){return _c('legend-item',{key:("legend-item-" + index),attrs:{"vnode":_vm.$slots.default[slotIndex],"val2pos":_vm.val2pos,"pos2shift":_vm.pos2shift}})}),1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }