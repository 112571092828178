<template>
	<section>
		<div class="row--time_period_and_ad_fligth">
			<nice-datetime
				v-model="innerTimePeriod"

				:range="true"
				format="YYYY-MM-DD"
				formatted="YYYY-MM-DD"
				:label="$t('campaign.time_period') | capitalize"
				:placeholder="$t('time_period_placeholder')"
				no-shortcuts
				class="time_period"
				required
			/>

		</div>

		<!-- TODO: TMP: CDEK asks for a list of ranges? -->
		<TimeRange
			v-show="showAdvancedSettings"
			v-model="innerTimeRange"
			class="input--time-range"
		/>

		<div
			v-show="showAdvancedSettings"
			class="input--schedule"
		>
			<nice-label
				:label="$t('campaign.week_days_label') | capitalize"
				class="schedule--label"
			/>
			<div class="schedule--row" >
				<div class="schedule--week_days-checkboxes" >
					<nice-checkbox
						v-for="day in [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', ]"
						:key="day"
						v-model="innerSchedule[day]"
						icon="chbx-circle"
					/>
				</div>

				<div class="schedule--week_days-labels" >
					<nice-label
						v-for="day in [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', ]"
						:key="day"
						:label="$t(`campaign.${day}`) + '.' | capitalize"
					/>
				</div>
			</div>
		</div>
	</section>
</template>


<script>
import _ from 'underscore';

import NiceCheckbox from '@/ui/nice-checkbox.vue';
import NiceDatetime from '@/ui/nice-datetime.vue';
import TimeRange from '@/components/time-range';


export default {
	name: 'Schedule',

	components: {
		TimeRange,
		NiceCheckbox,
		NiceDatetime,
	},


	props: {
		timePeriod: {
			type: Object,
			default: () => ({ end: null, start: null, }),
		},

		timeRange: {
			type: Array,
			default: () => [0, 24*60*60-1],
		},

		schedule: {
			type: Object,
			default: () => ({
				mon: false,
				tue: false,
				wed: false,
				thu: false,
				fri: false,
				sat: false,
				sun: false,
			}),
		},

		showAdvancedSettings: {
			type: Boolean,
			default: true,
		},
	},


	data() {

		return {
			innerTimePeriod: this.timePeriod,
			innerTimeRange: this.timeRange,
			innerSchedule: this.schedule,
		};
	},


	watch: {
		timePeriod: {
			deep: true,
			handler(value, old) {
				if (_.isEqual(value, this.innerTimePeriod)) {
					return;
				}

				this.$set(this, 'innerTimePeriod', value);
			},
		},

		timeRange: {
			deep: true,
			handler(value, old) {
				if (_.isEqual(value, this.innerTimeRange)) {
					return;
				}

				this.$set(this, 'innerTimeRange', value);
			},
		},

		schedule: {
			deep: true,
			handler(value, old) {
				if (_.isEqual(value, this.innerSchedule)) {
					return;
				}

				this.$set(this, 'innerSchedule', value);
			},
		},

		innerTimePeriod: {
			handler(value, old) {
				if (_.isEqual(value, this.timePeriod)) {
					return;
				}

				this.$emit('update:timePeriod', value);
			},
		},

		innerTimeRange: {
			handler(value, old) {
				if (_.isEqual(value, old)) {
					return;
				}

				this.$emit('update:timeRange', value);
			},
		},

		innerSchedule: {
			deep: true,
			handler(value, old) {
				if (_.isEqual(value, this.schedule)) {
					return;
				}

				this.$emit('update:schedule', value);
			},
		},
	},
};
</script>


<style lang="sass" scoped>
$row--width: 330px
$row--indent: 15px

.time_period
	width: 325px

.row--time_range
	max-width: $row--width
	margin-top: $row--indent

	.nice-range
		padding: 0 22px

.schedule--label
	margin-top: $row--indent
	margin-bottom: 10px

.schedule--row
	max-width: $row--width

	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch

.schedule--week_days-checkboxes
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-around
	align-items: center

	> *
		margin-bottom: 0

.schedule--week_days-labels
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	align-items: center

	> *
		min-width: 100 / 7 * 1%
		width: 100 / 7 * 1%
		justify-content: center



.row--duration_and_ad_fligth,
.row--time_period_and_ad_fligth
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: flex-start

	> *
		margin-right: $ui-grid__mgn-x

		&:last-child
			margin-right: 0

.input--duration
	width: 132px

.input--ad_flight,
.input--payment_model
	width: 110px

.input--payment_model
	::v-deep
		.ni_select--caption,
		.nice-select-option
			text-transform: uppercase

.input--time-range
	margin-top: 10px
</style>
