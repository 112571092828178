<template>
	<section>
		<h1>NiceActionList</h1>

		<p>Виджет списка действий</p>

		<div class="cl-container" >
			<div class="cl-row" >
				<nice-action-list
					class="cl-pad cl-xs4 cl-md2"
					status="draft"
					:list="statusList1"
				/>
				<nice-action-list
					class="cl-pad cl-xs4 cl-md2"
					status="pause"
					:list="statusList2"
				/>

				<nice-action-list
					class="cl-pad cl-xs4 cl-md2"
					status="approved"
					:list="statusList3"
				/>
			</div>
		</div>

		<p>
			<pre class="code-preview" ><code>&lt;nice-action-list
	status="published"
	:list="[
		'published',
		'unpublished',
		'draft'
	]"
/&gt;</code></pre>
		</p>

		<h2>Example</h2>
		<p>
			<nice-action-list
				status="draft"
				:list="statusList3"
			/>
		</p>

		<p>
			<pre class="code-preview" ><code>&lt;nice-action-list
	status="draft"
	:list="statusList3"
/&gt;

<hr>
const statusList3 = [
	{
		value: 'approved',
		icon: 'play',
		label: 'Approved',
	},
	{
		value: 'archived',
		icon: 'arrow_circle',
		labelAction: 'Archive',
	},
];
</code></pre>
		</p>

		<h5>Параметры:</h5>
		<dl>
			<dt>status</dt>
				<dd>Строка - текущий выбор</dd>

			<dt>list</dt>
				<dd>Список доступных вариантов, могут быть объектами <code class="code-preview" >{ value: &lt;value&gt;, label: &lt;label&gt;}</code> либо просто строкой значением.</dd>

			<dt>iconsInList</dt>
				<dd>Булево значение, Отображать ли иконки внутри бабла со списком вариантов</dd>

			<dt>hideOnClick</dt>
				<dd>Булево значение, скрывать ли бабл при клике</dd>

			<dt>tippyTrigger</dt>
				<dd>Триггер появления меню, возможные варианты - <a href="https://atomiks.github.io/tippyjs/all-options/" target="_blank" >документации tippy</a>.</dd>
		</dl>

		<p>На основе этого компонента работает <b><i>components/table/cell-status</i></b>.</p>
	</section>
</template>

<script>
import NiceActionList from '@/ui/nice-action-list';


export default {
	name: 'TabActionList',


	components: {
		NiceActionList,
	},


	data() {
		return {
			switchData: false,
			statusList1: [
				{
					value: 'draft',
				},
				{
					value: 'audit',
					icon: 'warn',
					label: 'Awaiting',
					labelAction: 'Send To Approve',
				},
				{
					value: 'approved',
					icon: 'play',
					label: 'Approved',
				},
				{
					value: 'rejected',
					icon: 'error',
				},
				{
					value: 'archived',
					icon: 'arrow_circle',
				},
				{
					value: 'delete',
					icon: 'basket',
				},
			],
			statusList2: [
				{
					value: 'pause',
					label: 'Paused',
				},
				{
					value: 'audit',
					icon: 'warn',
					label: 'Awaiting',
					labelAction: 'Send To Approve',
				},
				{
					value: 'archived',
					icon: 'arrow_circle',
				},
				{
					value: 'draft',
				},
			],
			statusList3: [
				{
					value: 'approved',
					icon: 'play',
					label: 'Approved',
				},
				{
					value: 'archived',
					icon: 'arrow_circle',
					labelAction: 'Archive',
				},
			],
		};
	},
};
</script>


<style lang="sass" scoped >
dt
	font-style: italic
	font-weight: 300

dd
	margin-bottom: 10px
</style>
