export default {
	authentication_title: {
		en: 'Log in to',
		ge: 'შესვლა',
		de: 'Einloggen bei',
		ru: 'Войти в',
		cn: 'Log in to',
	},
	selected_n_files: {
		en: 'no files selected | selected 1 file | selected {n} files',
		ge: 'ფაილები არ არის არჩეული | არჩეულია 1 ფაილი | არჩეულია {n} ფაილი',
		de: 'keine Dateien ausgewählt | 1 Datei ausgewählt | {n} Dateien ausgewählt',
		ru: 'не выбрано | выбран {n} файл | выбрано {n} файла | выбрано {n} файлов',
		cn: '未选中文件 | 已选择 1 个文件 | 已选择 {n} 个文件'
	},
	n_files: {
		en: 'no files | 1 file | {n} files',
		ge: 'ფაილები არ არის | 1 ფაილი | {n} ფაილი',
		de: 'keine Dateien | 1 Datei | {n} Dateien',
		ru: '0 файлов | {n} файл | {n} файла | {n} файлов',
		cn: '0 个文件 | 1 个文件 | {n} 个文件'
	},
	loading: {
		en: 'loading',
		ge: 'იტვირთება',
		de: 'Laden',
		ru: 'загрузка',
		cn: '正在加载'
	},
	changed: {
		en: 'changed',
		ge: 'შეცვლილია',
		de: 'geändert',
		ru: 'изменено',
		cn: '已更改'
	},
	changed_by: {
		en: 'changed_by',
		ge: 'შეცვლილი',
		de: 'geändert von',
		ru: 'автор изменений',
		cn: '更改者'
	},
	close: {
		en: 'close',
		ge: 'დახურვა',
		de: 'schließen',
		ru: 'закрыть',
		cn: '关闭'
	},
	cancel: {
		en: 'cancel',
		ge: 'გაუქმება',
		de: 'abbrechen',
		ru: 'отменить',
		cn: '取消'
	},
	reset: {
		en: 'reset',
		ge: 'გადატვირთვა',
		de: 'zurücksetzen',
		ru: 'сбросить',
		cn: '重置'
	},
	add: {
		en: 'add',
		ge: 'დამატება',
		de: 'hinzufügen',
		ru: 'добавить',
		cn: /* TODO: translation needed */ 'add'
	},
	save: {
		en: 'save',
		ge: 'შენახვა',
		de: 'speichern',
		ru: 'сохранить',
		cn: /* TODO: translation needed */ 'save'
	},
	apply: {
		en: 'apply',
		ge: 'გამოყენება',
		de: 'anwenden',
		ru: 'применить',
		cn: '应用'
	},
	send: {
		en: 'send',
		ge: 'გაგზავნა',
		de: 'senden',
		ru: 'отправить',
		cn: '發送'
	},
	back: {
		en: 'back',
		ge: 'უკან',
		de: 'zurück',
		ru: 'назад',
		cn: '背部'
	},
	creative_name: {
		en: 'name',
		ge: 'სახელი',
		de: 'Name',
		ru: 'имя',
		cn: '名称'
	},
	creative_name_placeholder: {
		en: 'enter name',
		ge: 'შეიყვანეთ სახელი',
		de: 'Namen eingeben',
		ru: 'введите имя',
		cn: '输入名称'
	},
	description: {
		en: 'description',
		ge: 'აღწერილობა',
		de: 'Beschreibung',
		ru: 'описание',
		cn: '描述'
	},
	description_placeholder: {
		en: 'enter description',
		ge: 'შეიყვანეთ აღწერა',
		de: 'Beschreibung eingeben',
		ru: 'введите описание',
		cn: '输入描述'
	},
	'for': {
		en: 'for',
		ge: 'განმავლობაში',
		de: 'für',
		ru: 'для',
		cn: '针对'
	},
	profile: {
		en: 'profile',
		ge: 'პროფილი',
		de: 'Profil',
		ru: 'профиль',
		cn: '资料'
	},
	logout: {
		en: 'logout',
		ge: 'გამოსვლა',
		de: 'ausloggen',
		ru: 'выйти',
		cn: '登出'
	},
	balance: {
		en: 'balance',
		ge: 'ბალანსი',
		de: 'Kontostand',
		ru: 'баланс',
		cn: '余额'
	},
	language: {
		en: 'language',
		ge: 'ენა',
		de: 'Sprache',
		ru: 'язык',
		cn: '语言'
	},
	agency: {
		en: 'agency',
		ge: 'სააგენტო',
		de: 'Agentur',
		ru: 'агентство',
		cn: '代理商'
	},
	users: {
		en: 'users',
		ge: 'მომხმარებლები',
		de: 'Benutzer',
		ru: 'пользователи',
		cn: '用户'
	},
	agencies: {
		en: 'agencies',
		ge: 'სააგენტოები',
		de: 'Agenturen',
		ru: 'агентства',
		cn: '代理商'
	},
	platform: {
		en: 'network',
		ge: 'ქსელი',
		de: 'Netzwerk',
		ru: 'рекламная сеть',
		cn: '网络'
	},
	platforms: {
		en: 'networks',
		ge: 'ქსელები',
		de: 'Netzwerke',
		ru: 'рекламные сети',
		cn: '网络'
	},
	horizontal: {
		en: 'horizontal',
		ge: 'ჰორიზონტალური',
		de: 'horizontal',
		ru: 'горизонтальный',
		cn: '水平'
	},
	vertical: {
		en: 'vertical',
		ge: 'ვერტიკალური',
		de: 'vertikal',
		ru: 'вертикальный',
		cn: '垂直'
	},
	dsp_agency: {
		en: 'DSP agency',
		ge: 'SmartAds სააგენტო' /* TODO: to be translated */,
		de: 'DSP-Agentur',
		ru: 'Агентство DSP',
		cn: 'DSP 代理商'
	},
	time_period: {
		en: 'time period',
		ge: 'დროის პერიოდი',
		de: 'Zeitraum',
		ru: 'период времени',
		cn: '时间段'
	},
	time_period_placeholder: {
		en: 'Select date interval',
		ge: 'Sაირჩიე თარიღის ინტერვალი',
		de: 'Datumsintervall auswählen',
		ru: 'Выберите интервал',
		cn: '选择日期间隔'
	},
	expires: {
		en: 'expires',
		ge: 'იწურება',
		de: 'läuft ab',
		ru: 'истекает',
		cn: 'expires'  // TODO: translation needed
	},
	email: {
		en: 'email',
		ge: 'ელფოსტა',
		de: 'E-Mail',
		ru: 'email',
		cn: '电子邮箱'
	},
	type_email: {
		en: 'Type email',
		ge: 'შეიყვანეთ ელ.წერილი',
		de: 'E-Mail eingeben',
		ru: 'Введите email',
		cn: 'Type email'  // TODO: translation needed
	},
	password: {
		en: 'password',
		ge: 'პაროლი',
		de: 'Passwort',
		ru: 'пароль',
		cn: 'password'  // TODO: translation needed
	},
	type_password: {
		en: 'Type password',
		ge: 'აკრიფეთ პაროლი',
		de: 'Passwort eingeben',
		ru: 'Введите пароль',
		cn: 'Type password'  // TODO: translation needed
	},
	confirm_password: {
		en: 'Confirm password',
		ge: 'დაადასტურეთ პაროლი',
		de: 'Passwort bestätigen',
		ru: 'Подтвердите пароль',
		cn: 'Confirm password'  // TODO: translation needed
	},
	enter: {
		en: 'Enter',
		ge: 'შესვლა',
		de: 'Eingeben',
		ru: 'Вход',
		cn: 'Enter'  // TODO: translation needed
	},
	support: {
		en: 'Support',
		ge: 'მხარდაჭერა',
		de: 'Support',
		ru: 'Поддержка',
		cn: 'Support'  // TODO: translation needed
	},
	help_is_needed: {
		en: 'Help?',
		ge: 'გსურთ დახმარება?',
		de: 'Hilfe?',
		ru: 'Нужна помощь?',
		cn: 'Help?'  // TODO: translation needed
	}
};
