var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data_block"},[_c('div',[(_vm.paymentModel === _vm.PAYMENT_MODEL_CPV && _vm.additionalProps.audience)?[_c('h3',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('campaign.audience_label')))+" "),_c('nice-hint',{attrs:{"hint":_vm.$t('campaign.audience_hint')}})],1),_c('audience-cpv',{attrs:{"age":_vm.age,"gender":_vm.gender,"errors":{
					// age: context.errors.age,
					// gender: context.errors.gender,
				}},on:{"update:age":function($event){return _vm.$emit('update:age', $event)},"update:gender":function($event){return _vm.$emit('update:gender', $event)}}})]:_vm._e()],2),(_vm.environmentList.length > 1 && _vm.additionalProps.environments)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('campaign.environment_label')))+" "),_c('nice-hint',{attrs:{"hint":_vm.$t('campaign.environment_hint')}})],1),_c('environments-tree',{attrs:{"environments":_vm.environments,"environment-list":_vm.environmentList},on:{"update:environments":function($event){return _vm.$emit('update:environments', $event)}}})],1):_vm._e(),(_vm.resolutionList.length > 1 || _vm.broadcatsTypesList.length > 1)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t('campaign.broadcasting_points'))))]),_c('broadcast-points',{attrs:{"platforms":_vm.platforms,"resolutions":_vm.resolutions,"resolution-list":_vm.resolutionList,"orientations":_vm.orientations,"orientation-list":_vm.orientationList,"device-types":_vm.deviceTypes,"broadcast-types-list":_vm.broadcatsTypesList,"fields":_vm.additionalProps.broadcast_points.fields,"errors":{
				// resolutions: context.errors.resolutions,
				// orientations: context.errors.orientations,
				// device_types: context.errors.device_types,
			}},on:{"update:resolutions":function($event){return _vm.$emit('update:resolutions', $event)},"update:orientations":function($event){return _vm.$emit('update:orientations', $event)},"update:device-types":function($event){return _vm.$emit('update:device-types', $event)}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }