<template>
	<div>
		<div v-if="type === 'Audit'">
			{{ $t('campaign.changes_in_creative') }}: {{ name }} <br /><br />
			{{ $t('campaign.status') }}: {{ status }} <br /><br />
			{{ $t('campaign.last_message') }}: {{ feedback }}
		</div>
		<div v-else-if="type == 'CampaignAudit'">
			{{ $t('campaign.status') }}: {{ status }} <br /><br />
			{{ $t('campaign.last_message') }}: {{ feedback }}
		</div>
		<div v-else-if="type == 'Campaign'">
			<p v-if="areDatesChanged">
				{{ formatDate(startDate) }} <br />
				{{ formatDate(endDate) }}
			</p>
			<!-- TODO make type Creative-->
			<div v-if="action && creativeName">
				{{ $t('campaign.changes_in_creative') }}: {{ creativeName }} <br /><br />
				<p v-if="isNewValue">
					{{ $t('campaign.action_' + action) | capitalize }}
				</p>
				<p v-else>
					-
				</p>
			</div>
		</div>
		<div v-else-if="type == 'Device'">
			<p v-if="!isNewValue">
				{{ $t('campaign.old_device_list_count') }}: {{ countDevices }}
			</p>
			<p v-else>
				{{ $t('campaign.new_device_list_count') }}: {{ countDevices }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HistoryItem',

	props: {
		isNewValue: {
			type: Boolean,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
	},

	computed: {
	
		value() {
			return this.isNewValue ? this.item.new_value : this.item.old_value;
		},

		type() {
			return this.item.type;
		},

		name() {
			return this.item.name;
		},

		creativeName() {
			const arr = this.item.comment ? this.item.comment.split('__') : [];
			return arr.length > 1 ? arr[1] : '';
		}, 

		countDevices() {
			let index = this.isNewValue ? 1 : 0;
			const arr = this.item.comment?.devices_count;
			return arr && arr.length > index ? arr[index] : '';
		},

		action() {
			const arr = this.item.comment ? this.item.comment.split('__') : [];
			return arr.length > 0 ? arr[0].toLowerCase() : '';
		},

		feedback() {
			let feedback = '-';
			if (Array.isArray(this.value.feedback) && this.value.feedback.length > 0) {
				feedback = this.value.feedback[this.value.feedback.length - 1];
			}
			return feedback;
		},

		status() {
			let status = '-';
			if (this.value.status) {
				status = this.$t('campaign.status_change_' + this.value.status);

				if (this.type == 'Audit' && this.value.status == 3) {
					status = this.$t('campaign.creative_audit_status_approved');
				}
			}
			return status;
		},

		startDate() {
			return this.value.start_date;
		},

		endDate() {
			return this.value.end_date;
		},

		areDatesChanged() {
			const oldValue = this.item.old_value.start_date + this.item.old_value.end_date;
			const newValue = this.item.new_value.start_date + this.item.new_value.end_date;
			return oldValue !== newValue;
		},
	},

	methods: {
		formatDate(dateStr) {
			if (!dateStr) return '-';
			const [year, month, day] = dateStr.split('-');
			return `${day}.${month}.${year}`;
		},
	},
};
</script>