export default {
	register: {
		en: 'Register',
		ge: 'რეგისტრაცია',
		de: 'Registrieren',
		ru: 'Зарегистрироваться',
		cn: 'Register',  // TODO: to be translated
	},

	registration: {
		en: 'Registration',
		ge: 'რეგისტრაცია',
		de: 'Registrierung',
		ru: 'Регистрация',
		cn: 'Registration',  // TODO: to be translated
	},
};
