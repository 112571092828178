<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 15 14">
		<g
			:fill="disabled ? colors.disabled : colors.main"
		>
			<!-- cross -->
			<polygon
				v-if="cross"
				points="11.04 0.71 10.33 0 7.5 2.83 4.67 0 3.96 0.71 6.79 3.54 3.96 6.36 4.67 7.07 7.5 4.24 10.33 7.07 11.04 6.36 8.21 3.54 11.04 0.71"
			/>

			<!-- arrow -->
			<polygon
				v-else
				points="7 10 7 1.914 3.611 5.303 2.904 4.596 7.5 0 12.096 4.596 11.389 5.303 8 1.914 8 10 7 10"
				:style="{
					transform: `rotate3d(0, 0, 1, ${arrowAngle}deg)`,
					transformOrigin: 'center 35%',
				}"
			/>

			<!-- poket -->
			<path
				d="M14,10.5V10h1v.5A3.5,3.5,0,0,1,11.5,14h-8A3.5,3.5,0,0,1,0,10.5V10H1v.5A2.5,2.5,0,0,0,3.5,13h8A2.5,2.5,0,0,0,14,10.5Z"
			/>
		</g>
	</symbol>
</template>

<script>
export const slug = 'load';


export default {
	name: 'NiIconLoad',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				disabled: this.$palette.disabled_color,
				...this.palette,
			};
		},

		arrowAngle() {
			let angle = this.state;
			switch(angle) {
			case 'up':
			case 'upload':
				angle = 0;
				break;

			case 'right':
				angle = 90;
				break;

			case 'down':
			case 'download':
				angle = 180;
				break;

			case 'left':
				angle = 270;
				break;
			}

			return angle;
		},

		cross() {
			return ['cancel', 'no', 'cross', 'x'].indexOf(this.state) !== -1;
		},
	},
};
</script>
