<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 10 9">
		<path
			:fill="disabled ? colors.disabled : colors.main"

			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.29297 4.5L0.646484 8.14649L1.35359 8.85359L5.00008 5.20711L8.64656 8.85359L9.35367 8.14649L5.70718 4.5L9.35359 0.853591L8.64649 0.146484L5.00008 3.79289L1.35367 0.146484L0.646561 0.853591L4.29297 4.5Z"
		/>
	</symbol>
</template>

<script>
export const slug = 'x';


export default {
	name: 'NiIconX',


	props: {
		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				disabled: this.$palette.disabled_color,
				...this.palette,
			};
		},
	},
};
</script>
