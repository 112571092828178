<template>
	<div class="refresh">
		<header class="refresh__header">
			<h2 class="refresh__title">{{ $t('two_factor_auth.backup_codes') | capitalize }}</h2>
			<nice-button-2
				palette="gray"
				class="refresh__close-button"
				@click="close"
			>{{ $t('close') }}</nice-button-2>
		</header>

		<form
			v-if="!backupCodes"
			class="refresh__form"
			@submit.prevent="handleFormSubmit"
		>
			<nice-input
				v-model="code"
				:label="$t('two_factor_auth.verification_code')"
				type="text"
				:invalid="error && error.message"
				class="refresh__input"
			/>

			<nice-button-2
				type="submit"
				filled
				class="refresh__submit"
			>{{ $t('two_factor_auth.submit') | capitalize }}</nice-button-2>
		</form>

		<div v-if="backupCodes">
			<p>{{ $t('two_factor_auth.save_codes') | capitalize }}</p>

			<div class="backup-codes">
				<p
					v-for="code in backupCodes"
					:key="code.token"
				>
					{{ code.token }}
				</p>
			</div>
		</div>

	</div>
</template>

<script>
import { refreshStaticTokens } from '@/api/profile';
import NiceInput from '@/ui/nice-input';

export default {
	name: 'RefreshStaticCodes',

	components: {
		NiceInput,
	},

	data() {
		return {
			code: '',
			error: null,
			backupCodes: null,
		};
	},

	methods: {
		close() {
			this.$emit('close');
		},

		async handleFormSubmit() {
			const payload = {
				code: this.code
			};

			try {
				const response = await refreshStaticTokens(payload);
				this.backupCodes = response;
			} catch(error) {
				this.handleSubmitError(error);
				return null;
			}
			this.error = null;
			this.$emit('ok');
		},

		handleSubmitError(error) {
			if (error && error.status === 400) {
				this.error = Object.freeze({
					status: 400,
					message: error.data.error,
				});
			} else {
				this.error = Object.freeze({
					status: null,
					message: 'unexpected error',
				});
			}

		},
	},
};
</script>

<style lang="sass" scoped>
.refresh
	box-sizing: border-box
	max-width: 380px
	padding: 40px

.refresh__header
	display: flex
	align-items: center

.backup-codes
	background-color: #f6f6f6

.refresh__title
	margin: 0 auto 0 0

.refresh__close-button
	flex-shrink: 0
	flex-grow: 0
	margin-left: 20px

.refresh__form
	display: flex
	align-items: flex-end

.refresh__input
	flex-grow: 1

.refresh__submit
	flex-grow: 0
	flex-shrink: 0
	margin-left: 20px
</style>
