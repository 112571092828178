<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="8"
			:fill="getColor('sign')"
			stroke="none"
		/>
		<polygon
			:fill="getColor('icon')"
			stroke="none"
			points="7.5,11.5 4,8 5.4,6.6 7.5,8.6 10.6,5.5 12,6.9 "
		/>
	</symbol>
</template>

<script>
export const slug = 'free';


export default {
	name: 'NiIconFree',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				icon: this.$palette.white,
				sign: this.icon == 'accepted' ? this.$palette.blue_dark : this.$palette.green,
				icon__disabled: this.$palette.white__disabled,
				sign__disabled: this.icon == 'accepted' ? this.$palette.blue_dark__disabled : this.$palette.green__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
