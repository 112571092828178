<template>
	<symbol :id="`${prefix}play-13`" viewBox="0 0 11 13">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M2.49972 1.1368C1.83305 0.752182 1 1.23332 1 2.00299L1 10.0774C1 10.847 1.83305 11.3282 2.49973 10.9436L9.49749 6.90634C10.1645 6.5215 10.1645 5.55879 9.4975 5.17397L2.49972 1.1368ZM1.01328e-06 2.00299C1.01328e-06 0.46367 1.66611 -0.498624 2.99944 0.270613L9.99722 4.30778C11.3313 5.07744 11.3313 7.00285 9.99723 7.77251L2.99945 11.8098C1.66613 12.579 0 11.6167 0 10.0774L1.01328e-06 2.00299Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlay2-13',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, var(--main_color))
</style>
