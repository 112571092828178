<template>
	<div class="locale-changer">
		<nice-button-2
			v-for="(lang, i) in $i18n.availableLocales"
			:key="`Lang${i}`"
			:filled="lang === $i18n.locale"
			bordered
			class="locale-changer__button"

			@click="updateLanguage(lang)"
		>
			{{ lang }}
		</nice-button-2>
	</div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
	name: 'LocaleChanger',


	methods: {
		...mapActions('profile', ['updateLanguage']),
	},
};
</script>


<style lang="sass" scoped>
.locale-changer
	display: flex
	align-items: center
	// justify-content: center

	& > .locale-changer__button
		margin-right: 15px

		&:last-child
			margin-right: 0
</style>
