<template>
	<button
		:type="type"
		class="nice-button"
		:class="{
			'__bordered': bordered,
			'__watercolor': watercolor,
			'__filled': filled,
			'__inline': inline,
			[actualPalette]: actualPalette,
			'icon-painted': iconPainted,
		}"

		v-bind="$attrs"
		v-on="$listeners"
	>
		<span
			v-if="$slots.default && iconPlacement === 'end'"
			class="wizard-btn-content"
		>
			<slot />
		</span>

		<nice-icon-2
			v-if="icon"

			class="ni_button-icon"
			:icon="icon"
			:state="iconState"
		/>

		<span
			v-if="$slots.default && iconPlacement === 'start'"
			class="wizard-btn-content"
		>
			<slot />
		</span>
	</button>
</template>

<script>
import _ from 'underscore';

import NiceIcon2 from '@/ui/nice-icon-2';


const ICON_PLACEMENTS = [ 'start', 'end' ];
export const AVAILABLE_PALETTES = [
	// 'default',
	'gray',
	'navy',
	'blue',
	'purple',
	'red',
	'yellow',
	'green',
];



export default {
	name: 'NiceButton',


	components: {
		NiceIcon2,
	},


	props: {
		type: {
			type: String,
			default: 'button',
		},

		inline: {
			type: Boolean,
			default: false,
		},

		// button themes
		bordered: {
			type: Boolean,
			default: false,
		},

		watercolor: {
			type: Boolean,
			default: false,
		},

		filled: {
			type: Boolean,
			default: false,
		},

		// palette
		palette: {
			type: [Object, String],
			default: null,
		},

		// icon
		icon: {
			type: String,
			default: null,
		},

		iconState: {
			type: [String, Number, Boolean],
			default: null,
		},

		iconPlacement: {
			type: String,
			default: 'start',
			validator(value) {
				return _.contains(ICON_PLACEMENTS, value);
			},
		},

		// paint icon with button colors
		iconPainted: {
			type: Boolean,
			default: false
		}
	},


	computed: {
		actualPalette() {
			if (!_.contains(AVAILABLE_PALETTES, this.palette)) return;
			return this.palette;
		},
	},
};
</script>



<style lang="sass" scoped >
.nice-button
	--default-color: var(--main_color)
	--hover-color: var(--main_lighter_color)
	--active-color: var(--main_color)
	--disabled-color: var(--disabled_color)
	--text-color: var(--text_3_color)

	margin: 0

	position: relative
	z-index: 0

	display: inline-flex
	min-height: 31px
	box-sizing: border-box
	flex-direction: row
	justify-content: flex-start
	align-items: center

	padding: 0

	background: none
	border-radius: $ni_button-border-radius
	border: none
	color: var(--default-color)
	cursor: pointer
	font-size: $fsz__small
	font-weight: bold
	line-height: $txt__line_height
	// overflow: hidden
	text-transform: uppercase
	user-select: none

	transition: color .2s ease, background-color .2s ease, border-color .2s ease

	> *:not(:first-child)
		margin-left: .8em

	&:hover
		color: var(--hover-color)

	&:active
		color: var(--active-color)

	&:disabled
		color: var(--disabled-color)

	&.__bordered
		border: $ni_button-border-width solid currentColor
		padding: $ni_button-padding-y $ni_button-padding-x
		justify-content: center

	&.__watercolor
		border: $ni_button-border-width solid transparent
		padding: $ni_button-padding-y $ni_button-padding-x
		justify-content: center

		&::before
			content: ""
			position: absolute
			top: -$ni_button-border-width
			right: -$ni_button-border-width
			bottom: -$ni_button-border-width
			left: -$ni_button-border-width
			border-radius: $ni_button-border-radius
			z-index: -1
			background-color: currentColor
			opacity: .2

	&.__filled
		border: $ni_button-border-width solid transparent
		padding: $ni_button-padding-y $ni_button-padding-x
		justify-content: center
		color: var(--text-color)
		background-color: var(--default-color)


		&:hover
			background-color: var(--hover-color)
			border-color: var(--hover-color)

		&:active
			background-color: var(--active-color)
			border-color: var(--active-color)

		&:disabled
			background-color: var(--disabled-color)
			border-color: var(--disabled-color)


	&.icon-painted
		.nice-icon
			--ni-icon-sign: currentColor
			--ni-icon-base: var(--disabled-color)

	// themes
	&.gray
		--default-color: #{$nice_color-gray_dark}
		--disabled-color: #{$nice_color-gray_light_semi}
		--hover-color: #{$nice_color-gray_dark_semi}
		--active-color: #{$nice_color-gray_dark}

	&.navy
		--default-color: #{$nice_color-navy}
		--disabled-color: #{$nice_color-navy_light_ultra}
		--hover-color: #{$nice_color-navy}
		--active-color: #{$nice_color-navy}

	&.red
		--default-color: #{$nice_color-red}
		--disabled-color: #{$nice_color-red_light_ultra}
		--hover-color: #{$nice_color-red}
		--active-color: #{$nice_color-red}

	&.purple
		--default-color: #{$nice_color-purple}
		--disabled-color: #{$nice_color-purple_light_ultra}
		--hover-color: #{$nice_color-purple}
		--active-color: #{$nice_color-purple}

	&.blue
		--default-color: #{$nice_color-blue}
		--disabled-color: #{$nice_color-blue_light_ultra}
		--hover-color: #{$nice_color-blue}
		--active-color: #{$nice_color-blue}

	&.green
		--default-color: #{$nice_color-green}
		--disabled-color: #{$nice_color-green_light_ultra}
		--hover-color: #{$nice_color-green}
		--active-color: #{$nice_color-green}

	&.yellow
		--default-color: #{$nice_color-yellow_dark_semi}
		--disabled-color: #{$nice_color-yellow_light_ultra}
		--hover-color: #{$nice_color-yellow}
		--active-color: #{$nice_color-yellow_dark_semi}
</style>
