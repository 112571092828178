<template>
	<!-- Edit details modal -->
	<transition name="modal-transition">
		<nice-modal
			@close="$emit('close')"
		>
			<section class="specification-details">
				<header class="header">
					<h2 class="header__title">{{ $t('creative_specification.policy_modal_title') | capitalize }}</h2>

					<div class="header__actions">
						<nice-button-2
							@click="$emit('close')"
						>{{$t('common.close')}}</nice-button-2>
					</div>

				</header>


				<div
					class="content"
					v-html="$t('creative_specification.policy_text')"
				/>


			</section>
		</nice-modal>
	</transition>
</template>


<script>
import NiceModal from '@/ui/nice-modal';


export default {
	name: 'PrivacyPolicyModal',


	components: {
		NiceModal,
	},
};
</script>


<style lang="sass" scoped>
.specification-details
	display: flex
	flex-direction: column
	padding: 30px 40px
	box-sizing: border-box
	flex-grow: 1
	min-height: 675px
	max-width: $layout-center805-width
	width: 100%
	max-height: calc(100vh - 160px)

.header
	display: flex
	align-items: center
	margin-bottom: 30px

	& > *
		margin-left: 15px

		&:first-child
			margin-left: 0

.header__title
	font-size: 16px
	line-height: 20px
	// margin: calc((34px - 20px) / 2) 0
	font-weight: normal

.header__actions
	display: flex
	align-items: center
	justify-content: flex-end
	margin-left: auto

	& > *
		margin-left: 15px

.content
	overflow: auto
</style>
