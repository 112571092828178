<template>
	<symbol :id="`${prefix}simple_plus`" viewBox="0 0 8 8">
		<path fill="#2DC3B7" d="M5 0H3v3H0v2h3v3h2V5h3V3H5V0z"/>
	</symbol>
</template>
<script>
export default {
	name: 'NiIconSymbolSimplePlus',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: none
	fill: var(--ni-icon-sign, #ffffff)
</style>
