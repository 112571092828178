<template>
	<section>
		<nice-tabs>
			<tab-base data-ni_tab_name="Base" />
			<tab-cell-name data-ni_tab_name="Cell Name" />
			<tab-cell-status data-ni_tab_name="Cell Status" />
		</nice-tabs>
	</section>
</template>

<script>
import NiceTabs from '@/ui/nice-tabs.vue';

import TabBase from './tab-base';
import TabCellName from './tab-cell-name';
import TabCellStatus from './tab-cell-status';


export default {
	name: 'PageDevTable',
	components: {
		NiceTabs,

		TabBase,
		TabCellName,
		TabCellStatus,
	},
};
</script>
