<template>
	<page-request-create
		v-if="mode === 'edit'"
		v-bind="$attrs"
		:resource="resource"
		v-on="$listeners"
	/>
	<page-request-review
		v-else-if="mode === 'view'"
		v-bind="$attrs"
		:resource="resource"
		v-on="$listeners"
	/>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { APPROVED as STATUS_APPROVED } from '@/constants/audit-status-codes';

const PageRequestCreate = () => import('./create/index.vue');
const PageRequestReview = () => import('./review/index.vue');


export default {
	name: 'PageRequestDetails',


	props: {
		resource: {
			type: String,
			default: 'request',
		},
	},

	components: {
		PageRequestCreate,
		PageRequestReview,
	},

	computed: {
		...mapState('app', { appSide: 'side' }),

	  mode() {
		  if (this.appSide === 'dsp' && this.resource === 'request') {
			  return 'edit';
		  }

		  return 'view';
	  },
	},

	methods: {
		...mapActions('request', {
			getRequest: 'getItem'
		})
	},

	async created() {
		const id = this.$attrs.id;
		if (!id) {
			return null;
		}

	  // redirect to campaign if request is approved
	  if (this.resource === 'request') {
		  const instance = await this.getRequest(id);

		  for (let audit of instance.status) {
			  if (audit.status === STATUS_APPROVED) {
				  await this.$router.replace({
					  name: 'campaign-details',
					  params: {
						  id: instance.campaign
					  },
				  });
			  }
		  }
	  }
	}
};
</script>
