<template>
	<section>
		<nice-spoiler
			:label="$t('campaign.environment_label') | capitalize"
			:expand="isEnvSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetEnvironments"
		>
			<nice-tree-check
				v-model="innerEnvironments"
				:tree="environmentList"
			/>
		</nice-spoiler>
	</section>
</template>


<script>
import _ from 'underscore';

import NiceSpoiler from '@/ui/nice-spoiler';
import NiceTreeCheck from '@/ui/nice-tree-check';


export default {
	name: 'EnvironmentsTree',


	components: {
		NiceSpoiler,
		NiceTreeCheck,
	},


	props: {
		environments: {
			type: Array,
			default: () => [],
		},

		environmentList: {
			type: Array,
			default: () => [],
		},
	},


	data() {
		return {
			innerEnvironments: this.environments || [],
		};
	},

	computed: {
		isEnvSpoilerExpanded() {
			return !!(this.environments && this.environments.length);
		},
	},

	methods: {
		resetEnvironments() {
			this.$set(this, 'innerEnvironments', []);
		},
	},


	watch: {
		environments: {
			deep: true,
			handler(value, old) {
				// compare with this.innerValue
				if (_.isEqual(value, this.innerEnvironments)) {
					return;
				}

				this.$set(this, 'innerEnvironments', value);
			},
		},

		innerEnvironments: {
			handler(value, old) {
				if (_.isEqual(value, this.environments)) {
					return;
				}

				this.$emit('update:environments', value);
			},
		},
	},

};
</script>


<style lang="sass" scoped>
.nice-spoiler
	box-sizing: border-box
	width: 100%
	max-width: 380px
	margin: 0

	&.ni_slider--container
		margin-top: 10px
		margin-bottom: 10px
</style>
