import _ from 'underscore';
import Vue from 'vue';

import {
	setterGenerator,
	stateGenerator,
	capitalize,
} from '@/utilites';
import {
	ROLE_SUPER,
	ROLE_SSP_ADMIN,
	ROLE_SSP_APPROVE,
	ROLE_SSP_VIEWER,
	ROLE_DSP,
} from '@/constants/roles';
import {
	PAYMENT_MODEL_AD_PLAYS,
	PAYMENT_MODEL_CPM,
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';


/**
 * loading - loading keys stack
 * agency - current agency to use in api requests
 * side - ssp / dsp / guest (guest not possibly?)
 * sspNetworks - ssp networks agency can operate on
 * dspNetworks - dsp networks agency can operate on. WE SHOULD USE THIS
 */
const FIELDS = [
	'loading',
	'agency',
	'side',
	'sspNetworks',
	'dspNetworks',
	'paymentModels',
	'errorMessage',
];
const LOADING_KEY = 'store/app';

const LOCALSTORAGE_APP_SIDE_NAME = 'appSide';
export const LOCALSTORAGE_AGENCY_NAME = 'agency';
export const LOCALSTORAGE_ROLE_NAME = 'role';


export default {
	namespaced: true,

	state: {
		loading: [],
		agency: localStorage.getItem(LOCALSTORAGE_AGENCY_NAME) || null,
		// current role: super, ssp_admin, ssp_approve, ssp_viewer, dsp
		role: localStorage.getItem(LOCALSTORAGE_ROLE_NAME) || 'guest',
		// ssp or dsp
		side: localStorage.getItem(LOCALSTORAGE_APP_SIDE_NAME) || 'guest',

		// can be overridden (SSP WL)
		enabledPaymentModels: [
			PAYMENT_MODEL_AD_PLAYS,
			PAYMENT_MODEL_CPM,
			PAYMENT_MODEL_CPV,
		],

		...stateGenerator(_.without(FIELDS, 'loading', 'side',  'agency')),
	},

	mutations: {
		...setterGenerator(_.without(FIELDS, 'side', 'agency')),

		// needed for reload and route guards
		setSide(state, side) {
			state.side = side;
			localStorage.setItem(LOCALSTORAGE_APP_SIDE_NAME, side);
		},

		// needed for reload and route guards
		setRole(state, role) {
			state.role = role;
			localStorage.setItem(LOCALSTORAGE_ROLE_NAME, role);
		},

		setAgency(state, agency_id) {
			state.agency = agency_id;

			if (!agency_id) {
				localStorage.removeItem(LOCALSTORAGE_AGENCY_NAME);
			}
			else {
				localStorage.setItem(LOCALSTORAGE_AGENCY_NAME, agency_id);
			}
		},

		pushLoading(state, something) {
			state.loading.push(something);
		},

		removeLoading(state, something) {
			let index = state.loading.indexOf(something);
			if (index === -1) {
				Vue.$log.error(`Loading status attemption. But '${something}' is not in the list.`);
				return null;
			}
			state.loading.splice(index, 1);
		},

		setEnabledPaymentModels(state, models) {
			state.enabledPaymentModels = models;
		},
	},

	getters: {
		inProgress(state) {
			return state.loading.reduce((all, thing) => {
				all[thing] = true;
				return all;
			}, {});
		},

		isLoading(state) {
			return !!state.loading[`${LOADING_KEY}/select_platform`];
		},

		paymentModelsAreLoading(state) {
			return !!state.loading[`${LOADING_KEY}/payment_models`];
		},

		//
		// Helper role checks
		//
		isSSPAdminOrApprover(state) {
			return state.role == ROLE_SSP_ADMIN || state.role == ROLE_SSP_APPROVE;
		},

		isSuperAdmin(state) {
			return state.role == ROLE_SUPER;
		},

		isSSPAdmin(state) {
			return state.role == ROLE_SSP_ADMIN;
		},

		isSSPApprover(state) {
			return state.role == ROLE_SSP_APPROVE;
		},

		isSSPViewer(state) {
			return state.role == ROLE_SSP_VIEWER;
		},

		isDSP(state) {
			return state.role == ROLE_DSP;
		},
	},

	actions: {

		async getPaymentModels({ dispatch }, force) {
			Vue.$log.warn('store/app/getPaymentModels action deprecated use store/extra/getPaymentModels');
			return await dispatch('extra/getPaymentModels', force, { root: true });
		},

		/**
		 * Clear this store. Used in logout action
		 */
		clear({ commit }) {
			_.each(_.without(FIELDS, 'loading', 'side'), function(f) {
				commit(`set${capitalize(f, null)}`);
			});
			commit('setSide', 'guest');
			commit('setRole', 'guest');
			commit('setAgency', null);
		},
	},
};
