import {
	ACTION_INCLUDE,
	ACTION_EXCLUDE,

	drawCircle,
} from '../defaults';


const METERS = 'm';
// const KILOMETERS = 'km';


/**
 * Create layout class for a cluster popup
 */
export function createAreaPopupLayoutClass(window, vm) {
	const AreaBalloonContentLayout = window.ymaps.templateLayoutFactory.createClass(
		`<div class="ymap-device-popup ymap-area-popup">
			<span class="name">{{ properties.title }}</span>
			<label>{{ properties.distance_label }}</label>
			<div class="distance">
				<input
					id="radius_field"
					class="radius"
					type="number"
					value="{{ properties.radius }}"
					step="0.1"
				>

				<select id="units_field" class="units">
					<option value="km">{{ properties.kilometers_label }}</option>
					<option value="m">{{ properties.meters_label }}</option>
				</select>

				<button
					id="delete"
					class="nice-button gray"
				>{{ properties.delete_label }}</button>
			</div>

			{% if (!properties.without_control) %}
			<div class="control" >
				<button
					id="add-to-blacklist"
					class="nice-button {% if (properties.action == 'exclude') %}filled-red{% endif %} red"
				>{{ properties.add_to_blacklist_label }}</button>
				<button
					id="add-to-whitelist"
					class="nice-button {% if (properties.action == 'include') %}filled-green{% endif %}"
				>{{ properties.add_to_whitelist_label }}</button>
			</div>
			{% endif %}
		</div>`,
		{
			build() {
				AreaBalloonContentLayout.superclass.build.call(this);

				const geoObject = this.getData().geoObject;
				geoObject.balloon.events.add('close', (e) => this.balloonCloseHandler(e));

				this.radiusChangedHandler = async () => await this.handleRadiusChange();
				this.addToBlacklistHandler = async () => await this.handleActionButtonClick(ACTION_EXCLUDE);
				this.addToWhitelistHandler = async () => await this.handleActionButtonClick(ACTION_INCLUDE);
				this.deleteAreaHandler = async () => await this.handleAreaDelete();


				window.document.getElementById('radius_field').addEventListener(
					'input',
					this.radiusChangedHandler,
					false
				);
				window.document.getElementById('units_field').addEventListener(
					'input',
					this.radiusChangedHandler,
					false
				);

				window.document.getElementById('add-to-blacklist').addEventListener(
					'click',
					this.addToBlacklistHandler,
					false
				);
				window.document.getElementById('add-to-whitelist').addEventListener(
					'click',
					this.addToWhitelistHandler,
					false
				);
				window.document.getElementById('delete').addEventListener(
					'click',
					this.deleteAreaHandler,
					false
				);
			},


			clear() {
				window.document.getElementById('radius_field').removeEventListener(
					'input',
					this.radiusChangedHandler,
					false
				);
				window.document.getElementById('units_field').removeEventListener(
					'input',
					this.radiusChangedHandler,
					false
				);

				window.document.getElementById('add-to-blacklist').removeEventListener(
					'click',
					this.addToBlacklistHandler,
					false
				);
				window.document.getElementById('add-to-whitelist').removeEventListener(
					'click',
					this.addToWhitelistHandler,
					false
				);
				window.document.getElementById('delete').removeEventListener(
					'click',
					this.deleteAreaHandler,
					false
				);

				AreaBalloonContentLayout.superclass.clear.call(this);
			},


			/**
			 * Update area on the map
			 */
			async handleRadiusChange() {
				const radiusInKm = this._getRadiusInKm();
				const obj = this.getData().geoObject;
				const lon = obj.properties.get('longitude');
				const lat = obj.properties.get('latitude');

				obj.geometry.setCoordinates(drawCircle([lon, lat], radiusInKm));
			},


			/**
			 * Handle add/remove black/white button click
			 *
			 * `area` is like this
			 * {
			 * 	"id": 144,
			 * 	"sorting": 0,
			 * 	"title": "423806, Набережные Челны, Татарстан, Добрая Улица",
			 * 	"radius": 10.0,
			 * 	"latitude": 55.70271009046081,
			 * 	"longitude": 52.35764061959827,
			 * 	"action": "include"
			 * }
			 *
			 * TODO:
			 */
			async handleActionButtonClick(type) {
				const obj = this.getData().geoObject;
				const radius = this._getRadiusInKm();

				// update radius on the object too
				obj.properties.set('radius', radius);

				let newArea = {
					...this._getCurrentArea(),

					action: type,
					radius: radius,
				};

				const areas = [ ...vm.selectedAreasData ];
				let index = -1;

				if (newArea.id) {
					index = areas.findIndex((item) => item.id === newArea.id);
				}

				if (index === -1) {
					index = areas.length;
				}

				areas.splice(index, 1, newArea);

				vm.$emit('update:selectedAreasData', areas);
			},


			/**
			 * Delete area
			 */
			async handleAreaDelete() {
				const area = this._getCurrentArea();
				const areas = [ ...vm.selectedAreasData ];
				const index = areas.findIndex((item) => item.id === area.id);

				if (index !== -1) {
					areas.splice(index, 1);
				}

				vm.$emit('update:selectedAreasData', areas);
			},


			/**
			 * Reset area if not changed on a balloon close
			 */
			balloonCloseHandler(e) {
				const obj = this.getData().geoObject;
				const lon = obj.properties.get('longitude');
				const lat = obj.properties.get('latitude');
				const radius = obj.properties.get('radius');
				obj.geometry.setCoordinates(drawCircle([lon, lat], radius));
			},


			_getCurrentArea() {
				const obj = this.getData().geoObject;

				return {
					id: obj.properties.get('id'),
					sorting: obj.properties.get('sorting'),
					title: obj.properties.get('title'),
					latitude: obj.properties.get('latitude'),
					longitude: obj.properties.get('longitude'),

					action: obj.properties.get('action'),
					radius: obj.properties.get('radius'),
				};
			},


			_getRadiusInKm() {
				const radius = window.document.getElementById('radius_field').value;
				const units = window.document.getElementById('units_field').value;

				let radiusInKm = radius;
				if (units === METERS) {
					radiusInKm *= 0.001;
				}

				return radiusInKm;
			},
		}
	);

	return AreaBalloonContentLayout;
}
