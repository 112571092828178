<template>
	<symbol :id="`${prefix}warn-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<circle r="3" transform="matrix(1 0 0 -1 12 12)" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolWarn24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-yellow})
	fill-opacity: 0.2

.sign
	fill: var(--ni-icon-sign, #{$nice_color-yellow_dark_semi})
</style>
