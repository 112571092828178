<template>
	<symbol :id="`${prefix}error-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M9.91431 8.5L8.5 9.91418L10.6213 12.0355L8.5 14.1569L9.91406 15.571L12.0354 13.4497L14.1567 15.571L15.571 14.1568L13.4497 12.0355L15.571 9.91418L14.1567 8.5L12.0356 10.6213L9.91431 8.5Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolError24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-red_light_ultra})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-red})
</style>
