/**
 * `header-panel.vue` menu state
 *
 * We add routes dynamically so the menu is dynamic too
 */
import { routeAllowsRole } from '@/utilites';


export default {
	namespaced: true,

	state: {
		pages: [],
	},

	mutations: {
		/**
		 * Leave only needed menu params
		 */
		setPages(state, { routes, currentRole }) {
			state.pages = routes.filter(route => {
				return route.meta
					&& !route.meta.disabled
					&& route.meta.in_menu
					&& routeAllowsRole(route, currentRole);
			}).map(route => ({
				name: route.name,
				menu_icon: route.meta.menu_icon,
				dev: route.meta.dev,
			}));
		},
	},
};
