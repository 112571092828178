import store from '@/store';
import { request } from './request';


export const getPrefix = () => `agency/${store.state.app.agency}/${store.state.app.side}/`;


/**
 * Get available payment methods
 */
export async function getPaymentMethods() {
	return await request(`${getPrefix()}payment-methods/`);
}


/**
 * Get invoices
 */
export async function getInvoicesList(params) {
	return await request(`${getPrefix()}invoice/`, params);
}


/**
 * Add balance
 *
 * TODO: change signature?
 */
export async function getAddBalanceFormData(params) {
	return await request(
		`${getPrefix()}add-balance/`,
		params,
		'post',
	);
}
