<template>
	<div class="invite-user">
		<div class="title">
			<nice-icon2 icon="man" class="icon"/>
			<div>Приглашение пользователя</div>
		</div>
		<nice-input
			class="input-field"
			:value="userData.email"
			type="text"
			label="Email to send invitation to"
		/>
		<nice-input
			class="input-field"
			:value="userData.surname"
			type="text"
			label="Surname"
		/>
		<nice-input
			class="input-field"
			:value="userData.name"
			type="text"
			label="Name"
		/>
		<nice-input
			class="input-field"
			:value="userData.fathersName"
			type="text"
			label="Fathers Name"
		/>
		<nice-select
			v-model="agency"
			class="input-field"
			labeled
			label="Agency"
			:options="agenciesOptions"
		/>
		<nice-select
			class="input-field"
			:input-value="userData.options.lang"
			labeled
			label="Language"
			:options="selectOptions.langOptions"
		/>
		<nice-select
			class="input-field"
			:input-value="userData.options.dateFormat"
			labeled
			label="Date format"
			:options="selectOptions.dateOptions"
		/>
		<nice-select
			class="input-field"
			:input-value="userData.options.timeFormat"
			labeled
			label="Time format"
			:options="selectOptions.timeOptions"
		/>
		<div class="buttons">
			<nice-button-2 bordered>Cancel</nice-button-2>
			<nice-button-2 filled>Send Invite</nice-button-2>
		</div>
	</div>
</template>

<script>
import NiceInput from '@/ui/nice-input';
import NiceSelect from '@/ui/nice-select';
import NiceButton2 from '@/ui/nice-button-2';

export default {
	name:'InviteUser',

	components: {
		NiceInput,
		NiceSelect,
		NiceButton2,
	},

	props: {
		selectOptions: {
			type: Object,
			required: true,
		},
		agenciesOptions: {
			type: Array,
			required: true,
		},
		userData: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			agency: this.userData.agency,
		};
	},
};
</script>

<style lang="sass" scoped>
.title
	font-size: 16px
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 48px

.icon
	margin-right: 12px

.buttons
	margin-top: 24px
	display: flex
	flex-direction: row
	align-items: center
	justify-content: space-between
	margin-bottom: 36px

</style>
