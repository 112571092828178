<template>
	<td
		v-tippy="bubbleConfig"
		:content="changedBy"
		v-html="typeof datetime == 'string' ? datetime : formatDate(datetime)"
	/>
</template>

<script>
import { formatDate } from '@/utilites';


export default {
	name: 'TableCellAuthor',


	props: {
		author: Object,

		datetime: {
			type: [Date, String],
			required: true,
		},

		bubbleConfig: {
			type: Object,
			required: false,
			default: () => ({
				followCursor: true,
				placement: 'left',
				offset: '15, 10'
			}),
		},
	},


	computed: {
		changedBy() {
			if (!this.author) {
				return '';
			}
			return `${ this.author.first_name } ${ this.author.last_name } <${ this.author.email }>`;
		},
	},


	methods: {
		formatDate,
	},
};
</script>
