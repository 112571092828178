<template>
	<section>
		<div class="page-wrapper layout-full_width">


			<div class="top-panel">
				<nice-button-2
					v-if="viewType === 'child-agency-user'"
					icon="arrow-14"
					icon-state="90"
					@click="goBack"
				>
					{{ $t('back') | capitalize }}
				</nice-button-2>
			</div>

			<template
				v-if="instance"
			>
				<header class="header">
					<details-header
						:title="`${instance.first_name} ${instance.last_name}`"
						:subtitle="instance.email"
						class="header__details-header"
					/>
					<div
						v-if="!instance.is_email_verified"
						class="verify-email"
					>
						<span>{{ $t('users.verify_email', {n: daysLeft}) }}</span>
						<br>
						<nice-button-2
							@click="sendEmail"
						>
							{{ $t('users.send_verify_email') }}
						</nice-button-2>
					</div>

					<div
						class="header__buttons"
					>
							<nice-button-2
								icon="cancel-24"
								:disabled="!isChanged"
								palette="gray"

								@click="reset"
							>
								{{ $t('reset') | capitalize}}
							</nice-button-2>

							<nice-button-2
								icon="check-24"
								:disabled="!isChanged || !isUserCardValid"
								palette="green"

								@click="applyChanges"
							>
								{{ $t('apply') | capitalize}}
							</nice-button-2>
					</div>
				</header>


				<user-card
					v-model="instance"
					:is-profile="viewType === 'profile'"

					@show-modal="showTfaModal"
					@userCard:valid="setUserCardIsValid"
				/>

				<div class="tos">
						<a
								:href="tosFile"
								target="_blank"
						>
								{{ $t('users.tos_caption') | uppercase }}
						</a>
				</div>

				<div
					v-if="viewType === 'profile'"
					class="two-factor-auth"
				>
					<span class="two-factor-auth__span">
						{{ $t('two_factor_auth.two_factor_auth') | capitalize }}
					</span>

					<nice-button-2
						v-if="!instance.have_2fa"
						class="two-factor-auth__item"
						type="submit"
						filled
						@click="activate2fa"
					>{{ $t('two_factor_auth.activate') | capitalize }}</nice-button-2>

					<nice-button-2
						v-if="instance.have_2fa"
						class="two-factor-auth__item"
						type="submit"
						filled
						@click="deactivate2fa"
					>{{ $t('two_factor_auth.deactivate') | capitalize }}</nice-button-2>

					<nice-button-2
						v-if="instance.have_2fa"
						class="two-factor-auth__item"
						type="submit"
						filled
						@click="refreshCodes"
					>{{ $t('two_factor_auth.regenerate_codes') | capitalize }}</nice-button-2>
				</div><!-- END: .two-factor-auth -->

				<!-- TODO: move into agency settings -->
				<nice-tabs
					v-if="viewType === 'profile' && agency && platforms"
				>

					<section :data-ni_tab_name="$t('common.platforms') | capitalize">
						<platforms-list
							:platforms="platforms"
							:agency="agency"
						/>
					</section>


					<section
						v-if="showUsers"
						:data-ni_tab_name="$t('common.users') | capitalize"
					>
						<users-list
							ref="users_list"
							:items="usersList"
							:agency="agency"

							:invitationShowRolesSelect="invitationShowRolesSelect"
							:invitationRolesList="invitationRolesList"

							@remove="removeUserOrInvitation"
							@send-invitation="sendInvitation"
							@interact="interactUser"
						/>
					</section>

				</nice-tabs>
			</template>
			<template v-else>
				<!-- TODO: loader?.. -->
			</template>

		</div><!-- END: .page-wrapper -->


		<!-- modal windows -->
		<nice-modal
			v-if="showQrBox || showDisableTFA || showRefreshCodes || showChangeEmail"
			class="tfa-modal"
			@close="handleModalClose"
		>
			<qr-box
				v-if="showQrBox"
				class="tfa-modal__box"
				:qr-svg="qrCode"
				@ok="handleCodeSubmitOk"
				@close="hanldeQrBoxClose"
			/>

			<disable-tfa
				v-if="showDisableTFA"
				class="tfa-modal__box"
				@ok="handleCodeSubmitOk"
				@close="hanldeDisableTFAClose"
			/>

			<refresh-static-codes
				v-if="showRefreshCodes"
				class="tfa-modal__box"
				@ok="handleCodeSubmitOk"
				@close="hanldeRefreshCodesClose"
			/>

			<change-email-tfa
				v-if="showChangeEmail"
				:email="newEmail"
				class="tfa-modal__box"

				@ok="handleCodeSubmitOk"
				@close="handleChangeEmailClose"
			/>

		</nice-modal>
	</section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';


import {
	// TODO: old?
	requestChildAgency,
	requestChildAgencyUser,
	updateChildAgencyUser,

	// new agency methods
	requestAgencyInvitations,
	requestAgencyUsers,
	// deleteAgencyInvitation,
	// deleteAgencyUser,
} from '@/api/agency';
import { sendVerifyEmail } from '@/api/profile';
import {
	createInvitation,
	deleteInvitation,

	INVITATION_ROLE_SSP_ADMIN,
	INVITATION_ROLE_SSP_APPROVER,
	INVITATION_ROLE_SSP_VIEWER,
	INVITATION_ROLE_DSP_ADMIN,
} from '@/api/invitation';
import {
	//ROLE_SUPER,
	ROLE_SSP_ADMIN,
	ROLE_SSP_APPROVE,
	ROLE_SSP_VIEWER,
	ROLE_DSP,
} from '@/constants/roles';

import { getQRCode } from '@/api/profile';

import { hasRole as userHasRole } from '@/models/users';

import NiceTabs from '@/ui/nice-tabs';
import UserCard from '@/components/users/user-card';
import UsersList from '@/components/users/users-list';
import DetailsHeader from '@/components/users/details-header';
import PlatformsList from '@/components/users/platforms-list';
import { notifyOk, notifyError } from '@/plugins/notifications';
import NiceModal from '@/ui/nice-modal';

import QrBox from '@/components/users/qr-box';
import DisableTfa from '@/components/users/disable-tfa';
import ChangeEmailTfa from '@/components/users/change-email-tfa';
import RefreshStaticCodes from '@/components/users/refresh-static-codes';


export default {
	name: 'PageUserDetails',


	components: {
		NiceModal,
		DetailsHeader,
		NiceTabs,
		UserCard,
		UsersList,
		PlatformsList,
		QrBox,
		DisableTfa,
		RefreshStaticCodes,
		ChangeEmailTfa
	},


	props: {
		// user ID?
		id: {
			type: Number,
			required: true,
		},

		// for child DSP agency
		agencyId: {
			type: Number,
			required: false,
		},

		// ssp, dsp
		// TODO: we have `side` and `appSide`!!??
		side: {
			type: String,
			required: true,
		},

		// we use this page for current user's `profile` and `child-agency-user`
		viewType: {
			type: String,
			default: 'profile',
		},

		resource: {
			type: String,
			default: 'user',
		},

	},


	data() {
		return {
			instance: {},

			agency: {},

			isChanged: false,

			qrCode: null,

			// modals
			showQrBox: false,
			showDisableTFA: false,
			showRefreshCodes: false,
			showChangeEmail: false,

			users: [],
			invitations: [],

			newEmail: null,
			isUserCardValid: true
		};
	},


	computed: {
		...mapState('app', {
			appSide: 'side',
			currentAgencyId: 'agency',
			currentRole: 'role',

			tosFile() {
				return this.$uioverrides.tos_file || process.env.VUE_APP_DEFAULT_TOS_FILE;
			},
		}),


		platforms() {
			let platforms = [];

			if (this.side === 'ssp') {
				platforms = this.agency.ssp_networks;
			}

			if (this.side === 'dsp') {
				platforms = this.agency.dsp_networks;
			}

			return platforms;
		},


		daysLeft() {
			return moment(this.instance.last_email_change).add(7, 'days').format('DD.MM.YYYY');
		},


		showUsers() {
			return (
				this.currentRole === ROLE_SSP_ADMIN
				|| this.currentRole === ROLE_DSP
			);
		},


		/**
		 * Combine users and invitations
		 *
		 * TODO: copied from `pages/agency/details.vue`
		 */
		usersList() {
			let users = this.users.map((item) => {
				return {
					...item,
					'detail': item.email,
					// add type - we are using one list so we need a way to differentiate
					'type': 'user',
					isRemoveAllowed: false,
				};
			});

			let invitations = this.invitations.map((item) => {
				let roleLabel = '';
				switch(item.role) {
				case INVITATION_ROLE_DSP_ADMIN:
					roleLabel = this.$t('invitation.dsp_admin');
					break;
				case INVITATION_ROLE_SSP_ADMIN:
					roleLabel = this.$t('invitation.ssp_admin');
					break;
				case INVITATION_ROLE_SSP_APPROVER:
					roleLabel = this.$t('invitation.ssp_approver');
					break;
				case INVITATION_ROLE_SSP_VIEWER:
					roleLabel = this.$t('invitation.ssp_viewer');
					break;
				}

				return {
					'id': item.id,
					'first_name': `${item.email} (${this.$t('invitation.invitation')})`,
					'last_name': '',
					'detail': `${this.$t('invitation.role')} ${roleLabel}, ${this.$t('common.expires')} ${item.expiration_date}`,
					// add type
					'type': 'invitation',
					'role': item.role,
				};
			});

			return users.concat(invitations);
		},


		invitationShowRolesSelect() {
			// TODO: or `this.side`?
			if (this.appSide == 'dsp') {
				return false;
			}

			return true;
		},


		invitationRolesList() {
			if (!this.invitationShowRolesSelect) {
				return [];
			}

			// add ssp side roles
			return [
				{
			    value: INVITATION_ROLE_SSP_ADMIN,
			    label: this.$t('invitation.ssp_admin'),
			  },
			  {
			    value: INVITATION_ROLE_SSP_APPROVER,
			    label: this.$t('invitation.ssp_approver'),
			  },
				{
			    value: INVITATION_ROLE_SSP_VIEWER,
			    label: this.$t('invitation.ssp_viewer'),
			  },
			];
		},

	},  // END: computed


	watch: {
		instance: {
			deep: true,
			handler(value) {
				this.isChanged = true;

				if (this.viewType === 'profile' && value.language !== this.$store.state.profile.language) {
					this.setLanguage(value.language);
				}
			},
		}
	},


	methods: {
		...mapActions('profile', [
			'getProfile',
			'updateProfile',
		]),

		...mapMutations('profile', [
			'setLanguage',
		]),

		setUserCardIsValid(value) {
			this.isUserCardValid = value;
		},


		async getInstance() {
			let user = null;

			if (this.viewType === 'profile') {
				user = await this.getProfile(/* force = */ true);
			}

			if (this.viewType === 'child-agency-user') {
				user = await requestChildAgencyUser(this.agencyId, this.id);
			}

			return user;
		},

		async sendEmail() {
			await sendVerifyEmail();
		},


		formatTime(date) {
			if (this.instance.time_format == 24) {
				return moment(date).format('hh:mm:ss');
			} else if (this.instance.time_format == 12) {
				return moment(date).format('hh:mm:ss A');
			}
		},


		formatDate(date) {
			if (this.instance.date_format == 'd/m/Y') {
				return moment(date).format('DD-MM-YYYY');
			} else if (this.instance.date_format == 'Y-m-d') {
				return moment(date).format('YYYY-MM-DD');
			}
		},


		async getAgency() {
			let agency = null;

			if (this.viewType === 'profile') {
				agency = this.$store.state.agency;
			}

			if (this.viewType === 'child-agency-user') {
				agency = await requestChildAgency(this.agencyId);
			}

			return agency;
		},


		async activate2fa() {
			const response = await getQRCode();
			this.qrCode = response;
			this.showQrBox = true;
		},


		deactivate2fa() {
			this.showDisableTFA = true;
		},


		refreshCodes() {
			this.showRefreshCodes = true;
		},

		showTfaModal(newEmail) {
			this.newEmail = newEmail;
			this.showChangeEmail = true;
		},


		async goBack() {
			if (this.isChanged) {
				await this.reset();
			}

			this.$router.go(-1);

		},


		async applyChanges() {
			let user = null;
			try {
				if (this.viewType === 'profile') {
					user = await this.updateProfile(this.instance);
				}

				if (this.viewType === 'child-agency-user') {
					user = await updateChildAgencyUser(this.agencyId, this.id, this.instance);
				}
				notifyOk();
			}
			catch(error) {
				notifyError();
				this.$log.error(error);
			}

			await this.$set(this, 'instance', user);
			this.isChanged = false;
		},


		async reset() {
			// we need to wait to reset `isChanged`
			await this.$set(this, 'instance', await this.getInstance(this.id));

			this.isChanged = false;
		},

		// 2fa
		handleModalClose() {
			this.hanldeQrBoxClose();
			this.hanldeDisableTFAClose();
			this.hanldeRefreshCodesClose();
			this.handleEmailChangeClose();
		},

		async handleCodeSubmitOk() {
			const user = await this.getInstance(this.id);
			this.instance.have_2fa = user.have_2fa;
			this.showQrBox = false;
			this.showDisableTFA = false;
			this.showRefreshCodes = false;
			this.showChangeEmail = false;
		},

		hanldeQrBoxClose() {
			this.showQrBox = false;
			this.qrCode = null;
		},

		hanldeDisableTFAClose() {
			this.showDisableTFA = false;
		},

		hanldeRefreshCodesClose() {
			this.showRefreshCodes = false;
		},

		handleChangeEmailClose() {
			this.showChangeEmail = false;
		},


		/**
		 * TODO: several requests! reuse API
		 */
		async getInvitations() {
			if (!this.id) {
				return;
			}

			let roles = this._getInvitationRoles();

			let res = [];
			for (let r of roles) {
				res.push(...(await requestAgencyInvitations(this.agency.id, this.appSide, r)));
			}

			this.$set(this, 'invitations', res);
		},

		async getUsers() {
			if (!this.id) {
				return;
			}

			let res = await requestAgencyUsers(this.agency.id, this.appSide);
			this.$set(this, 'users', res);
		},


		/**
		 * TODO: copied from `pages/agency/details.vue`
		 */
		async removeUserOrInvitation(item) {
			if (!confirm(`Delete ${item.first_name} ${item.last_name}?`)) {
				return;
			}

			if (item.type === 'invitation') {
				try {
					await deleteInvitation(this.currentAgencyId, item.id, item.role);
					await this.getInvitations();
				}
				catch(error) {
					this.$log.error(error);
					alert('Failed to delete invitation');
				}
				return;
			}

			// if (item.type === 'user') {
			// 	try {
			// 		await deleteAgencyUser(this.id, item.id, this.appSide);
			// 		await this.getUsers();
			// 	}
			// 	catch(error) {
			// 		this.$log.error(error);
			// 		alert('Failed to delete user');
			// 	}
			// 	return;
			// }
		},


		async sendInvitation(data) {
			// DSP Admin is a default role
			let role = data.role || INVITATION_ROLE_DSP_ADMIN;
			let email = data.email || data;

			try {
				let res = await createInvitation(this.currentAgencyId, email, role);

				if (!res) {
					throw this.$t('invitation.email_failed');
				}

				// HACK:
				this.$refs.users_list.isInvitationSent = true;

				await this.getInvitations();
			}
			catch(error) {
				this.$log.error(error);

				let message = this.$t('invitation.failed');
				if(error && error.response && error.response.data && error.response.data.error) {
					// translations are on the backend
					message = error.response.data.error;
				}

				alert(message);
			}
		},


		/**
		 * Interact with ssp viewer only
		 */
		interactUser(item) {
			if (item.type !== 'user') {
				return;
			}

			// agency _IS_ the agency in the API request - "current agency"
			if (!userHasRole(item, ROLE_SSP_VIEWER)) {
				return;
			}

			this.$router.push({
				name: 'user-ssp-viewer-details',
				params: {
					id: item.id,
					agencyId: this.currentAgencyId,
				},
			});
		},


		/**
		 * TODO: all the values are from the state!!!
		 */
		_getInvitationRoles() {
			let roles = [];

			switch(this.currentRole) {
			case ROLE_SSP_ADMIN:
			case ROLE_SSP_APPROVE:
				roles = [
					INVITATION_ROLE_SSP_ADMIN,
					INVITATION_ROLE_SSP_APPROVER,
					INVITATION_ROLE_SSP_VIEWER
				];
				break;

			case ROLE_DSP:
				roles = [INVITATION_ROLE_DSP_ADMIN];
				break;
			}

			return roles;
		},

	},  // END: methods


	async mounted() {
		this.reset();

		this.$set(this, 'agency', await this.getAgency());

		if (this.viewType === 'profile' && this.showUsers) {
			try {
				await this.getInvitations();
			}
			catch (error) {
				this.$log.error(error);
			}

			try {
				await this.getUsers();
			}
			catch (error) {
				this.$log.error(error);
			}
		}
	},
};
</script>


<style lang="sass" scoped >
.top-panel
	position: sticky
	top: 0
	z-index: 1

	display: flex
	flex-direction: row
	align-items: center
	justify-content: space-between
	margin: 0
	padding: 30px
	width: auto

	background: #fff


.header
	display: flex
	flex-direction: row
	align-items: center
	margin-bottom: 24px

	& > *
		margin-right: 40px

		&:last-child
			margin-right: 0


.header__details-header

.header__buttons
	margin-left: auto
	& > *
		margin-right: 40px

		&:last-child
			margin-right: 0

.two-factor-auth
	align-items: center
	display: flex
	justify-content: flex-start
	margin-bottom: 40px

.two-factor-auth__item
	margin-left: 15px

.two-factor-auth__span
	font-size: 15px

.tfa-modal .tfa-modal__box
	max-width: 380px

.verify-email
	max-width: 400px
	font-size: 15px

// TODO: margin is eaten somehow
::v-deep .nice-tabs
	padding-bottom: 30px

.tos 
	margin-bottom: 20px

.tos a
	display: block
	color:  var(--text-color)
	text-decoration: none
	cursor: pointer

.tos a:hover
	text-decoration: underline

</style>
