import {
	addDevicesToManualFilter,
	removeDevicesFromManualFilter,
} from '@/api/dsp/manual-inventory-filter';
import {
	DEVICE_BLACK_LIST,
	DEVICE_WHITE_LIST,
} from '@/constants/device';


/**
 * Create layout class for a cluster popup
 */
export function createClusterPopupLayoutClass(window, vm) {
	const ClusterBalloonContentLayout = window.ymaps.templateLayoutFactory.createClass(
		`<div class="ymap-device-popup">
			<span class="name">{{ properties.devices_selected_label }}: {{ properties.devices_selected_count }}</span>

			{% for device in properties.devices %}
				<div class="device-item">
					<span>{{ device.properties.name }} - {{ properties.count_screen_short_label }} {{ device.properties.count_screen }}</span>
				</div>
			{% endfor %}
			{% if (properties.devices_selected_count > properties.max_devices) %}
				<div class="device-item">
					<span>...</span>
				</div>
			{% endif %}

			{% if (!properties.without_control) %}
			<div class="control" >
				<button
					id="add-to-blacklist"
					class="nice-button {% if (properties.state == 'black') %}filled-red{% endif %} red"
				>{{ properties.add_to_blacklist_label }}</button>
				<button
					id="add-to-whitelist"
					class="nice-button {% if (properties.state == 'white') %}filled-green{% endif %}"
				>{{ properties.add_to_whitelist_label }}</button>
			</div>
			{% endif %}
		</div>`,
		{
			build: function () {
				ClusterBalloonContentLayout.superclass.build.call(this);

				this.addToBlacklistHandler = async () => await this.handleActionButtonClick(DEVICE_BLACK_LIST);
				this.addToWhitelistHandler = async () => await this.handleActionButtonClick(DEVICE_WHITE_LIST);
				
				const withoutControl = this.getData().properties.get('without_control');

				if (!withoutControl) {
					const blacklistButton = window.document.getElementById('add-to-blacklist');
					if (blacklistButton) {
						blacklistButton.addEventListener(
							'click',
							this.addToBlacklistHandler,
							false
						);
					}
					
					const whiteListButton = window.document.getElementById('add-to-whitelist');
					if (whiteListButton) {
						whiteListButton.addEventListener(
							'click',
							this.addToWhitelistHandler,
							false
						);
					}
				}
			},

			clear: function () {
				const withoutControl = this.getData().properties.get('without_control');

				if (!withoutControl) {

					const blacklistButton = window.document.getElementById('add-to-blacklist');
					if (blacklistButton) {
						blacklistButton.removeEventListener(
							'click',
							this.addToBlacklistHandler,
							false
						);
					}

					const whiteListButton = window.document.getElementById('add-to-whitelist');
					if (whiteListButton) {
						whiteListButton.removeEventListener(
							'click',
							this.addToWhitelistHandler,
							false
						);
					}
				}

				ClusterBalloonContentLayout.superclass.clear.call(this);
			},


			/**
			 * Handle add/remove black/white button click
			 */
			handleActionButtonClick: async function(type) {
				const object = this.getData().object;
				const devices = object.features.map(d => d.properties.id);
				const currentType = object.properties.state;

				try {
					// remove rule on a repeating click
					if (type === currentType) {
						await removeDevicesFromManualFilter(
							vm.campaignId,
							devices,
						);
					}
					// add rule
					else {
						await addDevicesToManualFilter(
							vm.campaignId,
							type,
							devices,
						);
					}
				}
				catch (error) {
					const data = error.response && error.response.data;
					vm.$log.error(`Error ${error.response && error.response.status}. Failed rule change.`, data);
					throw error;
				}

				vm.$emit('update');
			},
		}
	);

	return ClusterBalloonContentLayout;
}
