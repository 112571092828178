<template>
	<div
		class="nice-range"
		:class="{
			'scaled': scaleMarkers,
			'disabled': disabled,
			'demo': demo,
		}"
	>
		<!-- <nice-range-viz/> -->
		<!-- <div :class="{margined: scale}"> -->
		<slot
			name="viz"
			:range="[0,axisLimit]"
			:selected = "markersArray"
		/>
		<!-- </div> -->

		<div
			ref="axis"
			class="ni-axis"
		>

			<template
				v-if="initialized"
			>


				<!-- scale -->
				<template v-if="scaleMarkers">
					<div
						v-for="marker in scaleMarkers"
						:key="`scale-marker-${marker.value}`"

						class="ni-scale-marker"
						:class="{
							'in-range': marker.position >= markers[0] && marker.position <= markers[1]
						}"
						:style="{
							'--x-shift': pos2shift(marker.position),
						}"
					>
						<span class="ni-scale-label">{{ marker.label || marker.value }}</span>
					</div>
				</template>


				<!-- render markers if not demo mode -->
				<template
					v-if="!demo"
				>
					<span
						ref="range"
						class="ni-range"
						:class="{
							'active': context.active,
						}"

						:style="{
							'--a-shift': markersShift[0],
							'--b-shift': markersShift[markersShift.length - 1],
						}"
					/>

					<!-- render markers if not disabled -->
					<template
						v-if="!disabled"
					>
						<div
							v-for="(marker, index) in markers"
							:key="`marker-${index}`"
							ref="marker"
							class="ni-marker"
							:class="{
								'active': (index == context.index) && context.active,
								'last-active': index == context.index
							}"

							:style="{
								'--x-shift': markersShift[index],
							}"

							@mousedown="onDragStart($event, index)"
						/>
					</template>
				</template>
			</template>
		</div>

		<!-- legend -->
		<div
			v-if="legendSlotList"
			class="ni-legend-wrapper"
		>
			<legend-item
				v-for="(slotIndex, index) in legendSlotList"
				:key="`legend-item-${index}`"
				:vnode="$slots.default[slotIndex]"
				:val2pos="val2pos"
				:pos2shift="pos2shift"
			/>
		</div>
	</div>
</template>


<script>
import _ from 'underscore';
import VnodeRenderer from '@/ui/vnode-renderer';

export NiceRangeLegendItem from './nice-range-legend-item';
export NiceRangeViz from './nice-range-viz';

const MODEL = Object.freeze({
	prop: 'value',
	event: 'input',
});


export default {
	name: 'NiceRange',


	components: {
		LegendItem: VnodeRenderer,
		// NiceRangeViz,
	},


	model: MODEL,


	props: {
		[MODEL.prop]: {
			type: Array,
			default: null,  // () => [0, 0],
		},

		initial: {
			type: Array,
			default: null,
		},

		axis: {
			type: [Array, Number, Object],
			default: 100,
		},

		scale: {
			type: [Boolean, Array],
			default: null,
		},

		columns: {
			type: Object, // { func }
			default: null,
		},

		disabled: {
			type: Boolean,
		},

		demo: {
			type: Boolean,
		},
	},

	data() {
		return {
			/**
			 * markers list aka innerValue but hold value index from axis
			 */
			markers: null,

			globalDragHandler: null,
			globalDragEndHandler: null,

			context: {
				active: null,
				index: null,
				min: null,
				max: null,
			},

			initialized: false,
		};
	},

	computed: {
		markersArray(){
			return Object.keys(this.markers).map(index=>this.markers[index]);

		},
		/**
		 *computed is depended of markers
		 */
		markersShift() {
			if (!this.markers) return [];

			// avoid sideeffect warning
			const pos2shift = this.pos2shift;
			return _.keys(this.markers).map(index => pos2shift(this.markers[index]));
		},


		/**
		 * The mode of the axis:
		 * 'float' - float point position.
		 * 'fixed' - points have a fixed step
		 */
		pointsMode() {
			let mode = 'float';
			if (this.axis instanceof Array || this.axis && this.axis.step) mode = 'fixed';

			return mode;
		},


		/**
		 * Compute Axis limit:
		 * depends of axis type
		 */
		axisLimit() {
			let limit = 0;

			if (typeof this.axis === 'number') {
				limit = this.axis;
			} else if (this.axis instanceof Array) {
				limit = this.axis.length - 1;
			} else if (typeof this.axis === 'object') {
				const start = +this.axis.start || 0;
				let end = +this.axis.end;

				limit = (end - start);

				if (this.axis.step) {
					limit = ~~(limit / this.axis.step);
				}
			}

			return limit;
		},


		/**
		 * Return calculated initial data
		 * default its a min and max values
		 */
		actualInitial() {
			return this.initial || [this.pos2val(0), this.pos2val(this.axisLimit)];
		},


		/**
		 * Axis type:
		 * if `axis` prop is Array 'list' else 'normal'
		 */
		axisType() {
			let type = 'normal';

			if (typeof this.axis === 'object') type = 'custom';

			if (this.axis instanceof Array) type = 'list';

			return type;
		},


		/**
		 * Adapted scale markers list
		 */
		scaleMarkers() {
			if (!this.scale) return null;
			let scaleMarkers = [];

			// markers list provided as a prop
			if (this.scale instanceof Array) {
				return this.scale.map(point => {
					let position;

					if (Object.prototype.hasOwnProperty.call(point, 'position'))
						position = point.position;

					else if (Object.prototype.hasOwnProperty.call(point, 'value'))
						position = this.val2pos(point.value);

					else
						return null;

					return { ...point, position };
				});
			}

			// no step
			if (this.pointsMode === 'float') {
				scaleMarkers.push({
					position: 0,
					value: this.pos2val(0),
					label: this.pos2val(0),
				});

				scaleMarkers.push({
					position: this.axisLimit,
					value: this.pos2val(this.axisLimit),
					label: this.pos2val(this.axisLimit),
				});

				return scaleMarkers;

			} else if (this.pointsMode === 'fixed') {
				for (let position = 0; position <= this.axisLimit; position++) {
					scaleMarkers.push({
						position,
						value: this.pos2val(position),
						label: this.pos2val(position),
					});
				}
				return scaleMarkers;
			}

			return null;
		},


		legendSlotList() {
			if ( !this.$slots.default ) return null;

			return this.$slots.default.reduce((indexList, vnode, index) => {
				if (vnode.tag.split('-').pop() === 'NiceRangeLegendItem') indexList.push(index);
				return indexList;
			}, []);
		},
	},


	methods: {
		/**
		 * Init marker dagging:
		 * Update `this.context` with
		 * startX - cursor screen x position on start
		 * index - current marker id
		 * startPosition - marker's start position
		 * active - gragging flag
		 * min - minimal position for current marker
		 * max - maximal position for current marker
		 */
		onDragStart(event, index) {
			// update this.context
			this.context.startX = event.screenX;
			this.context.index = index;
			this.context.startPosition = this.markers[index];
			this.context.active = true;

			this.context.min = this.markers[parseInt(index) - 1] || 0;
			this.context.max = this.markers[parseInt(index) + 1] || this.axisLimit;

			// reconize touch or mouse event
			// let touch = event instanceof TouchEvent;
			let dragEvent = /* touch ? 'touchmove' : */ 'mousemove';
			let dragEndEvent = /* touch ? 'touchend' : */ 'mouseup';

			window.document.addEventListener(dragEvent, this.globalDragHandler);
			window.document.addEventListener(dragEndEvent, this.globalDragEndHandler, { once: true, });
		},


		/**
		 * Marker dragging handler.
		 * Update marker position.
		 * TODO: touch
		 */
		onDrag(event) {
			event.preventDefault();
			let totalDeltaX = event.screenX - this.context.startX;
			let deltaPosition = totalDeltaX / this.$refs.axis.clientWidth * this.axisLimit / window.devicePixelRatio;
			let newPosition = this.context.startPosition + deltaPosition;

			if (newPosition > this.context.max) newPosition = this.context.max;
			if (newPosition < this.context.min) newPosition = this.context.min;

			this.markers[this.context.index] = newPosition;
			if (this.markers[0] === this.markers[1]) {
				if (this.context.index == 0) {
					this.markers[this.context.index] = newPosition - 1;
				} else if (this.context.index == 1) {
					this.markers[this.context.index] = newPosition + 1;
				}
			}
		},


		/**
		 * Marker dragging end handler.
		 * Emit model update event.
		 * Round position if point type is fixed
		 * TODO: touch
		 */
		onDragEnd(event) {
			// console.debug('dragEnd', event)
			window.document.removeEventListener('mousemove', this.globalDragHandler);
			this.context.active = false;

			if (this.pointsMode === 'fixed') {
				this.markers[this.context.index] = Math.round(this.markers[this.context.index]);
			}
			if (!this.disabled && !this.demo) {
				this.$emit(MODEL.event, this.markers2value());
			}

		},


		/**
		* Convert single value to position
		*/
		val2pos(value) {
			let position = 0;

			// list
			if (this.axisType === 'list') {
				position = _.indexOf(this.axis, value);
				if (position === -1) {
					this.$log.error(new TypeError(`NiceRange wrong value: point '${value}' is out of provided axis.`));
					position = 0;
				}

			// normal
			} else if (this.axisType === 'normal') {
				position = value;

			// custom
			} else if (this.axisType === 'custom') {
				position = value;
				if (this.axis.start) position = position - this.axis.start;
				if (this.axis.step) position = Math.round(position / this.axis.step);
			}

			return position;
		},


		/**
		* Compute xshift by position
		*/
		pos2shift(position) {
			return `${position / this.axisLimit * 100}%`;
		},


		/**
		 * Convert single position to value
		 */
		pos2val(position) {
			let value = null;

			// list
			if (this.axisType === 'list') {
				if (position < 0 || position > this.axis.length) {
					this.$log.error(new TypeError(`NiceRange wrong value: index '${position}' is out of provided axis.`));
				}
				value = this.axis[position];

			// normal
			} else if (this.axisType === 'normal') {
				value = position;

			// custom
			} else if (this.axisType === 'custom') {
				value = position;
				if (this.axis.step) value = position * this.axis.step;
				if (this.axis.start) value += this.axis.start;
			}

			return value;
		},


		/**
		 * Convert value prop to markers.
		 */
		value2markers(value) {
			if (!value) {
				value = this[MODEL.prop];
			}

			let markers = value.map(value => {
				let position = this.val2pos(value);

				if (position < 0) {
					return 0;
				}

				if (position > this.axisLimit) {
					return this.axisLimit;
				}

				return position;
			});

			return {
				...markers,
			};
		},


		/**
		 * Convert value to markers
		 */
		markers2value(markers) {
			if (!markers) markers = this.markers;

			return _.keys(markers)
				.sort((a, b) => a - b)
				.map(i => this.pos2val(markers[i]))
			;
		},
	},


	watch: {
		/**
		 * Watch value to update markers data
		 *
		 */
		[MODEL.prop]: {
			deep: true,
			handler(newValue, oldValue) {
				if (!_.isEqual(newValue, oldValue)) {
					this.markers = this.value2markers(newValue);
				}
			},
		},
	},


	created() {
		this.globalDragHandler = event => { this.onDrag(event); };
		this.globalDragEndHandler = event => { this.onDragEnd(event); };

		this.markers = this.value2markers(this[MODEL.prop] || this.actualInitial);
	},


	mounted() {
		this.initialized = true;
		if (!this.disabled && !this.demo) {
			this.$emit(MODEL.event, this.markers2value());
		}
	},


	beforeDestroy() {
		window.document.removeEventListener('mousemove', this.globalDragHandler);
		window.document.removeEventListener('mouseup', this.globalDragEndHandler);
	},
};
</script>


<style lang="sass" scoped>
$padding-y: 5px
$axis-margin-y: 10px
$axis-width: 2px
$marker-halo-size: 28px
$marker-hover-size: 16px
$marker-size: 12px

$axis-color: $nice_color-gray
$range-color: $nice_color-blue

$range-disabled-color: $nice_color-darkgray

$scale-padding: 12px

$legend-color: $nice_color-gray


.nice-range
	--padding-y: #{$padding-y}
	--axis-margin-y: #{$axis-margin-y}
	--axis-width: #{$axis-width}
	--marker-halo-size: #{$marker-halo-size}
	--marker-hover-size: #{$marker-hover-size}
	--marker-size: #{$marker-size}

	--axis-color: #{$axis-color}
	--range-color: var(--secondary_color)

	--scale-padding: #{$scale-padding}
	--scale-marker-color: var(--axis-color)
	--scale-marker-width: 0px
	--scale-marker-height: 0px
	--scale-label-width: 5em
	--scale-label-font-size: 13.5px
	--scale-label-line-height: 16px

	--legend-color: #{$legend-color}

	position: relative
	padding: var(--padding-y) 0
	z-index: 0

	font-size: 13.3px
	line-height: 16px

	&.disabled
		--axis-color: #{$axis-color}
		--range-color: var(--secondary_disabled_color)

	&.demo
		--axis-color: transparent
		--axis-width: 0

.ni-axis
	position: relative
	width: 100%
	height: var(--axis-width)
	background-color: var(--axis-color)
	cursor: pointer
	border-radius: calc(var(--axis-width) / 2)
	margin: var(--axis-margin-y) 0

	.scaled &
		margin-top: calc(var(--scale-padding) + var(--scale-label-line-height))

.ni-range
	--a-shift: 0
	--b-shift: 0
	will-change: width, left, right
	position: absolute
	left: var(--a-shift)
	width: calc(var(--b-shift) - var(--a-shift))
	right: calc(100% - var(--b-shift))
	height: 100%
	background-color: var(--range-color)

	&.active

	&:not(.active)
		transition: left .3s ease, width .3s ease, right .3s ease


.ni-marker
	--x-shift: 0

	will-change: transform, left
	position: absolute
	z-index: 0
	top: calc(50% - var(--marker-size) / 2)
	left: calc(var(--x-shift) - var(--marker-size) / 2)
	display: block
	width: var(--marker-size)
	height: var(--marker-size)
	background-color: var(--range-color)
	border-radius: 50%
	cursor: grab

	&::before,
	&::after
		will-change: transform, opacity
		content: ""
		display: block
		position: absolute
		border-radius: 50%
		background-color: var(--range-color)

	&::before
		width: var(--marker-hover-size)
		height: var(--marker-hover-size)
		top: calc(50% - var(--marker-hover-size) / 2)
		left: calc(50% - var(--marker-hover-size) / 2)
		transition: transform .1s ease
		transform: scale3d(.5, .5, 1)

	&::after
		width: var(--marker-halo-size)
		height: var(--marker-halo-size)
		top: calc(50% - var(--marker-halo-size) / 2)
		left: calc(50% - var(--marker-halo-size) / 2)
		opacity: 0
		transition: opacity .1s ease

	&:not(.active)
		transition: left .3s ease

	&.active,
	&:hover
		&::before
			transform: scale3d(1, 1, 1)

		&::after
			opacity: .2

	&.active
		cursor: grabbing

	&.last-active
		z-index: 1


.ni-scale-marker

	position: absolute
	z-index: -1
	bottom: 0
	left: calc(var(--x-shift) - var(--scale-marker-width) / 2)
	height: var(--scale-marker-height)
	width: var(--scale-marker-width)

	background-color: var(--scale-marker-color)
	border-radius: calc(var(--scale-marker-width) / 2)

	&.in-range
		--scale-marker-color: var(--range-color)


.ni-scale-label

	position: absolute
	display: block

	bottom: calc(var(--axis-width) + var(--scale-padding))
	left: calc((var(--scale-marker-width) - var(--scale-label-width)) / 2)
	width: var(--scale-label-width)

	text-align: center
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap

	pointer-events: none


.ni-legend-wrapper
	display: block
	position: relative
	height: 1.25em
	color: var(--legend-color)

</style>
