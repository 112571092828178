<template>
	<main>
		<section class="layout-full_width" >
			<h1 class="page-title">
				<nice-icon2
					icon="money"
					class="page-title-icon"
				/>
				{{ $t('page_caption_billing') }}
			</h1>


			<!-- START QUICK BUTTONS -->
			<div
				v-if="appSide === 'dsp'"
				class="quick-buttons-container"
			>

				<div class="buttons">

					<!-- Add Balance -->
					<router-link
						:to="{
							name: 'balance-add',
						}"
						class="button"
					>
						<nice-icon2 icon="money" class="icon" />
						<span class="plus-icon">+</span>

						<span class="label">{{ $t('dashboard.add_balance') }}</span>
					</router-link>

				</div>
			</div>
			<!-- END: START QUICK BUTTONS -->


			<NiceTabs>

				<!-- Transactions -->
				<section
					:data-ni_tab_name="$t('transactions.transactions')"
					data-ni_tab_slug="transactions"
					data-ni_tab_active
					class="layout-full_width"
				>
					<table class="nice-table">
						<thead>
							<tr>
								<th>{{ $t('transactions.id') | capitalize }}</th>
								<th>{{ $t('transactions.amount')  | capitalize }} ({{ agency.currency }})</th>
								<th>{{ $t('transactions.correspondent') | capitalize }}</th>
								<th>{{ $t('transactions.campaign_id') | capitalize }}</th>
								<th>{{ $t('transactions.description') | capitalize }}</th>
								<th>{{ $t('transactions.time') | capitalize }}</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="transaction in transactionsList"
								:key="transaction.id"
							>
								<td>{{ transaction.id }}</td>

								<td
									v-if="transaction.credit.account == agency.id"
									:style="{ color: $palette['red'] }"> -{{ transaction.amount }}
								</td>
								<td
									v-else-if="transaction.debit.account == agency.id"
									:style="{ color: $palette['green'] }"> +{{ transaction.amount }}
								</td>

								<td
									v-if="transaction.credit.account == agency.id"
								> {{ transaction.debit.name || $t('transactions.cash') | capitalize }}
								</td>
								<td
									v-else-if="transaction.debit.account == agency.id"
								> {{ transaction.credit.name || $t('transactions.adding_funds') | capitalize }}
								</td>

								<td>{{ transaction.campaign_id }}</td>
								<td>{{ transaction.description }}</td>
								<td>{{ formatDateTime(transaction.ctime) }}</td>
							</tr>
						</tbody>
					</table>

					<TablePaginator
						v-model="transactionsPagination"
						:total="transactionsListTotalCount"
						:paginator-props="{ queryKey: 'p' }"

						@input="handleTransactionsPaginationChange"
					/>
				</section><!-- END: transactions -->


				<!-- Invoices -->
				<section
					v-if="currentRole !== ROLE_SSP_VIEWER"
					:data-ni_tab_name="$t('documents.invoices')"
					data-ni_tab_slug="invoices"
					data-ni_tab_active
					class="layout-full_width"
				>
					<table class="nice-table">
						<thead>
							<tr>
								<th>{{ $t('documents.invoice_id') | capitalize }}</th>
								<th>{{ $t('documents.invoice_date') | capitalize }}</th>
								<th>{{ $t('documents.invoice_total_excl_tax') | capitalize }}</th>
								<th>{{ $t('documents.invoice_status') | capitalize }}</th>
								<th>&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="invoice in invoicesList"
								:key="invoice.id"
							>
								<td>{{ invoice.number }}</td>
								<td>{{ formatDate(invoice.date) }}</td>
								<td><span v-html='formatCash(invoice.total_excl_tax, agencyCurrency)' /></td>
								<td
									class="status-cell"
									:class="`status-${invoice.status}`"
								>{{ $t(`documents.invoice_status_${invoice.status}`) | capitalize }}</td>
								<td class="actions-cell">

									<!-- Pay button -->
									<a
										v-if="invoice.status == INVOICE_STATUS_PENDING"
										:href="invoice.payment_gateway_url"
										target="_blank"
										class="action"
									>
										<NiceIcon2
											icon="bill"
											style="width: 20px; height: 24px;"
										/>
										<span>{{ $t('billing.pay') }}</span>
									</a>
									<!-- Invoice doc button -->
									<a
										v-else-if="invoice.invoice_document"
										:href="invoice.invoice_document.file"
										target="_blank"
										class="action"
									>
										<NiceIcon2
											icon="bill"
											style="width: 20px; height: 24px;"
										/>
										<span>{{ $t('documents.invoice') }}</span>
									</a>

								</td>
							</tr>
						</tbody>
					</table>

					<TablePaginator
						v-model="invoicesPagination"
						:total="invoicesListTotalCount"
						:paginator-props="{ queryKey: 'p' }"

						@input="handleInvoicesPaginationChange"
					/>
				</section><!-- END: documents -->


			</NiceTabs>

		</section>
	</main>
</template>


<script>
import { mapState, /*mapActions, mapMutations*/ } from 'vuex';

import TablePaginator from '@/components/table-paginator';
import NiceTabs from '@/ui/nice-tabs';

import {
	getTransactionsList,
} from '@/api/agency';
import {
	getInvoicesList,
} from '@/api/billing';
import documentAPI from '@/api/document';

import {
	formatCash,
	profileFormatDate as formatDate,
	profileFormatDateTime as formatDateTime,
} from '@/utilites';

import {
	ROLE_SSP_VIEWER,
} from '@/constants/roles';
import {
	INVOICE_STATUS_PENDING,
} from '@/constants/billing';
// import {
// 	DOCUMENT_TYPE_INVOICE,
// 	DOCUMENT_TYPE_COMPLETION_CERTIFICATE,
// } from '@/constants/documents';




export default {
	name: 'BillingMainPage',


	components: {
		TablePaginator,
		NiceTabs,
	},


	data() {
		return {
			// transactions
	    transactionsList: [],
	    transactionsListTotalCount: 0,
	    transactionsPagination: {
	      page: 1,
	      pageSize: 20,
	    },

			// invoices
			invoicesList: [],
	    invoicesListTotalCount: 0,
	    invoicesPagination: {
	      page: 1,
	      pageSize: 20,
	    },

			ROLE_SSP_VIEWER,
			INVOICE_STATUS_PENDING,
		};
	},


	async mounted() {
		await this.updateTransactions();

		if (this.currentRole !== ROLE_SSP_VIEWER) {
			await this.updateInvoices();
		}
	},


	watch: {
	},  // END: watch


	computed: {
		...mapState('app', {
			appSide: 'side',
			currentAgencyId: 'agency',
			currentRole: 'role',
		}),

		...mapState('agency', {
			agencyCurrency: 'currency',
		}),

		/**
		 * TODO: move into params?
		 */
		agency() {
			return this.$store.state.agency;
		},

	},  // END: computed


	methods: {

		//
		// Transaction methods
		//

		async updateTransactions() {
		  let payload = {};
		  payload.page = this.transactionsPagination.page;
		  payload.page_size = this.transactionsPagination.pageSize;

		  const transactions = await this.getTransactions(payload);
		  this.$set(this, 'transactionsList', Object.freeze(transactions.results));
		  this.transactionsListTotalCount = transactions.count;
		},


		/**
		 * get transactions API-request wrapper
		 * @param {object} payload - a payload
		 *
		 * @returns {object} - an axios response
		 */
		async getTransactions(payload) {
			let response = null;
			try {
				response = await getTransactionsList(payload);
			} catch (error) {
				const resp = error.response;
				this.$log.error(`Error ${resp && resp.status}. Failed to get a transactions list.`, resp);
				this.error = Object.freeze({
					status: resp.status,
					statusText: resp.status,
					data: resp.data,
				});
				throw error;
			}
			this.error = null;
			return response;
		},


		handleTransactionsPaginationChange({ page=1, pageSize=20 }={}) {
		  this.$set(this, 'transactionsPagination', { page, pageSize });
		  this.updateTransactions();
		},

		//
		// END: Transaction methods
		//

		//
		// Invoice methods
		//

		async updateInvoices() {
		  let payload = {
				'page': this.invoicesPagination.page,
				'page_size': this.invoicesPagination.pageSize,
			};

		  const invoices = await this.getInvoices(payload);
		  this.$set(this, 'invoicesList', Object.freeze(invoices.results));
		  this.invoicesListTotalCount = invoices.count;
		},


		handleInvoicesPaginationChange({ page=1, pageSize=20 }={}) {
		  this.$set(this, 'invoicesPagination', { page, pageSize });
		  this.updateInvoices();
		},


		/**
		 * Get documents API-request wrapper
		 *
		 * @param {object} payload - pagination data
		 *
		 * @returns {object} - an axios response
		 */
		async getInvoices(payload) {
			let response = null;
			try {
				response = await getInvoicesList(payload);
			}
			catch (error) {
				const resp = error.response;
				this.$log.error(`Error ${resp && resp.status}. Failed to get invoices list.`, resp);
				this.error = Object.freeze({
					status: resp.status,
					statusText: resp.status,
					data: resp.data,
				});
				throw error;
			}
			this.error = null;
			return response;
		},


		//
		// END: Invoice methods
		//


		/**
		 * Get documents API-request wrapper
		 *
		 * @param {object} payload - pagination and doc type data
		 *
		 * @returns {object} - an axios response
		 */
		async getDocuments(payload) {
			let response = null;
			try {
				response = await documentAPI.list(payload);
			}
			catch (error) {
				const resp = error.response;
				this.$log.error(`Error ${resp && resp.status}. Failed to get documents list.`, resp);
				this.error = Object.freeze({
					status: resp.status,
					statusText: resp.status,
					data: resp.data,
				});
				throw error;
			}
			this.error = null;
			return response;
		},


		formatCash,

		formatDate,

		formatDateTime,

	},  // END: methods
};
</script>


<style lang="sass" scoped>
.page-title
	font-weight: 400
	font-size: 18px
	line-height: 21px
	text-transform: capitalize

	.page-title-icon
		--ni-icon-sign: black
		width: 20px
		height: 19px
		vertical-align: middle
		margin-right: 6px
		margin-top: -3px

.status-pending
	color: #FF6236

.status-paid
	color: #21CF52

// .status-canceled


.quick-buttons-container
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	margin-top: 20px
	margin-bottom: 40px

	.subtitle
		font-weight: 400
		font-size: 16px
		line-height: 19px
		margin-bottom: 20px

	.buttons
		display: flex

		.button
			// flex-grow: 1
			position: relative
			display: inline-block
			min-width: 100px
			min-width: 200px
			height: 90px
			border: 1px solid $nice_color-gray_light_semi
			border-radius: 10px
			font-weight: 400
			font-size: 14px
			line-height: 16px
			text-decoration: none
			color: black

			&:hover
				color: var(--main_color)

			&:not(:last-child)
				margin-right: 10px

			.icon
				--ni-icon-sign: var(--main_color)
				position: absolute
				left: 14px
				top: 14px
				height: 20px
				width: 18px

			.plus-icon
				position: absolute
				right: 14px
				top: 14px
				font-weight: 400
				font-size: 20px
				line-height: 20px
				color: #919191

			.label
				position: absolute
				left: 14px
				bottom: 14px


.actions-cell

	.action
		display: flex
		align-items: center
		color: var(--text_2_color)
		text-decoration: none
		cursor: pointer

		&:hover
			text-decoration: underline

		span
			margin-left: 8px

</style>
