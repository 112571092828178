<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="8"
			:fill="getColor('sign')"
			stroke="none"
		/>
		<rect
			x="5"
			y="4.5"
			width="2"
			height="7"
			:fill="getColor('icon')"
			stroke="none"
		/>
		<rect
			x="9"
			y="4.5"
			width="2"
			height="7"
			:fill="getColor('icon')"
			stroke="none"
		/>
	</symbol>
</template>

<script>
export const slug = 'pause';


export default {
	name: 'NiIconPause',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},


	computed: {
		colors() {
			return {
				icon: this.$palette.white,
				sign: this.icon == 'unpublished' ? this.$palette.semidarkgray : this.$palette.yellow,
				icon__disabled: this.$palette.white__disabled,
				sign__disabled: this.icon == 'unpublished' ? this.$palette.semidarkgray__disabled : this.$palette.yellow__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
