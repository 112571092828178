<template>
	<span
		class="ni-legend-item"
		:class="{
			[`placement-${placement}`]: placement,
		}"
		:style="{
			'--x-shift': xShift,
		}"
		v-html="label"
	></span>
</template>

<script>
export default {
	name: 'NiceRangeLegendItem',

	props: {
		placement: {
			type: String,  // 'start' | 'center' | 'end' | 'index' | 'value'
			required: true,
		},

		position: {
			type: [String, Number],
			default: null
		},

		val2pos: {
			type: Function,
			required: true,
		},

		pos2shift: {
			type: Function,
			required: true,
		},

		label: {
			type: String,
			required: true,
		},
	},


	computed: {
		xShift() {
			if (this.placement !== 'index' && this.placement !== 'value') return null;

			// avoid linter sideEffect warning
			const val2pos = this.val2pos;
			const pos2shift = this.pos2shift;

			let position = this.position;
			if (this.placement === 'value') position = val2pos(position);
			return pos2shift(position);
		}
	}
};
</script>

<style lang="sass" scoped>
$legend-item-width: 45%

.ni-legend-item
	--legend-item-width: #{$legend-item-width}

	position: absolute
	display: block
	width: var(--legend-item-width)

	text-align: center

	&.placement-start
		left: 0
		text-align: left

	&.placement-end
		right: 0
		text-align: right

	&.placement-center
		left: calc((100% - var(--legend-item-width)) / 2)

	&.placement-value,
	&.placement-index
		left: calc(var(--x-shift) - var(--legend-item-width) / 2)
</style>
