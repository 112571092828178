<template>
	<main class="layout-full_width" >
		<h1 class="title-error" >{{ $t('errors.error') | capitalize }} 500 <span v-html="symbol"/> </h1>
		<p class="error-mesage" >{{ $t('errors.please_retry') }}</p>
	</main>
</template>

<script>
import _ from 'underscore';


export default {
	name: 'pageError500',

	computed: {
		symbol() {
			return `${['&#128105;', '&#128104;'][_.random(1)]}${['&#127995;', '&#127997;', '&#127998;', '&#127999;'][_.random(3)]}&zwj;💻`;
		},
	},
};
</script>
