import Vue from 'vue';
import { i18n } from '@/i18n.js';

export function notifyError(){
	return Vue.toasted.show(i18n.t('notifications.notification_error'), {
		theme: 'bubble',
		position: 'bottom-right',
		duration : 5000
	});
}

export function notifyOk(){
	return Vue.toasted.show(i18n.t('notifications.notification_success'), {
		theme: 'outline',
		position: 'bottom-right',
		duration : 5000
	});
}


/**
 * Styles are in src/assets/styles/partials/_notifications.sass
 */
export function toastedLoader(duration=1000) {
	// space MUST be in
	let toast = Vue.toasted.show(' ', {
		// drop all styles
		theme: 'none',
		position: 'bottom-right',
		duration: duration,
	});

	toast.el.innerHTML = '<div class="notification-loading-ring"><div></div><div></div><div></div><div></div></div>';

	return toast;
}
