<template>
	<div>

		<div class="cl-row cl-pad-no_sides">
			<NiceInput
				v-model="paymentName"
				:label="$t('billing.payment_name_private_label')"
				class="cl-pad cl-xs12"
			/>
		</div>


		<div class="cl-row cl-pad-no_sides">
			<NiceInput
				v-model="paymentEmail"
				label="email"
				class="cl-pad cl-xs6"
			/>

			<NiceInput
				v-model="paymentPhone"
				:label="$t('billing.payment_phone_label')"
				placeholder="+1 202 555 0123"
				class="cl-pad cl-xs6"
			/>
		</div>


		<div class="cl-row cl-pad-no_sides">
			<NiceInput
				v-model="amountExcludingVAT"
				:label="$t('billing.amount')"
				type="number"
				min="0"
				:units="currentCurrency"
				class="cl-pad cl-xs6"
			/>

		</div>


		<div class="buttons">
			<NiceButton2
				:disabled="isBusy"
				palette="gray"

				@click="close"
			>{{$t('common.close')}}</NiceButton2>

			<NiceButton2
				:disabled="!isOkToPay"

				@click="paymentHandler"
			>{{$t('billing.add_balance_button')}}</NiceButton2>
		</div>

	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';

import NiceInput from '@/ui/nice-input';

import { getAddBalanceFormData } from '@/api/billing';


export default {
	name: 'Stripe',


	components: {
		NiceInput,
	},


	props: {
		paymentMethod: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {
			isBusy: false,

			amountExcludingVAT: '',

			paymentName: '',
			paymentPhone: '',
			paymentEmail: '',
		};
	},


	mounted() {
		// init forms
		this.paymentName = this.profileFullName;
		this.paymentEmail = this.profileEmail;
		this.paymentPhone = this.profilePhone;
	},


	computed: {
		...mapState('agency', {
			'currentAgencyId': 'id',
			'currentCurrency': 'currency',
		}),

		...mapState('profile', {
			'profileEmail': 'email',
			'profilePhone': 'phone',
		}),

		...mapGetters('profile', {
			'profileFullName': 'fullName',
		}),


		/**
		 * TODO: minimum amount?
		 */
		isOkToPay() {
			let baseFieldsOK = this.paymentName
				&& this.paymentEmail
				&& this.paymentPhone
				&& this.amountExcludingVAT > 0;

			return baseFieldsOK;
		},
	},


	methods: {
		/**
		 * Add balance payment handler
		 *
		 * TODO: ...
		 */
		async paymentHandler(event) {
			let invoiceData;
			try {
				// here we get stripe PaymentIntent
				invoiceData = await getAddBalanceFormData({
					payment_method_id: this.paymentMethod.id,
					method: this.methodType,
					amount_excluding_vat: this.amountExcludingVAT,
					name: this.paymentName,
					phone: this.paymentPhone,
					email: this.paymentEmail,
				});
			}
			catch(err) {
				window.alert(this.$t('failed_to_add_balance'));
				console.error(err);
				return;
			}

			// redirect to a payment gateway
			if (invoiceData.payment_gateway_url) {
				location.href = invoiceData.payment_gateway_url;
			}
		},


		close() {
			this.$router.push({
				name: 'billing',
				hash: '#' + this.$t('documents.invoices').toLowerCase(),
			});
		},

	},

};
</script>


<style lang="sass" scoped>
.buttons
	display: flex
	flex-direction: row
	justify-content: flex-end
	margin-top: $ui-grid__mgn-top * 2

	button
		margin-left: $ui-grid__mgn-x
</style>
