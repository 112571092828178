<template>
	<symbol :id="`${prefix}copy-24`" viewBox="0 0 24 24" fill="none">
		<rect width="24" height="24" rx="12" fill="#E9E9E9"/>
		<rect x="7" y="10" width="7" height="7" rx="2" fill="#ABABAB"/>
		<path d="M10 8H15C15.5523 8 16 8.44772 16 9V14" stroke="#ABABAB" stroke-width="2"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolCopy',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>