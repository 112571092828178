<template>
	<symbol :id="`${prefix}switch`" viewBox="0 0 25 16">
		<rect
			:fill="getColor(choiceState)"
			width="25"
			height="16"
			rx="8"
			ry="8"
		/>
		<circle
			class="switcher"
			:class="[choiceState]"
			:fill="getColor('switcher')"
			cx="8"
			cy="8"
			r="6"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSwitcher',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number, Boolean],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				off: this.$palette.graydark,
				on: this.$palette.main_color,
				switcher: this.$palette.white,

				off__disabled: this.$palette.graydark__disabled,
				on__disabled: this.$palette.disabled_color,
				switcher__disabled: this.$palette.white__disabled,

				...this.palette,
			};
		},

		choiceState() {
			let state = this.state;
			if (typeof state !== 'string') {
				state = state ? 'on' : 'off';
			}
			return state;
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>


<style lang="sass" >
.switcher
	transform: translate(0, 0)
	transition: all 0.05s ease-out

	&.on
		transform: translate(9px, 0)
</style>
