import _ from 'underscore';

import apiDsp from '@/api/dsp/device';
import apiSsp from '@/api/ssp/device';
import { BUSY_FILLRATE, FREE_FILLRATE } from './constants.js';
import { setterGenerator } from '@/utilites.js';
import { storeFactory } from '../_base';


const DEFAULT_PAGE_SIZE = 10;
const serializeItem = device => {
	let availability;
	if (device.fillrate >= BUSY_FILLRATE) {
		availability = 'busy';
	} else if (device.fillrate <= FREE_FILLRATE) {
		availability = 'free';
	} else {
		availability = 'medium';
	}

	return {
		...device,
		availability: availability,
	};
};

const getRequest = (side) => side === 'ssp' ? apiSsp : apiDsp;


export default function devicesFactory() {
	const generated = storeFactory('device', getRequest, serializeItem);

	return {
		namespaced: true,

		state: {
			list: [],
			query: null,
			fieldsSensitive: null,
			totalCount: 0,
			page: 1,
			pageSize: DEFAULT_PAGE_SIZE,
			meta: null,
		},

		mutations: {
			...setterGenerator([ 'fieldsSensitive', 'totalCount', 'page', 'pageSize', 'meta' ]),
			...generated.mutations,  // setList, changeItem

			/**
			 * save filters to store
			 * + сохраняет стек используемых в фильтрах полей
			 *
			 * @param {State}
			 * @param {query{}} page
			 */
			setQuery(state, query) {
				state.query = query;

				let fields = {};
				_.each(_.keys(state.query), (filter) => {
					// дефолтное значение, атрибут фильтра равен полю девайса
					let filterFields = [filter];

					switch(filter) {
					case 'search':
						filterFields = ['name'];
						break;

					case 'radius':
						filterFields = ['address'];
						break;

					case 'resolution':
						filterFields = ['width', 'height'];
						break;

						// case 'hide_not_deployed':
						// 	filterFields = ['is_deployed'];
						// 	break;

						// параметры которые соответствуют названием полей
						// case 'address':
						// case 'orientation':
						// case 'surface_type':

						// всяко разные параметры из связанных моделей
						// case 'keywords':
						// case 'ad_duration':
						// case 'msg_duration':
						// case 'saturation':
						// case 'ad_flight':
						// case 'ad_flight_units':
						// case 'payment_model':
						// case 'audience':
						// case 'audience_interests':

					case 'hide_unavailable':
						filterFields = ['fillrate'];
						break;
					}

					_.each(filterFields, (field) => {

						if (_.has(fields, field)) {
							fields[field].push(filter);
						} else {
							fields[field] = [filter];
						}
					});
				});

				state.fieldsSensitive = Object.freeze(fields);
			},
		},

		getters: {
			...generated.getters,  // loadingKeyBase, loadingKeyList, loadingKeyItem, loadingKeyItemChange, isLoading, isLoadingList, isLoadingItem, isFilterChanged
		},

		actions: {
			..._.pick(generated.actions, [ 'requestList', 'getList', 'getItem', 'change' ]),

			// TODO: on the frontend
			async getEnvironments({ commit, rootState, rootGetters }, params) {
				return await getRequest(rootState.app.side).getEnvironments(params);
			},
			async getInterestsAndActivities({ commit, rootState, rootGetters }) {
				return await getRequest(rootState.app.side).getInterestsAndActivities();
			},
			async getLifeStages({ commit, rootState, rootGetters }) {
				return await getRequest(rootState.app.side).getLifeStages();
			},
			async getIntents({ commit, rootState, rootGetters }) {
				return await getRequest(rootState.app.side).getIntents();
			},
			async getOrientations({ commit, rootState, rootGetters }) {
				return await getRequest(rootState.app.side).orientations();
			},

			async getStats({ commit, rootState, rootGetters }, {filter}) {
				return await getRequest(rootState.app.side).stats(filter);
			},

			/*
			 * Get available resolutions and adapt for the UI
			 */
			async getResolutions({ commit, rootState, rootGetters }, params) {
				let resolutions = await getRequest(rootState.app.side).availableResolutions(params);
				resolutions = resolutions.map(item => ({
					value: item.resolution,
					label: `${item.resolution} (${item.devices})`,
					parent: null
				}));
				// resolutions.unshift({ value: 'all', label: 'All', parent: null });

				return resolutions;
			},

		},
	};
}
