<template>
	<symbol :id="`${prefix}see-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M16.8438 10.7204C14.1686 7.75986 9.83136 7.75986 7.15625 10.7204L6 12L7.15625 13.2796C9.83136 16.2401 14.1686 16.2401 16.8438 13.2796L18 12L16.8438 10.7204ZM12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12C13.5 12.8284 12.8284 13.5 12 13.5Z" class="sign"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolSee24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})
</style>
