<template>
	<section>
		<nice-tabs>
			<tab-device data-ni_tab_name="Device" />
			<tab-map data-ni_tab_name="Map" />
			<tab-table data-ni_tab_name="Table" />
		</nice-tabs>
	</section>
</template>

<script>
import NiceTabs from '@/ui/nice-tabs.vue';

import TabDevice from './tab-device';
import TabMap from './tab-map';
import TabTable from './tab-table';


export default {
	name: 'PageDevComponents',
	components: {
		NiceTabs,

		TabDevice,
		TabMap,
		TabTable,
	},
};
</script>
