const CLASS_NAME = 'v-prevent-body-scroll';
const STYLE_TAG_ATTRIBUTE = 'data-prevent-body-scroll';

/**
 * TODO: collect nodes
 */

function getCurrentScrollbarWidth() {
	return window.innerWidth - document.documentElement.clientWidth;
}


function init() {
	if (document.querySelector(`style[${STYLE_TAG_ATTRIBUTE}]`)) {
		return;
	}

	// <style> content
	const styles = `
	body.${CLASS_NAME} {
		position: fixed;
		width: 100%;
		padding-right: var(--scrollbar-width);
	}
	`;

	// <style>
	const css = document.createElement('style');
	if (css.styleSheet) {
		css.styleSheet.cssText = styles;
	} else {
		css.appendChild(document.createTextNode(styles));
	}
	css.type = 'text/css';
	css.setAttribute(STYLE_TAG_ATTRIBUTE, '');

	// inject <style>
	const head = document.querySelector('head');
	head.appendChild(css);
}

const savedScroll = {x: 0, y: 0};

/**
 * Saves the scroll position to the local directive stylesheet
 *
 * @param      {boolean}  [withWindowScroll=false]  Sets css custom property `--scrollbar-width`
 */
function setScrollPositionToStyle(withWindowScroll=false) {
	const css = document.querySelector(`style[${STYLE_TAG_ATTRIBUTE}]`);
	const body = [].find.call(css.sheet.cssRules, rule => rule.selectorText === `body.${CLASS_NAME}`);

	if (withWindowScroll) {
		body.style.top = `-${savedScroll.y}px`;
		body.style.left = `-${savedScroll.x}px`;
		body.style.setProperty('--scrollbar-width', `${getCurrentScrollbarWidth()}px`);
	} else {
		body.style.top = '';
		body.style.left = '';
		body.style.setProperty('--scrollbar-width', '0px');
	}
}

/**
 * Apply scroll blocking
 *
 * @param      {boolean}  [mode=false]  The mode
 */
function preventScroll(mode=false) {
	if (mode) {
		savedScroll.y = window.scrollY;
		savedScroll.x = window.scrollX;
		requestAnimationFrame(() => {
			setScrollPositionToStyle(true);
			document.body.classList.add(CLASS_NAME);
		});
	} else {
		requestAnimationFrame(() => {
			document.body.classList.remove(CLASS_NAME);
			window.scrollTo({top: savedScroll.y, left: savedScroll.x});
			setScrollPositionToStyle(false);
		});
	}
}


// list of blockers
const blockingVnodes = [];


/**
 * Helper
 *
 * @param      {Vnode}     example  The example node
 * @return     {function}  vnode equality predicate, comparing example and
 *                         vnode-prop
 */
const createVnodeEqPredicate = example => {
	/**
	 * Equality predicate
	 *
	 * @param      {Vnode}    vnode   The vnode
	 * @return     {Boolean}  vnode/example comparision result
	 */
	const equalityPredicate = vnode => {
		let isTheSame = false;
		if (example.componentInstance) {
			isTheSame = vnode.componentInstance && (example.componentInstance._uid === vnode.componentInstance._uid);
		} else {
			isTheSame = !vnode.componentInstance && (example.elm === vnode.elm);
		}
		return isTheSame;
	};
	return equalityPredicate;
};

/**
 * Pushes a vnode to blockingVnodes.
 *
 * @param      {Vnode}  vnode   The vnode
 */
const pushVnode = vnode => {
	const index = blockingVnodes.findIndex(createVnodeEqPredicate(vnode));
	if (index === -1) {
		blockingVnodes.push(vnode);
	} else {
		blockingVnodes.splice(index, 1, vnode);
	}
};

/**
 * Replaces oldVnode with vnode at blockingVnodes
 *
 * @param      {Vnode}  vnode     The vnode
 * @param      {Vnode}  oldVnode  The old vnode
 */
const replaceVnode = (vnode, oldVnode) => {
	const index = blockingVnodes.findIndex(createVnodeEqPredicate(oldVnode));
	if (index !== -1) {
		blockingVnodes.splice(index, 1, vnode);
	} else {
		blockingVnodes.push(vnode);
	}
};

/**
 * Removes a vnode from blockingVnodes.
 *
 * @param      {Vnode}  vnode   The vnode
 */
const removeVnode = vnode => {
	const index = blockingVnodes.findIndex(createVnodeEqPredicate(vnode));
	if (index !== -1) {
		blockingVnodes.splice(index, 1);
	}
};


let blockingState = false;

/**
 * Updates directive's  state
 *
 * @param      {Boolean}  status    The directive status
 * @param      {Vnode}    vnode     The appying vnode
 * @param      {Vnode}    oldVnode  The old vnode
 */
function update(status, vnode, oldVnode) {
	if (status && oldVnode) {
		replaceVnode(vnode, oldVnode);
	} else if (status) {
		pushVnode(vnode);
	} else {
		removeVnode(vnode);
	}
	if (blockingState === !!blockingVnodes.length) {
		return;
	}
	blockingState = !!blockingVnodes.length;
	preventScroll(blockingState);
}



// directive object
export const directive = {
	bind(el, binding, vnode) {
		init();
		const status = !{}.hasOwnProperty.call(binding, 'value') || binding.value;
		update(status, vnode);
	},

	inserted(el, binding, vnode) {
		const status = !{}.hasOwnProperty.call(binding, 'value') || binding.value;
		update(status, vnode);
	},

	update(el, binding, vnode, oldVnode) {
		const status = !{}.hasOwnProperty.call(binding, 'value') || binding.value;
		update(status, vnode);
	},

	unbind(el, binding, vnode) {
		update(false, vnode);
	},
};


// export plugin
export default {
	install(Vue, options) {
		Vue.directive('preventBodyScroll', directive);
	},
};
