<template>
	<main class="layout-full_width">
		<h1 class="title-error">
			{{ $t('billing.success_page_title') | capitalize }}
		</h1>

		<p>
			{{ $t('billing.success_page_text') }}

			<br><br>

			<nice-button-2
				@click="$router.push({name: 'login'})"
			>
				{{ $t('errors.to_the_main_page') }}
			</nice-button-2>
		</p>

	</main>
</template>


<script>
export default {
	name: 'BillingSuccessPage',
};
</script>
