<template>
	<symbol :id="`${prefix}hand`" viewBox="0 0 18 18" fill="none">
		<path d="M8 6.95745V3.5C8 2.67157 7.32843 2 6.5 2V2C5.67157 2 5 2.67157 5 3.5V9.29503C5 10.0193 4.18934 10.4481 3.59067 10.0405L3.29309 9.83785C2.59289 9.36112 1.63554 9.57521 1.20512 10.3048V10.3048C0.809575 10.9753 1.00693 11.838 1.65463 12.2698L3.37456 13.4164C4.39004 14.0934 5 15.2331 5 16.4535V17" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
		<path d="M11 5V2.5C11 1.67157 10.3284 1 9.5 1V1C8.67157 1 8 1.67157 8 2.5V4.2" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
		<path d="M14 7V3.5C14 2.67157 13.3284 2 12.5 2V2C11.6716 2 11 2.67157 11 3.5V7" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
		<path d="M17 17V6.5C17 5.67157 16.3284 5 15.5 5V5C14.6716 5 14 5.67157 14 6.5V8" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolHand',

	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>