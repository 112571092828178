<template>
	<symbol :id="`${prefix}${slug}`" viewBox="0 0 14 14">
		<g
			:fill="disabled ? colors.disabled : colors.main"
		>
			<circle cx="1.5" cy="7" r="1.5"/>
			<circle cx="7" cy="7" r="1.5"/>
			<circle cx="12.5" cy="7" r="1.5"/>
		</g>
	</symbol>
</template>

<script>
export const slug = 'dots';


export default {
	name: 'NiIconDots',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},
	},


	data() {
		return {
			slug,
		};
	},

	computed: {
		colors() {
			return {
				main: this.$palette.default,
				disabled: this.$palette.black__disabled,
				...this.palette,
			};
		},
	},
};
</script>
