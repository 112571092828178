<template>
	<transition name="modal-transition">
		<nice-modal
			@close="close"
		>
			<section
				v-if="!isSent"
				class="invitation-modal modal"
			>

				<div class="title">
					<nice-icon2 icon="man" class="icon"/>
					<div>
						{{ title || $t('invitation.invite_user') | capitalize }}
					</div>
				</div>

				<div class="inputs">
					<nice-input
						v-model="email"
						class="input-field"
						type="text"
						:label="$t('invitation.email_to_send_invitation_to') | capitalize"
					/>

					<!-- :required="true" -->
					<nice-select
						v-if="showRolesSelect"
						v-model="role"
						:options="rolesList"
						:label="$t('invitation.role')"
					/>
				</div>

				<div class="buttons">
					<nice-button-2
						@click="close"
					>
						{{ $t('cancel') | capitalize }}
					</nice-button-2>
					<nice-button-2
						:disabled="isSendButtonDisabled"
						@click="sendInvitation"
					>
						{{ $t('invitation.send_invitation') | capitalize }}
					</nice-button-2>
				</div>

			</section>

			<section
				v-if="isSent"
				class="invited-modal modal"
			>
				<div class="message">
					{{ $t('invitation.sent') }}<br>{{ email }}
				</div>

				<div class="buttons">
					<nice-button-2
						@click="close"
					>
						{{ $t('close') | capitalize }}
					</nice-button-2>
				</div>

			</section>

		</nice-modal>
	</transition>
</template>

<script>
import NiceInput from '@/ui/nice-input';
import NiceModal from '@/ui/nice-modal';
import NiceButton2 from '@/ui/nice-button-2';
import NiceSelect from '@/ui/nice-select';


export default {
	name: 'InvitationCreateModal',


	components: {
		NiceInput,
		NiceModal,
		NiceButton2,
		NiceSelect,
	},


	props: {
		// Agency ID we are inviting to
		agencyId: Number,

		title: String,

		isSent: {
			default: false,
		},

		showRolesSelect: {
			default: false,
		},

		/**
		 * Required if `showRolesSelect == true`
		 *
		 * [
		 *   {
		 *     value: <value>,
		 *     label: <label>
	   *   },
		 *   ...
	   * ]
		 */
		rolesList: {
			default: () => [],
		},
	},


	data() {
		return {
			email: '',
			role: '',
		};
	},


	computed: {
		isSendButtonDisabled() {
			return !this.email || (this.showRolesSelect && !this.role);
		},
	},


	methods: {
		/**
		 * TODO: always send object?
		 */
		sendInvitation() {
			let data = this.email;
			if (this.showRolesSelect) {
				data = {
					email: this.email,
					role: this.role,
				};
			}

			this.$emit('send-invitation', data);
		},

		/**
		 * Close the modal
		 */
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="sass" scoped>
.modal.invitation-modal,
.modal.invited-modal
	display: flex
	flex-direction: column
	padding: 30px 40px
	box-sizing: border-box
	flex-grow: 0
	overflow: auto
	min-height: 200px
	max-width: 480px
	max-height: calc(100vh - 160px)

	.title
		font-size: 16px
		display: flex
		flex-direction: row
		align-items: center
		margin-bottom: 48px

	.icon
		margin-right: 12px

	.inputs
		display: flex
		flex-direction: row

		> *
			flex-grow: 1
			width: 50%

		> :last-child
			margin-left: 24px

	.buttons
		margin-top: 24px
		display: flex
		flex-direction: row
		align-items: center
		justify-content: flex-end

		.nice-button
			margin-left: 24px

.modal.invited-modal
	background-color: var(--main_color)
	min-height: 100px

	.message
		color: white
		flex-grow: 1
		text-align: center
		font-size: $fsz__section-title
		margin-top: 24px

	.buttons
		.nice-button
			color: var(--text_color)
</style>
