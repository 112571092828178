<template>
	<div class="summary-stats" >
		<div
			v-if="plays"
			class="summary-stats__section"
		>
			<div class="summary-header">
				<h3 class="title" >{{$t('campaign.widget_ad_plays_title')}}</h3>
				<span
					v-if="timePeriod.start && timePeriod.end"
					class="header-details"
				>
					{{ dateRange[0] }} &nbsp;&mdash; {{ dateRange[1] }}
				</span>
			</div>
			<nice-progress-bar
				:value="plays.value"
				:max="plays.total"
				labeled
				:label="$t('campaign.widget_budget_label') | capitalize"
				:hint="$t('campaign.widget_ad_plays_hint')"
				:max-hint="$t('campaign.widget_ad_plays_max_hint')"
				:max-label="$t('campaign.available')"
			/>

			<div v-if="showAdditionalValue" class="summary-body">
				<div class="summary-body-element">
					<nice-label
						:label="$t('campaign.ad_flight_days') | capitalize"
						:hint="$t('campaign.ad_flight_hint')"
					/>
					<span class="summary-value">{{ adFlight || 30 }}</span>
				</div>
				<div class="summary-body-element">
					<nice-label
						:label="$t('campaign.per_day') | capitalize"
						:hint="$t('campaign.impressions_per_day_hint')"
					/>
					<span class="summary-value">{{ playsPerDay }}</span>
				</div>
				<div class="summary-body-element">
					<nice-label
						:label="$t('total') | capitalize"
						:hint="$t('campaign.impressions_total_with_days_of_week_hint')"
					/>
					<span class="summary-value"> {{ plays.value }} </span>
				</div>
			</div>

		</div>


		<template
			v-if="impressions && paymentModel !== PAYMENT_MODEL_AD_PLAYS"
		>
			<hr class="hr" />
			<div class="summary-stats__section">
				<div class="summary-header">
					<h3 class="title" >{{$t('campaign.widget_impressions_title')}}</h3>
					<span
						v-if="timePeriod.start && timePeriod.end"
						class="header-details"
					>
						{{ dateRange[0] }} &nbsp;&mdash; {{ dateRange[1] }}
					</span>
				</div>
				<nice-progress-bar
					:value="impressions.value"
					:max="impressions.total"
					labeled
					:label="$t('campaign.widget_budgeted_label') | capitalize"
					:hint="$t('campaign.widget_impressions_hint')"
					:max-hint="$t('campaign.widget_impressions_max_hint')"
					:max-label="$t('campaign.available')"
				/>
			</div>
		</template>

		<template
			v-if="inventory"
		>
			<hr class="hr" />
			<div class="summary-stats__section">
				<h3 class="title" >
					{{ $t('campaign.widget_inventory_title') }}
				</h3>

				<nice-progress-bar
					:value="inventory.value"
					value-icon="mark-14"
					:max="inventory.total"
					labeled
					:label="$t('campaign.widget_selected_label') | capitalize"
					:hint="$t('campaign.widget_inventory_hint')"
					:max-hint="$t('campaign.widget_inventory_max_hint')"
					:max-label="$t('campaign.available')"
				/>

				<div v-if="devicesUptime" class="summary-body">
					<div class="summary-body-element">
						<nice-label
							:label="$t('campaign.good_uptime') | capitalize"
						/>
						<span class="summary-value">{{ devicesUptime.good_uptime }}</span>
					</div>
					<div class="summary-body-element">
						<nice-label
							:label="$t('campaign.average_uptime') | capitalize"
						/>
						<span class="summary-value">{{ devicesUptime.average_uptime }}</span>
					</div>
					<div class="summary-body-element">
						<div class="uptime-hint">
							<nice-label
								:label="$t('campaign.bad_uptime') | capitalize"
							/>
							<nice-hint
								:hint="$t('campaign.uptime_hint')"
							/>
						</div>
						<span class="summary-value bad-uptime">{{ devicesUptime.bad_uptime }}</span>
					</div>
				</div>

			</div>


		</template>

		<template
			v-if="specs.length > 0"
		>
			<hr class="hr" />
			<div class="summary-stats__section">
				<h3 class="title" >{{$t("campaign.widget_requirements_label")}}</h3>
				<table class="nice-table table-specs" >
					<thead>
						<tr>
							<th colspan="2" >{{$t('campaign.widget_specs') | capitalize}}</th>
							<th colspan="2" >{{$t('campaign.widget_points') | capitalize}}</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(spec, i) in specs"
							:key="i"
						>
							<td class="col-icon" >
								<nice-icon-2 v-if="spec.type == 'monitor'" icon="resolution-14" />
								<nice-icon-2 v-else-if="spec.type == 'audio'" icon="melody-15" />
							</td>
							<td class="col-text" >{{ spec.type == 'monitor' ? spec.resolution : spec.type }}</td>

							<td class="col-icon" ><nice-icon-2 :icon="getCountIconByType(spec.type)" /></td>
							<td class="col-text" >{{ spec.count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</div>
</template>


<script>
import moment from 'moment';

import { formatMetric, formatNumber } from '@/utilites';

import NiceProgressBar from '@/ui/nice-progress-bar';
import NiceHint from '@/ui/nice-hint';

import {
	PAYMENT_MODEL_AD_PLAYS,
} from '@/constants/payment_models';


export default {
	name: 'DeviceCardListSummary',


	components: {
		NiceProgressBar,
		NiceHint,
	},


	props: {
		paymentModel: {
			type: String,
			default: '',
		},
		price: {
			type: Object,
		},
		plays: {
			type: Object,
		},
		impressions: {
			type: Object,
		},
		inventory: {
			type: Object,
		},
		specs: {
			type: Array,
		},
		timePeriod: {
			type: Object,
			default: () => ({ end: null, start: null, }),
		},
		// this is for displaying adFlight, playsPerDay, plays
		showAdditionalValue: {
			type: Boolean,
			default: false
		},
		playsPerDay: {
			type: Number,
			default: 0,
		},

		// {good_uptime: 0, average_uptime: 0, bad_uptime: 0}
		devicesUptime: {
			type: Object,
			required: false,
		},
	},


	data() {
		return {
			PAYMENT_MODEL_AD_PLAYS,
		};
	},


	methods: {
		formatMetric,
		formatNumber,

		getCountIconByType(type) {
			switch (type) {
			case 'audio':
				return 'audio';

			case 'monitor':
				return 'monitor-13';
			}
		},
	},

	computed: {
		dateRange() {
			return [
				moment.utc(this.timePeriod.start).format('MMM DD'),
				moment.utc(this.timePeriod.end).format('MMM DD')
			];
		},

		adFlight() {
			if (!this.timePeriod) {
				return 0;
			}

			const time_period = this.timePeriod;
			let diff = moment(time_period.end).diff(moment(time_period.start), 'days');

			if (isNaN(diff)) {
				return 0;
			}

			// moment.diff works like this `20191001-20191002 == 1`. we need 2
			diff += 1;

			return diff;
		},
	}
};
</script>

<style lang="sass" scoped >
.summary-stats
	+card-wrapper-default
	// width: 275px

.summary-header
	display: flex
	justify-content: space-between
	align-items: flex-start
	min-height: 30px

h3
	font-size: 16px
	line-height: 19px
	font-style: normal
	font-weight: normal
	margin: 0
	min-height: 30px

.summary-body
	display: grid
	grid-template-columns: 1fr 1fr 1fr

.summary-value
	font-size: 16px
	line-height: 19px

	color: #2DC3B7

.summary-body-element
	display: flex
	flex-direction: column

	&:not(:first-child)
		margin-left: 10px

.header-details
	height: $ni_label-height
	font-size: $ni_label-font-size
	line-height: $ni_label-height
	color: var(--text_2_color)
	font-family: inherit
	text-overflow: ellipsis
	text-transform: uppercase
	white-space: nowrap
	overflow: hidden

.hr
	margin-bottom: 21px

.cost
	color: var(--text_color)

	span
		color: var(--text_2_color)
		display: inline-block
		width: 40px

.title-wrapper
	display: flex
	align-items: baseline

.title-line-link
	color: var(--main_color)
	text-decoration: none
	font-weight: bold
	font-size: 11px
	line-height: 13px
	margin-left: auto



.nice-progress-bar
	width: 100%

.table-specs
	width: 100%

	thead th
		padding: 10px 0

	tbody
		tr
			height: 25px

			td
				padding: 5px 0
				color: var(--text_color)
				background-color: transparent

				> *
					vertical-align: middle

	.col-icon
		text-align: center
		width: 16px

	.col-text
		padding-left: 10px

.bad-uptime
	color: #919191

.uptime-hint
	display: flex
	flex-direction: row

	justify-content: space-between
</style>
