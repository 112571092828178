import { request } from './request';


/**
 * Update SSP Viewer Networks
 *
 * For SSP: ssp admins and ssp approvers
 * For DSP: dsp admins
 *
 * @param {int} agencyId - Agency ID
 * @param {int} userId - User ID
 * @param {Array[Object]} networks - list of ssp viewer networks, eg.
 *        [
 *          {
 *              "id": <net_id>,
 *              "discount": TODO:
 *          },
 *          ...
 *        ]
 *
 * @returns {Object} ssp viewer (like `agency.requestAgencyUser()`)
 */
export async function updateSSPViewerNetworks(agencyId, userId, networks) {
	const url = `agency/${agencyId}/ssp/ssp-viewer/${userId}/network/`;
	const data = {
		'ssp_viewer_networks': networks,
	};
	return await request(url, data, 'post');
}


/**
 * SSP Viewer's Network Devices
 */
export async function getDeviceList(
	agencyId,
	userId,
	networkId,
	{page=1, page_size=20, ...options}={}
) {
	const url = `agency/${agencyId}/ssp/ssp-viewer/${userId}/network/${networkId}/device/`;
	const params = {
		page,
		page_size,
		...options,
	};
	return await request(url, params, 'get');
}


/**
 * Manual filter (black/white list) api reference
 *
 * GET    /api/v1/agency/<agency_id>/ssp/ssp-viewer/<ssp_viewer_id>/network/<network_id>/manualfilter/?type=<type>
 * POST   /api/v1/agency/<agency_id>/ssp/ssp-viewer/<ssp_viewer_id>/network/<network_id>/manualfilter/ { device, type: black|white }
 * DELETE /api/v1/agency/<agency_id>/ssp/ssp-viewer/<ssp_viewer_id>/network/<network_id>/manualfilter/<device>/
 */

export async function getManualFilter(agencyId, userId, networkId, type) {
	const url = `agency/${agencyId}/ssp/ssp-viewer/${userId}/network/${networkId}/manualfilter/`;
	const payload = {};
	if (type) {
		payload.type = type;
	}
	return await request(url, payload, 'get');
}

export async function addDevicesToManualFilter(agencyId, userId, networkId, type, devices) {
	const url = `agency/${agencyId}/ssp/ssp-viewer/${userId}/network/${networkId}/manualfilter/`;
	return await request(url, {
		devices,
		type,
	}, 'post');
}

export async function removeDevicesFromManualFilter(agencyId, userId, networkId, devices) {
	const url = `agency/${agencyId}/ssp/ssp-viewer/${userId}/network/${networkId}/manualfilter/`;
	return await request(url, {
		devices,
	}, 'delete');
}
