<template>
	<section>
		<template
			v-for="(char, index) in sortOrder"
		>
			<div
				:key="index"
				class="big-letter"
			>
				{{ char }}
			</div>
			<div
				:key="'letterList' + index"
				class="letter-list"
			>
				<nice-user-thumb
					v-for="(item) in drawList[char]"
					:key="_itemKey(item)"
					:is-remove-allowed="isRemoveAllowed(item)"
					v-bind="item /* { id, first_name, last_name, detail, is_deleted, roles } */"
					class="in-col"
					@interact="interactHandler(item, $event)"
					@remove="removeHandler(item, $event)"
					@restore="restoreHandler(item, $event)"
				/>
			</div>
		</template>
	</section>
</template>

<script>
import NiceUserThumb from '@/ui/nice-users/nice-user-thumb';

export default {
	name: 'NiceUsersList',


	components: {
		NiceUserThumb,
	},


	props: {
		items: {
			type: Array,
			required: true
		},
	},


	// data() {},


	computed: {
		drawList() {
			return this.items.reduce((acc, el) => {
				let letter = el.email;
				if (el.last_name || el.first_name) {
					letter = (el.last_name || el.first_name);
				}

				letter = letter[0].toLowerCase();

				if (!Object.prototype.hasOwnProperty.call(acc, letter)){
					acc[letter] = [el];
				}else{
					acc[letter].push(el);
				}
				return acc;
			}, {});
		},


		sortOrder(){
			return Object.keys(this.drawList).sort();
		},
	},


	methods:{
		_itemKey(item) {
			return item.type ? item.type + '-' + item.id : item.id;
		},

		interactHandler(item, event) {
			this.$emit('interact', item, event);
		},

		removeHandler(item, event) {
			this.$emit('remove', item, event);
		},

		restoreHandler(item, event) {
			this.$emit('restore', item, event);
		},

		isRemoveAllowed(item) {
			let isRemoveAllowed = true;

			if (typeof(item.isRemoveAllowed) != 'undefined') {
				isRemoveAllowed = item.isRemoveAllowed;
			}

			return isRemoveAllowed;
		},
	},
};
</script>

<style lang="sass" scoped >
.letter-list
	$gap: 16px
	$width: 375px
	--col-width: #{$width}

	display: grid
	grid-template-columns: repeat(var(--columns-count), var(--col-width))
	grid-row-gap: $gap
	grid-column-gap: $gap
	grid-auto-rows: 1fr
	grid-auto-columns: var(--col-width)
	margin-bottom: 20px


	$page-padding: 50px
	@for $count from 0 through 10
		$min-width: $page-padding + $width * ($count + 1) + $gap * ($count)
		@media screen and (min-width: $min-width)
			--columns-count: #{$count + 1}

.in-col
	height: 56px
	width: var(--col-width)
	box-sizing: border-box

.big-letter
	font-size: 16px
	margin-bottom: 10px
	text-transform: uppercase
	width: 36px
	height: 36px
	text-align: center
	line-height: 36px
</style>
