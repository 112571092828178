<template>
	<symbol :id="`${prefix}chbx-sqr`" viewBox="0 0 20 20">
		<!-- CHECKED -->
		<template
			v-if="state == 'checked'"
		>
			<rect
				width="20"
				height="20"
				rx="6"
				:fill="getColor('main')"
			/>
			<polyline
				points="5.48 8.77 9.98 13.27 14.52 8.73"
				fill="none"
				:stroke="getColor('glyph')"
				stroke-miterlimit="10"
			/>
		</template>

		<template
			v-else-if="state == 'mixed'"
		>
			<rect
				width="20"
				height="20"
				rx="6"
				:fill="getColor('main')"
			/>
			<polyline
				points="5 10 15 10"
				fill="none"
				:stroke="getColor('glyph')"
				stroke-miterlimit="10"
			/>
		</template>

	<!-- UNCHECKED -->
		<rect
			v-else

			x=".5"
			y=".5"
			width="19"
			height="19"
			rx="5.5"
			fill="none"
			stroke-width="1"
			:stroke="getColor('border')"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconChbxSqr',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number, Boolean],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.main_color,
				glyph: this.$palette.white,
				border: this.$palette.gray,

				main__disabled: this.$palette.disabled_color,
				glyph__disabled: this.$palette.white__disabled,
				border__disabled: this.$palette.gray__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
