export default {
	modal_title: {
		en: /* TODO: to be translated */'Inventory details',
		ge: 'ინვენტარის დეტალები',
		de: 'Inventardetails',
		ru: 'Точки вещания',
		cn: /* TODO: to be translated */ null,
	},

	modal_description: {
		en: /* TODO: to be translated */ 'Here you can see all the platform\'s broadcasting points and include or exclude any of them from the final selection manually via balck-/whitelist',
		ge: 'აქ თქვენ შეგიძლიათ ნახოთ პლატფორმის ყველა სამაუწყებლო წერტილი და შეიყვანოთ ან გამორიცხოთ რომელიმე მათგანი საბოლოო არჩევანიდან ხელით balck-/whitelist-ის საშუალებით',
		de: 'Hier können Sie alle Übertragungspunkte der Plattform sehen und manuell über die Schwarz-/Weißliste ein- oder ausschließen',
		ru: 'Здесь вы можете просмотреть все устройства, доступные в рамках выбранной платформы, а так же вручную включить или исключить нужные устройства из финальной выборки',
		cn: /* TODO: to be translated */ null,
	},


	excel_export: {
		en: /* TODO: to be translated */'Export excel',
		ge: 'excel-ში გადატანა',
		de: 'Excel exportieren',
		ru: 'Экспорт в excel',
		cn: /* TODO: to be translated */ null,
	},

	refresh: {
		en: 'Refresh',
		ge: 'განახლება',
		de: 'Aktualisieren',
		ru: 'Обновить',
		cn: /* TODO: to be translated */ null,
	},


	th_rule_type: {
		en: /* TODO: to be translated */'rule type',
		ge: 'წესის ტიპი',
		de: 'Regeltyp',
		ru: 'тип правила',
		cn: /* TODO: to be translated */ null,
	},

	th_location_name: {
		en: 'location name',
		ge: 'მდებარეობის სახელი',
		de: 'Standortname',
		ru: 'название локации',
		cn: /* TODO: to be translated */ null,
	},

	th_city: {
		en: 'city',
		ge: 'ქალაქი',
		de: 'Stadt',
		ru: 'город',
		cn: /* TODO: to be translated */ null,
	},

	th_address: {
		en: /* TODO: to be translated */'address',
		ge: 'მისამართი',
		de: 'Adresse',
		ru: 'адрес',
		cn: /* TODO: to be translated */ null,
	},

	th_device: {
		en: /* TODO: to be translated */'device',
		ge: 'მოწყობილობა',
		de: 'Gerät',
		ru: 'устройство',
		cn: /* TODO: to be translated */ null,
	},

	th_specs: {
		en: /* TODO: to be translated */'specs',
		ge: 'მოწყობილობის პარამეტრები',
		de: 'Spezifikationen',
		ru: 'параметры устройства',
		cn: /* TODO: to be translated */ null,
	},

	count_screen_short: {
		en: 'screens',
		ge: 'ეკრანები',
		de: 'Bildschirme',
		ru: 'экранов',
		cn: /* TODO: to be translated */ 'screens',
	},

	count_screen: {
		en: 'count screen',
		ge: 'ეკრანების რაოდენობა',
		de: 'Bildschirmanzahl',
		ru: 'количество экранов',
		cn: /* TODO: to be translated */ 'count screen',
	},

	uptime: {
		en: 'uptime',
		ge: 'რეაგირების დრო',
		de: 'Betriebszeit',
		ru: 'время отклика',
		cn: /* TODO: to be translated */ 'uptime',
	},

	good_uptime_value: {
		en: '<12H',
		ge: '<12ს',
		de: '<12H',
		ru: '<12Ч',
		cn: /* TODO: to be translated */ '<12H',
	},

	average_uptime_value: {
		en: '>12H',
		ge: '>12ს',
		de: '>12H',
		ru: '>12Ч',
		cn: /* TODO: to be translated */ '>12H',
	},

	bad_uptime_value: {
		en: '>48H',
		ge: '>48ს',
		de: '>48H',
		ru: '>48Ч',
		cn: /* TODO: to be translated */ '>48H',
	},

	th_cpm_cost: {
		en: 'CPM ({currency})',
		ge: 'CPM ({currency})',
		de: 'CPM ({currency})',
		ru: 'CPM ({currency})',
		cn: 'CPM ({currency})',
	},

	th_cpv_cost: {
		en: 'CPV ({currency})',
		ge: 'CPV ({currency})',
		de: 'CPV ({currency})',
		ru: 'CPV ({currency})',
		cn: 'CPV ({currency})',
	},

	th_ad_plays_cost: {
		en: 'Ad Plays ({currency})',
		ge: 'რეკლამის ჩართვა ({currency})',
		de: 'Ad Plays ({currency})',
		ru: 'Ad Plays ({currency})',
		cn: 'Ad Plays ({currency})',
	},

	add_to_blacklist: {
		en: /* TODO: to be translated */'exclude',
		ge: 'გამორიცხვა',
		de: 'ausschließen',
		ru: 'исключить',
		cn: /* TODO: to be translated */ null,
	},

	add_to_blacklist_hint: {
		en: /* TODO: to be translated */'Add to blacklist',
		ge: 'დამატება შავ სიაში',
		de: 'Zur schwarzen Liste hinzufügen',
		ru: 'Добавить в чёрный список',
		cn: /* TODO: to be translated */ null,
	},

	remove_from_blacklist_hint: {
		en: /* TODO: to be translated */'Remove from blacklist',
		ge: 'წაშლა შავი სიიდან',
		de: 'Von der schwarzen Liste entfernen',
		ru: 'Удалить из чёрного списка',
		cn: /* TODO: to be translated */ null,
	},

	add_to_whitelist: {
		en: /* TODO: to be translated */'include',
		ge: 'შეიცავს',
		de: 'einschließen',
		ru: 'включить',
		cn: /* TODO: to be translated */ null,
	},

	add_to_whitelist_hint: {
		en: /* TODO: to be translated */'Add to whitelist',
		ge: 'დამატება თეთრ სიაში',
		de: 'Zur weißen Liste hinzufügen',
		ru: 'Добавить в белый список',
		cn: /* TODO: to be translated */ null,
	},

	remove_from_whitelist_hint: {
		en: /* TODO: to be translated */'Remove from whitelist',
		ge: 'წაშლა თეთრი სიიდან',
		de: 'Von der weißen Liste entfernen',
		ru: 'Удалить из белого списка',
		cn: /* TODO: to be translated */ null,
	},

	subset_platform_devices: {
		en: 'All platform devices',
		ge: 'ყველა პლატფორმის მოწყობილობა',
		de: 'Alle Plattformgeräte',
		ru: 'Все устройства сети',
		cn: /* TODO: to be translated */ null,
	},

	subset_request_devices: {
		en: 'Selected devices',
		ge: 'შერჩეული მოწყობილობები',
		de: 'Ausgewählte Geräte',
		ru: 'Выбранные устройства',
		cn: /* TODO: to be translated */ null,
	},

	subset_blacklisted_devices: {
		en: 'Blacklist',
		ge: 'შავი სია',
		de: 'Schwarze Liste',
		ru: 'Черный список',
		cn: /* TODO: to be translated */ null,
	},

	subset_whitelisted_devices: {
		en: 'Whitelist',
		ge: 'თეთრების სია',
		de: 'Weiße Liste',
		ru: 'Белый список',
		cn: /* TODO: to be translated */ null,
	},

	no_devices_message: {
		en: 'There are no devices that matching the filter',
		ge: 'არ არსებობს მოწყობილობები, რომლებიც ემთხვევა ფილტრს',
		de: 'Es gibt keine Geräte, die dem Filter entsprechen',
		ru: 'Нет устройств, соответствующих фильтру',
		cn: /* TODO: to be translated */ null,
	},

	error_message: {
		en: '<h2>Error {status}</h2><p>Devices list request failed.</p>',
		ge: '<h2>შეცდომა {status}</h2><p>მოწყობილობების სიის მოთხოვნა ვერ მოხერხდა.</p>',
		de: '<h2>Fehler {status}</h2><p>Gerätelistenanforderung fehlgeschlagen.</p>',
		ru: '<h2>Ошибка {status}</h2><p>Запрос списка устройств не удался.</p>',
		cn: /* TODO: to be translated */ null,
	},

	select_devices_subset: {
		en: 'Select devices subset',
		ge: 'მოწყობილობის ქვეჯგუფის არჩევა',
		de: 'Geräte-Teilmenge auswählen',
		ru: 'Подмножество устройств',
		cn: /* TODO: to be translated */ null,
	},

	search: {
		en: 'Search...',
		ge: 'ძებნა...',
		de: 'Suche...',
		ru: 'Поиск...',
		cn: /* TODO: to be translated */ null,
	},

	order_by: {
		en: 'Order by',
		ge: 'შეკვეთა',
		de: 'Sortieren nach',
		ru: 'Упорядочить по',
		cn: /* TODO: to be translated */ null,
	},




};
