<template>
	<!-- Edit details modal -->
	<transition name="modal-transition">
		<nice-modal
			@close="close"
		>
			<section class="inventory-details">
				<header class="header">
					<nice-icon-2
						class="header__icon"
						icon="mark-14"
					/>

					<h2 class="header__title">{{ $t('inventory_modal.modal_title') | capitalize }}</h2>
					<nice-hint :hint="$t('inventory_modal.modal_description')"/>

					<div class="header__actions">

						<nice-button-2
							bordered
							:disabled="isBusy"
							@click="exportExcel"
						>{{ $t('inventory_modal.excel_export') }}</nice-button-2>

						<nice-button-2
							bordered
							:disabled="isBusy"
							@click="close"
						>{{$t('common.close')}}</nice-button-2>
					</div>

				</header>

				<DeviceCardListSummary
					:plays="currentSelection.plays"
					:impressions="currentSelection.impressions"
					:inventory="currentSelection.inventory"
					:specs="[]"
					:paymentModel="instance.payment_model"
					:timePeriod="instance.time_period"

					:showAdditionalValue="showAdditionalValue"
					:impressionsPerDay="impressionsPerDay"
					:devicesUptime="devicesUptime"

					class="summary-section"
				/>

				<!-- Devices List -->
				<div class="table-section">
					<div class="list-actions">
						<div class="list-actions__left">
							<nice-input
								v-model="search"
								labeled
								:placeholder="$t('inventory_modal.search')"
								class="list-action_labeled-input"
							>
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M8.16438 8.87146C7.30244 9.57681 6.20064 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 6.20063 9.57682 7.30242 8.87149 8.16435L11.8536 11.1465L11.1465 11.8536L8.16438 8.87146ZM9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z" fill="#919191"/>
								</svg>
							</nice-input>
						</div>
						<div class="list-actions__right">
							<!-- :label="$t('inventory_modal.select_devices_subset')" -->
							<nice-select
								v-model="listSubsetType"
								labeled
								:options="subsetSelectOptions"
								class="list-action_labeled-input"
							/>
							<!-- <nice-button-2
								filled
								:disabled="isBusy"
								palette="blue"
								class="list-action_button"
								@click="refresh"
							><span class="visually-hidden">{{ $t('inventory_modal.refresh') }}</span></nice-button-2> -->
						</div>
					</div>

					<div ref="tableWrapper" class="inventory-table-wrapper">
						<table class="inventory-table nice-table">
							<thead>
								<inventory-table-header-row
									:context="{
										currency: instance.currency,
										payment_model: instance.payment_model,
									}"
									:ordering.sync="ordering"
									:disabled="isBusy"
								/>
							</thead>
							<tbody v-if="deviceList && !error">
								<inventory-table-row
									v-for="device in deviceList"
									:key="device.id"
									:device="device"
									:context="{
										currency: instance.currency,
										payment_model: instance.payment_model,
										request_id: instance.id,
									}"

									@update-required="updateManualFilter"
								/>
							</tbody>
						</table>
						<div
							v-if="error"
							class="inventory-table-message inventory-table-message_error"
							v-html="$t('inventory_modal.error_message', error)"
						/>
						<div
							v-else-if="!isBusy && !deviceList"
							class="inventory-table-message"
							v-html="$t('inventory_modal.no_devices_message')"
						/>
					</div>
				</div>

				<!-- Devices Pagination -->
				<!-- v-model="pagination" -->
				<TablePaginator
					:value="pagination"
					:total="totalDevicesCount"
					:disabled="isBusy"
					:page-size-options="[6, 20, 40]"

					class="inventory-paginator"
					@input="handlePaginationChange"
				/>

			</section>
		</nice-modal>
	</transition>
</template>

<script>
import { mapActions } from 'vuex';
import { debounce } from 'underscore';
import {
	getManualFilter,
	getDeviceList,
	getDeviceBlackWhiteList,
} from '@/api/dsp/manual-inventory-filter';
import { getDevicesFilter } from '@/utilites';

import NiceModal from '@/ui/nice-modal';
import NiceHint from '@/ui/nice-hint';
import NiceSelect from '@/ui/nice-select';
import NiceInput from '@/ui/nice-input';
import DeviceCardListSummary from '@/components/device/card-list-summary';
import TablePaginator from '@/components/table-paginator';

import InventoryTableHeaderRow from './inventory-table-header-row';
import InventoryTableRow from './inventory-table-row';


const ACTIONS = Object.freeze({
	REQUEST_DEVICES_LIST: 'devices_list_request',
	REQUEST_MANUAL_FILTER: 'devices_manual_filter_request',
});

const LIST_SUBSET = Object.freeze({
	PLATFORM: 'platform_devices',
	REQUEST: 'request_devices',
	BLACKLIST: 'blacklisted_devices',
	WHITELIST: 'whitelisted_devices',
});

// in milliseconds
const SEARCH_DELAY = 600;


export default {
	name: 'InventoryDetailsModal',


	components: {
		NiceModal,
		NiceHint,
		DeviceCardListSummary,
		TablePaginator,
		InventoryTableHeaderRow,
		InventoryTableRow,
		NiceSelect,
		NiceInput,
	},


	props: {
		instance: {
			type: Object,
			required: true,
		},

		currentSelection: {
			type: Object,
			required: true,
		},

		showAdditionalValue: {
			type: Boolean,
			default: false,
		},

		impressionsPerDay: {
			type: Number,
			default: 0,
		},

		devicesUptime: {
			type: Object,
			default: () => ({good_uptime: 0, average_uptime: 0, bad_uptime: 0}),
		},
	},


	data() {
		return {
			business: [],
			error: null,

			listSubsetType: LIST_SUBSET.PLATFORM,
			devicesPage: [],
			manualFilter: [],
			search: '',
			pagination: {
				page: 1,
				pageSize: 20,
			},
			totalDevicesCount: 0,
			ordering: {
				orderBy: null,
				descending: false,
			},
		};
	},


	computed: {
		isBusy() {
			return !!this.business.length;
		},

		deviceList() {
			if (!this.devicesPage || !this.devicesPage.length) {
				return null;
			}

			// split manual filter into black- and white- lists
			const { blacklist, whitelist } = this.manualFilter.reduce((lists, record) => {
				switch (record.type) {
				case 'white':
					lists.whitelist.push(record.device);
					break;

				case 'black':
					lists.blacklist.push(record.device);
					break;
				}

				return lists;
			}, { blacklist: [], whitelist: [] });

			// mixin filtering into device
			return this.devicesPage.map(device => {
				let manual_filter_type = null;
				if (blacklist.includes(device.id)) {
					manual_filter_type = 'black';
				} else if (whitelist.includes(device.id)) {
					manual_filter_type = 'white';
				}

				return { ...device, manual_filter_type };
			});
		},


		subsetSelectOptions() {
			return Object.keys(LIST_SUBSET).map(key => ({
				label: this.$t(`inventory_modal.subset_${LIST_SUBSET[key]}`),
				value: LIST_SUBSET[key],
			}));
		},
	},


	methods: {
		...mapActions('devices', ['getStats']),

		/**
		 * Closes the modal
		 */
		close() {
			this.$emit('close');
		},


		/**
		 * adds a business to the business list
		 * @param {string} key - a business key
		 */
		addBusiness(key) {
			this.business.push(key);
		},


		/**
		 * removes a business from the business list
		 * @param {string} key - a business key
		 */
		removeBusiness(key) {
			const index = this.business.indexOf(key);
			if (index >= 0) {
				this.business.splice(index, 1);
			}
		},


		/**
		 * get devices API-request wrapper
		 * @param {object} payload - a payload
		 *
		 * @returns {object} - an axios response
		 */
		async _getDeviceList(payload) {
			let response = null;
			this.addBusiness(ACTIONS.REQUEST_DEVICES_LIST);
			try {
				if (this.listSubsetType == LIST_SUBSET.PLATFORM || this.listSubsetType == LIST_SUBSET.REQUEST){
					response = await getDeviceList(payload);
				} else if (this.listSubsetType == LIST_SUBSET.BLACKLIST || this.listSubsetType == LIST_SUBSET.WHITELIST){
					response = await getDeviceBlackWhiteList(payload);
				}
			} catch (error) {
				const resp = error.response;
				this.$log.error(`Error ${resp && resp.status}. Failed to get a device list.`, resp);
				this.removeBusiness(ACTIONS.REQUEST_DEVICES_LIST);
				this.error = Object.freeze({
					status: resp.status,
					statusText: resp.status,
					data: resp.data,
				});
				throw error;
			}
			this.error = null;
			this.removeBusiness(ACTIONS.REQUEST_DEVICES_LIST);
			return response;
		},


		/**
		 * get manualfiter API-request wrapper
		 * @param {string} type - filtering type - `black` or `white`
		 *
		 * @returns {object} - an axios response
		 */
		async _getManualFilter(type=null) {
			let response = null;
			this.addBusiness(ACTIONS.REQUEST_MANUAL_FILTER);

			try {
				response = await getManualFilter(this.instance.id, type);
			}
			catch (error) {
				const resp = error.response;
				this.$log.error(`Error ${resp && resp.status}. Failed to get a device list.`, resp && resp.data);
				this.removeBusiness(ACTIONS.REQUEST_MANUAL_FILTER);
				throw error;
			}

			this.removeBusiness(ACTIONS.REQUEST_MANUAL_FILTER);
			return response;
		},

		/**
		 * Compiles the payload
		 */
		getDevicesRequestPayload() {
			let payload = {};
			switch (this.listSubsetType) {
			case LIST_SUBSET.REQUEST:
				payload = getDevicesFilter(this.instance);
				payload.campaign_id = this.instance.id;
				break;

			case LIST_SUBSET.BLACKLIST:
				payload.campaign_id = this.instance.id;
				payload.type = 'black';
				break;

			case LIST_SUBSET.WHITELIST:
				payload.campaign_id = this.instance.id;
				payload.type = 'white';
				break;

			// case LIST_SUBSET.PLATFORM:
			}

			payload.page = this.pagination.page;
			payload.page_size = this.pagination.pageSize;
			payload.platforms = this.instance.platforms;

			if (this.ordering.orderBy) {
				const prefix = this.ordering.descending ? '-' : '';
				payload.ordering = prefix + this.ordering.orderBy;
			}

			if (this.search && this.search.trim()) {
				payload.search = this.search.trim();
			}

			return payload;
		},


		/**
		 * updates the current page of devices list
		 */
		async updateDevices() {
			let loading = this.$loading.show();

			const payload = this.getDevicesRequestPayload();
			const devices = await this._getDeviceList(payload);
			this.$set(this, 'devicesPage', Object.freeze(devices.results));
			this.totalDevicesCount = devices.count;
			try	{
				this.$refs.tableWrapper.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			} catch (error) {
				this.$log.error(error);
			}

			loading.hide();
		},


		/**
		 * updates manual filter
		 */
		async updateManualFilter() {
			const manualFilter = await this._getManualFilter();
			this.$set(this, 'manualFilter', Object.freeze(manualFilter));
			this.$emit('update-required');
		},

		exportExcel() {
			const ancor = document.createElement('a');
			ancor.href = this.currentSelection.inventory.excel_url;
			ancor.setAttribute('download', '');
			ancor.className = 'visuallyhidden';
			ancor.tabIndex = -1;
			document.body.appendChild(ancor);
			ancor.click();
			document.body.removeChild(ancor);
		},

		async refresh() {
			await Promise.all([
				this.updateDevices(),
				this.updateManualFilter(),
			]);
		},


		handlePaginationChange({ page=1, pageSize=20 }={}) {
			this.$set(this, 'pagination', { page, pageSize });
			this.updateDevices();
		},
	},


	watch: {
		ordering: {
			deep: true,
			handler() {
				this.$set(this.pagination, 'page', 1);
				this.updateDevices();
			}
		},

		search: debounce(function(value) {
			this.$set(this.pagination, 'page', 1);
			this.updateDevices();
		}, SEARCH_DELAY),

		listSubsetType() {
			this.$set(this.pagination, 'page', 1);
			this.updateDevices();
		},
	},


	async created() {
		if (this.currentSelection.inventory.value > 0) {
			this.listSubsetType = LIST_SUBSET.REQUEST;
		}
		await this.refresh();
	},
};
</script>



<style lang="sass" scoped>
.inventory-details
	display: flex
	flex-direction: column
	padding: 30px 40px
	box-sizing: border-box
	flex-grow: 1
	min-height: 675px
	max-width: 1320px
	width: 100%
	max-height: calc(100vh - 160px)

.header
	display: flex
	align-items: center
	margin-bottom: 30px

	& > *
		margin-left: 15px

		&:first-child
			margin-left: 0


.header__icon
	--ni-icon-sign: var(--main_color)
	width: 34px
	height: 34px
	flex-shrink: 0

.header__title
	font-size: 16px
	line-height: 20px
	// margin: calc((34px - 20px) / 2) 0
	font-weight: normal

.header__actions
	display: flex
	align-items: center
	justify-content: flex-end
	margin-left: auto

	& > *
		margin-left: 15px

.summary-section
	display: flex
	width: auto
	justify-content: space-between
	margin: 0 0 30px 0

	& ::v-deep
		.hr
			height: 100%
			width: 0
			border: none
			border-left: 1px solid $nice_color-gray_light
			margin: 0 20px

		.summary-stats__section
			flex-grow: 1
			flex-basis: 215px


.table-section
	display: flex
	flex-direction: column
	flex-grow: 1
	overflow: hidden

.inventory-table-wrapper
	display: flex
	flex-direction: column
	height: auto
	flex-grow: 1
	margin-bottom: auto
	flex-basis: 100%
	overflow: auto

.inventory-table
	width: 100%
	border-collapse: collapse
	position: relative
	z-index: 0

	& ::v-deep th
		position: sticky
		top: 0
		z-index: 1
		padding-top: 10px
		padding-bottom: 10px
		background-color: white

.inventory-table-message
	margin: auto
	text-align: center
	// font-size: 16px

.inventory-paginator
	flex-grow: 0
	flex-basis: auto
	margin: 0

.list-actions
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: 20px

.list-action_labeled-input
	// margin-top: -10px
	width: 215px

.list-action_button
	margin-top: 22px

.list-actions__left,
.list-actions__right
	display: flex
	align-items: center

	& > *
		margin-left: 15px

		&:first-child
			margin-left: 0
</style>
