<template>
	<main class="page-auth">
		<section class="auth-container cl-pad">
			<form
				v-if="!token"
				class="auth-form cl-container"
				@submit.prevent="login_request"
			>
				<h2 class="cl-pad cl-row">Authorization</h2>
				<nice-input
					id="email-auth-field"
					v-model="email"
					class="cl-pad cl-xs12"
					type="text"
					placeholder="Type email"
					label="Email"
				/>

				<nice-input
					id="password-auth-field"
					v-model="password"
					class="cl-pad cl-xs12"
					type="password"
					placeholder="Type password"
					label="Password"

					autocomplete="current-password"
				/>

				<div class="auth-form-btn-row cl-container cl-pad cl-row">
					<button type="submit" :disabled="!email || !password" @click="login_request" >Войти</button>
				</div>
			</form>

			<template v-if="token" >
				<section class="cl-pad" >
					<h2 class="cl-row">User:</h2>
					<span class="auth-name">{{ profile.username }}</span>
					<span class="auth-id">#{{ profile.id }}</span>
					<span
						class="auth-name-tag super"
						:class="{ 'active': profile.is_super, }"
					>SUPER</span>
					<br />
					<span class="auth-email">({{ profile.email }})</span>
				</section>

				<section class="cl-pad" >
					<h2 class="cl-row">Agency:</h2>
					<span class="auth-name">
						{{ agency.name }}<span class="auth-id">#{{ agency.id }}</span>
					</span>
				</section>

				<section class="cl-pad" >
					<h2 class="cl-row">Platform:</h2>
					<span class="auth-param" >Side: {{ side }}</span><br />
				</section>

				<section class="cl-pad">
					<span>Token:</span>
					<pre class="auth-token cl-row">{{ token }}</pre>
					<span>Refresh-token:</span>
					<pre class="auth-token cl-row">{{ refresh }}</pre>
				</section>
			</template>
		</section>

	</main>
</template>

<script>
import NiceInput from '@/ui/nice-input.vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
	name: 'pageDevUserInfo',

	// data
	data() {
		return {
			email: '',
			password: '',
		};
	},

	// components
	components: {
		NiceInput,
	},

	// computed
	computed: {
		...mapState('profile', ['token', 'refresh']),
		...mapGetters('profile', ['profile']),
		...mapGetters('agency', ['agency']),
		...mapState('app', ['side']),
	},

	// methods
	methods: {
		...mapActions('profile', ['login']),

		login_request() {
			this.login({
				email: this.email,
				password: this.password,
			});
		}
	}
};
</script>

<style lang="sass" scoped>
main.page-auth
	display: flex
	align-items: center
	justify-content: center
	background-color: #f5f5f5
	min-height: 100%

.auth-container
	margin: 0 auto
	max-width: 400px

	> section
		&:not(:first-child)
			margin-top: 1.25em

.auth-name,
.auth-id
	display: inline-block
	font-size: 24px
	font-weight: 300

.auth-id
	color: $clr__gray

.auth-name-tag
	margin-left: 1.5px
	display: inline-block
	font-size: 11px
	font-weight: normal
	text-transform: uppercase
	border-radius: 3px
	background-color: $clr__darkgray
	padding: 0 3px
	text-decoration: line-through
	color: $clr__lightgray

	&.active
		text-decoration: none
		color: #ffffff

		&.ssp
			background-color: #dafe05

		&.dsp
			background-color: #a5a514

		&.super
			background-color: #fe0d0c

.auth-param,
.auth-email
	color: $clr__gray
	font-style: italic

pre.auth-token
	display: block
	border-radius: 8.5px
	padding: 8.5px
	background-color: $clr__lightgray
	white-space: normal
	word-break: break-all





</style>
