<template>
	<symbol :id="`${prefix}plus-20`" viewBox="0 0 20 20">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9 9V6H11V9H14V11H11V14H9V11H6V9H9Z" class="sign" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlus20',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark_semi})
</style>
