import Vue from 'vue';
import Router from 'vue-router';
// TODO: we need something like that
// import { createMatcher } from 'vue-router/src/create-matcher.js';

import store from '@/store';
import { userAccessToRoute } from '@/utilites';
import {
	ROLE_SUPER,
	ROLE_SSP_ADMIN,
	ROLE_SSP_APPROVE,
	ROLE_SSP_VIEWER,
	ROLE_DSP,
} from '@/constants/roles';


Vue.use(Router);


/**
 * async component load helper
 */
function loadPageComponent(pageName) {
	return () => import(
		/* webpackMode: "eager" */
		`@/pages/${pageName}`
	);
}


/**
 * Route meta data:
 * * access - необходимый уровень доступа, массив. возможные значения: all, guest, ssp, dsp
 * * in_menu - выводить ли пункт в меню, default: false
 * * dev - поставить пометку dev в меню, default: false
 * * caption - название пункта меню, при отсутствии выводится name роута
 * * hideNavbar - скрытие навигационной панели
 */

// Base routes - guest, all, dsp, ssp
export const baseRoutes = [
	{
		name: 'index',
		path: '/',
		component: loadPageComponent('index'),
		meta: {
			in_menu: true,
			menu_icon: 'dashboard',
			access: ['ssp', 'dsp'],
			caption: 'Dashboard',
			roles: ['all'],
		},
	},

	{
		name: 'login',
		path: '/login',
		component: loadPageComponent('login'),
		meta: {
			access: ['guest', 'all'],
			caption: 'Login',
			roles: ['all'],
		},
	},

	{
		name: 'registration',
		path: '/registration',
		component: loadPageComponent('registration'),
		meta: {
			access: ['guest', 'all'],
			caption: 'Registration',
			roles: ['all'],
		},
	},

	{
		name: 'reset-password',
		path: '/reset-password',
		component: loadPageComponent('reset-password'),
		meta: {
			access: ['guest', 'all'],
			caption: 'ResetPassword',
			roles: ['all'],
		},
	},

	{
		name: 'change-password',
		path: '/change-password/:urlToken',
		component: loadPageComponent('change-password'),
		props: (route) => ({
			urlToken: route.params.urlToken
		}),
		meta: {
			access: ['guest', 'all'],
			caption: 'ChangePassword',
			roles: ['all'],
		},
	},

	{
		name: 'choose-agency',
		path: '/choose-agency',
		component: loadPageComponent('choose-agency'),
		meta: {
			access: ['guest', 'all'],
			caption: 'ChooseAgency',
			roles: ['all'],
		},
	},


	// Profile pages

	{
		name: 'invitation',
		path: '/invitation',
		component: loadPageComponent('invitation'),
		meta: {
			access: ['all'],
			caption: 'Account creation',
			roles: ['all'],
		},
	},

	{
		name: 'profile',
		path: '/me',
		component: loadPageComponent('user/details'),
		props: route => ({
			key: 'profile',

			id: store.state.profile.id,
			resource: 'user',
			side: store.state.app.side,
			viewType: 'profile',
		}),
		meta: {
			access: ['dsp', 'ssp'],
			caption: 'Profile',
			roles: ['all'],
		},
	},

	{
		name: 'agency-profile',
		path: '/my-agency',
		component: loadPageComponent('agency/details'),
		props: route => ({
			id: store.state.agency.id,
			side: store.state.app.side,
			resource: 'agency',
		}),
		meta: {
			access: ['dsp', 'ssp'],
			caption: 'Agency profile',
			roles: ['all'],
		},
	},

	// Documents
	// {
	// 	name: 'document-list',
	// 	path: '/documents',
	// 	component: loadPageComponent('document/list'),
	// 	meta: {
	// 		in_menu: true,
	// 		menu_icon: 'documents',
	// 		access: ['ssp', 'dsp'],
	// 		caption: 'Docs',
	// 	},
	// 	children: [
	// 		{
	// 			name: 'document-list/document-add',
	// 			path: 'add',
	// 			// component: () => import( /* webpackChunkName: "[request]" */ '@/pages/add-document'),
	// 			component: loadPageComponent('error/404-not-found'),
	// 			meta: {
	// 				parentRouteName: 'document-list',
	// 				access: ['ssp'],
	// 				caption: 'Add document',
	// 			},
	// 		},
	// 		{
	// 			name: 'document-list/document-edit',
	// 			path: 'edit',
	// 			// component: () => import( /* webpackChunkName: "[request]" */ '@/pages/add-document'),
	// 			component: loadPageComponent('error/404-not-found'),
	// 			meta: {
	// 				parentRouteName: 'document-list',
	// 				access: ['ssp'],
	// 				caption: 'Edit document',
	// 			},
	// 		},
	// 	],
	// },

	// {
	// 	name: 'statistics',
	// 	path: '/statistics',
	// 	component: loadPageComponent('error/404-not-found'),
	// 	meta: {
	// 		disabled: true,
	// 		access: ['dsp'],
	// 		caption: 'Stat',
	// 	},
	// },
	//
	// {
	// 	name: 'creative-list',
	// 	path: '/creatives',
	// 	component: loadPageComponent('error/404-not-found'),
	// 	meta: {
	// 		disabled: true,
	// 		access: ['ssp', 'dsp'],
	// 		caption: 'Creatives',
	// 	},
	// },
];


export const allRoutes = [
	//
	// Advertisers
	//

	{
		name: 'advertiser-list',
		path: '/advertisers',
		component: loadPageComponent('advertiser/list'),
		meta: {
			in_menu: true,
			menu_icon: 'person',
			access: ['ssp', 'dsp'],
			caption: 'Advertisers',
			roles: [ROLE_SUPER, ROLE_SSP_ADMIN, ROLE_SSP_APPROVE, /*ROLE_SSP_VIEWER,*/ ROLE_DSP],
		},
		children: [
			{
				name: 'advertiser-add',
				path: 'add',
				component: loadPageComponent('advertiser/add'),
				// TODO: do we check `access` and `roles` here?
				meta: {
					access: ['dsp'],
					caption: 'Add Advertiser',
					hideNavbar: false,
					parentRouteName: 'advertiser-list',
					roles: [ROLE_DSP],
				},
			},
			{
				name: 'advertiser-edit',
				path: 'edit/:advertiserId',
				component: loadPageComponent('advertiser/add'),
				props: (route) => ({
					key: `advertiser-edit-${route.params.advertiserId}`,
					advertiserId: ~~route.params.advertiserId,
				}),
				// TODO: do we check `access` and `roles` here?
				meta: {
					access: ['ssp'],
					caption: 'Edit Advertiser',
					hideNavbar: false,
					parentRouteName: 'advertiser-list',
					roles: [ROLE_SUPER, ROLE_SSP_ADMIN, ROLE_SSP_APPROVE],
				},
			},
		],
	},


	//
	// Request
	//

	{
		name: 'request-list',
		path: '/requests',
		component: loadPageComponent('request/list'),
		meta: {
			in_menu: true,
			menu_icon: 'requests',
			access: ['ssp', 'dsp'],
			caption: 'Requests',
			roles: [ROLE_SUPER, ROLE_SSP_ADMIN, ROLE_SSP_APPROVE, ROLE_DSP],
		},
	},

	{
		name: 'request-details',
		path: '/request/:id',
		component: loadPageComponent('request/details'),
		props: (route) => ({
			key: `request-details-${route.params.id}`,

			id: ~~route.params.id,
			resource: 'request',
		}),
		meta: {
			access: ['ssp', 'dsp'],
			caption: 'Request details',
			hideNavbar: true,
			parentRouteName: 'request-list',
			roles: [ROLE_SUPER, ROLE_SSP_ADMIN, ROLE_SSP_APPROVE, ROLE_DSP],
		},
	},

	//
	// Campaign
	//

	{
		name: 'campaign-list',
		path: '/campaigns',
		component: loadPageComponent('campaign/list'),
		meta: {
			in_menu: true,
			menu_icon: 'campaigns',
			access: ['ssp', 'dsp'],
			caption: 'Campaigns',
			roles: ['all'],
		},
	},

	{
		name: 'campaign-details',
		path: '/campaign/:id',
		component: loadPageComponent('request/details'),
		props: (route) => ({
			key: `campaign-details-${route.params.id}`,

			id: ~~route.params.id,
			resource: 'campaign',
		}),
		meta: {
			access: ['ssp', 'dsp'],
			caption: 'Campaign details',
			hideNavbar: true,
			parentRouteName: 'campaign-list',
			roles: ['all'],
		},
	},

	{
		name: 'network',
		path: '/network',
		component: loadPageComponent('network'),
		meta: {
			in_menu: true,
			menu_icon: 'network',
			access: ['ssp'],
			caption: 'Network',
			roles: [ROLE_SSP_ADMIN],
		},
	},


	// User
	// TODO: this is done differently on the backend + it could be managed from the django admin for now
	// {
	// 	name: 'user-list',
	// 	path: '/users',
	// 	component: loadPageComponent('user/list'),
	// 	meta: {
	// 		in_menu: true,
	// 		menu_icon: users',
	// 		access: ['ssp'],
	// 		caption: 'Users',
	// 	},
	// },

	// only for users of child agencies
	{
		name: 'user-details',
		path: '/agency/:agencyId/user/:id',
		component: loadPageComponent('user/details'),
		props: (route) => ({
			key: `user-details-${route.params.id}`,

			id: ~~route.params.id,
			agencyId: ~~route.params.agencyId,
			resource: 'user',
			side: 'dsp',
			viewType: 'child-agency-user',
		}),
		meta: {
			access: ['ssp'],
			caption: 'User Details',
			hideNavbar: false,
			// TODO: profile?
			parentRouteName: 'profile',
			roles: [ROLE_SSP_ADMIN],
		},
	},

	// ssp viewer user setup
	{
		name: 'user-ssp-viewer-details',
		path: '/agency/:agencyId/user/:id/ssp-viewer',
		component: loadPageComponent('user/ssp-viewer-details'),
		props: (route) => ({
			key: `user-ssp-viewer-details-${route.params.id}`,

			id: ~~route.params.id,
			agencyId: ~~route.params.agencyId,
		}),
		meta: {
			access: ['ssp'],
			caption: 'SSP Viewer Details',
			hideNavbar: false,
			parentRouteName: 'profile',
			roles: [ROLE_SSP_ADMIN],
		},
		children: [
			{
				name: 'user-ssp-viewer-network-devices',
				path: 'network-devices/:networkId',
				component: loadPageComponent('user/ssp-viewer-network-devices'),
				props: (route) => ({
					key: `user-ssp-viewer-network-devices-${route.params.id}-${route.params.networkId}`,

					id: ~~route.params.id,
					agencyId: ~~route.params.agencyId,
					networkId: ~~route.params.networkId,
				}),
				// TODO: do we check `access` and `roles` here?
				meta: {
					access: ['ssp'],
					caption: 'SSP Viewer Network Devices',
					hideNavbar: false,
					parentRouteName: 'profile',
					roles: [ROLE_SSP_ADMIN],
				},
			},
		],
	},

	// Agency
	{
		name: 'agency-list',
		path: '/agencies',
		component: loadPageComponent('agency/list'),
		meta: {
			in_menu: true,
			menu_icon: 'person',
			access: ['ssp'],
			caption: 'Agencies',
			roles: [ROLE_SSP_ADMIN],
		},
	},

	{
		name: 'agency-create',
		path: '/agency/create',
		props: (route) => ({
			key: 'agency-create',
			side: 'dsp',
			resource: 'agency',
			isSspDspEditPage: true,
		}),
		component: loadPageComponent('agency/details'),
		meta: {
			access: ['ssp'],
			caption: 'New Agency',
			hideNavbar: false,
			parentRouteName: 'agency-list',
			roles: [ROLE_SSP_ADMIN],
		},
	},

	{
		name: 'agency-details',
		path: '/agency/:id',
		props: (route) => ({
			key: 'agency-details',
			id: ~~route.params.id,
			side: 'dsp',
			resource: 'agency',
			isSspDspEditPage: true,
		}),
		component: loadPageComponent('agency/details'),
		meta: {
			access: ['ssp'],
			caption: 'Agency details',
			hideNavbar: false,
			parentRouteName: 'agency-list',
			roles: [ROLE_SSP_ADMIN],
		},
	},

	{
		name: 'whitelabel-settings',
		path: '/whitelabel-settings',
		component: loadPageComponent('agency/whitelabel-settings'),
		meta: {
			in_menu: true,
			menu_icon: 'gear',
			access: ['ssp'],
			caption: 'White-label Settings',
			roles: [ROLE_SSP_ADMIN],
		},
	},

	//
	// Billing
	//

	{
		name: 'billing',
		path: '/billing',
		component: loadPageComponent('billing/index'),
		meta: {
			in_menu: true,
			menu_icon: 'money',
			access: ['ssp', 'dsp'],
			caption: 'Billing',
			roles: [ROLE_SUPER, ROLE_SSP_ADMIN, ROLE_SSP_VIEWER, ROLE_DSP],
		},
	},

	{
		name: 'balance-add',
		path: '/balance-add',
		component: loadPageComponent('billing/balance-add'),
		meta: {
			access: ['dsp'],
			caption: 'Add balance',
			hideNavbar: false,
			parentRouteName: 'billing',
			roles: [ROLE_DSP],
		},
	},

	{
		name: 'balance-add-success',
		path: '/billing/success',
		component: loadPageComponent('billing/success'),
		meta: {
			access: ['dsp'],
			caption: 'Success',
			hideNavbar: false,
			parentRouteName: 'billing',
			roles: [ROLE_DSP],
		},
	},

	{
		name: 'balance-add-fail',
		path: '/billing/fail',
		component: loadPageComponent('billing/fail'),
		meta: {
			access: ['dsp'],
			caption: 'Success',
			hideNavbar: false,
			parentRouteName: 'billing',
			roles: [ROLE_DSP],
		},
	},

	//
	// DSP ROUTES
	//

	{
		name: 'request-add',
		path: '/request-add',
		component: loadPageComponent('request/create'),
		meta: {
			access: ['dsp'],
			caption: 'Add request',
			hideNavbar: true,
			parentRouteName: 'request-list',
			roles: [ROLE_DSP],
		},
	},
];


// Dev routes
export const devRoutes = [
	// {
	// 	name: 'auth',
	// 	path: '/auth',
	// 	component: loadPageComponent('dev/user-info'),
	// 	meta: {
	// 		in_menu: true,
	// 		menu_icon: 'person',
	// 		dev: true,
	// 		access: ['all'],
	// 		caption: 'Auth 🔐',
	// 		roles: ['all'],
	// 	},
	// },
	//
	// {
	// 	name: 'error-404',
	// 	path: '/four-zero-four',
	// 	component: loadPageComponent('error/404-not-found'),
	// 	meta: {
	// 		in_menu: true,
	// 		menu_icon: 'warn',
	// 		dev: true,
	// 		caption: '404 🤷🏻‍♂️',
	// 		access: ['all'],
	// 		roles: ['all'],
	// 	},
	// },
	//
	// {
	// 	name: 'error-500',
	// 	path: '/five-zero-zero',
	// 	component: loadPageComponent('error/500-internal-server-error'),
	// 	meta: {
	// 		in_menu: true,
	// 		menu_icon: 'error',
	// 		dev: true,
	// 		caption: '500 🙀',
	// 		access: ['all'],
	// 		roles: ['all'],
	// 	},
	// },

	{
		name: 'dev',
		path: '/dev',
		component: loadPageComponent('dev/docs'),
		meta: {
			in_menu: true,
			menu_icon: 'dev',
			dev: true,
			access: ['all'],
			roles: ['all'],
			caption: 'Dev 🛠',
		},
	},
];


/**
 * This should give reasonable 404 pages
 */
export function setBaseAppRoutes(router) {
	let routes = [ ...baseRoutes ];

	routes = routes.concat([
		{
			name: 'page404',
			path: '*',
			component: loadPageComponent('error/404-not-found'),
			meta: {
				in_menu: false,
				access: ['guest', 'all'],
				roles: ['all'],
			},
		},
	]);

	router.addRoutes(routes);

	// update menu
	// TODO: store is undefined oO
	// store.commit('menu/setPages', router.getRoutes());

	router.__addedAppSideRoutes = false;
}


/**
 * We need to set routes based on a role
 *
 * TODO: is there a better way?
 */
export function addRoleRoutes(router, role) {
	if (!role) {
		return;
	}

	let routes = [];

	routes = allRoutes.filter(value => {
		const roles = value.meta.roles;
		return roles.includes(role) || roles.includes('all');
	});
	// fix old page404
	// there will be warning but it works!
	let old404page = router.getRoutes().find(r => r.name == 'page404');
	if (old404page) {
		old404page.name = 'fake-page404';
		old404page.path = '/fake-page404';
	}

	// let dev be the last in the list
	if (process.env.NODE_ENV === 'development') {
		routes = routes.concat(devRoutes);
	}

	routes = routes.concat([
		{
			name: 'page404',
			path: '*',
			component: loadPageComponent('error/404-not-found'),
			meta: {
				in_menu: false,
				access: ['guest', 'all'],
			},
		},
	]);

	router.addRoutes(routes);

	// update menu
	store.commit('menu/setPages', {
		routes: router.getRoutes(),
		currentRole: role,
	});

	router.__addedAppSideRoutes = true;
}


// Router instance
const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [],
});

// only base routes. we add more in the `profile.getProfile()`
setBaseAppRoutes(router);


// Check authorization before each route enter
router.beforeEach((to, from, next) => {
	// Check authentication here
	const authenticated = store.state.profile.token;

	// SSP or DSP?
	// const appSide = store.state.app.side;
	// if not authenticated and no guest access - go to the login page
	if ( !authenticated && to.matched.some(record => !userAccessToRoute('guest', record)) ) {
		next({ name: 'login', query: { next: to.fullPath } });
	}

	else if ( authenticated && to.name != 'choose-agency' && !store.state.app.agency ) {
		next({ name: 'choose-agency', query: { next: to.fullPath } });
	}
	// if authenticated but not authorized - show 404
	// TODO: new hacks (dynamic routes) shouldn't contain such routes!
	// TODO: it generates cyclic redirects!!! why?
	// else if (authenticated && to.matched.some(record => !userAccessToRoute(appSide, record))) {
	// 	next('/');
	// }
	// if authenticated and trying to reach login page - redirect
	else if (authenticated && (to.name === 'login' || to.name === 'registration')) {
		let result = to.query && to.query.next ? { path: to.query.next } : { name: 'index' };
		next(result);
	}
	else {
		next();
	}
});

export default router;
