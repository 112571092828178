<template>
	<div class="time">

		<div>
			<nice-label
				:label="$t('campaign.start_time')"
			/>
			<br>
			<input
				v-imask="mask"
				:value="startTime"
				class="time-input"

				@accept="onAccept($event, 'startTime')"
				@complete="onComplete"
			/>
		</div>

		<span class="separator">&mdash;</span>

		<div>
			<nice-label
				:label="$t('campaign.end_time')"
			/>
			<br>
			<input
				v-imask="mask"
				:value="endTime"
				class="time-input"

				@accept="onAccept($event, 'endTime')"
				@complete="onComplete"
			>
		</div>

	</div>
</template>

<script>
import IMask from 'imask';
import moment from 'moment';
import _ from 'underscore';

const humanize = (raw, format='HH:mm') => moment.utc(raw*1000).format(format);

const MODEL = Object.freeze({
	prop: 'value',
	event: 'input',
});

export default {
	name: 'TimeRange',

	model: MODEL,

	props: {
		[MODEL.prop]: {
			type: Array,
			default: null,  // () => [0, 0],
		},
	},

	data() {
		const [start, end] = this[MODEL.prop];
		return {
			mask: {
				mask: 'HH{:}`mm',
				lazy: false,

				blocks: {
					HH: {
						mask: IMask.MaskedRange,
						from: 0,
						to: 23
					},
					mm: {
						mask: IMask.MaskedRange,
						from: 0,
						to: 59
					}
				}
			},

			startTime: humanize(start),
			endTime: humanize(end),

			innerStartTime: start,
			innerEndTime: end,
		};
	},

	computed: {
		timeRange() {
			return [this.startTime, this.endTime];
		},
	},

	watch: {
		[MODEL.prop]: {
			handler(newValue, oldValue) {
				if (!_.isEqual(newValue, oldValue)) {
					this.startTime = humanize(newValue[0]);
					this.endTime = humanize(newValue[1]);
				}
			},
		},
	},

	methods: {
		onAccept(e, time) {
			const maskRef = e.detail;
			this.$set(this, time, maskRef.value);
		},

		onComplete (e) {
			this.handleTimeRange(); //save only when value is ready
		},

		handleTimeRange() {
			this.innerStartTime = moment.duration(this.startTime).asSeconds();
			this.innerEndTime = moment.duration(this.endTime).asSeconds();

			if (this.innerStartTime > this.innerEndTime) {
				this.$set(this, 'startTime', this.endTime);
				this.innerEndTime = this.innerStartTime;
			}

			this.$emit(MODEL.event, [this.innerStartTime, this.innerEndTime]);
		},
	},

};
</script>

<style scoped lang="sass">
.time
	display: flex
	flex-direction: row
	width: 325px

	align-items: center
	justify-content: space-between

.separator
	margin: 24px 5px 0px

.time-input
	--default-color: var(--text_2_color)

	flex-grow: 1
	height: $ni-input-height
	padding: 0

	border: none
	color: var(--text-color)
	font-size: $fsz__new__normal
	line-height: $txt__line_height
	overflow: hidden
	appearance: textfield

	border-bottom: 1px solid var(--default-color)

	&::placeholder
		color: var(--text_2_color)

	&:disabled
		background-color: transparent

</style>
