<template>
	<symbol :id="`${prefix}search`" viewBox="0 0 12 12">
		<circle
			cx="5"
			cy="5"
			r="4.5"
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
		/>
		<polyline
			points="8.5 8.5 11.5 11.5"
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconEdit',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.semidarkgray,
				main__disabled: this.$palette.semidarkgray__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
