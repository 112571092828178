<template>
	<section class="layout-full_width" >
		<h1 class="title-error" >Deprecated!</h1>
		<p>use <b><i>nice-icon-2</i></b></p>

		<h1>NiceIcon</h1>
		<p><nice-icon icon="chbx-sqr" state="unchecked" /> <code class="code-preview" >&lt;nice-icon icon="chbx-sqr" state="unchecked" /&gt;</code></p>
		<p><nice-icon icon="chbx-sqr" state="mixed" /> <code class="code-preview" >&lt;nice-icon icon="chbx-sqr" state="mixed" /&gt;</code></p>
		<p><nice-icon icon="chbx-sqr" state="checked" /> <code class="code-preview" >&lt;nice-icon icon="chbx-sqr" state="checked" /&gt;</code></p>

		<br/>
		<p><nice-icon icon="chbx-rnd" state="unchecked" /> <code class="code-preview" >&lt;nice-icon icon="chbx-rnd" state="unchecked" /&gt;</code></p>
		<p><nice-icon icon="chbx-rnd" state="checked" /> <code class="code-preview" >&lt;nice-icon icon="chbx-rnd" state="checked" /&gt;</code></p>

		<br/>
		<p><nice-icon icon="chbx-circle" state="unchecked" /> <code class="code-preview" >&lt;nice-icon icon="chbx-circle" state="unchecked" /&gt;</code></p>
		<p><nice-icon icon="chbx-circle" state="checked" /> <code class="code-preview" >&lt;nice-icon icon="chbx-circle" state="checked" /&gt;</code></p>
		<p><nice-icon icon="chbx-circle" state="unchecked" disabled /> <code class="code-preview" >&lt;nice-icon icon="chbx-circle" state="unchecked" disabled /&gt;</code></p>
		<p><nice-icon icon="chbx-circle" state="checked" disabled /> <code class="code-preview" >&lt;nice-icon icon="chbx-circle" state="checked" disabled /&gt;</code></p>

		<br/>
		<p><nice-icon icon="switch" state="off" /> <code class="code-preview" >&lt;nice-icon icon="switch" state="off" /&gt;</code></p>
		<p><nice-icon icon="switch" state="on" /> <code class="code-preview" >&lt;nice-icon icon="switch" state="on" /&gt;</code></p>

		<br/>
		<p><nice-icon icon="availability-busy" /> <code class="code-preview" >&lt;nice-icon icon="availability-busy" /&gt;</code></p>
		<p><nice-icon icon="availability-busy" :palette="{sign:'#ffd200'}" /> <code class="code-preview" >&lt;nice-icon icon="availability-busy" :palette="{sign:'#ffd200'}" /&gt;</code></p>
		<p><nice-icon icon="busy-medium" /> <code class="code-preview" >&lt;nice-icon icon="busy-medium" /&gt;</code></p>
		<p><nice-icon icon="availability-medium" /> <code class="code-preview" >&lt;nice-icon icon="availability-medium" /&gt;</code></p>
		<p><nice-icon icon="availability-free" /> <code class="code-preview" >&lt;nice-icon icon="availability-free" /&gt;</code></p>
		<p><nice-icon icon="published" /> <code class="code-preview" >&lt;nice-icon icon="published" /&gt;</code></p>
		<p><nice-icon icon="accepted" /> <code class="code-preview" >&lt;nice-icon icon="accepted" /&gt;</code></p>
		<p><nice-icon icon="cancel" /> <code class="code-preview" >&lt;nice-icon icon="cancel" /&gt;</code></p>
		<p><nice-icon icon="delete" /> <code class="code-preview" >&lt;nice-icon icon="delete" /&gt;</code></p>
		<p><nice-icon icon="rejected" /> <code class="code-preview" >&lt;nice-icon icon="rejected" /&gt;</code></p>

		<p><nice-icon icon="pause" /> <code class="code-preview" >&lt;nice-icon icon="pause" /&gt;</code></p>
		<p><nice-icon icon="unpublished" /> <code class="code-preview" >&lt;nice-icon icon="unpublished" /&gt;</code></p>
		<p><nice-icon icon="play" /> <code class="code-preview" >&lt;nice-icon icon="play" /&gt;</code></p>
		<p><nice-icon icon="playing" /> <code class="code-preview" >&lt;nice-icon icon="playing" /&gt;</code></p>
		<p>Монотонные иконки, в палитре только один цвет `main`</p>
		<p><nice-icon icon="sketch" /> <code class="code-preview" >&lt;nice-icon icon="sketch" /&gt;</code></p>
		<p><nice-icon icon="draft" /> <code class="code-preview" >&lt;nice-icon icon="draft" /&gt;</code></p>

		<br/>
		<p><nice-icon icon="diagram" state=33 /> <code class="code-preview" >&lt;nice-icon icon="diagram" state=33 /&gt;</code></p>
		<p><nice-icon icon="diagram" state=50 /> <code class="code-preview" >&lt;nice-icon icon="diagram" state=50 /&gt;</code></p>
		<p><nice-icon icon="diagram" state=87 /> <code class="code-preview" >&lt;nice-icon icon="diagram" state=87 /&gt;</code></p>

		<br/>
		<p>Монотонные иконки, в палитре только один цвет `main`</p>
		<p><nice-icon icon="monitor" /> <code class="code-preview" >&lt;nice-icon icon="monitor" /&gt;</code></p>
		<p><nice-icon icon="edit" /> <code class="code-preview" >&lt;nice-icon icon="edit" /&gt;</code></p>
		<p>
			<nice-icon icon="add" />
			<code class="code-preview" >&lt;nice-icon icon="add" /&gt;</code>
			используется в попапах
		</p>
		<p>
			<nice-icon icon="add" :params="{strokeWidth: 1}" />
			<code class="code-preview" >&lt;nice-icon icon="add" :params="{strokeWidth: 1}" /&gt;</code>
			используется в шабках таблиц
		</p>
		<p><nice-icon icon="choice-devices" /> <code class="code-preview" >&lt;nice-icon icon="choice-devices" /&gt;</code></p>
		<p><nice-icon icon="choice-devices" :params="{strokeWidth: 1}" /> <code class="code-preview" >&lt;nice-icon icon="choice-devices" :params="{strokeWidth: 1}" /&gt;</code></p>
		<p><nice-icon icon="choosing" /> <code class="code-preview" >&lt;nice-icon icon="choosing" /&gt;</code></p>
		<p><nice-icon icon="gear" /> <code class="code-preview" >&lt;nice-icon icon="gear" /&gt;</code></p>
		<p><nice-icon icon="chat" /> <code class="code-preview" >&lt;nice-icon icon="chat" /&gt;</code></p>
		<p><nice-icon icon="search" /> <code class="code-preview" >&lt;nice-icon icon="search" /&gt;</code></p>
		<p><nice-icon icon="marker" /> <code class="code-preview" >&lt;nice-icon icon="marker" /&gt;</code></p>
		<p><nice-icon icon="calendar" /> <code class="code-preview" >&lt;nice-icon icon="calendar" /&gt;</code></p>
		<br />
		<p><nice-icon icon="config" /> <code class="code-preview" >&lt;nice-icon icon="config" /&gt;</code></p>
		<p><nice-icon icon="config" :palette="{main:'#ffd200'}" /> <code class="code-preview" >&lt;nice-icon icon="config" :palette="{main:'#ffd200'}" /&gt;</code></p>
		<br />
		<br />
		<p>Монотонные иконки, с основным цветом `main`, и `disabled` для отключённого состояния</p>
		<br />
		<p><nice-icon icon="trash-bold" /> <code class="code-preview" >&lt;nice-icon icon="trash-bold" /&gt;</code></p>
		<p><nice-icon icon="trash" /> <code class="code-preview" >&lt;nice-icon icon="trash" /&gt;</code></p>
		<p><nice-icon icon="trash" disabled /> <code class="code-preview" >&lt;nice-icon icon="trash" disabled /&gt;</code></p>
		<p><nice-icon icon="trash" :palette="{main:'#ffd200'}" /> <code class="code-preview" >&lt;nice-icon icon="trash" :palette="{main:'#ffd200'}" /&gt;</code></p>
		<br />
		<h4>Arrow</h4>
		<p><nice-icon icon="arrow" /> <code class="code-preview" >&lt;nice-icon icon="arrow" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="next" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="next" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="90" disabled /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="90" disabled /&gt;</code></p>
		<p><nice-icon icon="arrow" state="right" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="right" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="back" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="back" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="prev" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="prev" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="45" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="45" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="left" disabled /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="left" disabled /&gt;</code></p>
		<p><nice-icon icon="arrow" state="left" :palette="{ main: this.$palette.semidarkgray }" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="left" :palette="{ main: this.$palette.semidarkgray }" /&gt;</code></p>
		<p><nice-icon icon="arrow" state="left" disabled :palette="{ disabled: this.$palette.semidarkgray__disabled }" /> <code class="code-preview" >&lt;nice-icon icon="arrow" state="left" disabled :palette="{ disabled: this.$palette.semidarkgray__disabled }" /&gt;</code></p>
		<p class="description" >
			State либо должен быть числом (углом поворота) либо одним из следующих значений - 'forward', 'next', 'right', 'bottom', 'down', 'back', 'prev', 'left', 'top', 'up'.<br />
			Палитра из двух цветов - main и disabled
		</p>
		<br/>
		<h4>Arrow Corner</h4>
		<p><nice-icon icon="arrow-corner" /> <code class="code-preview" >&lt;nice-icon icon="arrow-corner" /&gt;</code></p>
		<p><nice-icon icon="arrow-corner" state="back" /> <code class="code-preview" >&lt;nice-icon icon="arrow-corner" state="back" /&gt;</code></p>
		<p><nice-icon icon="arrow-corner" state="90" /> <code class="code-preview" >&lt;nice-icon icon="arrow-corner" state=90 /&gt;</code></p>
		<p class="description" >
			State либо должен быть числом (углом поворота) либо одним из следующих значений - 'forward', 'next', 'right', 'bottom', 'down', 'back', 'prev', 'left', 'top', 'up'.<br />
			Палитра из двух цветов - main и disabled
		</p>
		<br />
		<p><nice-icon icon="x" /> <code class="code-preview" >&lt;nice-icon icon="x" /&gt;</code></p>
		<p><nice-icon icon="cross" :palette="{ main: '#00f' }" /> <code class="code-preview" >&lt;nice-icon icon="cross" :palette="{ main: '#00f' }" /&gt;</code></p>
		<p><nice-icon icon="criss-cross" disabled /> <code class="code-preview" >&lt;nice-icon icon="criss-cross" disabled /&gt;</code></p>
		<br/>


		<p><nice-icon icon="load-min" /> <code class="code-preview" >&lt;nice-icon icon="load-min" /&gt;</code></p>
		<p><nice-icon icon="load" /> <code class="code-preview" >&lt;nice-icon icon="load" /&gt;</code></p>

		<p><nice-icon icon="load" disabled /> <code class="code-preview" >&lt;nice-icon icon="load" disabled /&gt;</code></p>

		<p><nice-icon icon="load" state="download"/> <code class="code-preview" >&lt;nice-icon icon="load" state="download" /&gt;</code></p>

		<p><nice-icon icon="load" state="72"/> <code class="code-preview" >&lt;nice-icon icon="load" state="72" /&gt;</code> kek)</p>

		<p><nice-icon icon="load" state="cancel" :palette="{ main: $palette.red }"/> <code class="code-preview" >&lt;nice-icon icon="load" state="cancel" :palette="{ main: $palette.red }" /&gt;</code></p>
		<br/>


		<p><nice-icon icon="add-min" /> <code class="code-preview" >&lt;nice-icon icon="add-min" /&gt;</code></p>
		<p><nice-icon icon="add-mini" disabled /> <code class="code-preview" >&lt;nice-icon icon="add-mini" disabled /&gt;</code></p>
		<br />
		<p><nice-icon icon="add-sqr" /> <code class="code-preview" >&lt;nice-icon icon="add-sqr" /&gt;</code></p>
		<p><nice-icon icon="add-sqr" /> <code class="code-preview" >&lt;nice-icon icon="add-sqr" /&gt;</code></p>
		<br />
		<p><nice-icon icon="dots" /> <code class="code-preview" >&lt;nice-icon icon="dots" /&gt;</code></p>

		<br/>
		<br/>
		<p><nice-icon icon="bell" /> <code class="code-preview" >&lt;nice-icon icon="bell" /&gt;</code></p>
		<p><nice-icon icon="bell" state=2 /> <code class="code-preview" >&lt;nice-icon icon="bell" state=2 /&gt;</code> - произвольное значение преобразовывающееся в `true`, наверное можно будет в будущем при необходимости передавать количество уведомлений</p>
		<p><nice-icon v-tippy icon="hint" content="hint" /> <code class="code-preview" >&lt;nice-icon icon="hint" title="hint" v-tippy /&gt;</code></p>
	</section>
</template>

<script>
export default {
	name: 'TabIcons',
};
</script>


<style lang="sass" scoped >
.code-preview
	margin-left: 20px

.description
	font-size: 0.6em

.title-error
	color: red
</style>
