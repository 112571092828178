<template>
	<section class="request-review layout-center805">
		<div class="controls layout_block-full_width">
			<nice-button-2
				class="right button"
				palette="gray"
				@click="modalClose"
			>{{ $t('close') | capitalize }}</nice-button-2>
		</div>

		<header v-if="instance" class="header">

			<div class="title-wrapper">
				<h1 class="title">
					<span class="title__name">{{ instance.name }}</span>
				</h1>
				<span class="subtitle">{{ $t('campaign.request_review_subtitle', {
					company: instance.owner_instance ? instance.owner_instance.name : '—',
					manager: instance.created_by ? instance.created_by.username : '—',
				})}}</span>
			</div>

			<div class="audits">

				<div
					v-for="audit in instance.status"
					:key="audit.id"
					class="audit-row"
				>
					<nice-icon-2
						class="title-icon"
						:icon="`${getStatusByCampaign().icon}-34`"
						:style="{
							'--ni-icon-base': $palette[getStatusByCampaign().color],
						}"
					/>

					<h2 class="agency-title">
						<!-- TODO: don't show ssp name for an ssp agency? -->
						<span
							class="title__name"
						>{{ audit.ssp_agency_name }}</span>
						<span
							class="title__status"
							:style="{ color: $palette[getStatusByCampaign().color] }"
						>{{ $t("campaign.status_change_" + getStatus()) }}</span>
					</h2>

					<div class="actions">

						<!-- archive/remove for a request if dsp -->
						<template v-if="isDSP" >
							<nice-button-2
								v-if="audit.status === statusList.STATUS_PAUSED"
								palette="green"
								icon="play-24"
								icon-placement="start"
								:disabled="isPlayDisabled"
								@click="play(audit)"
							>{{ $t('campaign.play') }}</nice-button-2>

							<nice-button-2
								v-if="audit.status === statusList.STATUS_PLAYING"
								palette="purple"
								icon="pause-24"
								icon-placement="start"
								@click="pause(audit)"
							>{{ $t('campaign.pause') }}</nice-button-2>

							<!-- <nice-button-2
								palette="gray"
								icon="basket-24"
								icon-placement="start"
								@click="remove(audit)"
							>{{ $t('remove') }}</nice-button-2> -->

							<nice-button-2
								v-if="audit.status !== statusList.STATUS_ARCHIVED"
								palette="gray"
								icon="arrow_circle-24"
								icon-placement="start"
								@click="archive(audit)"
							>{{ $t('campaign.archive') }}</nice-button-2>
						</template>

						<!-- SSP play/pause/awaiting approveal for a campaign -->
						<template v-else-if="isSSPAdminOrApprover && !instance.is_request">
							<nice-button-2
								v-if="audit.status !== statusList.STATUS_PLAYING"
								palette="green"
								icon="play-24"
								icon-placement="start"
								:disabled="isPlayDisabled"
								@click="play(audit)"
							>{{ $t('campaign.play') }}</nice-button-2>

							<nice-button-2
								v-if="audit.status !== statusList.STATUS_PAUSED"
								palette="purple"
								icon="pause-24"
								icon-placement="start"
								@click="pause(audit)"
							>{{ $t('campaign.pause') }}</nice-button-2>

							<nice-button-2
								v-if="audit.status !== statusList.STATUS_AWAITING_APPROVAL"
								palette="yellow"
								icon="warn-24"
								icon-placement="start"
								@click="setWaitingApproval(audit)"
							>{{ $t('campaign.status_change_1_action') }}</nice-button-2>
						</template>

						<!-- SSP approve/reject for a request -->
						<template v-else-if="isSSPAdminOrApprover && instance.is_request" >
							<nice-button-2
								v-if="audit.status !== statusList.STATUS_APPROVED"
								palette="green"
								icon="check-24"
								icon-placement="start"
								:disabled="isPlayDisabled"
								@click="approve(audit)"
							>{{ $t('campaign.approve') }}</nice-button-2>

							<nice-button-2
								v-if="audit.status !== statusList.STATUS_PRE_APPROVED"
								palette="blue"
								icon="check-24"
								icon-placement="start"
								@click="preApprove(audit)"
							>{{ $t('campaign.status_change_2_action') }}</nice-button-2>

							<nice-button-2
								v-if="audit.status !== statusList.STATUS_REJECTED"
								palette="red"
								icon="error-24"
								icon-placement="start"
								@click="reject(audit)"
							>{{ $t('campaign.reject') }}</nice-button-2>
						</template>
				  </div><!-- END: .actions -->
				</div><!-- END: .audit-row -->
				<div
						v-if="isPlayDisabled"
						class="audit-row message_errors"
				>
					<span v-if="isDSP">
						{{ $t('campaign.insufficient_balance_dsp_view') }}
					</span>
					<span v-else>
						{{ $t('campaign.insufficient_balance_ssp_view') }}
					</span>
				</div>
			</div><!-- END: .audits -->


		</header>

		<nice-tabs
			v-if="instance"
			class="tabs layout_block-full_width"
		>
			<tab-details
				:resource="resource"
				:instance="instance"
				:data-ni_tab_name="$t('campaign.details_tab') | capitalize"
				data-ni_tab_slug="details-tab"
				@update="refreshInstance"
			/>
			<tab-order
				:resource="resource"
				:instance="instance"
				:data-ni_tab_name="$t('campaign.order_tab') | capitalize"
				data-ni_tab_slug="order-tab"
				@update="refreshInstance"
			/>
			<tab-creative
				v-if="stats"
				:resource="resource"
				:instance="instance"
				:stats="stats"
				:data-ni_tab_name="$t('campaign.creative_tab') | capitalize"
				data-ni_tab_slug="creative-tab"
				@update="refreshInstance"
				@pause:campaign="pause(instance.status[0])"
			/>
			<tab-status
				v-if="stats"
				:resource="resource"
				:instance="instance"
				:campaign-stats="stats"
				:data-ni_tab_name="$t('campaign.status_tab') | capitalize"
				data-ni_tab_slug="status-tab"
				@update="refreshInstance"
			/>
			<tab-history
				v-if="!isDSP"
				:instance="instance"
				:status="instance.status[0]"
				:data-ni_tab_name="$t('campaign.history_tab') | capitalize"
				data-ni_tab_slug="history-tab"
				@update="refreshInstance"
			/>
		</nice-tabs>

		<approve-creatives-warning-modal
			v-if="showApproveCreativesWarningModal"
			class="layout_block-full_width"
			@close="showApproveCreativesWarningModal = false"
		/>

	</section>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

import { getDevicesFilter, objectToQuery } from '@/utilites';

import NiceTabs from '@/ui/nice-tabs';
import TabCreative from './tab-creative';
import TabDetails from './tab-details';
import TabOrder from './tab-order';
import TabStatus from './tab-status';
import TabHistory from './tab-history';

import {
	// same base status
	APPROVED as STATUS_APPROVED,  // request
	APPROVED as STATUS_PLAYING,  // campaign
	PENDING_AUDIT_EXTENDED_APPROVED,
	PARTIALLY_APPROVED_EXTENDED_APPROVED,

	PENDING_AUDIT as STATUS_AWAITING_APPROVAL,
	PRE_APPROVED as STATUS_PRE_APPROVED,
	DENIED as STATUS_REJECTED,
	DRAFT as STATUS_DRAFT,
	PAUSED as STATUS_PAUSED,
	FINISHED as STATUS_FINISHED,
	ARCHIVED as STATUS_ARCHIVED,
	DELETED as STATUS_DELETED,
} from '@/constants/audit-status-codes';
import { getExtendedStatus } from '@/constants/utils';

import ApproveCreativesWarningModal from './approve-creatives-warning-modal.vue';


const STATUSDATA_BY_STATUS = Object.freeze({
	[STATUS_DRAFT]: {
		name: 'draft',
		icon: 'draft',
		color: 'darkgray',
	},

	[STATUS_PLAYING]: {
		name: 'playing',
		icon: 'play',
		color: 'green',
	},

	[PENDING_AUDIT_EXTENDED_APPROVED]: {
		name: 'awaiting_approval',
		icon: 'warn',
		color: 'yellow',
	},

	[PARTIALLY_APPROVED_EXTENDED_APPROVED]: {
		name: 'partially_playing',
		icon: 'play',
		color: 'yellow',
	},

	[STATUS_PRE_APPROVED]: {
		name: 'pre_approved',
		icon: 'check',
		color: 'blue',
	},

	[STATUS_APPROVED]: {
		name: 'approved',
		icon: 'check',
		color: 'green',
	},

	[STATUS_PAUSED]: {
		name: 'paused',
		icon: 'pause',
		color: 'purple',
	},

	[STATUS_FINISHED]: {
		name: 'finished',
		icon: 'check',
		color: 'navy',
	},

	[STATUS_ARCHIVED]: {
		name: 'archived',
		icon: 'arrow_circle',
		color: 'darkgray',
	},

	[STATUS_AWAITING_APPROVAL]: {
		name: 'awaiting approval',
		icon: 'warn',
		color: 'yellow',
	},

	[STATUS_REJECTED]: {
		name: 'rejected',
		icon: 'error',
		color: 'red',
	},

	[STATUS_DELETED]: {
		name: 'deleted',
		icon: 'error',
		color: 'navy',
	},

	default: {
		name: 'reviewing',
		icon: 'see',
		color: 'main_color',
	},
});


export default {
	name: 'PageRequestReview',

	components: {
		NiceTabs,
		TabDetails,
		TabOrder,
		TabCreative,
		TabStatus,
		TabHistory,
		ApproveCreativesWarningModal,
	},


	props: {
		id: {
			type: Number,
			required: true,
		},

		/**
		 * `resource` prop
		 * The component represents both request
		 * or campaign review. So the resource
		 * should be provided. `request` by default
		 *
		 * @type {Object}
		 */
		resource: {
			type: String,
			default: 'request',
			valaidator: resource => [
				'request',
				'campaign',
			].includes(resource),
		},
	},


	data() {
		return {
			instance: null,
			stats: null,
			statusList: {
				STATUS_ARCHIVED,
				STATUS_AWAITING_APPROVAL,
				STATUS_DELETED,
				STATUS_FINISHED,
				STATUS_PAUSED,
				STATUS_PLAYING,
				STATUS_REJECTED,
				STATUS_PRE_APPROVED,
				STATUS_APPROVED,
				STATUS_DRAFT,
			},
			showApproveCreativesWarningModal: false,
		};
	},


	computed: {
		...mapGetters('app', [
		  'isSSPAdminOrApprover',
		  'isSSPViewer',
		  'isDSP',
		]),

		isPlayDisabled() {
			return this.instance.owner_instance.is_insufficient_balance;
		}
	},


	methods: {
		...mapActions('devices', ['getStats']),

		getStatus() {
			return getExtendedStatus(this.instance);
		},

		getStatusByCampaign() {
			if (!this.instance) {
				return STATUSDATA_BY_STATUS.default;
			}

			const status = getExtendedStatus(this.instance);
			return STATUSDATA_BY_STATUS[status];
		},

		async updateStats() {
			// only deployed devices
			let filter = objectToQuery(getDevicesFilter(this.instance, true));
			const response = await this.getStats({
				filter,
			});

			this.$set(this, 'stats', response);
		},

		/**
		 * Close the modal
		 * === go to parent route
		 */
		modalClose() {
			// при возвращении на предыдущую страницу нужно бы вернуться на исходный запрос
			this.$log.warn('need return old query');
			this.$router.push({
				name: this.$route.meta.parentRouteName,
				// params: this.$route.params,
				// query: this.$route.query,
			});
		},


		/**
		 * It MUST return campaign/request instance
		 */
		async changeStatus(status, audit) {
			const resource = this.instance.is_request ? 'request' : 'campaign';
			const action = `${ resource }/changeAudit`;

			let changeResponse = null;

			try {
				changeResponse = await this.$store.dispatch(action, {
					id: this.id,
					sspAgencyId: audit.ssp_agency,
					status: status,
				});

				if (changeResponse.status !== 'ok') {
					throw new Error('Failed to change status');
				}

				await this.refreshInstance();
			}
			catch (error) {
				window.alert(this.$t('campaign.status_change_failed'));
			}

			return changeResponse;
		},


		//
		// request/campaign actions
		//

		// TODO: check are wrong?
		async play(audit) {

			// TODO: dsp can pause and unpause campaign. right?
			if (this.isSSPAdminOrApprover) {
				// return null;
			}

			if (this.instance.is_request) {
				return null;
			}

			await this.changeStatus(STATUS_PLAYING, audit);
		},

		async pause(audit) {
			if (audit.status === STATUS_PAUSED) {
				return;
			}

			// TODO: dsp can pause and unpause campaign. right?
			if (this.isSSPAdminOrApprover) {
				// return null;
			}

			if (this.instance.is_request) {
				return null;
			}

			await this.changeStatus(STATUS_PAUSED, audit);
		},

		async setWaitingApproval(audit) {
			// TODO: reason needed!
			if (this.isDSP || this.isSSPViewer) {
				return null;
			}

			if (this.instance.is_request) {
				return null;
			}

			await this.changeStatus(STATUS_AWAITING_APPROVAL, audit);
		},

		async preApprove(audit) {
			if (this.isDSP || this.isSSPViewer) {
				return null;
			}

			if (!this.instance.is_request) {
				return null;
			}

			await this.changeStatus(STATUS_PRE_APPROVED, audit);
		},

		/**
		 * Campaign Approval
		 * We require approved ads before approving the entire campaign
		 *
		 * SSP only
		 */
		async approve(audit) {
			if (this.isDSP || this.isSSPViewer) {
				return null;
			}

			if (!this.instance.is_request) {
				return null;
			}

			// check ads' audits
			let anyAdApproved = false;
			for (let ad of this.instance.ads) {
				if (anyAdApproved) {
					break;
				}

				for (let audit of ad.audits) {
					if (audit.status == STATUS_APPROVED) {
						anyAdApproved = true;
						break;
					}
				}
			}

			// go to a creatives page and show a popup
			if (!anyAdApproved) {
				this.$router.push({hash: 'creative-tab'});
				this.showApproveCreativesWarningModal = true;
				return;
			}

			await this.changeStatus(STATUS_APPROVED, audit);

			// check updated request instance for campaign and redirect
			if (this.instance.campaign) {
				this.$router.replace({
					name: 'campaign-details',
					params: {
						id: this.instance.campaign
					},
				});
			}
		},

		async reject(audit) {
			if (this.isDSP || this.isSSPViewer) {
				return null;
			}

			if (!this.instance.is_request) {
				return null;
			}

			await this.changeStatus(STATUS_REJECTED, audit);
		},

		async remove(audit) {
			if (this.isSSPAdminOrApprover) {
				return null;
			}

			await this.changeStatus(STATUS_DELETED, audit);
		},

		async archive(audit) {
			if (this.isSSPAdminOrApprover) {
				return null;
			}

			await this.changeStatus(STATUS_ARCHIVED, audit);
		},

		async refreshInstance() {
			const action = `${this.resource}/getItem`;

			this.$set(this, 'instance', await this.$store.dispatch(action, { id: this.id, force: true }));

			return this.instance;
		},
	},


	/**
	 * Gets the instance if id provided
	 */
	async created() {
		if (!this.id) {
			return null;
		}

		const action = `${this.resource}/getItem`;
		const instance = await this.$store.dispatch(action, { id: this.id, force: true });

		this.$set(this, 'instance', instance);
		await this.updateStats();
	},


	watch: {
		instance: {
			deep: true,
			async handler(instance) {
				await this.updateStats();
			},
		},
	},
};
</script>

<style lang="sass" scoped>
.request-review
	padding-bottom: 120px

.controls
	$padding: 25px
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	// justify-content: space-between

	margin: 0
	padding: $padding - $ni_button-padding-y $padding

	> .button
		padding: 0 0

	> .left
		justify-self: flex-start
		order: 0

		&:not(:first-child)
			margin-left: $padding

	> .right
		justify-self: flex-end
		order: 1

		&:not(:last-child)
			margin-right: $padding

.title-wrapper
	margin-right: auto

.title
	margin: -4px 0 7px
	color: var(--text_2_color)
	flex-shrink: 1

.title__name
	color: var(--text_color)

.audit-row
	display: flex
	flex-direction: row
	align-items: center
	justify-content: flex-start
	margin-bottom: 12px

	&:hover
		background: #fafafa

	.agency-title
		flex: 1 1
		margin: 0px 12px
		color: var(--text_2_color)

		.title__name
			margin-right: 12px
			color: var(--text_color)

.actions
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: flex-start

	::v-deep
		.nice-icon
			flex-shrink: 0

		.wizard-btn-content
			text-align: left


.subtitle
	display: block
	font-size: 12px
	color: var(--text_2_color)


.tabs
	&::v-deep
		> .ni_tabs-header
			max-width: 805px
			margin: 0 auto

.message_errors
		color: $nice_color-red
		font-size: $fsz__new__section-title
		font-weight: 300
</style>
