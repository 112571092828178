<template>
	<div ref="container"
		class="container">
		<svg
			v-if="width!=null"
			class="svg"
			:viewBox="`0 0 ${width} ${lineHeight+(strokeSize)}`"
			:width="width">
			<line
				v-for="(line,index) in lines"
				:key='`${index}_${selected}`'
				:x1="line.x"
				:y1="lineHeight+(strokeSize/2)"
				:x2="line.x"
				:y2="lineHeight+(strokeSize/2)-line.heightValue"
				:stroke="line.stroke"
				:stroke-width="strokeSize"
				vector-effect="non-scaling-stroke"
				stroke-linecap="round" />
		</svg>
	</div>
</template>

<script>

export default {
	name:'NiceRangeViz',

	props: {
		range: {
			type: Array,
			required: true
		},
		selected: {
			type: Array,
			required: true
		},

	},


	data() {
		return {
			width: null,
			between: 15,
			lineHeight: 30,
			minHeight: 5,
			tempWidth: 100,
			observer: null,
			strokeSize: 2,
		};
	},
	mounted () {
		this.width=this.$refs.container.clientWidth;
		this.observer = new ResizeObserver(entries =>{
			for(let entry of entries){
				this.width = entry.contentRect.width;
			}
		});
		this.observer.observe(this.$refs.container);
	},
	methods: {
		linear(n, min1, max1, min2, max2){
			return min2+(((n-min1)/(max1-min1))*(max2-min2));
		},
		linear101(n, min1, max1){
			return (n-min1)/(max1-min1);
		},
		inSelected(n) {
			let nInRange = this.linear(n,0,this.count-1,this.range[0],this.range[this.range.length-1]);
			return this.inRange(nInRange,this.selected[0],this.selected[this.selected.length-1]);
		},
		inRange(n, min,max){
			return n>=min && n<=max;
		},
		curveFunc(n){
			return Math.sin(n*(Math.PI));
		},
		funcValue(n){
			let initValue = n/(this.count-1);
			let min = this.linear101(this.selected[0],this.range[0],this.range[this.range.length-1]);
			let max = this.linear101(this.selected[this.selected.length-1],this.range[0],this.range[this.range.length-1]);
			return this.inRange(initValue,min,max) ? this.curveFunc(this.linear101(initValue,min,max)) : 0;

		}
	},
	computed: {
		count() {
			return Math.round(this.width/this.between)+1;
		},

		betweenC(){
			return (this.width-2)/(this.count-1);
		},

		lines() {
			let arr=[];
			//let test=0;
			for(let i=0;i<this.count;i++){
				arr.push({
					stroke:			this.inSelected(i) ? this.$palette.secondary_color : '#cccccc',
					heightValue:	Math.round(this.minHeight + this.funcValue(i)*(this.lineHeight-this.minHeight)),
					x:				Math.round((this.strokeSize/2)+(this.betweenC*i))
				});
			}
			return arr;
		}
	},
};
</script>

<style lang="sass" scoped>
$height: 30px
.line
	--width: 2px

	will-change: background-color, height
	width: var(--width)
	height: $height
	background-color: var(--secondary_color)
	border-radius: 1px
	transition: background-color .2s ease, height .2s ease
.container
	//margin-bottom: 40px
	display: flex
	justify-content: space-between
	align-items: flex-end
	height: $height
.svg
	display: block

</style>
