<template>
	<div>
		<nice-label
			:label="label"
			class="uploader-label"
			:error="error? $t(`agency.${error}`) : null"
		/>
		<br>
		<div
			v-if="loading"
			class="loading-image"
		>
			<div class="icon-wrapper">
				<nice-icon-2
					class="loading-icon"
					icon="arrow_circle_2"
					:state="180"
				/>
			</div>
			<span class="loading-text"> {{ $t('agency.uploading') | capitalize }} </span>
			<nice-button-2
				class="uploader__action uploader__action_cancel"
				icon="x"
			/>
		</div>

		<nice-file
			v-else-if="!imageUrl"
			ref="fileInput"
			watercolor
			icon="load"
			icon-placement="start"
			icon-state="180"
			:accept="accept"
			@change="uploadImage"
		> {{ $t('upload') | capitalize }}
		</nice-file>

		<div
			v-else
			class="loading-image"
		>
			<img class="inner-image" :src="imageUrl">
			<div class="loading-text" :title="imageName">{{ imageName }}</div>
			<nice-button-2
				class="uploader__action uploader__action_basket"
				icon="basket-14"
				@click="deleteImage"
			/>
		</div>
	</div>
</template>

<script>
import NiceFile from '@/ui/nice-file.vue';

const getImageFromFile = async (file) => {
	const imageUrl = await new Promise((resolve, reject) => {
		try {
			const reader = new FileReader();
			reader.addEventListener('load', (event) => {
				resolve(event.target.result);
			});
			reader.addEventListener('error', (event) => {
				reject(event);
			});
			reader.readAsDataURL(file);
		} catch (error) {
			reject(error);
		}
	});

	return await new Promise((resolve, reject) => {
		const img = new Image();
		img.addEventListener('load', (event) => {
			resolve(event.target);
		});
		img.addEventListener('error', (event) => {
			reject(event);
		});
		img.src = imageUrl;
	});
};


export default {
	name: 'ImageUploader',

	components: {
		NiceFile
	},

	props: {
		label: {
			type: String,
			required: true,
		},
		imageUrl: String,
		uploadMethod: {
			type: Function,
			required: true,
		},
		accept: {
			type: String,
			required: true,
		},
		resolution: {
			type: Object,
		},
	},

	data() {
		return {
			loading: false,
			error: null,
		};
	},

	computed: {
		imageName() {
			return this.imageUrl.split('/').pop();
		}
	},

	methods: {
		async uploadImage(file) {
			this.loading = true;
			let image = null;

			try {
				image = await getImageFromFile(file[0]);
			} catch (error) {
				this.$log.error(error);
			}

			if (image === null) {
				this.loading = false;
				this.error = 'image_error';
				return;
			}

			const {naturalWidth: width, naturalHeight: height} = image;

			if (this.resolution && {width, height} !== this.resolution) {
				this.error = 'image_error';
			}

			await this.uploadMethod(file[0]);
			this.loading = false;
		},

		async deleteImage() {
			this.loading = true;
			await this.uploadMethod(null);
			this.loading = false;
		},
	}
};
</script>

<style lang="sass" scoped>

.loading-image
	width: 180px

	border: 1px solid #DDDDDD
	box-sizing: border-box
	border-radius: 6px

	display: grid
	grid-template-columns: 31px auto 31px
	grid-template-rows: 31px
	column-gap: 9px
	align-items: center
	overflow: hidden

.uploader-label
	margin-bottom: 10px

.loading-text
	height: 20px
	width: 90px
	font-size: 12px
	line-height: 18px
	color: #919191

	text-overflow: ellipsis
	white-space: nowrap
	overflow: hidden

.icon-wrapper
	height: 100%
	display: flex
	background: #cce0f5
	justify-content: center
	align-items: center

.inner-image
	display: block
	object-fit: cover

	width: 100%
	height: 100%

.uploader__action
	width: 100%
	height: 100%
	justify-content: center

.uploader__action_basket
	--ni-icon-sign: #FF6237

.uploader__action_cancel
	--ni-icon-sign: #000000
</style>
