<template>
	<section class="page-section">
		<div class="cl-container">
			<device-filter
				v-model='filterData'
				class="cl-pad cl-row"
				size='xl'
				@update="onFilterUpdate"
			/>
			<device-filter
				v-model='filterData'
				class="cl-pad cl-xs12 cl-sm12 cl-md12 cl-lg6 cl-xl6"
				size='md'
				@update="onFilterUpdate"
			/>
			<device-filter
				v-model='filterData'
				class="cl-pad cl-xs12 cl-sm6 cl-md6 cl-lg3 cl-xl3"
				size='sm'
				@update="onFilterUpdate"
			/>
			<device-filter
				v-model='filterData'
				class="cl-pad cl-xs12 cl-sm6 cl-md6 cl-lg3 cl-xl3"
				size='sm'
				@update="onFilterUpdate"
			/>
			<div class="cl-pad cl-xs6 cl-sm4 cl-md4 cl-lg3 cl-xl2">
				<h2>Value</h2>
				<pre>{{ filterData }}</pre>
			</div>

			<div class="cl-pad cl-xs6 cl-sm4 cl-md4 cl-lg3 cl-xl2">
				<h2>Filter data</h2>
				<pre>{{ filterOutput }}</pre>
			</div>
		</div>
	</section>
</template>

<script>
import DeviceFilter from '@/components/device-filter.vue';

export default {
	name: 'TabDeviceFilter',
	components: {
		DeviceFilter,
	},

	data() {
		return {
			filterData: {},
			filterOutput: null,
		};
	},
	methods: {
		onFilterUpdate(data) {
			this.filterOutput = data;
		},
	},
};
</script>
