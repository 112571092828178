<template>
	<span
		class="nice-value"
		:class="{
			'labeled': labeled || label,
		}"

	>
		<span v-if="label" class="ni-label " >
			<span v-html="label" />
			<nice-icon
				v-if="hint"
				v-tippy="{
					placement: 'top',
					distance: 10
				}"
				class="label-icon"
				icon="hint"
				:content="hint"
			/>
			<slot name="label" />
		</span>
		<span
			v-if="value"
			v-tippy="{
				placement: 'bottom',
				distance: 3
			}"
			v-bind="$attrs"
			class="ni-value"
			:class="{
				'ni-placeholder': !value,
			}"
			:content="extendedValue"
			v-html="value || placeholder"
		/>
		<span
			v-if="units && (value || placeholder)"
			class="ni-input-units"
			v-html="units"
		/>
		<slot
			v-if="$slots.default"
		/>
	</span>
</template>

<script>
export default {
	name: 'NiceValue',


	props: {
		labeled: { type: Boolean, default: false, },
		label: { type: String, },
		value: { type: [String, Number], default: '', },
		extendedValue: { type: [String, Number], default: '', },
		units: { type: String, default: '', },

		placeholder: { type: String, default: '', },

		hint: { type: String, },
	},

};
</script>

<style lang="sass" scoped>
.ni-label
	display: flex
	align-items: center

	& > *:not(:first-child)
		margin-left: .5em
</style>

