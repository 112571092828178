export default {
	booked: {
		en: 'booked',
		ge: 'დაჯავშნილი',
		de: 'gebucht',
		ru: 'забукировать',
		cn: '预订'
	},
	agency_list: {
		en: 'agency list',
		ge: 'სააგენტო სია',
		de: 'Agenturliste',
		ru: 'список агентств',
		cn: '代理商列表'
	},
	invite_agency: {
		en: 'invite agency',
		ge: 'მოიწვიე სააგენტო',
		de: 'Agentur einladen',
		ru: 'пригласить агентство',
		cn: 'invite agency'  // TODO: translation needed
	},
	add_agency: {
		en: 'add agency',
		ge: 'დაამატე სააგენტო',
		de: 'Agentur hinzufügen',
		ru: 'добавить агентство',
		cn: '添加代理商'
	},
	delete_agency: {
		en: 'delete agency',
		ge: 'წაშლა სააგენტო',
		de: 'Agentur löschen',
		ru: 'удалить агентство',
		cn: '删除代理商'
	},
	failed_to_delete_agency: {
		en: 'failed to delete the agency',
		ge: 'ვერ წაიშალა სააგენტო',
		de: 'Agentur konnte nicht gelöscht werden',
		ru: 'не удалось удалить агентство',
		cn: '无法删除代理商'
	},
	failed_to_restore_agency: {
		en: 'failed to restore the agency',
		ge: 'ვერ მოხერხდა სააგენტოს აღდგენა',
		de: 'Agentur konnte nicht wiederhergestellt werden',
		ru: 'не удалось восстановить агентство',
		cn: '无法恢复代理商'
	},
	confirm_agency_deletion: {
		en: 'Are you sure you want to delete the agency?',
		ge: 'დარწმუნებული ხართ, რომ გსურთ სააგენტოს წაშლა?',
		de: 'Sind Sie sicher, dass Sie die Agentur löschen möchten?',
		ru: 'Вы уверены, что хотите удалить агентство?',
		cn: '您确定要删除代理商吗？'
	},
	confirm_agency_restoration: {
		en: 'Are you sure you want to restore the agency',
		ge: 'დარწმუნებული ხართ, რომ გსურთ სააგენტოს აღდგენა',
		de: 'Sind Sie sicher, dass Sie die Agentur wiederherstellen möchten?',
		ru: 'Вы уверены, что хотите восстановить агентство?',
		cn: '您确定要恢复代理商吗？'
	},
	deleted: {
		en: 'deleted',
		ge: 'წაშლილი',
		de: 'gelöscht',
		ru: 'удалено',
		cn: '已删除'
	},
	search: {
		en: 'search',
		ge: 'ძებნა',
		de: 'Suche',
		ru: 'поиск',
		cn: '搜索'
	},
	comment: {
		en: 'comment',
		ge: 'კომენტარი',
		de: 'Kommentar',
		ru: 'комментарий',
		cn: '评论'
	},
	add_user: {
		en: 'add user',
		ge: 'მომხმარებლის დამატება',
		de: 'Benutzer hinzufügen',
		ru: 'добавить пользователя',
		cn: '添加用户'
	},
	person: {
		en: 'person',
		ge: 'პერსონა',
		de: 'Person',
		ru: 'персона',
		cn: '人员'
	},
	first_name: {
		en: 'first name',
		ge: 'სახელი',
		de: 'Vorname',
		ru: 'имя',
		cn: '名'
	},
	lastname: {
		en: 'lastname',
		ge: 'გვარი',
		de: 'Nachname',
		ru: 'фамилия',
		cn: '姓'
	},
	fathers_name: {
		en: 'fathers name',
		ge: 'მამის სახელი',
		de: 'Vaters Name',
		ru: 'отчество',
		cn: '父亲姓名'
	},
	agency: {
		en: 'agency',
		ge: 'სააგენტო',
		de: 'Agentur',
		ru: 'агентство',
		cn: '代理商'
	},
	contact: {
		en: 'contact',
		ge: 'კონტაქტი',
		de: 'Kontakt',
		ru: 'контакты',
		cn: '联系人'
	},
	preferences: {
		en: 'preferences',
		ge: 'პრეფერენციები',
		de: 'Einstellungen',
		ru: 'настройки',
		cn: '偏好'
	},
	language: {
		en: 'language',
		ge: 'ენა',
		de: 'Sprache',
		ru: 'язык',
		cn: '语言'
	},
	dateformat: {
		en: 'date format',
		ge: 'თარიღის ფორმატი',
		de: 'Datumsformat',
		ru: 'формат даты',
		cn: '日期格式'
	},
	timeformat: {
		en: 'time format',
		ge: 'დროის ფორმატი',
		de: 'Zeitformat',
		ru: 'формат времени',
		cn: '时间格式'
	},
	lang_caption_ru: {
		en: 'Русский',
		ge: 'ქართული',
		de: 'Русский',
		ru: 'Русский',
		cn: '俄语'
	},
	lang_caption_en: {
		en: 'English',
		ge: 'ინგლისური',
		de: 'English',
		ru: 'English',
		cn: '英语'
	},
	lang_caption_cn: {
		en: 'Chinese',
		ge: 'ჩინური',
		de: 'Chinese',
		ru: 'Китайский',
		cn: '中文'
	},
	lang_caption_ge: {
		en: 'Georgian',
		ge: 'Georgian',
		de: 'Georgian' /* TODO: to be translated */,
		ru: 'Грузинский',
		cn: 'Georgian'
	},
	lang_caption_de: {
		en: 'German',
		ge: 'German',
		de: 'German' /* TODO: to be translated */,
		ru: 'Немецкий',
		cn: 'German'
	},
	only_numbers: {
		en: 'The field can contain only numbers',
		ge: 'ეს ველი შეიძლება შეიცავდეს მხოლოდ რიცხვებს',
		de: 'Das Feld darf nur Zahlen enthalten',
		ru: 'Поле может содержать только цифры',
		cn: /* TODO: to be translated */'The field can contain only numbers',
	},
	verify_email: {
		en: 'Confirm the email before {n}, otherwise it will be blocked',
		ge: 'დაადასტურეთ წერილი {n}-მდე, წინააღმდეგ შემთხვევაში ის დაიბლოკება',
		de: 'Bestätigen Sie die E-Mail vor {n}, sonst wird sie blockiert',
		ru: 'Подтвердите email до {n}, иначе он будет заблокирован',
		cn: /* TODO: to be translated */'Confirm the email before {n}, otherwise it will be blocked',
	},
	verify_phone: {
		en: 'The field can contain numbers and symbols "+", "(", ")", "-" or spaces',
		ge: 'ველი შეიძლება შეიცავდეს რიცხვებს და სიმბოლოებს "+", "(", ")", "-" ან სფეისებს',
		de: 'Das Feld darf Zahlen und Symbole "+", "(", ")", "-" oder Leerzeichen enthalten',
		ru: 'Поле может содержать цифры и символы "+", "(", ")", "-" или пробелы',
		cn: /* TODO: to be translated */'The field can contain numbers and symbols "+", "(", ")", "-" or spaces',
	},
	verify_phone_only_number_and_plus: {
		en: 'The field can contain numbers and symbol "+"',
		ge: 'The field can contain numbers and symbol "+"', /* TODO: to be translated */
		de: 'The field can contain numbers and symbol "+"', /* TODO: to be translated */
		ru: 'Поле может содержать цифры и символ "+"',
		cn: /* TODO: to be translated */'The field can contain numbers and symbol "+"',
	},
	verify_inn: {
		en: 'INN can only consist of 10 or 12 digits',
		ge: 'INN can only consist of 10 or 12 digits', /* TODO: to be translated */
		de: 'INN can only consist of 10 or 12 digits', /* TODO: to be translated */
		ru: 'ИНН может состоять только из 10 или 12 цифр',
		cn: /* TODO: to be translated */'INN can only consist of 10 or 12 digits',
	},
	verify_first_and_last_name: {
		en: 'The field can only contain letters and hyphens',
		ge: 'ველი შეიძლება შეიცავდეს მხოლოდ ასოებს და დეფისებს',
		de: 'Das Feld darf nur Buchstaben und Bindestriche enthalten',
		ru: 'Поле может содержать только буквы и дефисы',
		cn: 'The field can only contain letters and hyphens'  // TODO: translation needed
	},
	send_verify_email: {
		en: 'Resend the email',
		ge: 'ელფოსტის ხელახლა გაგზავნა',
		de: 'E-Mail erneut senden',
		ru: 'Выслать письмо повторно',
		cn: /* TODO: to be translated */'Resend the email',
	},
	tos_caption: {
		en: 'Rules for using the service',
		ge: 'Rules for using the service',
		de: 'Rules for using the service',
		ru: 'Правила об использовании сервиса',
		cn: /* TODO: to be translated */'Rules for using the service',
	}
};
