<template>
	<transition name="modal-transition">
		<nice-modal
			@close="close"
		>
			<section class="modal">
				<div class="support-container">
					<nice-icon-2 class="hand" icon="hand" /> 
					<p>{{ $t('common.support') }}</p>
				</div>
				
				<div 
					v-if="$uioverrides.help_text" 
					class="message"
				>
					{{ $uioverrides.help_text }}
				</div>

				<div 
					v-if="$uioverrides.help_email" 
					class="details"
				>
					<div class="detail" >
						<nice-label class="detail-label" :label="$t('email2')" />
						<span class="value" >{{ $uioverrides.help_email }}</span>
					</div>
					<div class="detail" >
						<nice-button-2
							class="copy"
							icon="copy-24"
							palette="gray"
							@click="copyEmail"
						>
							{{ $t('copy') }}
						</nice-button-2>
					</div>
				</div>

				<div 
					v-if="$uioverrides.help_phone" 
					class="details"
				>
					<div class="detail" >
						<nice-label class="detail-label" :label="$t('phone')" />
						<span class="value" >{{ $uioverrides.help_phone }}</span>
					</div>
				</div>

				<div class="close-container">
					<nice-button-2
						class="close-button"
						@click="close"
					>
						{{ $t('close') }}
					</nice-button-2>
				</div>
			</section>
		</nice-modal>
	</transition>
</template>

<script>
import { mapMutations } from 'vuex';
import NiceModal from '@/ui/nice-modal';


export default {
	name: 'HelpModal',


	components: {
		NiceModal,
	},

	methods: {
		...mapMutations('modals', ['setShowHelpModal']),

		/**
		 * Close the modal
		 */
		close() {
			this.setShowHelpModal(false);
		},

		copyEmail() {
			navigator.clipboard.writeText(this.$uioverrides.help_email);
		}
	}
};
</script>


<style lang="sass" scoped >
.modal
	display: flex
	flex-direction: column
	max-height: calc(100vh - 160px)
	padding: 40px 40px 30px 40px
	gap: 30px

.nice-modal > *
	width: auto
.support-container
	display: flex
	flex-direction: row
	color: var(--main_color)

	.hand
		width: 18px
		height: 18px
		margin-right: 16px

	p
		margin: 0
		font-size: 16px

.message
	width: 280px
	line-height: 18px

.details
	grid-area: details
	display: flex
	flex-direction: row
	justify-content: flex-start

	::v-deep .nice-label
		height: 12px

	::v-deep .nice-button
		min-height: 24px
		font-size: 10px

	.detail
		display: flex
		flex-direction: column

		&:not(:first-child)
			margin-left: 20px

		& > .value
			margin: 12px 0 0 0
			line-height: 16px

		.copy
			padding-top: 16px

	.value.placeholder
		color: var(--text_2_color)
	
.close-container
	text-align: right
	line-height: 13px
	
	::v-deep .nice-button
		min-height: 13px
		font-size: 11px
</style>
