/**
 * Legal entity
 */
import store from '@/store';
import baseRest from './base/rest';


const getPrefix = () => `agency/${store.state.app.agency}/${store.state.app.side}/legal/`;

// TODO: return it as is?
const baseAdvertiserApi = baseRest(getPrefix);


export default {
	getPrefix,

	/**
	 * Request LegalEntity
	 *
	 * @returns {Object} legal entity
	 */
	'retrieve': baseAdvertiserApi.retrieve,
};
