<template>
	<section class="page page-dev">
		<nice-tabs>
			<tab-buttons data-ni_tab_name="Buttons" />
			<tab-checkbox data-ni_tab_name="Checkboxes" />
			<tab-multiselect data-ni_tab_name="Multiselect" />
			<tab-range data-ni_tab_name="Range" />
			<tab-switch data-ni_tab_name="Switch" />
			<tab-text-input data-ni_tab_name="Values" />
			<tab-tree data-ni_tab_name="Tree" />
			<tab-graph data-ni_tab_name="Stats Graph" />
		</nice-tabs>
	</section>
</template>

<script>
import NiceTabs from '@/ui/nice-tabs.vue';

import TabButtons from './tab-buttons';
import TabCheckbox from './tab-checkbox.vue';
import TabMultiselect from './tab-multiselect.vue';
import TabRange from './tab-range';
import TabSwitch from './tab-switch.vue';
import TabTextInput from './tab-text-inputs.vue';
import TabTree from './tab-tree';
import TabGraph from './tab-stats-graph.vue';


export default {
	name: 'PageDevUi',
	components: {
		NiceTabs,

		TabButtons,
		TabCheckbox,
		TabMultiselect,
		TabRange,
		TabSwitch,
		TabTextInput,
		TabTree,
		TabGraph,
	},
};
</script>
