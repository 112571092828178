
export default {
	notification_success: {
		en: 'Changes successful',
		ge: 'ცვლილებები წარმატებულით დასრულდა',
		de: 'Änderungen erfolgreich',
		ru: 'Информация успешно изменена',
		cn: /* TODO: to be translated */'Changes successful',
	},
	notification_error: {
		en: 'Changes failed',
		ge: 'ცვლილებები ვერ მოხერხდა',
		de: 'Änderungen fehlgeschlagen',
		ru: 'Произошла ошибка',
		cn: /* TODO: to be translated */'Changes failed',
	},
	wrong_email: {
		en: 'The user with such mail does not exist',
		ge: 'ასეთი ელფოსტით მომხმარებელი არ არსებობ',
		de: 'Ein Benutzer mit dieser E-Mail existiert nicht',
		ru: 'Пользователя с такой почтой не существует',
		cn: /* TODO: to be translated */'The user with such mail does not exist',
	},
	wrong_credentials: {
		en: 'Invalid credentials',
		ge: 'არასწორი სერთიფიკატები',
		de: 'Ungültige Anmeldedaten',
		ru: 'Неверные учетные данные',
		cn: /* TODO: to be translated */'Invalid credentials',
	},
	wrong_code: {
		en: 'Invalid two factor auth code',
		ge: 'არასწორი ავტორიზაციის კოდი',
		de: 'Ungültiger Zwei-Faktor-Authentifizierungscode',
		ru: 'Неверный код авторизации',
		cn: /* TODO: to be translated */'Invalid two factor auth code',
	},
	target_limit: {
		en: 'The campaign will end when the goal is completed - {target_limit} ad plays',
		ge: 'კამპანია დასრულდება, როდესაც მიზანს მიაღწევს - {target_limit} რეკლამის ჩართვა',
		de: 'Die Kampagne endet, wenn das Ziel erreicht ist - {target_limit} Anzeigenwiedergaben',
		ru: 'Кампания закончится когда выполнится цель - {target_limit} показов',
		cn: /* TODO: to be translated */'The campaign will end when the goal is completed - {target_limit} ad aplays',
	},
};
