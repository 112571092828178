<template>
	<symbol :id="`${prefix}error-34`" viewBox="0 0 34 34">
		<circle cx="17" cy="17" r="16" stroke-width="2" fill="none" class="sign" />
		<path d="M14,14 20,20 M20,14 14,20" stroke-width="2" fill="none" class="sign" />

	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolError34',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	stroke: var(--ni-icon-base, #{$nice_color-red})
</style>
