<template>
	<td
		:class="[
			'nice-table-cell_status',
			`status-${item.status}`,
			{
				'no-change': !canChange,
			}
		]"
	>
		<nice-action-list
			v-if="canChange"

			:status="item.status"
			:list="list"
			:hide-on-click="hideOnClick"
			:icons-in-list="iconsInList"
			:tippy-trigger="tippyTrigger"

			class="ni_table_c_status--ni_al"
			@change="change"
		/>

		<template v-else >
			<nice-icon-2
				:icon="currentItemFromList.icon"
				class="ni_table_c_status--label-icon"
			/>
			<!--<span class="ni_table_c_status--label-status" >{{ item.status.replace('_', ' ') }}</span>-->
			<span
				class="ni_table_c_status--label-status"
			>{{ $t('campaign.status_change_' + currentItemFromList.value) }}</span>
		</template>
	</td>
</template>

<script>
import NiceActionList from '@/ui/nice-action-list';
import { valueToChoice, fillItem } from '@/ui/nice-action-list';


export default {
	name: 'TableCellStatus',


	components: {
		NiceActionList,
	},


	props: {
		resource: {
			type: String,
			required: true,
		},

		/**
		 * Current item
		 *
		 * @namespace
		 * @property {object}  defaults
		 * @property {number}  defaults.id              - item id
		 * @property {string}  defaults.status          - item current status
		 */
		item: {
			type: Object,
			required: true,
		},

		/**
		 * Show link or caption only
		 */
		canChange: {
			type: Boolean,
			default: false,
			required: false,
		},


		// NiceActionList props

		/**
		 * Array of available statuses
		 * @namespace
		 * @property {object}  defaults
		 * @property {}  defaults.value                - Value.
		 * @property {(string|number)}  defaults.icon  - Icon slug (default: value).
		 * @property {string}  defaults.label          - label (default: value).
		 * @property {string}  defaults.labelAction    - label for row in change list (default: value).
		 */
		list: {
			type: Array,
			required: true,
		},

		/**
		 * Show icons in menu
		 */
		iconsInList: {
			type: Boolean,
			default: true,
		},

		/**
		 * Скрываеть ли попап по клику
		 */
		hideOnClick: {
			type: Boolean,
			default: true,
		},

		/**
		 * Trigger show/hide menu
		 */
		tippyTrigger: {
			type: String,
			default: 'mouseenter focus',
		},
	},


	computed: {
		currentItemFromList() {
			const current = this.list.find(i => i.value === this.item.status);
			const item = current ? fillItem(current) : valueToChoice(this.item.status);
			return item;
		},
	},


	methods: {
		async change(newStatus) {
			try {
				await this.$store.dispatch(`${this.resource}/change`, {
					id: this.item.id,
					data: {
						status: newStatus
					}
				});}
			catch (error) {
				if (error.response.status === 400) {
					alert(
						'Please fill in the required fields in your request: '
						+ Object.keys(error.response.data).join(', ')
					);
				}
			}
		},
	},
};
</script>


<style lang="sass" scoped >
.ni_table_c_status--ni_al
	width: 100%

	::v-deep .ni_action_list--label
		line-height: 1.2em

.no-change
	text-transform: capitalize
	vertical-align: middle

	.ni_table_c_status--label-icon
		height: 16px

	.ni_table_c_status--label-status
		font-size: $fsz__new__smaddle

	> *
		vertical-align: middle

		&:not(:first-child)
			margin-left: $ni_menu_context--margin
</style>
