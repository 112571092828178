<template>
	<symbol :id="`${prefix}load-24`" viewBox="0 0 24 24">
		<circle cx="12" cy="12" r="12" class="base" />
		<path d="M16 12V13C16 14.1025 15.1025 15 14 15H10C8.89697 15 8 14.1025 8 13V12H6V13C6 15.2061 7.79443 17 10 17H14C16.2061 17 18 15.2061 18 13V12H16Z" class="sign" />
		<path d="M15.9492 9.46435L14.5352 8.05029L13 9.58575V6H11V9.58594L9.46435 8.05029L8.05029 9.46435L12 13.4141L15.9492 9.46435Z" class="sign arrow" />
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolLoad24',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.base
	fill: var(--ni-icon-base, #{$nice_color-gray_light})

.sign
	fill: var(--ni-icon-sign, #{$nice_color-gray_dark})

.arrow
	transform: rotate3d(0, 0, 1, calc(var(--ni-icon-state, 0) * 1deg))
	transform-origin: 50% 40%
</style>
