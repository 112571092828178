<template>
	<nice-menu-context
		ref="ni_menu_context"
		class="nice-action-list"
		icon="arrow_corner-24"
		:tippy-trigger="tippyTrigger"
	>
		<!-- slot `label` -->
		<template #label >
			<nice-icon-2
				v-if="iconsInList"
				:icon="statusCurrent.icon"
				class="ni_action_list--icon"
			/>
			<span
				class="ni_action_list--label"
			>{{ statusCurrent.label }}</span>
		</template>

		<nice-button-2
			v-for="status in statusListForChange"

			:key="status.value"
			:class="[
				'ni_action_list--button-status_change',
				`action-${status.value}`,
			]"

			:icon="iconsInList ? status.iconList : null"
			:palette="getButtonPalette(status.icon)"

			@click="emitChange(status.value)"
		>
			{{ status.labelAction }}
		</nice-button-2>
	</nice-menu-context>
</template>

<script>
import _ from 'underscore';

import NiceMenuContext from '@/ui/nice-menu-context';

import { capitalize } from '@/utilites';


export const valueToChoice = value => {
	return {
		value: value,
		icon: value,
		iconList: `${value}-24`,
		label: capitalize(value),
		labelAction: capitalize(value),
	};
};

export const fillItem = item => {
	const icon = item.icon || item.value;
	const label = item.label || capitalize(item.value);
	return {
		value: item.value,
		icon: icon,
		iconList: item.iconList || `${icon}-24`,
		label: label,
		labelAction: item.labelAction || label,
	};
};


export default {
	name: 'NiceActionList',


	components: {
		NiceMenuContext,
	},


	props: {
		/**
		 * Current status (value) to show in row and cut from menu
		 */
		status: {
			required: true,
		},

		/**
		 * Array of available statuses
		 * @namespace
		 * @property {object} defaults
		 * @property {(string|number)} defaults.value - Value.
		 * @property {(string|number)} defaults.icon - Icon slug (default: value).
		 * @property {string} defaults.label - label (default: value).
		 * @property {string} defaults.labelAction - label for row in change list (default: value).
		 */
		list: {
			type: Array,
			required: true,
		},

		/**
		 * Show icons in menu
		 */
		iconsInList: {
			type: Boolean,
			default: true,  // bad practice: no deafault on Boolean prop
		},

		/**
		 * Скрываеть ли попап по клику
		 */
		hideOnClick: {
			type: Boolean,
			default: true,  // bad practice: no deafault on Boolean prop
		},

		/**
		 * Trigger show/hide menu
		 */
		tippyTrigger: {
			type: String,
			default: 'mouseenter focus',
		},
	},


	computed: {
		statusList() {
			let hasCurrent = false;
			let result = _.reduce(this.list, (res, status) => {
				const choice = typeof status === 'object' ? fillItem(status) : valueToChoice(status);

				if (choice.value == this.status) {
					hasCurrent = true;
				}

				res.push(choice);
				return res;
			}, []);

			if (!hasCurrent) {
				result.push(valueToChoice(this.status));
			}

			return result;
		},

		statusListForChange() {
			return _.reject(this.statusList, i => {
				return i.value === this.status;
			});
		},

		statusCurrent() {
			return this.statusList.find(i => i.value === this.status);
		},
	},


	methods: {

		emitChange(newStatus) {
			if (this.hideOnClick) {
				this.$refs.ni_menu_context.hide();
			}
			this.$emit('change', newStatus);
		},

		getButtonPalette(icon) {
			let palette = 'gray';

			switch(icon) {
			case 'error':
				palette = 'red' ;
				break;

			case 'warn':
				palette = 'yellow' ;
				break;

			case 'pause':
				palette = 'purple' ;
				break;

			case 'play':
				palette = 'green' ;
				break;
			}

			return palette;
		},
	},
};
</script>

<style lang="sass" scoped >
.nice-action-list
	margin: 0

	color: var(--text_2_color)
	font-size: $fsz__smaddle
	line-height: 24px
	font-weight: 400
	text-transform: capitalize

	&::v-deep > div > div
		display: flex

	.nice-icon
		flex-shrink: 1
		flex-basis: $ni_action_list--icon-status

	.ni_action_list--label
		flex-shrink: 2
		flex-basis: calc(100% - #{$ni_action_list--icon-status + $ni_action_list--icon-button} - #{$ni_menu_context--margin*2})

	::v-deep .nice-button
		flex-shrink: 1
		flex-basis: $ni_action_list--icon-button

		&.tippy-active,
		&:hover
			--ni-icon-base: var(--disabled_color)
			--ni-icon-sign: var(--main_color)
</style>
