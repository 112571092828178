<template>
	<symbol :id="`${prefix}marker`" viewBox="0 0 11 14">
		<path
			fill="none"
			:stroke="getColor('main')"
			stroke-width="1"
			d="M5.5,13.2L1.74,8.87C0,6.87,0.1,3.85,1.96,1.98l0,0c1.96-1.97,5.12-1.97,7.07,0l0,0 C10.9,3.85,11,6.87,9.26,8.87L5.5,13.2z"
		/>
		<ellipse
			:fill="getColor('main')"
			stroke="none"
			cx="5.5"
			cy="5.35"
			rx="1.45"
			ry="1.47"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconMarker',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.gray,
				main__disabled: this.$palette.gray__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
