<template>
	<symbol :id="`${prefix}draft`" viewBox="0 0 16 16">
		<circle
			cx="8"
			cy="8"
			r="7"
			fill="none"
			:stroke="getColor('main')"
			stroke-width="2"
			stroke-dasharray="4.8 4.2"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconDraft',


	props: {
		icon: {
			type: String,
		},

		prefix: {
			type: String,
			required: true,
		},

		state: {
			type: [String, Number, Boolean],
			required: false,
		},

		palette: {
			type: Object,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		params: {
			type: Object,
			required: false,
		},
	},


	computed: {
		colors() {
			return {
				main: this.$palette.graydark,

				main__disabled: this.$palette.graydark__disabled,

				...this.palette,
			};
		},
	},


	methods: {
		getColor(color) {
			let bits = [
				color,
			];

			if (this.disabled) {
				bits.push('disabled');
			}

			return this.colors[bits.join('__')];
		},
	},
};
</script>
