var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nice-paginator",class:{
		'disabled': _vm.disabled,
		'loading': _vm.loading,
	}},[_c('nice-paginator-item',{attrs:{"index":_vm.prevIndex,"icon":{
			icon: 'arrow-corner',
			state: 180,
			palette: {
				main: _vm.$palette.black,
				disabled: _vm.$palette.black__disabled,
			}
		},"disabled":_vm.index === 1 || _vm.disabled,"isLink":_vm.actualUseQuery,"queryKey":_vm.actualQueryKey},on:{"go-to":_vm.goTo}}),_vm._l((_vm.indexes),function(i){return _c('nice-paginator-item',{key:'test'+i,attrs:{"index":i,"is-current":_vm.index === i,"disabled":_vm.disabled,"isLink":_vm.actualUseQuery,"queryKey":_vm.actualQueryKey},on:{"go-to":_vm.goTo}})}),_c('nice-paginator-item',{attrs:{"index":_vm.nextIndex,"icon":{
			icon: 'arrow-corner',
			palette: {
				main: _vm.$palette.black,
				disabled: _vm.$palette.black__disabled,
			}
		},"disabled":_vm.index === _vm.count || _vm.disabled,"isLink":_vm.actualUseQuery,"queryKey":_vm.actualQueryKey},on:{"go-to":_vm.goTo}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }