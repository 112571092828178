<template>
	<symbol :id="`${prefix}plus_another-20`" viewBox="0 0 20 20">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6 0C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H14C17.3137 20 20 17.3137 20 14V6C20 2.68629 17.3137 0 14 0H6ZM9 5H11V9H15V11H11V15H9V11H5V9H9V5Z"
			class="sign"
		/>
	</symbol>
</template>

<script>
export default {
	name: 'NiIconSymbolPlusAnother20',


	props: {
		prefix: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="sass" scoped >
.sign
	fill: var(--ni-icon-sign, --main_color)
</style>
